export class ResourceType {
    public static PUBLICATION_JOURNAL_ARTICLE = 'publication.journalArticle';
    public static PUBLICATION_BOOK_CHAPTER = 'publication.bookChapter';
    public static PUBLICATION_CONFERENCE_PROCEEDING = 'publication.conferenceProceeding';
    public static PUBLICATION_BOOK_REVIEW = 'publication.bookReview';
    public static DATASET = 'dataset.dataset';
    public static PUBLICATION_BOOK = 'publication.book';
    public static CONFERENCE_PAPER = 'conference.conferencePaper';
    public static CONFERENCE_CONFERENCE_PRESENTATION = 'conference.conferencePresentation';
    public static CONFERENCE_POSTER = 'conference.conferencePoster';
    public static CONFERENCE_PROGRAM = 'conference.conferenceProgram';
    public static CONFERENCE_PRESENTATION = 'conference.presentation';
    public static CONFERENCE_EVENTPOSTER = 'conference.eventposter';
    public static PATENT = 'patent.patent';
    public static PUBLICATION_MAGAZINE_ARTICLE = 'publication.magazineArticle';
    public static PUBLICATION_EDITORIAL = 'publication.editorial';
    public static PUBLICATION_NEWSPAPER_ARTICLE = 'publication.newspaperArticle';
    public static POSTED_CONTENT_ACCEPTED_MANUSCRIPT = 'postedContent.acceptedManuscript';
    public static PUBLICATION_ABSTRACT = 'publication.abstract';

}
