import {UntypedFormControl} from '@angular/forms';

//PMID field accepts 1-8 digits, not only an 8-digit string
export function validatePmidFormat(control: UntypedFormControl) {
    const pmidFormat = control.value as string;
    if (pmidFormat && !(pmidFormat.match(/^[0-9]{1,8}$/))){
        return { validatePmidFormat: true };
    }
    return null;
}
