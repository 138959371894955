import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { FileUtils } from '@exl-ng/mulo-core';

@Pipe({
    name: 'fileTypeIcon',
    standalone: true
})
export class FileTypeIconPipe implements PipeTransform {
  transform(value: string, hasPrefix = true, extFallback = ''): string {
    /* By default, use the first part of the file type - add override cases as needed using the second part.
       Current available icons:
        image
        compressed
        cloud
        pdf
        video
        word
        code
        text
    */
    return (
      (hasPrefix ? 'file-' : '') + FileUtils.getFileProfile(extFallback, value)
    );
  }
}


