import { EsploroCreatorStatus } from './esploro-author.interface';

export enum CreatorContributorType {
    CREATOR,
    CONTRIBUTOR,
}

export interface CreatorContributor {
    id: string;
    order: number;
    owner: boolean;
    firstName: string;
    lastName: string;
    middleName: string;
    nameSuffix: string;
    orcid: string;
    role?: string;
    affiliationName: string;
    affiliationCode: string;
    affiliationNameList: string[];
    affiliationCodeList: string[];
    type?: CreatorContributorType;
    sourceAffiliation?: string;
    isDisplayInPublicProfile: boolean;
    isAffiliated: boolean;
    source: string;
    status: EsploroCreatorStatus;
}
