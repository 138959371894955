import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlUtils } from '../shared/utils/url.utils';
import { ConfigurationHandlerService } from './configuration-handler.service';

@Injectable()
export class ApcGuard  {

    institutionCode:string;

    constructor(
                private router: Router,
                private configurationService: ConfigurationHandlerService,
                ){
        this.institutionCode = UrlUtils.getParam('institution');
    }
    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        // return true;
        const isApcEnabled = this.configurationService.isCustomerParameterEnabled('esploro_oable_integration');
        if (!isApcEnabled) {
            const queryParams = {institution: this.institutionCode};
            this.router.navigate(['/'], { queryParams: queryParams});
        }
        return isApcEnabled;
    }

}
