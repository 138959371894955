<exl-badge-auto-populated
    *ngIf="autoPopulated"
    tooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
    color="primary"
>
</exl-badge-auto-populated>
<mulo-rich-text-editor
    (changedContent)="onEditorContentChange($event)"
    [class.is-autopopulated]="autoPopulated"
    [sneakyToolbar]="true"
    [editorContent]="freeText"
    [placeholder]="placeholder"
>
</mulo-rich-text-editor>
