<ng-container
  *ngIf="nav && navPosition === 'top' || nav && navPosition === 'left'"
>
  <ng-container *ngTemplateOutlet="nav"></ng-container>
</ng-container>
<div class="main" [ngStyle]="mainStyle">
  <!-- [style.overflow]="preventOverflow ? 'auto' : null" -->
  <ng-content select="[slot='main'], slot-main"></ng-content>
</div>
<ng-container
  *ngIf="nav && navPosition === 'bottom' || nav && navPosition === 'right'"
>
  <ng-container *ngTemplateOutlet="nav"></ng-container>
</ng-container>

<ng-template #nav>
  <div
    class="nav"
    [ngStyle]="navStyle"
    [ngClass]="getNavClass()"
    muloSticky
    (muloStickyStuck)="onNavStick($event)"
    [muloStickyzIndex]="stickyZIndex"
  >
    <div class="nav-inner" [style.top.px]="stickyOffset">
      <ng-content select="[slot='nav'], slot-nav"></ng-content>
    </div>
  </div>
</ng-template>
