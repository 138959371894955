export class Grant {
    id: string;
    grantId: string;
    grantName: string;
    funderCode: string;
    funderName: string;
    funderAgency: string;
    funderAgencyCode: string;
    selfCreated?: boolean;

    constructor(){}

    getId(){
        return this.id;
    }

    setId(id){
        this.id = id;
    }

    getGrantId(){
        return this.grantId;
    }

    setGrantId(grantId){
        this.grantId = grantId;
    }

    getGrantName(){
        return this.grantName;
    }

    setGrantName(grantName){
        this.grantName = grantName;
    }

    getFunderCode(){
        return this.funderCode;
    }

    setFunderCode(funderCode){
        this.funderCode = funderCode;
    }

    getFunderName(){
        return this.funderName;
    }

    setFunderName(funderName){
        this.funderName = funderName;
    }

    getFunderAgency(){
        return this.funderAgency;
    }

    setFunderAgency(funderAgency){
         this.funderAgency = funderAgency;
    }

    setFunderAgencyCode(funderAgencyCode){
        this.funderAgencyCode = funderAgencyCode;
    }


}
