import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';
import * as easings from './easings';

const slideInStart = {
  position: '{{ position }}',
  opacity: 0,
  height: '{{ startHeight }}',
  width: '{{ startWidth }}',
  // insetBlockStart: '{{ top }}',
  transform: 'translate({{ dX }}, {{ dY }})',
  overflow: 'hidden',
};
const slideInDefaults = {
  startHeight: 0,
  endHeight: '*',
  startWidth: '*',
  endWidth: '*',
  time: '.5s',
  dX: '0',
  dY: '100%',
  position: 'relative',
  top: '*',
};
const slideInEnd = {
  opacity: 1,
  height: '{{ endHeight }}',
  width: '{{ endWidth }}',
  transform: 'translate3d(0, 0, 0)',
};
const slideOutStart = {
  height: '{{ startHeight }}',
  width: '{{ startWidth }}',
  position: '{{ position }}',
  opacity: 1,
  // insetBlockStart: '{{ top }}',
  transform: 'translate(0, 0)',
  overflow: 'hidden',
};
const slideOutDefaults = {
  startHeight: 0,
  endHeight: '*',
  startWidth: '*',
  endWidth: '*',
  time: '.5s',
  dX: '0',
  dY: '100%',
  position: 'relative',
  top: '*',
};
const slideOutEnd = {
  height: '{{ endHeight }}',
  width: '{{ endWidth }}',
  opacity: 0,
  transform: 'translate({{ dX }}, {{ dY }})',
};

export const SlideInAnimation = trigger('slideInAnimation', [
  transition(
    ':enter',
    [
      style(slideInStart),
      animate('{{ time }} ' + easings.OutQuint, style(slideInEnd)),
    ],
    { params: slideInDefaults }
  ),
]);

export const SlideOutAnimation = trigger('slideOutAnimation', [
  transition(
    ':leave',
    [
      style(slideOutStart),
      animate('{{ time }} ' + easings.InOutQuint, style(slideOutEnd)),
    ],
    { params: slideOutDefaults }
  ),
]);

export const SlideInChildrenAnimation = trigger('slideInChildrenAnimation', [
  transition(
    '* => *',
    [
      query('[animation-in-child]', style(slideInStart)),
      query(
        '[animation-in-child]',
        animate('{{ time }} ' + easings.InOutQuint, style(slideInEnd))
      ),
    ],
    { params: slideInDefaults }
  ),
]);
