<div class="wrapper">
  <div class="icon" *ngIf="icon">
    <mat-icon [class.mat-icon-rtl-mirror]="rtlMirrorIcon" [svgIcon]="icon"></mat-icon>
  </div>
  <div class="content">
    <ng-container *ngIf="content">
      <div class="semibold" *ngIf="content.contentTitle">{{ content.contentTitle | translate }}</div>
      <div class="semibold" *ngIf="content.subject">{{ content.subject }}</div>
      <div class="semibold" *ngIf="content.reason">{{ reasonPrefix }} {{ content.reason | translate }}.</div>
      <div [innerHTML]="content.body"></div>
      <p class="time" *ngIf="time">{{ time | amTimeAgo }}</p>
    </ng-container>
    <ng-content select="[slot-chat-content]"></ng-content>
  </div>
  <div class="actions">
    <ng-content select="[slot-chat-bubble-actions],[slot='chat-bubble-actions']"></ng-content>
  </div>
</div>
