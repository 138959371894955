import {Directive, ElementRef, Input, SimpleChanges, OnChanges} from '@angular/core';

@Directive({
    selector: '[exlFocus]'
})
export class FocusDirective implements OnChanges{

    @Input('exlFocus') activated = true;

    constructor( private el: ElementRef ) { }


    ngOnChanges(changes: SimpleChanges){
        if (changes.activated && this.activated) {
            this.initFocus();
        }
    }

    initFocus(){
        setTimeout(() => {
            this.el.nativeElement.focus();
        }, 700);
    }


}
