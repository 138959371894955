import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    OnDestroy,
    Input,
} from '@angular/core';
import { DepositSearchForService } from './deposit-search-for.service';
import { DepositForm } from '../../../shared/configurations/deposit-form.constant';
import { EsploroRecord } from '../../../shared/interfaces/esploro-record.model';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { UntypedFormControl } from '@angular/forms';
import { EsploroRelationship } from '../../../shared/interfaces/esploro-relationship.interface';
import { ExistsAssetsSearchResults } from '../../../shared/interfaces/view-configuration/exists-assets-search-results.interface';
import { JwtUtilService } from '../../../core/jwt-util.service';
import { SelectedResearchRecord } from '../../../shared/interfaces/research-record.interface';
import {
    Subject,
    distinctUntilChanged,
    debounceTime,
    takeUntil,
    tap,
} from 'rxjs';
import { ResourceTypeUtils } from '../../../shared/utils/resource-type.utils';
import { StringUtils } from '../../../shared/utils/string.utils';

@Component({
    selector: 'esp-deposit-search-for',
    templateUrl: './deposit-search-for.component.html',
    styleUrls: ['./deposit-search-for.component.scss'],
})
export class DepositSearchForComponent implements OnInit, OnDestroy {
    @Input() includeExternalSearch = true;
    @Input() placeholder = 'research.search.overline';
    @Input() userId;

    @Output() private selectedResearch: EventEmitter<any> = new EventEmitter();

    searchResult: EsploroRecord[];
    searchInProgress = false;
    noResultsMessage: string;
    //with spaces in the end
    pmidRegex = new RegExp('^[0-9]{7,8}\\s*$');
    isbnRegex = StringUtils.regexIsbn;
    digitsPrefixRegex = new RegExp('^[0-9]{3,}$');
    searchTerm = new UntypedFormControl();
    enrichedFromTitle = false;
    isInternalResults: boolean;
    internalMmsIds: string[];

    private searchForDestroy = new Subject<void>();

    constructor(
        private depositSearchForService: DepositSearchForService,
        public depositFormDataService: DepositFormDataService,
        private jwtService: JwtUtilService
    ) {}

    ngOnInit() {
        this.listenToSearchInput();
    }

    listenToSearchInput() {
        this.searchTerm.valueChanges
            .pipe(
                debounceTime(DepositForm.SEARCH_DEBOUNCE_TIME),
                distinctUntilChanged(),
                takeUntil(this.searchForDestroy)
            )
            .subscribe((value) => {
                if (value && value.length >= DepositForm.MIN_INPUT_LENGTH) {
                    this.searchResult = [];
                    this.noResultsMessage = '';
                    this.enrichRecord(value);
                }
            });
    }

    isUserCollaborator = (item: EsploroRecord) => {
        const userId = Number(this.jwtService.getUserID());
        const userIsCreator = item?.temporary?.creators?.some(
            (creator) => creator.almaId == userId
        );
        const userIsContributor = item?.temporary?.contributors?.some(
            (contributor) => contributor.almaId == userId
        );
        return userIsCreator || userIsContributor;
    };

    onResultSelection(item: EsploroRecord, index: number) {
        const selectedResearch: SelectedResearchRecord = {
            isInternalResults: this.isInternalResults,
            isUserCollaborator: this.isUserCollaborator(item),
            title: this.getTitle(item),
            creatorOrContributor: this.getCreatorOrContributor(
                item,
                this.isInternalResults
            ),
            publicationTitle: this.getPublicationTitle(item),
            publicationDate: this.getPublicationDate(item),
            resourcetypeEsploro:
                ResourceTypeUtils.convertEsploroRecordResourceTypeToCategoryAndType(
                    item.resourcetypeEsploro
                )?.getType(),
        };
        if (this.isInternalResults) {
            selectedResearch.internalRecordMmsId = this.internalMmsIds[index];
        } else {
            if (this.enrichedFromTitle) {
                this.depositSearchForService
                    .authorMatchingEnrichment(
                        JSON.stringify(item),
                        this.depositFormDataService.mmsId.value
                    )
                    .subscribe((data) => {
                        const esploroRecord = data as EsploroRecord;
                        this.depositFormDataService.authorMathcing(
                            esploroRecord
                        );
                    });
            }
            this.enrichForm(item);
        }
        this.selectedResearch.emit(selectedResearch);
        this.searchTerm.reset();
    }

    enrichForm(item: EsploroRecord) {
        this.depositFormDataService.populateForm(item, !this.enrichedFromTitle);
    }

    enrichRecord(value) {
        this.enrichedFromTitle = false;
        const s = value.trim();
        if (StringUtils.isDoiFormat(s)) {
            this.searchInProgress = true;
            this.doiEnrichment(s);
        } else if (StringUtils.isDoiUrlFormat(s)) {
            this.searchInProgress = true;
            this.doiEnrichment(StringUtils.doiFromUrl(s));
        } else if (StringUtils.regexDoiPartial.test(s)) {
            this.searchInProgress = true;
            this.doiEnrichment('10.' + s);
        } else if (this.pmidRegex.test(s)) {
            this.searchInProgress = true;
            this.pmidEnrichment(s);
        } else if (this.isbnRegex.test(s.replace(new RegExp('-', 'g'), ''))) {
            this.searchInProgress = true;
            this.isbnEnrichment(s);
        } else if (
            !value.startsWith('10.') &&
            !this.digitsPrefixRegex.test(value)
        ) {
            this.searchInProgress = true;
            this.depositFormDataService.updateTitle(value);
            this.titleEnrichment(value);
        }
    }

    handleEnrichment = (data: ExistsAssetsSearchResults) => {
        this.searchInProgress = false;
        this.isInternalResults = data?.internalEsploroRecords?.length > 0;
        const esploroRecord = this.isInternalResults
            ? data?.internalEsploroRecords
            : data?.externalEsploroRecords;
        if (this.isInternalResults) {
            this.internalMmsIds = data.internalMmsIds;
        }
        this.success(esploroRecord);
    };

    doiEnrichment(value) {
        this.depositSearchForService
            .enrichFromDoi(
                this.depositFormDataService.type.value,
                value,
                this.includeExternalSearch
            )
            .pipe(
                tap((data: ExistsAssetsSearchResults) => {
                    if (data?.externalEsploroRecords?.length) {
                        this.enrichDoiFromInput(
                            data?.externalEsploroRecords[0],
                            value
                        );
                    } else {
                        this.depositFormDataService.updateDoi(value);
                    }
                })
            )
            .subscribe(this.handleEnrichment);
    }

    enrichDoiFromInput(data, value) {
        if (!this.depositFormDataService.isPublicationBookChapter() || !data) {
            this.depositFormDataService.updateDoi(value);
        } else if (
            this.depositFormDataService.isPublicationBookChapter() &&
            data
        ) {
            const esploroRecord = data as EsploroRecord;
            const isPartOf = this.depositFormDataService.getIsPartOfRelation(
                esploroRecord
            ) as EsploroRelationship;
            if (
                esploroRecord.identifierDoi &&
                esploroRecord.identifierDoi === value
            ) {
                this.depositFormDataService.updateDoi(value);
            } else if (
                esploroRecord.relationship &&
                esploroRecord.relationship.length > 0 &&
                isPartOf
            ) {
                if (
                    isPartOf.partOfIdentifierDoi &&
                    isPartOf.partOfIdentifierDoi === value
                ) {
                    this.depositFormDataService.updateIsPartOfDoi(
                        isPartOf.partOfIdentifierDoi
                    );
                }
            }
        }
    }

    pmidEnrichment(value) {
        this.depositSearchForService
            .enrichFromPmid(
                this.depositFormDataService.type.value,
                value,
                this.includeExternalSearch
            )
            .subscribe(this.handleEnrichment);
    }

    isbnEnrichment(value) {
        this.depositSearchForService
            .enrichFromIsbn(
                this.depositFormDataService.type.value,
                value,
                this.includeExternalSearch
            )
            .subscribe(this.handleEnrichment);
    }

    titleEnrichment(value) {
        this.enrichedFromTitle = true;
        this.depositSearchForService
            .enrichFromTitle(
                this.depositFormDataService.type.value,
                value,
                this.includeExternalSearch,
                this.userId
            )
            .subscribe(this.handleEnrichment);
    }

    isEmptyObject(o) {
        return Object.keys(o).every(function (x) {
            return o[x].length === 0;
        });
    }

    parseResults(data: EsploroRecord[]) {
        this.searchResult = [];
        this.searchInProgress = false;
        data.forEach((result) => {
            this.searchResult.push(result);
        });
    }

    getTitle(result) {
        const title = result.title ? result.title : '';
        return title;
    }

    getPublicationDate(result) {
        let publicationDate = '';
        if (result.datePublished && result.datePublished.length >= 4) {
            publicationDate = result.datePublished.substring(0, 4);
        }
        return publicationDate;
    }

    getCreatorOrContributor(result, isInternal?: boolean) {
        const temporary = result.temporary;
        const collaboratorFilter = (creator) =>
            isInternal
                ? creator.identifierResearcherid || creator.almaId
                : creator.identifierResearcherid;
        if (temporary) {
            let creators = [];
            const creatorsArray = temporary.creators.filter(collaboratorFilter);
            if (creatorsArray && creatorsArray.length > 0) {
                creators = creatorsArray.map((creator) => {
                    return this.getName(creator);
                });
            }
            return creators.slice(0, 5).join(DepositForm.AUTHORS_DELIMITER);
        }
        return '';
    }

    getName(creator): string {
        const familyName = creator.familyName ? creator.familyName : '';
        const givenName = creator.givenName ? creator.givenName : '';
        return familyName + ' ' + givenName;
    }

    getResourceType(result) {
        return ResourceTypeUtils.convertEsploroRecordResourceTypeToCategoryAndType(
            result.resourcetypeEsploro
        )?.getType();
    }

    getPublicationTitle(result) {
        let publicationTitle = '';
        if (result.relationship && result.relationship.length > 0) {
            const relation = result.relationship[0];
            if (relation.relationtitle) {
                publicationTitle = relation.relationtitle;
            }
        }
        return publicationTitle;
    }

    success(data) {
        if (
            !data ||
            this.isEmptyObject(data) ||
            (Array.isArray(data) && this.isEmptyObject(data[0]))
        ) {
            this.error();
        } else {
            if (!Array.isArray(data)) {
                data = [data];
            }
            this.parseResults(data as EsploroRecord[]);
        }
    }

    error() {
        this.searchInProgress = false;
        this.searchResult = [];
        this.noResultsMessage = 'research.identify.droptext';
    }

    ngOnDestroy() {
        if (!this.searchForDestroy.isStopped) {
            this.searchForDestroy.next();
            //unsubscribe from the subject itself
            this.searchForDestroy.unsubscribe();
        }
    }
}
