import { LinksConstant } from './../configurations/links.constant';

export class ResearcherLink {
    url: string;
    title: boolean;
    description: boolean;
    hiddenFromPublicProfile: boolean;
    icon: string;

    constructor(url, title, description){
        this.url = url;
        this.title = title;
        this.description = description;
    }

    setHiddenFromPublicProfile(hiddenFromPublicProfile) {
        this.hiddenFromPublicProfile = hiddenFromPublicProfile;
    }

    setIcon(url: String){
        for (const key of Object.keys(LinksConstant .LINK_ICON_REGEX)) {
            if(url.match(LinksConstant.LINK_ICON_REGEX[key])) {
                this.icon = key;
                break;
            }
        }
    }
}
