import { User } from './user.model';
import { ResearchPersonData } from './research-person-data.model';
import { ResearchPersonChangedTrackerData } from './research-person-changed-tracker-data.model';

export class ResearchPerson {
    user: User;
    userId: number;
    position: string;
    researcherType: string;
    defaultPublicationLanguage: string;
    photoUrl: string;
    dataValue: string;
    checksum: string;
    data: ResearchPersonData;
    changedTrackerData: ResearchPersonChangedTrackerData;
    changedTrackerDataValue: string;

    portalField:
        | 'true'
        | 'false'
        | 'displayNoAssets'
        | 'researcherRequested'
        | 'refused';
    autoCaptureActive: string;
    autoCaptureSkipAutoApprove: string;
    researchCenterAccess: string;
    researchOrcidTrust: string;
    autoCaptureLastRun: string;
    urlIdentifier: string;
    profileImage: any;
    firstName: string;
    lastName: string;
    middleName: string;
    suffix: string;
    title: string;
    previousAffiliated: boolean;
    displayTitle: string;
    lastOrcidRecordUpdate: any;

    constructor(data: ResearchPersonData) {
        this.data = data;
    }

    public getData(): ResearchPersonData {
        return this.data;
    }
}
