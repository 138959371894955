import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { UrlUtils } from '../shared/utils/url.utils';
import { SEO } from '../shared/configurations/seo.constant';
import { JwtUtilService } from './jwt-util.service';
import { ConfigurationHandlerService } from './configuration-handler.service';

@Injectable({
    providedIn: 'root',
})
export class SEOService {
    institution = UrlUtils.getParam('institution');
    private renderer2: Renderer2;

    constructor(
        private title: Title,
        private meta: Meta,
        @Inject(DOCUMENT) private doc,
        private jwtService: JwtUtilService,
        private configSvc: ConfigurationHandlerService,
        rendererFactory: RendererFactory2
    ) {
        this.renderer2 = rendererFactory.createRenderer(null, null);
    }

    updateTitle(title: string) {
        this.title.setTitle(title);
    }

    updateDescription(desc: string): HTMLMetaElement {
        return this.updateMetaTag(SEO.DESCRIPTIION_META, desc);
    }

    addMetaTag(name: string, content: string): HTMLMetaElement {
        if (!content) {
            return;
        }
        return this.meta.addTag({ name, content });
    }

    updateMetaTag(name: string, desc: string): HTMLMetaElement {
        if (!desc) {
            return;
        }
        return this.meta.updateTag({ name, content: desc });
    }

    addPropertyMetaTag(property: string, content: string): HTMLMetaElement {
        if (!content) {
            return;
        }
        return this.meta.addTag({ property, content });
    }

    updatePropertyMetaTag(property: string, content: string): HTMLMetaElement {
        if (!content) {
            return;
        }
        return this.meta.updateTag({ property, content });
    }

    removeMetaTag(metaTag) {
        this.meta.removeTag(metaTag);
    }

    removeMetaTagElement(metaTag: HTMLMetaElement) {
        this.meta.removeTagElement(metaTag);
    }

    get canonicalUrl() {
        return (
            this.configSvc.getExternalFacingBaseUrl() +
            this.doc.location.pathname
        );
    }
    createLinkForCanonicalURL(href = this.canonicalUrl) {
        const canonicalProfile = this.doc.getElementById('canonicalProfile');
        if (canonicalProfile) {
            canonicalProfile.href = href;
        } else {
            const link = this.doc.createElement('link');
            link.setAttribute('rel', 'canonical');
            link.setAttribute('id', 'canonicalProfile');
            this.doc.head.appendChild(link);
            link.setAttribute('href', href);
        }
    }

    removeSchemaDotOrgScript() {
        const scriptElems = this.doc.head.querySelectorAll(
            'script[type="application/ld+json"]'
        );
        for (const s of scriptElems) {
            this.renderer2.removeChild(this.doc.head, s);
        }
    }

    createSchemaDotOrgScript(value: object) {
        const s = this.renderer2.createElement('script');
        s.type = 'application/ld+json';
        s.text = JSON.stringify(value);
        this.renderer2.appendChild(this.doc.head, s);
    }

    updatePageTitle(pageTitle: string) {
        const name = this.jwtService.getUserDisplayName();
        const instName = this.configSvc.getMainInstitutionName();
        const title = name
            ? `${name} - ${instName} ${pageTitle}`
            : `${instName} ${pageTitle}`;
        this.updateTitle(title);
    }
}
