import { NgModule } from '@angular/core';
import { AriaCalendarDirective } from './aria-calendar.directive';
import {
    AriaProgressBarDirective,
    AriaProgressSpinnerDirective,
} from '@exl-ng/mulo-common';
import { ListitemDescriptionA11yDirective } from './description/listitem-description-a11y.directive';
import {
    ActiveFirstItemDirective,
    EspMatAutocompleteA11yDirective,
} from './mat-autocomplete-extension.directive';
import { ScrollableRegionDirective } from './scrollable-region.directive';
import {
    EspMatTreeNode,
    EspNestedTreeNode,
    EspNodeContentDirective,
} from './esp-tree/esp-tree-node.directive';
import { EspMatTreeDirective } from './esp-tree/esp-tree.directive';
import { AriaSwiperDirective } from './aria-swiper.directive';

//do NOT put providers here
@NgModule({
    imports: [AriaProgressBarDirective, AriaProgressSpinnerDirective],
    declarations: [
        AriaCalendarDirective,
        ActiveFirstItemDirective,
        ScrollableRegionDirective,
        EspMatAutocompleteA11yDirective,
        ListitemDescriptionA11yDirective,
        EspMatTreeDirective,
        EspMatTreeNode,
        EspNestedTreeNode,
        EspNodeContentDirective,
        AriaSwiperDirective,
    ],
    exports: [
        AriaCalendarDirective,
        AriaProgressBarDirective,
        AriaProgressSpinnerDirective,
        ActiveFirstItemDirective,
        ScrollableRegionDirective,
        EspMatAutocompleteA11yDirective,
        ListitemDescriptionA11yDirective,
        EspMatTreeDirective,
        EspMatTreeNode,
        EspNestedTreeNode,
        EspNodeContentDirective,
        AriaSwiperDirective,
    ],
})
export class A11yEspModule {}
