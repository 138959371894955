import { Component, Input } from '@angular/core';

@Component({
    selector: 'mulo-separator',
    templateUrl: './separator.component.html',
    styleUrls: ['./separator.component.scss'],
    host: {
        class: 'mulo-separator',
        '[class.is-vertical]': 'orientation === "vertical"',
        '[class.is-horizontal]': 'orientation === "horizontal"',
        '[style.width]': 'width',
        '[style.height]': 'height',
    },
    standalone: true,
})
export class SeparatorComponent {
  @Input() orientation: 'vertical' | 'horizontal' = 'vertical';
  @Input() thickness: number;
  @Input() length: number;

  constructor() {}

  get height() {
    const length = this.length ? this.length / 16 + 'rem' : undefined;
    const thickness = this.length ? this.thickness + 'px' : undefined;

    return this.orientation === 'vertical' ? length : thickness;
  }

  get width() {
    const length = this.length ? this.length / 16 + 'rem' : undefined;
    const thickness = this.length ? this.thickness + 'px' : undefined;

    return this.orientation === 'vertical' ? thickness : length;
  }
}
