import { Component, Input } from '@angular/core';
import {
  PaginationControlsComponent,
  PaginationControlsDirective,
} from 'ngx-pagination';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { NgIf, NgFor, DecimalPipe } from '@angular/common';

function coerceToBoolean(input: string | boolean): boolean {
  return !!input && input !== 'false';
}

@Component({
    selector: 'mulo-paging',
    templateUrl: './paging.component.html',
    styleUrls: ['./paging.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatButton,
        MatIcon,
        NgFor,
        DecimalPipe,
    ],
})
export class PagingComponent extends PaginationControlsComponent {
  @Input('data') p: PaginationControlsDirective;
  @Input('directionLinksAsIcons') icons: boolean = false;
  @Input() hideLastPage;
  @Input() screenReaderJumpLabel = 'Jump to page {{value}}';

  constructor() {
    super();
  }

  _parseJumpLabel = (_) => this.screenReaderJumpLabel.replace('{{value}}', _);
}
