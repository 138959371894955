import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, shareReplay, tap, share } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

  private _lightLogoUrl = new BehaviorSubject<string>(null);
  private _darkLogoUrl = new BehaviorSubject<string>(null);
  private _logoPathUrl = new BehaviorSubject<string>(null);

  public lightLogoUrl$ = this._lightLogoUrl.asObservable();
  public darkLogoUrl$ = this._darkLogoUrl.asObservable();
  public logoPathUrl$ = this._logoPathUrl.asObservable();

  constructor() {
  }

  setLightLogo(url: string) {
    this._lightLogoUrl.next(url);
  }

  setDarkLogo(url: string) {
    this._darkLogoUrl.next(url);
  }

  setLogoPathUrl(url: string) {
    this._logoPathUrl.next(url);
  }
}
