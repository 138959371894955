import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {RestApiBase} from '../../../shared/configurations/rest-api-base.config';
import { UrlUtils } from '../../../shared/utils/url.utils';

@Injectable({
    providedIn: 'root',
})
export class DepositANZfieldsService {

    institutionCode: string;

    constructor(private http: HttpClient) {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    getResearchTopics(researchTopicLookup, purpose){
        let params = new HttpParams().set('institution', this.institutionCode);
        params = params.set('researchTopicLookup', researchTopicLookup);
        params = params.set('purpose', purpose);
        return this.http.get(RestApiBase.RESEARCH_TOPICS_ANZ, {params:params});
    }



}
