import {
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { FilePreviewDialogComponent } from '../../dialogs/file-preview-dialog/file-preview-dialog.component';
import { MatAccordionDisplayMode } from '@angular/material/expansion';
import { CdkAccordion } from '@angular/cdk/accordion';
import { FileListItemComponent } from './file-list-item/file-list-item.component';

@Component({
    selector: 'exl-file-list',
    templateUrl: './file-list.component.html',
    styleUrls: ['./file-list.component.scss'],
    animations: [
        trigger('scaleInOut', [
            transition(':enter', [
                style({
                    transform: 'scale(.8)',
                    opacity: 0,
                }),
                animate(
                    '600ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                    style({
                        transform: 'scale(1)',
                        opacity: 1,
                    })
                ),
            ]),
            transition(':leave', [
                style({
                    transform: 'scale(1)',
                    height: '*',
                    opacity: 1,
                }),
                animate(
                    '300ms cubic-bezier(0.895, 0.03, 0.685, 0.22)',
                    style({
                        transform: 'scale(.8)',
                        height: 0,
                        opacity: 0,
                    })
                ),
            ]),
        ]),
    ],
})
export class FileListComponent implements OnInit {
    @Input() listTitle: string;
    @Input() expandable: string;
    @Input() fileUploadProgress: boolean;
    @Input() expandAllLabel: string;
    @Input() collapseAllLabel: string;
    @Input() minimal: boolean;

    @Input() expandAllRows: boolean;
    @Output() expandAll: EventEmitter<boolean> = new EventEmitter();
    @Output() collapseAll: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('fileList') _fileList: CdkAccordion;
    @ContentChildren(FileListItemComponent)
    fileItems: QueryList<FileListItemComponent>;
    multiOpen = true;
    displayMode: MatAccordionDisplayMode = 'default';
    @Output() private removeItem: EventEmitter<any> = new EventEmitter();
    @Output() private removeAll: EventEmitter<any> = new EventEmitter();

    //allPanelsToggled = false;

    constructor(public filePreviewDialog: MatDialog) {}

    get isAllExpanded() {
        return !this.fileItems.some((_) => !_.expanded);
    }
    get itemIds(): string[] {
        return this.fileItems.map((_) => _._id);
    }
    ngOnInit() {
        if (this.expandAllRows) {
            this.onExpandCollapseAll(true);
        }
    }

    onExpandCollapseAll(setTo = !this.isAllExpanded) {
        if (setTo === true) {
            this.fileItems.forEach((_) => _.open());
            this.expandAll.emit();
        } else {
            this.fileItems.forEach((_) => _.close());
            this.collapseAll.emit();
        }
    }

    onRemoveFileClick(fileName, index) {
        // event.preventDefault();
        // event.stopPropagation();
        this.removeItem.emit({ index: index, fileName: fileName });
    }

    onPreviewClick(event, file) {
        event.preventDefault();
        event.stopPropagation();
        this.filePreviewDialog.open(FilePreviewDialogComponent, {
            data: {
                file: file,
            },
        });
    }

    onRemoveAll(event) {
        this.removeAll.emit(event);
    }
}
