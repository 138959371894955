import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';

@Component({
    selector: 'mulo-header-nav-item',
    templateUrl: './header-nav-item.component.html',
    styleUrls: ['./header-nav-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class HeaderNavItemComponent implements OnInit {
  @HostBinding('[style.display]') @Input() display: 'block' | 'inline-flex' = 'inline-flex';
  @HostBinding('class') class = 'mulo-header-nav-item';

  constructor() {}

  ngOnInit(): void {}
}
