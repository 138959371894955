<mat-form-field
    #fromDateFormfield
    *ngIf="fromDate"
    [class.is-autopopulated]="autoPopulatedFromDate"
    [class.mat-form-field-invalid]="error"
    class="exl-date-picker-input"
>
    <input
        (dateChange)="onDateChange()"
        *ngIf="dateStyle === 'full'"
        [formControl]="fromDate"
        [matDatepicker]="fromDatePicker"
        autocomplete="off"
        exlAutomationClass="from-date"
        matInput
    />
    <mat-label *ngIf="!fromDateTranslation">{{ fromDateLabel | customTranslate }}</mat-label>
    <mat-label *ngIf="fromDateTranslation">{{ fromDateTranslation }}</mat-label>
    <ng-container *ngIf="dateStyle === 'year'">
        <mat-select [formControl]="fromDate" exlAutomationClass="from-date">
            <mat-option *ngFor="let year of years" [value]="year">
                {{ year }}
            </mat-option>
        </mat-select>
    </ng-container>
    <mat-hint
        *ngIf="showHint && !error"
        [translateParams]="{ value0: currentDate }"
        [translate]="'research.status.date.hint'"
        align="start"
    ></mat-hint>
    <mat-icon
        *ngIf="autoPopulatedFromDate"
        [matTooltip]="'research.auto.populate.tooltip' | customTranslate"
        matPrefix
        svgIcon="auto-populate"
    ></mat-icon>
    <mat-datepicker-toggle
        #fromDateToggle
        *ngIf="usePicker"
        [for]="usePicker ? fromDatePicker : null"
        exlAutomationClass="from-datepicker"
        matSuffix
    ></mat-datepicker-toggle>
    <mat-datepicker #fromDatePicker></mat-datepicker>
    <div
        *ngIf="error"
        class="mat-mdc-form-field-subscript-wrapper"
        style="inset-block-start: 2rem; width: calc(100% + 21px)"
    >
        <span class="mat-error" role="alert">
            {{ 'research.status.error' | customTranslate }}
        </span>
    </div>
    <mat-error *ngIf="fromDate.invalid">{{ 'research.status.error' | customTranslate }}</mat-error>
    <mat-error *ngIf="rangeError">{{ 'research.settings.date.range.error' | translate }}</mat-error>
</mat-form-field>

<span *ngIf="range" class="daterange-arrow">
    <mat-icon class="mat-icon-rtl-mirror" svgIcon="arrow-right"></mat-icon>
</span>
<mat-form-field #toDateFormfield *ngIf="range" class="exl-date-picker-input">
    <input
        *ngIf="dateStyle === 'full'"
        [formControl]="toDate"
        [matDatepicker]="toDatePicker"
        autocomplete="off"
        exlAutomationClass="to-date"
        matInput
    />
    <mat-label *ngIf="!toDateTranslation">{{ toDateLabel | customTranslate }}</mat-label>
    <mat-label *ngIf="toDateTranslation">{{ toDateTranslation }}</mat-label>
    <ng-container *ngIf="dateStyle === 'year'">
        <mat-select [formControl]="toDate" exlAutomationClass="to-date">
            <mat-option *ngFor="let year of years" [value]="year">
                {{ year }}
            </mat-option>
        </mat-select>
    </ng-container>
    <mat-datepicker-toggle
        #toDateToggle
        *ngIf="usePicker"
        [for]="usePicker ? toDatePicker : null"
        exlAutomationClass="to-datepicker"
        matSuffix
    ></mat-datepicker-toggle>
    <mat-datepicker #toDatePicker></mat-datepicker>
    <mat-error *ngIf="toDate.invalid">{{ 'research.status.error' | customTranslate }}</mat-error>
</mat-form-field>
