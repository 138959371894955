<mulo-header-main-footer-layout>
    <esp-esploro-header *ngIf="!isLoginRoute" slot="layout-header" hideOnPrint></esp-esploro-header>

    <div [@routeAnimations]="animationState" slot="layout-main" class="router-outlet-wrapper">
        <router-outlet #outlet="outlet" (activate)="onActivate($event)"></router-outlet>
    </div>

    <esp-esploro-footer slot="layout-footer" *ngIf="!isLoginRoute" color="primary" hideOnPrint></esp-esploro-footer>
</mulo-header-main-footer-layout>

<mulo-sidenav id="main" closeButtonSide="right" closeButtonIcon="close" #mainSidenav hideOnPrint>
    <a
        class="sidenav-logo-link"
        slot="mulo-brand"
        [href]="logoUrl"
        (click)="mainSidenav.close()"
        [attr.aria-label]="'research.search.document.title' | translate : { value: institutionName }"
    >
        <mulo-brand></mulo-brand>
    </a>
</mulo-sidenav>
<mulo-sidenav id="secondary" closeButtonSide="right" closeButtonIcon="close" #secondSidenav hideOnPrint></mulo-sidenav>
