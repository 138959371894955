import {Component, AfterViewInit, Input, Output, ElementRef, EventEmitter, Inject, ViewChild} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { MatDrawerContent } from '@angular/material/sidenav';

@Component({
    selector: 'exl-navigable-text',
    templateUrl: './navigable-text.component.html',
    styleUrls: ['./navigable-text.component.scss'],
    host: { 'class': 'exl-navigable-text'}
})
export class NavigableTextComponent implements AfterViewInit {

    @Input() html: string;
    @Input() drawerOpened: boolean;
    @Output() drawerClosed: EventEmitter<boolean> = new EventEmitter();
    anchorsArray: Array<any> = new Array();
    anchorBaseName = 'anchor';

    @ViewChild(MatDrawerContent) drawerContent: MatDrawerContent;

    constructor(private elemRef: ElementRef,
                @Inject(DOCUMENT) private doc) { }

    ngAfterViewInit(){
        this.enableFocusOnContentIfNeeded();

        const nodes = this.elemRef.nativeElement.querySelectorAll('h2');
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            node.setAttribute('id', this.anchorBaseName + (i));
            this.anchorsArray.push(node.innerText);
        }
    }
    focusOnSection(event, index){
        event.preventDefault();
        const content = this.doc.getElementsByTagName('mat-drawer-content')[0];
        content.scrollTop = this.doc.getElementById(this.anchorBaseName + index).offsetTop;
    }

    onDrawerClosed() {
        this.drawerClosed.emit(null);
    }

    private enableFocusOnContentIfNeeded() {
        const contentElement = this.drawerContent.getElementRef().nativeElement;
        // Check if has a scrollbar. Needed for keyboard users because there is no focusable element.
        if (contentElement.scrollHeight > contentElement.clientHeight) {
            contentElement.setAttribute('tabIndex', '0');
        }
    }

}
