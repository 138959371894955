import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ImageCroppedEvent,
  ImageCropperComponent as ngxIC,
} from 'ngx-image-cropper';
import { LoaderComponent } from '../loader';

@Component({
  selector: 'mulo-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  standalone: true,
  imports: [ngxIC, LoaderComponent],
})
export class ImageCropperComponent implements OnChanges {
  @Input() file;
  @Input() labelPreview = 'Preview:';
  @Input() labelCroppedImageAlt = 'Cropped image preview';
  @Input() labelImageAlt = 'Cropping image';

  @Output() imageOutput: EventEmitter<any> = new EventEmitter();
  @Output() loadedOutput: EventEmitter<any> = new EventEmitter();

  croppedImage: any = '';
  cropperReady = false;
  base64Image = '';

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.file) {
      this.fileChangeEvent(this.file);
    }
  }

  fileChangeEvent(file: any): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event: any) => {
      this.base64Image = event.target.result;
      this.croppedImage = this.base64Image;
    };
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.imageOutput.emit(event);
  }

  imageLoaded() {
    this.cropperReady = true;
    this.loadedOutput.emit(true);
  }

  loadImageFailed() {
    console.error('Load failed');
  }
}
