import { Component, OnInit, HostBinding, Input } from '@angular/core';
import {GrowInAnimation, GrowOutAnimation} from '@exl-ng/mulo-common';

let nextUniqId = 0;

@Component({
    selector: 'esp-deposit-form-section',
    templateUrl: './deposit-form-section.component.html',
    styleUrls: ['./deposit-form-section.component.scss'],
    host: {
        '[id]': 'sectionId',
        'class': 'esp-deposit-form-section',
        '[attr.role]':'role',
        '[attr.aria-labelledby]':'titleId',
        '[attr.aria-describedby]':'descriptionId'
     },
    animations: [
        GrowInAnimation,
        GrowOutAnimation
    ]
})
export class DepositFormSectionComponent implements OnInit {

    // bind animation with parameters to the host, instead of adding in HTML to each instance (saves multiple imports of animation)
    // @HostBinding('@growInAnimation') get animIn() { return { value: 'enter', params: { time: '.6s', startScale: '.7' }};}
    // @HostBinding('@growOutAnimation') get animOut() { return { value: 'enter', params: { time: '.6s', endScale: '.8' }};}

    private _id: string;
    @Input() role =  'group';
    @Input() sectionTitle: string;
    @Input() description: string;
    @Input() required: boolean;

    constructor() {
        this._id = `${nextUniqId++}`;
     }

     get sectionId() {
         return `deposit-section-${this._id}`;
     }

     get titleId() {
         return this.sectionTitle ? `deposit-section-title-${this._id}` : null;
     }

     get descriptionId() {
        return this.description ? `deposit-section-description-${this._id}` : null;
    }

    ngOnInit() {
    }

}
