<div
  class="guide-text-anchor"
  [matMenuTriggerFor]="guideText"
  #guideTextTrigger="matMenuTrigger"
  [style.margin-left]="offsetTextPosition"
></div>
<mat-menu
  #guideText="matMenu"
  class="mulo-onboarding-text-panel theme-palette-{{color}}"
  (closed)="onGuideTextClosed()"
  backdropClass="theme-palette-{{color}}"
>
  <div class="onboarding-guide-content" [style.text-align]="align">
    <div [innerHTML]="text"></div>
    <button mat-stroked-button class="is-large onboarding-guide-button">
      {{buttonText}}
    </button>
  </div>
</mat-menu>
