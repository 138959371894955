<button
    mat-raised-button
    class="is-funky-cool"
    (click)="onButtonClick()"
    [style.width]="nextButtonWidth"
    [disabled]="
        disabled ||
        (selectedStep === LAST_STEP && depositFormDataService.mainForm.status === 'INVALID') ||
        (selectedStep === LAST_STEP && !depositFormDataService.termsOfUse.value)
    "
    [ngClass]="{
        'is-hidden': nextButtonHidden || disabled,
        'is-last': selectedStep + 1 == steps?.length - 1,
        'is-rounded': rounded,
        'is-loading': loading
    }"
>
    <ng-container *ngFor="let step of steps; let i = index">
        <span
            class="button-content"
            *ngIf="selectedStep + 1 == step.pos"
            [@slideInOut]="selectedStep"
            (@slideInOut.start)="slideStart($event)"
            #nextButton
        >
            <span *ngIf="step.pos < steps?.length - 2 && !loading">
                {{ 'research.top.step' | translate : { value0: step.pos + 1, value1: steps.length - 1 } }}&nbsp;
            </span>
            <span *ngIf="step.pos === steps?.length - 2 && !loading">
                {{ 'research.top.step4top' | translate }}&nbsp;
            </span>
            <span *ngIf="!loading" class="step-title">{{ step.title | translate }}</span>
        </span>
    </ng-container>
    <mat-icon
        class="mat-icon-rtl-mirror"
        svgIcon="chevron-right"
        *ngIf="selectedStep + 1 < steps?.length - 1"
        [@IconInOut]
    >
    </mat-icon>
    <mat-icon
        class="mat-icon-rtl-mirror"
        svgIcon="send"
        *ngIf="selectedStep + 1 == steps?.length - 1 && !loading"
        [@IconInOut]
    >
    </mat-icon>
    <mat-spinner *ngIf="loading" diameter="30" [@SpinnerIn]></mat-spinner>
</button>
