import { Injectable } from '@angular/core';
import {
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { EsploroRecord } from '../shared/interfaces/esploro-record.model';
import {
    SelectedResearchTopic,
    TopicType,
} from '../shared/interfaces/selected-research-topic.model';
import { DepositForm } from '../shared/configurations/deposit-form.constant';
import { EsploroRelationship } from '../shared/interfaces/esploro-relationship.interface';
import { DepositPublicationDetailsService } from './deposit-sections/deposit-status-details/deposit-publication-details.service';
import { CZSearchResult } from '../shared/interfaces/cz-search-result.interface';
import { PDFMetadata } from '../shared/interfaces/pdf-metadata.interface';
import { Status } from '../shared/configurations/status.constant';
import { ValidateDate } from '../shared/validators/date.validator';
import { GrobidService } from '../parts/handle-files/file-upload/grobid.service';
import { ResourceTypeUtils } from '../shared/utils/resource-type.utils';
import { DepositLanguageService } from './deposit-sections/deposit-language/deposit-language.service';
import { validateHorizontalRange } from '../shared/validators/horizontal-range.validator';
import { validateNumberFormat } from '../shared/validators/number.validator';
import { validateVerticalRange } from '../shared/validators/vertical-range.validator';
import { validateSpatialMandatoryFields } from '../shared/validators/spatial-mandatory-fields.validator';
import { DepositStepThreeService } from './deposit-steps/deposit-step-three/deposit-step-three.service';
import { EsploroGeoLocationAddress } from '../shared/interfaces/esploro-geo-location-address.interface';
import { AutoPopulateKeys } from '../shared/configurations/auto-populate-keys.config';
import {
    ANZTopic,
    ResearchTopic,
} from '../shared/interfaces/research-topic.intreface';
import { DateRange } from '../shared/interfaces/date-range.model';
import { Link } from '../shared/interfaces/link.model';
import { EsploroGeoLocationBox } from '../shared/interfaces/esploro-geo-location-box.interface';
import { EsploroGeoLocationPoint } from '../shared/interfaces/esploro-geo-location-point.interface';
import { ResearcherAsset } from '../shared/interfaces/researcher-asset.interface';
import { User } from '../shared/interfaces/user.model';
import { StringUtils } from '../shared/utils/string.utils';
import {
    CreatorContributor,
    CreatorContributorType,
} from '../shared/interfaces/creator-contributor.interface';
import { Grant } from '../shared/interfaces/grant.model';
import { SortUtils } from '../shared/utils/sort.utils';
import { File } from '../shared/interfaces/file.model';
import { FileUtils } from '@exl-ng/mulo-core';
import { JwtUtilService } from '../core/jwt-util.service';
import { DepositLastStepService } from './deposit-steps/deposit-last-step/deposit-last-step.service';
import { validatePmidFormat } from '../shared/validators/pmid.validator';
import { validateDoiFormat } from '../shared/validators/doi.validator';
import { validateIssnFormat } from '../shared/validators/issn.validator';
import { EsploroEvent } from '../shared/interfaces/esploro-event.model';
import { EsploroCreator } from '../shared/interfaces/esploro-creator.interface';
import { EsploroCreatorStatus } from '../shared/interfaces/esploro-author.interface';

@Injectable({
    providedIn: 'root',
})
export class DepositFormDataService {
    resourceTypeTemplates = [
        'publication.journalArticle',
        'publication.abstract',
        'publication.conferenceProceeding',
        'publication.bookChapter',
        'publication.bookReview',
        'dataset.dataset',
        'publication.book',
        'conference.conferencePaper',
        'conference.conferencePresentation',
        'conference.conferencePoster',
        'conference.conferenceProgram',
        'conference.presentation',
        'conference.eventposter',
    ];
    mainForm: UntypedFormGroup;
    filesForm: UntypedFormArray;
    deletedStorageFilesNames = {};
    invalidFiles = [];
    yearOnlyRegex = new RegExp('^[0-9]{4}$');
    fullDateRegex = new RegExp('^[0-9]{8}$');
    DATE_DELIMITER = ' - ';

    constructor(
        private formBuilder: UntypedFormBuilder,
        private depositCitationService: DepositPublicationDetailsService,
        private grobidService: GrobidService,
        private depositLanguageService: DepositLanguageService,
        private depositStepThreeService: DepositStepThreeService,
        private depositLastStepService: DepositLastStepService,
        private jwtUtilService: JwtUtilService,
    ) {
        this.createForm();
        this.filesForm = this.formBuilder.array([]);
    }

    _autoPopulatedFields = [];

    get autoPopulatedFields() {
        return this._autoPopulatedFields;
    }

    _keywordsAutoPopulated = [];

    get keywordsAutoPopulated(): any[] {
        return this._keywordsAutoPopulated;
    }

    set keywordsAutoPopulated(value: any[]) {
        this._keywordsAutoPopulated = value;
    }

    _languagesAutoPopulated = [];

    get languagesAutoPopulated(): string[] {
        return this._languagesAutoPopulated;
    }

    set languagesAutoPopulated(value: string[]) {
        this._languagesAutoPopulated = value;
    }

    _authorsAutoPopulated = [];

    get authorsAutoPopulated(): string[] {
        return this._authorsAutoPopulated;
    }

    set authorsAutoPopulated(value: string[]) {
        this._authorsAutoPopulated = value;
    }

    _linksAutoPopulated = [];

    get linksAutoPopulated(): string[] {
        return this._linksAutoPopulated;
    }

    set linksAutoPopulated(value: string[]) {
        this._linksAutoPopulated = value;
    }

    _enrichForm = false;

    get enrichForm(): boolean {
        return this._enrichForm;
    }

    set enrichForm(value: boolean) {
        this._enrichForm = value;
    }

    _startPopulating = false;

    get startPopulating(): boolean {
        return this._startPopulating;
    }

    set startPopulating(value: boolean) {
        this._startPopulating = value;
    }

    _isDraft = false;

    get isDraft(): boolean {
        return this._isDraft;
    }

    set isDraft(value: boolean) {
        this._isDraft = value;
    }

    get filePath() {
        return this.mainForm.get('filePath');
    }

    get mmsId() {
        return this.mainForm.get('mmsId');
    }

    get topics(): UntypedFormArray {
        return this.mainForm.get('topics') as UntypedFormArray;
    }

    get files(): UntypedFormArray {
        return this.filesForm;
    }

    get links(): UntypedFormArray {
        return this.mainForm.get('links') as UntypedFormArray;
    }

    get languages(): UntypedFormArray {
        return this.mainForm.get('languages') as UntypedFormArray;
    }

    get keywords(): UntypedFormArray {
        return this.mainForm.get('keywords') as UntypedFormArray;
    }

    get subjects(): UntypedFormArray {
        return this.mainForm.get('subjects') as UntypedFormArray;
    }

    get forSubject(): UntypedFormArray {
        return this.mainForm.get('forSubject') as UntypedFormArray;
    }

    get seoSubject(): UntypedFormArray {
        return this.mainForm.get('seoSubject') as UntypedFormArray;
    }

    get toaSubject() {
        return this.mainForm.get('toaSubject');
    }

    get authors(): UntypedFormArray {
        return this.mainForm.get('authors') as UntypedFormArray;
    }

    get category() {
        return this.mainForm.get('category');
    }

    get type() {
        return this.mainForm.get('type');
    }

    get isPartOfIsbnForDisplay() {
        return this.mainForm.get('isPartOfIsbnForDisplay');
    }

    get isbnForDisplay() {
        return this.mainForm.get('isbnForDisplay');
    }

    get isPartOfVolume() {
        return this.mainForm.get('isPartOfVolume');
    }

    get title() {
        return this.mainForm.get('title');
    }

    get subtitle() {
        return this.mainForm.get('subtitle');
    }

    get edition() {
        return this.mainForm.get('edition');
    }

    get pages() {
        return this.mainForm.get('pages');
    }

    get series() {
        return this.mainForm.get('series');
    }

    get format() {
        return this.mainForm.get('format');
    }

    get eventName() {
        return this.mainForm.get('eventName');
    }

    get eventLocation() {
        return this.mainForm.get('eventLocation');
    }

    get eventType() {
        return this.mainForm.get('eventType');
    }

    get publicationPlace() {
        return this.mainForm.get('publicationPlace');
    }

    get publicationPlaceForDisplay() {
        return this.mainForm.get('publicationPlaceForDisplay');
    }

    get audience() {
        return this.mainForm.get('audience');
    }

    get isPartOfIssue() {
        return this.mainForm.get('isPartOfIssue');
    }

    get isPartOfDoi() {
        return this.mainForm.get('isPartOfDoi');
    }

    get doi() {
        return this.mainForm.get('doi');
    }

    get pmid() {
        return this.mainForm.get('pmid');
    }

    // updateAudienceForDisplay(audienceForDisplay){
    //     this.mainForm.patchValue({audienceForDisplay:audienceForDisplay});
    // }

    get isbn(): UntypedFormArray {
        return this.mainForm.get('isbn') as UntypedFormArray;
    }

    get eisbn(): UntypedFormArray {
        return this.mainForm.get('eisbn') as UntypedFormArray;
    }

    get isPartOfIsbn(): UntypedFormArray {
        return this.mainForm.get('isPartOfIsbn') as UntypedFormArray;
    }

    get isPartOfEisbn(): UntypedFormArray {
        return this.mainForm.get('isPartOfEisbn') as UntypedFormArray;
    }

    get publisher() {
        return this.mainForm.get('publisher');
    }

    get conferenceName() {
        return this.mainForm.get('conferenceName');
    }

    get conferenceNumber() {
        return this.mainForm.get('conferenceNumber');
    }

    get conferenceLocation() {
        return this.mainForm.get('conferenceLocation');
    }

    get relationTitle() {
        return this.mainForm.get('relationTitle');
    }

    get status() {
        return this.mainForm.get('status');
    }

    get date() {
        return this.mainForm.get('date');
    }

    get eventStartDate() {
        return this.mainForm.get('eventStartDate');
    }

    get eventEndDate() {
        return this.mainForm.get('eventEndDate');
    }

    get datePublished() {
        return this.mainForm.get('datePublished');
    }

    get seasonPublished() {
        return this.mainForm.get('seasonPublished');
    }

    get datePresented() {
        return this.mainForm.get('datePresented');
    }

    get yearPresented() {
        return this.mainForm.get('yearPresented');
    }

    get monthPresented() {
        return this.mainForm.get('monthPresented');
    }

    get dayPresented() {
        return this.mainForm.get('dayPresented');
    }

    get dateCopyrighted() {
        return this.mainForm.get('dateCopyrighted');
    }

    get dateCreatedStart() {
        return this.mainForm.get('dateCreatedStart');
    }

    get dateCreatedEnd() {
        return this.mainForm.get('dateCreatedEnd');
    }

    get dateCollectedStart() {
        return this.mainForm.get('dateCollectedStart');
    }

    get dateCollectedEnd() {
        return this.mainForm.get('dateCollectedEnd');
    }

    get conferenceStartDate() {
        return this.mainForm.get('conferenceStartDate');
    }

    get conferenceStartDay() {
        return this.mainForm.get('conferenceStartDay');
    }

    get conferenceStartMonth() {
        return this.mainForm.get('conferenceStartMonth');
    }

    get conferenceStartYear() {
        return this.mainForm.get('conferenceStartYear');
    }

    get conferenceEndDate() {
        return this.mainForm.get('conferenceEndDate');
    }

    get conferenceEndDay() {
        return this.mainForm.get('conferenceEndDay');
    }

    get conferenceEndMonth() {
        return this.mainForm.get('conferenceEndMonth');
    }

    get conferenceEndYear() {
        return this.mainForm.get('conferenceEndYear');
    }

    get isPartOfPages() {
        return this.mainForm.get('isPartOfPages');
    }

    get isPartOfIssn() {
        return this.mainForm.get('isPartOfIssn');
    }

    get peerReviewed() {
        return this.mainForm.get('peerReviewed');
    }

    get description() {
        return this.mainForm.get('description') as UntypedFormControl;
    }

    get methods() {
        return this.mainForm.get('methods') as UntypedFormControl;
    }

    get coverage() {
        return this.mainForm.get('coverage') as UntypedFormControl;
    }

    get technicalInformation() {
        return this.mainForm.get('technicalInformation') as UntypedFormControl;
    }

    get enableAddress() {
        return this.mainForm.get('enableAddress') as UntypedFormControl;
    }

    get enablePoint() {
        return this.mainForm.get('enablePoint') as UntypedFormControl;
    }

    get enableBoundingBox() {
        return this.mainForm.get('enableBoundingBox') as UntypedFormControl;
    }

    get address() {
        return this.mainForm.get('address') as UntypedFormControl;
    }

    get pointLatitude() {
        return this.mainForm.get('pointLatitude') as UntypedFormControl;
    }

    get pointLongitude() {
        return this.mainForm.get('pointLongitude') as UntypedFormControl;
    }

    get boundingBoxSouth() {
        return this.mainForm.get('boundingBoxSouth') as UntypedFormControl;
    }

    get boundingBoxWest() {
        return this.mainForm.get('boundingBoxWest') as UntypedFormControl;
    }

    get boundingBoxNorth() {
        return this.mainForm.get('boundingBoxNorth') as UntypedFormControl;
    }

    get boundingBoxEast() {
        return this.mainForm.get('boundingBoxEast') as UntypedFormControl;
    }

    get grants(): UntypedFormArray {
        return this.mainForm.get('grants') as UntypedFormArray;
    }

    get note() {
        return this.mainForm.get('note') as UntypedFormControl;
    }

    get copyright() {
        return this.mainForm.get('copyright') as UntypedFormControl;
    }

    get isReservedDOI() {
        return this.mainForm.get('isReservedDOI');
    }

    get isRegisteredDOI() {
        return this.mainForm.get('isRegisteredDOI');
    }

    get researcherReservedDoi() {
        return this.mainForm.get('researcherReservedDoi');
    }

    get cleanReservedRegisterDoi() {
        return this.mainForm.get('cleanReservedRegisterDoi');
    }

    get resourceType() {
        return this.type.value;
    }

    get resourceTypeTemplate() {
        let template = this.resourceType;
        if (this.resourceTypeTemplates.indexOf(template) === -1) {
            template = DepositForm.DEFAULT_TYPE;
        }
        return template;
    }

    get termsOfUse() {
        return this.mainForm.get('termsOfUse') as UntypedFormControl;
    }

    createForm() {
        this.mainForm = this.formBuilder.group(
            {
                owner: '',
                proxy: '',
                proxyName: '',
                userPrimaryID: '',
                mmsId: '',
                title: ['', Validators.required],
                subtitle: '',
                category: '',
                type: ['', Validators.required],
                doi: ['', [validateDoiFormat]],
                pmid: ['', [validatePmidFormat]],
                issn: '',
                eissn: '',
                pmcid: '',
                isbn: this.formBuilder.array([]),
                isbnForDisplay: '',
                eisbn: this.formBuilder.array([]),
                edition: '',
                pages: '',
                series: '',
                seriesNumber: '',
                publicationPlace: this.formBuilder.array([]),
                publicationPlaceForDisplay: '',
                filePath: '',
                keywords: this.formBuilder.array([]),
                subjects: this.formBuilder.array([]),
                forSubject: this.formBuilder.array([]),
                seoSubject: this.formBuilder.array([]),
                toaSubject: '',
                topics: this.formBuilder.array([]),
                authors: this.formBuilder.array([]),
                comments: this.formBuilder.array([]),
                description: '',
                methods: '',
                coverage: '',
                technicalInformation: '',
                localFields: '',
                format: this.formBuilder.array([]),
                audience: '', //this.formBuilder.array([]),
                //audienceForDisplay: '',
                links: this.formBuilder.array([]),
                languages: this.formBuilder.array([]),
                peerReviewed: 'UNKNOWN',
                openAccess: 'UNKNOWN',
                openAccessStatus: '',
                copyright: '',
                eventName: '',
                eventLocation: '',
                eventType: '',
                eventStartDate: '',
                eventStartYear: '',
                eventStartMonth: '',
                eventStartDay: '',
                eventEndDate: '',
                eventEndYear: '',
                eventEndMonth: '',
                eventEndDay: '',
                status: '',
                date: '',
                datePublished: '',
                dateCreatedStart: '',
                dateCreatedEnd: '',
                dateCollectedStart: '',
                dateCollectedEnd: '',
                datePresented: '',
                year: '',
                month: '',
                day: '',
                seasonPublished: '',
                yearPresented: '',
                monthPresented: '',
                dayPresented: '',
                dateCopyrighted: '',
                yearCopyrighted: '',
                monthCopyrighted: '',
                dayCopyrighted: '',
                yearCreatedStart: '',
                monthCreatedStart: '',
                dayCreatedStart: '',
                yearCreatedEnd: '',
                monthCreatedEnd: '',
                dayCreatedEnd: '',
                yearCollectedStart: '',
                monthCollectedStart: '',
                dayCollectedStart: '',
                yearCollectedEnd: '',
                monthCollectedEnd: '',
                dayCollectedEnd: '',
                publisher: '',
                conferenceName: '',
                conferenceNumber: '',
                conferenceLocation: '',
                conferenceStartDate: '',
                conferenceEndDate: '',
                conferenceStartDay: '',
                conferenceStartMonth: '',
                conferenceStartYear: '',
                conferenceEndDay: '',
                conferenceEndMonth: '',
                conferenceEndYear: '',
                relationTitle: '',
                isPartOfDoi: ['', [validateDoiFormat]],
                isPartOfIssn: ['', [validateIssnFormat]],
                isPartOfEissn: '',
                isPartOfNlmAbbrev: '',
                isPartOfIsbn: this.formBuilder.array([]),
                isPartOfIsbnForDisplay: '',
                isPartOfEisbn: this.formBuilder.array([]),
                isPartOfVolume: '',
                isPartOfIssue: '',
                isPartOfPages: '',
                titleAlternative: this.formBuilder.array([]),
                titleOther: this.formBuilder.array([]),
                titleTranslated: this.formBuilder.array([]),
                enableAddress: false,
                enablePoint: false,
                enableBoundingBox: false,
                address: '',
                size: this.formBuilder.array([]),
                descriptionOther: this.formBuilder.array([]),
                resourceSubtype: '',
                pointLatitude: [
                    '',
                    [validateNumberFormat, validateVerticalRange],
                ],
                pointLongitude: [
                    '',
                    [validateNumberFormat, validateHorizontalRange],
                ],
                boundingBoxSouth: [
                    '',
                    [validateNumberFormat, validateVerticalRange],
                ],
                boundingBoxWest: [
                    '',
                    [validateNumberFormat, validateHorizontalRange],
                ],
                boundingBoxNorth: [
                    '',
                    [validateNumberFormat, validateVerticalRange],
                ],
                boundingBoxEast: [
                    '',
                    [validateNumberFormat, validateHorizontalRange],
                ],
                grants: this.formBuilder.array([]),
                note: '',
                termsOfUse: [
                    false,
                    [Validators.required, Validators.requiredTrue],
                ],
                isReservedDOI: false,
                isRegisteredDOI: false,
                researcherReservedDoi: false,
                cleanReservedRegisterDoi: false,
            },
            { validators: [ValidateDate, validateSpatialMandatoryFields] },
        );
    }

    addDeletedStorageFilesNames(file: File) {
        this.deletedStorageFilesNames[file.fullName] = file.mid;
    }

    updateCategory(category) {
        this.mainForm.patchValue({ category });
    }

    updateType(type) {
        this.mainForm.patchValue({ type });
    }

    updateMmsId(mmsId, emitEvent = true) {
        this.mainForm.patchValue({ mmsId }, { emitEvent });
    }

    updateFilePath(filePath) {
        this.mainForm.patchValue({ filePath });
    }

    updateDoi(doi) {
        this.mainForm.patchValue({ doi });
        if (!this.startPopulating) {
            this.removeKeyFromAutoPopulatedArray(AutoPopulateKeys.DOI);
        }
    }

    updateAddress(address) {
        this.mainForm.patchValue({ address });
    }

    updateCopyright(copyright) {
        this.mainForm.patchValue({ copyright });
    }

    updatePointLatitude(pointLatitude) {
        this.mainForm.patchValue({ pointLatitude });
    }

    updatePointLongitude(pointLongitude) {
        this.mainForm.patchValue({ pointLongitude });
    }

    updateBoundingBoxSouth(boundingBoxSouth) {
        this.mainForm.patchValue({ boundingBoxSouth });
    }

    updateBoundingBoxWest(boundingBoxWest) {
        this.mainForm.patchValue({ boundingBoxWest });
    }

    updateBoundingBoxNorth(boundingBoxNorth) {
        this.mainForm.patchValue({ boundingBoxNorth });
    }

    updateBoundingBoxEast(boundingBoxEast) {
        this.mainForm.patchValue({ boundingBoxEast });
    }

    updateEnableAddress(enableAddress) {
        this.mainForm.patchValue({ enableAddress });
    }

    updateEnablePoint(enablePoint) {
        this.mainForm.patchValue({ enablePoint });
    }

    updateEnableBox(enableBoundingBox) {
        this.mainForm.patchValue({ enableBoundingBox });
    }

    updatePmid(pmid) {
        this.mainForm.patchValue({ pmid });
    }

    updatePmcid(pmcid) {
        this.mainForm.patchValue({ pmcid });
    }

    updateIssn(issn) {
        this.mainForm.patchValue({ issn });
    }

    updateEdition(edition) {
        this.mainForm.patchValue({ edition });
    }

    updatePages(pages) {
        this.mainForm.patchValue({ pages });
    }

    updateResourceSubtype(resourceSubtype) {
        this.mainForm.patchValue({ resourceSubtype });
    }

    updateSize(size) {
        this.mainForm.setControl('size', this.formBuilder.array(size));
    }

    updateDescriptionOther(descriptionOther) {
        this.mainForm.setControl(
            'descriptionOther',
            this.formBuilder.array(descriptionOther),
        );
    }

    updateSeries(series) {
        this.mainForm.patchValue({ series });
    }

    updateSeriesNumber(seriesNumber) {
        this.mainForm.patchValue({ seriesNumber });
    }

    updatePublicationPlace(publicationPlace) {
        this.mainForm.setControl(
            'publicationPlace',
            this.formBuilder.array(publicationPlace),
        );
    }

    updatePublicationPlaceForDisplay(publicationPlaceForDisplay) {
        this.mainForm.patchValue({ publicationPlaceForDisplay });
    }

    updateAudience(audience) {
        this.mainForm.patchValue({ audience });
        //this.mainForm.setControl('audience', this.formBuilder.array(audience));
    }

    updateSubtitle(subtitle) {
        this.mainForm.patchValue({ subtitle });
    }

    updateEventName(eventName) {
        this.mainForm.patchValue({ eventName });
    }

    updateEventLocation(eventLocation) {
        this.mainForm.patchValue({ eventLocation });
    }

    updateEventType(eventType) {
        this.mainForm.patchValue({ eventType });
    }

    updateEventStartDate(eventStartDate) {
        this.mainForm.patchValue({ eventStartDate });
    }

    updateEventStartYear(eventStartYear) {
        this.mainForm.patchValue({ eventStartYear });
    }

    updateEventStartMonth(eventStartMonth) {
        this.mainForm.patchValue({ eventStartMonth });
    }

    updateEventStartDay(eventStartDay) {
        this.mainForm.patchValue({ eventStartDay });
    }

    updateEventEndDate(eventEndDate) {
        this.mainForm.patchValue({ eventEndDate });
    }

    updateEventEndYear(eventEndYear) {
        this.mainForm.patchValue({ eventEndYear });
    }

    updateEventEndMonth(eventEndMonth) {
        this.mainForm.patchValue({ eventEndMonth });
    }

    updateEventEndDay(eventEndDay) {
        this.mainForm.patchValue({ eventEndDay });
    }

    updateEissn(eissn) {
        this.mainForm.patchValue({ eissn });
    }

    updatePeerReviewed(peerReviewed) {
        this.mainForm.patchValue({ peerReviewed });
    }

    updateFormat(format) {
        this.mainForm.setControl('format', this.formBuilder.array(format));
    }

    updateLinkDescription(index, description) {
        const link = this.links.at(index) as UntypedFormGroup;
        link.setControl('description', new UntypedFormControl(description));
    }

    updateLinkType(index, type) {
        const link = this.links.at(index) as UntypedFormGroup;
        link.setControl('type', new UntypedFormControl(type));
    }

    updateLinkLicense(index, license) {
        const link = this.links.at(index) as UntypedFormGroup;
        link.setControl('license', new UntypedFormControl(license));
    }

    updateFileDescription(index, description) {
        const file = this.filesForm.at(index) as UntypedFormGroup;
        file.setControl('description', new UntypedFormControl(description));
    }

    updateFileType(index, type) {
        const file = this.filesForm.at(index) as UntypedFormGroup;
        file.setControl('type', new UntypedFormControl(type));
    }

    updateFileLicense(index, license) {
        const file = this.filesForm.at(index) as UntypedFormGroup;
        file.setControl('license', new UntypedFormControl(license));
    }

    updateFileAccess(index, access) {
        const file = this.filesForm.at(index) as UntypedFormGroup;
        file.setControl('access', new UntypedFormControl(access.selected));
    }

    updateFileAccessReason(index, access) {
        const file = this.filesForm.at(index) as UntypedFormGroup;
        const reason = access.reason ? access.reason : '';
        file.setControl('accessRightsReason', new UntypedFormControl(reason));
    }

    updateFileAccessDate(index, date, year, month, day) {
        const file = this.filesForm.at(index) as UntypedFormGroup;
        file.setControl('accessRightsFixedDate', new UntypedFormControl(date));
        file.setControl('accessRightsFixedYear', new UntypedFormControl(year));
        file.setControl(
            'accessRightsFixedMonth',
            new UntypedFormControl(month),
        );
        file.setControl('accessRightsFixedDay', new UntypedFormControl(day));
    }

    updateOpenAccess(openAccess) {
        this.mainForm.patchValue({ openAccess });
    }

    updateOpenAccessStatus(openAccessStatus) {
        this.mainForm.patchValue({ openAccessStatus });
    }

    updateTitle(title) {
        this.mainForm.patchValue({ title });
    }

    updateLinks(link) {
        this.links.push(this.formBuilder.group(link));
    }

    updateIsReservedDOI(isReservedDOI) {
        this.mainForm.patchValue({ isReservedDOI });
    }

    updateIsRegisteredDOI(isRegisteredDOI) {
        this.mainForm.patchValue({ isRegisteredDOI });
    }

    updateResearcherReservedDoi(researcherReservedDoi) {
        this.mainForm.patchValue({ researcherReservedDoi });
    }

    updateCleanReservedRegisterDoi(cleanReservedRegisterDoi) {
        this.mainForm.patchValue({ cleanReservedRegisterDoi });
    }

    handleDoiReserved(doi: string) {
        this.updateDoi(doi);
        this.updateResearcherReservedDoi(true);
        this.updateIsReservedDOI(true);
    }

    handleDoiDismissed() {
        this.updateDoi('');
        this.updateCleanReservedRegisterDoi(true);
        this.updateIsRegisteredDOI(false);
        this.updateIsReservedDOI(false);
        this.updateResearcherReservedDoi(false);
    }

    isFileInUploadingProcess() {
        const uploadingFiles = this.filesForm.value.filter(
            (file) => file.uploadProgress < 100,
        );
        if (uploadingFiles.length > 0) {
            return true;
        }
        return false;
    }

    isReservedOrRegisteredDoi() {
        return this.isReservedDOI.value || this.isRegisteredDOI.value;
    }

    removeTopic(index) {
        this.topics.removeAt(index);
    }

    removeFile(index) {
        this.files.removeAt(index);
    }

    removeLink(index) {
        this.links.removeAt(index);
    }

    removeAuthor(index) {
        this.authors.removeAt(index);
    }

    removeLanguage(index) {
        this.languages.removeAt(index);
    }

    removeGrant(index) {
        this.grants.removeAt(index);
    }

    insertGrantAtPosition(index, grant) {
        this.grants.insert(index, grant);
    }

    getGrant(index) {
        return this.grants.at(index);
    }

    isGrantExist(grant): boolean {
        for (let i = 0; i < this.grants.length; i++) {
            const tmpGrant = this.getGrant(i);
            if (tmpGrant.value.id === grant.id) {
                return true;
            }
        }
        return false;
    }

    insertAuthor(pos, author) {
        this.authors.insert(pos, author);
    }

    updateLink(pos, link) {
        const editableLink = this.links.at(pos) as UntypedFormGroup;
        editableLink.setControl('url', new UntypedFormControl(link.url));
        editableLink.setControl(
            'description',
            new UntypedFormControl(link.description),
        );
    }

    getAuthor(index) {
        return this.authors.at(index);
    }

    updateTopics(topic) {
        this.topics.push(this.formBuilder.group(topic));
    }

    updateLanguages(language) {
        this.languages.push(this.formBuilder.control(language));
    }

    updateAuthors(author) {
        this.authors.push(this.formBuilder.group(author));
    }

    changeRole(index, role) {
        const author = this.authors.at(index) as UntypedFormGroup;
        author.setControl('role', this.formBuilder.control(role));
    }

    filesLength() {
        return this.files.length;
    }

    setFileUploaded(uploadedFile, percentDone) {
        let uploadedFileIndex = -1;
        this.files.value.forEach((file, index) => {
            if (
                file.fullName === uploadedFile.fullName &&
                file.sizeFloat === uploadedFile.sizeFloat
            ) {
                uploadedFileIndex = index;
            }
        });
        if (uploadedFileIndex !== -1) {
            const fileToChangeStatus = this.files.at(
                uploadedFileIndex,
            ) as UntypedFormGroup;
            if (percentDone < 100) {
                fileToChangeStatus.value.uploadProgress = percentDone;
            } else {
                //replace the control in order to trigger valueChanges for the file progress
                fileToChangeStatus.setControl(
                    'uploadProgress',
                    this.formBuilder.control(percentDone),
                );
            }
        }
    }

    updateFiles(file) {
        this.files.push(this.formBuilder.group(file));
    }

    isFileExists(newFile) {
        const fileExists = this.files.value.filter(
            (file) =>
                newFile.fullName === file.fullName &&
                newFile.sizeFloat === file.sizeFloat,
        );
        return fileExists.length > 0;
    }

    clearAndSetAuthor(author) {
        this.clearAuthors();
        this.authors.push(this.formBuilder.group(author));
    }

    updateKeywords(keyword) {
        this.keywords.push(this.formBuilder.control(keyword));
    }

    updateSubjects(subject) {
        this.subjects.push(this.formBuilder.control(subject));
    }

    updateStatus(status) {
        this.mainForm.patchValue({ status });
    }

    updateNote(note) {
        this.mainForm.patchValue({ note });
    }

    updateOwner(owner) {
        this.mainForm.patchValue({ owner });
    }

    updateProxy(proxy) {
        this.mainForm.patchValue({ proxy });
    }

    updateProxyName(proxyName) {
        this.mainForm.patchValue({ proxyName });
    }

    updateUserPrimaryID(userPrimaryID) {
        this.mainForm.patchValue({ userPrimaryID });
    }

    updateRelationTitle(relationTitle) {
        this.mainForm.patchValue({ relationTitle });
    }

    updateIsPartOfIssn(isPartOfIssn) {
        this.mainForm.patchValue({ isPartOfIssn });
    }

    updateIsPartOfEissn(isPartOfEissn) {
        this.mainForm.patchValue({ isPartOfEissn });
    }

    updateIsPartOfIssue(isPartOfIssue) {
        this.mainForm.patchValue({ isPartOfIssue });
    }

    updateIsPartOfVolume(isPartOfVolume) {
        this.mainForm.patchValue({ isPartOfVolume });
    }

    updateIsPartOfDoi(isPartOfDoi) {
        this.mainForm.patchValue({ isPartOfDoi });
    }

    updateIsPartOfNlmAbbrev(isPartOfNlmAbbrev) {
        this.mainForm.patchValue({ isPartOfNlmAbbrev });
    }

    updateIsPartOfPages(isPartOfPages) {
        this.mainForm.patchValue({ isPartOfPages });
    }

    updateConferenceLocation(conferenceLocation) {
        this.mainForm.patchValue({ conferenceLocation });
    }

    updateConferenceName(conferenceName) {
        this.mainForm.patchValue({ conferenceName });
    }

    updateConferenceNumber(conferenceNumber) {
        this.mainForm.patchValue({ conferenceNumber });
    }

    setDescription(description) {
        this.mainForm.setControl('description', description);
    }

    updateIsbn(isbn) {
        this.mainForm.setControl('isbn', this.formBuilder.array(isbn));
    }

    updateEisbn(eisbn) {
        this.mainForm.setControl('eisbn', this.formBuilder.array(eisbn));
    }

    updateIsPartOfIsbn(isPartOfIsbn) {
        this.mainForm.setControl(
            'isPartOfIsbn',
            this.formBuilder.array(isPartOfIsbn),
        );
    }

    updateIsPartOfIsbnForDisplay(isPartOfIsbnForDisplay) {
        this.mainForm.patchValue({ isPartOfIsbnForDisplay });
    }

    updateIsbnForDisplay(isbnForDisplay) {
        this.mainForm.patchValue({ isbnForDisplay });
    }

    updateIsPartOfEisbn(isPartOfEisbn) {
        this.mainForm.setControl(
            'isPartOfEisbn',
            this.formBuilder.array(isPartOfEisbn),
        );
    }

    updateTitleAlternative(titleAlternative) {
        this.mainForm.setControl(
            'titleAlternative',
            this.formBuilder.array(titleAlternative),
        );
    }

    updateTitleTranslated(titleTranslated) {
        this.mainForm.setControl(
            'titleTranslated',
            this.formBuilder.array(titleTranslated),
        );
    }

    updateComments(comments) {
        this.mainForm.setControl('comments', this.formBuilder.array(comments));
    }

    updateTitleOther(titleOther) {
        this.mainForm.setControl(
            'titleOther',
            this.formBuilder.array(titleOther),
        );
    }

    updateDescription(description) {
        this.mainForm.patchValue({ description });
    }

    updateMethods(methods) {
        this.mainForm.patchValue({ methods });
    }

    updateLocalFields(localFields) {
        this.mainForm.patchValue({ localFields });
    }

    updateCoverage(coverage) {
        this.mainForm.patchValue({ coverage });
    }

    updateTechnicalInformation(technicalInformation) {
        this.mainForm.patchValue({ technicalInformation });
    }

    updateYear(year) {
        this.mainForm.patchValue({ year });
    }

    updateMonth(month) {
        this.mainForm.patchValue({ month });
    }

    updateDay(day) {
        this.mainForm.patchValue({ day });
    }

    updateDate(date) {
        this.mainForm.patchValue({ date });
    }

    updateConferenceStartDate(conferenceStartDate) {
        this.mainForm.patchValue({ conferenceStartDate });
    }

    updateConferenceEndDate(conferenceEndDate) {
        this.mainForm.patchValue({ conferenceEndDate });
    }

    updateDatePublished(datePublished) {
        this.mainForm.patchValue({ datePublished });
    }

    updateSeasonPublished(seasonPublished) {
        this.mainForm.patchValue({ seasonPublished });
    }

    updateDateCopyrighted(dateCopyrighted) {
        this.mainForm.patchValue({ dateCopyrighted }, { emitEvent: false });
    }

    updateYearCopyrighted(yearCopyrighted) {
        this.mainForm.patchValue({ yearCopyrighted });
    }

    updateMonthCopyrighted(monthCopyrighted) {
        this.mainForm.patchValue({ monthCopyrighted });
    }

    updateDayCopyrighted(dayCopyrighted) {
        this.mainForm.patchValue({ dayCopyrighted });
    }

    updateDatePresented(datePresented) {
        this.mainForm.patchValue({ datePresented });
    }

    updateYearPresented(yearPresented) {
        this.mainForm.patchValue({ yearPresented });
    }

    updateMonthPresented(monthPresented) {
        this.mainForm.patchValue({ monthPresented });
    }

    updateDayPresented(dayPresented) {
        this.mainForm.patchValue({ dayPresented });
    }

    updateYearCreatedStart(yearCreatedStart) {
        this.mainForm.patchValue({ yearCreatedStart });
    }

    updateMonthCreatedStart(monthCreatedStart) {
        this.mainForm.patchValue({ monthCreatedStart });
    }

    updateDayCreatedStart(dayCreatedStart) {
        this.mainForm.patchValue({ dayCreatedStart });
    }

    updateDateCreatedStart(dateCreatedStart) {
        this.mainForm.patchValue({ dateCreatedStart });
    }

    updateYearCreatedEnd(yearCreatedEnd) {
        this.mainForm.patchValue({ yearCreatedEnd });
    }

    updateMonthCreatedEnd(monthCreatedEnd) {
        this.mainForm.patchValue({ monthCreatedEnd });
    }

    updateDayCreatedEnd(dayCreatedEnd) {
        this.mainForm.patchValue({ dayCreatedEnd });
    }

    updateDateCreatedEnd(dateCreatedEnd) {
        this.mainForm.patchValue({ dateCreatedEnd });
    }

    updateYearCollectedStart(yearCollectedStart) {
        this.mainForm.patchValue({ yearCollectedStart });
    }

    updateMonthCollectedStart(monthCollectedStart) {
        this.mainForm.patchValue({ monthCollectedStart });
    }

    updateDayCollectedStart(dayCollectedStart) {
        this.mainForm.patchValue({ dayCollectedStart });
    }

    updateDateCollectedStart(dateCollectedStart) {
        this.mainForm.patchValue({ dateCollectedStart });
    }

    updateDateCollectedEnd(dateCollectedEnd) {
        this.mainForm.patchValue({ dateCollectedEnd });
    }

    updateYearCollectedEnd(yearCollectedEnd) {
        this.mainForm.patchValue({ yearCollectedEnd });
    }

    updateMonthCollectedEnd(monthCollectedEnd) {
        this.mainForm.patchValue({ monthCollectedEnd });
    }

    updateDayCollectedEnd(dayCollectedEnd) {
        this.mainForm.patchValue({ dayCollectedEnd });
    }

    updateConferenceStartDay(conferenceStartDay) {
        this.mainForm.patchValue({ conferenceStartDay });
    }

    updateConferenceStartMonth(conferenceStartMonth) {
        this.mainForm.patchValue({ conferenceStartMonth });
    }

    updateConferenceStartYear(conferenceStartYear) {
        this.mainForm.patchValue({ conferenceStartYear });
    }

    updateConferenceEndDay(conferenceEndDay) {
        this.mainForm.patchValue({ conferenceEndDay });
    }

    updateConferenceEndMonth(conferenceEndMonth) {
        this.mainForm.patchValue({ conferenceEndMonth });
    }

    updateConferenceEndYear(conferenceEndYear) {
        this.mainForm.patchValue({ conferenceEndYear });
    }

    updateConferenceDateRange(dateRange: DateRange) {
        this.updateConferenceStartYear(dateRange.getStartDate().getYear());
        this.updateConferenceStartMonth(dateRange.getStartDate().getMonth());
        this.updateConferenceStartDay(dateRange.getStartDate().getDay());
        this.updateConferenceStartDate(dateRange.getStartDate().getDate());

        this.updateConferenceEndYear(dateRange.getEndDate().getYear());
        this.updateConferenceEndMonth(dateRange.getEndDate().getMonth());
        this.updateConferenceEndDay(dateRange.getEndDate().getDay());
        this.updateConferenceEndDate(dateRange.getEndDate().getDate());
    }

    updateEventDateRange(dateRange: DateRange) {
        this.updateEventStartYear(dateRange.getStartDate().getYear());
        this.updateEventStartMonth(dateRange.getStartDate().getMonth());
        this.updateEventStartDay(dateRange.getStartDate().getDay());
        this.updateEventStartDate(dateRange.getStartDate().getDate());

        this.updateEventEndYear(dateRange.getEndDate().getYear());
        this.updateEventEndMonth(dateRange.getEndDate().getMonth());
        this.updateEventEndDay(dateRange.getEndDate().getDay());
        this.updateEventEndDate(dateRange.getEndDate().getDate());
    }

    updateCollectedDateRange(dateRange: DateRange) {
        this.updateYearCollectedStart(dateRange.getStartDate().getYear());
        this.updateMonthCollectedStart(dateRange.getStartDate().getMonth());
        this.updateDayCollectedStart(dateRange.getStartDate().getDay());
        this.updateDateCollectedStart(dateRange.getStartDate().getDate());

        this.updateYearCollectedEnd(dateRange.getEndDate().getYear());
        this.updateMonthCollectedEnd(dateRange.getEndDate().getMonth());
        this.updateDayCollectedEnd(dateRange.getEndDate().getDay());
        this.updateDateCollectedEnd(dateRange.getEndDate().getDate());
    }

    updateCreatedDateRange(dateRange: DateRange) {
        this.updateYearCreatedStart(dateRange.getStartDate().getYear());
        this.updateMonthCreatedStart(dateRange.getStartDate().getMonth());
        this.updateDayCreatedStart(dateRange.getStartDate().getDay());
        this.updateDateCreatedStart(dateRange.getStartDate().getDate());

        this.updateYearCreatedEnd(dateRange.getEndDate().getYear());
        this.updateMonthCreatedEnd(dateRange.getEndDate().getMonth());
        this.updateDayCreatedEnd(dateRange.getEndDate().getDay());
        this.updateDateCreatedEnd(dateRange.getEndDate().getDate());
    }

    updatePublisher(publisher) {
        this.mainForm.patchValue({ publisher });
    }

    updateGrants(grant) {
        this.grants.push(this.formBuilder.group(grant));
    }

    hasFiles() {
        return this.files.length > 0;
    }

    hasLinks() {
        return this.links.length > 0;
    }

    clearFiles() {
        // this.filesForm = this.formBuilder.array([]);
        //Any subscriptions the filesForm, such as that registered with formArray.valueChanges, will not be lost.
        while (this.filesForm.length !== 0) {
            this.filesForm.removeAt(0);
        }
    }

    clearLinks() {
        this.mainForm.setControl('links', this.formBuilder.array([]));
    }

    clearTopics() {
        this.mainForm.setControl('topics', this.formBuilder.array([]));
    }

    clearKeywords() {
        this.keywordsAutoPopulated = [];
        this.mainForm.setControl('keywords', this.formBuilder.array([]));
    }

    clearSubjects() {
        this.mainForm.setControl('subjects', this.formBuilder.array([]));
    }

    clearAuthors() {
        this.mainForm.setControl('authors', this.formBuilder.array([]));
    }

    clearLanguages() {
        this.mainForm.setControl('languages', this.formBuilder.array([]));
    }

    populateFormFromPDF(pdfMetadata: PDFMetadata) {
        this.startPopulating = true;
        const title = this.getTitleFromPDF(pdfMetadata);
        if (title) {
            this.updateTitle(title);
            this.autoPopulatedFields.push(AutoPopulateKeys.TITLE);
        }
        if (pdfMetadata.doi) {
            this.updateDoi(pdfMetadata.doi);
            this.autoPopulatedFields.push(AutoPopulateKeys.DOI);
        }
        if (pdfMetadata.journal) {
            this.updateRelationTitle(pdfMetadata.journal);
            this.depositCitationService.lastPartOfTitle = pdfMetadata.journal;
            this.autoPopulatedFields.push(AutoPopulateKeys.RELATION_TITLE);
        }
        if (pdfMetadata.issn) {
            this.updateIsPartOfIssn(pdfMetadata.issn);
            this.depositCitationService.lastPartOfIssn = pdfMetadata.issn;
            this.autoPopulatedFields.push(AutoPopulateKeys.IS_PART_OF_ISSN);
        }
        if (pdfMetadata.issue) {
            this.updateIsPartOfIssue(pdfMetadata.issue);
            this.autoPopulatedFields.push(AutoPopulateKeys.IS_PART_OF_ISSUE);
        }
        if (pdfMetadata.volume) {
            this.updateIsPartOfVolume(pdfMetadata.volume);
            this.autoPopulatedFields.push(AutoPopulateKeys.IS_PART_OF_VOLUME);
        }
        this.updateIsPartOfPages(
            this.getPartOfPages(pdfMetadata.startPage, pdfMetadata.endPage),
        );
        if (pdfMetadata.keywords && pdfMetadata.keywords.length > 0) {
            // this.autoPopulatedFields.push('topics');
            this.cleanTopics();
            this.handleKeywordsSave(pdfMetadata.keywords);
            this.autoPopulateKeywords(pdfMetadata.keywords);
        }
        if (pdfMetadata.pdfAbstract) {
            this.updateDescription(pdfMetadata.pdfAbstract);
            this.autoPopulatedFields.push(AutoPopulateKeys.DESCRIPTION);
        }
        if (pdfMetadata.language) {
            if (pdfMetadata.language.length === 2) {
                this.depositLanguageService
                    .getThreeCharactersLang(pdfMetadata.language)
                    .subscribe(
                        (data) => {
                            if (data) {
                                this.handleLanguagesSave([data]);
                                this.autoPopulateLanguages([data]);
                            }
                        },
                        () => {},
                    );
            } else if (pdfMetadata.language.length === 3) {
                this.handleLanguagesSave([pdfMetadata.language]);
                this.autoPopulateLanguages([pdfMetadata.language]);
            }
        }
        this.startPopulating = false;
    }

    getTitleFromPDF(pdfMetadata: PDFMetadata) {
        if (pdfMetadata.title) {
            return pdfMetadata.title;
        }
        if (pdfMetadata.articleTitle) {
            return pdfMetadata.articleTitle;
        }
        if (pdfMetadata.bookTitle) {
            return pdfMetadata.bookTitle;
        }
        return '';
    }

    authorMathcing(item: EsploroRecord) {
        const userID = this.jwtUtilService.getUserID();
        if (
            item.temporary &&
            item.temporary.creators &&
            item.temporary.creators.length > 0
        ) {
            //clear authors list from other authors before auto populate
            this.authorsAutoPopulated = [];
            const userAuthor = item.temporary.creators.find(
                (creator) =>
                    creator.identifierResearcherid &&
                    creator.identifierResearcherid === userID,
            );
            if (!userAuthor) {
                this.clearListAndAddCurrentAuthor();
            } else {
                this.clearAuthors();
            }
            item.temporary.creators
                .filter((creator) => creator.identifierResearcherid)
                .forEach((creator) => {
                    this.authorsAutoPopulated.push(
                        creator.identifierResearcherid,
                    );
                    const addedAuthor =
                        creator.identifierResearcherid === userID
                            ? this.createCurrentAuthor(true, creator)
                            : this.getCreatorFromAutoPopulate(creator);

                    this.updateAuthors(addedAuthor);
                });
        }
    }

    clearListAndAddCurrentAuthor() {
        const currentAuthor = this.createCurrentAuthor();
        this.clearAndSetAuthor(currentAuthor);
    }

    createCurrentAuthor(
        creatorByMatch?: boolean,
        source?: EsploroCreator,
    ): User {
        const status = creatorByMatch
            ? EsploroCreatorStatus.MATCHED_WITH_ID
            : EsploroCreatorStatus.MANUAL;
        const currentAuthor = new User();
        currentAuthor.setName(
            StringUtils.capitalizeName(
                this.jwtUtilService.getUserDisplayName(),
            ),
        );
        currentAuthor.setFirstName(this.jwtUtilService.getFirstName());
        currentAuthor.middleName = this.jwtUtilService.getMiddleName();
        currentAuthor.setLastName(this.jwtUtilService.getLastName());
        currentAuthor.nameSuffix = this.jwtUtilService.getNameSuffix();
        currentAuthor.setId(this.jwtUtilService.getUserID());
        currentAuthor.setOrcid(source?.identifierOrcid ?? '');
        currentAuthor.setSourceAffiliation(source?.affiliation[0] ?? '');
        currentAuthor.setAffiliationCode(
            source?.mappedAffiliationErodCode ?? '',
        );
        currentAuthor.setAffiliationName(source?.affiliation[0] ?? '');
        if (source?.mappedAffiliationErodCode) {
            const codes = [
                ...currentAuthor.getAffiliationCodeList(),
                source?.mappedAffiliationErodCode,
            ];
            const names = [
                ...currentAuthor.getAffiliationNameList(),
                source?.affiliation[0],
            ];
            currentAuthor.setAffiliationCodeList(codes);
            currentAuthor.setAffiliationNameList(names);
        }
        currentAuthor.setRole('');
        currentAuthor.setOwner(true);
        currentAuthor.setIsAffiliated(true);
        currentAuthor.setIsDisplayInPublicProfile(true);
        currentAuthor.setStatus(status);
        return currentAuthor;
    }

    populateForm(item: EsploroRecord, doAuthorMatching: boolean) {
        this.enrichForm = true;
        this.startPopulating = true;
        this.autoPopulatedFields.push(AutoPopulateKeys.STATUS);

        if (doAuthorMatching) {
            this.authorMathcing(item);
        }

        if (item.identifierDoi) {
            this.updateDoi(item.identifierDoi);
            this.autoPopulatedFields.push(AutoPopulateKeys.DOI);
        }

        if (item.identifierPmid) {
            this.updatePmid(item.identifierPmid);
            this.autoPopulatedFields.push(AutoPopulateKeys.PMID);
        }

        if (item.identifierIssn) {
            this.updateIssn(item.identifierIssn);
            this.autoPopulatedFields.push(AutoPopulateKeys.ISSN);
        }

        if (item.identifierEissn) {
            this.updateEissn(item.identifierEissn);
            this.autoPopulatedFields.push(AutoPopulateKeys.EISSN);
        }

        if (item.identifierIsbn && item.identifierIsbn.length > 0) {
            this.updateIsbn(item.identifierIsbn);
            const isbnForDisplay = item.identifierIsbn.join(
                DepositForm.VALUES_SEPARATOR,
            );
            this.updateIsbnForDisplay(isbnForDisplay);
            this.autoPopulatedFields.push(AutoPopulateKeys.ISBN_FOR_DISPLAY);
        }

        if (item.identifierEisbn && item.identifierEisbn.length > 0) {
            this.updateEisbn(item.identifierEisbn);
        }

        if (item.identifierPmcid) {
            this.updatePmcid(item.identifierPmcid);
        }

        if (item.publisher) {
            this.updatePublisher(item.publisher);
            this.autoPopulatedFields.push(AutoPopulateKeys.PUBLISHER);
        }

        if (item.copyright) {
            this.depositLastStepService.copyrightsNotes = true;
            this.updateCopyright(item.copyright);
        }

        if (item.peerreview) {
            this.updatePeerReviewed(item.peerreview);
            this.autoPopulatedFields.push(AutoPopulateKeys.PEER_REVIEWED);
        }

        if (item.openaccess) {
            this.updateOpenAccess(item.openaccess);
            this.autoPopulatedFields.push(AutoPopulateKeys.OPEN_ACCESS);
        }

        if (item.openAccessStatus) {
            this.updateOpenAccessStatus(item.openAccessStatus);
            this.autoPopulatedFields.push(AutoPopulateKeys.OPEN_ACCESS_STATUS);
        }

        if (item.title) {
            this.updateTitle(item.title);
            //this.mainForm.patchValue({title:item.title}, {emitEvent:false});
            this.autoPopulatedFields.push(AutoPopulateKeys.TITLE);
        }

        if (item.links && item.links.length > 0) {
            this.clearLinks();
            this.linksAutoPopulated = [];
            item.links.forEach((link) => {
                const linkToAdd = new Link(
                    link.linkURL,
                    link.linkDescription,
                    link.linkType,
                );
                if (link.linkRights) {
                    linkToAdd.setAccessRights(link.linkRights);
                }
                if (link.linkOwnership) {
                    linkToAdd.setOwnership(link.linkOwnership);
                }
                this.updateLinks(linkToAdd);
                this.linksAutoPopulated.push(link.linkURL);
            });
        }

        if (item.conferencename) {
            this.updateConferenceName(item.conferencename);
            this.autoPopulatedFields.push(AutoPopulateKeys.CONFERENCE_NAME);
        }

        if (item.format && item.format.length > 0) {
            this.updateFormat(item.format);
            this.autoPopulatedFields.push(AutoPopulateKeys.FORMAT);
        }

        if (item.descriptionAbstract && item.descriptionAbstract.length > 0) {
            const description = item.descriptionAbstract.join(
                DepositForm.DESCRIPTION_JOIN_PARAM,
            );
            this.updateDescription(description);
            this.autoPopulatedFields.push(AutoPopulateKeys.DESCRIPTION);
        }

        if (item.descriptionMethods && item.descriptionMethods.length > 0) {
            const method = item.descriptionMethods.join(
                DepositForm.DESCRIPTION_JOIN_PARAM,
            );
            this.updateMethods(method);
            this.autoPopulatedFields.push(AutoPopulateKeys.METHODS);
            this.depositStepThreeService.showAdditionalInfo = true;
        }

        if (item.descriptionCoverage && item.descriptionCoverage.length > 0) {
            const coverage = item.descriptionCoverage.join(
                DepositForm.DESCRIPTION_JOIN_PARAM,
            );
            this.updateCoverage(coverage);
            this.autoPopulatedFields.push(AutoPopulateKeys.COVERAGE);
            this.depositStepThreeService.showAdditionalInfo = true;
        }

        if (
            item.descriptionTechnicalinfo &&
            item.descriptionTechnicalinfo.length > 0
        ) {
            const technicalInfo = item.descriptionTechnicalinfo.join(
                DepositForm.DESCRIPTION_JOIN_PARAM,
            );
            this.updateTechnicalInformation(technicalInfo);
            this.autoPopulatedFields.push(
                AutoPopulateKeys.TECHNICAL_INFORMATION,
            );
            this.depositStepThreeService.showAdditionalInfo = true;
        }

        if (item.descriptionAudience && item.descriptionAudience.length > 0) {
            this.updateAudience(item.descriptionAudience[0]);
            // const audienceForDisplay = item.descriptionAudience.join(DepositForm.VALUES_SEPARATOR);
            // this.updateAudienceForDisplay(audienceForDisplay);
            this.autoPopulatedFields.push(AutoPopulateKeys.AUDIENCE);
        }

        if (item.keywords && item.keywords.length > 0) {
            this.cleanTopics();
            this.handleKeywordsSave(item.keywords);
            this.autoPopulateKeywords(item.keywords);
        }

        if (item.titleAlternative && item.titleAlternative.length > 0) {
            this.updateTitleAlternative(item.titleAlternative);
        }

        if (item.titleSubtitle) {
            this.updateSubtitle(item.titleSubtitle);
            this.autoPopulatedFields.push(AutoPopulateKeys.SUBTITLE);
        }
        if (item.edition) {
            this.updateEdition(item.edition);
            this.autoPopulatedFields.push(AutoPopulateKeys.EDITION);
        }
        if (item.pages) {
            this.updatePages(item.pages);
            this.autoPopulatedFields.push(AutoPopulateKeys.PAGES);
        }
        if (item.series) {
            this.updateSeries(item.series);
            this.autoPopulatedFields.push(AutoPopulateKeys.SERIES);
        }
        if (item.size) {
            this.updateSize(item.size);
        }
        if (item.resourceSubtype) {
            this.updateResourceSubtype(item.resourceSubtype);
        }
        if (item.publicationPlace && item.publicationPlace.length > 0) {
            this.updatePublicationPlace(item.publicationPlace);
            const publicationPlaceForDisplay = item.publicationPlace.join(
                DepositForm.VALUES_SEPARATOR,
            );
            this.updatePublicationPlaceForDisplay(publicationPlaceForDisplay);
            this.autoPopulatedFields.push(AutoPopulateKeys.PUBLICATION_PLACE);
        }

        if (item.language && item.language.length > 0) {
            this.handleLanguagesSave(item.language);
            this.autoPopulateLanguages(item.language);
        }
        if (item.geoLocations) {
            const geoLocationAddress = item.geoLocations[
                'geoLocationAddress'
            ] as Array<EsploroGeoLocationAddress>;
            if (geoLocationAddress.length > 0) {
                this.depositStepThreeService.showAdditionalInfo = true;
                this.populateAddress(geoLocationAddress);
                this.autoPopulatedFields.push(AutoPopulateKeys.ADDRESS);
            }
        }
        this.updateStatus(Status.PUBLISHED);
        if (item.datePublished) {
            this.autoPopulatedFields.push(AutoPopulateKeys.DATE);
            this.autoPopulatedFields.push(AutoPopulateKeys.DATE_PUBLISHED);
            this.parseDatePublishedAndUpdate(item.datePublished);
        }
        if (item.datePresented) {
            this.autoPopulatedFields.push(AutoPopulateKeys.DATE_PRESENTED);
            this.parseDatePresentedAndUpdate(item.datePresented);
        }
        if (item.dateCopyrighted) {
            this.autoPopulatedFields.push(AutoPopulateKeys.DATE_COPYRIGHTED);
            this.parseDateCopyrightedAndUpdate(item.dateCopyrighted);
        }
        this.enrichPartOfSection(item.relationship);
        this.startPopulating = false;
    }

    getCreatorFromAutoPopulate(creator: EsploroCreator) {
        const addedAuthor = new User();
        const firstName = creator.givenName ? creator.givenName + ' ' : '';
        const lastName = creator.familyName ? creator.familyName : '';
        let sourceAffiliation = '';
        if (creator.affiliation && creator.affiliation.length > 0) {
            sourceAffiliation = creator.affiliation[0];
        }
        addedAuthor.setId(creator.identifierResearcherid);
        addedAuthor.setName(StringUtils.capitalizeName(firstName + lastName));
        addedAuthor.setAffiliationCode(creator.mappedAffiliationErodCode ?? '');
        addedAuthor.setAffiliationName(sourceAffiliation);
        addedAuthor.setSourceAffiliation(sourceAffiliation);
        addedAuthor.setOrcid(creator.identifierOrcid);
        addedAuthor.setOwner(false);
        addedAuthor.setRole('');
        addedAuthor.setStatus(creator.status);
        addedAuthor.setIsDisplayInPublicProfile(
            creator.status === EsploroCreatorStatus.AFF_STRONG ||
                creator.status === EsploroCreatorStatus.MANUAL,
        );
        return addedAuthor;
    }

    populateDepositFormFromEditOrView(item: ResearcherAsset) {
        this.startPopulating = true;

        this.updateMmsId(item.mmsId);
        const esploroRecord = item.esploroRecord as EsploroRecord;
        this.populateCreatorsContributors(item);
        if (esploroRecord.identifierDoi) {
            this.updateDoi(esploroRecord.identifierDoi);
        }
        if (esploroRecord.identifierPmid) {
            this.updatePmid(esploroRecord.identifierPmid);
        }
        if (esploroRecord.identifierPmcid) {
            this.updatePmcid(esploroRecord.identifierPmcid);
        }
        if (esploroRecord.identifierIssn) {
            this.updateIssn(esploroRecord.identifierIssn);
        }
        if (esploroRecord.identifierEissn) {
            this.updateEissn(esploroRecord.identifierEissn);
        }
        if (
            esploroRecord.identifierIsbn &&
            esploroRecord.identifierIsbn.length > 0
        ) {
            this.updateIsbn(esploroRecord.identifierIsbn);
            const isbnForDisplay = esploroRecord.identifierIsbn.join(
                DepositForm.VALUES_SEPARATOR,
            );
            this.updateIsbnForDisplay(isbnForDisplay);
        }
        if (
            esploroRecord.identifierEisbn &&
            esploroRecord.identifierEisbn.length > 0
        ) {
            this.updateEisbn(esploroRecord.identifierEisbn);
        }
        if (esploroRecord.copyright) {
            this.depositLastStepService.copyrightsNotes = true;
            this.updateCopyright(esploroRecord.copyright);
        }
        if (esploroRecord.publisher) {
            this.updatePublisher(esploroRecord.publisher);
        }
        if (esploroRecord.peerreview) {
            this.updatePeerReviewed(esploroRecord.peerreview);
        }
        if (esploroRecord.openaccess) {
            this.updateOpenAccess(esploroRecord.openaccess);
        }
        if (esploroRecord.openAccessStatus) {
            this.updateOpenAccessStatus(esploroRecord.openAccessStatus);
        }
        if (esploroRecord.title) {
            this.updateTitle(esploroRecord.title);
        }
        if (esploroRecord.titleSubtitle) {
            this.updateSubtitle(esploroRecord.titleSubtitle);
        }
        if (esploroRecord.titleTranslated) {
            this.updateTitleTranslated(esploroRecord.titleTranslated);
        }
        if (esploroRecord.titleOther) {
            this.updateTitleOther(esploroRecord.titleOther);
        }
        if (esploroRecord.edition) {
            this.updateEdition(esploroRecord.edition);
        }
        if (esploroRecord.series) {
            this.updateSeries(esploroRecord.series);
        }
        if (esploroRecord.seriesNumber) {
            this.updateSeriesNumber(esploroRecord.seriesNumber);
        }
        if (esploroRecord.comments) {
            this.updateComments(esploroRecord.comments);
        }
        if (esploroRecord.format && esploroRecord.format.length > 0) {
            this.updateFormat(esploroRecord.format);
        }
        if (esploroRecord.publicationPlace) {
            this.updatePublicationPlace(esploroRecord.publicationPlace);
            const publicationPlaceForDisplay =
                esploroRecord.publicationPlace.join(
                    DepositForm.VALUES_SEPARATOR,
                );
            this.updatePublicationPlaceForDisplay(publicationPlaceForDisplay);
        }
        if (esploroRecord.localFields) {
            this.updateLocalFields(esploroRecord.localFields);
        }
        if (esploroRecord.pages) {
            this.updatePages(esploroRecord.pages);
        }
        if (esploroRecord.event) {
            const event = esploroRecord.event;
            this.updateEvent(event);
        }
        if (
            esploroRecord.descriptionAbstract &&
            esploroRecord.descriptionAbstract.length > 0
        ) {
            const description = esploroRecord.descriptionAbstract.join(
                DepositForm.DESCRIPTION_JOIN_PARAM,
            );
            this.updateDescription(description);
        }
        if (
            esploroRecord.descriptionMethods &&
            esploroRecord.descriptionMethods.length > 0
        ) {
            const method = esploroRecord.descriptionMethods.join(
                DepositForm.DESCRIPTION_JOIN_PARAM,
            );
            this.updateMethods(method);
            this.depositStepThreeService.showAdditionalInfo = true;
        }
        if (
            esploroRecord.descriptionCoverage &&
            esploroRecord.descriptionCoverage.length > 0
        ) {
            const coverage = esploroRecord.descriptionCoverage.join(
                DepositForm.DESCRIPTION_JOIN_PARAM,
            );
            this.updateCoverage(coverage);
            this.depositStepThreeService.showAdditionalInfo = true;
        }

        if (
            esploroRecord.descriptionTechnicalinfo &&
            esploroRecord.descriptionTechnicalinfo.length > 0
        ) {
            const technicalInfo = esploroRecord.descriptionTechnicalinfo.join(
                DepositForm.DESCRIPTION_JOIN_PARAM,
            );
            this.updateTechnicalInformation(technicalInfo);
            this.depositStepThreeService.showAdditionalInfo = true;
        }

        if (
            esploroRecord.descriptionAudience &&
            esploroRecord.descriptionAudience.length > 0
        ) {
            this.updateAudience(esploroRecord.descriptionAudience[0]);
            // const audienceForDisplay = esploroRecord.descriptionAudience.join(DepositForm.VALUES_SEPARATOR);
            // this.updateAudienceForDisplay(audienceForDisplay);
        }

        if (item.subjects && item.subjects.length > 0) {
            this.handleSubjectsSave(item.subjects);
        }

        if (item.subjectsFor && item.subjectsFor.length > 0) {
            this.handleSubjectFor(item.subjectsFor);
        }

        if (item.subjectsSeo && item.subjectsSeo.length > 0) {
            this.handleSubjectSeo(item.subjectsSeo);
        }

        this.mainForm.patchValue({ toaSubject: item.subjectToa });

        if (esploroRecord.keywords && esploroRecord.keywords.length > 0) {
            this.handleKeywordsSave(esploroRecord.keywords);
        }
        if (
            esploroRecord.titleAlternative &&
            esploroRecord.titleAlternative.length > 0
        ) {
            this.updateTitleAlternative(esploroRecord.titleAlternative);
        }
        if (esploroRecord.language && esploroRecord.language.length > 0) {
            this.handleLanguagesSave(esploroRecord.language);
        }

        if (esploroRecord.geoLocations) {
            this.depositStepThreeService.showAdditionalInfo = true;
            const geoLocationAddress = esploroRecord.geoLocations[
                'geoLocationAddress'
            ] as Array<EsploroGeoLocationAddress>;
            if (geoLocationAddress && geoLocationAddress.length > 0) {
                this.populateAddress(geoLocationAddress);
            }
            const geoLocationPoints = esploroRecord.geoLocations[
                'geoLocationPoints'
            ] as Array<EsploroGeoLocationPoint>;
            if (geoLocationPoints && geoLocationPoints.length > 0) {
                this.populatePoint(geoLocationPoints);
            }
            const geoLocationBoxs = esploroRecord.geoLocations[
                'geoLocationBoxs'
            ] as Array<EsploroGeoLocationBox>;
            if (geoLocationBoxs && geoLocationBoxs.length > 0) {
                this.populateBoundingBox(geoLocationBoxs);
            }
        }

        if (esploroRecord.datePublished) {
            this.updateStatus(Status.PUBLISHED);
            this.updateSeasonPublished(esploroRecord.seasonPublished);
            this.parseDatePublishedAndUpdate(esploroRecord.datePublished);
        }
        if (esploroRecord.dateAccepted) {
            this.updateStatus(Status.ACCEPTED);
            this.parseDateAndUpdate(esploroRecord.dateAccepted);
        }
        if (esploroRecord.dateEPublished) {
            this.updateStatus(Status.ONLINE_PUBLICATION);
            this.parseDateAndUpdate(esploroRecord.dateEPublished);
        }
        if (esploroRecord.dateSubmitted) {
            this.updateStatus(Status.SUBMITTED);
            this.parseDateAndUpdate(esploroRecord.dateSubmitted);
        }
        if (esploroRecord.datePosted) {
            this.updateStatus(Status.POSTED);
            this.parseDateAndUpdate(esploroRecord.datePosted);
        }
        if (esploroRecord.dateCreated) {
            const createdDateRange = this.getDateRange(
                esploroRecord.dateCreated,
            );
            this.updateCreatedDateRange(createdDateRange);
        }
        if (esploroRecord.dateAvailable) {
            this.updateStatus(Status.UNPUBLISHED);
        }
        if (esploroRecord.datePresented) {
            this.parseDatePresentedAndUpdate(esploroRecord.datePresented);
        }
        if (esploroRecord.dateCopyrighted) {
            this.depositLastStepService.copyrightsNotes = true;
            this.parseDateCopyrightedAndUpdate(esploroRecord.dateCopyrighted);
        }
        if (esploroRecord.conferencelocation) {
            this.updateConferenceLocation(esploroRecord.conferencelocation);
        }
        if (esploroRecord.conferencename) {
            this.updateConferenceName(esploroRecord.conferencename);
        }
        if (esploroRecord.conferencenumber) {
            this.updateConferenceNumber(esploroRecord.conferencenumber);
        }
        if (esploroRecord.conferencedate) {
            const conferenceDateRange = this.getDateRange(
                esploroRecord.conferencedate,
            );
            this.updateConferenceDateRange(conferenceDateRange);
        }

        if (esploroRecord.dateCollected) {
            const collectedDateRange = this.getDateRange(
                esploroRecord.dateCollected,
            );
            this.updateCollectedDateRange(collectedDateRange);
        }

        if (esploroRecord.links && esploroRecord.links.length > 0) {
            esploroRecord.links.forEach((link) => {
                const newLink = new Link(
                    link.linkURL,
                    link.linkDescription,
                    link.linkType,
                );
                newLink.setLicense(
                    link.linkLicenseCode ? link.linkLicenseCode : '',
                );
                this.updateLinks(newLink);
            });
        }

        if (esploroRecord.size) {
            this.updateSize(esploroRecord.size);
        }
        if (esploroRecord.resourceSubtype) {
            this.updateResourceSubtype(esploroRecord.resourceSubtype);
        }
        if (esploroRecord.descriptionOther) {
            this.updateDescriptionOther(esploroRecord.descriptionOther);
        }

        this.enrichPartOfSection(esploroRecord.relationship, false);
        this.populateGrants(item);
        this.populateFiles(item);
        this.updateIsReservedDOI(item.isReservedDOI);
        this.updateIsRegisteredDOI(item.isRegisteredDOI);

        this.startPopulating = false;
    }

    updateEvent(event: EsploroEvent) {
        if (event.eventName) {
            this.updateEventName(event.eventName);
        }
        if (event.eventLocation) {
            this.updateEventLocation(event.eventLocation);
        }
        if (event.eventType) {
            this.updateEventType(event.eventType);
        }
        if (event.eventDate) {
            const eventDateRange = this.getDateRange(event.eventDate);
            this.updateEventDateRange(eventDateRange);
        }
    }

    populateFiles(item: ResearcherAsset) {
        let index = 0;
        const accessRightsMap = {
            Open: 'public',
            'Fixed Embargo Date': 'embargoed',
            Restricted: 'request',
            Closed: 'private',
            Embargo: 'embargoed',
        };
        item.files.forEach((file) => {
            const addedFile = new File();
            addedFile.setIndex(index++);
            addedFile.setUploadProgress(100);
            addedFile.setBaseName(file.digitalFileCreationData.label);
            addedFile.setFullName(file.digitalFileCreationData.fileName);
            addedFile.setProfile(
                FileUtils.getFileProfile(
                    file.digitalFileCreationData.extension,
                    file.digitalFileCreationData.mimeType,
                ),
            );
            addedFile.setExtension(file.digitalFileCreationData.extension);
            addedFile.setSizeFloat(
                FileUtils.getHumanFileSize(
                    file.digitalFileCreationData.size,
                    2,
                ),
            );
            if (file.fileDescription) {
                addedFile.setDescription(file.fileDescription);
            }
            if (file.fileTypeCode) {
                addedFile.setType(file.fileTypeCode);
            }
            if (file.licenseCode) {
                addedFile.setLicense(file.licenseCode);
            }
            if (file.policyType) {
                addedFile.setAccess(accessRightsMap[file.policyType]);
                if (file.accessRightsFixedDate) {
                    const arr = file.accessRightsFixedDate.split('/');
                    addedFile.setAccessRightsFixedYear(arr[2]);
                    addedFile.setAccessRightsFixedMonth(arr[0]);
                    addedFile.setAccessRightsFixedDay(arr[1]);
                    const date = new Date(
                        parseInt(arr[2]),
                        parseInt(arr[0]) - 1,
                        parseInt(arr[1]),
                    );
                    addedFile.setAccessRightsFixedDate(date);
                }
                if (file.accessRightsPredefinedDate) {
                    addedFile.setAccessRightsPredefinedDate(
                        file.accessRightsPredefinedDate,
                    );
                }
                if (file.accessRightsReason) {
                    addedFile.setAccessRightsReason(file.accessRightsReason);
                }
            }
            if (file.digitalFileCreationData.mid) {
                addedFile.setMid(file.digitalFileCreationData.mid);
            }
            this.updateFiles(addedFile);
        });
    }

    populateCreatorsContributors(item: ResearcherAsset) {
        const authors = this.getSortedAuthors(item);
        authors.forEach((author) => {
            this.createCreatorContributor(author);
        });
    }

    getSortedAuthors(item: ResearcherAsset) {
        const creators =
            item.creators && item.creators.length > 0 ? item.creators : [];
        creators.forEach((creator) => {
            creator.type = CreatorContributorType.CREATOR;
        });
        const contributors =
            item.contributors && item.contributors.length > 0
                ? item.contributors
                : [];
        contributors.forEach((contributor) => {
            contributor.type = CreatorContributorType.CONTRIBUTOR;
        });
        creators.sort(SortUtils.authorSortByOrder);
        contributors.sort(SortUtils.authorSortByOrder);
        const authors = creators.concat(contributors);
        return authors;
    }

    populateGrants(item: ResearcherAsset) {
        if (item.grants && item.grants.length > 0) {
            item.grants.forEach((grant) => {
                const addedGrant = new Grant();
                addedGrant.setId(grant.id);
                addedGrant.setGrantId(grant.grantId);
                addedGrant.setGrantName(grant.grantName);
                addedGrant.setFunderCode(grant.funderCode);
                addedGrant.setFunderName(grant.funderName);
                addedGrant.setFunderAgency(grant.funderAgency);
                addedGrant.setFunderAgencyCode(grant.funderAgencyCode);
                this.updateGrants(addedGrant);
            });
        }
    }

    createCreatorContributor(author: CreatorContributor) {
        const lastName = author.lastName ? author.lastName : '';
        const firstName = author.firstName ? author.firstName : '';
        const middleName = author.middleName ? author.middleName : '';
        const nameSuffix = author.nameSuffix ? author.nameSuffix : '';

        const name = new Array(firstName, middleName, lastName, nameSuffix)
            .filter(Boolean)
            .join(' ');

        const addedAuthor = new User();
        addedAuthor.setId(author.id);
        addedAuthor.setSource(author.source);
        let authorName = name;
        if (author.source !== 'groupAuthor') {
            authorName = StringUtils.capitalizeName(name);
        }
        addedAuthor.setName(authorName);
        addedAuthor.setAffiliationCode(
            author.affiliationCode ? author.affiliationCode : '',
        );
        addedAuthor.setAffiliationName(
            author.affiliationName ? author.affiliationName : '',
        );
        addedAuthor.setAffiliationCodeList(
            author.affiliationCodeList ? author.affiliationCodeList : [],
        );
        addedAuthor.setAffiliationNameList(
            author.affiliationNameList ? author.affiliationNameList : [],
        );
        addedAuthor.setSourceAffiliation(
            author.sourceAffiliation ? author.sourceAffiliation : '',
        );
        addedAuthor.setOwner(author.owner);
        addedAuthor.setRole(author.role ? author.role : '');
        addedAuthor.setType(author.type);
        addedAuthor.setIsDisplayInPublicProfile(
            author.isDisplayInPublicProfile,
        );
        addedAuthor.setIsAffiliated(author.isAffiliated);
        addedAuthor.setStatus(author.status);
        this.updateAuthors(addedAuthor);
    }

    populateAddress(geoLocationAddress: Array<EsploroGeoLocationAddress>) {
        this.updateEnableAddress(true);
        const address = geoLocationAddress[0]['address'];
        this.updateAddress(address);
    }

    populatePoint(geoLocationPoints: Array<EsploroGeoLocationPoint>) {
        this.updateEnablePoint(true);
        this.updatePointLatitude(geoLocationPoints[0]['latitude']);
        this.updatePointLongitude(geoLocationPoints[0]['longitude']);
    }

    populateBoundingBox(geoLocationBoxs: Array<EsploroGeoLocationBox>) {
        this.updateEnableBox(true);
        this.updateBoundingBoxEast(geoLocationBoxs[0]['eastBoundLongitude']);
        this.updateBoundingBoxNorth(geoLocationBoxs[0]['northBoundLongitude']);
        this.updateBoundingBoxSouth(geoLocationBoxs[0]['southBoundLongitude']);
        this.updateBoundingBoxWest(geoLocationBoxs[0]['westBoundLongitude']);
    }

    getDateRange(date: string): DateRange {
        const arr = date.split(this.DATE_DELIMITER);
        const startDate = arr[0];
        let endDate = '';
        if (arr.length > 1) {
            endDate = arr[1];
        } else {
            endDate = arr[0];
        }

        const startYear = startDate.substring(0, 4);
        const startMonth = startDate.substring(4, 6);
        const startDay = startDate.substring(6);

        const endYear = endDate.substring(0, 4);
        const endMonth = endDate.substring(4, 6);
        const endDay = endDate.substring(6);

        const dateRange = new DateRange();
        dateRange.getStartDate().setYear(startYear);
        dateRange.getStartDate().setMonth(startMonth);
        dateRange.getStartDate().setDay(startDay);
        const start = new Date(
            parseInt(startYear),
            parseInt(startMonth) - 1,
            parseInt(startDay),
        );
        dateRange.getStartDate().setDate(start);

        dateRange.getEndDate().setYear(endYear);
        dateRange.getEndDate().setMonth(endMonth);
        dateRange.getEndDate().setDay(endDay);
        const end = new Date(
            parseInt(endYear),
            parseInt(endMonth) - 1,
            parseInt(endDay),
        );
        dateRange.getEndDate().setDate(end);
        return dateRange;
    }

    parseDatePublishedAndUpdate(datePublished) {
        const year = datePublished.substring(0, 4);
        let date = datePublished;

        //only month and year
        if (datePublished.length == 6) {
            const month = datePublished.substring(4, 6);
            date = new Date(year, month - 1, 1);
        } else if (this.fullDateRegex.test(datePublished)) {
            const day = datePublished.substring(6);
            const month = datePublished.substring(4, 6);
            date = new Date(year, month - 1, day);
        }
        this.updateDate(date);
        this.updateDatePublished(date);
    }

    parseDatePresentedAndUpdate(datePresented) {
        if (this.yearOnlyRegex.test(datePresented)) {
            this.updateYearPresented(datePresented);
            this.updateMonthPresented('01');
            this.updateDayPresented('01');
            const date = new Date(datePresented, 0, 1);
            this.updateDatePresented(date);
        } else if (this.fullDateRegex.test(datePresented)) {
            const year = datePresented.substring(0, 4);
            const month = datePresented.substring(4, 6);
            const day = datePresented.substring(6);
            this.updateYearPresented(year);
            this.updateMonthPresented(month);
            this.updateDayPresented(day);
            const date = new Date(year, month - 1, day);
            this.updateDatePresented(date);
        }
    }

    parseDateCopyrightedAndUpdate(dateCopyrighted) {
        if (this.yearOnlyRegex.test(dateCopyrighted)) {
            this.updateYearCopyrighted(dateCopyrighted);
            this.updateMonthCopyrighted('01');
            this.updateDayCopyrighted('01');
            const date = new Date(dateCopyrighted, 0, 1);
            this.updateDateCopyrighted(date);
        } else if (this.fullDateRegex.test(dateCopyrighted)) {
            const year = dateCopyrighted.substring(0, 4);
            const month = dateCopyrighted.substring(4, 6);
            const day = dateCopyrighted.substring(6);
            this.updateYearCopyrighted(year);
            this.updateMonthCopyrighted(month);
            this.updateDayCopyrighted(day);
            const date = new Date(year, month - 1, day);
            this.updateDateCopyrighted(date);
        }
    }

    parseDateAndUpdate(dateStr) {
        if (this.yearOnlyRegex.test(dateStr)) {
            this.updateYear(dateStr);
            this.updateMonth('01');
            this.updateDay('01');
            const date = new Date(dateStr, 0, 1);
            this.updateDate(date);
        } else if (this.fullDateRegex.test(dateStr)) {
            const year = dateStr.substring(0, 4);
            const month = dateStr.substring(4, 6);
            const day = dateStr.substring(6);
            this.updateYear(year);
            this.updateMonth(month);
            this.updateDay(day);
            const date = new Date(year, month - 1, day);
            this.updateDate(date);
        }
    }

    populatePartOfFormFromIssn(item: EsploroRecord, enrichTitle: boolean) {
        this.startPopulating = true;
        if (item.openaccess && item.openaccess !== 'UNKNOWN') {
            this.updateOpenAccess(item.openaccess);
            if (
                this.autoPopulatedFields.indexOf(
                    AutoPopulateKeys.OPEN_ACCESS,
                ) === -1
            ) {
                this.autoPopulatedFields.push(AutoPopulateKeys.OPEN_ACCESS);
            }
        }
        if (item.openAccessStatus && item.openAccessStatus !== '') {
            this.updateOpenAccessStatus(item.openAccessStatus);
            if (
                this.autoPopulatedFields.indexOf(
                    AutoPopulateKeys.OPEN_ACCESS_STATUS,
                ) === -1
            ) {
                this.autoPopulatedFields.push(
                    AutoPopulateKeys.OPEN_ACCESS_STATUS,
                );
            }
        }
        if (item.peerreview && item.peerreview !== 'UNKNOWN') {
            this.updatePeerReviewed(item.peerreview);
            if (
                this.autoPopulatedFields.indexOf(
                    AutoPopulateKeys.PEER_REVIEWED,
                ) === -1
            ) {
                this.autoPopulatedFields.push(AutoPopulateKeys.PEER_REVIEWED);
            }
        }
        if (item.relationship && enrichTitle) {
            this.getJournalName(item.relationship);
        }
        this.startPopulating = false;
    }

    populatePartOfFormFromTitle(result: CZSearchResult) {
        this.startPopulating = true;
        this.updateRelationTitle(result.title);
        this.depositCitationService.lastPartOfTitle = result.title;
        this.autoPopulatedFields.push(AutoPopulateKeys.RELATION_TITLE);
        if (result.eissn) {
            this.updateIsPartOfEissn(result.eissn);
            this.autoPopulatedFields.push(AutoPopulateKeys.IS_PART_OF_EISSN);
        }
        this.updateIsPartOfIssn(result.issn);
        this.depositCitationService.lastPartOfIssn = result.issn;
        this.autoPopulatedFields.push(AutoPopulateKeys.IS_PART_OF_ISSN);
        const isPeerReviewed = result.isPeerReviewed ? 'YES' : 'NO';
        this.updatePeerReviewed(isPeerReviewed);
        this.autoPopulatedFields.push(AutoPopulateKeys.PEER_REVIEWED);
        const isOpenAccess = result.isOpenAccess ? 'YES' : 'NO';
        this.updateOpenAccess(isOpenAccess);
        this.autoPopulatedFields.push(AutoPopulateKeys.OPEN_ACCESS);
        this.startPopulating = false;
    }

    getIsPartOfRelation(esploroRecord: EsploroRecord) {
        const relationList = esploroRecord.relationship;
        if (relationList) {
            const isPartOfList = relationList.filter(
                (relation) => relation.relationtype === DepositForm.IS_PART_OF,
            );
            if (isPartOfList.length > 0) {
                return isPartOfList[0];
            }
        }
        return '';
    }

    getJournalName(relationship) {
        const isPartOf = relationship.filter(
            (relation) => relation.relationtype === DepositForm.IS_PART_OF,
        );
        if (isPartOf.length > 0) {
            this.updateRelationTitle(isPartOf[0].relationtitle);
            this.depositCitationService.lastPartOfTitle =
                isPartOf[0].relationtitle;
            if (
                this.autoPopulatedFields.indexOf(
                    AutoPopulateKeys.RELATION_TITLE,
                ) === -1
            ) {
                this.autoPopulatedFields.push(AutoPopulateKeys.RELATION_TITLE);
            }
        } //else {
        //     this.updateIsPartOfPublicationName('');
        // }
    }

    enrichPartOfSection(
        relationship: EsploroRelationship[],
        cameFromAutoPopulate = true,
    ) {
        if (relationship) {
            const isPartOfList = relationship.filter(
                (relation) => relation.relationtype === DepositForm.IS_PART_OF,
            );
            if (isPartOfList.length > 0) {
                const isPartOf = isPartOfList[0];
                this.enrichIsPartOf(isPartOf, cameFromAutoPopulate);
            }
        }
    }

    enrichIsPartOf(isPartOf: EsploroRelationship, cameFromAutoPopulate = true) {
        if (isPartOf.relationtitle) {
            this.updateRelationTitle(isPartOf.relationtitle);
            this.depositCitationService.lastPartOfTitle =
                isPartOf.relationtitle;
            if (cameFromAutoPopulate) {
                this.autoPopulatedFields.push(AutoPopulateKeys.RELATION_TITLE);
            }
        }
        if (isPartOf.identifierIssn) {
            this.updateIsPartOfIssn(isPartOf.identifierIssn);
            this.depositCitationService.lastPartOfIssn =
                isPartOf.identifierIssn;
            if (cameFromAutoPopulate) {
                this.autoPopulatedFields.push(AutoPopulateKeys.IS_PART_OF_ISSN);
                const enrichTitle = isPartOf.relationtitle ? false : true;
                if (
                    !this.isPublicationBookChapter() &&
                    !this.isPublicationBook()
                ) {
                    this.enrichFromIssn(isPartOf.identifierIssn, enrichTitle);
                }
            }
        }
        if (isPartOf.identifierIsbn && isPartOf.identifierIsbn.length > 0) {
            this.updateIsPartOfIsbn(isPartOf.identifierIsbn);
            const isbnForDisplay = isPartOf.identifierIsbn.join(
                DepositForm.VALUES_SEPARATOR,
            );
            this.updateIsPartOfIsbnForDisplay(isbnForDisplay);
            if (cameFromAutoPopulate) {
                this.autoPopulatedFields.push(
                    AutoPopulateKeys.IS_PART_OF_ISBN_FOR_DISPLAY,
                );
            }
        }
        if (isPartOf.identifierEisbn && isPartOf.identifierEisbn.length > 0) {
            this.updateIsPartOfEisbn(isPartOf.identifierEisbn);
            this.autoPopulatedFields.push(AutoPopulateKeys.IS_PART_OF_EISBN);
        }

        if (isPartOf.identifierEissn) {
            this.updateIsPartOfEissn(isPartOf.identifierEissn);
            if (cameFromAutoPopulate) {
                this.autoPopulatedFields.push(
                    AutoPopulateKeys.IS_PART_OF_EISSN,
                );
            }
        }

        if (isPartOf.issue) {
            this.updateIsPartOfIssue(isPartOf.issue);
            if (cameFromAutoPopulate) {
                this.autoPopulatedFields.push(
                    AutoPopulateKeys.IS_PART_OF_ISSUE,
                );
            }
        }

        if (isPartOf.volume) {
            this.updateIsPartOfVolume(isPartOf.volume);
            if (cameFromAutoPopulate) {
                this.autoPopulatedFields.push(
                    AutoPopulateKeys.IS_PART_OF_VOLUME,
                );
            }
        }
        if (isPartOf.partOfIdentifierDoi) {
            this.updateIsPartOfDoi(isPartOf.partOfIdentifierDoi);
            if (cameFromAutoPopulate) {
                this.autoPopulatedFields.push(AutoPopulateKeys.IS_PART_OF_DOI);
            }
        }
        if (isPartOf.nlmAbbrev) {
            this.updateIsPartOfNlmAbbrev(isPartOf.nlmAbbrev);
        }
        const pages = this.getPartOfPages(isPartOf.startPage, isPartOf.endPage);
        if (pages) {
            this.updateIsPartOfPages(pages);
            if (cameFromAutoPopulate) {
                this.autoPopulatedFields.push(
                    AutoPopulateKeys.IS_PART_OF_PAGES,
                );
            }
        }
    }

    getPartOfPages(startPage, endPage) {
        if (!startPage && !endPage) {
            return '';
        }
        if (startPage && endPage) {
            return startPage + '-' + endPage;
        }
        if (startPage) {
            return startPage;
        }
        return endPage;
    }

    enrichFromIssn(issn, enrichTitle) {
        this.depositCitationService.enrichFromIssn(issn).subscribe(
            (data) => {
                this.populatePartOfFormFromIssn(
                    data as EsploroRecord,
                    enrichTitle,
                );
            },
            (error) => {},
        );
    }

    handleKeywordsSave(keywords) {
        keywords.forEach((keyword) => {
            const selectedTopic = new SelectedResearchTopic(
                '',
                keyword,
                '',
                TopicType.KEYWORD,
            );
            this.updateTopics(selectedTopic);
            //  this.keywordsAutoPopulated.push(keyword);
            this.updateKeywords(keyword);
        });
    }

    cleanTopics() {
        this.clearTopics();
        this.clearKeywords();
        this.clearSubjects();
    }

    handleSubjectsSave(subjects: ResearchTopic[]) {
        subjects.forEach((subject) => {
            const selectedTopic = new SelectedResearchTopic(
                subject.code,
                subject.description,
                '',
                TopicType.SUBJECT,
            );
            this.updateTopics(selectedTopic);
            this.updateSubjects(subject.code);
        });
    }

    handleSubjectFor(subjects: ANZTopic[]) {
        subjects.forEach((subject) => {
            this.forSubject.push(this.formBuilder.group(subject));
        });
    }

    handleSubjectSeo(subjects: ANZTopic[]) {
        subjects.forEach((subject) => {
            this.seoSubject.push(this.formBuilder.group(subject));
        });
    }

    autoPopulateKeywords(keywords) {
        keywords.forEach((keyword) => {
            this.keywordsAutoPopulated.push(keyword);
        });
    }

    handleLanguagesSave(languages) {
        this.clearLanguages();
        languages.forEach((language) => {
            this.updateLanguages('research.lang.' + language);
        });
    }

    autoPopulateLanguages(languages) {
        languages.forEach((language) => {
            this.languagesAutoPopulated.push('research.lang.' + language);
        });
    }

    isKeyAutoPopulated(key) {
        return this.autoPopulatedFields.indexOf(key) !== -1;
    }

    removeKeyFromAutoPopulatedArray(key) {
        const index = this.autoPopulatedFields.indexOf(key);
        if (index > -1) {
            this.autoPopulatedFields.splice(index, 1);
        }
    }

    enableFormEnrichment() {
        this.enrichForm = false;
        this._autoPopulatedFields = [];
    }

    isDefaultForm() {
        if (this.resourceTypeTemplates.indexOf(this.resourceType) === -1) {
            return true;
        }
        return false;
    }

    isPublicationJournalArticle() {
        return ResourceTypeUtils.isPublicationJournalArticle(this.resourceType);
    }

    isPublicationBookReview() {
        return ResourceTypeUtils.isPublicationBookReview(this.resourceType);
    }

    isPublicationBookChapter() {
        return ResourceTypeUtils.isPublicationBookChapter(this.resourceType);
    }

    isPublicationConferenceProceedings() {
        return ResourceTypeUtils.isPublicationConferenceProceedings(
            this.resourceType,
        );
    }

    isDataset() {
        return ResourceTypeUtils.isDataset(this.resourceType);
    }

    isPublicationBook() {
        return ResourceTypeUtils.isPublicationBook(this.resourceType);
    }

    isPublication() {
        return ResourceTypeUtils.isPublication(this.resourceType);
    }

    isPublicationForm() {
        return this.isPublication() && !this.isDefaultForm();
    }

    isConference() {
        return ResourceTypeUtils.isConference(this.resourceType);
    }

    isPresentation() {
        return ResourceTypeUtils.isPresentation(this.resourceType);
    }

    isPublicationMoreInformation() {
        return ResourceTypeUtils.isPublicationMoreInformation(
            this.resourceType,
        );
    }

    isPublicationAbstract(): boolean {
        return ResourceTypeUtils.isPublicationAbstract(this.resourceType);
    }

    updateTermsOfUse(flag) {
        this.mainForm.patchValue({ termsOfUse: flag });
    }

    resetForm() {
        this.isDraft = false;
        this.deletedStorageFilesNames = {};
        this._autoPopulatedFields = [];
        this._keywordsAutoPopulated = [];
        this._languagesAutoPopulated = [];
        this.authorsAutoPopulated = [];
        this.linksAutoPopulated = [];
        this.clearFiles();
        this.enrichForm = false;
        this.createForm();
        this.grobidService.grobidExecuted = false;
        this.depositStepThreeService.showAdditionalInfo = false;
        this.depositLastStepService.summaryNotes = false;
        this.depositLastStepService.copyrightsNotes = false;
        this.invalidFiles = [];
    }

    cleanForm() {
        if (!this.pointLatitude.valid) {
            this.pointLatitude.reset('');
        }
        if (!this.pointLongitude.valid) {
            this.pointLongitude.reset('');
        }
        if (!this.boundingBoxSouth.valid) {
            this.boundingBoxSouth.reset('');
        }
        if (!this.boundingBoxWest.valid) {
            this.boundingBoxWest.reset('');
        }
        if (!this.boundingBoxNorth.valid) {
            this.boundingBoxNorth.reset('');
        }
        if (!this.boundingBoxEast.valid) {
            this.boundingBoxEast.reset('');
        }
        if (!this.pmid.valid) {
            this.pmid.reset('');
        }
        if (!this.doi.valid) {
            this.doi.reset('');
        }
        if (!this.isPartOfDoi.valid) {
            this.isPartOfDoi.reset('');
        }
        if (!this.isPartOfIssn.valid) {
            this.isPartOfIssn.reset('');
        }
    }

    //Any subscriptions on the formArray (such as that registered with formArray.valueChanges) will not be lost
    clearFormArray(formArray: UntypedFormArray) {
        while (formArray.length !== 0) {
            formArray.removeAt(0);
        }
    }
}
