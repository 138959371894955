import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OpacityInAnimation, OpacityOutAnimation } from '@exl-ng/mulo-common';

import { ConfigurationHandlerService } from '../core/configuration-handler.service';
import { IndexPageService } from './index-page.service';
import { SEOService } from '../core/seo.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'esp-index-page',
    templateUrl: './index-page.component.html',
    styleUrls: ['./index-page.component.scss'],
    animations: [OpacityInAnimation, OpacityOutAnimation],
})
export class IndexPageComponent {
    indexKey: string;
    indexData: { [key: string]: string[] };
    indexIdx: string;
    delimiter = '____^____^____';

    bttStuck = false;
    loading = true;

    constructor(
        private configService: ConfigurationHandlerService,
        private route: ActivatedRoute,
        private router: Router,
        private indexPageService: IndexPageService,
        private seoService: SEOService,
        private t: TranslateService
    ) {
        route.data.subscribe((routeData) => {
            this.indexKey = routeData.indexKey;
            this.indexPageService
                .getSEOFileIndexByName(this.indexKey)
                .subscribe((data) => {
                    this.indexData = data as { [key: string]: string[] };
                    const idxParam = route.snapshot.params?.['idx'];
                    if (!(this.indexData[idxParam]?.length > 0)) {
                        const firstRealIdx = Object.keys(this.indexData)
                            .sort()
                            .find((key) => this.indexData[key]?.length > 0);

                        router.navigate(['../', firstRealIdx], {
                            relativeTo: route,
                            queryParamsHandling: 'preserve',
                            replaceUrl: true,
                        });
                    }
                    this.loading = false;
                });

            const pageTitle =
                'esploro.research.conf.research.portal.label.prefix.' +
                (this.indexKey === 'researchersIndex'
                    ? 'profileIndexLink'
                    : 'portalIndexLink');
            t.get(pageTitle).subscribe((_) => seoService.updatePageTitle(_));
        });
        route.params.subscribe((params) => (this.indexIdx = params['idx']));
    }
}
