export class Discovery {
    public static RESEARCHERS_BULK_SIZE = 9;
    public static ASSETS_BULK_SIZE = 10;
    public static SORT_BY = 'asc';
    public static FACETS_CALL_RETRIES = 5;
    public static FACETS_CALL_DELAY = 1500;
    public static FACET_JOINER = '|,|';
    public static FACET_DATE_RANGE_SEPERATOR = ' TO ';
    public static SORT_CODE_PREFIX = 'research.results.sortby.option.';
    public static SIMPLE_SEARCH_QUERY_PREFIX = 'any,contains,';
    public static SIMPLE_SEARCH__ALL_QUERY = 'any,contains,*';
    public static CREATORS_ADVANCED_SEARCH_QUERY_PREFIX = 'creator,exact,';
    public static NGX_PAGINATION_MORE_LABEL = '...';
    public static ADVANCED_SEARCH_MAX_QUERY_LINES = 7;
    public static ADVANCED_SEARCH_DATE_FORMAT = 'YYYYMMDD';
    public static INTERNAL_AFFILIATION_START_DATE_FACET = 'researcher_internal_affiliation_start_date_facet';
    public static CREATION_DATE_FACET = 'facet_creationdate';
    public static RESEARCHER_FACETS_MAX_ITEMS_TO_DISPLAY = 20;

}
