import { Component, Input } from '@angular/core';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'mulo-keyword-item',
    templateUrl: './keyword-item.component.html',
    styleUrls: ['./keyword-item.component.scss'],
    host: {
        class: 'mulo-keyword-item',
        '[class.as-block]': 'this.flavor === "block"',
    },
    standalone: true,
    imports: [
        NgIf,
        MatIcon,
        SvgViewboxDirective,
        NgTemplateOutlet,
    ],
})
export class KeywordItemComponent {
  /** Optional URL to make the keyword a link */
  @Input() url: string;
  /** Show/hide the icon */
  @Input() showIcon: boolean = false;
  /** Style of the keyword */
  @Input() flavor: 'block' | 'plain' = 'plain';

  /** The icon to display next the keyword, if displayed */
  @Input() set icon(val) {
    this._icon = val;
  }
  get icon(): string | 'default' | 'none' {
    return this._icon === 'default' ? this._defaultIcon : this._icon;
  }
  @Input() rtlMirrorIcon: boolean;

  _icon = 'default';
  _defaultIcon = 'tag';

  constructor() {}

  setFlavor(val) {
    this.flavor = val;
  }
}
