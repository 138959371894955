import { AfterViewInit, ContentChildren, Directive, ElementRef, Input, QueryList, Renderer2 } from '@angular/core';
import { DescriptionDirective } from './exl-description.directive';

let nextUniqId = 0;

// ? TODO: Enable inserting invisible span to buttons with labelledby to the descriptions labels
/**
 * ! Aceessibility
 * This directive add description to buttons of every list item in a list,
 * using aria-describedby a given element(s) for description.
 */
@Directive({
  selector: '[exlDescriptionA11y]'
})
export class ListitemDescriptionA11yDirective implements AfterViewInit {

    @Input('exlDescriptionA11y')
    get id() { return this._id; }
    set id(value: string) {
        this._id = value || `description-item-${nextUniqId++}`;
    }
    private _id: string;

    /** Holder for the ids stored. needed in case there is more than one description element */
    ids: string[] = [];

    @ContentChildren(DescriptionDirective, {descendants: true, read: ElementRef}) descriptiveLabels: QueryList<ElementRef<HTMLElement>>;

    constructor(private elementRef: ElementRef, private render: Renderer2) {}

    // * keep this in AfterViewInit so all children will set up
    ngAfterViewInit() {
        this.describerLabelElements();

        this.buttonsDescription();
    }

    describerLabelElements(): void {
        this.descriptiveLabels
            .map(el => el.nativeElement)
            .forEach((line, index) => this.storeDescriptiveLabelId(line, index));
    }

    buttonsDescription() {
        const buttons: HTMLElement[] = this.elementRef.nativeElement.querySelectorAll('button, input[type=checkbox]');
        const idsToString = this.ids.filter(id => id != null).join(' ');

        buttons?.forEach(button => this.storeButtonDescription(button, idsToString));
    }

    storeDescriptiveLabelId = (labelElement: HTMLElement, index: number) => {
        const tempId = `${this.id}-${index}`;
        this.render.setAttribute(labelElement, 'id', tempId);
        this.ids.push(tempId);
    }

    storeButtonDescription = (button: HTMLElement, ids: string) => {
        const oldDescriptionIds = button.getAttribute('aria-describedby') || '';
        this.render.setAttribute(button, 'aria-describedby', `${ids} ${oldDescriptionIds}`);
    }

}
