import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'esp-license-select-dialog',
    templateUrl: './license-select-dialog.component.html',
    styleUrls: ['./license-select-dialog.component.scss']
})
export class LicenseSelectDialogComponent {

    licenses = [];
    applyToAll = false;
    saveButton = false;

    constructor(public dialogRef: MatDialogRef<LicenseSelectDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        data.licenseCodes.forEach(row => {
            const licenseRow: any = new Object();
            licenseRow['title'] = row.source1;
            licenseRow['category'] = row.source2;
            licenseRow['link'] = row.source3;
            licenseRow['forResearcherView'] = row.source5;

            this.licenses.push(licenseRow);
        });
    }

    selectLicense(license){
        const obj = new Object();
        obj['license'] = license;
        obj['applyToAll'] = this.applyToAll;
        this.dialogRef.close(obj);
    }

    showLicenseInfo(event){
        event.stopPropagation();
    }

    checkboxChanged(){
        if (this.data.selected) {
            this.saveButton = true;
        }
    }

    onSave(){
        const license = this.licenses.filter(obj => obj.title === this.data.selected)[0];
        this.selectLicense(license);
    }


}
