import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {ConfigurationHandlerService} from '../core/configuration-handler.service';
import {RouterService} from './router.service';

@Injectable()
export class ResearchUnitsGuard  {

    constructor(private routerService: RouterService,
                private configurationHandlerService: ConfigurationHandlerService){}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.isActiveResearchUnitsBrowse();
    }

    isActiveResearchUnitsBrowse(): boolean {
        if(this.configurationHandlerService.getCustomerParameter('esploro_hide_link_to_org_unit_browse')
            === 'false'){
            return true;
        }
        this.routerService.goToHomepage();
        return false;
    }



}
