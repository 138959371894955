import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'muloHighlightText',
    standalone: true
})
export class HighlightTextPipe implements PipeTransform {
  transform(text: string, search): string {
    if (search && text) {
      let textString = text.toString();
      let pattern = search
        .toString()
        .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
      pattern = pattern
        .split(' ')
        .filter((t) => {
          return t.length > 0;
        })
        .join('|');
      const regex = new RegExp(pattern, 'gi');

      return textString.replace(
        regex,
        (match) => `<span class="highlight">${match}</span>`
      );
    } else {
      return text;
    }
  }
}


