import {Directive,  HostListener, EventEmitter, Output} from '@angular/core';
import { DepositFormOverlayService } from '../deposit/deposit-form-overlay/deposit-form-overlay.service';
import {ProfileService} from '../profile/profile.service';
import {DepositKeywordsSuggestionsService} from './deposit-sections/deposit-keywords/deposit-keywords-suggestions.service';

@Directive({
    selector: '[espDepositFormTrigger]'
})
export class DepositFormTriggerDirective {

    @Output() overlayClosed = new EventEmitter<null>();

    @HostListener('click') onClick() {
        this.launchDepositForm();
    }

    constructor(private depositFormOverlay: DepositFormOverlayService,
                private profileService: ProfileService,
                private suggestionsService: DepositKeywordsSuggestionsService) {
    }

    launchDepositForm(){
        this.depositFormOverlay
            .open()
            .afterClosed()
            .subscribe(res => {
                this.overlayClosed.emit(null);
            });
        this.suggestionsService.clearSuggestions();
        this.profileService.retrieveProfileData();
    }

}
