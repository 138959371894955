<mat-card appearance="outlined">
    <form #loginForm>
        <div class="login-form">
            <div style="color:red" *ngIf="error" class="margin-bottom-small">{{errorMessage | translate}}</div>
            <mat-form-field class="is-auto-filling">
                <input matInput
                       name="user"
                       type="text"
                       required
                       [(ngModel)]="user"
                       #name="ngModel">
                <mat-label>{{'research.login.username' | translate}}</mat-label>

                <mat-error *ngIf="name?.errors?.required">
                    {{'research.login.username.error' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="is-auto-filling">
                <input matInput
                       required
                       type="password"
                       name="password"
                       [(ngModel)]="password"
                       #pass="ngModel">
                <mat-label>{{'research.login.password' | translate}}</mat-label>
                <mat-error *ngIf="pass?.errors?.required">
                    {{'research.login.password.error' | translate}}
                </mat-error>
            </mat-form-field>
            <button mat-raised-button
                    type="submit"
                    color="primary"
                    class="submit-button is-large"
                    [class.is-processing]="submitInProgress"
                    attr.aria-label="{{'research.aria.sign.in' | translate}}"
                    (click)="doSignIn()">
                <ng-container *ngIf="!submitInProgress">{{'research.login.sign.in' | translate}}</ng-container>
                <mat-spinner *ngIf="submitInProgress" diameter="30"></mat-spinner>
            </button>
            <a *ngIf="resetPwUrl && showResetPwUrl"
                class="margin-top-xs"
                color="primary"
                role="button"
                mat-button
                [href]="resetPwUrl"
                attr.aria-label="{{ 'research.aria.reset.pw.link' | translate }}"
            >
                {{ 'research.reset.password.link' | translate }}
        </a>
        </div>
    </form>
</mat-card>
