<mulo-brief
    [type]="type | translate"
    [postType]="postType"
    [status]="status | translate"
    [dateLabel]="dateLabel | translate"
    [created]="created"
    [modified]="modified"
    [thumbs]="thumbs"
    [thumbsWidth]="thumbsWidth"
    [title]="title"
    [titleAsLink]="titleAsLink"
    [titleUrl]="titleUrl"
    [labelOpenInNewWindow]="'research.aria.output.list.title.open.asset' | translate: { value: (title | htmlStrip) }"
    [routerLinkUrl]="routerLinkUrl"
    [queryParams]="queryParams"
    [titleClickable]="titleClickable"
    [titleHeaderLevel]="titleHeaderLevel"
    (titleClick)="onTitleClick($event)"
    (actionClick)="onAction($event)"
    [minimal]="minimal"
    [hidden]="hidden"
    [disabled]="disabled"
    [hoverable]="hoverable"
    [id]="id + '_0'"
>
    <ng-container slot="pre-brief">
        <ng-content select="[slot='pre-brief']"></ng-content>
        <span #exlSlotPreBrief class="pre-brief">
            <ng-content select="[exl-slot-pre-brief]"></ng-content>
        </span>
    </ng-container>
    <ng-container slot="pre-title">
        <ng-content select="[slot='additional-status-tags']"></ng-content>
        <ng-content select="[slot='pre-title']"></ng-content>
    </ng-container>
    <ng-container slot="post-title">
        <ng-content select="[slot='post-title']"></ng-content>
        <!-- authors -->
        @if (authors && authors.length > 0) {
            <p class="authors">
                @if (useByForAuthors === true) {
                    <ng-container>{{ 'research.output.list.authors.display.by' | translate }}&nbsp;</ng-container>
                } @else if (useByForAuthors) {
                    <ng-container>{{ useByForAuthors }}:&nbsp;</ng-container>
                }
                @for (author of authors | slice: 0 : maxVisibleAuthors; track $index) {
                    @if (authors.length > 1) {
                        @if ($last) {
                            <ng-container
                                >&nbsp;{{ 'research.output.list.authors.display.and' | translate }}&nbsp;</ng-container
                            >
                        } @else if (!$first) {
                            <ng-container>,&nbsp;</ng-container>
                        }
                    }
                    <span class="semibold">{{ author }}</span>
                }
                @if (authors.length > maxVisibleAuthors) {
                    <span class="italics">
                        ... ({{
                            'research.output.list.authors.display.number.of.authors'
                                | translate: { val0: authors.length }
                        }})
                    </span>
                }
            </p>
        }

        @if (publication) {
            <p class="line">{{ publication }}</p>
        }
        @if (metadata) {
            <p class="line">{{ metadata }}</p>
        }
        @if (dateLine) {
            <p class="line">{{ dateLine.code | translate }} {{ dateLine.value }}</p>
        }

        <!-- display lines -->
        @for (line of lines; track $index) {
            <p class="line">{{ line | htmlStrip }}</p>
        }
    </ng-container>
    <ng-container slot="actions">
        <ng-content select="[slot='actions']"></ng-content>

        <!-- item actions, menu and stats -->
        <span class="actions">
            @if (haveActionsOrMenuActions) {
                <span class="actions-top">
                    <ng-content select="[exl-slot-custom-actions]"></ng-content>
                    @for (action of actions; track action.name) {
                        @if (action.icon) {
                            <button
                                mat-icon-button
                                (click)="onAction(action.name)"
                                [matTooltip]="action.tooltip | translate"
                                attr.aria-label="{{ action.name | translate }} {{ title | htmlStrip }}"
                            >
                                <mat-icon [svgIcon]="action.icon"></mat-icon>
                            </button>
                        } @else {
                            <button
                                mat-button
                                (click)="onAction(action.name)"
                                [matTooltip]="action.tooltip | translate"
                                attr.aria-label="{{ action.name | translate }} {{ title | htmlStrip }}"
                            >
                                <span>{{ action.name | translate }}</span>
                            </button>
                        }
                    }
                    <!-- menu button -->
                    @if (menuActions) {
                        <button
                            mat-icon-button
                            [matTooltip]="'research.output.list.menu.more.options.tooltip' | translate"
                            [disableRipple]="false"
                            [matMenuTriggerFor]="menu"
                            [attr.aria-label]="'research.aria.output.list.menu.more.options' | translate"
                            [attr.aria-describedby]="id + '_0_title'"
                            #menuBtn
                        >
                            <mat-icon svgIcon="dots-vertical"></mat-icon>
                        </button>
                    }

                    <!-- Menu -->
                    <mat-menu #menu="matMenu" [overlapTrigger]="media.is('lt-md')">
                        @for (action of menuActions; track action.name) {
                            @if (action?.divider === 'before') {
                                <mat-divider></mat-divider>
                            }
                            <button mat-menu-item (click)="onAction(action.name)" [disabled]="action.disabled">
                                {{ action.name | translate }}
                            </button>
                            @if (action?.divider === 'after') {
                                <mat-divider></mat-divider>
                            }
                        }
                    </mat-menu>
                </span>
            }

            <span class="actions-bottom">
                @if (doi && altmetric) {
                    <span class="margin-right-xs">
                        <div
                            class="altmetric-embed"
                            data-badge-type="donut"
                            data-link-target="_blank"
                            [attr.data-doi]="doi"
                            data-badge-popover="left"
                            data-hide-no-mentions="true"
                        ></div>
                    </span>
                }
            </span>
        </span>
    </ng-container>

    @if (images) {
        <span class="media">
            @for (img of images; track img.url) {
                <img [src]="img.url" [alt]="img.alt" />
            }
        </span>
    }

    <!-- This where dynamic content goes -->
    <span class="dynamic-content">
        <ng-content></ng-content>
    </span>
</mulo-brief>
