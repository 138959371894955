import { Injectable } from '@angular/core';

@Injectable()
export class StorageUtilService {

    constructor(){
    }

    setObject(key: string, value: any){
        localStorage.setItem(key,JSON.stringify(value));
    }

    getObject(key: string){
        return JSON.parse(localStorage.getItem(key));
    }

    setItem(key: string, value: string){
        localStorage.setItem(key,value);
    }

    removeItem(key: string){
        localStorage.removeItem(key);
    }

    getItem(key: string){
        return localStorage.getItem(key);
    }

    check(key: string) {
        return this.getItem(key) != null;
    }

    clear(){
        const isCookiesApproved = this.check('esploro_irus_uk');
        localStorage.clear();
        if (isCookiesApproved) {
            this.setItem('esploro_irus_uk', 'Approved');
        }
    }



}
