<div>
    <div>
        <mat-form-field *ngIf="!isPosted" style="min-width:350px" [class.is-autopopulated]="isAutoPopulated('relationTitle')">
            <input matInput
                   [formControl]="depositFormDataService.relationTitle"
                   [matAutocomplete]="auto"
                   placeholder="{{'research.deposit.searchable.fields.placeholder' | translate}}">
            <mat-label>{{'research.status.title' | customTranslate}}</mat-label>
            <mat-icon matPrefix
                      *ngIf="isAutoPopulated('relationTitle')"
                      svgIcon="auto-populate"
                      matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
            <mat-progress-bar mode="indeterminate" color="primary" *ngIf="searchTitleInProgress"></mat-progress-bar>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let result of czSearchResults"
                            [value]="result.title"
                            (onSelectionChange)="onJournalNameSelect(result)"
                            [disabled]="searchTitleInProgress">
                    <span class="semibold">{{result.title}}</span>
                    <!--<span [innerHTML]="result.title" | highlight: depositFormData.isPartOfIssn></span>-->
                </mat-option>
                <!--<mat-option *ngIf="depositFormData.journalName && noValuesFound && !searchTitleInProgress">-->
                <!--<span>no values found</span>-->
                <!--</mat-option>-->
            </mat-autocomplete>

        </mat-form-field>

        <mat-form-field *ngIf="!isPosted" [class.is-autopopulated]="isAutoPopulated('isPartOfIssn')" class="is-short-input">
            <input matInput
                   [formControl]="depositFormDataService.isPartOfIssn"
                   placeholder="{{'research.deposit.searchable.fields.placeholder' | translate}}">
            <mat-label>{{'research.status.issn' | customTranslate}}</mat-label>
            <mat-error>
                {{'research.status.issn.error' | customTranslate}}
            </mat-error>
            <mat-icon matPrefix
                      *ngIf="isAutoPopulated('isPartOfIssn')"
                      svgIcon="auto-populate"
                      matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
            <mat-progress-bar mode="indeterminate" color="primary" *ngIf="searchIssnInProgress"></mat-progress-bar>
        </mat-form-field>
    </div>

    <div>

        <mat-form-field *ngIf="displayPmid" [class.is-autopopulated]="isAutoPopulated('pmid')">
            <input matInput
                   [formControl]="depositFormDataService.pmid">
            <mat-label>{{'research.status.pmid' | customTranslate}}</mat-label>
            <mat-error>
                {{'research.status.pmid.error' | customTranslate}}
            </mat-error>
            <mat-icon matPrefix
                      *ngIf="isAutoPopulated('pmid')"
                      svgIcon="auto-populate"
                      matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
        </mat-form-field>

        <mat-form-field *ngIf="displayVolume" [class.is-autopopulated]="isAutoPopulated('isPartOfVolume')" class="is-short-input">
            <input matInput
                   [formControl]="depositFormDataService.isPartOfVolume">
            <mat-label>{{'research.status.volume' | customTranslate}}</mat-label>
            <mat-icon matPrefix
                      *ngIf="isAutoPopulated('isPartOfVolume')"
                      svgIcon="auto-populate"
                      matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
        </mat-form-field>

        <mat-form-field *ngIf="displayIssue" [class.is-autopopulated]="isAutoPopulated('isPartOfIssue')" class="is-short-input">
            <input matInput
                   [formControl]="depositFormDataService.isPartOfIssue">
            <mat-label>{{'research.status.issue' | customTranslate}}</mat-label>
            <mat-icon matPrefix
                      *ngIf="isAutoPopulated('isPartOfIssue')"
                      svgIcon="auto-populate"
                      matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
        </mat-form-field>

        <mat-form-field *ngIf="displayPages" [class.is-autopopulated]="isAutoPopulated('isPartOfPages')"  class="is-short-input">
            <input matInput
                   [formControl]="depositFormDataService.isPartOfPages">
            <mat-hint align="start">{{'research.status.page.hint' | customTranslate}}</mat-hint>
            <mat-label>{{'research.status.pages' | customTranslate}}</mat-label>
            <mat-icon matPrefix
                      *ngIf="isAutoPopulated('isPartOfPages')"
                      svgIcon="auto-populate"
                      matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
        </mat-form-field>


    </div>
</div>
