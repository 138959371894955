import { Component, Input, HostBinding, ElementRef } from '@angular/core';
import classNames from 'classnames';
import { TranslateModule } from '@ngx-translate/core';
import { ChatBubbleComponent } from '../chat-bubble/chat-bubble.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'mulo-chat-entry',
    templateUrl: './chat-entry.component.html',
    styleUrls: ['./chat-entry.component.scss'],
    host: { class: 'mulo-chat-entry' },
    standalone: true,
    imports: [
        NgClass,
        ChatBubbleComponent,
        TranslateModule,
    ],
})
export class ChatEntryComponent {
  @Input() content;
  @Input() time;
  @Input() sender;
  @Input() senderTitle;
  @Input() color: 'accent' | 'primary' = 'accent';
  @Input() align: 'left' | 'right' = 'left';
  @Input() index;
  @Input() reasonPrefix = 'Return reason:';
  classList;

  @HostBinding('class') get classes(): string {
    return classNames(
      this.classList,
      'mulo-' + this.color,
      'align-' + this.align,
      'from-' + this.sender,
      'chat-bubble-' + this.index
    );
  }

  constructor(private elRef: ElementRef) {
    this.classList = elRef.nativeElement.className;
  }
}
