import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs';
import { SettingsDataService } from '../../settings/settings-data.service';
import { Settings } from '../../shared/configurations/settings.constant';
import { ResearcherOrganization } from '../../shared/interfaces/researcher-organization.interface';
import { InternalOrganizationService } from './internal-organization.service';

@Component({
    selector: 'esp-internal-organization-autocomplete-input',
    templateUrl: './internal-organization-autocomplete-input.component.html',
    styleUrls: ['./internal-organization-autocomplete-input.component.scss'],
})
export class InternalOrganizationAutocompleteInputComponent implements OnInit {
    @Input() internalOrgControl = new UntypedFormControl();
    @Input() label: string;
    @Input() placeholder: string;
    @Input() required: boolean;
    @Input() classField: string;
    @Input() errorMessage = 'research.field.required.error.message';

    @Output() departmentChange: EventEmitter<ResearcherOrganization> =
        new EventEmitter();

    departmentSearchInProgress = false;
    displayDepartmentInput = true;
    organizationsFilterCtrl = new UntypedFormControl('');
    filteredOrganizations$;
    @ViewChild('departmentInput', { static: false }) departmentInputElem;

    private destroy = new Subject();

    constructor(
        public settingsDataService: SettingsDataService,
        private internalOrganizationService: InternalOrganizationService
    ) {}

    ngOnInit(): void {
        this.internalOrganizationService.internalOrg.subscribe((data) => {
            this.filteredOrganizations$ =
                this.organizationsFilterCtrl.valueChanges.pipe(
                    startWith(data),
                    distinctUntilChanged(),
                    takeUntil(this.destroy),
                    map(() => this.filterOrganizations())
                );
        });
    }

    filterOrganizations() {
        if (!this.internalOrganizationService.departments) {
            return;
        }

        const search: string = this.organizationsFilterCtrl.value
            ?.toString()
            .toLowerCase();
        if (typeof search !== 'string') {
            return;
        }

        return this.internalOrganizationService.departments?.filter((option) =>
            option.organizationName?.toLowerCase()?.includes(search)
        );
    }

    get numberOfItemsToDisplay() {
        return Settings.MAX_DISPLAYED_ITEMS_IN_DROP_DOWN;
    }

    onDepartmentSelect(department: ResearcherOrganization, event: any) {
        if (
            !event.isUserInput ||
            this.internalOrgControl.value === department?.organizationCode ||
            this.internalOrgControl.value === department?.organizationName
        ) {
            return;
        }
        this.departmentChange.emit(department);
    }
}
