import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {RestApiBase} from '../../../shared/configurations/rest-api-base.config';
import {StorageUtilService} from '../../../core/storage-util.service';
import {UrlUtils} from '../../../shared/utils/url.utils';
import {DepositFormService} from '../../../deposit/deposit-form.service';

@Injectable()
export class FileUploadService {

    institutionCode: string;

    constructor(private http: HttpClient,
                private storageUtilService: StorageUtilService,
                private depositFormService: DepositFormService) {
        this.institutionCode = UrlUtils.getParam('institution');
    }


    uploadS3(file, _amazonInfo = null) {
        const formData: FormData = new FormData();
        const contentType = file.type === null || file.type === '' ? 'application/octet-stream' : file.type;
        const amazonInfo = _amazonInfo ?? this.depositFormService.amazonInfo;//this.storageUtilService.getObject('amazon_info');
        const url = 'https://' + amazonInfo.endpoint + '/' + amazonInfo.bucketName + '/';

        formData.append('key', amazonInfo.uploadPath + file.name);
        formData.append('policy', amazonInfo.policyV4);
        formData.append('X-Amz-Algorithm','AWS4-HMAC-SHA256');
        formData.append('X-Amz-Credential', amazonInfo.credential);
        formData.append('X-Amz-Date', amazonInfo.date);
        formData.append('X-Amz-Signature', amazonInfo.signatureV4);
        formData.append('acl', 'public-read');
        formData.append('success_action_status', '201');
        formData.append('Content-Type', contentType);
        formData.append('file', file, file.name);

        return this.http.post(url,formData, {reportProgress: true, observe: 'events', responseType: 'text'});
    }

    deleteFileFromUpload(directoryPath:string, fileName:string){
        const params = new HttpParams().set('fileName', fileName).set('directoryPath',directoryPath)
            .set('institution', this.institutionCode);
        return this.http.delete(RestApiBase.DELETE_FILE_FROM_UPLOAD, {params:params});
    }
}
