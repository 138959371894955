import {
  AfterViewInit,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  QueryList,
  Renderer2,
} from '@angular/core';
import {
  CdkDrag,
  CdkDropList,
  DragDrop,
  DragRef,
} from '@angular/cdk/drag-drop';
import {
  AriaDescriber,
  FocusableOption,
  FocusKeyManager,
  FocusMonitor,
  FocusTrap,
  FocusTrapFactory,
} from '@angular/cdk/a11y';
import { MatChip, MatChipGrid, MatChipListbox } from '@angular/material/chips';
import { MatSelectionList } from '@angular/material/list';
import { merge, tap } from 'rxjs';

interface DropListA11yMove {
  delta: -1 | 1;
  event: KeyboardEvent;
  currentIndex: number;
  previousIndex: number;
}

@Directive({
  selector: '[muloDropListA11y]',
  standalone: true,
  host: {
    '(focusout)': 'onFocusOut($event)',
    '(blur)': 'onBlur($event)',
    '(keydown)': 'onKeydown($event)',
    '(focus)': 'onContainerFocus()',
  },
})
export class DropListA11yDirective implements OnDestroy, AfterViewInit {
  @Input() muloDropListEditClass = 'mulo-a11y-list-in-edit';
  @Input() muloDropListMoveClass = 'mulo-a11y-item-in-transit';
  @Input() muloDropListFocusClass = 'mulo-a11y-item-in-focus';
  @Input() muloDropListDragClass: string | null = null;

  /**
   * The WIA-ARIA role description
   *
   * @see {@link https://www.w3.org/TR/wai-aria-1.2/#aria-roledescription}
   */
  @Input() muloDropListA11yRoleDesc = 'reorderable list';
  @HostBinding('attr.aria-roledescription') _a11yRoleDesc;

  @Input() muloDropListA11yDesc =
    'Press space bar to toggle drag/drop, use the arrow keys to move selected item.';

  @Input() muloDropListOrientation: 'vertical' | 'horizontal';
  @Input() muloDropListDirection: 'ltr' | 'rtl' = 'ltr';

  @ContentChildren(CdkDrag, { descendants: true })
  items: QueryList<FocusableOption & CdkDrag>;

  /**
   * Emits when the user drops an item inside the container.
   */
  @Output()
  muloDropListA11yEditing: EventEmitter<boolean> = new EventEmitter();
  activeItemIndex = -1;

  /**
   * @ignore
   */
  private keyManager: FocusKeyManager<CdkDrag>;
  private focusInItem = false;
  private focusedElem;
  private wrapping = true;
  private doingFocus = false;

  private ft: FocusTrap;
  constructor(
    private dd: DragDrop,
    private elem: ElementRef,
    private renderer: Renderer2,
    private focusMonitor: FocusMonitor,
    private cdl: CdkDropList,
    @Optional() private matChipList: MatChipListbox,
    @Optional() private matChipGrid: MatChipGrid,
    @Optional() private matSelectionList: MatSelectionList,
    private ariaDescriber: AriaDescriber,
    private ftf: FocusTrapFactory,
  ) {
    // if selection list, set it to not allow multiple selections (has to happen before list init)
    if (matSelectionList) {
      this.matSelectionList.multiple = false;
    }
    if (matChipList || matChipGrid) {
      this.wrapping = false;
    }
  }

  /**
   * @ignore
   */
  private _editing = false;

  get editing(): boolean {
    return this._editing;
  }

  set editing(editing: boolean) {
    console.group('DropListA11yDirective.editing', editing);
    const el = this.elem?.nativeElement;

    console.log('activeItemIndex', this.activeItemIndex);
    if (editing) {
      this.renderer.addClass(el, this.muloDropListEditClass);
      this.setItemClass(this.activeItemIndex, 'active');
      // this.cdl._dropListRef.start();
      if (this.activeItemIndex > -1) {
        const item = this.items.get(this.activeItemIndex);
        const chipSet = this.matChipGrid || this.matChipList;
        // chipSet._chips.get(this.activeItemIndex).focus();
        // this.ft = this.ftf.create(item.element.nativeElement);
        // this.ft.attachAnchors();
        // this.ft.enabled = true;
        // (this.items.get(this.activeItemIndex) as CdkTrapFocus).enabled = true;
        // item.element.nativeElement.dispatchEvent(new DragEvent('dragstart'));
      }
    } else {
      this.renderer.removeClass(el, this.muloDropListEditClass);
      this.setItemClass(this.activeItemIndex, 'focus');
      if (this.activeItemIndex > -1) {
        const item = this.items.get(this.activeItemIndex);
        // this.items.get(this.activeItemIndex).released.emit();
        // item.element.nativeElement.dispatchEvent(new DragEvent('drop'));
      }
    }

    if (editing !== this._editing) {
      this.muloDropListA11yEditing.emit(editing);
    }
    this._editing = editing;
    console.groupEnd();
  }

  ngAfterViewInit() {
    this.focusMonitor.monitor(this.elem, true).subscribe((origin) => {
      // this.focusedElem = this.elem;
      console.log('focusMonitor', this.elem, origin);
      if (origin == null && !this.editing) {
        this.activeItemIndex = -1;
        if (this.keyManager) {
          this.keyManager.updateActiveItem(-1);
        }
        this.renderer.removeClass(
          this.elem.nativeElement,
          this.muloDropListEditClass,
        );
        this.setListTabindex('0');
      } else {
        this.renderer.addClass(
          this.elem.nativeElement,
          this.muloDropListEditClass,
        );
        this.setListTabindex('-1');
      }
    });

    // Initialize drag directive to each list item
    this.items.forEach((item, idx) => {
      // item.element.nativeElement.dataset['index'] = idx.toString();
      this.initDrag(item._dragRef);
    });

    // If the list is mat-chips list, subscribe to its existing focus state
    const matChipSet = this.matChipList || this.matChipGrid;
    if (matChipSet) {
      const listbox = this.matChipList as MatChipListbox;
      if (listbox?.selectable) {
        listbox.selectable = false;
      }
      matChipSet._chips.changes.subscribe((change: QueryList<any>) =>
        change.forEach((item: MatChip, idx) => {
          if (item?._elementRef?.nativeElement?.dataset['dragInit'] != 'true') {
            // item._elementRef.nativeElement.dataset['index'] = idx.toString();
            const dragRef = this.dd.createDrag(item._elementRef);
            this.initDrag(dragRef);
          }
        }),
      );
      matChipSet.chipFocusChanges.subscribe((ev) => {
        console.group('DropListA11yDirective.chipFocusChanges');
        console.log('ev', ev);
        matChipSet._chips.forEach((chip, idx) => {
          if (ev.chip === chip) {
            console.log('ev.chip === chip', idx);
            this.activeItemIndex = idx;
          }
        });
        console.groupEnd();
      });
    } //else {
    // For all other lists, create a new key manager and subscribe to it
    this.keyManager = new FocusKeyManager(this.items)
      .withAllowedModifierKeys(['shiftKey'])
      .withHorizontalOrientation(this.muloDropListDirection)
      .withWrap(this.wrapping);

    merge(
      this.keyManager.change.pipe(
        tap((idx) => {
          this.activeItemIndex = idx;
          this.setItemClass(idx, this._editing ? 'active' : 'focus');
        }),
      ),
      this.keyManager.tabOut.pipe(tap(() => this.onListBlur())),
    ).subscribe();
    //}
  }

  setListTabindex = (val) =>
    this.renderer.setAttribute(this.elem.nativeElement, 'tabindex', val);

  /**
   * This is required here, even as empty, to trigger all unsubscriptions
   *
   * @ignore
   */
  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy() {}

  /**
   * @ignore
   */
  onFocusOut(ev: FocusEvent) {
    console.group('DropListA11yDirective.onFocusOut');
    console.log('this.editing', this.editing);
    console.log('ev.target', ev.target);
    console.log('ev.relatedTarget', ev.relatedTarget);
    const isAnyItemFocused = this.items.some(
      (item) => item.getRootElement() == this.focusedElem,
    );
    console.log('isAnyItemFocused', isAnyItemFocused);
    if (!this.editing && (!ev.relatedTarget || !isAnyItemFocused)) {
      // disable edit mode on tabOut for matChipList
      this.onListBlur();
    } else if (isAnyItemFocused && !this.doingFocus) {
      this.doingFocus = true;
      this.focusedElem.focus();
      setTimeout(() => (this.doingFocus = false));
    }
    ev.preventDefault();
    console.groupEnd();
  }
  onBlur(ev: FocusEvent) {
    console.group('DropListA11yDirective.onBlur');
    console.log('this.editing', this.editing);
    console.log('ev', ev);
    console.log('this.matChipGrid.focused', this.matChipGrid.focused);
    console.groupEnd();
  }

  /**
   * @ignore
   */
  onContainerFocus() {
    console.group('DropListA11yDirective.onContainerFocus');
    if (this.matChipList == null && this.matChipGrid == null) {
      // as soon as the container gets focus, we want to redirect it
      // MatChipList handles this on its own, so it's not needed there
      this.keyManager.setFirstItemActive();
    } else {
      this.matChipList?.focus();
      this.matChipGrid?.focus();
    }
    console.groupEnd();
  }

  /**
   * @ignore
   */
  onKeydown(ev) {
    console.group('keydown', ev);
    console.log('this.editing', this.editing);
    if (
      this.matChipList == null &&
      this.matChipGrid == null &&
      this.focusInItem
    ) {
      this.keyManager.onKeydown(ev);
    }

    let direction: -1 | 1 = 1;
    switch (ev.code) {
      case 'ArrowUp':
      case 'ArrowLeft':
        direction = -1;
      // eslint-disable-next-line no-fallthrough
      case 'ArrowDown':
      case 'ArrowRight':
        if (this.editing) {
          const oldIdx =
            (this.activeItemIndex + (this.items.length - direction)) %
            this.items.length;

          console.log('oldIdx', oldIdx);
          console.log('this.items.length', this.items.length);
          console.log('this.activeItemIndex', this.activeItemIndex);
          this.setItemClass(this.activeItemIndex, 'active');
          const item = this.items.get(this.activeItemIndex);
          const itemRect = item.element.nativeElement.getBoundingClientRect();

          // this.cdl._dropListRef.drop(
          //   item._dragRef,
          //   this.activeItemIndex,
          //   oldIdx,
          //   this.cdl._dropListRef,
          //   true,
          //   {
          //     x: itemRect.width,
          //     y: itemRect.height,
          //   },
          //   direction === 1
          //     ? {
          //         x: itemRect.right,
          //         y: itemRect.bottom,
          //       }
          //     : {
          //         x: itemRect.left,
          //         y: itemRect.top,
          //       },
          // );
          // this.cdl.sorted.emit({
          //   item: this.items.get(this.activeItemIndex),
          //   container: this.cdl,
          //   previousIndex: oldIdx,
          //   currentIndex: this.activeItemIndex,
          // });
          this.cdl.dropped.emit({
            item: this.items.get(this.activeItemIndex),
            container: this.cdl,
            previousContainer: this.cdl,
            previousIndex: oldIdx,
            currentIndex: this.activeItemIndex,
            isPointerOverContainer: false,
            distance: { x: itemRect.width, y: itemRect.height },
            dropPoint:
              direction === 1
                ? {
                    x: itemRect.right,
                    y: itemRect.bottom,
                  }
                : {
                    x: itemRect.left,
                    y: itemRect.top,
                  },
            event: ev,
          });
          // item.setFreeDragPosition(
          //   direction === 1
          //     ? {
          //         x: itemRect.right,
          //         y: itemRect.bottom,
          //       }
          //     : {
          //         x: itemRect.left,
          //         y: itemRect.top,
          //       },
          // );
        } else {
          this.setItemClass(this.activeItemIndex, 'focus');
        }
        // ev.stopPropagation();
        // ev.stopImmediatePropagation();
        ev.preventDefault();
        break;
      case 'Space':
        if (this.activeItemIndex !== -1) {
          this.editing = !this.editing;
        }
        ev.preventDefault();
      // eslint-disable-next-line no-fallthrough
      case 'Enter':
      case 'NumpadEnter':
        ev.target.dispatchEvent(new MouseEvent('click', { ...ev }));
        break;
    }
    console.groupEnd();
  }

  initDrag(item: DragRef) {
    // this.renderer.setAttribute(item.element?.nativeElement, 'tabindex', '0');
    console.group('DropListA11yDirective.initDrag');
    const rootElem = item.getRootElement();
    if (rootElem) {
      rootElem.dataset['dragInit'] = 'true';

      // item.element?.nativeElement?.addEventListener('blur', (_) =>
      //   this.onBlur(_),
      // );
      // item.element?.nativeElement?.addEventListener('focusout', (_) =>
      //   this.onFocusOut(_),
      // );
      // this.ftf.create(item.element.nativeElement);
      // if (this.matChipList == null && this.matChipGrid == null) {
      this.focusMonitor.monitor(rootElem, false).subscribe((origin) => {
        this.focusedElem = rootElem;
        console.group('focusMonitor2', rootElem, origin);
        console.log(this.activeItemIndex);
        if (this.editing) {
          this.focusInItem = true;
        } else if (origin == null) {
          this.focusInItem = false;
        } else {
          this.focusInItem = true;
          // this.activeItemIndex = Number.parseInt(rootElem.dataset?.['index']);
          if (this.keyManager) {
            this.keyManager.updateActiveItem(this.activeItemIndex);
          }
        }
        console.groupEnd();
      });
      // }
    }
    console.groupEnd();
  }

  /**
   * @ignore
   */
  onListBlur() {
    console.group('DropListA11yDirective.onListBlur');
    this.activeItemIndex = -1;
    this.editing = false;
    if (this.keyManager) {
      this.keyManager.updateActiveItem(-1);
    }
    this.renderer.removeClass(
      this.elem.nativeElement,
      this.muloDropListEditClass,
    );
    console.groupEnd();
  }

  /**
   * @ignore
   */
  setItemClass(idx: number, mode?: 'active' | 'focus') {
    if (this.matSelectionList != null) {
      this.matSelectionList.deselectAll();
    }

    let nextItemElem;
    this.items.forEach((item: CdkDrag, i) => {
      this.renderer.removeClass(
        item.element?.nativeElement,
        this.muloDropListFocusClass,
      );
      this.renderer.removeClass(
        item.element?.nativeElement,
        this.muloDropListMoveClass,
      );
      if (i === idx) {
        nextItemElem = item.element?.nativeElement;
      }
    });

    if (idx >= 0) {
      if (mode) {
        this.renderer.addClass(nextItemElem, this.muloDropListFocusClass);
        if (mode === 'active') {
          this.renderer.addClass(nextItemElem, this.muloDropListMoveClass);
          if (this.matSelectionList != null) {
            this.matSelectionList.options.toArray()[idx].selected = true;
          }
        }
      }
    }
  }
}
