import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'exl-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

    actionFocus = 'confirm';
    accentColor = 'accent';
    @ViewChild('confirm', {static: false}) confirm: ElementRef;
    @ViewChild('cancel', {static: false}) cancel: ElementRef;
    titleParam = {};
    messageParam = {};
    useCustomPipe = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        if (this.data.actionFocus) {
            this.actionFocus = this.data.actionFocus;
        }
        if (this.data.titleParam){
            this.titleParam['value'] = this.data.titleParam;
        }
        if (this.data.param){
            this.messageParam['value0'] = this.data.param;
        }
        if (this.data.useCustomPipe){
            this.useCustomPipe = true;
        }
    }
}
