import {Status} from '../configurations/status.constant';

export class StatusUtils {


    public static isPosted(status) {
          return status === Status.POSTED;
    }

    public static displayVolume(status){
        return status === Status.ACCEPTED || status === Status.ONLINE_PUBLICATION || status === Status.PUBLISHED;
    }

    public static displayPmid(status){
        return status === Status.ONLINE_PUBLICATION || status === Status.PUBLISHED;
    }

    public static displayIssue(status){
        return status === Status.ACCEPTED || status === Status.ONLINE_PUBLICATION || status === Status.PUBLISHED;
    }

    public static displayPages(status){
        return status === Status.ONLINE_PUBLICATION || status === Status.PUBLISHED;
    }

    public static displaySiteName(status) {
        return status === Status.POSTED;
    }

    public static displayISBN(status){
        return status === Status.ACCEPTED || status === Status.ONLINE_PUBLICATION || status === Status.PUBLISHED;
    }

    public static displayBookTitle(status){
        return status === Status.ACCEPTED || status === Status.ONLINE_PUBLICATION || status === Status.PUBLISHED;
    }

    public static displayBookDOI(status){
        return status === Status.ONLINE_PUBLICATION || status === Status.PUBLISHED || status === Status.ACCEPTED;
    }

    public static displayPublisherName(status){
        return !this.isPosted(status);
    }

    public static displayEdition(status){
        return  status === Status.PUBLISHED;
    }
    public static displaySeries(status){
        return  status === Status.PUBLISHED;
    }
    public static displayPublicationPlace(status){
        return status === Status.ACCEPTED || status === Status.SUBMITTED || status === Status.PUBLISHED ;
    }
}
