<mat-form-field style="min-width:250px" [class.is-autopopulated]="isAutoPopulated">
    <mat-icon matPrefix
              *ngIf="isAutoPopulated"
              svgIcon="auto-populate"
              matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}"></mat-icon>
    <mat-select placeholder="{{ placeholder | customTranslate}}"
                [formControl]="depositFormDataService.status"
                #statusSelect>
        <mat-option *ngFor="let option of statusOptions; let i = index"
                    [value]="option">
            {{'research.publication.status.'+ option | customTranslate}}
        </mat-option>
    </mat-select>
</mat-form-field>

