<mat-form-field
    [ngClass]="expanded ? 'is-expanded' : 'is-auto-expanding'"
    [class.is-autopopulated]="autoPopulated"
    [muloInputExpander]="input.value"
    class="is-comfortably-readable"
>
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
        matInput
        *ngIf="!multipleLines"
        [formControl]="input"
        [placeholder]="placeholder"
        #inputElement
        [exlFocus]="jump"
        [attr.autocomplete]="autocompleteLabel"
    />
    <textarea
        matInput
        *ngIf="multipleLines"
        class="hasnt-scrollbar"
        [formControl]="input"
        [placeholder]="placeholder"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        [rows]="1"
        #inputElement
        [maxlength]="lengthLimit"
        [exlFocus]="jump"
        [attr.autocomplete]="autocompleteLabel"
    >
    </textarea>

    <mat-icon
        matPrefix
        *ngIf="autoPopulated"
        svgIcon="auto-populate"
        matTooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
    >
    </mat-icon>
    <!--<exl-badge-auto-populated-->
    <!--*ngIf="autoPopulated"-->
    <!--tooltip="{{'research.auto.populate.tooltip' | customTranslate}}">-->
    <!--</exl-badge-auto-populated>-->
    <button
        *ngIf="input.value"
        mat-icon-button
        (click)="clearInput()"
        class="clear-button"
        attr.aria-label="{{ 'research.aria.deposit.clear.input' | translate }}"
        matSuffix
    >
        <mat-icon svgIcon="close"></mat-icon>
    </button>
    <mat-hint align="end" *ngIf="lengthLimit && input.value">{{ input.value.length }} / {{ lengthLimit }}</mat-hint>
    <mat-error>
        <span [innerHTML]="'research.field.required.error.message' | customTranslate"></span>
    </mat-error>
</mat-form-field>
