<mat-accordion [multi]="multiOpen" [displayMode]="displayMode" *ngIf="listStyle === 'accordion'">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</mat-accordion>
<mat-list *ngIf="listStyle === 'list'" class="mulo-elevation-list">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</mat-list>
<ng-template #content><ng-content></ng-content></ng-template>

<!-- Content projection for slot "[slot-file-list-footer]" -->
<div select="[slot='file-list-footer']" class="files-list-footer"></div>
