<h1 class="cdk-visually-hidden">{{ 'research.title.deposit.form' | translate }}</h1>
<form [formGroup]="depositFormDataService.mainForm" autocomplete="off">
    <ng-content select="[slot-deposit-form-overlay-close-button]"></ng-content>
    <div class="loading-wrap" *ngIf="profileOutputService.loadingDraft">
        <mulo-loading-slate height="100%">
            <p class="padding-sm semibold">{{ 'research.output.list.loading.draft.message' | translate }}</p>
        </mulo-loading-slate>
    </div>
    <esp-deposit-stepper (closeDeposit)="closeEmitter.emit($event)"></esp-deposit-stepper>
</form>
