export class Link {
    url: string;
    description?: string;
    type?: string;
    license?: string;
    accessRights?: string | any;
    ownership?: string;
    id?: string;
    licenseCode?: string;
    parentId?: string;
    sortOrder?: number;
    supplemental?: boolean;
    title?: string;
    displayName?: string;
    typeValue?: string;
    note?: string;
    contentType?: string;

    constructor(url, description, type) {
        this.url = url;
        this.description = description;
        this.type = type;
    }

    setLicense(license) {
        this.license = license;
    }

    setAccessRights(accessRights) {
        this.accessRights = accessRights;
    }

    setOwnership(ownership) {
        this.ownership = ownership;
    }
}
