import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DepositForm } from '../../../shared/configurations/deposit-form.constant';
import moment from 'moment';
import { preventOnKeyboardOrigin } from '../../../shared/annotations/prevent-on-keyboard-origin.annotation';

@Component({
    selector: 'esp-access-rights-dialog',
    templateUrl: './access-rights-dialog.component.html',
    styleUrls: ['./access-rights-dialog.component.scss']
})
export class AccessRightsDialogComponent implements OnInit {

    @ViewChild('input', {static: false}) input: any;
    applyToAll = false;
    focus = false;
    date = moment();
    labelsPrefix = DepositForm.FILE_ACCESSRIGHTS_LABELS_PREFIX;

    constructor(public dialogRef: MatDialogRef<AccessRightsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
        if (this.data.date){
            this.date = this.data.date;
        }
    }

    @preventOnKeyboardOrigin
    onSelection(){
        setTimeout(() => {
            if (this.input) {
                this.input.nativeElement.focus();
            }
        }, 0);
    }

    onSubmit(){
        if (this.data.selected === 'embargoed') {
            this.data.date = this.date;
        }
        this.dialogRef.close(this.data);
    }

    checkboxChanged(event){
        this.data.applyToAll = event.checked;
    }

}
