import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'exl-files-from-online-dialog',
    templateUrl: './files-from-online-dialog.component.html',
    styleUrls: ['./files-from-online-dialog.component.scss']
})
export class FilesFromOnlineDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {}

}
