import { Component, OnInit, Input } from '@angular/core';
import { GrowInAnimation } from '../../animations/grow.animation';
import { ChatService } from './chat.service';
import { ChatEntry } from './chat-entry.model';
import { ChatEntryComponent } from './chat-entry/chat-entry.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'mulo-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    host: { class: 'mulo-chat' },
    animations: [GrowInAnimation],
    standalone: true,
    imports: [NgFor, ChatEntryComponent],
})
export class ChatComponent implements OnInit {
  @Input() entries: Array<any>;
  @Input() singleInstance: boolean = false;
  @Input() reasonPrefix = 'Return reason:';
  chat: ChatEntry[] = [];
  userReply;

  constructor(private service: ChatService) {
    this.service.singleInstance = this.singleInstance;
    this.service.chatEntries$.subscribe((chat) => {
      this.chat = chat;
    });
  }

  ngOnInit() {
    this.service.populateChat(this.entries);
  }

  addUserMessage() {
    this.service.addUserMessage();
  }
}
