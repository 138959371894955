import { Component, OnDestroy, OnInit } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { Subject, takeUntil } from 'rxjs';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';

@Component({
  selector: 'esp-deposit-peer-reviewed',
  templateUrl: './deposit-peer-reviewed.component.html',
  styleUrls: ['./deposit-peer-reviewed.component.scss'],
})
export class DepositPeerReviewedComponent implements OnInit, OnDestroy {
  private peerReviewedDestroy = new Subject<void>();

  constructor(public depositFormDataService: DepositFormDataService) { }

  ngOnInit() {
    this.onChanges();
  }

  onChanges(): void {
    this.depositFormDataService.peerReviewed.valueChanges
      .pipe(takeUntil(this.peerReviewedDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.PEER_REVIEWED
          );
        }
      });
  }

  get isAutoPopulated() {
    return this.depositFormDataService.isKeyAutoPopulated(
      AutoPopulateKeys.PEER_REVIEWED
    );
  }

  ngOnDestroy() {
    if (!this.peerReviewedDestroy.isStopped) {
      this.peerReviewedDestroy.next();
      //unsubscribe from the subject itself
      this.peerReviewedDestroy.unsubscribe();
    }
  }
}
