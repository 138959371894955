import moment, { Moment } from 'moment';

/**
 * for parsing date-like objects;
 * reconstructs according to https://momentjs.com/docs/#/parsing/object/
 */
export function momentObjParse(obj: object): Moment {
  let constructedMomentObj = {};

  for (let key in obj) {
    // extract what we can from this obj
    switch (key) {
      case 'year':
      case 'years':
      case 'y':
        constructedMomentObj['y'] = obj[key];
        break;
      case 'month':
      case 'months':
      case 'M':
        constructedMomentObj['M'] = obj[key];
        break;
      case 'day':
      case 'days':
      case 'date':
      case 'd':
        constructedMomentObj['d'] = obj[key];
        break;
      case 'hour':
      case 'hours':
      case 'h':
        constructedMomentObj['h'] = obj[key];
        break;
      case 'minute':
      case 'minutes':
      case 'm':
        constructedMomentObj['m'] = obj[key];
        break;
      case 'second':
      case 'seconds':
      case 's':
        constructedMomentObj['s'] = obj[key];
        break;
      case 'millisecond':
      case 'milliseconds':
      case 'ms':
        constructedMomentObj['ms'] = obj[key];
        break;
    }
  }

  if (Object.keys(constructedMomentObj).length === 0) {
    throw new Error('Not a date object');
  } else if (
    Object.keys(constructedMomentObj).length === 1 &&
    constructedMomentObj.hasOwnProperty('ms')
  ) {
    // handles unix millisecond timestamp case
    return moment(constructedMomentObj['ms']).utc();
  } else if (
    Object.keys(constructedMomentObj).length === 1 &&
    constructedMomentObj.hasOwnProperty('s')
  ) {
    // handles unix second timestamp case
    return moment.unix(constructedMomentObj['s']).utc();
  } else {
    return moment(constructedMomentObj).utc();
  }
}
