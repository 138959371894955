<div>
    <div>
        <mat-form-field
            *ngIf="displayISBN()"
            [class.is-autopopulated]="isAutoPopulated('isbnForDisplay')"
            [muloInputExpander]="depositFormDataService.isbnForDisplay.value"
        >
            <input
                matInput
                [formControl]="depositFormDataService.isbnForDisplay"
            />
            <mat-label>{{
                'research.status.isbn' | customTranslate
            }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('isbnForDisplay')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
        </mat-form-field>

        <mat-form-field
            *ngIf="displayPublisherName()"
            [class.is-autopopulated]="isAutoPopulated('publisher')"
            [muloInputExpander]="depositFormDataService.publisher.value"
            class="is-short-input"
        >
            <input matInput [formControl]="depositFormDataService.publisher" />
            <mat-label>{{
                'research.status.publisher' | customTranslate
            }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('publisher')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
        </mat-form-field>

        <mat-form-field
            *ngIf="displayPublicationPlace()"
            [class.is-autopopulated]="isAutoPopulated('publicationPlace')"
            class="is-short-input"
            [muloInputExpander]="
                depositFormDataService.publicationPlaceForDisplay.value
            "
        >
            <input
                matInput
                [formControl]="
                    depositFormDataService.publicationPlaceForDisplay
                "
            />
            <mat-label>{{
                'research.status.publicationPlace' | customTranslate
            }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('publicationPlace')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field
            *ngIf="displayPages()"
            [class.is-autopopulated]="isAutoPopulated('pages')"
            class="is-short-input"
        >
            <input matInput [formControl]="depositFormDataService.pages" />

            <mat-label>{{
                'research.status.pages' | customTranslate
            }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('pages')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
        </mat-form-field>

        <mat-form-field
            *ngIf="displayEdition()"
            [class.is-autopopulated]="isAutoPopulated('edition')"
            class="is-short-input"
            [muloInputExpander]="depositFormDataService.edition.value"
        >
            <input matInput [formControl]="depositFormDataService.edition" />
            <mat-label>{{
                'research.status.edition' | customTranslate
            }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('edition')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
        </mat-form-field>

        <mat-form-field
            *ngIf="displaySeries()"
            [class.is-autopopulated]="isAutoPopulated('series')"
            class="is-short-input"
            [muloInputExpander]="depositFormDataService.series.value"
        >
            <input matInput [formControl]="depositFormDataService.series" />
            <mat-label>{{
                'research.status.series' | customTranslate
            }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('series')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
        </mat-form-field>
    </div>
</div>
