import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'exl-file-preview-dialog',
    templateUrl: './file-preview-dialog.component.html',
    styleUrls: ['./file-preview-dialog.component.scss'],
    host: { class: 'exl-file-preview-dialog' },
})
export class FilePreviewDialogComponent implements OnInit {
    file;
    safeData;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sanitizer: DomSanitizer
    ) {
        this.file = this.data?.file;
    }

    ngOnInit() {
        this.safeData = this.sanitizer.bypassSecurityTrustUrl(this.file?.data);
    }
}
