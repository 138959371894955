import { NgModule } from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {CenteredBlockLayoutComponent} from './centered-block-layout/centered-block-layout.component';
import {HeaderMainFooterLayoutComponent} from './header-main-footer-layout/header-main-footer-layout.component';


@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        CenteredBlockLayoutComponent,
        HeaderMainFooterLayoutComponent
    ],
    exports: [
        CenteredBlockLayoutComponent,
        HeaderMainFooterLayoutComponent
    ]
})
export class LayoutsModule { }
