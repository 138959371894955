export class FullAssetPage {
    public static OUTPUTS_URL = '/esploro/outputs/{{mms_id}}?institution={{institution_code}}';
    public static MAX_TITLE_LENGTH = 80;
    public static DOI_LINK_PREFIX = 'https://doi.org/';
    public static HANDLE_LINK_PREFIX = 'https://hdl.handle.net/';
    public static DOI_IDENTIFIER = 'DOI';
    public static HANDLE_IDENTIFIER = 'HANDLE';
    public static IDENTIFIERS_PREFIX_IN_CONFIGURATION = 'esploroAssetModel.identifiers.';
    public static AUTHOR_SEARCH_IN_DISCOVERY =
        '/discovery/search?query=creator,exact,{{display_name}},AND&tab=Research&search_scope=Research&sortby=date_d&vid={{inst}}:ResearchRepository&mode=advanced&offset=0&lang={{lang}}';
    public static MAX_ESSENTIALS_AUTHORS = 10;
    public static REFWORKS_URL = 'https://www.refworks.com/express/ExpressImport.asp?vendor=Esploro&filter=RIS%20Format&encoding=65001';
    public static ENDNOTE_URL = 'http://www.myendnoteweb.com/?func=directExport&partnerName=Esploro&dataIdentifier=1&dataRequestUrl=';
    public static GET_EXPORT_RIS = '/esplorows/rest/research/pushTo/pushToRisTxt';

}
