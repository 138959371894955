import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DepositForm } from '../../../shared/configurations/deposit-form.constant';
import { ResearchTopic } from '../../../shared/interfaces/research-topic.intreface';
import { DepositKeywordsSuggestionsService } from '../deposit-keywords/deposit-keywords-suggestions.service';
import {
  SelectedResearchTopic,
  TopicType,
} from '../../../shared/interfaces/selected-research-topic.model';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { Subject, distinctUntilChanged, debounceTime, takeUntil } from 'rxjs';
import { WidthInAnimation, WidthOutAnimation } from '@exl-ng/mulo-common';

@Component({
  selector: 'esp-deposit-freetext-keywords',
  templateUrl: './deposit-freetext-keywords.component.html',
  styleUrls: ['./deposit-freetext-keywords.component.scss'],
  animations: [WidthInAnimation, WidthOutAnimation],
})
export class DepositFreetextKeywordsComponent implements OnInit, OnDestroy {
  @ViewChild('chipInput', { static: false, read: MatAutocompleteTrigger })
  chipInput: MatAutocompleteTrigger;
  @Input() jump;

  topics: ResearchTopic[];
  autoCompleteChipList: UntypedFormControl = new UntypedFormControl();
  removable = true;
  filteredSuggestions: SelectedResearchTopic[];
  private topicsDestroy = new Subject<void>();

  trackByTopic = (index: number, topic: ResearchTopic) => topic.description;

  constructor(
    public depositFormDataService: DepositFormDataService,
    private suggestionsService: DepositKeywordsSuggestionsService
  ) { }

  ngOnInit() {
    this.filteredSuggestions = this.suggestionsService.getSuggestions('');
    this.listenToSearchInput();
  }

  listenToSearchInput() {
    this.autoCompleteChipList.valueChanges
      .pipe(
        debounceTime(DepositForm.SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.topicsDestroy)
      )
      .subscribe((value) => {
        this.filteredSuggestions =
          this.suggestionsService.getSuggestions(value);
      });
  }

  addSuggestionChip(topic: SelectedResearchTopic, input): void {
    const description = topic.description;
    this.insertChip(description);
    if (input) {
      input.value = '';
      this.autoCompleteChipList.reset();
    }
  }

  isTopicExists(description) {
    for (const topic of this.depositFormDataService.topics.value) {
      if (topic.description === description) {
        return true;
      }
    }
    return false;
  }

  insertChip(description) {
    this.chipInput.closePanel();
    if (!this.isTopicExists(description)) {
      const selectedTopic = new SelectedResearchTopic(
        '',
        description,
        '',
        TopicType.KEYWORD
      );
      this.depositFormDataService.updateTopics(selectedTopic);
      this.depositFormDataService.updateKeywords(description);
    }
  }

  removeChip(topic: SelectedResearchTopic, index): void {
    this.depositFormDataService.removeTopic(index);
    this.removeTopicFromArray(
      this.depositFormDataService.keywords,
      topic.description
    );
  }

  removeTopicFromArray(array: UntypedFormArray, element) {
    const i = array.value.indexOf(element);
    if (i > -1) {
      array.removeAt(i);
    }
    this.depositFormDataService.keywordsAutoPopulated.splice(i, 1);
  }

  onInputEnterKey(input: any): void {
    const value = input.value;
    if (value) {
      this.insertChip(value);
      input.value = '';
      this.autoCompleteChipList.reset();
    }
  }

  isAutoPopulated(keyword) {
    return (
      this.depositFormDataService.keywordsAutoPopulated.indexOf(
        keyword.description
      ) !== -1
    );
  }

  ngOnDestroy() {
    if (!this.topicsDestroy.isStopped) {
      this.topicsDestroy.next();
      //unsubscribe from the subject itself
      this.topicsDestroy.unsubscribe();
    }
  }
}
