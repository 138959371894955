<div class="export-scope-selector" *ngIf="scope != 'single' && selectedAssets.length > 1">
  <h2>{{ labels.scope }}</h2>
  <div mat-tab-nav-bar class="as-selector is-scaling hasnt-underline margin-left-sm" [mat-stretch-tabs]="false">
    <button
      mat-tab-link
      class="export-scope-button"
      [active]="scope == 'selected'"
      (click)="onScopeChange()"
      *ngIf="selectedCount"
    >
      {{ labels.selected }}<span> ({{ selectedCount }})</span>
    </button>
    <button
      mat-tab-link
      class="export-scope-button"
      [active]="scope == 'all'"
      (click)="onScopeChange()"
      *ngIf="totalCount && selectedCount != totalCount"
    >
      <span
        >{{ labels.all }}<span *ngIf="totalCount"> ({{ totalCount }})</span></span
      >
    </button>
  </div>
</div>

<div *ngIf="scope === 'single' && selectedAssets[0] as asset" class="export-scope-item margin-bottom-sm">
  <div>{{ asset.type }}</div>
  <h2 class="semibold" [innerHTML]="asset.title | htmlSanitize"></h2>
</div>

<div
  class="export-target-selector margin-bottom-xs exporter-buttons-group"
  role="group"
  [attr.aria-labelledby]="titleId"
  aria-label="Export Asset"
>
  <span class="exporter-buttons-leading-text">{{ labels.to }}</span>
  <div class="overflow-hidden">
    <div
      mat-tab-nav-bar
      #selectors
      class="as-selector is-scaling hasnt-underline margin-left-sm"
      [mat-stretch-tabs]="false"
    >
      <button
        mat-tab-link
        *ngFor="let target of exportTargets; let i = index"
        [active]="selectedExportTarget ? selectedExportTarget.value == target.value : null"
        [class.has-icon]="target.icon"
        (click)="setExportTarget(target)"
        class="export-target-button margin-right-xs"
      >
        <mat-icon *ngIf="target.icon" [svgIcon]="target.icon" muloSvgViewbox></mat-icon>
        <span class="export-target-label" *ngIf="target.name">{{ target.name }}</span>
      </button>
    </div>
  </div>
</div>
<div
  *ngIf="selectedExportTarget && (selectedExportTarget.targetOptions || selectedExportTarget.preview)"
  class="options"
  @heightInAnimation
  @heightOutAnimation
>
  <div
    class="options-container-wrapper"
    *ngIf="selectedExportTarget && selectedExportTarget.targetOptions as targetOptions"
    @heightInAnimation
    @heightOutAnimation
  >
    <div class="options-container">
      <div *ngFor="let field of targetOptions; index as i" [class.margin-right-md]="i != targetOptions.length - 1">
        <mat-form-field class="hasnt-errors semibold has-dynamic-width">
          <mat-label>{{ field.label }}</mat-label>
          <mat-select
            *ngIf="field.type === 'select'"
            [value]="field.selected"
            (valueChange)="onOptionsChange(field, $event)"
          >
            <mat-option *ngFor="let item of field.items" [value]="item.value" class="semibold">
              {{ item.label }}
            </mat-option>
          </mat-select>
          <input
            [type]="field.type"
            matInput
            *ngIf="['text', 'email'].includes(field.type)"
            [value]="field.value ? field.value : ''"
            (change)="onOptionsChange(field, $event)"
            placeholder="{{ field.placeholder ? field.placeholder : ' ' }}"
            [formControl]="getFieldControl(field)"
          />
          <mat-error *ngIf="field.control?.invalid">{{ field.errorMessage }}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngIf="actionSuccess$ | async as msg">
      <div
        cdkAriaLive="polite"
        *ngIf="msg !== ''"
        class="target-action-success-msg"
        @widthInAnimation
        @opacityOutAnimation
      >
        <mulo-animated-checkmark [animate]="true"></mulo-animated-checkmark>
        {{ msg }}
      </div>
    </ng-container>
  </div>
  <!-- Output preview container -->
  <div *ngIf="selectedExportTarget && selectedExportTarget.preview as preview" @heightInAnimation @heightOutAnimation>
    <mulo-copier
      display="wrapping"
      [copyLabel]="labels.copy"
      [copiedLabel]="labels.copied"
      [content]="outputPreview$ | async"
      [copyMethod]="outputStyle != 'code' && outputStyle != 'plain' ? 'rich' : 'plain'"
      [processing]="loadingPreview"
      (copied)="onOutputCopied()"
    >
      <div class="output" [class.processing]="loadingPreview">
        <pre
          #output
          class="output-content"
          [class.isnt-preformatted]="outputStyle != 'code' && outputStyle != 'plain'"
          [innerHTML]="outputPreview$ | async"
        ></pre>
        <mat-progress-spinner *ngIf="loadingPreview" class="spinner" mode="indeterminate" diameter="50">
        </mat-progress-spinner>
      </div>
    </mulo-copier>
    <div *ngIf="selectedExportTarget?.afterPreviewNote" @heightInAnimation @heightOutAnimation class="overflow-hidden">
      <mulo-infobar
        class="margin-top-xs overflow-hidden"
        color="warn"
        icon="alert-outline"
        corners="rounded"
        appearance="slim"
      >
        {{ selectedExportTarget.afterPreviewNote }}
      </mulo-infobar>
    </div>
  </div>
</div>
<!-- Action button for when component is used not in a dialog context -->
<div class="action-btn-wrap margin-top-xs" *ngIf="!inDialog">
  <span class="action-btn-spacer"></span>
  <ng-container *ngFor="let action of targetActions$ | async; index as i">
    <ng-container *ngIf="{ ad: action.disabled$ | async } as disabledObj">
      <button
        *ngIf="!action.url"
        mat-button
        color="primary"
        [disabled]="disabledObj.ad || loadingPreview"
        [class.is-large]="i === 0"
        (click)="onActionClick(action)"
      >
        {{ action.label }}
      </button>
      <a
        *ngIf="action.url"
        mat-button
        color="primary"
        [disabled]="disabledObj.ad || loadingPreview"
        [href]="action.url"
        [class.is-large]="i === 0"
        target="_blank"
      >
        {{ action.label }}
      </a>
    </ng-container>
  </ng-container>
</div>
