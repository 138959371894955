import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialCssVariablesConfig } from 'angular-material-css-vars';

import { MATERIAL_CSS_VARS_CFG } from './token.const';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class MuloMatCssVarsModule {
  static forRoot(
    config?: Partial<MaterialCssVariablesConfig>
  ): ModuleWithProviders<MuloMatCssVarsModule> {
    return {
      ngModule: MuloMatCssVarsModule,
      providers: [{ provide: MATERIAL_CSS_VARS_CFG, useValue: config }],
    };
  }
}
