
<h2 mat-dialog-title>{{ data.title | translate }}</h2>
<button
    mat-button
    class="close-button"
    (click)="onDialogClose()"
    attr.aria-label="{{
        'research.aria.dialog.close.dialog' | translate
    }}"
>
    <mat-icon svgIcon="close"></mat-icon>
</button>
<div mat-dialog-content #contentbody>
    <ng-container *ngComponentOutlet="data.component" ></ng-container>
</div>
