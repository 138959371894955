import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, shareReplay } from 'rxjs';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { ResearcherOrganization } from '../shared/interfaces/researcher-organization.interface';
import { UrlUtils } from '../shared/utils/url.utils';
import { I18nService } from './i18n.service';
import { JwtUtilService } from './jwt-util.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  institutionCode: string;

  constructor(private http: HttpClient,
    private jwtService: JwtUtilService,
    private i18nService: I18nService) {
    this.institutionCode = UrlUtils.getParam('institution');
  }

  getInternalOrganizationAutoComplete(organization) {
    const params = new HttpParams()
      .set('institution', this.institutionCode)
      .set('organization', organization);
    return this.http.get(
      RestApiBase.GET_INTERNAL_ORGANIZATION_AUTO_COMPLETE,
      { params: params }
    ) as Observable<Partial<ResearcherOrganization>[]>;
  }

  getReseracherPrimaryOrganization() {
    const userID = this.jwtService.getUserID();
    const params = new HttpParams()
      .set('institution', this.institutionCode)
      .set('userID', userID);
    return this.http.get<ResearcherOrganization>(RestApiBase.RESEARCHER_PRIMARY_ORGANIZATION, { params: params });
  }
}
