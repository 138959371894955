import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { GrowInAnimation, GrowOutAnimation } from '@exl-ng/mulo-common';
import { DepositGrantsService } from './deposit-grants.service';
import { Grant } from '../../../shared/interfaces/grant.model';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { DepositGrantsSearchComponent } from './deposit-grants-search/deposit-grants-search.component';

@Component({
    selector: 'esp-deposit-grants',
    templateUrl: './deposit-grants.component.html',
    styleUrls: ['./deposit-grants.component.scss'],
    providers: [DepositGrantsService],
    animations: [GrowOutAnimation, GrowInAnimation],
})
export class DepositGrantsComponent implements OnDestroy {
    @ViewChild('grantInput', { static: false, read: MatAutocompleteTrigger })
    grantInput: MatAutocompleteTrigger;
    @ViewChild('grantsSearch') grantsSearch: DepositGrantsSearchComponent;

    public dataSection = 'grants';

    allGrants: Grant[] = [];
    enterNewGrant = false;
    private grantDestroy = new Subject<void>();

    constructor(public depositFormDataService: DepositFormDataService) {}

    onGrantRemove(index) {
        this.depositFormDataService.removeGrant(index);
    }

    addGrant(item) {
        if (!this.depositFormDataService.isGrantExist(item)) {
            this.depositFormDataService.updateGrants(item);
        }
    }

    ngOnDestroy() {
        if (!this.grantDestroy.isStopped) {
            this.grantDestroy.next();
            this.grantDestroy.unsubscribe();
        }
    }

    handleFormClose() {
        this.enterNewGrant = false;
        setTimeout(() => this.grantsSearch?.addNewBtn?.focus());
    }
}
