<div *ngIf="shareData.url">
  <mulo-copier
    [copyLabel]="labels.copy"
    [copiedLabel]="labels.copied"
    [content]="shareData.url"
    (copied)="onLinkCopied()"
  >
    <span class="padding-sm">
      {{ shareData.url }}
    </span>
  </mulo-copier>
</div>
<ul *ngIf="shareTargets?.length" class="share-targets">
  <li *ngFor="let target of shareTargets">
    <a
      mat-raised-button
      [target]="target.openAs === 'popup' ? shareWindowId : '_blank'"
      class="share-target-button margin-right-xs"
      [class.has-icon]="target.icon || target.iconSvg"
      [attr.href]="target.key !== 'native' ? target.url : '#'"
      [tabIndex]="0"
      (click)="
        target.key === 'native'
          ? openNativeShare($event)
          : target.openAs === 'popup'
          ? openPopupShare(target.url)
          : null
      "
      (muloMultipleEvents)="
        target.key === 'native'
          ? openNativeShare($event)
          : target.openAs === 'popup'
          ? openPopupShare(target.url)
          : null
      "
    >
      <mat-icon *ngIf="target.icon" [svgIcon]="target.icon ? target.icon : null" muloSvgViewbox></mat-icon>
      <mat-icon *ngIf="!target.icon && target.iconSvg" [innerHTML]="target.iconSvg | htmlTrust"></mat-icon>
      <span class="share-target-label" *ngIf="target.label">{{ target.label }}</span>
    </a>
  </li>
</ul>
