import { Injectable, OnInit } from '@angular/core';
import {SelectedResearchTopic, TopicType} from '../../../shared/interfaces/selected-research-topic.model';
import { ProfileService } from '../../../profile/profile.service';
import { ResearchTopic } from '../../../shared/interfaces/research-topic.intreface';
import { ResearchPerson } from '../../../shared/interfaces/research-person.model';
import { ResearchPersonData } from '../../../shared/interfaces/research-person-data.model';

@Injectable()
export class DepositKeywordsSuggestionsService implements OnInit {
    MAX_SUGGESTIONS = 10;
    recentKeywords: SelectedResearchTopic[];
    profileKeywords: SelectedResearchTopic[];
    recentTopics: SelectedResearchTopic[];
    profileTopics: SelectedResearchTopic[];
    suggestions: SelectedResearchTopic[];

    constructor(private profileService: ProfileService) {}

    ngOnInit(){
    }

    clearSuggestions(){
        this.suggestions = null;
    }

    transformKeywordsToSelectedTopics(data: string[]): SelectedResearchTopic[]{
        if(data == null){
            return new Array();
        }
        const selTopics: SelectedResearchTopic[] = new Array<SelectedResearchTopic>();
        for(let i=0; i < data.length; i++){
            selTopics.push(new SelectedResearchTopic('', data[i], '', TopicType.KEYWORD));
        }
        return selTopics;
    }

    transformTopicsToSelectedTopics(data: any[]): SelectedResearchTopic[]{
        if(!data){
            return new Array();
        }
        const selTopics: SelectedResearchTopic[] = new Array<SelectedResearchTopic>();
        data.forEach(
            function(value){
                selTopics.push(SelectedResearchTopic.buildFromResearchTopic(value as ResearchTopic));
            });
        return selTopics;
    }

    buildSuggestions(): SelectedResearchTopic[]{
        let sugArr: SelectedResearchTopic[] = new Array<SelectedResearchTopic>();
        sugArr = sugArr.concat(this.profileTopics);
        sugArr = sugArr.concat(this.profileKeywords);
        sugArr = sugArr.concat(this.recentTopics);
        sugArr = sugArr.concat(this.recentKeywords);
        return sugArr;
    }

    retrieveSuggestions(){
        const profile: ResearchPerson = this.profileService.getProfileData();
        if(profile){
            const resData: ResearchPersonData = profile.getData();
            this.recentKeywords=this.transformKeywordsToSelectedTopics(resData.recentKeywords);
            this.recentTopics = this.transformTopicsToSelectedTopics(resData.recentTopics);
            this.profileKeywords=this.transformKeywordsToSelectedTopics(resData.profileKeywords);
            this.profileTopics=this.transformTopicsToSelectedTopics(resData.profileTopics);

            this.suggestions = this.buildSuggestions();
        }
    }

    getSuggestions(contains: string):SelectedResearchTopic[]{
        if(!this.suggestions){
            this.retrieveSuggestions();
        }
        if(this.suggestions){
            const filteredSuggestions: SelectedResearchTopic[] = this.suggestions.filter(
                function(el){
                    if(!contains){
                        return true;
                    }
                    return el.description.toLowerCase().includes(contains.toLowerCase());
                });
            const limitedFilteredSuggestions = filteredSuggestions.slice(0, this.MAX_SUGGESTIONS);
            return limitedFilteredSuggestions;
        }
        return new Array();
    }








}
