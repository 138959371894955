export class ResourceTypeModel{
    category: string;
    type: string;

    constructor(category, type){
        this.category = category;
        this.type = type;
    }

    getCategory(){
        return this.category;
    }

    getType(){
        return this.type;
    }

}
