<mat-form-field [ngClass]="classField">
    <mat-label>{{label | translate}}</mat-label>
    <mat-error [innerHtml]="errorMessage | customTranslate"></mat-error>

    <mat-select [formControl]="internalOrgControl"
                [panelClass]="'is-tall'"
                [required] = "required"
                [placeholder]="placeholder | translate">
        <mat-option>
            <ngx-mat-select-search [formControl]="organizationsFilterCtrl"
                placeholderLabel="{{'research.student.deposit.form.select.lookup.placeholder' | translate}}"
                noEntriesFoundLabel="{{'research.student.deposit.form.select.lookup.no.match' | translate}}"
                ariaLabel="{{'research.aria.student.deposit.form.select.lookup' | translate}}"
            >
                <mat-icon ngxMatSelectSearchClear svgIcon="close"></mat-icon>
            </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let option of filteredOrganizations$ | async"
            [value]="option.organizationCode"
            (onSelectionChange)="onDepartmentSelect(option, $event)">
            <span style="white-space: normal;" [innerHTML]="option.organizationCode | translate | highlight: organizationsFilterCtrl.value" class="semibold"></span>
        </mat-option>
    </mat-select>

</mat-form-field>
