import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlUtils } from '../shared/utils/url.utils';
import { ConfigurationHandlerService } from './configuration-handler.service';
import { RouterService } from './router.service';

@Injectable()
export class PivotGuard  {

    institutionCode:string;

    constructor(
                private router: Router,
                private configurationService: ConfigurationHandlerService,
                private routerService: RouterService
                ){
        this.institutionCode = UrlUtils.getParam('institution');
    }
    canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        // return true;
        const isPivotEnabled = this.configurationService.getPortalConfigurationHedearIncludePivot();
        if (!isPivotEnabled) {
            this.routerService.goToHomepage();
        }
        return isPivotEnabled;
    }

}
