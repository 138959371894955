import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { LangChangeEvent, TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatRadioChange } from '@angular/material/radio';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageSelectDialogComponent } from '../language-select/language-select.component';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatButton, MatIconButton } from '@angular/material/button';
import { NgIf, NgTemplateOutlet, NgFor, UpperCasePipe } from '@angular/common';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'mulo-language-select-button',
    templateUrl: './language-select-button.component.html',
    styleUrls: ['./language-select-button.component.scss'],
    standalone: true,
    imports: [
        MatIcon,
        SvgViewboxDirective,
        NgIf,
        MatButton,
        NgTemplateOutlet,
        MatIconButton,
        MatMenuTrigger,
        MatMenu,
        NgFor,
        MatMenuItem,
        UpperCasePipe,
        TranslateModule,
    ],
})
export class LanguageSelectButtonComponent implements OnChanges {
  @Input() mode: 'full' | 'compact' | 'icon' = 'full';
  @Input() useDialog = false;
  @Input() ariaLabel = 'Open language menu';
  @Input() langListLabel = 'Display language';
  @Input() cancelLabel;
  @Output() click = new EventEmitter<null>();
  @Output() languageChange = new EventEmitter<string>();
  @Input() languages;
  @Input() selectedLanguage;
  dialogRef: MatDialogRef<LanguageSelectDialogComponent>;

  constructor(private translate: TranslateService, private dialog: MatDialog) {
    this.languages = translate.getLangs();
    this.selectedLanguage = translate.currentLang;
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['languages'] && changes['languages'].currentValue) {
      this.translate.addLangs(changes['languages'].currentValue);
    }
  }

  /**
   * @internal
   */
  handleClick() {
    if (this.useDialog) {
      this.dialogRef = this.dialog.open(LanguageSelectDialogComponent, {
        data: {
          langListLabel: this.langListLabel,
          cancelLabel: this.cancelLabel,
        },
      });
    }
  }

  handleLangChange(lang: string) {
    this.translate.use(lang);
    this.languageChange.emit(lang);
  }
}
