import { Injectable } from '@angular/core';
import { LanguageSelectDialogComponent } from './language-select-dialog/language-select-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlUtils } from '../../shared/utils/url.utils';
import { RestApiBase } from '../../shared/configurations/rest-api-base.config';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    institutionCode = UrlUtils.getParam('institution');
    dialogRef: MatDialogRef<LanguageSelectDialogComponent>;

    constructor(private dialog: MatDialog, private http: HttpClient) {}

    showLanguageDialog(data) {
        this.dialogRef = this.dialog.open(LanguageSelectDialogComponent, {
            data,
        });
    }

    changeUserLanguage(language) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.post(RestApiBase.CHANGE_USER_LANGUAGE, language, {
            params,
        });
    }

    closeDialog() {
        this.dialogRef?.close();
    }
}
