import { NgModule } from '@angular/core';
import {LabeledRowComponent} from './labeled-row.component';


@NgModule({
    imports: [

    ],
    declarations: [
        LabeledRowComponent
    ],
    providers: [

    ],
    exports: [
        LabeledRowComponent
    ]
})
export class LabeledRowModule { }
