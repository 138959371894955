<div class="deposit-form-section-wrapper">
    <div class="deposit-form-section-header" *ngIf="sectionTitle" @growInAnimation>
        <h3 class="deposit-form-section-title" [id]="titleId">
          {{sectionTitle}}
          <span *ngIf="required" class="mark-required">
            <mat-icon svgIcon="asterisk"></mat-icon>
            <span>{{'research.deposit.required' | translate}}</span>
          </span>
        </h3>
        <span class="section-header-additional-content">
          <ng-content select="[section-header-additional-content]"></ng-content>
        </span>
      </div>
    <div class="deposit-section-description" *ngIf="description" [id]="descriptionId">
        <span [innerHTML]="description"></span>
    </div>
    <ng-content></ng-content>
</div>
