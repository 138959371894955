<mat-icon [class.mat-icon-rtl-mirror]="rtlMirrorIcon" [svgIcon]="icon" *ngIf="showIcon" muloSvgViewbox></mat-icon>
<!--
<ng-container *ngIf="url && url.length">
  <a class="content" [href]="url">
    <ng-content></ng-content>
  </a>
</ng-container>
<ng-container *ngIf="!url">
  <span class="content">
    <ng-content></ng-content>
  </span>
</ng-container> -->
<a *ngIf="url" class="content" [href]="url">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a>
<span *ngIf="!url" class="content">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</span>

<span class="separator"></span>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
