<div class="grant-search-form">
    <mat-form-field
        class="is-comfortably-readable"
        [muloInputExpander]="searchTerm.value"
        [muloInputExpanderBuffer]="7"
    >
        <input #grantInput [formControl]="searchTerm" [matAutocomplete]="auto" autocomplete="off" matInput />
        <mat-label>{{ 'funding.lookup' | customTranslate }}</mat-label>
        <mat-progress-bar *ngIf="searchInProgress" color="primary" mode="indeterminate"></mat-progress-bar>
        @for (error of searchTerm.errors | keyvalue; track error.key) {
            <mat-error>{{ error.value | translate }}</mat-error>
        }
    </mat-form-field>
    <span class="add-btn" *ngIf="showAddBtn">
        <span class="margin-left-sm margin-right-xs">{{ 'funding.or' | customTranslate }}</span>
        <button #addNewBtn (click)="addBtnClick.emit($event)" color="primary" mat-button type="button">
            {{ 'funding.add' | customTranslate }}
        </button>
    </span>
</div>

<mat-autocomplete #auto="matAutocomplete" class="is-wide">
    <!-- no results -->
    <mat-option class="no-results-option" *ngIf="!searchInProgress && noResultsMessage" disabled>
        <span role="alert" [innerHTML]="noResultsMessage | translate"></span>
    </mat-option>

    <mat-optgroup *ngIf="filteredSuggestedGrant.length > 0" [label]="'research.grants.suggestions' | customTranslate">
        <mat-option
            (onSelectionChange)="handleGrantSelect(suggested, $event)"
            *ngFor="let suggested of filteredSuggestedGrant"
        >
            <div class="autocomplete-option-multiline">
                <span
                    [innerHTML]="
                        suggested.grantName + (suggested?.grantId ? ' (' + suggested.grantId + ')' : '')
                            | highlight: searchTerm.value
                    "
                    class="semibold"
                ></span>
                <small *ngIf="!suggested.funderAgency" class="italics">{{
                    suggested.funderCode | defaultTranslate: { default: suggested.funderName }
                }}</small>
                <small *ngIf="suggested.funderAgency" class="italics"
                    >{{ suggested.funderCode | defaultTranslate: { default: suggested.funderName } }}
                    ,
                    {{ suggested.funderAgencyCode | defaultTranslate: { default: suggested.funderAgency } }}</small
                >
            </div>
        </mat-option>
    </mat-optgroup>
    <mat-optgroup *ngIf="grantsFromPivot.length > 0" label="{{ 'research.grants.pivot' | customTranslate }}">
        <mat-option
            (onSelectionChange)="onPivotGrantSelect(suggested, $event)"
            *ngFor="let suggested of grantsFromPivot"
        >
            <div class="autocomplete-option-multiline">
                <span
                    [innerHTML]="
                        suggested.grantName + (suggested?.grantId ? ' (' + suggested.grantId + ')' : '')
                            | highlight: searchTerm.value
                    "
                    class="semibold"
                ></span>
            </div>
        </mat-option>
    </mat-optgroup>

    <mat-optgroup *ngIf="filteredGrants.length > 0" label="{{ 'research.grants.esploro' | customTranslate }}">
        <mat-option (onSelectionChange)="handleGrantSelect(result, $event)" *ngFor="let result of filteredGrants">
            <div class="autocomplete-option-multiline">
                <span
                    [innerHTML]="
                        result.grantName + (result?.grantId ? ' (' + result.grantId + ')' : '')
                            | highlight: searchTerm.value
                    "
                    class="semibold"
                ></span>

                <small class="italics"
                    >{{ result.funderCode | defaultTranslate: { default: result.funderName } }}
                    @if (result.funderAgency) {
                        {{ ', ' + (result.funderAgencyCode | defaultTranslate: { default: result.funderAgency }) }}
                    }
                </small>
            </div>
        </mat-option>
    </mat-optgroup>
</mat-autocomplete>
