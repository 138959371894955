import { Component, OnDestroy, OnInit } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { Subject, takeUntil } from 'rxjs';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';

@Component({
  selector: 'esp-deposit-methods',
  templateUrl: './deposit-methods.component.html',
  styleUrls: ['./deposit-methods.component.scss'],
})
export class DepositMethodsComponent implements OnInit, OnDestroy {
  private methodsDestroy = new Subject<void>();

  constructor(public depositFormDataService: DepositFormDataService) { }

  ngOnInit() {
    this.onChanges();
  }

  onChanges(): void {
    this.depositFormDataService.methods.valueChanges
      .pipe(takeUntil(this.methodsDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.METHODS
          );
        }
      });
  }

  get isAutoPopulated() {
    return this.depositFormDataService.isKeyAutoPopulated(
      AutoPopulateKeys.METHODS
    );
  }

  ngOnDestroy() {
    if (!this.methodsDestroy.isStopped) {
      this.methodsDestroy.next();
      //unsubscribe from the subject itself
      this.methodsDestroy.unsubscribe();
    }
  }
}
