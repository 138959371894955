import { Injectable, SecurityContext } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DepositFormDataService } from '../deposit/deposit-form-data.service';
import { ResearcherAsset } from '../shared/interfaces/researcher-asset.interface';
import { DepositForm } from '../shared/configurations/deposit-form.constant';
import { ResourceTypeUtils } from '../shared/utils/resource-type.utils';
import { ResourceTypeModel } from '../shared/interfaces/resource-type.model';
import { EsploroRecord } from '../shared/interfaces/esploro-record.model';
import { StringUtils } from '../shared/utils/string.utils';
import { Profile } from '../shared/configurations/profile.constant';
import { I18nService } from '../core/i18n.service';
import { HtmlStripPipe } from '@exl-ng/mulo-common';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class ProfileAssetDisplayEnrichmentService {
    htmlStripPipe: HtmlStripPipe;
    METADATA_BRIEF_DELIMITER = ', ';
    FIELD_DELIMITER = ' - ';

    constructor(
        private depositFormDataService: DepositFormDataService,
        private translate: TranslateService,
        private i18nService: I18nService,
        private sanitizer: DomSanitizer
    ) {
        this.htmlStripPipe = new HtmlStripPipe();
    }

    getIsPartOf(item: ResearcherAsset) {
        const relationsList = item.esploroRecord.relationship;
        if (relationsList) {
            const isPartOfList = relationsList.filter(
                (relation) => relation.relationtype === DepositForm.IS_PART_OF
            );
            if (isPartOfList.length > 0) {
                const isPartOf = isPartOfList[0];
                return isPartOf;
            }
        }
        return undefined;
    }

    getAuthor(item: ResearcherAsset) {
        const authors = this.depositFormDataService.getSortedAuthors(item);
        const authorNames = authors.map((author) => {
            const firstName = author.firstName ? author.firstName : '';
            const lastName = author.lastName ? author.lastName : '';
            const middleName = author.middleName ? author.middleName : '';
            const nameSuffix = author.nameSuffix ? author.nameSuffix : '';
            const name = new Array(firstName, middleName, lastName, nameSuffix)
                .filter(Boolean)
                .join(' ');
            return name;
        });
        return authorNames;
    }

    getType(item: ResearcherAsset) {
        const resourceType =
            ResourceTypeUtils.convertEsploroRecordResourceTypeToCategoryAndType(
                item.esploroRecord.resourcetypeEsploro
            ) as ResourceTypeModel;
        if (resourceType) {
            return resourceType.getType();
        }
        return '';
    }

    getDateByPriority(item) {
        const date = {};
        if (item.dateCodeByPriority && item.dateAsStrByPriority) {
            date['code'] = item.dateCodeByPriority;
            date['value'] = item.dateAsStrByPriority;
        }
        return date;
    }

    getAssetMetadataBrief(item) {
        let metadata = '';
        //all publication, not only the type that has a dedicated form
        const isPublication = item.type.indexOf('publication.') === 0;
        const isConference = ResourceTypeUtils.isConference(item.type);
        const isPresentation = ResourceTypeUtils.isPresentation(item.type);
        const isPatent = ResourceTypeUtils.isPatent(item.type);
        if (isPublication) {
            metadata = this.getMetadataForPublication(item);
        } else if (isConference) {
            metadata = this.getMetadataForConference(item);
        } else if (isPresentation) {
            metadata = this.getMetadataForPresentation(item);
        } else if (isPatent) {
            metadata = this.getMetadataForPatent(item);
        }
        metadata = metadata.replace(/^, +/g, '').replace(/, $/g, '');
        return metadata;
    }

    getLines(item) {
        const lines = [
            this.getMetadataForPublication(item),
            this.getMetadataForConference(item),
            this.getMetadataForPresentation(item),
            this.getMetadataForPatent(item),
        ]
            .map((line) => this.htmlStripPipe.transform(line))
            .filter((line) => line != null);
        return lines;
    }

    getMetadataForPublication(item) {
        let metadata = '';
        if (item.isPartOf) {
            metadata = new Array(
                item.isPartOf.relationtitle,
                item.isPartOf.volume,
                item.isPartOf.issue
            )
                .filter(Boolean)
                .join(this.METADATA_BRIEF_DELIMITER);
            if (item.isPartOf.startPage && item.isPartOf.endPage) {
                metadata =
                    metadata +
                    this.METADATA_BRIEF_DELIMITER +
                    item.isPartOf.startPage +
                    this.FIELD_DELIMITER +
                    item.isPartOf.endPage;
            } else if (item.isPartOf.startPage) {
                metadata =
                    metadata +
                    this.METADATA_BRIEF_DELIMITER +
                    item.isPartOf.startPage;
            } else if (item.isPartOf.endPage) {
                metadata =
                    metadata +
                    this.METADATA_BRIEF_DELIMITER +
                    item.isPartOf.endPage;
            } else if (item.isPartOf.articleNumber) {
                metadata =
                    metadata +
                    this.METADATA_BRIEF_DELIMITER +
                    item.isPartOf.articleNumber;
            }
        }
        return metadata;
    }

    getMetadataForConference(item) {
        const esploroRecord = item.esploroRecord as EsploroRecord;
        const metadata = [
            esploroRecord.conferencename,
            item.conferenceDateAsStr,
            esploroRecord.conferencelocation,
        ]
            .filter(Boolean)
            .join(this.METADATA_BRIEF_DELIMITER);
        return metadata;
    }

    getMetadataForPresentation(item) {
        const esploroRecord = item.esploroRecord as EsploroRecord;
        let metadata = '';
        if (esploroRecord.event) {
            const event = esploroRecord.event;
            metadata = [
                event.eventName,
                item.eventDateAsStr,
                event.eventLocation,
            ]
                .filter(Boolean)
                .join(this.METADATA_BRIEF_DELIMITER);
        }
        return metadata;
    }

    getMetadataForPatent(item) {
        let metadata = '';
        const esploroRecord = item.esploroRecord as EsploroRecord;
        if (esploroRecord.patent) {
            const patent = esploroRecord.patent;
            if (patent.patentNumber) {
                metadata =
                    metadata +
                    patent.patentNumber +
                    this.METADATA_BRIEF_DELIMITER;
            }
            if (patent.patentStatus) {
                this.translate
                    .get('PatentStatus.' + patent.patentStatus)
                    .subscribe((res) => {
                        metadata = metadata + res;
                    });
            }
        }
        return metadata;
    }

    getAbstractDescription(item) {
        return item.esploroRecord.descriptionAbstract?.join(
            DepositForm.DESCRIPTION_JOIN_PARAM
        );
    }

    getDiscoveryURL(mmsId: number | string = '', institutionCode: string = '') {
        let discoveryURL = Profile.DISCOVERY_URL;
        discoveryURL = discoveryURL.replace('{{mms_id}}', mmsId.toString());
        discoveryURL = discoveryURL.replace(
            '{{institution_code}}',
            institutionCode
        );
        discoveryURL = discoveryURL.replace(
            '{{lang}}',
            this.i18nService.getLanguage()
        );
        return discoveryURL;
    }
}
