import { Component, OnInit, Input, ElementRef } from '@angular/core';

@Component({
    selector: 'mulo-header-main-footer-layout',
    templateUrl: './header-main-footer-layout.component.html',
    styleUrls: ['./header-main-footer-layout.component.scss'],
    standalone: true,
})
export class HeaderMainFooterLayoutComponent implements OnInit {
  @Input()
  private _headerHeight: string = '64px';
  public get headerHeight() {
    return this._headerHeight;
  }
  public set headerHeight(value: string | number) {
    if (typeof value === 'number') {
      this._headerHeight = value + 'px';
    } else {
      this._headerHeight = value;
    }
  }

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {
    this._elementRef.nativeElement.style.setProperty(
      '--header-height',
      this.headerHeight
    );
  }
}
