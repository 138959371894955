import { Component, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { UrlUtils } from '../../shared/utils/url.utils';
import { EntityService } from '../../core/entity.service';
import { Observable } from 'rxjs';
import { ConfigurationHandlerService } from '../../core/configuration-handler.service';
import { MappingTable } from '../../shared/interfaces/mapping-table.interface';
import { ContentDialogService } from '../../parts/dialogs/content-dialog/content-dialog.service';
import { SnackbarService } from '../../core/snackbar.service';
import { ActivityDetails } from '../../shared/interfaces/activity.interface';
import { ActivitiesService } from '../activities.service';
import { SuccessSnackbarComponent } from '../../parts/snackbars/success-snackbar/success-snackbar.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { MultiLanguageService } from '../../parts/multi-language/multi-language.service';

@Component({
    selector: 'esp-add-activities',
    templateUrl: './add-activities.component.html',
    styleUrls: ['./add-activities.component.scss'],
    animations: [
        trigger('SpinnerIn', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateX(100%) scale(.5)',
                }),
                animate(
                    '300ms 300ms cubic-bezier(0.165, 0.84, 0.44, 1)',
                    style({
                        opacity: 1,
                        transform: 'translateX(0) scale(1)',
                    })
                ),
            ]),
        ]),
    ],
})
export class AddActivitiesComponent implements OnInit {
    activityForm: UntypedFormGroup;
    institutionCode = UrlUtils.getParam('institution');

    activityCategories$: Observable<string[]>;
    activityTypes$: Observable<string[]>;
    activityTypesMapping: MappingTable[] = [];

    loading = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private entityService: EntityService,
        private configurationHandlerService: ConfigurationHandlerService,
        private contentDialogService: ContentDialogService,
        private snackbarService: SnackbarService,
        private activitiesService: ActivitiesService,
        private router: Router,
        private multiLanguageService: MultiLanguageService
    ) {}

    ngOnInit() {
        this.initForm();
        this.activityTypesMapping =
            this.configurationHandlerService.getMappingTable(
                'ResearcherActivityTypesMapping'
            ) as MappingTable[];
    }

    initForm() {
        this.activityForm = this.formBuilder.group({
            name: ['', [Validators.required, Validators.maxLength(4000)]],
            categoryCode: ['', Validators.required],
            typeCode: [''],
        });

        this.activityCategories$ = this.entityService.getCodeTableList(
            'ResearcherActivityCategories'
        );
        this.activityTypes$ = this.entityService.getCodeTableList(
            'ResearcherActivityTypes'
        );
    }

    resetTypeSelection(event) {
        this.activityForm.get('typeCode').reset('');
    }

    filterActivityType = (activityCode: string) => {
        return this.activityTypesMapping.some(
            (row) =>
                row.enabled &&
                row.source1 === this.activityForm.value.categoryCode &&
                row.target === activityCode
        );
    };

    submit() {
        this.loading = true;
        const newActivity: ActivityDetails = this.activityForm.value;
        newActivity.name = {
            [this.multiLanguageService.defaultLangKey()]:
                this.activityForm.value?.name,
        };
        newActivity.visibleInPortal = false;
        newActivity.visibleInProfiles = true;
        newActivity.repositoryStatusCode = 'approved';

        this.activitiesService
            .addNewActivity(newActivity)
            .subscribe((result: any) => {
                this.loading = false;
                this.contentDialogService.closeDialog();
                this.navigateToActivityPage(result.url);
                this.showSuccessfullMessage();
            });
    }

    showSuccessfullMessage() {
        this.snackbarService.showComponent(
            SuccessSnackbarComponent,
            'research.add.activity.dialog.success.message',
            9000
        );
    }

    navigateToActivityPage(url) {
        this.router.navigate([url], {
            queryParams: { institution: this.institutionCode, mode: 'edit' },
        });
    }
}
