export class ResearcherEmail {
    email: string;
    isPreferred: boolean;
    esploroDisplayEmail: number;
    isExternal: boolean;
    type?: string;

    constructor(email, isPreferred, esploroDisplayEmail){
        this.email = email;
        this.isPreferred = isPreferred;
        this.esploroDisplayEmail = esploroDisplayEmail;
        this.isExternal = false;
    }
}
