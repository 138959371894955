import { Component, OnDestroy, OnInit } from '@angular/core';
import { DepositFormDataService } from '../../../deposit-form-data.service';
import { StatusUtils } from '../../../../shared/utils/status.utils';
import { AutoPopulateKeys } from '../../../../shared/configurations/auto-populate-keys.config';
import { DepositForm } from '../../../../shared/configurations/deposit-form.constant';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'esp-deposit-publication-details-book',
  templateUrl: './deposit-publication-details-book.component.html',
  styleUrls: ['./deposit-publication-details-book.component.scss'],
})
export class DepositPublicationDetailsBookComponent
  implements OnInit, OnDestroy {
  private editionDestroy = new Subject<void>();
  private seriesDestroy = new Subject<void>();
  private pagesDestroy = new Subject<void>();
  private isbnDestroy = new Subject<void>();
  private publisherDestroy = new Subject<void>();
  private publicationPlaceDestroy = new Subject<void>();

  constructor(public depositFormDataService: DepositFormDataService) { }

  ngOnInit() {
    this.onChangesEdition();
    this.onChangesSeries();
    this.onChangesPages();
    this.onChangesPublisher();
    this.onChangesIsbnForDisplay();
    this.onChangesPublicationPlaceForDisplay();
  }

  onChangesIsbnForDisplay(): void {
    this.depositFormDataService.isbnForDisplay.valueChanges
      .pipe(takeUntil(this.isbnDestroy))
      .subscribe((value) => {
        const isbnForDisplay = value as string;
        let isbn = [];
        if (isbnForDisplay) {
          isbn = isbnForDisplay.split(DepositForm.VALUES_SEPARATOR);
        }
        this.depositFormDataService.updateIsbn(isbn);
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.ISBN_FOR_DISPLAY
          );
        }
      });
  }

  onChangesPublicationPlaceForDisplay(): void {
    this.depositFormDataService.publicationPlaceForDisplay.valueChanges
      .pipe(takeUntil(this.publicationPlaceDestroy))
      .subscribe((value) => {
        const publicationPlaceForDisplay = value as string;
        let publicationPlace = [];
        if (publicationPlaceForDisplay) {
          publicationPlace = publicationPlaceForDisplay.split(
            DepositForm.VALUES_SEPARATOR
          );
        }
        this.depositFormDataService.updatePublicationPlace(
          publicationPlace
        );
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.PUBLICATION_PLACE
          );
        }
      });
  }

  onChangesPublisher(): void {
    this.depositFormDataService.publisher.valueChanges
      .pipe(takeUntil(this.publisherDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.PUBLISHER
          );
        }
      });
  }

  onChangesEdition(): void {
    this.depositFormDataService.edition.valueChanges
      .pipe(takeUntil(this.editionDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.EDITION
          );
        }
      });
  }

  onChangesSeries(): void {
    this.depositFormDataService.series.valueChanges
      .pipe(takeUntil(this.seriesDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.SERIES
          );
        }
      });
  }

  onChangesPages(): void {
    this.depositFormDataService.pages.valueChanges
      .pipe(takeUntil(this.pagesDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.PAGES
          );
        }
      });
  }

  displayPublisherName() {
    return StatusUtils.displayPublisherName(this.status);
  }

  displayEdition() {
    return StatusUtils.displayEdition(this.status);
  }

  displaySeries() {
    return StatusUtils.displaySeries(this.status);
  }

  displayPublicationPlace() {
    return StatusUtils.displayPublicationPlace(this.status);
  }

  displayPages() {
    return StatusUtils.displayPages(this.status);
  }

  displayISBN() {
    return StatusUtils.displayISBN(this.status);
  }

  get status() {
    return this.depositFormDataService.status.value;
  }

  isAutoPopulated(key) {
    return this.depositFormDataService.isKeyAutoPopulated(key);
  }

  ngOnDestroy() {
    if (!this.pagesDestroy.isStopped) {
      this.pagesDestroy.next();
      //unsubscribe from the subject itself
      this.pagesDestroy.unsubscribe();
    }
    if (!this.isbnDestroy.isStopped) {
      this.isbnDestroy.next();
      this.isbnDestroy.unsubscribe();
    }
    if (!this.editionDestroy.isStopped) {
      this.editionDestroy.next();
      this.editionDestroy.unsubscribe();
    }
    if (!this.seriesDestroy.isStopped) {
      this.seriesDestroy.next();
      this.seriesDestroy.unsubscribe();
    }
    if (!this.publisherDestroy.isStopped) {
      this.publisherDestroy.next();
      this.publisherDestroy.unsubscribe();
    }
    if (!this.publicationPlaceDestroy.isStopped) {
      this.publicationPlaceDestroy.next();
      this.publicationPlaceDestroy.unsubscribe();
    }
  }
}
