<div *ngIf="scopes?.length > 1" class="scopes">
    <span id="title-label">{{ 'research.advanced.search.scopes.title' | translate }}:</span>
    <mat-form-field class="no-subscript">
        <mat-select [attr.aria-labelledby]="'title-label'" [(ngModel)]="selectedScope" id="scope">
            <mat-option *ngFor="let option of scopes" [value]="option.value">
                {{ option.viewValue | translate }}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<fieldset [class.margin-top-sm]="media.is('gt-sm') && !(scopes?.length > 1)">
    <legend>{{ 'research.advanced.search.filters.title' | translate }}:</legend>
    <div class="fields-grid">
        <ng-container *ngFor="let line of lines | slice : 0 : formClauseCount; index as i; let first = first">
            <mat-form-field *ngIf="!first">
                <mat-label>{{ 'research.aria.search.combobox.operator' | translate }}</mat-label>
                <mat-select [(ngModel)]="line.boolOperator" id="clause-{{ i }}-bool">
                    <mat-option *ngFor="let option of this.BOOLEAN_OPERATORS" [value]="option">
                        {{ 'research.advanced.search.boolOperator.option.' + option | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field [class.is-first]="first" class="clause-index">
                <mat-label>{{ 'research.aria.search.combobox.index' | translate }}</mat-label>
                <mat-select [(ngModel)]="line.searchIndex" id="clause-{{ i }}-index">
                    <mat-option *ngFor="let option of searchIndexes" [value]="option">
                        {{ 'research.portal.search.index_names.' + option | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="line.searchIndex === 'title'">
                <mat-label>{{ 'research.aria.search.combobox.type' | translate }}</mat-label>
                <mat-select [(ngModel)]="line.searchOperator" id="clause-{{ i }}-precision_all">
                    <mat-option *ngFor="let option of PRECISION_OPERATORS_ALL" [value]="option">
                        {{ 'research.advanced.search.precisionOperator.option.' + option | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="line.searchIndex !== 'title'">
                <mat-label>{{ 'research.aria.search.combobox.type' | translate }}</mat-label>
                <mat-select [(ngModel)]="line.searchOperator" id="clause-{{ i }}-precision_partial">
                    <mat-option *ngFor="let option of PRECISION_OPERATORS_PARTIAL" [value]="option">
                        {{ 'research.advanced.search.precisionOperator.option.' + option | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="clause-term">
                <mat-label>{{ 'research.advanced.search.line.placeholder' | translate }}</mat-label>
                <input
                    (keydown.enter)="submitSearch()"
                    [(ngModel)]="line.searchQuery"
                    id="clause-{{ i }}-term"
                    matInput
                    [required]="first"
                />
                <mat-error [innerHtml]="'research.settings.field.required.error.message' | translate"></mat-error>
            </mat-form-field>
        </ng-container>

        <div class="add-clause">
            <button
                (click)="addFormClause()"
                [disabled]="formClauseCount >= ADVANCED_SEARCH_MAX_QUERY_LINES"
                class="has-icon"
                color="primary"
                mat-button
            >
                <mat-icon svgIcon="plus"></mat-icon>
                {{ 'research.advanced.search.line.add' | translate }}
            </button>
            <button (click)="resetForm()" mat-button>
                {{ 'research.advanced.search.line.clear' | translate }}
            </button>
        </div>
    </div>

    <div *ngIf="displayYearFilter || displayResourceTypeFilter" class="special-filters">
        <ng-container *ngFor="let filter of searchFilters">
            <mat-form-field *ngIf="displayResourceTypeFilter && filter === 'resource_type'">
                <mat-label>{{ 'research.portal.search.filters.resource_type' | translate }}</mat-label>
                <mat-select [(ngModel)]="rtypeFilter" id="resource-type">
                    <mat-option *ngFor="let option of rtypeFilterOptions" [value]="option">
                        {{ 'research.advanced.resourceType.' + option | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="displayYearFilter && filter === 'year'">
                <exl-date-input
                    [fromDateTranslation]="'research.portal.search.filters.year.start' | translate"
                    [fromDate]="this.dateForm.get('fromDate')"
                    [rangeError]="!this.isDateValid()"
                    [range]="true"
                    [showHint]="false"
                    [toDateTranslation]="'research.portal.search.filters.year.end' | translate"
                    [toDate]="this.dateForm.get('toDate')"
                    id="date-input"
                ></exl-date-input>
            </ng-container>
        </ng-container>
    </div>
</fieldset>
<div *mediaIf="'gt-sm'" class="summary">
    <ng-container *ngFor="let line of lines | slice : 0 : formClauseCount; index as i; let first = first">
        <div *ngIf="line.searchQuery" [class]="'summary-line-' + line.boolOperator | lowercase">
            <mat-icon
                *ngIf="first || line.boolOperator === 'OR'"
                color="primary"
                svgIcon="arrow-curve-down-right"
            ></mat-icon>
            <mat-form-field *ngIf="!first" class="summary-clause summary-clause-boolean">
                <mat-label class="cdk-visually-hidden">{{
                    'research.aria.search.combobox.operator' | translate
                }}</mat-label>
                <mat-select [(ngModel)]="line.boolOperator" id="summary-clause-{{ i }}-bool">
                    <mat-option *ngFor="let option of this.BOOLEAN_OPERATORS" [value]="option">
                        {{ 'research.advanced.search.boolOperator.option.' + option | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="summary-clause summary-clause-index">
                <mat-label class="cdk-visually-hidden">{{
                    'research.aria.search.combobox.index' | translate
                }}</mat-label>
                <mat-select [(ngModel)]="line.searchIndex" id="summary-clause-{{ i }}-index">
                    <mat-option *ngFor="let option of searchIndexes" [value]="option">
                        {{ 'research.portal.search.index_names.' + option | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="line.searchIndex === 'title'" class="summary-clause summary-clause-precision">
                <mat-label class="cdk-visually-hidden">{{
                    'research.aria.search.combobox.type' | translate
                }}</mat-label>
                <mat-select [(ngModel)]="line.searchOperator" id="summary-clause-{{ i }}-precision_all">
                    <mat-option *ngFor="let option of PRECISION_OPERATORS_ALL" [value]="option">
                        {{ 'research.advanced.search.precisionOperator.option.' + option | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="line.searchIndex !== 'title'" class="summary-clause summary-clause-precision">
                <mat-label class="cdk-visually-hidden">{{
                    'research.aria.search.combobox.type' | translate
                }}</mat-label>
                <mat-select [(ngModel)]="line.searchOperator" id="summary-clause-{{ i }}-precision_partial">
                    <mat-option *ngFor="let option of PRECISION_OPERATORS_PARTIAL" [value]="option">
                        {{ 'research.advanced.search.precisionOperator.option.' + option | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="summary-clause summary-clause-term">
                <span #termWidthCtrl aria-hidden class="summary-clause-term-hidden">{{ line.searchQuery }} </span>
                <input
                    [(ngModel)]="line.searchQuery"
                    [style.min-width]="'min(' + termWidthCtrl.scrollWidth + 'px, 100%)'"
                    [style.width.px]="termWidthCtrl.scrollWidth"
                    id="summary-clause-{{ i }}-term"
                    matInput
                />
            </mat-form-field>
        </div>
    </ng-container>
</div>

<mat-card-actions *mediaIf="'gt-sm'" align="end">
    <button (click)="submitSearch()" color="primary" mat-flat-button>
        <span class="margin-right-xs">{{ 'research.advanced.search.submit' | translate }}</span>
        <mat-icon svgIcon="magnify"></mat-icon>
    </button>
</mat-card-actions>

<button
    (click)="submitSearch()"
    *mediaIf="'lt-md'"
    class="adv-search-search-btn has-icon"
    color="primary"
    mat-flat-button
>
    <mat-icon svgIcon="magnify"></mat-icon>
    <span>{{ 'research.advanced.search.submit' | translate }}</span>
</button>
