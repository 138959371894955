import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { FooterLinksSectionComponent } from './footer-links-section/footer-links-section.component';
import { FooterLinksSectionHeadDirective } from './footer-links-section/footer-link-section-heade-directive.directive';
import { FooterLinksComponent } from './footer-links/footer-links.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    imports: [SharedModule],
    declarations: [
        FooterComponent,
        FooterLinksSectionComponent,
        FooterLinksComponent,
        FooterLinksSectionHeadDirective,
    ],
    exports: [
        FooterComponent,
        FooterLinksSectionComponent,
        FooterLinksComponent,
        FooterLinksSectionHeadDirective,
        SharedModule,
    ],
})
export class FooterModule {}
