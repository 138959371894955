import { CreatorContributorType } from './creator-contributor.interface';
import { EsploroCreatorStatus } from './esploro-author.interface';

export class User {
    name: string;
    actualName: string;
    id: string;
    orcid: string;
    affiliationCode: string;
    affiliationName: string;
    affiliationNameList: string[];
    affiliationCodeList: string[];
    role: string;
    owner: boolean;
    type: CreatorContributorType;
    sourceAffiliation?: string;
    isDisplayInPublicProfile: boolean;
    isAffiliated: boolean;
    source = 'person';
    status: EsploroCreatorStatus;
    sendUsage?: boolean;
    email?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    nameSuffix?: string;
    isResearcher: boolean;
    displayInList?: 'owner' | 'authors' | 'advisors' | null;

    constructor(author?: User) {
        if (author) {
            this.setName(author.name);
            this.actualName = author.actualName;
            this.setId(author.id);
            this.setOrcid(author.orcid);
            this.setAffiliationName(author.affiliationName);
            this.setAffiliationCode(author.affiliationCode);
            this.setAffiliationNameList(author.affiliationNameList);
            this.setAffiliationCodeList(author.affiliationCodeList);
            this.setRole('');
            this.setOwner(false);
            this.setIsAffiliated(author.isAffiliated);
            this.setIsDisplayInPublicProfile(author.isAffiliated);
            this.setStatus(EsploroCreatorStatus.MANUAL);
            this.sendUsage = author.sendUsage;
            this.firstName = author.firstName;
            this.lastName = author.lastName;
            this.middleName = author.middleName;
            this.nameSuffix = author.nameSuffix;
            this.email = author.email;
        }
    }

    isOwner(): boolean {
        return this.owner;
    }

    setOwner(value: boolean) {
        this.owner = value;
    }

    getRole(): string {
        return this.role;
    }

    setRole(value: string) {
        this.role = value;
    }

    getAffiliationCode(): string {
        return this.affiliationCode;
    }

    setAffiliationCode(value: string) {
        this.affiliationCode = value;
    }

    getAffiliationName(): string {
        return this.affiliationName;
    }

    setAffiliationName(value: string) {
        this.affiliationName = value;
    }

    getAffiliationNameList(): string[] {
        return this.affiliationNameList;
    }

    setAffiliationNameList(value: string[]) {
        this.affiliationNameList = value;
    }
    getAffiliationCodeList(): string[] {
        return this.affiliationCodeList;
    }

    setAffiliationCodeList(value: string[]) {
        this.affiliationCodeList = value;
    }

    getOrcid(): string {
        return this.orcid;
    }

    setOrcid(value: string) {
        this.orcid = value;
    }
    getName(): string {
        return this.name;
    }

    setName(value: string) {
        this.name = value;
    }

    setFirstName(firstName: string) {
        this.firstName = firstName;
    }

    setLastName(lastName: string) {
        this.lastName = lastName;
    }

    getId(): string {
        return this.id;
    }

    setId(value: string) {
        this.id = value;
    }

    getType(): CreatorContributorType {
        return this.type;
    }

    setType(value: CreatorContributorType) {
        this.type = value;
    }

    getSourceAffiliation(): string {
        return this.sourceAffiliation;
    }

    setSourceAffiliation(value: string) {
        this.sourceAffiliation = value;
    }

    getIsDisplayInPublicProfile(): boolean {
        return this.isDisplayInPublicProfile;
    }

    setIsDisplayInPublicProfile(value: boolean) {
        this.isDisplayInPublicProfile = value;
    }

    getIsAffiliated(): boolean {
        return this.isAffiliated;
    }

    setIsAffiliated(value: boolean) {
        this.isAffiliated = value;
    }

    setSource(value: string) {
        this.source = value;
    }

    getStatus(): EsploroCreatorStatus {
        return this.status;
    }

    setStatus(value: EsploroCreatorStatus) {
        this.status = value;
    }
}
