import { Component, OnInit, Input } from '@angular/core';
import { BadgeComponent } from '../badge.component';

@Component({
  selector: 'exl-badge-auto-populated',
  templateUrl: './badge-auto-populated.component.html',
  styleUrls: ['./badge-auto-populated.component.scss'],
  host: { class: 'exl-badge-auto-populated' }
})
export class BadgeAutoPopulatedComponent extends BadgeComponent implements OnInit  {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
