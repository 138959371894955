<button
  mat-icon-button
  (click)="toggle()"
  [matTooltip]="toolTipText"
  [disabled]="disabled"
>
  <span class="content">
    <mat-icon [svgIcon]="active ? 'eye-outline' : 'eye-off-outline'"></mat-icon>
    <span class="text">{{active ? visibleLabel : hiddenLabel}}</span>
  </span>
</button>
