import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'mulo-scrollable-toggle-group',
    templateUrl: './scrollable-toggle-group.component.html',
    styleUrls: ['./scrollable-toggle-group.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatIconButton,
        MatIcon,
        MatButtonToggleGroup,
        NgFor,
        MatButtonToggle,
    ],
})
export class ScrollableToggleGroupComponent implements OnChanges {
  @Input() buttonsKeys = [];
  @Input() buttonsKeysAriaLabels = [];
  @Input() selectedButton;
  @Input() canAdd = true;
  @Input() scrollLeftBtnAriaLabel = 'Scroll Backward';
  @Input() scrollRightBtnAriaLabel = 'Scroll Forward';
  @Input() buttonGroupLabel = 'Languages';

  @Output() selectedButtonChanged: EventEmitter<any> = new EventEmitter();
  @ViewChild('scrollerWrapper') scrollerWrapper: ElementRef;
  @ViewChildren(MatButtonToggle) buttons: QueryList<MatButtonToggle>;

  _disableScrollBefore = true;
  _disableScrollAfter = true;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) return;
    if (changes.buttonsKeys) {
      setTimeout(() => {
        this._checkScrollingControls();
      });
    }
    if (changes.selectedButton) {
      this.moveToSelected();
    }
  }

  moveToSelected() {
    if (!this.buttons) return;
    setTimeout(() => {
      const el = this.buttons.find((item) => item.checked)?._buttonElement
        .nativeElement.parentElement.offsetLeft;
      this.scrollerWrapper.nativeElement.scrollLeft = el;
      this._checkScrollingControls();
    }, 100);
  }

  scroll(event, direction: string) {
    const scrollWrapper = this.scrollerWrapper.nativeElement;
    scrollWrapper.scrollLeft += direction === 'right' ? 100 : -100;
    this._checkScrollingControls();
  }

  _getMaxScrollDistance(): number {
    const scrollWrapper = this.scrollerWrapper.nativeElement;
    const buttonsSize = this.buttons.reduce(
      (accumulator, obj) =>
        accumulator +
        obj._buttonElement.nativeElement.parentElement.offsetWidth,
      0
    );
    // get how much of menu is invisible
    return buttonsSize - scrollWrapper.offsetWidth || 0;
  }

  _checkScrollingControls() {
    if (!this.scrollerWrapper) return;
    // Check if the pagination arrows should be activated.
    this._disableScrollBefore =
      this.scrollerWrapper.nativeElement.scrollLeft <= 8;
    this._disableScrollAfter =
      this.scrollerWrapper.nativeElement.scrollLeft >=
      this._getMaxScrollDistance();
  }
}
