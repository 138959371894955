<mat-radio-group [formControl]="depositFormDataService.peerReviewed">
    <exl-badge-auto-populated
        *ngIf="isAutoPopulated && depositFormDataService.peerReviewed.value === 'NO'"
        tooltip="{{'research.auto.populate.tooltip' | customTranslate}}"
        color="primary">
    </exl-badge-auto-populated>
    <mat-radio-button value="NO"
                      type="radio"
                      [class.is-autopopulated]="isAutoPopulated && depositFormDataService.peerReviewed.value === 'NO'">
                      {{'research.peereview.no' | customTranslate}}
    </mat-radio-button>
    <exl-badge-auto-populated
        *ngIf="isAutoPopulated && depositFormDataService.peerReviewed.value === 'YES'"
        tooltip="{{'research.auto.populate.tooltip' | customTranslate}}"
        color="primary">
    </exl-badge-auto-populated>
    <mat-radio-button value="YES"
                      type="radio"
                      [class.is-autopopulated]="isAutoPopulated && depositFormDataService.peerReviewed.value === 'NO'">
                      {{'research.peereview.yes' | customTranslate}}
    </mat-radio-button>
</mat-radio-group>
