import { AriaDescriber } from '@angular/cdk/a11y';
import { Directive, ElementRef, Input, NgModule, OnInit } from '@angular/core';

@Directive({
    selector: '[mulo-aria-describe]',
    standalone: true,
})
export class AriaDescriberDirective implements OnInit {
    @Input('mulo-aria-describe') ariaDescribe: string;

    constructor(
      private el: ElementRef<HTMLElement>,
      private ariaDescriber: AriaDescriber,
    ) { }
  
    ngOnInit(): void {
      this.ariaDescriber.describe(this.el?.nativeElement, this.ariaDescribe);
    }
}


