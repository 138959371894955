import { Injectable } from '@angular/core';
import {ConfigurationHandlerService} from './configuration-handler.service';
import {JwtUtilService} from './jwt-util.service';


@Injectable({
    providedIn: 'root'
})
export class AuthorizationService {

    constructor(private configurationHandlerService: ConfigurationHandlerService,
    			private jwtUtilService: JwtUtilService){}

    isResearcherDepositsEnabled(){
        return this.configurationHandlerService.getCustomerParameter('esploro_enable_researcher_deposits') === 'true';
    }

	isAuthorizedForDeposit(){
		return this.jwtUtilService.isLoggedIn() && this.isResearcherDepositsEnabled() && this.jwtUtilService.isAuthorizedForDeposit();
	}
}
