<mat-card appearance="outlined">
    <exl-citation-brief
        [title]="research?.title"
        [authors]="getAuthorsAsArray()"
        [publication]="research?.publicationTitle"
        [metadata]="research?.publicationDate"
        [hoverable]="false"
    >
        <!-- extra content here -->
        <ng-container *ngIf="isInternalResults">
            <p class="message" [innerHTML]="message | customTranslate"></p>
            <div class="esp-request-action">
                <button mat-button (click)="onRemoveButtonClick()">
                    {{ 'research.selected.brief.button.label.ignore' | customTranslate }}
                </button>
                <button mat-raised-button color="primary" (click)="duplicateAction()">
                    {{ actionButtonLabel | customTranslate }}
                </button>
            </div>
        </ng-container>
    </exl-citation-brief>
    <div class="citation-brief-actions">
        <button mat-button class="preview" *ngIf="isInternalResults" (click)="openViewer()">
            <mat-icon svgIcon="eye-outline"></mat-icon>
            <span>{{ 'research.selected.brief.preview.button.label' | customTranslate }}</span>
        </button>
        <button
            mat-icon-button
            class="remove-button"
            (click)="onRemoveButtonClick()"
            attr.aria-label="{{ 'research.aria.deposit.selected.asset.remove' | translate }}"
        >
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </div>
    <ng-template #iframeTemplate>
        <button
            mat-mini-fab
            class="esp-viewer-dialog-close has-shadow"
            matDialogClose
            aria-label="{{ 'research.aria.dialog.close.dialog' | translate }}"
        >
            <mat-icon svgIcon="close"></mat-icon>
        </button>
        <iframe [src]="safeUrl"></iframe>
    </ng-template>
</mat-card>
