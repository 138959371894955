<nav
  mat-tab-nav-bar
  [muloVerticalTabs]="selectedIndex"
  class="is-scaling"
  [color]="color"
  #navbar
>
  <ng-content select="[slot='pre-nav']"></ng-content>
  <ng-template #recursiveNavList let-items>
    <ng-container *ngFor="let item of items; index as i; trackBy: trackItem">
      <a
        mat-tab-link
        [routerLink]="item.url ? assessUrl(item.url) : null"
        [fragment]="item.url && containsHash(item.url) ? unHash(item.url) : null"
        [queryParamsHandling]="queryParamsHandling"
        [skipLocationChange]="skipLocationChange"
        [active]="disableActiveIndication ? false : selectedIndex === item.id"
        [class.has-children]="item.children && item.children.length > 0"
        [class.is-expanded]="item.expanded"
        [class.is-toggled]="item.children && item.children.length > expandMinimum && item.toggled"
        [style.margin-inline-start.rem]="item.level"
        (click)="changeItem(item, item.url && containsHash(item.url))"
      >
        <ng-container
          *ngIf="itemTemplate; else defaultTemplate"
          [ngTemplateOutlet]="itemTemplate"
          [ngTemplateOutletContext]="{ $implicit: item, index: i }"
        >
        </ng-container>

        <ng-template #defaultTemplate>
          <span>{{ item.label }}&nbsp;<span *ngIf="item.length" class="counter">{{item.length}}</span></span>
        </ng-template>
      </a>

      <div
        class="mat-tab-link-children"
        *ngIf="item.expanded || item.children && item.children.length > expandMinimum && item.toggled"
      >
        <ng-container
          *ngTemplateOutlet="recursiveNavList; context: { $implicit: item.children }"
        >
        </ng-container>
      </div>
    </ng-container>
  </ng-template>
  <ng-content select="[slot='post-nav']"></ng-content>
  <ng-container
    *ngTemplateOutlet="recursiveNavList; context: { $implicit: items }"
  ></ng-container>
</nav>
