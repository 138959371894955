<div class="deposit-section-description">
    <span>{{ 'research.files.subtitle.part1' | customTranslate }}</span>
    <span>&nbsp;{{ 'research.files.subtitle.part2' | customTranslate }}</span>
    <button mat-button color="primary" (click)="onAddLink()" type="button">
        <span *ngIf="multiple">{{ 'research.files.link' | customTranslate }}</span>
    </button>
</div>

<exl-file-upload [multiple]="multiple" (addNewFile)="addNewFile($event)" [exlFocus]="jump"> </exl-file-upload>

<exl-file-list
    *ngIf="hasFiles()"
    listTitle="{{ 'research.filetable.title' | customTranslate }}"
    [fileUploadProgress]="isFileInUploadingProcess"
    [expandable]="editableFiles"
    [expandAllLabel]="'research.aria.deposit.files.and.links.list.expand.all' | translate"
    [collapseAllLabel]="'research.aria.deposit.files.and.links.list.collapse.all' | translate"
    (expandAll)="allFilesEditable = !allFilesEditable"
    (removeAll)="onRemoveAllFiles()"
>
    <!-- File items -->
    <exl-file-list-item
        *ngFor="let file of depositFormDataService.files.value; trackBy: trackByFile; let index = index"
        [item]="file"
        [index]="index"
        [expandable]="editableFiles"
        [allPanelsExpanded]="allFilesEditable || alwaysExpandPanel"
        (remove)="onFileRemove($event)"
        [@growInAnimation]="{ value: 'enter', params: { endHeight: '48px', origin: 'center center' } }"
        [@growOutAnimation]="{ value: 'leave', params: { origin: 'center center' } }"
    >
        <!-- metadata of each file -->
        <esp-deposit-file-metadata
            [displayAccessRights]="displayAccessRights"
            [displayLicense]="displayLicense"
            [displayContentType]="displayContentType"
            [displayDescription]="displayDescription"
            [file]="file"
            [index]="index"
        >
        </esp-deposit-file-metadata>
    </exl-file-list-item>
</exl-file-list>

<!-- LIST OF URLs-->
<exl-file-list
    *ngIf="hasLinks()"
    listTitle="{{ 'research.linktable.title' | customTranslate }}"
    [expandable]="editableFiles"
    [expandAllLabel]="'research.aria.deposit.files.and.links.list.expand.all' | translate"
    [collapseAllLabel]="'research.aria.deposit.files.and.links.list.collapse.all' | translate"
    (edit)="onLinkEdit($event)"
    (expandAll)="allLinksEditable = !allLinksEditable"
    (removeAll)="onRemoveAllLinks()"
>
    <!-- URL items -->
    <exl-file-list-item
        *ngFor="let link of depositFormDataService.mainForm.value.links; trackBy: trackByLink; let index = index"
        [item]="link"
        [index]="index"
        [expandable]="editableFiles"
        [allPanelsExpanded]="allLinksEditable || alwaysExpandPanel"
        (remove)="onLinkRemove($event)"
        (edit)="onLinkEdit($event, index)"
        [@growInAnimation]="{ value: 'enter', params: { endHeight: '48px', origin: 'center center' } }"
        [@growOutAnimation]="{ value: 'leave', params: { origin: 'center center' } }"
    >
        <ng-container slot="file-list-prefix" *ngIf="isAutoPopulated(link.url)">
            <exl-badge-auto-populated
                tooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
                color="primary"
            >
            </exl-badge-auto-populated>
        </ng-container>

        <!-- metadata of each URL -->
        <esp-deposit-link-metadata
            [displayLicense]="displayLicense"
            [displayContentType]="displayContentType"
            [displayDescription]="displayDescription"
            [index]="index"
        >
        </esp-deposit-link-metadata>
    </exl-file-list-item>
    <div slot-file-list-footer>
        <button mat-button class="has-icon" (click)="onAddLink()" color="primary" type="button">
            <mat-icon svgIcon="plus"></mat-icon>
            <span>{{ 'research.linktable.another' | customTranslate }}</span>
        </button>
    </div>
</exl-file-list>
