import {Component, OnInit, ChangeDetectionStrategy, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {SortUtils} from '../../../shared/utils/sort.utils';

@Component({
    selector: 'exl-language-select-dialog',
    templateUrl: './language-select-dialog.component.html',
    styleUrls: ['./language-select-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectDialogComponent implements OnInit {

    languages;
    selectedLanguage;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private translate: TranslateService) {
        this.languages = this.data.languages;
        this.selectedLanguage = this.data.selectedLanguage;
    }

    ngOnInit() {
        this.sortLanguages();
    }

    sortLanguages(){
        this.translate.get(this.languages).subscribe((res: Object) => {
            const codeDescriptionEntries = Object.entries(res);
            codeDescriptionEntries.sort(SortUtils.labelSort);
            this.languages = codeDescriptionEntries.map((lang) => {
                return lang[0];
            });
        });
    }

}
