<div *ngIf="!advSearchStartOpen" class="inputs-wrapper">
  <mat-form-field [appearance]="inputAppearance" [color]="color" [floatLabel]="floatLabel">
    <div #beforeInput class="before-input-container" matPrefix>
      <!-- Slot to project selectable options 'before' -->
      <ng-content select="mulo-searchbar-options[placement='before']"></ng-content>
    </div>

    <mat-label *ngIf="label">{{ label }}</mat-label>

    <input
      (keyup.enter)="submitSearch($event)"
      [attr.tabIndex]="0"
      [formControl]="inputControl"
      [matAutocomplete]="matAutocomplete ? matAutocomplete : auto"
      [placeholder]="placeholder"
      [attr.aria-label]="label"
      id="searchInput-#{{ id }}"
      matInput
    />
    <button
      (click)="clearInput()"
      *ngIf="clearInputButton && inputControl.value"
      [attr.aria-label]="clearInputButtonLabel"
      class="clear-input-btn"
      mat-icon-button
      matSuffix
    >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
    <div class="after-input-container" matSuffix>
      <ng-content select="mulo-searchbar-options[placement='after']"></ng-content>
      <button
        #submitButton
        (click)="submitSearch($event)"
        [class.is-hidden]="!searchButton"
        [class.is-large]="size === 'large'"
        [color]="color"
        [disabled]="!searchButton"
        [tabIndex]="hasOptionsAfter ? '-1' : '0'"
        aria-label="perform search"
        class="search-button is-square"
        mat-icon-button
      >
        <mat-icon muloSvgViewbox svgIcon="magnify"></mat-icon>
      </button>
    </div>
  </mat-form-field>

  <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>
</div>
<ng-container *ngIf="advSearchRoot && media.is('gt-sm')">
  <button
    (click)="toggleAdvSearchOpen(true, advSearch)"
    *ngIf="!advSearchStartOpen"
    [color]="color"
    class="adv-search-btn"
    mat-button
  >
    {{ advSearchLabel }}
  </button>
  <ng-template #advSearch>
    <ng-container *ngIf="advSearchRoot && media.is('gt-sm')">
      <div @opacityInAnimation @opacityOutAnimation class="mulo-adv-search-backdrop"></div>
      <div
        @opacityInAnimation
        @opacityOutAnimation
        class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"
      ></div>
      <div class="mulo-adv-search-wrap">
        <mat-card
          @heightInAnimation
          @heightOutAnimation
          [@.disabled]="advSearchDisableAnim"
          appearance="outlined"
          class="mulo-adv-search-card"
        >
          <button
            (click)="toggleAdvSearchOpen(false)"
            [attr.aria-label]="advSearchCloseAriaLabel"
            class="mulo-adv-search-close-btn"
            mat-icon-button
          >
            <mat-icon muloSvgViewbox svgIcon="close"></mat-icon>
          </button>
          <ng-content></ng-content>
          <!--         <mat-card-actions align="end">
            <button mat-flat-button color="primary" (click)="submitSearch($event)">
              <span class="margin-right-xs">{{advSearchSearchLabel}}</span>
              <mat-icon svgIcon="magnify"></mat-icon>
            </button>
          </mat-card-actions> -->
        </mat-card>
      </div>
    </ng-container>
  </ng-template>
</ng-container>

<mat-autocomplete #auto="matAutocomplete" class="searchbar-autocomplete for-{{ size }}-size">
  <ng-container *ngIf="inputControl.value as val">
    <mat-option
      (onSelectionChange)="submitSearch($event)"
      *ngFor="let scope of assessedScopes; let i = index"
      [value]="{ query: val, scope: scope }"
    >
      <ng-container *ngIf="autocompleteTemplate('scopes') as t; else scopeTemplate">
        <ng-container
          [ngTemplateOutletContext]="{ $implicit: scope, index: i, val: val }"
          [ngTemplateOutlet]="t.templateRef"
        >
        </ng-container>
      </ng-container>

      <ng-template #scopeTemplate>
        <mat-icon muloSvgViewbox svgIcon="magnify"></mat-icon>
        <span>{{ val }}</span>
        <span class="semibold">{{ autocompleteRelationWord }}&nbsp;</span>
        <span class="semibold">{{ scope }}</span>
      </ng-template>
    </mat-option>
  </ng-container>

  <mat-option
    (onSelectionChange)="submitSearch($event)"
    *ngFor="let suggestion of autocompleteSuggestions; let i = index"
    [value]="{ suggestion: suggestion }"
  >
    <ng-container *ngIf="autocompleteTemplate('suggestions') as t; else suggestionTemplate">
      <ng-container *ngTemplateOutlet="t.templateRef; context: { value: suggestion }"></ng-container>
    </ng-container>

    <ng-template #suggestionTemplate>
      <span [innerHTML]="suggestion | muloHighlightText: inputControl.value"></span>
    </ng-template>
  </mat-option>

  <ng-container *ngIf="inputControl.value as val">
    <mat-option
      (onSelectionChange)="submitSearch($event)"
      *ngFor="let result of autocompleteResults; let i = index"
      [value]="{ result: result }"
    >
      <ng-container *ngIf="autocompleteTemplate('results') as t; else resultTemplate">
        <ng-container *ngTemplateOutlet="t.templateRef; context: { value: result }"></ng-container>
      </ng-container>

      <ng-template #resultTemplate>
        <span [innerHTML]="result | muloHighlightText: val"></span>
      </ng-template>
    </mat-option>
  </ng-container>
</mat-autocomplete>
