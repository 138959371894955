export function preventOnKeyboardOrigin(
    target: Object,
    propertyName: string,
    propertyDesciptor: PropertyDescriptor): PropertyDescriptor {

    const method = propertyDesciptor.value;

    propertyDesciptor.value = function (...args: any[]) {
        if (document.documentElement.getAttribute('data-whatinput') !== 'keyboard') {
          method.apply(this, args);
        }
    };
    return propertyDesciptor;
}

export function preventOnOrigin(origin: 'keyboard' | 'mouse' | 'program' = 'keyboard') {
  return function preventOnMouseOrigin(
    target: Object,
    propertyName: string,
    propertyDesciptor: PropertyDescriptor): PropertyDescriptor {
  
    const method = propertyDesciptor.value;
  
    propertyDesciptor.value = function (...args: any[]) {
        if (document.documentElement.getAttribute('data-whatinput') !== origin) {
          method.apply(this, args);
        }
    };
    return propertyDesciptor;
  }
}
