import { NgModule } from '@angular/core';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from '../../shared/shared.module';
import { InternalOrganizationAutocompleteInputComponent } from './internal-organization-autocomplete-input.component';




@NgModule({
    imports: [
        SharedModule,
        NgxMatSelectSearchModule,
    ],
    declarations: [
        InternalOrganizationAutocompleteInputComponent
    ],
    exports: [
        InternalOrganizationAutocompleteInputComponent
    ]
})
export class InternalOrganizationAutocompleteInputModule { }