export class File {
    data: any;
    fullName: string;
    baseName: string;
    extension: string;
    sizeFloat: string;
    profile: string;
    uploadProgress: number;
    url: any;
    index: number;
    type: string;
    access: string;
    license: string;
    description: string;
    mid: number;
    accessRightsReason: string;
    accessRightsFixedDate: Date;
    accessRightsFixedYear: string;
    accessRightsFixedMonth: string;
    accessRightsFixedDay: string;
    accessRightsPredefinedDate: string;

    constructor(){}

    getFullName(){
        return this.fullName;
    }

    setFullName(fullName){
        this.fullName = fullName;
    }

    getBaseName(){
        return this.baseName;
    }

    setBaseName(baseName){
        this.baseName = baseName;
    }

    getExtension(){
        return this.extension;
    }

    setExtension(extension){
        this.extension = extension;
    }

    getSizeFloat(){
        return this.sizeFloat;
    }

    setSizeFloat(sizeFloat){
        this.sizeFloat = sizeFloat;
    }

    getProfile(){
        return this.profile;
    }

    setProfile(profile){
        this.profile = profile;
    }

    getUploadProgress(){
        return this.uploadProgress;
    }

    setUploadProgress(uploadProgress){
        this.uploadProgress = uploadProgress;
    }

    getIndex(){
        return this.index;
    }

    setIndex(index){
        this.index = index;
    }

    getType(){
        return this.type;
    }

    setType(type){
        this.type = type;
    }

    getAccess(){
        return this.access;
    }

    setAccess(access){
        this.access = access;
    }

    getLicense(){
        return this.license;
    }

    setLicense(license){
        this.license = license;
    }

    getDescription(){
        return this.description;
    }

    setDescription(description){
        this.description = description;
    }

    getMid(){
        return this.mid;
    }

    setMid(mid){
        this.mid = mid;
    }
     getAccessRightsFixedDate(){
        return this.accessRightsFixedDate;
     }

     setAccessRightsFixedDate(accessRightsFixedDate){
        this.accessRightsFixedDate = accessRightsFixedDate;
     }

    getAccessRightsFixedYear(){
        return this.accessRightsFixedYear;
    }

    setAccessRightsFixedYear(accessRightsFixedYear){
        this.accessRightsFixedYear = accessRightsFixedYear;
    }

    getAccessRightsFixedMonth(){
        return this.accessRightsFixedMonth;
    }

    setAccessRightsFixedMonth(accessRightsFixedMonth){
        this.accessRightsFixedMonth = accessRightsFixedMonth;
    }

    getAccessRightsFixedDay(){
        return this.accessRightsFixedDay;
    }

    setAccessRightsFixedDay(accessRightsFixedDay){
        this.accessRightsFixedDay = accessRightsFixedDay;
    }

    getAccessRightsReason(){
        return this.accessRightsReason;
    }

    setAccessRightsReason(accessRightsReason){
        this.accessRightsReason = accessRightsReason;
    }


    getAccessRightsPredefinedDate(){
        return this.accessRightsPredefinedDate;
    }

    setAccessRightsPredefinedDate(accessRightsPredefinedDate){
        this.accessRightsPredefinedDate = accessRightsPredefinedDate;
    }

}

export interface AccessRight {
    id: string;
    name: string;
}

