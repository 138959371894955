import { Component, Input } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatDialog, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatRadioChange, MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { NgFor, UpperCasePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    selector: 'mulo-language-select',
    templateUrl: './language-select.component.html',
    styleUrls: ['./language-select.component.scss'],
    standalone: true,
    imports: [
        MatRadioGroup,
        FormsModule,
        NgFor,
        MatRadioButton,
        UpperCasePipe,
        TranslateModule,
    ],
})
export class LanguageSelectComponent {
  languages: Array<string>;
  selectedLanguage: string;

  constructor(private translate: TranslateService, private dialog: MatDialog) {
    this.languages = translate.getLangs();
    this.selectedLanguage = translate.currentLang;
  }

  onLanguageChange(language: MatRadioChange) {
    this.translate.use(language.value);
    this.dialog.closeAll();
  }
}

@Component({
    selector: 'mulo-language-select-dialog',
    templateUrl: './language-select-dialog.component.html',
    styleUrls: ['./language-select-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        LanguageSelectComponent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
    ],
})
export class LanguageSelectDialogComponent {
  @Input() langListLabel = 'Display language';
  @Input() cancelLabel = 'Cancel';

  constructor() {}
}
