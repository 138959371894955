import { Component, OnDestroy, OnInit } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'esp-deposit-technical-information',
  templateUrl: './deposit-technical-information.component.html',
  styleUrls: ['./deposit-technical-information.component.scss'],
})
export class DepositTechnicalInformationComponent implements OnInit, OnDestroy {
  private techInfoDestroy = new Subject<void>();

  constructor(public depositFormDataService: DepositFormDataService) { }

  ngOnInit() {
    this.onChanges();
  }

  onChanges(): void {
    this.depositFormDataService.technicalInformation.valueChanges
      .pipe(takeUntil(this.techInfoDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.TECHNICAL_INFORMATION
          );
        }
      });
  }

  get isAutoPopulated() {
    return this.depositFormDataService.isKeyAutoPopulated(
      AutoPopulateKeys.TECHNICAL_INFORMATION
    );
  }

  ngOnDestroy() {
    if (!this.techInfoDestroy.isStopped) {
      this.techInfoDestroy.next();
      //unsubscribe from the subject itself
      this.techInfoDestroy.unsubscribe();
    }
  }
}
