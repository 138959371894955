import { Directive, HostBinding, Input, OnInit } from '@angular/core';

@Directive({ selector: '[muloElementAsHeading]', standalone: true })
export class ElementAsHeadingDirective implements OnInit {
  @HostBinding('role') role = 'heading';
  @HostBinding('attr.aria-level') @Input('muloElementAsHeading') headingLevel;

  ngOnInit() {
    if (this.headingLevel == null || this.headingLevel <= 0) {
      this.headingLevel = undefined;
      this.role = undefined;
    }
  }
}
