<h2 mat-dialog-title>{{ file.name }}</h2>
<mat-dialog-content>
  <!-- <img [src]="file.data" alt=""> -->
  <mulo-image-cropper
    [file]="file"
    (imageOutput)="onImageCropped($event)"
    (loadedOutput)="onImageLoaded()"
  ></mulo-image-cropper>
</mat-dialog-content>
<div mat-dialog-actions>
  <span class="action-spacer"></span>
  <button mat-button class="is-large" *ngIf="data.actions.confirm" color="accent" [mat-dialog-close]="preparedImage">
    {{ data.actions.confirm }}
  </button>
  <button mat-button *ngIf="data.actions.cancel" [mat-dialog-close]="'cancel'">
    {{ data.actions.cancel }}
  </button>
</div>
