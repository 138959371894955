import { Component, AfterContentInit ,Inject, HostListener } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'exl-success-snackbar',
    templateUrl: './success-snackbar.component.html',
    styleUrls: ['./success-snackbar.component.scss'],
    host: {'class': 'exl-snackbar'}
})
export class SuccessSnackbarComponent implements AfterContentInit {

    showAnimation = false;

    @HostListener('click', ['$event'])
    clickInside(event) {
        event.stopPropagation();
    }
  
    @HostListener('document:click')
    clickOutside() {
        this.close();
    }

    constructor(private snackBarRef: MatSnackBarRef<SuccessSnackbarComponent>,
                @Inject(MAT_SNACK_BAR_DATA) public data: any) {}


    ngAfterContentInit(){
        setTimeout(() => {
            this.showAnimation = true;
        }, 0);
    }

    close(){
        this.snackBarRef.dismiss();
    }

}
