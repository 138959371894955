<div class="sender">
  <span class="upper">{{ senderTitle | translate }}</span>
</div>
<div class="wrapper" [ngClass]="'align-' + align">
  <mulo-chat-bubble
    [content]="content"
    [anchor]="align == 'left' ? 'top left' : 'top right'"
    [color]="align == 'left' ? 'accent' : 'primary'"
    [time]="time"
    [align]="align"
    [reasonPrefix]="reasonPrefix"
  >
  </mulo-chat-bubble>
</div>
