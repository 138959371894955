import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {UrlUtils} from "../shared/utils/url.utils";
import {RestApiBase} from "../shared/configurations/rest-api-base.config";

@Injectable({
    providedIn: 'root',
})
export class IndexPageService {

    institutionCode = UrlUtils.getParam('institution');

    constructor(private http: HttpClient) {}

    getSEOFileIndexByName(indexKey){
        let indexName = 'assets_index.json';
        if (indexKey === 'researchersIndex'){
            indexName = 'researchers_index.json';
        }
        let params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('fileName', indexName);
        return this.http.get(RestApiBase.GET_SEO_FILE_INDEX_BY_NAME, {params});
    }

}
