<div>
    <div>
        <mat-form-field
            [muloInputExpander]="depositFormDataService.eventName.value"
            class="is-comfortably-readable"
            matTooltip="{{ 'research.event.name.tooltip' | customTranslate }}"
        >
            <input
                matInput
                exlAutomationClass="event-name"
                [formControl]="depositFormDataService.eventName"
            />
            <mat-label>{{ 'research.event.name' | customTranslate }}</mat-label>
        </mat-form-field>
        <mat-form-field class="is-comfortably-readable">
            <input
                matInput
                exlAutomationClass="event-type"
                [formControl]="depositFormDataService.eventType"
            />
            <mat-label>{{ 'research.event.type' | customTranslate }}</mat-label>
        </mat-form-field>
        <mat-form-field
            [muloInputExpander]="depositFormDataService.eventLocation.value"
            class="is-comfortably-readable"
        >
            <input
                matInput
                exlAutomationClass="event-location"
                [formControl]="depositFormDataService.eventLocation"
            />
            <mat-label>{{
                'research.event.location' | customTranslate
            }}</mat-label>
        </mat-form-field>
    </div>

    <div>
        <esp-deposit-dates
            [range]="true"
            [dateHint]="true"
            [fromDate]="depositFormDataService.eventStartDate"
            [toDate]="depositFormDataService.eventEndDate"
        >
        </esp-deposit-dates>
    </div>
</div>
