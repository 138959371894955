<ng-content select="[slot='pre-brief']"></ng-content>
<span class="brief-body" exlDescriptionA11y>
  <span class="title-and-actions">
    <span class="title-wrap">
      <!-- pre-title (type + status) -->
      <p class="pre-title">
        <span *ngIf="type" class="type small-caps semibold"
          >{{ type }} <ng-container *ngIf="postType"> {{ postType }}</ng-container></span
        >
        <ng-container *ngIf="status">
          <span class="status">{{ status }}</span>
          <span *ngIf="created || modified" class="modified italic">{{ dateLabel }} {{ modified | amTimeAgo }}</span>
        </ng-container>
        <ng-content select="[slot='pre-title']"></ng-content>
      </p>

      <!-- thumbnail/s -->

      <mulo-thumbnail
        (thumbHeight)="onThumbLoad($event)"
        *ngIf="thumbs"
        [thumbs]="thumbs"
        [width]="thumbsWidth"
      ></mulo-thumbnail>

      <!-- title -->
      <p
        *ngIf="title"
        [id]="id + '_title'"
        [class.is-link]="titleAsLink"
        class="title hoverable-link-parent"
        exlDescription
      >
        <a
          *ngIf="titleUrl"
          [attr.aria-label]="labelOpenInNewWindow"
          [class.is-active]="mouseOver"
          [href]="titleUrl"
          [innerHtml]="title | htmlSanitize"
          [muloElementAsHeading]="titleHeaderLevel"
          dir="auto"
          target="_blank"
        ></a>
        <a
          *ngIf="routerLinkUrl"
          [class.is-active]="mouseOver"
          [innerHtml]="title | htmlSanitize"
          [muloElementAsHeading]="titleHeaderLevel"
          [queryParams]="queryParams"
          [routerLink]="routerLinkUrl"
          dir="auto"
        >
        </a>
        <a
          (click)="onTitleClick($event)"
          *ngIf="titleClickable && !titleUrl && !routerLinkUrl"
          [class.is-active]="mouseOver"
          [innerHtml]="title | htmlSanitize"
          [muloElementAsHeading]="titleHeaderLevel"
          dir="auto"
          href
        >
        </a>
        <span
          *ngIf="!titleClickable && !titleUrl && !routerLinkUrl"
          [innerHtml]="title | htmlSanitize"
          [muloElementAsHeading]="titleHeaderLevel"
          class="title-inner"
          dir="auto"
        ></span>
      </p>
      <ng-content select="[slot='post-title']"></ng-content>
    </span>
    <!-- item actions, menu and stats -->
    <ng-content select="[slot='actions']"></ng-content>
  </span>
  <ng-content></ng-content>
</span>
