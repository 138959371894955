import {
    Component,
    Input,
    Output,
    EventEmitter,
    Optional,
    SkipSelf,
    ChangeDetectorRef, HostListener
} from "@angular/core";
import { trigger, style, transition, animate } from '@angular/animations';
import { FilePreviewDialogComponent } from '../../../dialogs/file-preview-dialog/file-preview-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { File } from '../../../../shared/interfaces/file.model';
import { CdkAccordion } from '@angular/cdk/accordion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { ExpansionPanelComponent } from '@exl-ng/mulo-common';

@Component({
    selector: 'exl-file-list-item',
    templateUrl: './file-list-item.component.html',
    styleUrls: ['./file-list-item.component.scss'],
    host: {
        class: 'exl-file-list-item',
    },
    animations: [
        trigger('scaleInOut', [
            transition(':enter', [
                style({
                    transform: 'scale(.8)',
                    opacity: 0,
                }),
                animate(
                    '600ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                    style({
                        transform: 'scale(1)',
                        opacity: 1,
                    })
                ),
            ]),
            transition(':leave', [
                style({
                    transform: 'scale(1)',
                    height: '*',
                    opacity: 1,
                }),
                animate(
                    '300ms cubic-bezier(0.895, 0.03, 0.685, 0.22)',
                    style({
                        transform: 'scale(.8)',
                        height: 0,
                        opacity: 0,
                    })
                ),
            ]),
        ]),
    ],
})
export class FileListItemComponent extends ExpansionPanelComponent {
    @Input() item: File;
    @Input() index: number;
    @Input() expandable = true;
    @Input() minimal = false;
    @Input() allPanelsExpanded = false;
    @Input() progress: number;
    @Output() remove: EventEmitter<any> = new EventEmitter();
    @Output() edit: EventEmitter<any> = new EventEmitter();

    panelOpenState: boolean;

    constructor(
        public filePreviewDialog: MatDialog,
        @Optional() @SkipSelf() accordion: CdkAccordion,
        _changeDetectorRef: ChangeDetectorRef,
        _uniqueSelectionDispatcher: UniqueSelectionDispatcher
    ) {
        super(accordion, _changeDetectorRef, _uniqueSelectionDispatcher);
    }

    onRemove(event, item, index) {
        event.preventDefault();
        event.stopPropagation();
        const removedItem = { item: item, index: index };
        this.remove.emit(removedItem);
    }

    onEdit(event, item) {
        event.preventDefault();
        event.stopPropagation();
        this.edit.emit(item);
    }
    // @HostListener('click', ['$event']) onClick($event: Event) {
    //     console.group("FileListItemComponent.onClick");
    //     $event.stopPropagation();
    //     console.groupEnd();
    // }
    // @HostListener('keydown', ['$event']) onKeydown(event) {
    //     console.group("FileListItemComponent.onKeydown");
    //     if (event.code === "Enter" || event.code === "Space") {
    //         event.stopPropagation();
    //     }
    //     console.groupEnd();
    // }

    onPreviewClick(event, file) {
        event.preventDefault();
        event.stopPropagation();
        this.filePreviewDialog.open(FilePreviewDialogComponent, {
            data: {
                file: file,
            },
        });
    }

    preventEditMetadataOpen(event) {
        event.preventDefault();
        event.stopPropagation();
    }
}
