import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JwtUtilService } from '../../core/jwt-util.service';
import { RestApiBase } from '../../shared/configurations/rest-api-base.config';
import { UrlUtils } from '../../shared/utils/url.utils';
import { ResearcherAsset } from '../../shared/interfaces/researcher-asset.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterService } from '../../core/router.service';
import { ProfileService } from '../profile.service';
import { ResearcherOutputListFacet } from '../../shared/interfaces/researcher-output-list-facet.interface';
import { Profile } from '../../shared/configurations/profile.constant';
import { ResearcherOutputListFacetCategoryWithSubCategories } from '../../shared/interfaces/researcher-output-list-facet-category-with-sub-categories.interface';
import { MuloNavLink } from '@exl-ng/mulo-common';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ProfileOutputService {
    institutionCode: string;

    facets: ResearcherOutputListFacetCategoryWithSubCategories[] = [];
    flattenFacetsList: ResearcherOutputListFacet[] = [];
    facetsForMobile: MuloNavLink[] = [];

    chosenFacet;

    selectedIndex = 0;
    selectedIndexForMobile = 0;
    loadingDraft = false;
    loadingAsset = false;
    isOutputEmpty = false;

    fullMMSList = [];
    highlights = [];

    UPDATE_DATA_TIMEOUT = 2200;

    itemChange$ = new BehaviorSubject<number>(0);
    assetLoaded$ = new Subject<boolean>();
    refreshData$ = new Subject<boolean>();
    assetDeleted$ = new Subject<boolean>();

    EMPTY_ARRAY = [];

    constructor(
        private http: HttpClient,
        private jwtUtilService: JwtUtilService,
        private router: Router,
        private route: ActivatedRoute,
        private routerService: RouterService,
        private profileService: ProfileService,
        private translate: TranslateService
    ) {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    getAssetSummaryList(isPublicPage = false, assetFilter = '', offset) {
        let userID;
        let params = new HttpParams().set('institution', this.institutionCode);
        if (assetFilter) {
            params = params.set('assetFilter', assetFilter);
        }
        params = params.set('offset', offset);
        params = params.set('bulkSize', Profile.BULK_SIZE.toString());
        if (isPublicPage) {
            userID = this.profileService.userID;
            params = params.set('urlIdentifier', userID);
            return this.http
                .get(RestApiBase.ASSET_SUMMARY_LIST_URL_IDENTIFIER, { params })
                .pipe(take(1));
        }
        userID = this.jwtUtilService.getUserID();
        params = params
            .set('userID', userID)
            .set('isPublicPage', isPublicPage.toString());
        return this.http
            .get(RestApiBase.ASSET_SUMMARY_LIST, { params })
            .pipe(take(1));
    }

    getOutputListFacets(isPublicPage = false) {
        let userID;
        let params = new HttpParams().set('institution', this.institutionCode);
        if (isPublicPage) {
            userID = this.profileService.userID;
            params = params.set('urlIdentifier', userID);
            return this.http
                .get(RestApiBase.OUTPUT_LIST_FACETS_URL_IDENTIFIER, { params })
                .pipe(take(1));
        }
        userID = this.jwtUtilService.getUserID();
        params = params
            .set('userID', userID)
            .set('isPublicPage', isPublicPage.toString());
        return this.http
            .get(RestApiBase.OUTPUT_LIST_FACETS, { params })
            .pipe(take(1));
    }

    getFullMMSList(isPublicPage = false) {
        let userID;
        let params = new HttpParams().set('institution', this.institutionCode);
        if (isPublicPage) {
            userID = this.profileService.userID;
            params = params.set('urlIdentifier', userID);
            return this.http.get(
                RestApiBase.GET_FULL_MMS_IDS_BY_URL_IDENTIFIER,
                { params }
            );
        }
        userID = this.jwtUtilService.getUserID();
        params = params.set('userID', userID);
        return this.http.get(RestApiBase.GET_FULL_MMS_IDS_BY_USER_ID, {
            params,
        });
    }

    getHighlightsFullList() {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('userID', this.jwtUtilService.getUserID());
        return this.http.get(RestApiBase.GET_HIGHLIGHTS_FULL_LIST, { params });
    }

    addHighlight(highlight) {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.put(
            RestApiBase.ADD_HIGHLIGHT,
            { highlight, userID: this.jwtUtilService.getUserID() },
            { params }
        );
    }

    removeHighlight(highlight) {
        const params = new HttpParams()
            .set('highlight', highlight)
            .set('userID', this.jwtUtilService.getUserID())
            .set('institution', this.institutionCode);
        return this.http.delete(RestApiBase.REMOVE_HIGHLIGHT, { params });
    }

    getAssetByMmsId(item) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        const mmsID = item.mmsId.toString();
        return this.http.get(RestApiBase.ASSET_BY_MMS_ID + '/' + mmsID, {
            params,
        });
    }

    saveFacets(data) {
        this.isOutputEmpty = false;
        this.facets =
            data as ResearcherOutputListFacetCategoryWithSubCategories[];
        this.flattenFacetsList = [];
        for (const facet of this.facets) {
            this.getFlattenFacetList(facet);
        }
        this.parseFacetsForMobile();
        this.refreshData$.next(false);
    }

    getFlattenFacetList(facet) {
        this.flattenFacetsList.push(facet.category);
        for (const sub of facet.subCategories) {
            this.getFlattenFacetList(sub);
        }
    }

    parseFacetsForMobile() {
        if (this.facets) {
            this.facetsForMobile = [];
            this.facets.forEach((facet) =>
                this.facetsForMobile.push(
                    this.parseFacetForMobileRecursion(facet, '', 0)
                )
            );
        }
    }

    parseFacetForMobileRecursion(
        facet: ResearcherOutputListFacetCategoryWithSubCategories,
        parentCode: string,
        level
    ) {
        const muloFacetResult = {} as MuloNavLink;
        muloFacetResult.name =
            facet.category.facet === 'all'
                ? 'all_assets'
                : facet.category.facet;
        this.translate
            .get(this.getOutputTitle(facet.category))
            .subscribe((res) => {
                muloFacetResult.label = res;
            });
        muloFacetResult.length = parseInt(facet.category.count);
        muloFacetResult.level = level;
        muloFacetResult.id = this.getFacetNames().indexOf(facet.category.facet);
        if (parentCode) {
            muloFacetResult.parent = parentCode;
        }

        if (this.hasChildren(facet)) {
            muloFacetResult.children = [];
            facet.subCategories.forEach((subCategory) =>
                muloFacetResult.children.push(
                    this.parseFacetForMobileRecursion(
                        subCategory,
                        muloFacetResult.name,
                        level + 1
                    )
                )
            );
        }

        return muloFacetResult;
    }

    getOutputTitle(link) {
        return link.facet
            ? this.getOutputTitleByFacetName(link.facet)
            : this.getOutputTitleByFacetName(link);
    }

    navigateToFirstResourceTypeInDisplayForProfile() {
        const outputGroup = this.firstElementToDisplay();
        if (outputGroup) {
            this.router.navigate(['./profile/output/' + outputGroup], {
                relativeTo: this.route,
                queryParams: { institution: this.institutionCode },
            });
        }
    }

    cleanFacets() {
        this.facets.splice(0, this.facets.length);
        this.refreshData$.next(true);
    }

    firstElementToDisplay() {
        if (this.facets.length > 0) {
            return this.facets[0].category.facet;
        }
        return '';
    }

    getFacetNames() {
        if (this.flattenFacetsList && this.flattenFacetsList.length > 0) {
            return this.flattenFacetsList.map((facet) => {
                return facet.facet;
            });
        }
        return this.EMPTY_ARRAY;
    }

    deleteAsset(item: ResearcherAsset) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        const mmsID = item.mmsId.toString();
        return this.http.delete(RestApiBase.DELETE_ASSET + '/' + mmsID, {
            params,
        });
    }

    //big overhead to check everything - will check only category and not sub
    deleteAssetFromDisplay(facetName) {
        let i = this.facets.length;
        while (i--) {
            if (this.facets[i].category.facet === facetName) {
                const count = parseInt(this.facets[i].category.count);
                this.updateFacetCount(i, count);
                if (count === 1) {
                    this.removeFacetCategoryAfterDelete(i);
                    this.selectedIndex = 0;
                    if (this.facets.length === 0) {
                        this.isOutputEmpty = true;
                        //TODO change when we have more tabs- navigate to another in public profile only
                    } else {
                        this.navigateToFirstResourceTypeInDisplayForProfile();
                    }
                }
            }
        }
    }

    updateFacetCount(index, count) {
        this.facets[index].category.count = (count - 1).toString();
        this.flattenFacetsList[index].count = (count - 1).toString();
        this.refreshData$.next(true);
    }

    //The delete is only available from draft and in process, therefore we will check only them because it is a

    removeFacetCategoryAfterDelete(index) {
        this.facets.splice(index, 1);
        this.flattenFacetsList.splice(index, 1);
        this.refreshData$.next(true);
    }

    isPublicPage() {
        return this.isProfileRoute() && this.profileService.isPublicPage;
    }

    isProfileRoute() {
        return this.routerService.isProfileRoute();
    }

    unhideAssetOnPublicProfile(mmsId: string) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        return this.http.put(
            RestApiBase.DISPLAY_ASSET_ON_PUBLIC_PROFILE,
            { mmsId, userID: this.jwtUtilService.getUserID() },
            { params }
        );
    }

    hideAssetFromPublicProfile(mmsId: string) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        return this.http.put(
            RestApiBase.HIDE_ASSET_ON_PUBLIC_PROFILE,
            { mmsId, userID: this.jwtUtilService.getUserID() },
            { params }
        );
    }

    private hasChildren(tab) {
        return tab.subCategories.length > 0;
    }

    private getOutputTitleByFacetName(facetName: string) {
        if (
            facetName === 'drafts' ||
            facetName === 'inProcess' ||
            facetName === 'all' ||
            facetName === 'highlights'
        ) {
            return 'research.output.list.' + facetName;
        }
        return 'portal.' + facetName;
    }
}
