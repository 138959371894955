import {Date} from './date.model';

export class DateRange {
    startDate = new Date();
    endDate = new Date();

    constructor(){}


    getStartDate(){
        return this.startDate;
    }

    setStartDate(startDate){
        this.startDate = startDate;
    }

    getEndDate(){
        return this.endDate;
    }

    setEndDate(endDate){
        this.endDate = endDate;
    }

}
