<div style="position: relative">
    <div>
        <div class="field-wrap">
            <mat-form-field [class]="formFieldClass">
                <mat-label>{{ 'research.assetype.overline1' | translate }}</mat-label>
                <mat-select [formControl]="selectedTypeCtrl" panelClass="is-tall">
                    <mat-option>
                        <ngx-mat-select-search
                            [ariaLabel]="'research.aria.deposoit.asset.types.lookup' | translate"
                            [formControl]="typeFilterCtrl"
                            [noEntriesFoundLabel]="'research.assetype.lookup.no.match' | translate"
                            [placeholderLabel]="'research.assetype.lookup.placeholder' | translate"
                        >
                            <mat-icon ngxMatSelectSearchClear svgIcon="close"></mat-icon>
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-optgroup
                        *ngFor="let group of filteredTypeGroups | async"
                        [ngClass]="{ 'deposit-type-top-level': group.icon }"
                        class="is-sticky"
                    >
                        <mat-icon *ngIf="group.icon" [svgIcon]="group.icon"></mat-icon>
                        <label>
                            <span
                                [muloElementAsHeading]="4"
                                [innerHTML]="group.name | highlight: typeFilterCtrl.value"
                            ></span>
                        </label>
                        <mat-option *ngFor="let type of group.types" [value]="type.code">
                            <span [innerHTML]="type.name | highlight: typeFilterCtrl.value" class="semibold"></span>
                        </mat-option>
                    </mat-optgroup>
                </mat-select>
                <mat-error [innerHtml]="'research.settings.field.required.error.message' | translate"></mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
