import { Injectable } from '@angular/core';

import { RestApiBase } from '../../../shared/configurations/rest-api-base.config';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class DepositLastStepService {

    summaryNotes = false;
    copyrightsNotes = false;

    constructor(private http: HttpClient) {}

    getConfigTermsOfUse(configFileName, intst) {
        const params = new HttpParams().set('institution', intst).set('termsOfUse', configFileName);
        return this.http.get(RestApiBase.TERMS_OF_USE, { params: params });
    }
}
