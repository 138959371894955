const flatten = (data, keepChildren) => {
  return data.reduce((r, { children, ...rest }) => {
    const data = keepChildren ? rest : { children, ...rest };
    r.push(data);
    if (children) r.push(...flatten(children, keepChildren));
    return r;
  }, []);
};

export function flattenArrayWithChildren(
  data: any[],
  keepChildren: boolean = false
) {
  return flatten(data, keepChildren);
}

export function getArrayDepth(array: any[], property: string = 'children') {
  if (!array) return;
  let depth: number[] = [];
  const recurse = (arr, level = 0) => {
    return arr.forEach((obj) => {
      depth.push(level);
      if (obj.children) recurse(obj.children, level + 1);
    });
  };
  recurse(array);
  return Math.max(...depth);
}

export function addObjectDepthLevel(array) {
  if (!array) return;
  const recurse = (arr, level = 0) => {
    return arr.forEach((obj) => {
      if (!obj.level) obj.level = level;
      return obj.children ? recurse(obj.children, level + 1) : obj;
    });
  };
  return recurse(array);
}

/**
 * Create a tree from flat list
 * Requires each object to have the following:
 *    'name' (unique),
 *    'parent' (optional, but matching the parent 'name' value)
 *    'children' array of same object - optionally pass a string as the children key, defaults to 'children
 */
export function createTreeFromFlatList(list, children: string = 'children') {
  let tempArray = Object.create(null);
  let tree = [];
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    tempArray[item.name] = { ...item, id: i, [children]: [] };
    item.parent
      ? tempArray[item.parent].children.push(tempArray[item.name])
      : tree.push(tempArray[item.name]);
  }
  tree.forEach((item) => {
    setPathInTree.bind([])(item);
  });
  return tree;
}

/**
 * Set paths of child nodes based on their hierarchy
 */
export function setPathInTree(o) {
  o.path = this.concat(o?.name);
  Array.isArray(o?.children) && o.children.forEach(setPathInTree, o.path);
}

export function createPathsForTree(path?, name: string = 'name', children: string = 'children') {
  path = path || [];
  return function (o) {
    o.path = path.concat(o[`${name}`]);
    if (Array.isArray(o[`${children}`])) {
      o[`${children}`].forEach(createPathsForTree(o.path));
    }
  }
}

/** Sort an array according to another sorting array (string[]) */
export function sortArrayByAnother(
  array,
  order: string[],
  key,
  dir?: 'asc' | 'des'
) {
  const up = dir === 'asc' ? 1 : -1;
  const down = dir === 'asc' ? -1 : 1;
  return array.sort((a, b) => {
    const A = a[key],
      B = b[key];
    return order.indexOf(A) > order.indexOf(B) ? up : down;
  });
}
