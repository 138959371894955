import { AbstractControl, UntypedFormControl, Validator, ValidatorFn } from '@angular/forms';

declare module '@angular/forms' {
    export interface AbstractControl {
        addValidators(validators: ValidatorFn[]): void;
        isRequired(): boolean;
    }
}

AbstractControl.prototype.addValidators = function (
    this: AbstractControl,
    validators: ValidatorFn[]
) {
    if (!validators || !validators.length) {
        return;
    }

    this.clearValidators();
    this.setValidators(
        this.validator ? [this.validator, ...validators] : validators
    );
};

AbstractControl.prototype.isRequired = function (
    this: AbstractControl
): boolean {
    const { validator } = this;
    if (validator) {
        const validation = validator(new UntypedFormControl());
        return !!(validation?.required);
    }
    return false;
};

