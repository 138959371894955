import { ExportTarget } from '../exporter.model';

export const AssetExportTargets: ExportTarget[] = [
  {
    value: 'email',
    name: 'Email',
    actions: [],
    icon: 'email-outline'
  },
  {
    value: 'bibtex',
    name: 'BibTeX',
    actions: [
      { label: 'Download .bib file', value: 'download' },
      { label: 'Email it', value: 'email' },
    ],
    preview: 'code',
  },
  {
    value: 'ris',
    name: 'RIS',
    actions: [{ label: 'Download .ris file', value: 'download' }],
    preview: 'code',
  },
];
