import { Component, OnInit, Input, Inject } from '@angular/core';
import { SharerLabels, ShareTarget } from '../share.model';
import { ShareService } from '../share.service';
import { WINDOW } from 'ngx-window-token';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { HtmlTrustPipe } from '../../../pipes/html-trust.pipe';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { MultipleEventsDirective } from '../../../directives/multiple-events.directive';
import { MatAnchor, MatIconButton } from '@angular/material/button';
import { CopierComponent } from '../../copier/copier.component';
import { NgIf, NgFor } from '@angular/common';

const defaultLabels: SharerLabels = {
  copy: 'Copy',
  copied: 'Copied!',
};
@Component({
    selector: 'mulo-sharer',
    templateUrl: './sharer.component.html',
    styleUrls: ['./sharer.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        CopierComponent,
        NgFor,
        MatAnchor,
        MultipleEventsDirective,
        MatIcon,
        SvgViewboxDirective,
        HtmlTrustPipe,
    ],
})
export class SharerComponent implements OnInit {
  /** Whether the asset share component is loaded in a dialog context */
  @Input() inDialog = false;
  /** static labels (for translation) */
  @Input() labels: SharerLabels;
  /** Array of targets to share to */
  @Input() shareTargets: ShareTarget[];
  /** The data to be sent to the share popup (url, title, text) */
  @Input() shareData: ShareData;
  /** open the share session as a popup or as a new tab */
  @Input() openAs: 'popup' | 'tab' = 'popup';
  /** Optional popup params */
  @Input()
  popupParams = `location=0,menubar=0,toolbar=0,personalbar=0,status=0,scrollbars=1,resizable=1`;
  /** the id name of the popup window. Used to ensure any new popup replaces the previously opened one, instead of opening multiple popup windows. */
  @Input() shareWindowId = 'social';

  constructor(
    private service: ShareService,
    @Inject(WINDOW) private _window: any
  ) {}

  ngOnInit(): void {
    this.labels = { ...defaultLabels, ...this.labels };
    // check if the browser handles the Web Share API
    const nativeCompatible = this._window?.navigator?.share;
    if (!nativeCompatible) {
      // filter out native share if not compatible
      this.shareTargets = this.shareTargets.filter((t) => t.key !== 'native');
    }

    this.shareTargets.forEach((t, i) => {
      const service = this.service.shareServices.find((s) => s.key === t.key);
      if (service) {
        this.shareTargets[i] = { ...service, ...t };
      }
      if (this.shareTargets[i].url) {
        const urlObj = this.service.urlParse(
          this.shareTargets[i].url,
          this.shareData
        );
        this.shareTargets[i].url = urlObj.href;
      }
    });
  }

  /** Calculate popup window size */
  popupSize() {
    const screen = this._window.screen;
    const sw = screen.availWidth || 1024;
    const sh = screen.availHeight || 700;
    const pw = Math.min(600, sw - 40);
    const ph = Math.min(600, sh - 40);
    const px = Math.floor((sw - pw) / 2);
    const py = Math.floor((sh - ph) / 2);

    return `width=${pw},height=${ph},left=${px},top=${py}`;
  }

  onLinkCopied() {
    this.service.onLinkCopied();
  }

  openPopupShare(url): void {
    const popup = this._window.open(
      url,
      this.shareWindowId,
      `${this.popupSize()}, ${this.popupParams}`
    );

    if (popup) popup.focus();
  }

  openNativeShare(event) {
    event.preventDefault();
    this._window.navigator.share(this.shareData);
  }
}

@Component({
    selector: 'mulo-share-dialog',
    templateUrl: './share-dialog.component.html',
    styleUrls: ['./share-dialog.component.scss'],
    standalone: true,
    imports: [
        MatIconButton,
        MatIcon,
        SvgViewboxDirective,
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
        SharerComponent,
    ],
})
export class ShareDialogComponent implements OnInit {
  dialogTitle = 'Share';

  constructor(
    public dialogRef: MatDialogRef<ShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    // console.groupCollapsed(this.constructor.name + '.ngOnInit');
    // console.debug('this.data', this.data);
    // if (this.data.dialogTitle) {
    //   this.dialogTitle = this.data.dialogTitle;
    // }
    // console.groupEnd();
  }

  close() {
    this.dialogRef.close();
  }
}
