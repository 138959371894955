import { Injectable } from '@angular/core';
import { ConfigurationHandlerService } from './configuration-handler.service';
import { StringUtils } from '../shared/utils/string.utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtUtilService } from './jwt-util.service';

/**
 * Pendo Agent API
 * @see https://agent.pendo.io/
 */
interface Pendo {
    get_visitor_id: () => string;
    initialize: (pendoConfig: any) => void;
    updateOptions: (options: any) => void;
}

declare var OnetrustActiveGroups: string;
/**
 * Checks if a OneTrust cookie type is active:
 * - C0001 Strictly Necessary Cookies
 * - C0002 Performance Cookies
 * - C0003 Functional Cookies
 * - C0004 Targeting Cookies
 * - C0005 Social Media Cookies
 */

const otOptIn = (groupKey: string) =>
    OnetrustActiveGroups?.indexOf(groupKey) > -1;

@Injectable({
    providedIn: 'root',
})
export class OnetrustPendoService {
    PIkey = '';

    constructor(
        private configSvc: ConfigurationHandlerService,
        private http: HttpClient,
        private jwtUtilSvc: JwtUtilService,
    ) {}

    initPendo() {
        if (this.configSvc.getFeatureFlagEnabled('ENABLE_PENDO')) {
            document.addEventListener('OneTrustIsLoaded', () =>
                this.initPendoParam(),
            );
            const pendoLicenceKey = this.configSvc.getCustomerParameter(
                'esploro_pendo_licence_key',
            );
            const pendoBlockMode = this.configSvc.getCustomerParameter(
                'pendo_control_level',
            );
            this.PIkey = this.configSvc.getCustomerParameter(
                'esploro_pendo_integration_key',
            );

            if (Number.parseInt(pendoBlockMode) === 2) {
                if (StringUtils.isNilOrEmpty(pendoLicenceKey)) {
                    return;
                }
                this.loadPendo(pendoLicenceKey);
            }
        }
    }

    initPendoParam() {
        const pendo: Pendo = window['pendo'];
        if (pendo != null) {
            if (otOptIn('C0003')) {
                this.startPendo();
            }

            const visitorId = pendo.get_visitor_id();
            if (visitorId != undefined) {
                this.callDNP(visitorId, !otOptIn('C0003'));
                this.callTG(visitorId, otOptIn('C0004') ? 'YES' : 'NO');
            }
        }
    }

    initOnetrust() {
        if (this.configSvc.getFeatureFlagEnabled('ENABLE_ONETRUST')) {
            const OTkey = this.configSvc.getCustomerParameter(
                'esploro_onetrust_licence_key',
            );
            setTimeout(() => {
                this.loadOnetrust(OTkey);
            }, 10);
        }
    }

    updateLoggedIn() {
        const pendo: Pendo = window['pendo'];
        if (pendo != null) {
            pendo.updateOptions({
                visitor: { isLoggedIn: this.jwtUtilSvc.isLoggedIn() },
            });
        }
    }

    private loadPendo(apiKey: string) {
        (function (p, e, n, d, o) {
            var v, w, x, y, z;
            o = p[d] = p[d] || {};
            o._q = o._q || [];
            v = [
                'initialize',
                'identify',
                'updateOptions',
                'pageLoad',
                'track',
            ];
            for (w = 0, x = v.length; w < x; ++w) {
                (function (m) {
                    o[m] =
                        o[m] ||
                        function () {
                            o._q[m === v[0] ? 'unshift' : 'push'](
                                [m].concat([].slice.call(arguments, 0)),
                            );
                        };
                })(v[w]);
            }
            y = e.createElement(n);
            y.async = !0;
            y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
            z = e.getElementsByTagName(n)[0];
            z.parentNode.insertBefore(y, z);
        })(window, document, 'script', 'pendo');
    }

    private callDNP(visitorId: string, flag: boolean) {
        this.http
            .put(
                `https://app.eu.pendo.io/api/v1/metadata/visitor/pendo/value/${visitorId}/donotprocess`,
                JSON.stringify(flag),
                { headers: this.requestHeaders() },
            )
            .subscribe({
                error: (error) => console.error('There was an error!', error),
            });
    }

    private callTG(visitorId: string, flag: string) {
        this.http
            .put(
                `https://app.eu.pendo.io/api/v1/metadata/visitor/custom/value/${visitorId}/esploro_targetingcookies`,
                JSON.stringify(flag),
                { headers: this.requestHeaders() },
            )
            .subscribe({
                error: (error) => console.error('There was an error!', error),
            });
    }

    private requestHeaders() {
        const headers = new Headers();
        if (!StringUtils.isNilOrEmpty(this.PIkey)) {
            headers.append('Content-Type', 'application/json');
            headers.append('x-pendo-integration-key', this.PIkey);
        }
        return new HttpHeaders(headers);
    }

    private createVisitorId(visitorId: any) {
        this.http
            .post(
                'https://app.eu.pendo.io/api/v1/metadata/visitor/custom/value?create=true',
                [{ visitorId }],
                { headers: this.requestHeaders() },
            )
            .subscribe({
                error: (error) => console.error('There was an error!', error),
            });
    }

    private loadOnetrust(apiKey: string) {
        const script1 = document.createElement('script');
        script1.id = 'otScript1';
        script1.src = `https://cdn.cookielaw.org/consent/${apiKey}/OtAutoBlock.js`;
        script1.type = 'text/javascript';
        script1.async = true;

        const script2 = document.createElement('script');
        script2.id = 'otScript2';
        script2.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
        script2.type = 'text/javascript';
        script2.charset = 'UTF-8';
        script2.dataset.domainScript = apiKey;
        script2.async = true;
        script2.setAttribute('amp', 'true');

        if (document.getElementById('otScript1') === null) {
            document.head.appendChild(script1);
        }
        if (document.getElementById('otScript2') === null) {
            document.head.appendChild(script2);
        }
    }

    private startPendo() {
        window['pendo'].initialize({
            visitor: {
                id: 'VISITOR-UNIQUE-ID',
                isLoggedIn: this.jwtUtilSvc.isLoggedIn(),
            },
            account: {
                id: this.configSvc.config.institutionCode,
                'Institution size': this.configSvc.config.institutionSize,
            },
            location: {
                setUrl: window.location.href,
            },
            excludeAllText: true,
            esploro_targetingcookies: otOptIn('C0004') ? 'YES' : 'NO',
        });
    }
}
