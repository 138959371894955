import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'htmlStrip', standalone: true })
export class HtmlStripPipe implements PipeTransform {
  parser = new DOMParser();

  transform(value: string, ...args: unknown[]): string {
    return this.parser
      .parseFromString(value, 'text/html')
      .body.textContent.trim();
  }
}
