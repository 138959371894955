import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatOption } from '@angular/material/core';
import { FloatLabelType, MatFormField, MatLabel } from '@angular/material/form-field';
import { NgFor } from '@angular/common';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'mulo-facet-sort',
    templateUrl: './facet-sort.component.html',
    styleUrls: ['./facet-sort.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'mulo-facet-sort' },
    standalone: true,
    imports: [
        MatFormField,
        MatLabel,
        MatSelect,
        NgFor,
        MatOption,
    ],
})
export class FacetSortComponent implements OnInit {
  @Input() label = 'Sort by';
  @Input() options: MatOption[] = [];
  @Input() selected: string;
  @Output() selectedChange = new EventEmitter<string>();

  floatLabel: FloatLabelType = 'auto';

  constructor() {}

  ngOnInit(): void {}

  onValueChange(value) {
    this.selectedChange.emit(value);
  }
}
