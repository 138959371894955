import { Component, OnDestroy, OnInit } from '@angular/core';
import { GrowInAnimation, GrowOutAnimation } from '@exl-ng/mulo-common';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { UntypedFormControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'esp-deposit-spatial',
  templateUrl: './deposit-spatial.component.html',
  styleUrls: ['./deposit-spatial.component.scss'],
  animations: [GrowOutAnimation, GrowInAnimation],
})
export class DepositSpatialComponent implements OnInit, OnDestroy {
  private addressDestroy = new Subject<void>();

  constructor(public depositFormDataService: DepositFormDataService) { }

  ngOnInit() {
    this.onChanges();
  }

  onChanges(): void {
    this.depositFormDataService.address.valueChanges
      .pipe(takeUntil(this.addressDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.ADDRESS
          );
        }
      });
  }

  get isAddressAutoPopulated() {
    return this.depositFormDataService.isKeyAutoPopulated(
      AutoPopulateKeys.ADDRESS
    );
  }

  getErrorMessage(formControl: UntypedFormControl, message: string) {
    if (formControl.value) {
      return message;
    } else {
      return 'research.field.required.error.message';
    }
  }

  ngOnDestroy() {
    if (!this.addressDestroy.isStopped) {
      this.addressDestroy.next();
      //unsubscribe from the subject itself
      this.addressDestroy.unsubscribe();
    }
  }
}
