import {
  Component,
  ViewChild,
  Input,
  EventEmitter,
  Output,
  AfterContentInit,
  ElementRef,
} from '@angular/core';
import {
  trigger,
  state,
  animate,
  transition,
  style,
  keyframes,
} from '@angular/animations';
import * as easings from '../../animations/easings';
import { MatButton } from '@angular/material/button';

const timingShort = '350ms ';
const easing = easings.OutBackMod;

@Component({
    selector: 'mulo-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss'],
    host: { class: 'mulo-toggle-button' },
    animations: [
        trigger('toggle', [
            state('left', style({
                transform: 'translateX(0)',
                width: '{{ widthLeft }}px',
            }), {
                params: {
                    widthLeft: '*',
                },
            }),
            state('right', style({
                transform: 'translateX({{ widthLeft }}px)',
                width: '{{ widthRight }}px',
            }), {
                params: {
                    widthLeft: '*',
                    widthRight: '*',
                },
            }),
            transition('left => right', [
                animate(timingShort + easing, keyframes([
                    style({
                        width: '{{ widthLeft }}px',
                        transform: 'translateX(0)',
                        offset: 0,
                    }),
                    style({
                        width: '100%',
                        offset: 0.65,
                    }),
                    style({
                        width: '{{ widthRight }}px',
                        transform: 'translateX({{ left }}px)',
                        offset: 1,
                    }),
                ])),
            ]),
            transition('right => left', [
                animate(timingShort + easing, keyframes([
                    style({
                        width: '{{ widthRight }}px',
                        transform: 'translateX({{ left }}px)',
                        offset: 0,
                    }),
                    style({
                        width: '100%',
                        transform: 'translateX(calc({{ left }}px / 3)',
                        offset: 0.65,
                    }),
                    style({
                        transform: 'translateX(0)',
                        width: '{{ widthLeft }}px',
                        offset: 1,
                    }),
                ])),
            ]),
        ]),
    ],
    standalone: true,
    imports: [MatButton],
})
export class ToggleButtonComponent implements AfterContentInit {
  /**
   * The toggle state
   */
  @Input() public get state() {
    return this._stateValue;
  }
  public set state(val) {
    this._stateValue = val;
  }

  /**
   * Button color, corresponds to Material button color
   */
  @Input() color = '';

  /**
   * Button type, correspond to Material button directives
   */
  @Input() flavor: null | 'stroked' | 'raised' | 'flat' = null;

  @Input('aria-label') ariaLabel;

  /**
   * Emitter for state change
   */
  @Output() stateChange = new EventEmitter();

  // DOM hooks
  /**
   * @internal
   */
  @ViewChild('toggleButton', { read: ElementRef, static: true })
  toggleButton: ElementRef<HTMLElement>;
  /**
   * @internal
   */
  @ViewChild('left', { static: true }) leftContainer: ElementRef<HTMLElement>;
  /**
   * @internal
   */
  @ViewChild('right', { static: true }) rightContainer: ElementRef<HTMLElement>;

  /**
   * The current state of the toggle (left || right)
   *
   * @internal
   */
  private _stateValue: 'left' | 'right' = 'left';

  // Values to calculate the sliding background
  /**
   * @internal
   */
  leftWidth: number = null;
  /**
   * @internal
   */
  rightWidth: number;
  /**
   * @internal
   */
  leftPadding = false;
  /**
   * @internal
   */
  rightPadding = false;

  /**
   * @internal
   */
  ngAfterContentInit() {
    const left = this.leftContainer.nativeElement;
    const right = this.rightContainer.nativeElement;
    this.leftPadding = !this.checkIfIcon(left);
    this.rightPadding = !this.checkIfIcon(right);
    setTimeout(() => {
      this.leftWidth = this.leftContainer.nativeElement.clientWidth;
      this.rightWidth = this.rightContainer.nativeElement.clientWidth;
    });
  }

  /**
   * toggle button state
   *
   * @internal
   */
  toggle() {
    this.state = this._stateValue === 'left' ? 'right' : 'left';
    this.stateChange.emit(this.state);
    // Accessibility - unfocus and then refocus the button in order for a screen reader to read the new button state
    setTimeout(() => {
      this.toggleButton.nativeElement.focus();
    }, 500);
  }

  /**
   * Check if the injected content is of icon type
   *
   * @internal
   */
  checkIfIcon(el) {
    return el.innerHTML.includes('mat-icon');
  }
}
