import { Injectable } from '@angular/core';
import { RestUtilService } from '../core/rest-util.service';
import { JwtUtilService } from '../core/jwt-util.service';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { StorageUtilService } from '../core/storage-util.service';
import { UrlUtils } from '../shared/utils/url.utils';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { LogoutInfo } from '../shared/interfaces/logout-info.model';
import { ConfigurationHandlerService } from '../core/configuration-handler.service';
import { I18nService } from '../core/i18n.service';
import { SessionStorageUtilService } from '../core/session-storage-util.service';
import { map } from 'rxjs';
import { OnetrustPendoService } from '../core/onetrust-pendo.service';

@Injectable({
    providedIn: 'root',
})
export class LogoutService {
    constructor(
        private restUtilService: RestUtilService,
        private jwtUtilService: JwtUtilService,
        private router: Router,
        private authService: AuthService,
        private storageUtilService: StorageUtilService,
        private configurationHandlerService: ConfigurationHandlerService,
        private i18nService: I18nService,
        private sessionStorageUtilService: SessionStorageUtilService,
        private otpSvc: OnetrustPendoService,
    ) {}

    logout() {
        if (!this.jwtUtilService.isResearcherLoggedIn()) {
            this.localLogout();
            window.close();
        }

        const authenticationSystem =
            this.jwtUtilService.getAuthenticationSystem();
        const idpCode = this.jwtUtilService.getAuthenticationProfile();
        const targetURL = this.authService.getProfilePage();
        const institution = UrlUtils.getParam('institution');

        const data = {
            sessionTimeout: 'false',
            targetURL: targetURL,
            authenticationSystem: authenticationSystem,
            institution: institution,
            idpCode: idpCode,
        };
        return this.restUtilService
            .postToServlet(RestApiBase.LOGOUT, data)
            .pipe(
                map((data) => {
                    const logoutInfo = data as LogoutInfo;
                    return logoutInfo ? logoutInfo.redirect : '';
                }),
            )
            .subscribe((redirectUrl) => {
                this.localLogout();
                //this.restoreDefaultInterfaceLanguage();
                if (redirectUrl) {
                    this.externalLogout(redirectUrl);
                } else {
                    this.internalLogout();
                }
                this.otpSvc.updateLoggedIn();
            });
    }

    externalLogout(redirectUrl) {
        window.location.href = redirectUrl;
    }

    internalLogout() {
        window.location.href = this.authService.getLoginPage();
    }

    localLogout() {
        this.storageUtilService.clear();
        this.sessionStorageUtilService.clear();
    }

    restoreDefaultInterfaceLanguage() {
        this.i18nService.setLanguage(
            this.configurationHandlerService.getDefaultDisplayLanguage(),
        );
    }
}
