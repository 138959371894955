import { NgModule } from '@angular/core';
import {ImageDialogComponent} from './image-dialog.component';
import {SharedModule} from '../../../shared/shared.module';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        ImageDialogComponent
    ],
    exports: [
        ImageDialogComponent
    ]
})
export class ImageDialogModule { }
