export class LinksConstant {

    public static LINK_ICON_REGEX = {
        facebook: /(?:facebook\.com|fb\.me)/,
        linkedin: /(?:linkedin\.com)/,
        twitter: /(?:twitter\.com)/,
        youtube: /(?:youtube\.com|youtu\.be)/,
        instagram: /(?:instagram\.com|instagr\.am)/,
        quora: /(?:quora\.com|qr\.ae)/,
        tumblr: /(?:tumblr\.com)/,
        medium: /(?:medium\.com)/,
    };

    public static LINK_SOCIALS_MEDIA_URL = {
        facebook: 'https://www.facebook.com/',
        linkedin: 'https://www.linkedin.com/',
        twitter: 'https://twitter.com/',
        youtube: 'https://www.youtube.com/',
        instagram: 'https://www.instagram.com/',
        reddit: 'https://www.reddit.com/',
        tumblr: 'https://www.tumblr.com/',
    };
}
