// export function trimExtraSpaces(string) {
//         return string.replace(/\s{2,}/g, ' ').trim()
// }
// export const camelize = (str) => {
//     return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
//         return index === 0 ? word.toLowerCase() : word.toUpperCase();
//     }).replace(/\s+/g, '');
// }

export function numberOrNumberUnit(val: string | number) {
  return typeof val == 'number'
    ? `${val}px`
    : /^\d+$/.test(val)
    ? `${val}px`
    : val;
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export function underscoreString(str) {
  return str.toLowerCase().replace(/ /g, '_');
}

export function replaceUnderscores(str, entity: string = ' ') {
  return str.charAt(0).toUpperCase() + str.replace(/ /g, entity).slice(1);
}

/**
 * Transforms a string into a number (if needed).
 */
export function strToNumber(value) {
  // Convert strings to numbers
  if (typeof value === 'string' && !isNaN(Number(value) - parseFloat(value))) {
    return Number(value);
  }
  if (typeof value !== 'number') {
    throw new Error(`${value} is not a number`);
  }
  return value;
}

export function digitsInfoParse(digitsInfo) {
  const parts = digitsInfo.match(/^(\d+)?\.((\d+)(-(\d+))?)?$/);
  if (parts === null) {
    throw new Error(`${digitsInfo} is not a valid digit info`);
  }
  return [parts[1], parts[3], parts[5]];
}

export function randomString(length: number = 5) {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, length);
}

export const lipsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin laoreet congue feugiat. Nunc pulvinar urna sit amet nisl accumsan, at malesuada velit suscipit. Donec eu lobortis velit. Donec rhoncus arcu ut magna sollicitudin, at scelerisque leo dignissim. Donec vel nunc suscipit, egestas mauris nec, sagittis metus. Duis viverra, libero id mattis luctus, est nisi laoreet felis, ut finibus leo justo ut justo. Vivamus vel nisl orci. Praesent tincidunt, mi non imperdiet pellentesque, mi nulla tempor sem, non malesuada justo justo ac felis. Suspendisse congue, felis vel sollicitudin ornare, massa sem scelerisque eros, a efficitur leo mi sit amet arcu. Integer posuere nibh vel odio scelerisque, ut vehicula est dapibus. Mauris volutpat leo volutpat dolor convallis elementum. Ut quis nunc non augue porttitor vulputate a ut neque. Suspendisse potenti. Donec eget nulla sodales arcu imperdiet congue. Donec risus arcu, elementum quis tincidunt eget, fringilla eget purus. Mauris ac consequat leo, id imperdiet metus.

Vivamus diam odio, molestie sit amet auctor id, sodales vel quam. Sed sit amet arcu at libero euismod ullamcorper. Donec sollicitudin enim in facilisis rutrum. Etiam facilisis, massa rhoncus commodo porta, justo urna ornare magna, eu ullamcorper purus risus nec ante. Cras dignissim eros vitae eros bibendum, sit amet euismod massa vulputate. Phasellus a finibus turpis. Cras mollis nisi facilisis, posuere ex ac, imperdiet lacus. Nullam fermentum, augue at faucibus cursus, risus dolor bibendum ipsum, tincidunt interdum elit enim id odio. Quisque nec tempor purus. Sed posuere dolor nibh, sit amet ornare ligula gravida sed. Integer in molestie nisl. Nunc vel orci sed mauris vulputate tempus. Pellentesque in est in velit tempor pulvinar non quis nisl. Nam pharetra dictum suscipit. Etiam egestas mauris vitae consequat malesuada. Suspendisse accumsan scelerisque consequat.

Vestibulum et neque suscipit, semper massa vel, condimentum elit. Donec nec mi purus. Phasellus fermentum tortor ut lacinia tincidunt. Quisque vulputate elementum sem, sed aliquet nulla ornare eu. Fusce varius dui sit amet metus dictum placerat. Vivamus finibus tellus turpis, vitae posuere nunc molestie eget. Maecenas sed tellus ante. Nunc tincidunt molestie placerat. Donec sit amet sem convallis, sagittis urna ut, suscipit nisi. Ut eu felis urna. Donec at diam sit amet ipsum dignissim rhoncus non quis risus. Sed nulla magna, tincidunt vel fermentum quis, mollis nec leo. Nullam nec felis enim. Integer ac ligula id diam volutpat consequat in non orci. Aliquam pretium quam a nisl viverra, nec convallis diam venenatis.

Aenean tristique mi sit amet mauris finibus facilisis. Curabitur at efficitur ipsum, quis pulvinar eros. Vivamus sed rhoncus erat, vitae ultricies nibh. Vestibulum rhoncus placerat nunc. Phasellus lobortis neque in aliquet tempus. Pellentesque semper efficitur elit. Cras urna est, vulputate vitae lectus ac, porttitor vulputate quam. Aliquam non sagittis enim. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras tristique euismod odio id porttitor. Pellentesque ultricies magna et eros sagittis interdum. Morbi scelerisque sapien sagittis metus dapibus fringilla. Fusce in sagittis metus, sed interdum eros.

Curabitur interdum ex eu quam accumsan, vel varius felis ullamcorper. Cras sapien lorem, sollicitudin non efficitur efficitur, laoreet sit amet eros. Nam lorem enim, placerat sit amet ante sed, blandit ullamcorper elit. Donec convallis, purus nec eleifend ultricies, diam ipsum bibendum nisi, tempor iaculis dolor neque ut magna. Integer vestibulum leo eget facilisis egestas. Nam eget nisi eget sapien posuere viverra. Pellentesque eros mi, tristique eget egestas vel, tristique ornare lacus. Donec consequat lorem faucibus enim luctus, sit amet volutpat augue sagittis.`;
