<div class="inner-wrapper">
    @if (isReserved || isRegistered) {
        <div>
            @if (isReserved) {
                <div class="deposit-section-description">
                    <span
                        [innerHTML]="
                            'research.status.doi.reservedExplanation' | customTranslate: translatedResourceType
                        "
                    ></span>
                </div>
            }
            <div class="reserve-status">
                @if (reserveDoiInProcess) {
                    <span class="margin-right-sm">{{ 'research.status.doi.reservedInProcess' | customTranslate }}</span>
                } @else if (isReserved) {
                    <span class="margin-right-sm"
                        >{{ 'research.status.doi.reserved' | customTranslate }}
                        <span class="semibold">{{ doi }}</span></span
                    >
                } @else {
                    <span class="margin-right-sm semibold">{{ doi }}</span>
                }
                @if (isReserved) {
                    <button #dismissBtn (click)="dismissDoi()" class="has-icon" color="primary" mat-button>
                        <mat-icon svgIcon="close"></mat-icon>
                        <span>{{ 'research.status.doi.dismissDoiLink' | customTranslate }}</span>
                    </button>
                }
            </div>
        </div>
    } @else {
        <div class="field-wrap">
            <mat-form-field [class.is-autopopulated]="isAutoPopulated" class="is-comfortably-readable">
                <input [formControl]="doiControl" matInput />
                @if (isAutoPopulated) {
                    <mat-icon
                        [matTooltip]="'research.auto.populate.tooltip' | customTranslate"
                        matPrefix
                        svgIcon="auto-populate"
                    >
                    </mat-icon>
                }
                <mat-label>{{ placeholder | customTranslate }}</mat-label>
                <mat-hint>{{ 'research.status.doi.hint' | translate }}</mat-hint>
                <mat-error>{{ 'research.status.doi.error' | customTranslate }}</mat-error>
            </mat-form-field>
            @if (isReservedDoiButtonVisible) {
                <div class="margin-left-sm">
                    <span>{{ 'research.status.doi.reserveMessage' | customTranslate }}</span>
                    <button #reserveBtn (click)="requestReserveDoi()" color="primary" mat-button>
                        {{ 'research.status.doi.reserveLink' | customTranslate }}
                    </button>
                </div>
            }
        </div>
    }
</div>
