<mat-form-field
  *ngIf="options && options.length > 1"
  class="is-inline is-wrapping"
  [style.maxWidth]="maxWidth"
  appearance="outline"
>
  <mat-select [formControl]="_selectControl" class="italic semibold" [aria-label]="label">
    <mat-option *ngFor="let option of options" [value]="option.value">{{ option.viewValue | translate }}</mat-option>
  </mat-select>
</mat-form-field>

<div
  *ngIf="!options || options.length === 1"
  class="mulo-searchbar-static-label semibold italic"
  [style.maxWidth]="maxWidth"
>
  {{ options ? (options[0].viewValue | translate) : label }}
</div>
