<div>
    <mat-form-field
        [muloInputExpander]="depositFormDataService.audience.value"
        class="is-comfortably-readable"
    >
        <input
            matInput
            exlAutomationClass="presentation-audience"
            [formControl]="depositFormDataService.audience"
        />
        <mat-label>{{
            'research.conference.audience' | customTranslate
        }}</mat-label>
    </mat-form-field>

    <mat-form-field
        class="is-comfortably-readable"
        [muloInputExpander]="depositFormDataService.pages.value"
    >
        <input
            matInput
            exlAutomationClass="presentation-pages"
            [formControl]="depositFormDataService.pages"
        />
        <mat-label>{{ 'research.status.pages' | customTranslate }}</mat-label>
    </mat-form-field>
</div>
