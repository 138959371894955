<div class="paging-container">
  <nav role="navigation" [attr.aria-label]="screenReaderPaginationLabel" *ngIf="!(autoHide && p.pages.length <= 1)">
    <button
      mat-button
      class="go-dir-btn"
      *ngIf="directionLinks && !p.isFirstPage()"
      (click)="p.previous()"
      (keyup.enter)="p.previous()"
      [attr.aria-label]="previousLabel + ' ' + screenReaderPageLabel"
      [class.is-square]="icons"
    >
      <ng-container *ngIf="!icons; else prevIcon">
        {{ previousLabel }}
        <span class="cdk-visually-hidden">{{ screenReaderPageLabel }}</span>
      </ng-container>
      <ng-template #prevIcon>
        <mat-icon class="mat-icon-rtl-mirror" svgIcon="chevron-left"></mat-icon>
      </ng-template>
    </button>

    <ng-container *ngFor="let page of p.pages; let i = index">
      <button
        mat-button
        [class.is-current]="p.getCurrent() === page.value"
        [class.is-ellipsis]="page.label === '...'"
        [attr.aria-label]="page.label === '...' ? _parseJumpLabel(page.value) : null"
        *ngIf="!hideLastPage || page.value !== p.getLastPage() || p.pages[p.pages.length - 2].label !== '...'"
        class="is-square"
        (click)="p.setCurrent(page.value)"
        [disabled]="p.getCurrent() === page.value"
        (keyup.enter)="p.setCurrent(page.value)"
      >
        <span class="cdk-visually-hidden">{{ screenReaderPageLabel }} </span>
        {{ page.label === '...' ? page.label : (page.label | number : '') }}
      </button>
    </ng-container>

    <button
      mat-button
      class="go-dir-btn"
      *ngIf="directionLinks && !p.isLastPage()"
      (keyup.enter)="p.next()"
      (click)="p.next()"
      [attr.aria-label]="nextLabel + ' ' + screenReaderPageLabel"
      [class.is-square]="icons"
    >
      <ng-container *ngIf="!icons; else nextIcon">
        {{ nextLabel }}
        <span class="cdk-visually-hidden">{{ screenReaderPageLabel }}</span>
      </ng-container>
      <ng-template #nextIcon>
        <mat-icon class="mat-icon-rtl-mirror" svgIcon="chevron-right"></mat-icon>
      </ng-template>
    </button>
  </nav>
</div>
