import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { mixinColor } from '../../common-behavior/color';
import { MatIcon } from '@angular/material/icon';
import { NgClass, NgStyle, NgIf } from '@angular/common';

class InfobarComponentBase {
  constructor(public _elementRef: ElementRef) {}
}

const _InfobarComponentMixinBase = mixinColor(InfobarComponentBase);

type Width = 'auto' | 'full' | 'none' | number | string;
type Appearance = 'slim' | 'normal' | 'chunky';

@Component({
    selector: 'mulo-infobar',
    templateUrl: './infobar.component.html',
    styleUrls: ['./infobar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['color'],
    host: {
        class: 'mulo-infobar',
        '[class.has-rounded-corners]': 'this.corners === "rounded"',
        '[class.is-slim]': 'this.appearance === "slim"',
        '[class.is-chunky]': 'this.appearance === "chunky"',
        '[style.--align-v]': 'horizontalAlign',
        '[style.--align-h]': 'verticalAlign',
    },
    standalone: true,
    imports: [
        NgClass,
        NgStyle,
        NgIf,
        MatIcon,
    ],
})
export class InfobarComponent
  extends _InfobarComponentMixinBase
  implements OnInit
{
  @Input() icon: string | null = null;
  @Input() rtlMirrorIcon: boolean;
  @Input() width: Width = 'auto';
  @Input() corners: 'rounded' | 'none' = 'rounded';
  @Input() horizontalAlign: 'start' | 'end' | 'center' = 'start';
  @Input() verticalAlign: 'start' | 'end' | 'center' = 'center';

  @Input() appearance: Appearance = 'normal';

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }

  ngOnInit(): void {}

  get isNumber() {
    return typeof this.width === 'number';
  }

  get widthClass() {
    return this.isNumber ? `is-custom-width` : `is-${this.width}-width`;
  }

  get customWidth() {
    return this.isNumber ? { width: `${this.width}px` } : null;
  }
}
