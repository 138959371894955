import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'htmlEscape', standalone: true })
export class HtmlEscapePipe implements PipeTransform {
  parser = new DOMParser();

  static escape = (_: string) =>
    _.replace(/["&'<>`]/gm, (s) => '&#' + s.charCodeAt(0) + ';');

  transform(str: string, certain = true) {
    // completely replace new line elements because they only make things worse
    str = str.replace(/<br>/gim, '\n');
    const html = this.parser.parseFromString(str, 'text/html').body.innerHTML;

    // we can't simply check the incoming str against the html (see URM-177030)
    // so we parse out the HTML entities and act as though they were always there
    // also, we replace any lone ampersands and non-breaking spaces so that they won't be the deciding factor
    const strCleaned = str.replace(
      /&[#\w]+;|&\s|\xa0/gim,
      (match) => this.parser.parseFromString(match, 'text/html').body.innerHTML
    );

    // trim is to handle leading spaces that throw off char count
    if (!certain && html.trim().length === strCleaned.trim().length) {
      return strCleaned;
    } else {
      return HtmlEscapePipe.escape(str);
    }
  }
}
