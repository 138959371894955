import { trigger, animate, transition, style } from '@angular/animations';
import * as easings from './easings';

const paramsIn = {
  time: '.4s',
  opacityFrom: 1,
  opacityTo: 1
};

const paramsOut = {
  time: '.4s',
  opacityFrom: 1,
  opacityTo: 1
};

export const HeightInAnimation = trigger('heightInAnimation', [
  transition(
    ':enter',
    [
      style({
        opacity: '{{opacityFrom}}',
        height: 0,
        overflow: 'hidden',
      }),
      animate(
        '{{ time }} ' + easings.OutQuint,
        style({
          height: '*',
          opacity: '{{opacityTo}}'
        })
      ),
    ],
    { params: paramsIn }
  ),
]);

export const HeightOutAnimation = trigger('heightOutAnimation', [
  transition(
    ':leave',
    [
      style({
        opacity: '{{opacityFrom}}',
        height: '*',
        overflow: 'hidden',
      }),
      animate(
        '{{ time }} ' + easings.OutQuint,
        style({
          height: 0,
          opacity: '{{opacityTo}}'
        })
      ),
    ],
    { params: paramsOut }
  ),
]);
