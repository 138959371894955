import { Injectable } from '@angular/core';
import {
    SearchEntities,
    SearchScopesByEntity,
} from '../shared/configurations/portal-search.constant';
import { ConfigurationHandlerService } from '../core/configuration-handler.service';
import { PortalService } from '../portal/portal.service';

@Injectable({
    providedIn: 'root',
})
export class EntitySearchService {
    searchEntities = [
        {
            value: SearchEntities.OUTPUTS_ENTITY,
            viewValue: 'research.search.scope.outputs',
            placeholder: 'research.search.scope.outputs.placeholder',
            facets: true,
            view: null,
            searchBy: [],
            scopes: this.portalService.filterSearchScopesByCtrlCode(
                SearchScopesByEntity.OUTPUTS
            ),
        },
        {
            value: SearchEntities.RESEARCHERS_ENTITY,
            viewValue: 'research.search.scope.researchers',
            placeholder: 'research.search.scope.researchers.placeholder',
            facets: false,
            view: 'tiles',
            searchBy: [],
            scopes: this.configurationHandlerService.getProfilesSearchScopesFromConfiguration(),
        },
        {
            value: SearchEntities.PROJECTS_ENTITY,
            viewValue: 'research.search.scope.projects',
            placeholder: 'research.search.scope.projects.placeholder',
            facets: false,
            view: null,
            searchBy: [],
            scopes: this.configurationHandlerService.getProjectsSearchScopesFromConfiguration(),
        },
    ];

    constructor(
        private configurationHandlerService: ConfigurationHandlerService,
        private portalService: PortalService
    ) {}

    getSearchEntities() {
        const sortedSearchEntities =
            this.configurationHandlerService.getPortalSearchOptionsConfiguration() ||
            [];
        this.searchEntities = this.searchEntities.filter(
            (entity) => sortedSearchEntities.indexOf(entity.value) !== -1
        );
        this.searchEntities = this.searchEntities.sort(
            (a, b) =>
                sortedSearchEntities.indexOf(a.value) -
                sortedSearchEntities.indexOf(b.value)
        );
        this.setSearchEntitiesByCustomParam();
        return this.searchEntities;
    }

    setSearchEntitiesByCustomParam() {
        const searchProfiles =
            this.configurationHandlerService.getCustomerParameter(
                'esploro_public_profiles_active'
            ) === 'true';
        if (!searchProfiles) {
            this.searchEntities = this.searchEntities.filter(
                (item) => item.value !== 'researchers'
            );
        }
        const searchProjects =
            this.configurationHandlerService.isEsploroEditionAdvanced() ||
            this.configurationHandlerService.getCustomerParameter(
                'esploro_projects'
            ) === 'true';
        if (!searchProjects) {
            this.searchEntities = this.searchEntities.filter(
                (item) => item.value !== 'projects'
            );
        }
    }
}
