export class UrlUtils {

    private static urlParamMap = {};

    //IE does not support URLSearchParams
    public static getParam(name): string{
        if (this.urlParamMap[name]){
            return this.urlParamMap[name];
        }
        const results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results==null){
            return null;
        } else{
            this.urlParamMap[name] = decodeURI(results[1]);
            return decodeURI(results[1]);
        }
    }

    public static setParam(name, paramVal) {
    	this.urlParamMap[name] = paramVal;
    }

    public static isURL(str) {
        const pattern = new RegExp('^https?:.+');
        return pattern.test(str);
    }
}
