import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  DoCheck,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  Renderer2,
  SimpleChange,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import * as easings from '../../animations/easings';
import {
  OpacityInAnimation,
  OpacityOutAnimation,
  SlideInAnimation,
  SlideOutAnimation,
} from '../../animations';
import { HorizontallerItemComponent } from './horizontaller-item/horizontaller-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { NgIf, NgFor, NgTemplateOutlet } from '@angular/common';

@Directive({
    selector: '[slot="horizontaller-header"]',
    standalone: true
})
export class HorizontallerHeaderDirective {}

@Component({
    selector: 'mulo-horizontaller',
    templateUrl: './horizontaller.component.html',
    styleUrls: ['./horizontaller.component.scss'],
    animations: [
        SlideInAnimation,
        SlideOutAnimation,
        OpacityOutAnimation,
        OpacityInAnimation,
    ],
    host: {
        class: 'mulo-horizontaller',
        '[style.--horizontaller-width]': 'horizontallerWidth',
        '[style.--horizontaller-valign]': 'verticalAlign',
        '[style.--horizontaller-btn-width]': 'buttonWidth',
        '[style.--horizontaller-min-height]': 'minHeight',
        '[style.--horizontaller-height]': 'height',
        '[class.has-bullets]': 'showPageBullets',
    },
    standalone: true,
    imports: [
        NgIf,
        MatIconButton,
        MatIcon,
        NgFor,
        NgTemplateOutlet,
        ContentLoaderModule,
        TranslateModule,
    ],
})
export class HorizontallerComponent implements OnInit, DoCheck {
  @ViewChild('scrollerParent') scrollerParent: ElementRef;
  @ViewChild('scroller') scroller: ElementRef;
  @ViewChild('scrollerWrapper') scrollerWrapper: ElementRef;

  @ContentChild(HorizontallerHeaderDirective) headerContent;
  @ContentChildren(HorizontallerItemComponent)
  items: QueryList<HorizontallerItemComponent>;

  // @Input() headerText: String;
  @Input() scrollLeftBtnAriaLabel = 'Scroll Backward';
  @Input() scrollRightBtnAriaLabel = 'Scroll Forward';
  @Input() verticalAlign: 'start' | 'end' | 'center' = 'center';
  @Input() height = 150;
  @Input() minHeight = 150;
  @Input() scrollbarMask = 40;
  @Input() buttonWidth = 80;
  @Input() rtl = false;

  _placeholders;
  @Input() placeholders = 3;
  @Input() placeholderTemplate: TemplateRef<any>;
  @Input() loading = false;
  @Input() scrollSpeed = 1000;

  @Input() showPageBullets = false;
  @Input() pageBulletBtnLabel: string;
  activeBullet;

  @Input() placeholderWidth = 250;
  scrollStarted = false;
  scrollLeftDisabled = true;
  scrollRightDisabled = false;
  offsetBreakpoints: Array<number> = [];
  horizontallerWidth;
  /**
   * The intersection observer that watches whether elements interesect with the view
   */
  private observer: IntersectionObserver;

  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) {
    this._placeholders = Array(this.placeholders);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        this.activeBullet = null;
        entries.forEach((entry) => {
          const offset = (entry.target as HTMLElement).offsetLeft;
          if (offset) {
            this.offsetBreakpoints = this.offsetBreakpoints.filter(
              (bp) => bp != offset
            );
            if (entry.intersectionRatio === 1) {
              const currentIdx = Number.parseInt(
                entry.target.getAttribute('data-index')
              );
              this.activeBullet =
                this.activeBullet != null
                  ? Math.min(this.activeBullet, currentIdx)
                  : currentIdx;
              entry.target.setAttribute('mulo-horizontaller-visible', '');
            } else {
              this.offsetBreakpoints.push(offset);
              this.offsetBreakpoints.sort((a, b) => a - b);
              entry.target.removeAttribute('mulo-horizontaller-visible');
            }
          }
        });
      },
      { root: this.scrollerParent.nativeElement, threshold: [0, 1] }
    );
    this.observeItems();
    this.horizontallerWidth = this.scrollerParent?.nativeElement?.scrollWidth;

    this.items.changes.subscribe(() => {
      this.observeItems();
      this.cdr.markForCheck();
    });
    setTimeout(() => this.cdr.markForCheck());
  }

  ngOnChanges(changes: SimpleChange) {
    if (changes['loading'] && !changes['loading'].firstChange) {
      setTimeout(() => this.cdr.markForCheck());
    }
  }

  ngDoCheck() {
    const scrollWrapper = this.scrollerWrapper?.nativeElement;
    const totalScroll = scrollWrapper?.scrollWidth;
    const scrollEl = this.scroller?.nativeElement;
    const viewableScroll = scrollEl?.offsetWidth;

    this.scrollLeftDisabled = scrollWrapper?.scrollLeft == 0;
    this.scrollRightDisabled =
      scrollWrapper?.scrollLeft >= totalScroll - viewableScroll - 1; // add 1 for integer math
  }

  scroll(dir, scrollTo = null) {
    const scrollWrapper = this.scrollerWrapper.nativeElement;
    const totalScroll = scrollWrapper.scrollWidth;
    const scrollEl = this.scroller.nativeElement;
    const viewableScroll = scrollEl.offsetWidth;

    let nextBreakpoint;
    if (dir === 'right') {
      nextBreakpoint = Math.min(
        ...this.offsetBreakpoints.filter((bp) => bp > scrollWrapper.scrollLeft)
      );
    } else {
      nextBreakpoint = Math.max(
        ...this.offsetBreakpoints.filter((bp) => bp < scrollWrapper.scrollLeft)
      );
    }
    if (
      !this.scrollStarted &&
      ((dir === 'left' && !this.scrollLeftDisabled) ||
        (dir === 'right' && !this.scrollRightDisabled))
    ) {
      const scrollRight = scrollWrapper.scrollLeft + viewableScroll;
      const distanceOpts =
        scrollTo === null
          ? [
              viewableScroll - this.buttonWidth,
              dir === 'right'
                ? totalScroll - scrollRight
                : scrollWrapper.scrollLeft,
              Math.abs(scrollWrapper.scrollLeft - nextBreakpoint) +
                (dir === 'right' ? -this.buttonWidth : this.buttonWidth),
            ].filter((_) => _ > 0)
          : [
              Math.abs(scrollWrapper.scrollLeft - scrollTo) +
                (dir === 'right' ? -this.buttonWidth : this.buttonWidth),
            ];
      const distance = Math.ceil(Math.min(...distanceOpts)); // ceiling to prevent 1px distance

      const direction = dir === 'right' ? '-' : '';
      const time = this.scrollSpeed;
      this.scrollStarted = true;
      scrollEl.style.transition = `transform ${time}ms ${easings.InOutCubic}`;
      scrollEl.style.transform = `translate3d(${direction}${distance}px, 0, 0)`;
      setTimeout(() => {
        scrollEl.style.transition = '';
        scrollEl.style.transform = '';
        scrollWrapper.scrollLeft += dir === 'right' ? distance : -distance;
        this.scrollStarted = false;
        this.cdr.markForCheck();
      }, time);
    }
  }

  scrollToItem(index: number) {
    const itemElem = this.items.get(index)?.elem?.nativeElement;
    const scrollWrap = this.scrollerWrapper.nativeElement;
    const dir = scrollWrap.scrollLeft > itemElem.offsetLeft ? 'left' : 'right';
    this.scroll(dir, itemElem.offsetLeft);
  }

  observeItems() {
    this.items?.toArray().forEach((item, i) => {
      this.renderer.setAttribute(
        item.elem.nativeElement,
        'data-index',
        i.toString()
      );
      this.observer.observe(item.elem.nativeElement);
    });
  }
}
