import {Component, Input, OnInit} from '@angular/core';
import {TabComponentModel} from '../../../shared/interfaces/tab-component.model';

@Component({
    selector: 'esp-deposit-step-four',
    templateUrl: './deposit-step-four.component.html',
    styleUrls: ['./deposit-step-four.component.scss']
})
export class DepositStepFourComponent implements OnInit {

    @Input() tab4;

    constructor() { }

    ngOnInit() {
    }

    displayGrants(comp: TabComponentModel){
        return comp.visible && this.identifyComponent(comp.field, 'grants');
    }

    identifyComponent(field:string, name:string) {
        return field === name;
    }

    isRequired(comp){
        return comp.mandatory;
    }

}
