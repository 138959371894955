import {Directive, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl, AbstractControl, Validators, ValidatorFn } from '@angular/forms';

@Directive({
    selector: '[exlRequiredInput]',
})
export class RequiredInputDirective implements OnInit, OnDestroy {

    @Input('exlRequiredInput') control: UntypedFormControl;
    @Input('exlIsRequired') isRequired = true;

    private _originalValidators: ValidatorFn | null;

    set originalValidators(validator: ValidatorFn | null) {
        if (!this._originalValidators){
            this._originalValidators = validator;
        }
    }

    ngOnInit() {
        if (this.isRequired){
            if (this.control.validator) {
                const { validator } = this.control;
                this.originalValidators = validator;
                this.control.setValidators([Validators.required, this._originalValidators]);
                this.control.updateValueAndValidity();
            } else {
                this.control.setValidators([Validators.required]);
            }
        }
    }

    isRequiredControl = (control: AbstractControl): boolean => {
        const { validator } = control;
        if (validator) {
            const validation = validator(new UntypedFormControl());
            return validation !== null && validation.required === true;
        }
        return false;
    }

    ngOnDestroy(): void {
        if (this.isRequired) {
            if (this._originalValidators){
                this.control.setValidators([this._originalValidators]);   // erase all current validators
            } else {
                this.control.clearValidators();
            }
            this.control.updateValueAndValidity();
        }
    }
}
