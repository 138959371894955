import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'collectByField',
    standalone: true
})
export class CollectByFieldPipe implements PipeTransform {
  transform(value: Array<object> = [], field: string): any[] {
    return value.map((item) => item[field]);
  }
}

