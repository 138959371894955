import { ReplaySubject } from 'rxjs';
import { OnDestroy } from '@angular/core';

export const componentDestroyed = (component: OnDestroy) => {
  const oldNgOnDestroy = component.ngOnDestroy;
  const destroyed$ = new ReplaySubject<any>(1);
  const _destroyed = destroyed$.asObservable();
  component.ngOnDestroy = () => {
    oldNgOnDestroy.apply(component);
    destroyed$.next(undefined);
    destroyed$.complete();
  };
  return _destroyed;
};
