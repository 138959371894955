import { ValidatorFn } from '@angular/forms';
import { StringUtils } from '../utils/string.utils';

export const validateDoiFormat: ValidatorFn = (control) => {
    const doiFormat = control.value as string;
    if (doiFormat && !StringUtils.isDoiFormat(doiFormat)) {
        return {
            validateDoiFormat: 'research.status.doi.error.default.default',
        };
    }
    return null;
};

export const validateDoiOrURLFormat: ValidatorFn = (control) => {
    const doiFormat = control.value as string;
    if (
        doiFormat &&
        !StringUtils.isDoiFormat(doiFormat) &&
        !StringUtils.isDoiUrlFormat(doiFormat)
    ) {
        return {
            validateDoiOrURLFormat: 'research.status.doi.error.default.default',
        };
    }
    return null;
};
