import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    AbstractControl,
    AsyncValidatorFn,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { SettingsDataService } from '../../settings/settings-data.service';
import { ProjectDetails } from '../../shared/interfaces/project.interface';
import { ProjectService } from '../project.service';
import { ContentDialogService } from '../../parts/dialogs/content-dialog/content-dialog.service';
import {
    Observable,
    of,
    Subject,
    filter,
    finalize,
    map,
    startWith,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';
import { SuccessSnackbarComponent } from '../../parts/snackbars/success-snackbar/success-snackbar.component';
import { SnackbarService } from '../../core/snackbar.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ResearcherOrganization } from '../../shared/interfaces/researcher-organization.interface';
import { ProfileService } from '../../profile/profile.service';
import { UrlUtils } from '../../shared/utils/url.utils';
import { OrganizationService } from '../../core/organization.service';
import { Router } from '@angular/router';
import { MultiLanguageService } from '../../parts/multi-language/multi-language.service';

@Component({
    selector: 'esp-add-project',
    templateUrl: './add-project.component.html',
    styleUrls: ['./add-project.component.scss'],
    host: { class: 'esp-add-project' },
    animations: [
        trigger('SpinnerIn', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateX(100%) scale(.5)',
                }),
                animate(
                    '300ms 300ms cubic-bezier(0.165, 0.84, 0.44, 1)',
                    style({
                        opacity: 1,
                        transform: 'translateX(0) scale(1)',
                    })
                ),
            ]),
        ]),
    ],
})
export class AddProjectComponent implements OnInit, OnDestroy {
    projectForm: UntypedFormGroup;
    loading: boolean;
    internalDepartmentId: string;
    institutionCode: string;

    formSubmitSubject$ = new Subject();
    projectTypes$: Observable<string[]>;

    private addProjectDestroy = new Subject<void>();

    constructor(
        public settingsDataService: SettingsDataService,
        private formBuilder: UntypedFormBuilder,
        public projectService: ProjectService,
        private contentDialogService: ContentDialogService,
        private snackbarService: SnackbarService,
        public profileService: ProfileService,
        private organizationService: OrganizationService,
        private router: Router,
        private multiLanguageService: MultiLanguageService
    ) {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    get internalDepartment() {
        return this.projectForm.get('internalDepartment');
    }

    ngOnInit(): void {
        this.initForm();
        this.projectTypes$ = this.projectService.getCodeTableList(
            'ResearchProjectTypes'
        );
        this.formSubmitListener();
        this.setPrimaryOrganization();
    }

    ngOnDestroy(): void {
        this.addProjectDestroy.next();
        this.addProjectDestroy.unsubscribe();
    }

    initForm() {
        this.projectForm = this.formBuilder.group({
            name: ['', Validators.required],
            projectIdentifier: new UntypedFormControl('', {
                validators: Validators.maxLength(255),
                asyncValidators: this.validateProjectIdentifier(),
                updateOn: 'blur',
            }),
            type: [''],
            internalDepartment: '',
        });
    }

    setPrimaryOrganization() {
        this.organizationService
            .getReseracherPrimaryOrganization()
            .pipe(takeUntil(this.addProjectDestroy))
            .subscribe((org) => {
                if (!org) return;
                this.projectForm.patchValue({
                    internalDepartment: org.organizationCode,
                });
                this.setInternalOrganization(org);
            });
    }

    formSubmitListener() {
        this.formSubmitSubject$
            .pipe(
                takeUntil(this.addProjectDestroy),
                tap(() => (this.loading = true)),
                switchMap(() =>
                    this.projectForm.statusChanges.pipe(
                        startWith(this.projectForm.status),
                        filter((status) => status !== 'PENDING')
                    )
                ),
                filter((status) => status === 'VALID'),
                finalize(() => {
                    this.loading = false;
                })
            )
            .subscribe((validationSuccessful) => this.addNewProject());
    }

    addNewProject() {
        this.loading = true;
        const addNewProject: ProjectDetails = {} as ProjectDetails;
        addNewProject.name = {
            [this.multiLanguageService.defaultLangKey()]:
                this.projectForm.value.name,
        };
        addNewProject.projectIdentifier =
            this.projectForm.value.projectIdentifier;
        addNewProject.typeCode = this.projectForm.value.type;
        addNewProject.visibleInPortal = false;
        addNewProject.visibleInProfiles = true;
        addNewProject.repositoryStatusCode = 'project.pending_approval';
        addNewProject.owningDepartmentsId = this.internalDepartmentId
            ? [this.internalDepartmentId]
            : [];
        this.projectService.addNew(addNewProject).subscribe((result: any) => {
            this.loading = false;
            this.contentDialogService.closeDialog();
            this.showSuccessfullMessage();
            this.navigateToFullProjectPage(result.url);
        });
    }

    navigateToFullProjectPage(url) {
        this.router.navigate([url], {
            queryParams: { institution: this.institutionCode, mode: 'edit' },
        });
    }

    validateProjectIdentifier(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            if (!control.value) {
                return of(null);
            }
            return this.projectService.isIdentifierUnique(control.value).pipe(
                finalize(() => {
                    this.loading = false;
                }),
                map((isUnique) => {
                    return isUnique
                        ? null
                        : {
                              isNotUnique: true,
                          };
                })
            );
        };
    }

    isValid() {
        return this.projectForm?.valid;
    }

    showSuccessfullMessage() {
        this.snackbarService.showComponent(
            SuccessSnackbarComponent,
            'research.add.project.dialog.success.message',
            7000
        );
    }

    setInternalOrganization(organization: ResearcherOrganization) {
        this.internalDepartmentId = organization.organizationId;
    }
}
