import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'htmlSanitize', standalone: true })
export class HtmlSanitizePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, ...args: unknown[]): string | null {
    return this.sanitizer.sanitize(SecurityContext.HTML, value);
  }
}
