import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';
import { ContentLoaderModule } from '@ngneat/content-loader';

@Component({
    selector: 'mulo-page-placeholder',
    templateUrl: './page-placeholder.component.html',
    styleUrls: ['./page-placeholder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'mulo-page-placeholder',
        // '[style.height.px]': 'this.height'
    },
    standalone: true,
    imports: [ContentLoaderModule],
})
export class PagePlaceholderComponent implements OnInit {
  @Input() width: number = 500;
  @Input() height: number = 580;
  @Input() rtl = false;

  constructor() {}

  ngOnInit(): void {}
}
