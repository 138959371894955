import {EsploroOrganizationCategory} from './esploro-organization-category.enum';

export class ListOwnership{
    public static INTERNAL = 'internal';
    public static EXTERNAL = 'external';
}

export class ListType{
    public static CURRENT = 'current';
    public static PREVIOUS = 'previous';
}

export interface ResearcherOrganization {
    organizationCode: string;
    organizationName: string;
    organizationId: string;
    organizationCategory: EsploroOrganizationCategory;
}
