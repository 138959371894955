import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { UrlUtils } from '../shared/utils/url.utils';
import { Profile } from '../shared/configurations/profile.constant';
import { ConfigurationHandlerService } from '../core/configuration-handler.service';
import { RouterService } from './router.service';
import { I18nService } from '../core/i18n.service';

@Injectable()
export class EditionGuard  {
    institutionCode = UrlUtils.getParam('institution');

    constructor(
        private router: Router,
        private routerService: RouterService,
        private configurationHandlerService: ConfigurationHandlerService,
        private i18nService: I18nService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.info('EditionGuard#canActivate called for ' + route.params.id);
        return this.isEditionAdvanced(route.params.id);
    }

    isEditionAdvanced(id): boolean {
        if (this.configurationHandlerService.isEsploroEditionAdvanced()) {
            return true;
        }
        const discoveryURL = this.getDiscoveryMainPageURL(this.institutionCode);
        window.open(discoveryURL, '_self');
    }

    getDiscoveryMainPageURL(institutionCode: string) {
        let discoveryURL = Profile.DISCOVERY_MAIN_PAGE_URL;
        discoveryURL = discoveryURL.replace(
            '{{institution_code}}',
            institutionCode
        );
        discoveryURL = discoveryURL.replace(
            '{{lang}}',
            this.i18nService.getLanguage()
        );
        return discoveryURL;
    }
}
