import {
    ANZTopic,
    ResearchTopic,
} from './../../../shared/interfaces/research-topic.intreface';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { StatusUtils } from '../../../shared/utils/status.utils';
import { ResourceTypeUtils } from '../../../shared/utils/resource-type.utils';
import { DateUtils } from '../../../shared/utils/date.utils';
import { Status } from '../../../shared/configurations/status.constant';
import { SystemDateFormatService } from '@exl-ng/mulo-core';

@Component({
    selector: 'esp-deposit-summary',
    templateUrl: './deposit-summary.component.html',
    styleUrls: ['./deposit-summary.component.scss'],
})
export class DepositSummaryComponent implements OnInit {
    @Output() jumpToSection: EventEmitter<any> = new EventEmitter();

    constructor(
        public depositFormDataService: DepositFormDataService,
        private systemDateFormatService: SystemDateFormatService
    ) {}

    ngOnInit() {}

    get asset() {
        return this.depositFormDataService.mainForm.value;
    }

    get assetTopics() {
        const forSubject =
            this.depositFormDataService.mainForm.value.forSubject.map(
                (subject: ANZTopic) => {
                    return subject.label;
                }
            );
        const seoSubject =
            this.depositFormDataService.mainForm.value.seoSubject.map(
                (subject: ANZTopic) => {
                    return subject.label;
                }
            );
        const topics = this.depositFormDataService.mainForm.value.topics.map(
            (subject: ResearchTopic) => {
                return subject.description;
            }
        );
        return topics.concat(forSubject, seoSubject);
    }

    displayPeerReviewed() {
        return (
            (this.depositFormDataService.isPublicationJournalArticle() ||
                this.depositFormDataService.isPublicationBookReview() ||
                this.depositFormDataService.isPublicationBookChapter() ||
                this.depositFormDataService.isPublicationBook() ||
                this.depositFormDataService.isPublicationConferenceProceedings()) &&
            this.asset.peerReviewed === 'YES'
        );
    }

    get files() {
        return this.depositFormDataService.filesForm.value;
    }

    get resourceType() {
        return this.depositFormDataService.resourceType;
    }

    goToTitle() {
        this.goToSection('title');
    }

    goToTopics() {
        this.goToSection('topics');
    }

    goToFiles() {
        this.goToSection('files');
    }

    goToSection(sectionName) {
        this.jumpToSection.emit(sectionName);
    }

    displayPublicationDetails() {
        if (
            this.asset.date ||
            this.asset.relationTitle ||
            this.asset.isPartOfIssn ||
            this.asset.isPartOfIsbnForDisplay ||
            this.asset.isPartOfVolume ||
            this.asset.isPartOfIssue ||
            this.asset.isPartOfPages ||
            this.asset.publisher ||
            this.asset.isPartOfDoi ||
            this.asset.edition ||
            this.asset.page ||
            this.asset.series ||
            this.asset.publicationPlaceForDisplay
        ) {
            return true;
        }
        return false;
    }

    displaySubtitle() {
        if (
            this.depositFormDataService.isPublicationBook() &&
            this.asset.subtitle
        ) {
            return true;
        }
        return false;
    }

    displayMethods() {
        if (this.depositFormDataService.isDataset() && this.asset.methods) {
            return true;
        }
        return false;
    }

    displayCoverage() {
        if (this.depositFormDataService.isDataset() && this.asset.coverage) {
            return true;
        }
        return false;
    }

    displayTechInformation() {
        if (
            this.depositFormDataService.isDataset() &&
            this.asset.technicalInformation
        ) {
            return true;
        }
        return false;
    }

    formattedDate(date) {
        let dateResult = '';
        if (date.length === 4) {
            dateResult = date;
        } else if (date.length === 6) {
            const yearMonth = new Date(
                this.depositFormDataService.mainForm.get('year').value,
                this.depositFormDataService.mainForm.get('month').value,
                1
            );
            dateResult = DateUtils.getFullMomentDateAsString(
                yearMonth,
                this.systemDateFormatService.getSystemDateFormat()
            );
        } else {
            dateResult = DateUtils.getFullMomentDateAsString(
                date,
                this.systemDateFormatService.getSystemDateFormat()
            );
        }
        return dateResult;
    }

    getDateTitleBasedOnStatus() {
        const status = this.asset.status;
        return 'research.summary.asset.date.' + status;
    }

    displayPublicationDate() {
        const status = this.asset.status;
        if (
            this.depositFormDataService.isPublicationForm() &&
            this.asset.date &&
            status !== Status.UNPUBLISHED
        ) {
            return true;
        }
        return false;
    }

    displayGeoLocationInfo() {
        if (
            this.depositFormDataService.isDataset() &&
            (this.asset.address ||
                (this.asset.pointLatitude && this.asset.pointLongitude) ||
                (this.asset.boundingBoxSouth &&
                    this.asset.boundingBoxWest &&
                    this.asset.boundingBoxNorth &&
                    this.asset.boundingBoxEast))
        ) {
            return true;
        }
        return false;
    }

    displayConferenceDetails() {
        if (
            (this.depositFormDataService.isPublicationConferenceProceedings() ||
                this.depositFormDataService.isConference()) &&
            (this.asset.conferenceName ||
                this.asset.conferenceNumber ||
                this.asset.conferenceLocation ||
                this.asset.conferenceStartDate)
        ) {
            return true;
        }
        return false;
    }

    displayDatesSection() {
        if (
            (this.depositFormDataService.isDataset() ||
                this.depositFormDataService.isDefaultForm() ||
                this.depositFormDataService.isConference() ||
                this.depositFormDataService.isPresentation()) &&
            (this.asset.datePublished ||
                this.asset.dateCollectedStart ||
                this.asset.dateCreatedStart ||
                this.asset.datePresented)
        ) {
            return true;
        }
        return false;
    }

    displayRelationTitle() {
        if (
            (this.depositFormDataService.isPublicationJournalArticle() ||
                this.depositFormDataService.isPublicationBookReview() ||
                this.depositFormDataService.isPublicationBookChapter() ||
                this.depositFormDataService.isPublicationMoreInformation() ||
                this.depositFormDataService.isPublicationConferenceProceedings()) &&
            this.asset.relationTitle
        ) {
            return true;
        }
        return false;
    }

    displayIsPartOfIssn() {
        if (
            (this.depositFormDataService.isPublicationJournalArticle() ||
                this.depositFormDataService.isPublicationBookReview() ||
                this.depositFormDataService.isPublicationConferenceProceedings() ||
                this.depositFormDataService.isPublicationMoreInformation()) &&
            this.asset.isPartOfIssn
        ) {
            return true;
        }
        return false;
    }

    displayIsPartOfIsbnForDisplay() {
        if (
            (this.depositFormDataService.isPublicationBookChapter() ||
                this.depositFormDataService.isPublicationConferenceProceedings()) &&
            this.asset.isPartOfIsbnForDisplay
        ) {
            return true;
        }
        return false;
    }

    displayIsPartOfVolume() {
        if (
            (this.depositFormDataService.isPublicationJournalArticle() ||
                this.depositFormDataService.isPublicationBookReview() ||
                this.depositFormDataService.isPublicationBookChapter() ||
                this.depositFormDataService.isPublicationMoreInformation() ||
                this.depositFormDataService.isPublicationConferenceProceedings()) &&
            this.asset.isPartOfVolume
        ) {
            return true;
        }
        return false;
    }

    displayIsPartOfIssue() {
        if (
            (this.depositFormDataService.isPublicationJournalArticle() ||
                this.depositFormDataService.isPublicationBookReview() ||
                this.depositFormDataService.isPublicationBookChapter() ||
                this.depositFormDataService.isPublicationMoreInformation() ||
                this.depositFormDataService.isPublicationConferenceProceedings()) &&
            this.asset.isPartOfIssue
        ) {
            return true;
        }
        return false;
    }

    displayIsPartOfPages() {
        if (
            (this.depositFormDataService.isPublicationJournalArticle() ||
                this.depositFormDataService.isPublicationBookReview() ||
                this.depositFormDataService.isPublicationBookChapter() ||
                this.depositFormDataService.isPublicationMoreInformation() ||
                this.depositFormDataService.isPublicationConferenceProceedings()) &&
            this.asset.isPartOfPages
        ) {
            return true;
        }
        return false;
    }

    displayIsPartOfDoi() {
        if (
            (this.depositFormDataService.isPublicationBookChapter() ||
                this.depositFormDataService.isPublicationConferenceProceedings()) &&
            this.asset.isPartOfPages
        ) {
            return true;
        }
        return false;
    }

    displayEventDetails() {
        if (
            ResourceTypeUtils.isPresentation(this.asset.type) &&
            (this.asset.eventName ||
                this.asset.eventType ||
                this.asset.eventLocation ||
                this.asset.eventStartDate)
        ) {
            return true;
        }
        return false;
    }

    displayPresentationDetails() {
        if (
            (ResourceTypeUtils.isConferencePresentation(this.asset.type) ||
                ResourceTypeUtils.isPresentation(this.asset.type)) &&
            (this.asset.audience || this.asset.pages)
        ) {
            return true;
        }
        return false;
    }

    isBook() {
        return this.depositFormDataService.isPublicationBook();
    }
}
