import { ComponentRef, Injectable, Injector } from '@angular/core';
import {
    Overlay,
    OverlayConfig,
    OverlayContainer,
    OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { DepositFormOverlayComponent } from './deposit-form-overlay.component';
import { DepositFormOverlayRef } from './deposit-form-overlay.ref';
import { DEPOSIT_FORM_DIALOG_DATA } from './deposit-form-overlay.tokens';
import { DepositFormService } from '../deposit-form.service';
import { StorageUtilService } from '../../core/storage-util.service';
import { DepositFormDataService } from '../deposit-form-data.service';
import { AmazonInfo } from '../../shared/interfaces/amazon-info.interface';

interface DepositFormOverlayConfig {
    panelClass?: string;
    hasBackdrop?: boolean;
    backdropClass?: string;
    maxWidth?: string | number;
    minHeight?: string | number;
}

const DEFAULT_CONFIG: DepositFormOverlayConfig = {
    hasBackdrop: true,
    backdropClass: 'mulo-backdrop-circular',
    panelClass: 'exl-deposit-form-pane',
    maxWidth: 960,
};

@Injectable()
export class DepositFormOverlayService {
    dialogRef: DepositFormOverlayRef;

    fileUploadRegex = new RegExp('/esploro/(.+)/');

    constructor(
        private injector: Injector,
        private overlay: Overlay,
        private overlayContainer: OverlayContainer,
        private storageUtilService: StorageUtilService,
        private depositFormService: DepositFormService,
        public depositFormDataService: DepositFormDataService
    ) {}

    open(config: DepositFormOverlayConfig = {}) {
        const overlayConfig = { ...DEFAULT_CONFIG, ...config };
        const overlayRef = this.createOverlay(overlayConfig);
        const dialogRef = new DepositFormOverlayRef(overlayRef);
        const overlayComponent = this.attachDialogContainer(
            overlayRef,
            overlayConfig,
            dialogRef
        );
        dialogRef.componentInstance = overlayComponent;
        this.dialogRef = dialogRef;
        this.depositFormService.getAmazonInfo().subscribe((data) => {
            //this.storageUtilService.setObject('amazon_info', data);
            const amazonInfo = data as AmazonInfo;
            this.depositFormService.amazonInfo = amazonInfo;
            const match = this.fileUploadRegex.exec(amazonInfo.uploadPath);
            if (match) {
                this.depositFormDataService.updateFilePath(match[1]);
            }
        });
        // Hide backdrop content from screen readers
        this.dialogRef.hideNonDialogContentFromAssistiveTechnology(
            this.overlayContainer.getContainerElement()
        );
        return dialogRef;
    }

    close() {
        this.dialogRef.close();
    }

    createOverlay(config: DepositFormOverlayConfig) {
        const overlayConfig = this.getOverlayConfig(config);
        return this.overlay.create(overlayConfig);
    }

    attachDialogContainer(
        overlayRef: OverlayRef,
        config: DepositFormOverlayConfig,
        dialogRef: DepositFormOverlayRef
    ) {
        const injector = this.createInjector(config, dialogRef);
        const containerPortal = new ComponentPortal(
            DepositFormOverlayComponent,
            null,
            injector
        );
        const containerRef: ComponentRef<DepositFormOverlayComponent> =
            overlayRef.attach(containerPortal);
        return containerRef.instance;
    }

    createInjector(
        config: DepositFormOverlayConfig,
        dialogRef: DepositFormOverlayRef
    ): PortalInjector {
        const injectionTokens = new WeakMap();
        injectionTokens.set(DepositFormOverlayRef, dialogRef);
        injectionTokens.set(DEPOSIT_FORM_DIALOG_DATA, config);
        return new PortalInjector(this.injector, injectionTokens);
    }

    getOverlayConfig(config: DepositFormOverlayConfig): OverlayConfig {
        const positionStrategy = this.overlay
            .position()
            .global()
            .centerHorizontally();

        const overlayConfig = new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            maxWidth: config.maxWidth,
            minHeight: config.minHeight,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy,
        });
        return overlayConfig;
    }
}
