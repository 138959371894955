import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { PlainTextInputComponent } from './plain-text-input.component';
import { TextFieldModule } from '@angular/cdk/text-field';

@NgModule({
    imports: [SharedModule, TextFieldModule],
    declarations: [PlainTextInputComponent],
    exports: [PlainTextInputComponent],
})
export class PlainTextInputModule {}
