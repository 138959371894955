import { NgModule } from '@angular/core';
import { SuccessSnackbarComponent } from './success-snackbar/success-snackbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { CookiesSnackbarComponent } from './cookies-snackbar/cookies-snackbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AnimatedCheckmarkComponent } from '@exl-ng/mulo-common';

@NgModule({
    imports: [
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        AnimatedCheckmarkComponent,
    ],
    declarations: [SuccessSnackbarComponent, CookiesSnackbarComponent],
    exports: [SuccessSnackbarComponent],
})
export class SnackbarsModule {}
