import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
} from '@angular/core';
import { HeaderNavItemComponent } from '../header-nav-item/header-nav-item.component';
import {
  NgResizeObserver,
  ngResizeObserverProviders,
} from 'ng-resize-observer';
import { componentDestroyed } from '@exl-ng/mulo-core';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs';
import { NgIf } from '@angular/common';
import { HeaderNavComponent } from '../header-nav/header-nav.component';

@Component({
    selector: 'mulo-header-links',
    templateUrl: './header-links.component.html',
    styleUrls: ['./header-links.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [...ngResizeObserverProviders],
    standalone: true,
    imports: [
        HeaderNavComponent,
        NgIf,
        HeaderNavItemComponent,
    ],
})
export class HeaderLinksComponent implements AfterViewInit, OnDestroy {
  @Input() linkMaxWidth = 160;
  @Input() separators: 'dots' | 'lines' | 'none' = 'dots';
  @Input('aria-label') ariaLabel: string;
  @Output() itemsInMenu = new EventEmitter<number>();
  @ContentChildren(HeaderNavItemComponent, { read: ElementRef })
  links: QueryList<ElementRef>;
  @HostBinding('class') class = 'mulo-header-links';
  @HostBinding('class.has-dots-separators') hasDotsSeparators =
    this.separators === 'dots';
  @HostBinding('class.has-lines-separators') hasLinesSeparators =
    this.separators === 'lines';

  showMoreButton = false;

  width$ = this.resize$.pipe(
    map((entry) => entry.target.clientWidth),
    distinctUntilChanged(),
    tap(() => this.cd.detectChanges()),
    takeUntil(componentDestroyed(this))
  );

  constructor(
    private resize$: NgResizeObserver,
    private cd: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.width$.subscribe((availWidth) => {
      const array = this.links.toArray();
      let currWidth = 48;
      let itemsInMenu = 0;

      for (let i = 0; i < array.length; i++) {
        const elm = array[i].nativeElement;
        this.renderer.removeStyle(elm, 'display');
        currWidth += elm.clientWidth;

        this.renderer.setStyle(elm, 'max-width', `${this.linkMaxWidth}px`);
        const showMoreButton = currWidth > availWidth;
        this.showMoreButton = showMoreButton;
        if (showMoreButton) {
          this.renderer.setStyle(elm, 'display', 'none');
        } else {
          itemsInMenu = array.length - 1 - i;
        }
      }
      this.itemsInMenu.emit(itemsInMenu);
    });
  }

  ngOnDestroy() {}
}
