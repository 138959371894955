import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  NgZone,
  Optional,
  Output,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { Platform } from '@angular/cdk/platform';
import { IntervalerDirective } from '../../directives/intervaler.directive';
import { NgIf } from '@angular/common';
import { MatRipple } from '@angular/material/core';

@Component({
    selector: 'mulo-funky-button, [mulo-funky-button]',
    templateUrl: './funky-button.component.html',
    styleUrls: ['./funky-button.component.scss'],
    host: {
        class: 'mulo-funky-button has-icon is-loud is-funky-cool is-rounded mdc-button mdc-button--raised mat-mdc-raised-button mat-mdc-button-base',
        '[class.jelly-on-hover]': 'jellyOnHover',
        '[class.is-large]': 'isLarge',
        '[disabled]': 'disabled',
        '[attr.aria-label]': 'ariaLabel',
        '(click)': 'buttonClick.emit($event)',
    },
    standalone: true,
    imports: [
        MatRipple,
        NgIf,
        IntervalerDirective,
    ],
})
export class FunkyButtonComponent extends MatButton {
  @Input() gleam = true;
  @Input('class') classList = '';
  @Input() ariaLabel: string;
  @Output('clicked') buttonClick = new EventEmitter();

  @HostBinding('class.jelly-on-hover') @Input() jellyOnHover = true;
  @Input() isLarge = false;

  constructor(
    platform: Platform,
    public _elementRef: ElementRef,
    _ngZone: NgZone,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) animationMode: string
  ) {
    super(_elementRef, platform, _ngZone, animationMode);
  }
}
