import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Status } from '../configurations/status.constant';
import { ResourceTypeUtils } from '../utils/resource-type.utils';

export const ValidateDate: ValidatorFn = (group: UntypedFormGroup) => {
    const resourceType = group.get('type').value;
    if (
        ((ResourceTypeUtils.isPublicationJournalArticle(resourceType) ||
            ResourceTypeUtils.isPublicationBookReview(resourceType) ||
            ResourceTypeUtils.isPublicationBookChapter(resourceType) ||
            ResourceTypeUtils.isPublicationBook(resourceType) ||
            ResourceTypeUtils.isPublicationConferenceProceedings(
                resourceType,
            )) &&
            !group.get('date').value &&
            group.get('status').value &&
            group.get('status').value !== Status.UNPUBLISHED) ||
        (!group.get('date').value && group.get('seasonPublished').value)
    ) {
        return { validDate: true };
    }
    return null;
};
