<mat-list class="authors-list mulo-elevation-list" role="list">
    <div exl-transition-group="'flip-list'" style="position: relative">
        @for (author of authors?.value; track author.id) {
            <mat-list-item
                [@growInAnimation]="{
                    value: 'enter',
                    params: { endHeight: '64px', origin: 'center center' }
                }"
                [@growOutAnimation]="{
                    value: 'leave',
                    params: { origin: 'center center' }
                }"
                class="exl-default-list-item"
                exlTransitionGroupItem
                role="listitem"
            >
                <mat-icon
                    [svgIcon]="author.source === 'groupAuthor' ? 'organization' : 'account'"
                    matListItemIcon
                ></mat-icon>
                <exl-badge-auto-populated
                    *ngIf="isAutoPopulated(author.id)"
                    [tooltip]="'research.auto.populate.tooltip' | customTranslate"
                    color="primary"
                >
                </exl-badge-auto-populated>
                <span class="author-line1" matListItemTitle>
                    <span [muloElementAsHeading]="4" [id]="'author-name-' + $index" class="author-name semibold">{{
                        author.name
                    }}</span>
                    <span class="author-separator"> — </span>
                    <mat-form-field appearance="outline" class="author-role is-inline no-subscript">
                        <mat-label>{{ 'research.people.role' | customTranslate }}</mat-label>
                        <mat-select
                            (ngModelChange)="author.role = $event; changeRole($index, $event)"
                            [attr.aria-description]="'research.people.role.ariaLabel' | translate: { '0': author.name }"
                            [ngModel]="author.role"
                        >
                            <mat-option *ngFor="let role of rolesTranslations" [value]="role[0]">
                                {{ role[1] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </span>

                @if (
                    author.affiliationCode ||
                    author.affiliationCodeList?.length > 0 ||
                    author.orcid ||
                    author.sourceAffiliation
                ) {
                    <span class="author-line2" matListItemLine>
                        <small>
                            @if (author.affiliationCodeList?.length > 0) {
                                <span
                                    >{{
                                        author.affiliationCodeList[0]
                                            | defaultTranslate: { default: author.affiliationNameList[0] }
                                    }}
                                    @if (author.affiliationCodeList?.length > 1) {
                                        + {{ author.affiliationCodeList.length - 1 }} more
                                    }
                                </span>
                            } @else if (author.affiliationCode) {
                                <span>{{
                                    author.affiliationCode | defaultTranslate: { default: author.affiliationName }
                                }}</span>
                            }
                            @if (author.orcid) {
                                <span>
                                    @if (author.affiliationCode || author.affiliationCodeList?.length > 0) {
                                        <span class="author-separator"> / </span>
                                    }
                                    <mulo-orcid-link
                                        [idDescription]="'research.general.orcid.profile.tooltip' | translate"
                                        [logoTooltip]="'research.general.orcid.website.tooltip' | translate"
                                        [orcidId]="author.orcid"
                                    ></mulo-orcid-link
                                ></span>
                            }
                            @if (
                                !author.affiliationCode &&
                                author.affiliationCodeList?.length === 0 &&
                                author.sourceAffiliation
                            ) {
                                <span>{{ author.sourceAffiliation }}</span>
                            }
                        </small>
                    </span>
                }

                <div class="btns-wrap" matListItemMeta>
                    @if (author.source !== 'groupAuthor') {
                        <button
                            (click)="editAuthor.emit(author)"
                            [attr.aria-describedby]="'author-name-' + $index"
                            [attr.aria-label]="'research.aria.icon.button.edit' | translate"
                            mat-icon-button
                            type="button"
                        >
                            <mat-icon svgIcon="pencil"></mat-icon>
                        </button>
                    }
                    @if (!$first) {
                        <button
                            (click)="onAuthorShift($index, 'up')"
                            [attr.aria-describedby]="'author-name-' + $index"
                            [attr.aria-label]="'research.aria.deposit.authors.author.shift.up' | translate"
                            mat-icon-button
                            type="button"
                        >
                            <mat-icon svgIcon="arrow-up"></mat-icon>
                        </button>
                    }
                    @if (!$last) {
                        <button
                            (click)="onAuthorShift($index, 'down')"
                            [attr.aria-describedby]="'author-name-' + $index"
                            [attr.aria-label]="'research.aria.deposit.authors.author.shift.down' | translate"
                            mat-icon-button
                            type="button"
                        >
                            <mat-icon svgIcon="arrow-down"></mat-icon>
                        </button>
                    }

                    @if (!author.owner) {
                        <button
                            (click)="onAuthorRemove($index)"
                            [attr.aria-describedby]="'author-name-' + $index"
                            [attr.aria-label]="'research.aria.deposit.authors.author.remove' | translate"
                            mat-icon-button
                            type="button"
                        >
                            <mat-icon svgIcon="close"></mat-icon>
                        </button>
                    }
                </div>
            </mat-list-item>
        }
    </div>
</mat-list>
