import { Component, Input } from '@angular/core';
import { SpinnerComponent } from '../spinner';

@Component({
  standalone: true,
  selector: 'mulo-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  host: { class: 'mulo-loader' },
  imports: [SpinnerComponent],
})
export class LoaderComponent {
  @Input() message: string;
  @Input() orientation: 'vertical' | 'horizontal' = 'vertical';
  @Input() zIndex: number;

  constructor() {}
}
