import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { CustomerParameters } from '../shared/configurations/customer-parameters.constant';
import { UrlUtils } from '../shared/utils/url.utils';
import { ConfigurationHandlerService } from './configuration-handler.service';
import { JwtUtilService } from './jwt-util.service';

@Injectable()
export class EntityEditableGuard  {
    institutionCode: string;
    constructor(
        private configurationHandlerService: ConfigurationHandlerService,
        private jwtUtilService: JwtUtilService,
        private router: Router
    ) {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route.queryParams?.mode !== 'edit') {
            return true;
        }
        const entity = route.routeConfig?.path;

        if (
            !this.jwtUtilService.isLoggedIn() ||
            !this.canEditByConfig(entity)
        ) {
            const url = entity + '/' + route.firstChild.url.join('/');
            this.router.navigate([url], {
                queryParams: { institution: this.institutionCode },
            });
        }
        return true;
    }

    canEditByConfig(entity: string) {
        switch (entity) {
            case 'project': {
                return this.configurationHandlerService.canEditEntityByConfig(
                    CustomerParameters.ESPLORO_PROJECTS,
                    'addProjects'
                );
            }
            case 'activity': {
                return this.configurationHandlerService.canEditEntityByConfig(
                    CustomerParameters.ESPLORO_ACTIVITIES,
                    'addActivities'
                );
            }
            default:
                return false;
        }
    }
}
