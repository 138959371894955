import { NgModule } from '@angular/core';
import {ChipsListComponent} from './chips-list.component';

@NgModule({
    imports: [
    ],
    declarations: [
        ChipsListComponent
    ],
    exports: [
        ChipsListComponent
    ]
})
export class ChipsListModule { }
