import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Inject,
    OnInit,
} from '@angular/core';
import {
    animate,
    AnimationEvent,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { DepositFormOverlayRef } from './deposit-form-overlay.ref';
import { DEPOSIT_FORM_DIALOG_DATA } from './deposit-form-overlay.tokens';
import { ConfirmDialogComponent } from '../../parts/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { OutQuint } from '@exl-ng/mulo-common';
import { DepositFormDataService } from '../deposit-form-data.service';
import { DepositFormService } from '../deposit-form.service';
import { ProfileOutputService } from '../../profile/profile-output/profile-output.service';
import { RouterService } from '../../core/router.service';
import { ProfileService } from '../../profile/profile.service';
import { ResearcherOutputListFacet } from '../../shared/interfaces/researcher-output-list-facet.interface';
import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import { TranslateService } from '@ngx-translate/core';

const ESCAPE = 27;

@Component({
    selector: 'esp-deposit-form-overlay',
    templateUrl: './deposit-form-overlay.component.html',
    styleUrls: ['./deposit-form-overlay.component.scss'],
    animations: [
        trigger('slideContent', [
            state(
                'void',
                style({
                    transform: 'scale3d(.8,.8,1)',
                    opacity: 0,
                })
            ),
            state(
                'enter',
                style({
                    transform: 'scale3d(1,1,1)',
                    opacity: 1,
                })
            ),
            state(
                'leave',
                style({
                    transform: 'scale3d(1.05,1.05,1.05)',
                    opacity: 0,
                })
            ),
            transition('* => enter', animate('600ms 450ms ' + OutQuint)),
            transition('* => leave', animate('250ms ease-in')),
        ]),
    ],
})
export class DepositFormOverlayComponent implements OnInit {
    animationState: 'void' | 'enter' | 'leave' = 'enter';
    animationStateChanged = new EventEmitter<AnimationEvent>();
    confirmationDialogOpen = false;

    @HostBinding() role = 'dialog';
    @HostBinding('attr.aria-modal') ariaModal = true;
    @HostBinding('attr.aria-label') ariaLabel = 'Researcher deposit form';

    @HostListener('keydown.Escape', ['$event']) private handleKeydown(
        event: KeyboardEvent
    ) {
        event.stopPropagation();
        if (this.confirmDialog.openDialogs.length === 0) {
            this.onDialogCloseAttempt();
        }
    }

    constructor(
        private overlayRef: DepositFormOverlayRef,
        @Inject(DEPOSIT_FORM_DIALOG_DATA) public image: any,
        public confirmDialog: MatDialog,
        public depositFormDataService: DepositFormDataService,
        public depositFormService: DepositFormService,
        private profileOutputService: ProfileOutputService,
        private routerService: RouterService,
        private profileService: ProfileService,
        private focusTrap: ConfigurableFocusTrapFactory,
        private xl8Svc: TranslateService,
        private elementRef: ElementRef
    ) {
        xl8Svc
            .get('research.title.deposit.form')
            .subscribe((_) => (this.ariaLabel = _));
    }
    ngOnInit(): void {
        this.focusTrap
            .create(this.elementRef.nativeElement)
            .focusInitialElement();
    }

    closeOverlay() {
        this.confirmationDialogOpen = false;
        this.overlayRef.close();
        this.depositFormDataService.resetForm();
    }

    onOverlayCloseAttempt(discard?: boolean) {
        if (discard) {
            this.handleDepositDelete();
        }
        this.closeOverlay();
    }

    onAnimationStart(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    onAnimationDone(event: AnimationEvent) {
        this.animationStateChanged.emit(event);
    }

    startExitAnimation() {
        this.animationState = 'leave';
    }

    saveDraft() {
        const depositData = this.depositFormService.buildDepositData();
        this.depositFormService.saveDraft(depositData.value, false).subscribe(
            () => {
                if (this.routerService.isProfileRoute()) {
                    this.refreshDataInOutputListAfterSave();
                }
            },
            (error) => {}
        );
    }

    refreshDataInOutputListAfterSave() {
        setTimeout(() => {
            this.profileOutputService
                .getOutputListFacets(this.profileOutputService.isPublicPage())
                .subscribe((data) => {
                    const isListEmpty =
                        this.profileOutputService.facets.length === 0;
                    const facets = data as ResearcherOutputListFacet[];
                    if (facets && facets.length > 0) {
                        this.profileOutputService.saveFacets(data);
                        if (isListEmpty) {
                            //if the is empty- need to navigate to the first facet
                            this.profileOutputService.refreshData$.next(true);
                        } else {
                            this.profileOutputService.refreshData$.next(false);
                        }
                    }
                });
        }, this.profileOutputService.UPDATE_DATA_TIMEOUT);
    }

    onDialogCloseAttempt() {
        if (this.depositFormDataService.category.value) {
            const isDraft = this.depositFormDataService.isDraft;
            const dialog = this.openConfirmationDialog(isDraft);
            this.confirmationDialogOpen = true;
            dialog.afterClosed().subscribe((result) => {
                this.confirmationDialogOpen = false;
                if (result === 'ok') {
                    this.saveDraft();
                    this.closeOverlay();
                } else if (result === 'discard' && !isDraft) {
                    this.handleDepositDelete();
                    this.closeOverlay();
                }
            });
        } else {
            this.closeOverlay();
        }
    }

    openConfirmationDialog(isDraft) {
        return this.confirmDialog.open(ConfirmDialogComponent, {
            maxWidth: '500px',
            data: {
                title: isDraft
                    ? 'research.popup.exit.draft.title'
                    : 'research.popup.exit.save.draft',
                message: isDraft
                    ? 'research.popup.exit.draft.message'
                    : 'research.popup.exit.newdeposit.title',
                actions: {
                    discard: isDraft
                        ? ''
                        : 'research.popup.exit.newdeposit.discard',
                    confirm: 'research.popup.exit.newdeposit.save',
                    cancel: 'research.popup.exit.keep.editing',
                },
                actionFocus: 'confirm',
            },
        });
    }

    discard() {
        if (this.depositFormService.mmsToDeleteInDiscard) {
            this.depositFormService
                .deleteDeposit(this.depositFormService.mmsToDeleteInDiscard)
                .subscribe(() => {});
        }
    }

    private handleDepositDelete() {
        //save the mms in the service- after "closeOverlay" the form will be reset
        this.depositFormService.mmsToDeleteInDiscard =
            this.depositFormDataService.mmsId.value;
        this.discard();
    }
}
