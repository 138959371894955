<exl-centered-block-layout width="none">
    <div class="header">
        <mulo-brand></mulo-brand>
        <h1 class="title margin-bottom-zero">
            {{ 'research.login.proxy.choose.account' | translate }}
        </h1>
    </div>
    <div class="main">
        <div class="signed-in-as">
            <span>
                {{ 'research.login.proxy.sign.in.message' | translate }}
                <span class="semibold">{{ signInName }}</span>
            </span>
            <button (click)="signOut()" color="primary" mat-button>
                {{ 'research.login.proxy.sign.out' | translate }}
            </button>
        </div>

        <button
            (click)="loginToOwnDahboard()"
            *ngIf="accessToResearcherCenter"
            class="own-profile-btn is-large"
            color="primary"
            mat-raised-button
        >
            {{ 'research.login.proxy.proceed.to.profile' | translate }}
            <mat-icon class="mat-icon-rtl-mirror" svgIcon="chevron-right"></mat-icon>
        </button>

        <div class="margin-bottom-sm">
            <h2 class="margin-bottom-zero semibold">
                {{ 'research.login.proxy.manage.profiles.title' | translate }}
            </h2>
            <span [innerHTML]="'research.login.proxy.sign.in.as' | translate" class="italic"></span>
        </div>

        <exl-person-brief
            (clicked)="onPersonClick(researcher)"
            (muloMultipleEvents)="onPersonClick(researcher)"
            *ngFor="let researcher of researchersProxyFor"
            @growInAnimation
            cdkMonitorElementFocus
            eventOrigins="keydown.enter, keydown.space"
            role="button"
            style="width: 100%"
        >
            <mulo-avatar
                [description]="'research.alt.profile.img.with.name' | translate : { value: researcher.displayName }"
                [image]="researchersPhotos[researcher.userID]"
                [initials]="getInitial(researcher)"
                [position]="{ x: 0, y: 20 }"
                [radius]="4"
                size="60"
            ></mulo-avatar>

            <span *ngIf="researcher.displayName" [muloElementAsHeading]="4" [titleAsLink]="false" exlPersonBriefTitle>
                {{ researcher.displayName }}
            </span>
            <p>
                <span *ngIf="researcher.displayTitle; else affiliationTitle">
                    {{ researcher.displayTitle }}
                </span>
                <ng-template #affiliationTitle>
                    <span *ngIf="showTitleOrPosition(researcher)">
                        {{
                            affiliationService.getAffTitleOrPositionOrResearcherPosition(
                                researcher.currentAffiliation,
                                researcher.position
                            )
                        }},
                    </span>
                    <span
                        *ngIf="
                            researcher.currentAffiliation &&
                            researcher.currentAffiliation.organizationCode &&
                            researcher.currentAffiliation.organizationCode !== mainInstCode
                        "
                        >{{ researcher.currentAffiliation.organizationCode | translate }},
                    </span>
                    <span>{{ mainInstCode | translate }}</span>
                </ng-template>
            </p>
        </exl-person-brief>
    </div>

    <!-- </div> -->
</exl-centered-block-layout>
