import { Injectable, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject, Observable, of } from 'rxjs';

export interface SidenavOptions {
  position?: 'start' | 'end';
  focusOn?: string;
  autofocus?: boolean;
}

@Injectable({ providedIn: 'root' })
export class SideNavService {
  content: string;
  elementToFocusOn: string = null;

  public content$: Observable<any>;
  public focus$ = new BehaviorSubject<string>('');
  public get isOpen() {
    return this.panels.some((p) => this.panel(p.id).opened);
  }

  private _content = new BehaviorSubject<any>(null);
  private panels = new Array<{
    id: string;
    panel: MatSidenav;
    state: boolean;
  }>();

  constructor() {
    this.content$ = this._content.asObservable();
  }

  public setSidenav(sidenav: MatSidenav, id: string) {
    if (this.panels && this.panels.find((panel) => panel.id === id)) {
      this.panels.filter((panel) => panel.id === id)[0].panel = sidenav;
    } else {
      this.panels.push({ id, panel: sidenav, state: false });
    }
  }

  public unloadSidenav(id: string) {
    const panel = this.panels.findIndex((p) => p.id === id);
    this.panels.splice(panel, 1);
  }

  public open(
    id: string,
    template?: ViewContainerRef,
    options?: SidenavOptions
  ): void {
    this.processPanel(id, template, options);
    this.panel(id).autoFocus = !options.focusOn;
    this.elementToFocusOn = options.focusOn ? options.focusOn : null;
    this.panel(id).open();
  }

  public close(
    id: string,
    template?: ViewContainerRef,
    options?: SidenavOptions
  ): void {
    this.processPanel(id, template, options);
    this.panel(id)?.close();
  }

  public toggle(
    id: string,
    template?: ViewContainerRef,
    options?: SidenavOptions
  ): void {
    if (!this.panel(id)?.opened) {
      this.processPanel(id, template, options);
      this.panel(id).autoFocus = !options.focusOn;
      this.elementToFocusOn = options.focusOn ? options.focusOn : null;
    }
    this.panel(id)?.toggle();
  }

  onSidenavAnimationDone(state: 'open' | 'void' | string) {
    if (state === 'open') {
      this.focus$.next(this.elementToFocusOn);
    } else if (state === 'void') {
      this.focus$.next(null);
    }
  }

  availblePanels$() {
    return of(this.panels.map((panel) => panel.id));
  }

  private processPanel(id, template, options) {
    if (template) {
      this._content.next({ id, template });
    }
    if (options && this.panel(id)) {
      Object.keys(options).forEach((option) => {
        this.panel(id)[option] = options[option];
      });
    }
  }

  private panel(id) {
    return this.panels.find((panel) => panel.id === id)?.panel;
  }
}
