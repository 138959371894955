import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayClean',
    standalone: true
})
export class ArrayCleanPipe implements PipeTransform {
  transform(value: any[], includeBlankStrings = false): any[] {
    return value.filter(
      (_) => _ !== null && _ !== undefined && (includeBlankStrings || _ !== '')
    );
  }
}

