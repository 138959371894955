import { DOCUMENT } from '@angular/common';
import {
    Component,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    Inject,
    OnDestroy,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ReplaySubject, takeUntil } from 'rxjs';
import { ConfigurationHandlerService } from '../../../core/configuration-handler.service';
import { NotificationService } from '../../../core/notification.service';
import { SnackbarService } from '../../../core/snackbar.service';
import { ConfirmDialogComponent } from '../../../parts/dialogs/confirm-dialog/confirm-dialog.component';
import { SuccessSnackbarComponent } from '../../../parts/snackbars/success-snackbar/success-snackbar.component';
import { ProfileAssetDisplayEnrichmentService } from '../../../profile/profile-asset-display-enrichment.service';
import { DepositForm } from '../../../shared/configurations/deposit-form.constant';
import { FullAssetPage } from '../../../shared/configurations/full-asset-page.constant';
import { SelectedResearchRecord } from '../../../shared/interfaces/research-record.interface';
import { UrlUtils } from '../../../shared/utils/url.utils';

@Component({
    selector: 'esp-deposit-selected-research',
    templateUrl: './deposit-selected-research.component.html',
    styleUrls: ['./deposit-selected-research.component.scss'],
})
export class DepositSelectedResearchComponent implements OnInit, OnDestroy {
    private onDestroy$ = new ReplaySubject<void>();
    safeUrl: any;

    @Input() research: SelectedResearchRecord;
    @Output() removeSelected: EventEmitter<any> = new EventEmitter();
    @Output() closeDeposit: EventEmitter<any> = new EventEmitter();

    @ViewChild('iframeTemplate') iframeTemplate;

    constructor(
        private profileAssetService: ProfileAssetDisplayEnrichmentService,
        private dialogRef: MatDialog,
        private sanitizer: DomSanitizer,
        private configurationHandlerService: ConfigurationHandlerService,
        private communicationService: NotificationService,
        private snackbarService: SnackbarService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnInit() {
        this.safeUrl = this.getViewerLink();
    }

    get isInternalResults(): boolean {
        return this.research?.isInternalResults;
    }

    get researchExistsInOutputList(): boolean {
        return this.isInternalResults && this.research.isUserCollaborator;
    }

    get message(): string {
        return this.researchExistsInOutputList
            ? 'research.selected.brief.message.user.exists'
            : 'research.selected.brief.message';
    }

    get actionButtonLabel() {
        return this.researchExistsInOutputList
            ? 'research.selected.brief.button.label.cancel'
            : 'research.selected.brief.button.label';
    }

    onRemoveButtonClick() {
        this.removeSelected.emit();
    }

    getAuthorsAsArray() {
        if (this.research?.creatorOrContributor) {
            return this.research.creatorOrContributor.split(
                DepositForm.AUTHORS_DELIMITER
            );
        }
        return [];
    }

    duplicateAction(): void {
        this.openConfirmationDialog(!this.researchExistsInOutputList)
            .afterClosed()
            .subscribe((res) => {
                if (res === 'ok') {
                    if (!this.researchExistsInOutputList) {
                        this.communicationService
                            .addResearcherToAssetRequest(
                                this.research.internalRecordMmsId.toString()
                            )
                            .subscribe();
                        this.success();
                    }
                    this.closeDeposit.emit(true);
                }
            });
    }

    openViewer() {
        this.dialogRef
            .open(this.iframeTemplate, { panelClass: 'esp-viewer' })
            .afterClosed()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe();
    }

    private getViewerLink() {
        const viewLinkUrl = this.getAssetLinkUrl();
        viewLinkUrl.searchParams.set('mode', 'quickaccess');
        return this.sanitizer.bypassSecurityTrustResourceUrl(
            viewLinkUrl.toString()
        );
    }

    private getAssetLinkUrl() {
        const assetLink = this.document.location.origin.concat(
            this.getLinkPath()
        );
        const viewLinkUrl = new URL(assetLink);
        return viewLinkUrl;
    }

    private getLinkPath() {
        const isEsploroNewPortal =
            this.configurationHandlerService.getCustomerParameter(
                'esploro_new_portal'
            ) === 'true';
        const linkPath = isEsploroNewPortal
            ? this.getNewPortalViewPath()
            : this.getPrimoViewPath();
        return linkPath;
    }

    private getPrimoViewPath(): string {
        const institution = UrlUtils.getParam('institution');
        return this.profileAssetService.getDiscoveryURL(
            this.research?.internalRecordMmsId,
            institution
        );
    }

    private getNewPortalViewPath(): string {
        return FullAssetPage.OUTPUTS_URL.replace(
            '{{mms_id}}',
            this.research?.internalRecordMmsId?.toString()
        ).replace('{{institution_code}}', UrlUtils.getParam('institution'));
    }

    openConfirmationDialog(isExistsInOutputList: boolean) {
        return this.dialogRef.open(ConfirmDialogComponent, {
            maxWidth: '500px',
            data: {
                title: isExistsInOutputList
                    ? 'research.popup.selected.brief.title.add.request'
                    : 'research.popup.selected.brief.title.end.process',
                actions: {
                    confirm: isExistsInOutputList
                        ? 'research.popup.selected.brief.button.confirm.add'
                        : 'research.popup.selected.brief.button.confirm.delete',
                    cancel: isExistsInOutputList
                        ? 'research.popup.selected.brief.button.cancel'
                        : 'research.popup.selected.brief.button.cancel.no',
                },
                actionFocus: 'confirm',
            },
        });
    }

    success() {
        setTimeout(() => {
            this.showSuccessMessage();
        }, 1500);
    }

    showSuccessMessage() {
        this.snackbarService.showComponent(
            SuccessSnackbarComponent,
            'research.popup.selected.brief.confirm.snackbar.message',
            5000
        );
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
