<h1 mat-dialog-title>{{ 'research.header.language.dialog.title' | translate }}</h1>
<mat-dialog-content>
    <exl-language-select [languages]="languages" [selectedLanguage]="selectedLanguage"></exl-language-select>
</mat-dialog-content>
<mat-dialog-actions class="padding-bottom-sm">
    <span class="spacer"></span>
    <button mat-button mat-dialog-close>
        {{ 'research.header.language.dialog.close' | translate }}
    </button>
</mat-dialog-actions>
