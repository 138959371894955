import { NgModule } from '@angular/core';
import {BadgeAutoPopulatedComponent} from './badge-auto-populated/badge-auto-populated.component';
import {BadgeComponent} from './badge.component';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        BadgeAutoPopulatedComponent,
        BadgeComponent
    ],
    exports: [
        BadgeAutoPopulatedComponent,
        BadgeComponent
    ]
})
export class BadgeModule { }
