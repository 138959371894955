import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, shareReplay } from 'rxjs';
import { RestApiBase } from '../../shared/configurations/rest-api-base.config';
import { ResearcherOrganization } from '../../shared/interfaces/researcher-organization.interface';
import { UrlUtils } from '../../shared/utils/url.utils';
import { I18nService } from '../../core/i18n.service';

@Injectable({
  providedIn: 'root',
})
export class InternalOrganizationService {

  private internalSubject = new BehaviorSubject<ResearcherOrganization[]>([]);
  get internalOrg() {
    return this.internalSubject.asObservable();
  }

  departments: ResearcherOrganization[];

  constructor(private i18nService: I18nService,
    private http: HttpClient) {
    this.getAllInternalOrganizations().subscribe(data => {
      if (data) {
        this.departments = data as ResearcherOrganization[];
        this.internalSubject.next(data);
      }
    });
  }

  getAllInternalOrganizations() {
    const institutionCode = UrlUtils.getParam('institution');
    const params = new HttpParams().set('institution', institutionCode)
      .set('language', this.i18nService.getLanguage());
    return this.http.get<ResearcherOrganization[]>(RestApiBase.GET_ALL_INTERNAL_ORGANIZATIONS, { params: params }).pipe(shareReplay());
  }
}
