import {
  Directive,
  HostListener,
  HostBinding,
  EventEmitter,
  Output,
  Input,
} from '@angular/core';
import { FileDropService } from './file-drop.service';

@Directive({
    selector: '[mulo-file-drop]',
    standalone: true,
})
export class FileDropDirective {
  @Input() multiple: boolean = true;
  @Output()
  filesDroppedEmitter: EventEmitter<File[]> = new EventEmitter();
  @Output()
  filesInvalidEmitter: EventEmitter<File[]> = new EventEmitter();
  @Output() dragOverEmitter: EventEmitter<Boolean> = new EventEmitter();
  @HostBinding('class.is-drag-over') dragOver: boolean = false;
  @HostBinding('class.is-drag-leave') dragLeave: boolean = false;
  @HostBinding('class.is-drag-dropped') dragDrop: boolean = false;
  @HostBinding('class.files-added') filesAdded: boolean = false;

  delay = 2000;
  constructor(private fileDropService: FileDropService) {}

  @HostListener('dragover', ['$event']) public onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragOver = true;
    this.dragLeave = false;
    this.dragOverEmitter.emit(true);
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dragLeave = true;
    this.dragOverEmitter.emit(false);
    setTimeout(() => {
      this.dragOver = false;
    }, this.delay / 2);
    setTimeout(() => {
      this.dragLeave = false;
    }, this.delay);
  }

  @HostListener('drop', ['$event']) public onDrop(event) {
    event.preventDefault();
    event.stopPropagation();

    this.dragDrop = true;
    this.dragOver = false;
    this.dragLeave = false;
    setTimeout(() => {
      this.dragDrop = false;
    }, this.delay);

    let filesList = event.dataTransfer.files;

    if (filesList.length > 0) {
      this.emitFiles(filesList);
    }
  }

  emitFiles(filesList) {
    console.log(filesList);

    this.filesAdded = true;
    this.filesDroppedEmitter.emit(filesList);
    // this.fileDropService.processFiles(files, this.allowed_extensions)
  }
}
