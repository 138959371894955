import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { ConfigurationHandlerService } from './configuration-handler.service';

export const decommissionGuard = () => {
    const router = inject(Router);
    const configSvc = inject(ConfigurationHandlerService);

    if (configSvc.getCustomerParameter('esploro_new_portal') === 'false') {
        const urlObj = new URL(location.href);
        const tombstone = router.parseUrl('/unavailable');
        tombstone.queryParams['institution'] =
            urlObj.searchParams.get('institution');
        return tombstone;
    }
    return true;
};
