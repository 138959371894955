import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import {
    AfterContentInit,
    Directive,
    Host,
    Input,
    OnDestroy,
    Optional,
    Self,
} from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatFormField } from '@angular/material/form-field';
import { Subject, takeUntil } from 'rxjs';
import { AnnouncerService } from '../../../core/announcer.service';

/**
 * Default value is 'true' if exists, Otherwise default is false.
 */
const corectBoolean = (value: boolean) =>
    value != null && `${value}` !== 'false';

/**
 * ! Accessibility
 * This directive add announcements to screen readers on option list changes:
 * * Default announcement: Count results
 */
@Directive({
    selector:
        'mat-autocomplete:not([exlDisableA11yAnnouncement]):not([espActiveOptions]):not([autoActiveFirstOption])',
    host: { '(opened)': 'setAutocompletePanelAriaLabel($event)' },
})
export class EspMatAutocompleteA11yDirective
    implements AfterContentInit, OnDestroy
{
    /** mat-label id */
    private labelId: string;
    private autocompleteDestroy = new Subject<void>();

    private RESULTS_LABEL = 'research.aria.autocomplete.announce.results';

    constructor(
        @Self() private matAutocomplete: MatAutocomplete,
        private announcerService: AnnouncerService,
        @Optional() @Host() formField: MatFormField
    ) {
        this.labelId = formField?._labelId;
    }

    ngOnDestroy(): void {
        this.autocompleteDestroy.next();
        this.autocompleteDestroy.complete();
    }

    ngAfterContentInit(): void {
        this.announceOnDataChanges();
    }

    private announceOnDataChanges() {
        this.matAutocomplete.options?.changes
            ?.pipe(takeUntil(this.autocompleteDestroy))
            .subscribe((optionsList) => {
                if (optionsList?.length > 0 && this.matAutocomplete.isOpen) {
                    this.announcerService.labelTranslation(this.RESULTS_LABEL, {
                        value: optionsList.length,
                    });
                } else {
                    this.announcerService.clearAnnouncement();
                }
            });
    }

    /**
     * Set a descriptive label to the lisbox panel that contains the options.
     * ? Angular material fixed this issue in mat-select in Material 10.2V, but not fixed yet in mat-autocomplete.
     * ? Once it be fixed this function with the formField can be removed
     */
    setAutocompletePanelAriaLabel(event: any) {
        setTimeout(() => {
            this.matAutocomplete?.panel?.nativeElement.setAttribute(
                'aria-labelledby',
                this.labelId
            );
        }, 50);
    }
}

/**
 * ! Accessibility
 * ! Prefer use only `autoActiveFirstOption` input instead (mat-autocomplete input)
 * This directive replace the MAT_AUTOCOMPLETE_DEFAULT_OPTIONS provider
 * to fit accessibility and esploro design.
 *
 * * More details below
 *
 * ? Check on material update if this issue fixed.
 */
@Directive({
    selector: 'mat-autocomplete[espActiveOptions]:not([autoActiveFirstOption])',
    host: { '(opened)': 'activeFirstItemOnOpen($event)' },
})
export class ActiveFirstItemDirective implements AfterContentInit {
    /**
     * Skip the first element on typing.
     * For mat-autocomplete with fixed first item.
     */
    @Input('skipFirst')
    get skipFirst() {
        return this._skipFirst;
    }
    set skipFirst(value: boolean) {
        this._skipFirst = corectBoolean(value);
    }
    private _skipFirst: boolean;

    /**
     * MatAutocomplete input.
     * * Disable directive behaviour when needed.
     */
    @Input('autoActiveFirstOption')
    get autoActiveFirstOption(): boolean {
        return this._autoActiveFirstOption;
    }
    set autoActiveFirstOption(value: boolean) {
        this._autoActiveFirstOption = corectBoolean(value);
    }
    private _autoActiveFirstOption: boolean;

    constructor(@Self() private host: MatAutocomplete) {}

    get keyManager(): ActiveDescendantKeyManager<any> {
        return this.host._keyManager;
    }

    /**
     * Auto-complete options list changes observerable.
     */
    get optionListChanges$(): Subject<number> {
        return this.keyManager.change;
    }

    ngAfterContentInit(): void {
        const active = this.activeDirectiveCheck();

        if (active) {
            this.host.autoActiveFirstOption = false;
            this.updateActiveItemOnChange();
        }
    }

    activeDirectiveCheck(): boolean {
        if (this.autoActiveFirstOption != null) {
            this.host.autoActiveFirstOption = this.autoActiveFirstOption;
            return false;
        }
        return true;
    }

    /**
     * Pass to the `opened` @Output EventEmitter of the mat-autocomplete componenet.
     *
     * This method replaced the MAT_AUTOCOMPLETE_DEFAULT_OPTIONS provider to fit accessibility.
     * * (Issue with screen readers does not announce the input label with the `autoActiveFirstOption` mat-autocomplete input).
     * * set timeout to solve the issue.
     */
    activeFirstItemOnOpen(event: any): void {
        if (!this.activeDirectiveCheck()) {
            return;
        }

        setTimeout(() => {
            this.keyManager.setFirstItemActive();
        }, 100);
    }

    /**
     * Active initial item on typing.
     * The index received on subscribe equal to -1 wile typing and the list changed.
     * The subscription fired also when the user navigate throw the options list.
     */
    updateActiveItemOnChange(): void {
        this.optionListChanges$.subscribe((index) => {
            const initialIndex = this.firstItemToActive();
            if (index === -1 || index == null) {
                this.keyManager.setActiveItem(initialIndex);
            }
        });
    }

    firstItemToActive(): number {
        return this.skipFirst && this.host.options.length > 1
            ? 1
            : this.host.options.length > 0
            ? 0
            : -1;
    }
}
