import { SettingsService } from '../settings/settings.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { LogoutService } from '../logout/logout.service';
import { JwtUtilService } from '../core/jwt-util.service';
import { UrlUtils } from '../shared/utils/url.utils';
import { DiscoveryService } from '../discovery/discovery.service';
import { RouterService } from '../core/router.service';
import { ResearcherDataService } from '../core/researcher-data.service';
import { CustomizationService } from '../core/customization.service';
import { I18nService } from '../core/i18n.service';
import { ConfigurationHandlerService } from '../core/configuration-handler.service';
import { MappingTable } from '../shared/interfaces/mapping-table.interface';
import { LanguageService } from '../parts/language/language.service';
import { AuthorizationService } from '../core/authorization.service';
import { MediaService } from '@exl-ng/mulo-core';
import {
    GrowInAnimation,
    GrowOutAnimation,
    MatCssAdditionalPaletteContrastNames,
    SearchbarComponent,
    SideNavService,
    SlideInAnimation,
    SlideOutAnimation,
} from '@exl-ng/mulo-common';
// eslint-disable-next-line max-len
import { PortalEsploroHeaderLinksConfiguration } from '../shared/interfaces/view-configuration/portal-esploro-links-configuration.interface';
import { LinkResolverService } from '../core/link-resolver.service';
import { BrandService } from '../core/brand.service';
import { Discovery } from '../shared/configurations/discovery.constant';
import { MatExpansionPanel } from '@angular/material/expansion';
import { EsploroEntity } from '../shared/interfaces/esploro-entity';
import { DepositFormTriggerDirective } from '../deposit/deposit-form-trigger.directive';
import { AddProjectComponent } from '../project/add-project/add-project.component';
import { ContentDialogService } from '../parts/dialogs/content-dialog/content-dialog.service';
import { PivotService } from '../pivot-standalone/pivot.service';
import { SearchEntities } from '../shared/configurations/portal-search.constant';
import { PortalService } from '../portal/portal.service';
import { CustomerParameters } from '../shared/configurations/customer-parameters.constant';
import { AddActivitiesComponent } from '../activities/add-activities/add-activities.component';
import { SessionStorageUtilService } from '../core/session-storage-util.service';
import { ClaimOutputsService } from '../profile/claim-outputs/claim-outputs.service';
import { ClaimAsset } from '../shared/interfaces/claim-output-list.interface';
import { EntitySearchService } from '../discovery/entity-search.service';
import { State } from '../shared/configurations/state.constant';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from '../parts/dialogs/change-password-dialog/change-password-dialog.component';
import { MatButton } from '@angular/material/button';
import { ProfileService } from '../profile/profile.service';

@Component({
    selector: 'esp-esploro-header',
    templateUrl: './esploro-header.component.html',
    styleUrls: ['./esploro-header.component.scss'],
    animations: [
        SlideInAnimation,
        SlideOutAnimation,
        GrowInAnimation,
        GrowOutAnimation,
    ],
})
export class EsploroHeaderComponent implements OnInit, OnDestroy {
    @Output() toggleNotifications: EventEmitter<any> = new EventEmitter();
    @Output() showFunding: EventEmitter<any> = new EventEmitter();

    menuItems;
    notifications;
    institutionCode: string;
    institutionName: string;
    imgSubscription: Subscription;
    userAvatarImage;

    proxyName;
    proxyAvatarImage;

    showOnboarding = false;
    modeTheme: 'light' | 'dark';

    userInitials: string;
    proxyInitials: string;
    languages;
    searchQuery = '';
    logoUrl$;
    searchEntities = [];
    searchScopesByEntity = [];
    activeScope: string;
    selectedScope: string;

    activeEntity = 'outputs';
    selectedEntity: string;
    autocompleteSearchBy = [];
    entities = [];
    headerLinks: PortalEsploroHeaderLinksConfiguration[];
    headerLinksFiltered: PortalEsploroHeaderLinksConfiguration[];
    includeSignIn: boolean;
    includePivot: boolean;
    searchbarPlaceholder = 'research.header.search.placeholder';

    displaySearchBar = true;

    contentEntities: EsploroEntity[] = [];

    advancedSearchEnabled = false;

    outputsForNotificationDisplay: ClaimAsset[] = [];
    notifyCount = 0;

    @ViewChild('headerMenu', { static: true }) headerMenu: ViewContainerRef;
    @ViewChild('mobileSearchbar') searchbarMobile: SearchbarComponent;
    @ViewChild(DepositFormTriggerDirective) depositDirective;
    @ViewChild('mobileSearchMenu') mobileSearchMenu: MatExpansionPanel;
    @ViewChild('mobileSearchBtn') mobileSearchBtn: MatButton;
    @ViewChild('advSearchBlock', { static: true })
    advSearchBlock: ViewContainerRef;

    displayEntityMenu = false;
    searchbarState;
    itemsInMenu;
    minuteInMilliseconds = 60000;
    timerPeriod = this.minuteInMilliseconds * 60 * 3;

    componentDestroy = new Subject<void>();
    mobileSearchMenuOpen = false;
    constructor(
        private logoutService: LogoutService,
        private jwtUtilService: JwtUtilService,
        private router: Router,
        private settingsService: SettingsService,
        public discoveryService: DiscoveryService,
        private routerService: RouterService,
        private researcherDataService: ResearcherDataService,
        private profileSvc: ProfileService,
        private customizationService: CustomizationService,
        private i18nService: I18nService,
        private configSvc: ConfigurationHandlerService,
        private languageService: LanguageService,
        private authorizationService: AuthorizationService,
        public media: MediaService,
        private sidenavService: SideNavService,
        private linkResolverService: LinkResolverService,
        private brandService: BrandService,
        private pivotService: PivotService,
        private contentDialogService: ContentDialogService,
        private portalService: PortalService,
        private sessionStorageUtilService: SessionStorageUtilService,
        private claimOutputsService: ClaimOutputsService,
        private entitySearchService: EntitySearchService,
        private dialog: MatDialog,
    ) {
        this.institutionCode = UrlUtils.getParam('institution');
        this.institutionName = configSvc.getMainInstitutionName();
        this.includeSignIn =
            configSvc.getPortalConfigurationHedearIncludeSignIn();
        if (
            configSvc.getPortalConfigurationHedearIncludePivot() &&
            jwtUtilService.isResearcherTypeLoggedIn()
        ) {
            this.pivotService
                .getHasPivot(this.jwtUtilService.getUserID())
                .subscribe((hasPivot) => {
                    this.includePivot = hasPivot;
                });
        }
        portalService.openMobileAdvSearch.subscribe(() =>
            this.toggleAdvSearchSidenav(),
        );
    }

    ngOnInit() {
        this.imgSubscription = this.researcherDataService.imageUrl$.subscribe(
            (res) => {
                this.userAvatarImage = res;
            },
        );
        if (this.isResearcherLoggedIn()) {
            this.loadProfileImage();
        }
        if (this.jwtUtilService.getCurrentUserForProxy()) {
            this.setProxyData();
        }
        this.searchEntities = this.entitySearchService.getSearchEntities();
        if (this.searchEntities.length >= 1) {
            this.setCurrentEntity(this.searchEntities[0].value);
        }
        this.languages = this.getLanguageMenuOptions();
        this.userInitials = this.getInitials();
        this.setMenuItems();
        this.handleOnboarding();
        this.setLogoTheme();
        this.displayEntityMenu =
            this.configSvc.isCustomerParameterEnabled(
                'esploro_new_homepage_and_header',
            ) &&
            this.configSvc.getPortalConfigurationHedearIncludeDropdownMenu();
        this.handleHeaderLinks();
        this.logoUrl$ = this.brandService.logoPathUrl$;
        this.entities = this.configSvc.getActiveEntities();
        this.setContentMenuEntities();
        if (this.isResearcherLoggedIn()) {
            this.handleNotifications();
        }
    }

    handleNotifications() {
        //   timer(0, 10000).pipe(takeUntil(this.componentDestroy)).subscribe(() =>{
        this.getResearcherClaimOutputsList();
        //    })
        this.listenToOutputClaimCountRefresh();
        this.listenToOutputClaimListRefresh();
    }

    getResearcherClaimOutputsList(updateTotal = true) {
        this.claimOutputsService
            .getResearcherClaimOutputsList(0, 2, [])
            .subscribe((data) => {
                if (data) {
                    if (updateTotal) {
                        this.notifyCount = data.total;
                    }
                    this.outputsForNotificationDisplay = data.assets;
                    this.outputsForNotificationDisplay.forEach((output) => {
                        output.dateObject = new Date(
                            output.createdYear,
                            output.createdMonth,
                            output.createdDay,
                            output.createdHour,
                            output.createdMinute,
                            output.createdSecond,
                        );
                    });
                }
            });
    }

    listenToOutputClaimCountRefresh() {
        this.claimOutputsService.notificationCount
            .pipe(takeUntil(this.componentDestroy))
            .subscribe((total) => {
                this.notifyCount = total;
            });
    }

    //we don't update the total because sometimes there is a delay in the index and the number and data are not updated although the timeout
    listenToOutputClaimListRefresh() {
        this.claimOutputsService.dataRefresh
            .pipe(takeUntil(this.componentDestroy))
            .subscribe((isRefresh) => {
                if (isRefresh) {
                    setTimeout(() => {
                        this.getResearcherClaimOutputsList(false);
                    }, 5000);
                }
            });
    }

    get userName() {
        return this.jwtUtilService.getUserDisplayName();
    }

    get selectedLanguage() {
        return this.i18nService.getLanguage();
    }

    getLanguageMenuOptions() {
        const institutionLanguagesMT = this.configSvc.getMappingTable(
            'esploro_InstitutionLanguages',
        ) as MappingTable[];
        return institutionLanguagesMT
            .filter((row) => row.source2 === 'true' && row.enabled === 'true')
            .map((row) => {
                return row.target;
            });
    }

    getInitials(forceJwt = false) {
        let initials = this.jwtUtilService
            .getLastName(forceJwt)
            .charAt(0)
            .toUpperCase();
        if (this.jwtUtilService.getFirstName(forceJwt)) {
            initials =
                this.jwtUtilService
                    .getFirstName(forceJwt)
                    .charAt(0)
                    .toUpperCase() + initials;
        }
        return initials;
    }

    setProxyData() {
        this.proxyName = this.jwtUtilService.getUserDisplayName(true);
        this.proxyInitials = this.getInitials(true);
        this.settingsService
            .getResearcherProfileImage(this.jwtUtilService.getUserID(true))
            .subscribe((data) => {
                if (data) {
                    this.proxyAvatarImage = window.URL.createObjectURL(data);
                }
            });
    }

    handleOnboarding() {
        if (this.jwtUtilService.getCurrentUserForProxy()) {
            setTimeout(() => {
                this.showOnboarding = true;
            }, 0);
        }
    }

    handleHeaderLinks() {
        const links = this.configSvc.getPortalConfigurationHeaderLinks() || [];
        links.forEach((item) => {
            item.url = this.linkResolverService.getLinkUrl(item.url);
        });
        this.headerLinks = links.filter((item) => item.active);
        this.headerLinksFiltered = this.displayEntityMenu
            ? this.headerLinks.filter((link) => link.display)
            : this.headerLinks;
    }

    setMenuItems() {
        this.menuItems = [
            {
                section: 'links',
                divider:
                    !this.configSvc.getFeatureFlagEnabled('ENABLE_ONETRUST'),
                items: [
                    {
                        label: 'research.header.profile',
                        link: '/profile',
                        disabled: false,
                    },
                    {
                        label: 'research.header.settings',
                        link: '/settings',
                        disabled: false,
                    },
                ],
            },
        ];
        if (this.configSvc.getFeatureFlagEnabled('ENABLE_ONETRUST')) {
            this.menuItems.push({
                section: 'onetrust',
                divider: true,
                items: [
                    {
                        labelMethod: () =>
                            document.getElementById('ot-sdk-btn').innerText,
                        action: 'onetrust',
                    },
                ],
            });
        }

        if (this.jwtUtilService.getProxyFor()) {
            this.menuItems.push({
                section: 'account',
                divider: false,
                items: [
                    {
                        label: 'research.header.switch.account',
                        link: '/login-as',
                        disabled: false,
                    },
                ],
            });
        }

        if (
            !this.configSvc.isExternalLogin() &&
            this.configSvc.getCustomerParameter(
                'primo_patron_info_updatable',
            ) === 'Y'
        ) {
            this.menuItems.push({
                section: 'password',
                divider: true,
                items: [
                    {
                        label: 'research.header.change.password',
                        action: 'password',
                    },
                ],
            });
        }
    }

    loadProfileImage() {
        const userId = this.jwtUtilService.getUserID();
        this.settingsService
            .getResearcherProfileImage(userId)
            .subscribe((imgBlob) => {
                if (imgBlob) {
                    this.researcherDataService.setProfileImageUrl(imgBlob);
                } else {
                    this.profileSvc
                        .getResearcherDataForSEO(userId)
                        .subscribe((data) => {
                            this.researcherDataService.setProfileImageUrl(
                                data.researcherImageUrl ?? '',
                            );
                        });
                }
            });
    }

    isLoggedIn() {
        return this.jwtUtilService.isLoggedIn();
    }

    isResearcherLoggedIn() {
        return this.jwtUtilService.isResearcherTypeLoggedIn();
    }

    isUserLoggedIn() {
        return this.jwtUtilService.isUserTypeLoggedIn();
    }

    isResearcherDepositsEnabled() {
        return this.authorizationService.isAuthorizedForDeposit();
    }

    setLogoTheme() {
        const contrast = this.customizationService.getThemeByConfig('header');
        this.modeTheme =
            contrast === MatCssAdditionalPaletteContrastNames.dark
                ? 'light'
                : 'dark';
    }

    doSignOut() {
        this.logoutService.logout();
    }

    search(event) {
        const searchTerm = event.query || this.searchQuery;

        if (!searchTerm) {
            if (this.mobileSearchMenu) {
                this.mobileSearchMenu.close();
            }
            return;
        }
        if (this.activeEntity === SearchEntities.RESEARCHERS_ENTITY) {
            this.goToSearchProfiles(searchTerm, this.selectedScope);
        } else if (this.activeEntity === SearchEntities.OUTPUTS_ENTITY) {
            this.goToSearchAssets(searchTerm, this.selectedScope);
        } else if (this.activeEntity === SearchEntities.PROJECTS_ENTITY) {
            this.goToSearchProjects(searchTerm, this.selectedScope);
        }

        setTimeout(() => {
            this.searchQuery = '';
            if (this.mobileSearchMenu) {
                this.mobileSearchMenu.close();
            }
        }, 0);
    }

    handleMobileSearchBtnClick(event) {
        this.mobileSearchMenuOpen = true;
        // this.mobileSearchMenu.open();
        // this.mobileSearchMenu.afterExpand.pipe(take(1)).subscribe(() => {
        //     // this.mobileSearchMenu._body.nativeElement.tabIndex = 0;
        //     // this.mobileSearchMenu._body.nativeElement.focus();
        //     // this.mobileSearchMenu._body.nativeElement.tabIndex = -1;
        // });

        // setTimeout(() => {
        //     this.searchbarMobile.focusInput();
        // }, 100);
    }

    goToSearchProfiles(query: string, scope = 'all') {
        this.router.navigate(['/search/researchers'], {
            queryParams: {
                query,
                page: 1,
                scope,
                institution: this.institutionCode,
            },
        });
    }

    goToSearchProjects(query: string, scope) {
        this.router.navigate([State.PROJECTS_SEARCH], {
            queryParams: {
                query,
                page: 1,
                scope,
                institution: this.institutionCode,
            },
        });
    }

    goToSearchAssets(query: string, scope: string) {
        if (
            this.configSvc.getCustomerParameter(
                'esploro_new_search_results_page',
            ) !== 'true'
        ) {
            const discoveryURL = this.discoveryService.getDiscoverySearchURL(
                this.institutionCode,
                query,
            );
            window.open(discoveryURL, '_blank');
        } else {
            this.router.navigate(['/search/outputs'], {
                queryParams: {
                    query: Discovery.SIMPLE_SEARCH_QUERY_PREFIX + query,
                    scope,
                    page: 1,
                    institution: this.institutionCode,
                },
            });
        }
    }

    onUserMenuAction(action) {
        switch (action) {
            case 'language':
                this.showLanguageDialog();
                break;
            case 'password':
                this.showChangePasswordDialog();
                break;
            case 'onetrust':
                document.getElementById('ot-sdk-btn').click();
                break;
            default:
                console.log('onUserMenuAction', action);
                break;
        }
    }

    displayLanguageButton() {
        if (this.languages && this.languages.length > 1) {
            return true;
        }
        return false;
    }

    toggleSidenav() {
        this.sidenavService.toggle('main', this.headerMenu, {
            position: 'end',
        });
    }

    toggleAdvSearchSidenav() {
        this.mobileSearchMenuOpen = false;
        this.sidenavService.toggle('secondary', this.advSearchBlock, {
            position: 'end',
        });
    }

    showLanguageDialog() {
        const data = {
            languages: this.languages,
            selectedLanguage: this.selectedLanguage,
        };
        this.languageService.showLanguageDialog(data);
    }

    showChangePasswordDialog() {
        this.dialog.open(ChangePasswordDialogComponent);
    }

    setContentMenuEntities() {
        const contentDataTemp: EsploroEntity[] = [
            { code: 'output', active: this.showAddAssets() },
            {
                code: 'project',
                active: this.showAddProjects(),
            },
            {
                code: 'activity',
                active: this.showAddActivities(),
            },
        ];
        this.contentEntities = contentDataTemp.filter((item) => item.active);
    }

    showAddAssets() {
        return (
            this.isResearcherDepositsEnabled() &&
            this.configSvc.getConfigurationByDefualtView().headerFooter
                ?.addAssets
        );
    }

    showAddProjects() {
        return this.configSvc.canEditEntityByConfig(
            CustomerParameters.ESPLORO_PROJECTS,
            'addProjects',
        );
    }

    showAddActivities() {
        return this.configSvc.canEditEntityByConfig(
            CustomerParameters.ESPLORO_ACTIVITIES,
            'addActivities',
        );
    }

    menuContentOnClick(item: EsploroEntity) {
        switch (item.code) {
            case 'output':
                this.depositDirective.launchDepositForm();
                break;
            case 'project':
                this.contentDialogService.openDialog(
                    AddProjectComponent,
                    'research.add.project.title',
                );
                break;
            case 'activity':
                this.contentDialogService.openDialog(
                    AddActivitiesComponent,
                    'research.add.activity.title',
                );
                break;
        }
    }

    ngOnDestroy() {
        if (this.imgSubscription) {
            this.imgSubscription.unsubscribe();
        }
        this.componentDestroy.next();
        this.componentDestroy.complete();
    }

    handleEntityChange(entity) {
        this.activeEntity = entity;
        this.advancedSearchEnabled =
            entity === 'outputs' &&
            this.configSvc.getAdvancedSearchConfiguration()
                .enableAdvancedSearch;
        this.setSearchScopesByEntity(entity);
        this.setSearchbarPlaceholder(entity);
        this.setAutocompleteSearchBy(entity);
    }

    setAutocompleteSearchBy(scope: string) {
        this.autocompleteSearchBy = this.searchEntities.find(
            (s) => s.value === scope,
        )?.searchBy;
    }

    setSearchbarPlaceholder(scope: string) {
        this.searchbarPlaceholder = this.searchEntities.find(
            (s) => s.value === scope,
        )?.placeholder;
    }

    setSearchScopesByEntity(entity: string) {
        this.searchScopesByEntity = this.searchEntities.filter(
            (s) => s.value === entity,
        )[0].scopes;
        if (this.searchScopesByEntity.length > 0) {
            this.setCurrentScope(this.searchScopesByEntity[0].value);
        }
    }

    setCurrentScope(scope) {
        this.activeScope = scope;
        this.selectedScope = scope;
    }

    handleScopeChange(scope) {
        this.selectedScope = scope;
    }

    setCurrentEntity(entity) {
        this.activeEntity = entity;
        this.selectedEntity = entity;
        this.advancedSearchEnabled =
            entity === 'outputs' &&
            this.configSvc.getAdvancedSearchConfiguration()
                .enableAdvancedSearch;
        this.setSearchbarPlaceholder(entity);
        this.setSearchScopesByEntity(entity);
    }

    getEntityParams(entity) {
        const qParams = {
            ...(entity.qParams || {}),
            institution: this.institutionCode,
        };

        return qParams;
    }

    runAdvancedSearch(params) {
        if (this.mobileSearchMenu) {
            this.mobileSearchMenu.close();
        }

        this.router.navigate(['/search/outputs'], {
            queryParams: {
                query: params.query,
                scope: params.scope,
                pfilter: params.pfilter,
                mode: 'advanced',
                page: 1,
                institution: this.institutionCode,
            },
        });
    }

    handleLanguageChange(language) {
        this.i18nService.setLanguage(language);
        if (this.isLoggedIn()) {
            const langJson = { language };
            this.languageService
                .changeUserLanguage(langJson)
                .subscribe((data) => {
                    this.jwtUtilService.storeJwt(data);
                });
        } else {
            this.sessionStorageUtilService.setSessionLanguage(language);
        }
        setTimeout(() => {
            this.languageService.closeDialog();
        }, 500);
    }

    handleMobileSearchBtnClose() {
        this.mobileSearchMenuOpen = false;
        // this.mobileSearchMenu.close();
        setTimeout(() => this.mobileSearchBtn.focus());
    }
}
