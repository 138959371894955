import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

@Directive({
  selector: '[muloInputExpander]',
  standalone: true,
})
export class InputExpanderDirective implements OnChanges {
  @Input('muloInputExpander') val;
  @Input('muloInputExpanderBuffer') buffer = 5;
  @Output() widthCalc: EventEmitter<number> = new EventEmitter();
  @HostBinding('class.is-auto-expanding') hostClass = true;

  constructor(private el: ElementRef) {}

  ngOnChanges(): void {
    if (this.val) {
      switch (typeof this.val) {
        case 'object':
          if (Array.isArray(this.val)) {
            this.setInputWidth(
              this.val.join(''),
              this.buffer * this.val.length
            );
          }
          break;
        case 'string':
          this.setInputWidth(this.val);
          break;
      }
    } else {
      return;
    }
  }

  setInputWidth(value, buffer = this.buffer) {
    const chars = (value?.length || 0) + buffer;
    this.el.nativeElement.style.width = chars + 'ch';
  }
}
