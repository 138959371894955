import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { DepositFormService } from '../deposit-form.service';
import { MatStepper } from '@angular/material/stepper';
import { TabComponentModel } from '../../shared/interfaces/tab-component.model';
import { DepositFormOverlayRef } from '../deposit-form-overlay/deposit-form-overlay.ref';
import { DepositFormDataService } from '../deposit-form-data.service';
import { SnackbarService } from '../../core/snackbar.service';
import {
    GrowInAnimation,
    GrowOutAnimation,
    OpacityInAnimation,
    OpacityOutAnimation,
} from '@exl-ng/mulo-common';
import { SuccessSnackbarComponent } from '../../parts/snackbars/success-snackbar/success-snackbar.component';
import { ProfileOutputService } from '../../profile/profile-output/profile-output.service';
import { ConfigurationHandlerService } from '../../core/configuration-handler.service';
import { RouterService } from '../../core/router.service';
import { DepositForm } from '../../shared/configurations/deposit-form.constant';
import { ResearcherOutputListFacet } from '../../shared/interfaces/researcher-output-list-facet.interface';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
    selector: 'esp-deposit-stepper',
    templateUrl: './deposit-stepper.component.html',
    styleUrls: ['./deposit-stepper.component.scss'],
    animations: [
        OpacityInAnimation,
        OpacityOutAnimation,
        GrowInAnimation,
        GrowOutAnimation,
    ],
})
export class DepositStepperComponent implements OnInit {
    @Output() closeDeposit: EventEmitter<any> = new EventEmitter();
    @ViewChild('stepper', { static: false }) stepper: MatStepper;
    @HostBinding('class.header-is-hidden') headerIsHidden = false;
    @HostBinding('class.stepper-active') stepperActive = false;

    @ViewChildren('step', { read: ElementRef }) stepsContent: QueryList<
        ElementRef<HTMLElement>
    >;
    private elementsToHideInLastStep: [];

    visibleSteps: number;
    firstTabComponents: TabComponentModel[] = [];
    secondTabComponents: TabComponentModel[] = [];
    thirdTabComponents: TabComponentModel[] = [];
    fourthTabComponents: TabComponentModel[] = [];
    isLinear = true;
    backButtonDisabled = true;
    lastStep;
    showSkipToEnd = false;
    nextStepEnabled = false;
    processingNextStep = false;
    stepperStepsActive = false;
    jumpToTitle = false;
    jumpToTopics = false;
    jumpToFiles = false;

    public hasBaseDropZoneOver = false;
    public hasAnotherDropZoneOver = false;
    steps = [
        { pos: 0, title: 'research.toptab.tab1', icon: 'file' },
        { pos: 1, title: 'research.toptab.tab2', icon: 'home' },
        { pos: 2, title: 'research.toptab.tab3', icon: 'sort' },
        { pos: 3, title: 'research.toptab.tab4' },
        { pos: 4, title: 'research.top.step4bottom' },
        { pos: 5, title: 'research.top.step5' },
    ];

    constructor(
        private depositFormService: DepositFormService,
        private overlayRef: DepositFormOverlayRef,
        public depositFormDataService: DepositFormDataService,
        private snackbarService: SnackbarService,
        public profileOutputService: ProfileOutputService,
        private configurationHandlerService: ConfigurationHandlerService,
        private renderer: Renderer2,
        private element: ElementRef<HTMLElement>,
        private routerService: RouterService
    ) {}

    ngOnInit() {
        if (!this.depositFormDataService.isDraft) {
            this.firstTabComponents = [
                new TabComponentModel(
                    'file_uploader',
                    false,
                    true,
                    '1',
                    '',
                    ''
                ),
            ];
        }
        this.visibleSteps = this.steps.length - 3;
    }

    buildTabsPerConfiguration() {
        this.firstTabComponents = JSON.parse(
            JSON.stringify(this.buildTab('tab1'))
        );
        this.secondTabComponents = JSON.parse(
            JSON.stringify(this.buildTab('tab2'))
        );
        this.thirdTabComponents = JSON.parse(
            JSON.stringify(this.buildTab('tab3'))
        );
        this.fourthTabComponents = JSON.parse(
            JSON.stringify(this.buildTab('tab4'))
        );
    }

    buildTab(tab) {
        const resourceTypeTemplate =
            this.depositFormDataService.resourceTypeTemplate;
        const comp =
            this.configurationHandlerService.depositConfig.deposit[
                resourceTypeTemplate
            ].tabs[tab].components;
        comp.sort((a, b) => {
            return parseInt(a.order) - parseInt(b.order);
        });
        return comp;
    }

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    public fileOverAnother(e: any): void {
        this.hasAnotherDropZoneOver = e;
    }

    onBackButtonClick() {
        this.stepper.selectedIndex = this.lastStep;
        this.headerIsHidden = false;
        this.backButtonDisabled = true;
        this.showSkipToEnd = true;

        this.unHideTabsFromAssistiveTechnologies();
    }

    onSkipToEndButtonClick() {
        this.resetJump();
        this.goToSummary();
    }

    get isLastStep() {
        if (this.stepper.selectedIndex >= this.visibleSteps) {
            return true;
        }
        return false;
    }

    goToSummary() {
        this.lastStep = this.stepper.selectedIndex;
        this.stepper.selectedIndex = this.visibleSteps + 1;
        this.headerIsHidden = true;
        this.backButtonDisabled = false;
        this.showSkipToEnd = false;

        this.hideHiddenTabsFromAssistiveTechnologies();
    }

    onNextButtonClick() {
        this.resetJump();
        if (this.stepper.selectedIndex === this.visibleSteps) {
            this.goToSummary();
        } else if (this.stepper.selectedIndex > this.visibleSteps) {
            this.submit();
        } else {
            this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
        }
    }

    submit() {
        this.processingNextStep = true;
        this.submitNewAsset();
    }

    submitNewAsset() {
        const depositData = this.depositFormService.buildDepositData();
        this.depositFormService.submitNewAsset(depositData.value).subscribe(
            () => {
                if (this.routerService.isProfileRoute()) {
                    this.refreshDataInOutputListAfterSave();
                }
            },
            (error) => {}
        );
        this.success();
    }

    refreshDataInOutputListAfterSave() {
        setTimeout(() => {
            this.profileOutputService
                .getOutputListFacets(this.profileOutputService.isPublicPage())
                .subscribe((data) => {
                    const isListEmpty =
                        this.profileOutputService.facets.length === 0;
                    const facets = data as ResearcherOutputListFacet[];
                    if (facets && facets.length > 0) {
                        this.profileOutputService.saveFacets(data);
                        if (isListEmpty) {
                            //if the is empty- need to navigate to the first facet
                            this.profileOutputService.refreshData$.next(true);
                        } else {
                            this.profileOutputService.refreshData$.next(false);
                        }
                    }
                });
        }, this.profileOutputService.UPDATE_DATA_TIMEOUT);
    }

    stepChanged(event: StepperSelectionEvent) {
        // Prevent from mat-stepper mark input fields as invalid when step changed.
        if (event.previouslySelectedIndex > event.selectedIndex) {
            event.previouslySelectedStep.interacted = false;
        } else {
            event.selectedStep.interacted = false;
        }
    }

    success() {
        //this.processingNextStep = true;
        setTimeout(() => {
            this.overlayRef.close();
            this.showSuccessMessage();
        }, 1000);
    }

    showSuccessMessage() {
        setTimeout(() => {
            this.depositFormDataService.resetForm();
            this.snackbarService.showComponent(
                SuccessSnackbarComponent,
                'research.deposit.success',
                5000
            );
        }, 500);
    }

    //TODO change according to configuration in the future
    assetTypeSelected() {
        this.resetJump();
        this.depositFormDataService.cleanForm();
        this.isLinear = false;
        this.nextStepEnabled = true;
        this.showSkipToEnd = true;
        this.buildTabsPerConfiguration();
        this.enableNextStep();
        if (
            !this.depositFormDataService.isDraft &&
            this.depositFormService.needToSaveDraftInOpen
        ) {
            this.saveDraftInOpenning();
        }

        this.setElementsToHideInLastStep();
    }

    saveDraftInOpenning() {
        this.depositFormService.needToSaveDraftInOpen = false;
        this.depositFormService.disableCloseDialogDuringFirstSave = true;
        this.depositFormService.draftInProcess = true;
        const depositData = this.depositFormService.buildDepositData(false);
        this.depositFormService.saveDraft(depositData.value, true).subscribe(
            (data) => {
                this.depositFormDataService.updateMmsId(data);
                this.turnOffFlags();
            },
            () => {
                this.turnOffFlags();
            }
        );
    }

    turnOffFlags() {
        this.depositFormService.draftInProcess = false;
        this.depositFormService.disableCloseDialogDuringFirstSave = false;
    }

    assetTypeCleared() {
        this.resetJump();
        this.nextStepEnabled = false;
        this.showSkipToEnd = false;
        this.stepperStepsActive = false;
        this.stepperActive = false;
        this.isLinear = true;
        this.firstTabComponents = [
            new TabComponentModel('file_uploader', false, true, '1', '', ''),
        ];
        this.secondTabComponents = [];
        this.thirdTabComponents = [];
        this.fourthTabComponents = [];
    }

    displayTab(tab: TabComponentModel[]) {
        if (tab.length === 0) {
            return false;
        }
        const visibleComp = tab.filter((comp) => comp.visible);
        if (visibleComp.length > 0) {
            return true;
        }
        return false;
    }

    enableNextStep() {
        setTimeout(() => {
            this.nextStepEnabled = true;
            this.exposeStepperSteps();
        }, 0);
    }

    exposeStepperSteps() {
        setTimeout(() => {
            this.stepperStepsActive = true;
        }, 0);
        setTimeout(() => {
            this.stepperActive = true;
        }, 300);
    }

    jumpToSection(sectionName) {
        this.headerIsHidden = false;
        this.backButtonDisabled = true;
        this.showSkipToEnd = true;
        if (sectionName === 'title') {
            this.stepper.selectedIndex = 1;
            this.jumpToTitle = true;
        } else if (sectionName === 'topics') {
            this.stepper.selectedIndex = 1;
            this.jumpToTopics = true;
        } else if (sectionName === 'files') {
            this.stepper.selectedIndex = 0;
            this.jumpToFiles = true;
        }

        this.unHideTabsFromAssistiveTechnologies();
    }

    resetJump() {
        this.jumpToTitle = false;
        this.jumpToTopics = false;
        this.jumpToFiles = false;
    }

    /**
     * ! Accessibility
     * Hide
     */
    setElementsToHideInLastStep() {
        setTimeout(() => {
            const headerTabs = [].slice.call(
                this.element.nativeElement.querySelectorAll(
                    'mat-step-header[role=tab]'
                )
            );
            const stepsContent = this.stepsContent.map(
                (step) => step.nativeElement
            );
            this.elementsToHideInLastStep = headerTabs.concat(stepsContent);
        }, 1000);
    }

    hideHiddenTabsFromAssistiveTechnologies() {
        this.elementsToHideInLastStep.forEach((step) => {
            this.renderer.setAttribute(step, 'aria-hidden', 'true');
        });
    }

    unHideTabsFromAssistiveTechnologies() {
        this.elementsToHideInLastStep.forEach((step) => {
            this.renderer.removeAttribute(step, 'aria-hidden');
        });
    }
}
