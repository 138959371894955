import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PersonOrganizationAffiliationData } from '../../../shared/interfaces/person-organization-affiliation-data.model';
import { ResearcherProxyFor } from '../../../shared/interfaces/researcher-proxy-for.interface';

@Injectable({
  providedIn: 'root'
})
export class AffiliationService {

  constructor(private translate: TranslateService) { }

  /**
   * returns title if exists, if not returns the translation for the position code from the CT
   */
  getTranslatedPositionOrTitle(affiliation: PersonOrganizationAffiliationData): string{

    if (!affiliation){
        return '';
    }

    if (affiliation.title){
        return affiliation.title;
    }

    if (affiliation.position){
        const translation = this.translate.instant(affiliation.position);

        if (translation){
            return translation;
        }
    }

    return '';
  }

  /**
   * Returns title and then position from first current organization
   * if not exists returns the researcher position
   */
  getAffTitleOrPositionOrResearcherPosition(affiliation: PersonOrganizationAffiliationData, researcherPosition: string): string{

    let titleOrPosition = this.getTranslatedPositionOrTitle
                            (affiliation);

    if (!titleOrPosition && researcherPosition){
        titleOrPosition = this.translate.instant(researcherPosition);
    }
    return titleOrPosition;
  }

}
