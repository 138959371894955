import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { DOCUMENT } from '@angular/common';


@Injectable({
  providedIn: 'root',
})
export class AltmetricService {

  url = 'https://api.altmetric.com/v1/doi/';

  private renderer2: Renderer2;

  constructor(private http: HttpClient,
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  searchDOI(doi): Observable<any> {
    return this.http.get(this.url + doi)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    console.error(
      `Altmetric API returned ${error.status}. ` +
      `Reason: ${error.error}`);
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  loadScript(src?: string) {
    const id = 'altmetric';
    const element = this.document.getElementById(id);
    if (element) {
      element.remove();
    }
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js';
    s.id = id;
    this.renderer2.appendChild(this.document.body, s);
  }

  fetchImage(url): Observable<any> {
    return this.http.get<any>(url)
      .pipe(catchError(this.handleError));
  }


}
