<div>
    <div>
        <mat-form-field
            *ngIf="displayISSN()"
            [class.is-autopopulated]="isAutoPopulated('isPartOfIssn')"
            class="is-short-input"
        >
            <input
                matInput
                [formControl]="depositFormDataService.isPartOfIssn"
                placeholder="{{
                    'research.deposit.searchable.fields.placeholder' | translate
                }}"
            />
            <mat-label>{{
                'research.status.issn' | customTranslate
            }}</mat-label>
            <mat-error>
                {{ 'research.status.issn.error' | customTranslate }}
            </mat-error>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('isPartOfIssn')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
            <mat-progress-bar
                mode="indeterminate"
                color="primary"
                *ngIf="searchIssnInProgress"
            ></mat-progress-bar>
        </mat-form-field>
        <mat-form-field
            *ngIf="displayISBN()"
            [class.is-autopopulated]="isAutoPopulated('isPartOfIsbnForDisplay')"
        >
            <input
                matInput
                [formControl]="depositFormDataService.isPartOfIsbnForDisplay"
            />
            <mat-label>{{
                'research.status.isbn' | customTranslate
            }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('isPartOfIsbnForDisplay')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
        </mat-form-field>
        <mat-form-field
            *ngIf="displayVolume()"
            [class.is-autopopulated]="isAutoPopulated('isPartOfVolume')"
            class="is-short-input"
        >
            <input
                matInput
                [formControl]="depositFormDataService.isPartOfVolume"
            />
            <mat-label>{{
                'research.status.volume' | customTranslate
            }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('isPartOfVolume')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
        </mat-form-field>
    </div>
    <div>
        <mat-form-field
            *ngIf="displayIssue()"
            [class.is-autopopulated]="isAutoPopulated('isPartOfIssue')"
            class="is-short-input"
        >
            <input
                matInput
                [formControl]="depositFormDataService.isPartOfIssue"
            />
            <mat-label>{{
                'research.status.issue' | customTranslate
            }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('isPartOfIssue')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
        </mat-form-field>

        <mat-form-field
            *ngIf="displayPages()"
            [class.is-autopopulated]="isAutoPopulated('isPartOfPages')"
            class="is-short-input"
        >
            <input
                matInput
                [formControl]="depositFormDataService.isPartOfPages"
            />
            <mat-hint align="start">{{
                'research.status.page.hint' | customTranslate
            }}</mat-hint>
            <mat-label>{{
                'research.status.pages' | customTranslate
            }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('isPartOfPages')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
        </mat-form-field>

        <mat-form-field
            *ngIf="displayPublisherName()"
            [class.is-autopopulated]="isAutoPopulated('publisher')"
            [muloInputExpander]="depositFormDataService.publisher.value"
            class="is-short-input"
        >
            <input matInput [formControl]="depositFormDataService.publisher" />
            <mat-label>{{
                'research.status.publisher' | customTranslate
            }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('publisher')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
        </mat-form-field>
    </div>
</div>
