import {
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { Params } from '@angular/router';

export interface BriefActionItem {
    name: string;
    icon?: string;
    tooltip?: string;
}
export interface BriefMenuActionItem {
    name: string;
    divider?: string;
    disabled?: boolean;
}

@Component({
    selector: 'exl-brief-base',
    templateUrl: './brief-base.component.html',
    styleUrls: ['./brief-base.component.scss'],
})
export class BriefBaseComponent {
    @HostBinding('class.exl-citation-brief')
    @HostBinding('class.is-minimal')
    @Input()
    minimal = false;
    @HostBinding('class.is-indented') indentation = 0;
    @HostBinding('class.is-mouseover') mouseOver = false;
    @HostBinding('class.is-disabled') @Input() disabled = false;
    @HostBinding('class.is-hidden') @Input() hidden = false;
    @HostBinding('class.is-hoverable') @Input() hoverable = true;

    @Input() type;
    @Input() postType;
    @Input() doi;
    @Input() title;
    @Input() titleUrl;
    @Input() authors: Array<any>;
    @Input() publisher;
    @Input() container;
    @Input() subject: Array<any>;
    @Input() issue;
    @Input() volume;
    @Input() page;
    @Input() date;
    @Input() isbn;
    @Input() abstract;
    @Input() thumbs: Array<any>;
    @Input() thumbsWidth = '50px';
    @Input() lines: Array<any>;
    @Input() altmetric = false;
    @Input() titleAsLink = false;
    @Input() titleClickable: boolean;
    @Input() actions: BriefActionItem[];
    @Input() menuActions: BriefMenuActionItem[];
    @Input() dateLine: DateLine;
    @Input() highlighted: boolean;
    @Input() images: Array<any>;
    @Input() publication: string;
    @Input() metadata: string;
    @Input() status: string | Array<string>;
    @Input() created: Date;
    @Input() modified: Date;
    @Input() dateLabel: string;
    @Input() href: string;
    @Input() altmetricDelay: number;
    @Input('router') routerLinkUrl: string;
    @Input() queryParams: Params;

    highlightHover = false;

    @Output() titleClick: EventEmitter<any> = new EventEmitter();
    @Output() actionClick: EventEmitter<any> = new EventEmitter();
    @Output() authorClick: EventEmitter<any> = new EventEmitter();

    @HostListener('mouseover') onMouseEnter() {
        this.mouseOver = true;
    }
    @HostListener('mouseleave') onMouseLeave() {
        this.mouseOver = false;
    }

    constructor() {}

    onTitleClick(event) {
        event?.preventDefault();
        event?.stopPropagation();
        this.titleClick.emit(event);
    }

    onAction(action) {
        this.actionClick.emit(action);
    }

    onAuthorClick(event, author) {
        event.preventDefault
            ? event.preventDefault()
            : (event.returnValue = false);
        this.authorClick.emit(author);
    }

    onAltmetricCancel(event) {}

    get haveActionsOrMenuActions() {
        const haveActions =
            this.actions && Object.keys(this.actions).length > 0;
        const haveMenuActions =
            this.menuActions && Object.keys(this.menuActions).length > 0;
        return haveActions || haveMenuActions;
    }
}

export interface DateLine {
    code: string;
    value: string;
}
