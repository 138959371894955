<mulo-prologue>
    <mulo-centered-block-layout class="collections-header" width="full">
        <h1>
            <mat-icon muloSvgViewbox svgIcon="bookmark-star"></mat-icon>
            <span>{{ 'research.search.collections' | translate }}</span>
        </h1>
    </mulo-centered-block-layout>
</mulo-prologue>

<ng-container *ngIf="featuredCollections?.length">
    <h2 class="collection-grouping">{{ 'research.portal.featured.collections' | translate }}</h2>
    <mulo-centered-block-layout class="padding-sm" width="auto">
        <ng-container
            *ngTemplateOutlet="
                collectionBlock;
                context: { collections: featuredCollections, paginationConfig: featuredPaginationConfig }
            "
        ></ng-container>
    </mulo-centered-block-layout>
</ng-container>

<h2 *ngIf="collections?.length && featuredCollections?.length" class="collection-grouping">
    {{ 'research.portal.all.collections' | translate }}
</h2>

<div
    (muloStickyScrolled)="toolbarScrolled = $event"
    (muloStickyStuck)="toolbarStuck = $event"
    *ngIf="collections?.length > 0"
    @heightInAnimation
    @heightOutAnimation
    [class.is-scrolled]="toolbarScrolled"
    [class.is-stuck]="toolbarStuck"
    class="floating-toolbar"
    muloSticky
    muloStickyOffsetTop="1rem"
>
    <mulo-floating-toolbar [disabled]="searchInProgress" [shadow]="toolbarStuck || toolbarScrolled">
        <div class="padding-left-sm" slot="toolbar-left">
            <span cdkAriaLive="polite" class="toolbar-text">
                {{
                    'research.profiles.search.results.toolbar'
                        | translate
                            : {
                                  start: (currentPageStart | number),
                                  end: (currentPageEnd | number),
                                  total: (collections?.length | number)
                              }
                }}
            </span>
        </div>

        <mulo-floating-toolbar-buttons *ngIf="toolbarStuck" slot="toolbar-right">
            <mulo-floating-toolbar-separator style="height: 1rem"></mulo-floating-toolbar-separator>
            <button
                (click)="scrollToTop()"
                @widthOutAnimation
                [@widthInAnimation]="{
                    value: ':enter',
                    params: { startScale: '1' }
                }"
                [attr.aria-label]="'research.portal.fulldisplay.backToTopButton' | translate"
                class="margin-zero"
                mat-icon-button
            >
                <mat-icon svgIcon="arrow-up"></mat-icon>
            </button>
        </mulo-floating-toolbar-buttons>
    </mulo-floating-toolbar>
</div>

<mulo-centered-block-layout class="padding-sm" width="auto">
    <ng-container
        *ngTemplateOutlet="collectionBlock; context: { collections: collections, paginationConfig: paginationConfig }"
    ></ng-container>
    <div *ngIf="pageCount > 1" class="pagination-wrap">
        <mulo-floating-toolbar class="margin-top-md">
            <div slot="toolbar-middle">
                <pagination-template
                    #p="paginationApi"
                    (pageChange)="pageChange($event)"
                    [id]="paginationConfig.id"
                    [maxSize]="7"
                >
                    <mulo-paging
                        [data]="p"
                        [directionLinksAsIcons]="media.is('xs')"
                        [hideLastPage]="collections?.length > 0"
                        [nextLabel]="'research.search.results.next' | translate"
                        [previousLabel]="'research.search.results.previous' | translate"
                        [screenReaderJumpLabel]="'research.aria.results.page.jump.button' | translate"
                        id="pagination"
                    ></mulo-paging>
                </pagination-template>
            </div>
        </mulo-floating-toolbar>
    </div>
</mulo-centered-block-layout>

<ng-template #collectionBlock let-collections="collections" let-paginationConfig="paginationConfig">
    <div class="search-results-wrapper">
        <ng-container *ngIf="collections?.length > 0">
            <mulo-brief
                *ngFor="let collection of collections | paginate: paginationConfig"
                [disabled]="searchInProgress"
            >
                <div class="collection-title" slot="pre-title">
                    <mat-icon muloSvgViewbox svgIcon="bookmark-star"></mat-icon>
                    <a
                        [queryParams]="{ institution }"
                        [routerLink]="collection.permalink"
                        class="collection-title-line"
                    >
                        {{ 'research.collection.' + collection.code + '.name' | translate }}
                    </a>
                </div>

                <mulo-expandable
                    *ngIf="'research.collection.' + collection.code + '.description' | translate"
                    [collapseLabel]="'research.results.description.collapse' | translate"
                    [descriptionLabel]="'research.collection.' + collection.code + '.name' | translate"
                    [ratio]="8"
                    buttonPosition="right"
                    expandLabel="...{{ 'research.results.description.expand' | translate }}"
                >
                    @if (collection.image) {
                        <mulo-thumbnail [thumbs]="[{ alt: '', url: collection.image }]" width="10rem"></mulo-thumbnail>
                    }
                    <p
                        [innerHTML]="'research.collection.' + collection.code + '.description' | translate"
                        class="collection-desc"
                    ></p>
                </mulo-expandable>
            </mulo-brief>
        </ng-container>
    </div>
</ng-template>
