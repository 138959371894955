import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'defaultTranslate',
    pure: false
})

export class DefaultTranslatePipe extends TranslatePipe implements PipeTransform{



    constructor(private translateService: TranslateService,
                _ref: ChangeDetectorRef,) {
        super(translateService, _ref);
    }

    transform(query: string, ...args: any[]): any {
        try {
            let translation =  super.transform(query);
            if ((args.length > 0) && (!translation || args[0].forceDefault)){
                translation = args[0].default;
            }
            return translation;
        } catch (e) {
            return '';
        }

    }
}
