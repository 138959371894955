export class ResourceTypeTemplateKeys {
    public static allDepositKeys = ['forSubject', 'seoSubject', 'toaSubject'];

    static keySets = {
        base: [
            'authors',
            'category',
            'cleanReservedRegisterDoi',
            'comments',
            'copyright',
            'dayCopyrighted',
            'description',
            'doi',
            'filePath',
            'grants',
            'keywords',
            'languages',
            'links',
            'localFields',
            'mmsId',
            'monthCopyrighted',
            'note',
            'owner',
            'proxy',
            'proxyName',
            'researcherReservedDoi',
            'subjects',
            'title',
            'type',
            'userPrimaryID',
            'yearCopyrighted',
        ],
        address: [
            'address',
            'boundingBoxEast',
            'boundingBoxNorth',
            'boundingBoxSouth',
            'boundingBoxWest',
            'coverage',
            'enableAddress',
            'enableBoundingBox',
            'enablePoint',
            'pointLatitude',
            'pointLongitude',
        ],
        book: [
            'eisbn',
            'isPartOfIssn',
            'isbn',
            'peerReviewed',
            'publicationPlace',
            'publisher',
            'seasonPublished',
        ],
        conference: [
            'conferenceEndDay',
            'conferenceEndMonth',
            'conferenceEndYear',
            'conferenceLocation',
            'conferenceName',
            'conferenceNumber',
            'conferenceStartDay',
            'conferenceStartMonth',
            'conferenceStartYear',
        ],
        event: [
            'eventEndDay',
            'eventEndMonth',
            'eventEndYear',
            'eventLocation',
            'eventName',
            'eventStartDay',
            'eventStartMonth',
            'eventStartYear',
            'eventType',
        ],
        openAccess: ['openAccess', 'openAccessStatus'],
        dmy: ['date', 'day', 'month', 'year'],
        dmyPresented: ['dayPresented', 'monthPresented', 'yearPresented'],
        titles: ['subtitle', 'titleAlternative'],
    };

    public static journalArticleKeys = [
        ...ResourceTypeTemplateKeys.keySets.base,
        ...ResourceTypeTemplateKeys.keySets.openAccess,
        ...ResourceTypeTemplateKeys.keySets.dmy,
        ...ResourceTypeTemplateKeys.keySets.titles,
        'eissn',
        'isPartOfEissn',
        'isPartOfIsbn',
        'isPartOfIssn',
        'isPartOfIssue',
        'isPartOfNlmAbbrev',
        'isPartOfPages',
        'isPartOfVolume',
        'issn',
        'peerReviewed',
        'pmcid',
        'pmid',
        'publisher',
        'relationTitle',
        'seasonPublished',
        'status',
    ];
    public static bookChapterKeys = [
        ...ResourceTypeTemplateKeys.keySets.base,
        ...ResourceTypeTemplateKeys.keySets.book,
        ...ResourceTypeTemplateKeys.keySets.dmy,
        ...ResourceTypeTemplateKeys.keySets.openAccess,
        ...ResourceTypeTemplateKeys.keySets.titles,
        'edition',
        'isPartOfDoi',
        'isPartOfEisbn',
        'isPartOfIsbn',
        'isPartOfIssue',
        'isPartOfPages',
        'isPartOfVolume',
        'relationTitle',
        'series',
        'seriesNumber',
        'status',
    ];

    public static bookKeys = [
        ...ResourceTypeTemplateKeys.keySets.base,
        ...ResourceTypeTemplateKeys.keySets.book,
        ...ResourceTypeTemplateKeys.keySets.dmy,
        ...ResourceTypeTemplateKeys.keySets.openAccess,
        ...ResourceTypeTemplateKeys.keySets.titles,
        'edition',
        'pages',
        'series',
        'status',
        'titleTranslated',
    ];

    public static conferenceProceedingKeys = [
        ...ResourceTypeTemplateKeys.keySets.base,
        ...ResourceTypeTemplateKeys.keySets.book,
        ...ResourceTypeTemplateKeys.keySets.conference,
        ...ResourceTypeTemplateKeys.keySets.openAccess,
        ...ResourceTypeTemplateKeys.keySets.dmy,
        ...ResourceTypeTemplateKeys.keySets.titles,
        'eissn',
        'isPartOfDoi',
        'isPartOfEisbn',
        'isPartOfEissn',
        'isPartOfIsbn',
        'isPartOfIssue',
        'isPartOfPages',
        'isPartOfVolume',
        'issn',
        'pmcid',
        'pmid',
        'relationTitle',
        'status',
    ];

    public static conferenceKeys = [
        ...ResourceTypeTemplateKeys.keySets.base,
        ...ResourceTypeTemplateKeys.keySets.conference,
        ...ResourceTypeTemplateKeys.keySets.dmyPresented,
        'audience',
        'pages',
        'size',
    ];

    public static presentationKeys = [
        ...ResourceTypeTemplateKeys.keySets.base,
        ...ResourceTypeTemplateKeys.keySets.event,
        ...ResourceTypeTemplateKeys.keySets.dmyPresented,
        'audience',
        'pages',
        'size',
    ];

    public static defaultKeys = [
        ...ResourceTypeTemplateKeys.keySets.base,
        ...ResourceTypeTemplateKeys.keySets.address,
        ...ResourceTypeTemplateKeys.keySets.book,
        ...ResourceTypeTemplateKeys.keySets.conference,
        ...ResourceTypeTemplateKeys.keySets.event,
        ...ResourceTypeTemplateKeys.keySets.openAccess,
        ...ResourceTypeTemplateKeys.keySets.titles,
        'audience',
        'datePublished',
        'dayCreatedEnd',
        'dayCreatedStart',
        'descriptionOther',
        'edition',
        'format',
        'isPartOfDoi',
        'isPartOfEisbn',
        'isPartOfEissn',
        'isPartOfIsbn',
        'isPartOfIssue',
        'isPartOfNlmAbbrev',
        'isPartOfPages',
        'isPartOfVolume',
        'methods',
        'monthCreatedEnd',
        'monthCreatedStart',
        'pages',
        'pmcid',
        'pmid',
        'relationTitle',
        'resourceSubtype',
        'series',
        'seriesNumber',
        'size',
        'technicalInformation',
        'titleOther',
        'titleTranslated',
        'yearCreatedEnd',
        'yearCreatedStart',
    ];

    public static datasetKeys = [
        ...ResourceTypeTemplateKeys.keySets.base,
        ...ResourceTypeTemplateKeys.keySets.address,
        ...ResourceTypeTemplateKeys.keySets.titles,
        'datePublished',
        'dayCollectedEnd',
        'dayCollectedStart',
        'descriptionOther',
        'methods',
        'monthCollectedEnd',
        'monthCollectedStart',
        'publisher',
        'resourceSubtype',
        'seasonPublished',
        'size',
        'technicalInformation',
        'yearCollectedEnd',
        'yearCollectedStart',
    ];
}
