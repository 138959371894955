import {
  style,
  animate,
  query,
  animateChild,
  group,
  animation,
  transition,
  useAnimation,
} from '@angular/animations';
import * as easings from './easings';

const optional = { optional: true };
/* Route animation BASELINE */
const routeStyleBasline = {
  position: 'relative',
};
const routeAnimationBaseline = [
  style({
    position: 'absolute',
    insetBlockStart: 0,
    insetInlineStart: 0,
    width: '100%',
    opacity: 0,
  }),
];

/* SLIDE animation */
export const RouteSlideCoreAnimation = animation(
  [
    style(routeStyleBasline),
    query(':enter', routeAnimationBaseline),
    query(':enter', [style({ transform: 'translate({{ slideFrom }})' })]),
    group([
      query(
        ':leave',
        [
          animate(
            '{{ timing }} ' + easings.OutQuint,
            style({
              transform: 'translate({{ slideTo }})',
              opacity: 0,
            })
          ),
        ],
        optional
      ),
      query(
        ':enter',
        [
          animate(
            '{{ timing }} ' + easings.OutQuint,
            style({
              transform: 'translate(0, 0)',
              opacity: 1,
            })
          ),
        ],
        optional
      ),
    ]),
    query(':leave', animateChild(), optional),
    query(':enter', animateChild(), optional),
  ],
  {
    params: {
      slideFrom: '100px, 0',
      slideTo: '-30px, 0',
      timing: '500ms',
    },
  }
);

export const RouteSlideParams = {
  right: {
    params: {
      slideFrom: '100px, 0',
      slideTo: '-30px, 0',
    },
  },
  left: {
    params: {
      slideFrom: '-100px, 0',
      slideTo: '30px, 0',
    },
  },
  up: {
    params: {
      slideFrom: '0, 50px',
      slideTo: '0, -20px',
    },
  },
  down: {
    params: {
      slideFrom: '0, -50px',
      slideTo: '0, 20px',
    },
  },
};

export const RouteSlideAnimation = [
  transition(
    ':increment',
    useAnimation(RouteSlideCoreAnimation, RouteSlideParams.right)
  ),
  transition(
    ':decrement',
    useAnimation(RouteSlideCoreAnimation, RouteSlideParams.left)
  ),
];

/* SCALE animation */
export const RouteScaleCoreAnimation = animation(
  [
    style(routeStyleBasline),
    query(':enter', routeAnimationBaseline),
    query(':enter', [style({ transform: 'scale({{ scaleFrom }})' })]),
    group([
      query(
        ':leave',
        [
          animate(
            '{{ timing }} ' + easings.OutQuint,
            style({
              transform: 'scale({{ scaleTo }})',
              opacity: 0,
            })
          ),
        ],
        optional
      ),
      query(
        ':enter',
        [
          animate(
            '{{ timing }} ' + easings.OutQuint,
            style({
              transform: 'scale(1)',
              opacity: 1,
            })
          ),
        ],
        optional
      ),
    ]),
    query(':leave', animateChild(), optional),
    query(':enter', animateChild(), optional),
  ],
  {
    params: {
      scaleFrom: '1.2',
      scaleTo: '.8',
      timing: '500ms',
    },
  }
);
export const RouteScaleParams = {
  in: {
    params: {
      scaleFrom: '1.2',
      scaleTo: '.8',
    },
  },
  out: {
    params: {
      scaleFrom: '.8',
      scaleTo: '1.1',
    },
  },
};

export const RouteScaleAnimation = [
  transition(
    ':increment',
    useAnimation(RouteScaleCoreAnimation, RouteScaleParams.in)
  ),
  transition(
    ':decrement',
    useAnimation(RouteScaleCoreAnimation, RouteScaleParams.out)
  ),
];
