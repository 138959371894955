import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'esp-deposit-publisher',
  templateUrl: './deposit-publisher.component.html',
  styleUrls: ['./deposit-publisher.component.scss'],
})
export class DepositPublisherComponent implements OnInit, OnDestroy {
  @Input() placeholder: string;

  private publisherDestroy = new Subject<void>();

  constructor(public depositFormDataService: DepositFormDataService) { }

  ngOnInit() {
    this.onChanges();
  }

  onChanges(): void {
    this.depositFormDataService.publisher.valueChanges
      .pipe(takeUntil(this.publisherDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.PUBLISHER
          );
        }
      });
  }

  get isAutoPopulated() {
    return this.depositFormDataService.isKeyAutoPopulated(
      AutoPopulateKeys.PUBLISHER
    );
  }

  ngOnDestroy() {
    if (!this.publisherDestroy.isStopped) {
      this.publisherDestroy.next();
      //unsubscribe from the subject itself
      this.publisherDestroy.unsubscribe();
    }
  }
}
