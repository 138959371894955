import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { Params, RouterLink } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '@exl-ng/mulo-core';
import { MomentModule } from 'ngx-moment';
import { Dir } from '@angular/cdk/bidi';
import {
  ElementAsHeadingDirective,
  HtmlSanitizePipe,
  ThumbnailComponent,
} from '@exl-ng/mulo-common';
import { NgIf } from '@angular/common';

@Component({
  selector: 'mulo-brief',
  templateUrl: './brief.component.html',
  styleUrls: ['./brief.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    ThumbnailComponent,
    Dir,
    ElementAsHeadingDirective,
    RouterLink,
    HtmlSanitizePipe,
    MomentModule,
  ],
})
export class BriefComponent {
  @HostBinding('class.mulo-brief') hostClass = true;
  @HostBinding('class.is-minimal') @Input() minimal = false;
  @HostBinding('class.is-hidden') @Input() hidden = false;
  @HostBinding('class.is-disabled') @Input() disabled = false;
  @HostBinding('class.is-hoverable') @Input() hoverable = true;
  @HostBinding('class.is-mouseover') mouseOver = false;
  @HostBinding('class.is-title-cutoff') @Input() titleCutoff = false;

  @Input() type;
  @Input() postType;
  @Input() title;
  @Input() titleUrl;
  @Input() thumbs: any[];
  @Input() thumbsWidth = '50px';
  @Input() titleAsLink = false;
  @Input() titleClickable: boolean;
  @Input() titleHeaderLevel: number;
  @Input() images: any[];
  @Input() status: string | string[];
  @Input() created: Date;
  @Input() modified: Date;
  @Input() dateLabel: string;
  @Input() href: string;
  @Input() routerLinkUrl: string;
  @Input() queryParams: Params;
  @Input() id = Math.random().toString(36).substring(2);

  @Input() labelOpenInNewWindow: string;

  highlightHover = false;
  maxVisibleAuthors = 10;
  hostMinHeight;

  @Output() titleClick: EventEmitter<any> = new EventEmitter();
  @Output() actionClick: EventEmitter<any> = new EventEmitter();
  @Output() authorClick: EventEmitter<any> = new EventEmitter();

  @HostListener('mouseover') onMouseEnter() {
    this.mouseOver = true;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.mouseOver = false;
  }

  constructor(
    public media: MediaService,
    private sanitizer: DomSanitizer,
  ) {
    this.labelOpenInNewWindow = `View ${this.title}, opens in a new window`;
  }

  onTitleClick(event) {
    event?.preventDefault();
    event?.stopPropagation();
    this.titleClick.emit(event);
  }

  onThumbLoad(height) {
    this.hostMinHeight = this.sanitizer.bypassSecurityTrustStyle(
      `calc(${height}px + 4rem)`,
    );
  }
}
