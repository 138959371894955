import { LongTextDialogComponent } from './../../../parts/dialogs/long-text-dialog/long-text-dialog.component';
import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { GrowInAnimation } from '@exl-ng/mulo-common';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationHandlerService } from '../../../core/configuration-handler.service';
import { MappingTable } from '../../../shared/interfaces/mapping-table.interface';
import { DepositLastStepService } from './deposit-last-step.service';
import { UrlUtils } from '../../../shared/utils/url.utils';
import { DomSanitizer } from '@angular/platform-browser';
import { WindowRefService } from '../../../core/window-ref.service';
import { DateUtils } from '../../../shared/utils/date.utils';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'esp-deposit-last-step',
    templateUrl: './deposit-last-step.component.html',
    styleUrls: ['./deposit-last-step.component.scss'],
    animations: [GrowInAnimation],
})
export class DepositLastStepComponent implements OnInit, OnDestroy {
    notesFocus = false;
    termsOfUseConf = this.configurationHandlerService.getMappingTable(
        'EsploroDepositPolicyConfiguration'
    ) as MappingTable[];
    institutionCode: string;
    nativeWindow: any;
    copyrightsFocus = false;
    hideTerms = false;

    dateCopyrightedDestroy = new Subject<void>();

    @Output() jumpToSection: EventEmitter<any> = new EventEmitter();

    constructor(
        public depositLastStepService: DepositLastStepService,
        public depositFormDataService: DepositFormDataService,
        private matDialog: MatDialog,
        public configurationHandlerService: ConfigurationHandlerService,
        private sanitizer: DomSanitizer,
        private windowRef: WindowRefService
    ) {
        this.institutionCode = UrlUtils.getParam('institution');
        this.nativeWindow = windowRef.nativeWindow;
    }

    ngOnInit() {
        this.onChangesDateCopyrighted();
        this.hideTerms =
            this.configurationHandlerService.getCustomerParameter(
                'hide_esploro_researcher_depositPolicy_terms'
            ) === 'true';
        if (this.hideTerms) {
            this.depositFormDataService.updateTermsOfUse(true);
        }
    }

    onChangesDateCopyrighted(): void {
        this.depositFormDataService.dateCopyrighted.valueChanges
            .pipe(takeUntil(this.dateCopyrightedDestroy))
            .subscribe((value) => {
                if (!this.depositFormDataService.startPopulating) {
                    this.depositFormDataService.removeKeyFromAutoPopulatedArray(
                        AutoPopulateKeys.DATE_COPYRIGHTED
                    );
                }
                if (!this.depositFormDataService.dateCopyrighted.value) {
                    this.depositFormDataService.updateYearCopyrighted('');
                    this.depositFormDataService.updateMonthCopyrighted('');
                    this.depositFormDataService.updateDayCopyrighted('');
                } else {
                    const date = new Date(
                        this.depositFormDataService.dateCopyrighted.value
                    );
                    this.depositFormDataService.updateYearCopyrighted(
                        DateUtils.getYear(date)
                    );
                    this.depositFormDataService.updateMonthCopyrighted(
                        DateUtils.getMonth(date)
                    );
                    this.depositFormDataService.updateDayCopyrighted(
                        DateUtils.getDay(date)
                    );
                }
            });
    }

    get isDateCopyrightedAutoPopulated() {
        return this.depositFormDataService.isKeyAutoPopulated(
            AutoPopulateKeys.DATE_COPYRIGHTED
        );
    }

    showSummaryNotes() {
        this.depositLastStepService.summaryNotes = true;
        setTimeout(() => {
            this.notesFocus = true;
        }, 200);
    }

    getNotesSectionTitle() {
        return this.depositLastStepService.summaryNotes
            ? 'research.notes.title.summary'
            : '';
    }

    getCopyrightSectionTitle() {
        return this.depositLastStepService.copyrightsNotes
            ? 'research.copyright.title.summary'
            : '';
    }

    showTermsDialog(event) {
        event.preventDefault();
        const publicationTerms = this.termsOfUseConf.filter(
            (term) => term.source1 === 'Publications'
        )[0];
        const datasetTerms = this.termsOfUseConf.filter(
            (term) => term.source1 === 'Datasets'
        )[0];
        const defaultTerms = this.termsOfUseConf.filter(
            (term) => term.source1 === 'Default'
        )[0];
        if (this.depositFormDataService.isPublication()) {
            if (publicationTerms['enabled'] === 'true') {
                if (publicationTerms['source2'] === 'text') {
                    this.getConfigTermsOfUse(
                        'com.exlibris.esploro.backoffice.publications'
                    );
                } else {
                    this.openLinkInNewTab(publicationTerms['source3']);
                }
            } else {
                this.openDedault(defaultTerms);
            }
        } else if (this.depositFormDataService.isDataset()) {
            if (datasetTerms['enabled'] === 'true') {
                if (datasetTerms['source2'] === 'text') {
                    this.getConfigTermsOfUse(
                        'com.exlibris.esploro.backoffice.datasets'
                    );
                } else {
                    this.openLinkInNewTab(datasetTerms['source3']);
                }
            } else {
                this.openDedault(defaultTerms);
            }
        } else {
            this.openDedault(defaultTerms);
        }
    }

    openDedault(defaultTerms) {
        if (defaultTerms['source2'] === 'text') {
            this.getConfigTermsOfUse('com.exlibris.esploro.backoffice.default');
        } else {
            this.openLinkInNewTab(defaultTerms['source3']);
        }
    }

    getConfigTermsOfUse(configFileName) {
        this.depositLastStepService
            .getConfigTermsOfUse(configFileName, this.institutionCode)
            .subscribe(
                (data) => {
                    this.opendialog(data);
                },
                (error) => console.error(error)
            );
    }

    openLinkInNewTab(link) {
        this.sanitizer.bypassSecurityTrustUrl(link);
        this.nativeWindow.open(link, '_blank');
    }

    opendialog(data) {
        const dialogRef = this.matDialog.open(LongTextDialogComponent, {
            data: {
                title: 'Terms & Conditions',
                body: data,
                actions: {
                    cancel: 'research.terms.close',
                    confirm: 'research.terms.agree',
                },
                actionFocus: 'confirm',
            },
        });

        dialogRef.afterClosed().subscribe((response) => {
            if (response === 'ok') {
                this.depositFormDataService.updateTermsOfUse(true);
            }
        });
    }

    jump($event) {
        this.jumpToSection.emit($event);
    }

    showCopyrightsNotes() {
        this.depositLastStepService.copyrightsNotes = true;
        setTimeout(() => {
            this.copyrightsFocus = true;
        }, 200);
    }

    ngOnDestroy() {
        if (!this.dateCopyrightedDestroy.isStopped) {
            this.dateCopyrightedDestroy.next();
            //unsubscribe from the subject itself
            this.dateCopyrightedDestroy.unsubscribe();
        }
    }
}
