export class PortalHomepageSections {
    public static ABOUT = 'home.page.about';
    public static SCOPES = 'home.page.scopes';
    public static ENTITIES = 'home.page.entities';
    public static PICTURES = 'home.page.pictures';
    public static DOWNLOAD_MAP = 'home.page.download.map';
    public static STATISTICS = 'home.page.statistics';
    public static TOP_TEN = 'home.page.topten';
    public static WORKS_ADDED = 'home.page.worksadded';
    public static SEARCH = 'home.page.search';
    public static CUSTOM_LINK_1 = 'home.page.customlink1';
    public static CUSTOM_LINK_2 = 'home.page.customlink2';
    public static CUSTOM_LINK_3 = 'home.page.customlink3';
    public static CUSTOM_LINK_4 = 'home.page.customlink4';
    public static CUSTOM_LINK_5 = 'home.page.customlink5';
    public static FEATURED_RESEARCHER = 'home.page.featuredresearcher';
    public static FEATURED_COLLECTIONS = 'home.page.featuredcollections';
}

export class PortalMetrics {
    public static COLORS_SCHEMA_OLD = [
        '#F47721',
        '#71B48D',
        '#4bd294',
        '#FFAE03',
        '#127273',
        '#62ACC8',
    ];
    public static COLORS_SCHEMA = [
        '#ed640a',
        '#509e72',
        '#4299b9',
        '#713e5a',
        '#ab8b17',
        '#127273',
    ];
    public static OTHERS = 'research.portal.download.map.others';
    public static NUM_OF_ENTRIES_IN_WORKS_BY_YEARS_SECTION = 12;
}

export enum TopTenOption {
    NEWEST = 'NEWEST',
    MOST_DOWNLOADED = 'MOST_VIEWED',
    MOST_METADATA_VIEWED = 'MOST_METADATA_VIEWED',
    SET_ONE = 'HIGHLIGHTS_SET_1',
    SET_TWO = 'HIGHLIGHTS_SET_2',
    SET_THREE = 'HIGHLIGHTS_SET_3',
}
