<button
  mat-button
  [class.mat-stroked-button]="flavor === 'stroked'"
  [class.mat-raised-button]="flavor === 'raised'"
  [class.mat-flat-button]="flavor === 'flat'"
  (click)="toggle()"
  [color]="color"
  [attr.aria-label]="ariaLabel"
  #toggleButton
>
  <div
    class="content-block content-left"
    #left
    [class.has-padding]="leftPadding"
    [class.is-active]="state === 'left'"
  >
    <ng-content select="[slot='left']"></ng-content>
  </div>
  <div
    class="content-block content-right"
    #right
    [class.has-padding]="rightPadding"
    [class.is-active]="state === 'right'"
  >
    <ng-content select="[slot='right']"></ng-content>
  </div>
  <div
    class="sliding-focus"
    [@toggle]="{value: state, params: {left: leftWidth, widthLeft: leftWidth, widthRight: rightWidth}}"
  ></div>
</button>
