<div (keydown.space)="preventDrag($event)" class="header">
    <ng-content select="[slot='file-list-prefix']"></ng-content>
    <span *ngIf="!minimal" class="item-index semibold">{{ index + 1 }}.</span>
    <button
        (click)="toggle()"
        [attr.aria-controls]="body.id"
        [attr.aria-expanded]="expanded"
        class="expand-button"
        disableRipple
        mat-flat-button
        type="button"
    >
        <div [class.header-text--text-only]="!hasBodyContent" class="header-text">
            <span [id]="'expansion-header-title-' + _id" class="title">
                <span class="item-name">
                    <!-- files -->
                    <ng-container *ngIf="!item.url">
                        <span class="item-basename semibold">
                            <ng-container *ngIf="!minimal">{{ item.baseName }}</ng-container>
                            <ng-container *ngIf="minimal">{{ item.fullName }}</ng-container>
                        </span>
                        <span *ngIf="!minimal" class="item-ext">
                            &nbsp;
                            <mat-icon *ngIf="item.profile" svgIcon="file-{{ item.profile }}"></mat-icon>
                            <span>{{ item.extension }}</span>
                        </span>
                        <span class="item-size">&nbsp;{{ item.sizeFloat }}</span>
                    </ng-container>

                    <!-- links -->
                    <span *ngIf="item.url" class="link-url">{{ item.url }}</span>
                </span>

                <span *ngIf="item.uploadProgress && item.uploadProgress < 100 && !minimal" class="progress-count">
                    <mat-icon svgIcon="upload-arrow"></mat-icon>
                    <span class="semibold">{{ item.uploadProgress }}%</span>
                </span>

                <span *ngIf="item.uploadProgress === 100" class="item-status">
                    <mat-icon class="color-positive" svgIcon="check"></mat-icon>
                </span>
                <mat-icon *ngIf="hasBodyContent" class="expand-icon" muloSvgViewbox svgIcon="chevron-down"></mat-icon>
            </span>

            <mat-progress-bar
                *ngIf="item.uploadProgress < 100"
                [bufferValue]="0"
                [value]="item.uploadProgress"
                mode="determinate"
            >
            </mat-progress-bar>
        </div>
    </button>
    <button
        (click)="onEdit($event, item)"
        *ngIf="!minimal && item.url"
        [attr.aria-label]="'research.aria.deposit.links.list.edit.link' | translate"
        mat-icon-button
        type="button"
    >
        <mat-icon svgIcon="pencil"></mat-icon>
    </button>
    <button
        (click)="onRemove($event, item, index)"
        *ngIf="!minimal || item.url"
        [attr.aria-label]="'research.aria.deposit.files.and.links.remove.item' | translate"
        mat-icon-button
        style="z-index: 1; position: relative"
        type="button"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>
<div
    #body
    [attr.aria-labelledby]="'expansion-header-title-' + _id"
    [id]="'expansion-body-' + _id"
    [style.display]="expanded ? '' : 'none'"
    class="content"
    role="region"
>
    <ng-content></ng-content>
</div>
