let nextUniqId = 0;

export class SectionClass {
    protected _id: number;
    
    private _sectionId: string;
    get sectionId(): string {
        return this._sectionId;
    }
    set sectionId(value: string) {
        this._sectionId = value;
    }
    
    constructor(idPrefix?: string) {
        this._id = nextUniqId++;
        const prefixLabel = idPrefix ? `section-${idPrefix}` : 'section';
        this.sectionId = `${prefixLabel}-${this._id}`;
    }


}