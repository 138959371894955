import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PortionMethod } from '../portion-list.service';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'mulo-portion-list-toolbar',
    templateUrl: './portion-list-toolbar.component.html',
    styleUrls: ['./portion-list-toolbar.component.scss'],
    host: { class: 'mulo-portion-list-toolbar' },
    standalone: true,
    imports: [
        MatIconButton,
        MatTooltip,
        MatIcon,
    ],
})
export class PortionListToolbarComponent {
  @Input() labelDistribute = 'Distribute';
  @Input() labelDistDesc = 'Distribute apportionments in a descending order';
  @Input() labelDistEq = 'Distribute apportionments equally';
  @Input() labelDistAsc = 'Distribute apportionments in an ascending order';

  @Output() clicked = new EventEmitter<PortionMethod>();

  constructor() {}

  onClick(method: PortionMethod) {
    this.clicked.emit(method);
  }
}
