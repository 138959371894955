import {ResourceType} from '../configurations/resource-type.constant';
import {ResourceTypeModel} from '../interfaces/resource-type.model';

export class ResourceTypeUtils {

    public static isPublicationJournalArticle(resourceType): boolean{
        return resourceType === ResourceType.PUBLICATION_JOURNAL_ARTICLE;
    }

    public static isPublicationBookReview(resourceType): boolean{
        return resourceType === ResourceType.PUBLICATION_BOOK_REVIEW;
    }

    public static isPublicationBookChapter(resourceType): boolean{
        return resourceType === ResourceType.PUBLICATION_BOOK_CHAPTER;
    }

    public static isPublicationConferenceProceedings(resourceType): boolean{
        return resourceType === ResourceType.PUBLICATION_CONFERENCE_PROCEEDING;
    }

    public static isPublicationBook(resourceType): boolean{
        return resourceType === ResourceType.PUBLICATION_BOOK;
    }

    public static isPublication(resourceType): boolean{
        return resourceType.indexOf('publication.') !== -1;
    }

    public static isDataset(resourceType): boolean{
        return resourceType === ResourceType.DATASET;
    }

    public static isConference(resourceType): boolean{
        return resourceType === ResourceType.CONFERENCE_PAPER || resourceType === ResourceType.CONFERENCE_CONFERENCE_PRESENTATION
            || resourceType === ResourceType.CONFERENCE_POSTER || resourceType === ResourceType.CONFERENCE_PROGRAM;
    }

    public static isPresentation(resourceType): boolean{
        return resourceType === ResourceType.CONFERENCE_PRESENTATION || resourceType === ResourceType.CONFERENCE_EVENTPOSTER;
    }

    public static isPatent(resourceType): boolean{
        return resourceType === ResourceType.PATENT;
    }

    public static isConferencePresentation(resourceType): boolean{
        return resourceType === ResourceType.CONFERENCE_CONFERENCE_PRESENTATION;
    }

    public static isPublicationMoreInformation(resourceType): boolean {
        return resourceType === ResourceType.PUBLICATION_MAGAZINE_ARTICLE || resourceType === ResourceType.PUBLICATION_CONFERENCE_PROCEEDING ||
        resourceType === ResourceType.PUBLICATION_EDITORIAL || resourceType === ResourceType.PUBLICATION_NEWSPAPER_ARTICLE ||
        resourceType === ResourceType.POSTED_CONTENT_ACCEPTED_MANUSCRIPT;
    }

    public static isPublicationAbstract(resourceType): boolean{
        return resourceType === ResourceType.PUBLICATION_ABSTRACT;
    }

    public static convertEsploroRecordResourceTypeToCategoryAndType(resourceType: string){
        if (resourceType === 'PUBLICATION_JOURNAL_ARTICLE'){
            return new ResourceTypeModel('publication', 'publication.journalArticle');
        }
        if (resourceType === 'PUBLICATION_MAGAZINE_ARTICLE'){
            return new ResourceTypeModel('publication', 'publication.magazineArticle');
        }
        if (resourceType === 'PUBLICATION_NEWSPAPER_ARTICLE'){
            return new ResourceTypeModel('publication', 'publication.newspaperArticle');
        }
        if (resourceType === 'PUBLICATION_NEWSLETTER_ARTICLE'){
            return new ResourceTypeModel('publication', 'publication.newsletterArticle');
        }
        if (resourceType === 'PUBLICATION_JOURNAL_ISSUE'){
            return new ResourceTypeModel('publication', 'publication.journalIssue');
        }
        if (resourceType === 'PUBLICATION_BOOK'){
            return new ResourceTypeModel('publication', 'publication.book');
        }
        if (resourceType === 'PUBLICATION_BOOK_CHAPTER'){
            return new ResourceTypeModel('publication', 'publication.bookChapter');
        }
        if (resourceType === 'PUBLICATION_BOOK_REVIEW'){
            return new ResourceTypeModel('publication', 'publication.bookReview');
        }
        if (resourceType === 'PUBLICATION_CONFERENCE_PROCEEDING'){
            return new ResourceTypeModel('publication', 'publication.conferenceProceeding');
        }
        if (resourceType === 'PUBLICATION_DICTIONARY_ENTRY'){
            return new ResourceTypeModel('publication', 'publication.dictionaryEntry');
        }
        if (resourceType === 'PUBLICATION_EDITED_BOOK'){
            return new ResourceTypeModel('publication', 'publication.editedBook');
        }
        if (resourceType === 'PUBLICATION_ENCYCLOPEDIA_ENTRY'){
            return new ResourceTypeModel('publication', 'publication.encyclopediaEntry');
        }
        if (resourceType === 'PUBLICATION_REPORT'){
            return new ResourceTypeModel('publication', 'publication.report');
        }
        if (resourceType === 'PUBLICATION_TRANSLATION'){
            return new ResourceTypeModel('publication', 'publication.translation');
        }
        if (resourceType === 'PUBLICATION_ANNOTATION'){
            return new ResourceTypeModel('publication', 'publication.annotation');
        }
        if (resourceType === 'PUBLICATION_BIBLIOGRAPHY'){
            return new ResourceTypeModel('publication', 'publication.bibliography');
        }
        if (resourceType === 'PUBLICATION_LETTER'){
            return new ResourceTypeModel('publication', 'publication.letter');
        }
        if (resourceType === 'PUBLICATION_TECHNICAL_DOCUMENTATION'){
            return new ResourceTypeModel('publication', 'publication.technicalDocumentation');
        }
        if (resourceType === 'PUBLICATION_EDITORIAL'){
            return new ResourceTypeModel('publication', 'publication.editorial');
        }
        if (resourceType === 'PUBLICATION_ABSTRACT'){
            return new ResourceTypeModel('publication', 'publication.abstract');
        }
        if (resourceType === 'CONFERENCE_PAPER'){
            return new ResourceTypeModel('conference', 'conference.conferencePaper');
        }
        if (resourceType === 'CONFERENCE_PRESENTATION'){
            return new ResourceTypeModel('conference', 'conference.presentation');
        }
        if (resourceType === 'CONFERENCE_CONFERENCE_PRESENTATION'){
            return new ResourceTypeModel('conference', 'conference.conferencePresentation');
        }
        if (resourceType === 'CONFERENCE_POSTER'){
            return new ResourceTypeModel('conference', 'conference.conferencePoster');
        }
        if (resourceType === 'CONFERENCE_PROGRAM'){
            return new ResourceTypeModel('conference', 'conference.conferenceProgram');
        }
        if (resourceType === 'CONFERENCE_EVENTPOSTER'){
            return new ResourceTypeModel('conference', 'conference.eventposter');
        }
        if (resourceType === 'DATASET_DATASET'){
            return new ResourceTypeModel('dataset', 'dataset.dataset');
        }
        if (resourceType === 'SOFTWARE_CODE'){
            return new ResourceTypeModel('software', 'software.code');
        }
        if (resourceType === 'SOFTWARE_WORKFLOW'){
            return new ResourceTypeModel('software', 'software.workflow');
        }
        if (resourceType === 'INTERACTIVE_RESOURCE_WEBSITE'){
            return new ResourceTypeModel('interactiveResource', 'interactiveResource.website');
        }
        if (resourceType === 'INTERACTIVE_RESOURCE_BLOG'){
            return new ResourceTypeModel('interactiveResource', 'interactiveResource.blog');
        }
        if (resourceType === 'INTERACTIVE_RESOURCE_PODCAST'){
            return new ResourceTypeModel('interactiveResource', 'interactiveResource.podcast');
        }
        if (resourceType === 'INTERACTIVE_RESOURCE_VIRTUAL_REALITY_ENVIRONMENT'){
            return new ResourceTypeModel('interactiveResource', 'interactiveResource.virtualRealityEnvironment');
        }
        if (resourceType === 'INTERACTIVE_RESOURCE_WEBINAR'){
            return new ResourceTypeModel('interactiveResource', 'interactiveResource.webinar');
        }
        if (resourceType === 'CREATIVE_WORK_MUSICAL_SCORE'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.musicalScore');
        }
        if (resourceType === 'CREATIVE_WORK_MUSICAL_COMPOSITION'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.musicalComposition');
        }
        if (resourceType === 'CREATIVE_WORK_MUSICAL_PERFORMANCE'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.musicalPerformance');
        }
        if (resourceType === 'CREATIVE_WORK_FICTION'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.fiction');
        }
        if (resourceType === 'CREATIVE_WORK_POETRY'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.poetry');
        }
        if (resourceType === 'CREATIVE_WORK_ESSAY'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.essay');
        }
        if (resourceType === 'CREATIVE_WORK_DRAMA'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.drama');
        }
        if (resourceType === 'CREATIVE_WORK_NON_FICTION'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.nonFiction');
        }
        if (resourceType === 'CREATIVE_WORK_DANCE'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.dance');
        }
        if (resourceType === 'CREATIVE_WORK_CHOREOGRAPHY'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.choreography');
        }
        if (resourceType === 'CREATIVE_WORK_THEATER'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.theater');
        }
        if (resourceType === 'CREATIVE_WORK_FILM'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.film');
        }
        if (resourceType === 'CREATIVE_WORK_SCRIPT'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.script');
        }
        if (resourceType === 'CREATIVE_WORK_NEW_MEDIA'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.newMedia');
        }
        if (resourceType === 'CREATIVE_WORK_DESIGN_AND_ARCHITECTURE'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.designAndArchitecture');
        }
        if (resourceType === 'CREATIVE_WORK_PAINTING'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.painting');
        }
        if (resourceType === 'CREATIVE_WORK_SCULPTURE'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.sculpture');
        }
        if (resourceType === 'CREATIVE_WORK_EXHIBITION_CATALOG'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.exhibitionCatalog');
        }
        if (resourceType === 'CREATIVE_WORK_SET_DESIGN'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.setDesign');
        }
        if (resourceType === 'CREATIVE_WORK_OTHER'){
            return new ResourceTypeModel('creativeWork', 'creativeWork.other');
        }
        if (resourceType === 'OTHER_MAP'){
            return new ResourceTypeModel('other', 'other.map');
        }
        if (resourceType === 'OTHER_MODEL'){
            return new ResourceTypeModel('other', 'other.model');
        }
        if (resourceType === 'OTHER_OTHER'){
            return new ResourceTypeModel('other', 'other.other');
        }
        if (resourceType === 'POSTED_CONTENT_PREPRINT'){
            return new ResourceTypeModel('postedContent', 'postedContent.preprint');
        }
        if (resourceType === 'POSTED_CONTENT_WORKING_PAPER'){
            return new ResourceTypeModel('postedContent', 'postedContent.workingPaper');
        }
        if (resourceType === 'POSTED_CONTENT_ACCEPTED_MANUSCRIPT'){
            return new ResourceTypeModel('postedContent', 'postedContent.acceptedManuscript');
        }
        if (resourceType === 'ETD_UNDERGRADUATE'){
            return new ResourceTypeModel('etd', 'etd.undergraduate');
        }
        if (resourceType === 'ETD_GRADUATE'){
            return new ResourceTypeModel('etd', 'etd.graduate');
        }
        if (resourceType === 'ETD_DOCTORAL'){
            return new ResourceTypeModel('etd', 'etd.doctoral');
        }
        if (resourceType === 'ETD_EXTERNAL_UNDERGRADUATE'){
            return new ResourceTypeModel('etdexternal', 'etdexternal.undergraduate_external');
        }
        if (resourceType === 'ETD_EXTERNAL_GRADUATE'){
            return new ResourceTypeModel('etdexternal', 'etdexternal.graduate_external');
        }
        if (resourceType === 'ETD_EXTERNAL_DOCTORAL'){
            return new ResourceTypeModel('etdexternal', 'etdexternal.doctoral_external');
        }
        if (resourceType === 'PATENT_PATENT'){
            return new ResourceTypeModel('patent', 'patent.patent');
        }
        if (resourceType === 'TEACHING_ASSIGNMENT') {
            return new ResourceTypeModel('teaching', 'teaching.assignment');
        }
        if (resourceType === 'TEACHING_WORKBOOK') {
            return new ResourceTypeModel('teaching', 'teaching.workbook');
        }
        if (resourceType === 'TEACHING_FLASHCARDS') {
            return new ResourceTypeModel('teaching', 'teaching.flashcards');
        }
        if (resourceType === 'TEACHING_LECTURE') {
            return new ResourceTypeModel('teaching', 'teaching.lecture');
        }
        if (resourceType === 'TEACHING_OUTLINE') {
            return new ResourceTypeModel('teaching', 'teaching.outline');
        }
        if (resourceType === 'TEACHING_COURSEMODULE') {
            return new ResourceTypeModel('teaching', 'teaching.coursemodule');
        }
        if (resourceType === 'TEACHING_STUDYGUIDE') {
            return new ResourceTypeModel('teaching', 'teaching.studyguide');
        }
        if (resourceType === 'TEACHING_SYLLABUS') {
            return new ResourceTypeModel('teaching', 'teaching.syllabus');
        }
        if (resourceType === 'TEACHING_ACTIVITY') {
            return new ResourceTypeModel('teaching', 'teaching.activity');
        }
        if (resourceType === 'TEACHING_CASESTUDY') {
            return new ResourceTypeModel('teaching', 'teaching.casestudy');
        }
        if (resourceType === 'TEACHING_MANUAL') {
            return new ResourceTypeModel('teaching', 'teaching.manual');
        }
        if (resourceType === 'TEACHING_QUESTIONBANK') {
            return new ResourceTypeModel('teaching', 'teaching.questionbank');
        }
        if (resourceType === 'TEACHING_TEXTBOOK') {
            return new ResourceTypeModel('teaching', 'teaching.textbook');
        }
        if (resourceType === 'TEACHING_TUTORIAL') {
            return new ResourceTypeModel('teaching', 'teaching.tutorial');
        }
        if (resourceType === 'TEACHING_DEMONSTRATION') {
            return new ResourceTypeModel('teaching', 'teaching.demonstration');
        }
        if (resourceType === 'TEACHING_OTHER') {
            return new ResourceTypeModel('teaching', 'teaching.other');
        }
    }
}
