import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { MediaService } from '@exl-ng/mulo-core';
import {
  ActionListItemComponent,
  MULO_ACTION_LIST_ITEM,
  MuloActionListItem,
} from '../action-list-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { VisibilitySwitchComponent } from '../../visibility-switch';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MultipleEventsDirective } from '../../../directives';

@Component({
  selector: 'mulo-action-list-item-action',
  templateUrl: './action-list-item-action.component.html',
  styleUrls: ['./action-list-item-action.component.scss'],
  standalone: true,
  imports: [
    MatSlideToggle,
    VisibilitySwitchComponent,
    MatIconButton,
    MatIcon,
    MatMenuItem,
    TranslateModule,
    MultipleEventsDirective,
  ],
})
export class ActionListItemActionComponent {
  @Input() labels: string[] = [];
  @Input() type: 'visibility' | 'icon-btn' | 'toggle';
  @Input() data;
  @Input() icon: string;
  @Input() rtlMirrorIcon: boolean;
  @Output() actionEvent = new EventEmitter();
  private _actionItem: ActionListItemComponent;

  constructor(
    public media: MediaService,
    @Optional() @Inject(MULO_ACTION_LIST_ITEM) actionItem: MuloActionListItem,
  ) {
    this._actionItem = actionItem as ActionListItemComponent;
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    // Disable action if parent item disabled or if set to be disabled
    return this._disabled || !!(this._actionItem && this._actionItem.disabled);
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }

  onActionClick(event) {
    if (event?.stopPropagation) {
      event?.stopPropagation();
    }
    if (event?.preventDefault) {
      event?.preventDefault();
    }
    this.actionEvent.emit(event);
  }
}
