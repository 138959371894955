import { trigger, style, transition, animate } from '@angular/animations';
import * as easings from './easings';

const inStart = {
  opacity: 0,
  height: 0,
  transform: 'scale({{ startScale }})',
  transformOrigin: '{{ origin }}',
};
const inEnd = {
  opacity: 1,
  height: '{{ endHeight }}',
  transform: 'scale(1)',
};

const paramsIn = {
  endHeight: '*',
  startScale: '.95',
  time: '.4s',
  origin: 'left top',
};

const outStart = {
  height: '{{ startHeight }}',
  opacity: 1,
  transform: 'scale(1)',
  transformOrigin: '{{ origin }}',
  overflow: '{{ overflow }}',
};

const outEnd = {
  height: 0,
  opacity: 0,
  transform: 'scale({{ endScale}})',
};

const paramsOut = {
  startHeight: '*',
  endScale: '.9',
  time: '.4s',
  origin: 'left top',
  overflow: 'hidden',
};

export const GrowInAnimation = trigger('growInAnimation', [
  transition(
    ':enter',
    [style(inStart), animate('{{ time }} ' + easings.OutQuint, style(inEnd))],
    { params: paramsIn }
  ),
]);

export const GrowOutAnimation = trigger('growOutAnimation', [
  transition(
    ':leave',
    [style(outStart), animate('{{ time }} ' + easings.OutQuint, style(outEnd))],
    { params: paramsOut }
  ),
]);
