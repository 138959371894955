<div class="item-wrap" [attr.title]="label" (mouseenter)="mouseOver = true" (mouseleave)="mouseOver = false">
  <ng-container *ngIf="hasCheckbox">
    <mat-checkbox
      class="is-large"
      [class.is-excluded]="excluded"
      [checked]="checked"
      [disabled]="checkboxDisabled"
      [aria-label]="label"
      [color]="excluded ? 'accent' : 'primary'"
      (change)="handleCheckedChange($event)"
    ></mat-checkbox>
  </ng-container>
  <ng-content select="[slot='facet-content']"></ng-content>
  <ng-content select="mulo-facet-numerical"></ng-content>
  <ng-container *ngIf="!content">
    <button mat-button (click)="handleButtonClick()" class="activate-button">
      <span class="label-spacer" *ngIf="hasCheckbox"></span>
      <span class="label"
        ><span #buttonLabel class="label-inner" [id]="id + '_label'">{{ label }}</span
        >&nbsp;&nbsp;</span
      >
      <span class="count">{{ count }}</span>
    </button>
    <button
      mat-icon-button
      class="exclude-button is-small"
      [attr.aria-label]="excludeAriaLabel"
      [attr.aria-describedby]="buttonLabel?.id"
      (click)="handleExcludeClick()"
    >
      <mat-icon
        [svgIcon]="
          !checked ? 'circle-off-outline' : !excluded ? 'checkbox-marked-off-outline' : 'checkbox-marked-outline'
        "
      ></mat-icon>
      <!-- [svgIcon]="checked && excluded ? 'checkbox-marked-outline' : (checked ? 'checkbox-marked-outline' : 'circle-off-outline' )" -->
    </button>
  </ng-container>
</div>
