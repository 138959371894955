export class DepositForm {
    public static DEPOSIT_CONFIGURATION_URL = './assets/files/data/deposit-form-data.json';
    public static MIN_INPUT_LENGTH = 3;
    public static SEARCH_DEBOUNCE_TIME = 800;
    public static IS_PART_OF = 'ispartof';
    public static UPLOAD_FILE_PATH = 'esploro/';
    public static DEFAULT_TYPE = 'default';
    public static ETD_TYPE = 'etd';
    public static MAX_DISPLAYED_ITEMS_IN_DROP_DOWN = 20;
    public static AUTHORS_DELIMITER = '; ';
    public static VALUES_SEPARATOR = '; ';
    public static DESCRIPTION_JOIN_PARAM = '. ';
    public static FILE_ACCESSRIGHTS_LABELS_PREFIX = 'c.esploro.accessrights.';
    public static FILE_ACCESSRIGHTS_LABELS_POSTFIX = '.detailed';
    public static AUTO_SAVE_TIMER = 30000;
    public static MS_WAIT_FOR_AUTO_SAVE = 50;
    public static MAX_ITERAIONS = 600;
    public static ETD_PREFIX = 'etd';

}
