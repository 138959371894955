<ng-container *ngIf="sideSide === 'left'">
  <ng-container *ngTemplateOutlet="sidecolumn"></ng-container>
</ng-container>

<div class="main-column">
  <ng-content select="[slot='layout-main'], slot-main"></ng-content>
</div>
<ng-container *ngIf="sideSide === 'right'">
  <ng-container *ngTemplateOutlet="sidecolumn"></ng-container>
</ng-container>

<ng-template #sidecolumn>
  <div class="side-column">
    <ng-content select="[slot='layout-side'], slot-side"></ng-content>
  </div>
</ng-template>
