import { Injectable, Output, EventEmitter } from '@angular/core';
import { ExtendedFile } from '../../models/extended-file.model';

@Injectable()
export class FileDropService {
  public filesChangeEmitter: EventEmitter<ExtendedFile[]> = new EventEmitter();
  public filesInvalidEmitter: EventEmitter<ExtendedFile[]> = new EventEmitter();
  public filesDroppedEmitter: EventEmitter<number> = new EventEmitter();
  // public filesProcessedEmitter: EventEmitter<any> = new EventEmitter();
  allowed_extensions;
  valid_files: ExtendedFile[] = [];
  invalid_files: ExtendedFile[] = [];

  constructor() {}

  processFiles(files: FileList | File[], extensions) {
    // console.group(this.constructor.name + '.processFiles', files, extensions);

    this.filesDroppedEmitter.emit(files.length);
    this.allowed_extensions = extensions;

    for (let i = 0; i < files.length; i++) {
      var reader = new FileReader();
      reader.readAsDataURL(files[i]);
      reader.onload = (event: ProgressEvent<FileReader>) => {
        // let data = event.target.result;
        let file: ExtendedFile = files[i];
        let ext = file.name
          .split('.')
          [file.name.split('.').length - 1].toLowerCase();

        file['baseName'] = file.name.replace(/\.[^/.]+$/, '');
        file['ext'] = ext;

        if (this.allowed_extensions?.lastIndexOf(ext) == -1) {
          this.invalid_files.push(file);
          this.filesInvalidEmitter.emit([file]);
        } else {
          this.valid_files.push(file);
          this.filesChangeEmitter.emit([file]);
        }
      };
    }

    this.clearFiles();
    // console.groupEnd();
  }

  clearFiles() {}

  stripDataKey(filesList) {
    let strippedArray = [];
    filesList.forEach((file) => {
      delete file['data'];
      strippedArray.push(file);
    });
    return strippedArray;
  }

  // processFile(file, multiple){
  //     // this.filesProcessedEmitter.emit(file, multiple)
  // }
}
