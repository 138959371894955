export class Profile {
    public static DISCOVERY_URL =
        '/discovery/fulldisplay?docid=alma{{mms_id}}&context=L&vid={{institution_code}}:ResearchRepository&lang={{lang}}';
    public static ORCID_URL_PREFIX = 'https://orcid.org/';
    public static SCOPUS_ID_URL_PREFIX =
        'https://www.scopus.com/authid/detail.uri?authorId=';
    public static ISNI_URL_PREFIX = 'http://www.isni.org/isni/';
    public static RESEARCHER_ID_URL_PREFIX =
        'https://www.webofscience.com/wos/author/rid/';
    public static VIAF_URL_PREFIX = 'https://viaf.org/viaf/';
    public static WIKIDATA_ID_URL_PREFIX = 'https://www.wikidata.org/wiki/';
    public static ARXIV_ID_URL_PREFIX = 'https://arxiv.org/a/';
    public static PIVOT_ID_URL_PREFIX = 'https://pivot.proquest.com/profiles/';
    public static GOOGLE_SCHOLAR_ID_URL_PREFIX = 'https://scholar.google.com/citations?user=';
    public static BULK_SIZE = 10;
    public static BULK_GRID_SIZE = 9;
    public static HIGHLIGHTS_LIMIT = 10;
    public static AWARDS_HIGHLIGHTS_LIMIT = 3;
    public static DISCOVERY_MAIN_PAGE_URL =
        '/discovery?context=L&vid={{institution_code}}:ResearchRepository&lang={{lang}}';
    public static DISCOVERY_SEARCH_URL =
        '/discovery/search?query=any,contains,{{query}}&tab=Research&search_scope=Research&vid={{institution_code}}:ResearchRepository&lang={{lang}}';
}

export class ProfileTabs {
    public static OVERVIEW = 'overview';
    public static OUTPUT = 'output';
    public static AWARDS = 'awards';
    public static MENTIONS = 'mentions';
    public static PROJECTS = 'projects';
    public static ACTIVITIES = 'activities';
}
