import { NgModule } from '@angular/core';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileListComponent } from './file-list/file-list.component';
import { SharedModule } from '../../shared/shared.module';
import { FileUploadDirective } from './file-upload/file-upload.directive';
import { FileUploadService } from './file-upload/file-upload.service';
import { GrobidService } from './file-upload/grobid.service';
import { FileListItemComponent } from './file-list/file-list-item/file-list-item.component';
import {
    ExpansionPanelComponent,
    MultipleEventsDirective,
} from '@exl-ng/mulo-common';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@NgModule({
    imports: [
        SharedModule,
        ExpansionPanelComponent,
        CdkAccordionModule,
        MatButtonModule,
        MatIconModule,
        NgIf,
        MultipleEventsDirective,
        MatButtonModule,
    ],
    declarations: [
        FileUploadComponent,
        FileListComponent,
        FileListItemComponent,

        //Directives
        FileUploadDirective,
    ],
    providers: [FileUploadService, GrobidService],
    exports: [FileUploadComponent, FileListComponent, FileListItemComponent],
})
export class HandleFilesModule {}
