import { Injectable } from '@angular/core';
import {UrlUtils} from '../shared/utils/url.utils';
import {State} from '../shared/configurations/state.constant';

@Injectable()
export class AuthService {

    public PROFILE_PAGE = '/esploro/profile';

    private _redirectUrl;
    private _redirectRoute;

    constructor(){
        this.redirectUrl = this.getProfilePage();
        this.redirectRoute = State.PROFILE;
    }

    get redirectUrl() {
        return this._redirectUrl;
    }

    set redirectUrl(value) {
        this._redirectUrl = value;
    }

    get redirectRoute() {
        return this._redirectRoute;
    }

    set redirectRoute(value) {
        this._redirectRoute = value;
    }

    getProfilePage() {
        return window.location.origin + this.PROFILE_PAGE+ '?institution=' + UrlUtils.getParam('institution');
    }

    getLoginPage() {
        return window.location.origin + '/esploro/login?institution=' + UrlUtils.getParam('institution');
    }

    getLoginAsPage() {
        return window.location.origin + '/esploro/login-as?institution=' + UrlUtils.getParam('institution');
    }

    getUrlByRoute(route: string) {
        return route ? window.location.origin + '/esploro' + route : this.redirectUrl;
    }













}
