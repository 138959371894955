import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, EMPTY, finalize, take } from 'rxjs';
import { cge, cgr, clo, FileUtils, MediaIfDirective } from '@exl-ng/mulo-core';
import { CommonModule } from '@angular/common';
import {
  FileTypeIconPipe,
  HtmlEncodePipe,
  HtmlTrustPipe,
  UrlTrustPipe,
} from '../../pipes';
import { LoadingSlateComponent } from '../loading-slate';

import { PdfViewerModule } from 'ng2-pdf-viewer';

@Component({
  selector: 'mulo-arcview',
  templateUrl: './arcview.component.html',
  styleUrls: ['./arcview.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HtmlEncodePipe,
    HtmlTrustPipe,
    UrlTrustPipe,
    FileTypeIconPipe,
    LoadingSlateComponent,
    PdfViewerModule,
    MediaIfDirective,
  ],
})
export class ArcviewComponent implements OnChanges {
  @Input() src = '';
  @Input() type = '';
  @Input() alt = '';
  @Input() unsupportedMsg = 'This format is not supported by the viewer';
  @Input() errorMsg = 'An unexpected error has occurred';
  @Input() ariaLabelledBy;
  @Input() ariaLabel;
  @Input() loading = false;
  @Input() loadingText: string;
  @Input() viewHtmlAsCode = false;

  srcContent;
  reloadTimeout;
  reloadTries = 5;

  constructor(
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
  ) {}

  get officeSrc() {
    return (
      'https://view.officeapps.live.com/op/embed.aspx?src=' +
      encodeURIComponent(this.src)
    );
  }

  get gDocsSrc() {
    return (
      'https://docs.google.com/gview?embedded=true&url=' +
      encodeURIComponent(this.src)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['src'] && changes['src'].currentValue) {
      cgr(changes['src'].currentValue);
      const shortType = FileUtils.getFileProfile('', this.type);
      clo('type', this.type);
      clo('profile', shortType);
      if (['pdf', 'text', 'code'].includes(shortType)) {
        this.loading = true;
        this.srcContent = null;
        this.http
          .get(this.src, { responseType: 'text' })
          .pipe(
            take(1),
            finalize(() => {
              this.loading = false;
            }),
            catchError((error: HttpErrorResponse) => {
              console.error(error);
              return EMPTY;
            }),
          )
          .subscribe((res) => (this.srcContent = res));
      }
      cge();
    }
  }

  handleGdocsLoad(ev: Event) {
    // this is all needed because of blank 204 responses returning from google
    const iframe = ev.target as HTMLIFrameElement;
    if (iframe?.contentDocument?.body?.innerHTML === '') {
      this.loading = true;
      this.reloadTries--;
      if (this.reloadTries > 0) {
        console.log(
          'Google Docs viewer content empty, reloading in 5 seconds if not resolved',
        );
        const src = this.src;
        this.reloadTimeout = setTimeout(() => {
          console.error('not resolved, reloading');
          this.src = null;
          this.cdr.detectChanges();
          this.src = src;
          this.cdr.detectChanges();
        }, 5000);
      } else {
        // if it doesn't resolve after 5 tries, consider it an error
        this.type = 'example/error';
      }
    } else {
      clearTimeout(this.reloadTimeout);
      this.loading = false;
    }
  }

  handleUnsupported(ev) {
    console.error('unsupported', ev);
    this.type = 'example/unsupported';
    clearTimeout(this.reloadTimeout);
    this.loading = false;
  }

  handleError(ev) {
    console.error('error', ev);
    this.type = 'example/error';
    clearTimeout(this.reloadTimeout);
    this.loading = false;
  }
}
