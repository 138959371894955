<ng-template #mainBtnContent>
  <mat-icon svgIcon="language" muloSvgViewbox></mat-icon>
  <ng-container *ngIf="selectedLanguage as language">
    <ng-container *ngIf="mode == 'full'; else compact">
      {{ language | translate }}&nbsp;({{ language | uppercase }})
    </ng-container>
    <ng-template #compact>
      <span class="selected-lang">{{ language | uppercase }}</span>
      <mat-icon svgIcon="menu-down" class="menu-icon"></mat-icon>
    </ng-template>
  </ng-container>
  <span class="cdk-visually-hidden"> {{ ariaLabel }}</span>
</ng-template>
<ng-container *ngIf="useDialog; else menuBtns">
  <button
    mat-button
    [class.has-icon]="mode == 'full'"
    [class.has-small-icon]="mode == 'full'"
    (click)="handleClick()"
    *ngIf="mode !== 'icon'; else iconBtn"
  >
    <ng-container *ngTemplateOutlet="mainBtnContent"></ng-container>
  </button>
  <ng-template #iconBtn>
    <button mat-icon-button (click)="handleClick()" [attr.aria-label]="ariaLabel">
      <mat-icon svgIcon="language" muloSvgViewbox></mat-icon>
    </button>
  </ng-template>
</ng-container>
<ng-template #menuBtns>
  <button
    mat-button
    [matMenuTriggerFor]="languagesMenu"
    [class.has-icon]="mode == 'full'"
    [class.has-small-icon]="mode == 'full'"
    *ngIf="mode !== 'icon'; else iconMenuBtn"
  >
    <div class="btn-content">
      <ng-container *ngTemplateOutlet="mainBtnContent"></ng-container>
    </div>
  </button>
  <ng-template #iconMenuBtn>
    <button mat-icon-button [attr.aria-label]="ariaLabel" [matMenuTriggerFor]="languagesMenu">
      <mat-icon svgIcon="language" muloSvgViewbox></mat-icon>
    </button>
  </ng-template>
  <mat-menu #languagesMenu="matMenu" xPosition="before">
    <div class="list-label">{{ langListLabel }}</div>
    <button
      mat-menu-item
      *ngFor="let lang of languages"
      (click)="handleLangChange(lang)"
      [attr.aria-current]="selectedLanguage === lang ? true : null"
      [class.selected-button]="selectedLanguage === lang"
    >
      <mat-icon [svgIcon]="selectedLanguage === lang ? 'radiobox-marked' : 'radiobox-blank'"></mat-icon>
      <span>{{ lang | translate }}&nbsp;·&nbsp;{{ lang | uppercase }}</span>
    </button>
  </mat-menu>
</ng-template>
