import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
    selector: 'exl-user-nav',
    templateUrl: './user-nav.component.html',
    styleUrls: ['./user-nav.component.scss'],
    host: { 'class': 'exl-user-nav'},
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserNavComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
