import { trigger, style, transition, animate } from '@angular/animations';
import * as easings from './easings';

export const WidthInAnimation = trigger('widthInAnimation', [
  transition(
    ':enter',
    [
      style({
        opacity: 0,
        width: 0,
        transform: 'scale({{ startScale }})',
        transformOrigin: '{{ origin }}',
        overflow: '{{ overflow }}',
      }),
      animate(
        '{{ time }} ' + easings.OutQuint,
        style({
          opacity: 1,
          width: '{{ endWidth }}',
          transform: 'scale(1)',
        })
      ),
    ],
    {
      params: {
        endWidth: '*',
        startScale: '.5',
        time: '.4s',
        origin: 'left center',
        overflow: 'hidden',
      },
    }
  ),
]);

export const WidthOutAnimation = trigger('widthOutAnimation', [
  transition(
    ':leave',
    [
      style({
        opacity: 1,
        width: '*',
        transform: 'scale(1)',
        transformOrigin: '0 0',
        overflow: '{{ overflow }}',
      }),
      animate(
        '{{ time }} ' + easings.OutQuint,
        style({
          opacity: 0,
          width: '{{ endWidth }}',
          transform: 'scale({{ endScale }})',
          transformOrigin: '{{ origin }}',
        })
      ),
    ],
    {
      params: {
        endWidth: 0,
        endScale: '.5',
        time: '.4s',
        origin: 'left center',
        overflow: 'hidden',
      },
    }
  ),
]);
