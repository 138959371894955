import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ChatEntry } from './chat-entry.model';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  public chatEntries$: Subject<ChatEntry[]> = new Subject();
  public userInput$: Subject<string> = new Subject();
  public chat: ChatEntry[] = [];
  public input: string;
  public singleInstance: boolean;

  constructor() {
  }

  populateChat(chat) {
    if (typeof chat === 'string') {
      this.chatEntries$.next((this.chat = [this.createEntry(chat, 'Admin')]));
    } else {
      this.chat = chat;
      this.chatEntries$.next(this.chat);
    }
  }

  createEntry(body, origin) {
    let entry: ChatEntry = {
      body,
      origin,
      originTitle: 'you',
      time: new Date()
    };
    return entry;
  }

  addUserMessage() {
    this.chat.push(this.createEntry(this.input, 'self'));
    this.input = '';
    this.userInput$.next('');
  }
}
