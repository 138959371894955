import { Component, OnInit } from '@angular/core';
import {CodeTableService} from '../../../core/code-table.service';
import {CodeLabelEntry} from '../../../shared/interfaces/code-label-entry.interface';
import {DepositFormDataService} from '../../deposit-form-data.service';


@Component({
    selector: 'esp-deposit-anz-toa',
    templateUrl: './deposit-anz-toa.component.html',
    styleUrls: ['./deposit-anz-toa.component.scss']
})
export class DepositAnzToaComponent implements OnInit {

    activities: String[];

    constructor(private codetableService: CodeTableService,
                public depositFormDataService: DepositFormDataService) {
    }

    ngOnInit() {
        this.codetableService.getCodeTable('ANZTypesOfActivityCodes').subscribe((data) => {
            this.activities =  (data as CodeLabelEntry[]).map(entry => entry.code);
        });
    }
}
