<nav mat-tab-nav-bar class="is-scaling is-scrollable" [color]="color" #navbar [mat-stretch-tabs]="false">
  <ng-content select="[slot='pre-nav']"></ng-content>
  <a
    mat-tab-link
    *ngFor="let item of items; index as i"
    [routerLink]="item.url ? assessUrl(item.url) : null"
    [fragment]="item.url && containsHash(item.url) ? unHash(item.url) : null"
    [queryParamsHandling]="queryParamsHandling"
    [skipLocationChange]="skipLocationChange"
    [active]="disableActiveIndication ? false : selectedIndex === item.id"
    (click)="changeItem(item, item.url && containsHash(item.url))"
  >
    <ng-container
      *ngIf="itemTemplate; else defaultTemplate"
      [ngTemplateOutlet]="itemTemplate"
      [ngTemplateOutletContext]="{ $implicit: item, index: i }"
    >
    </ng-container>

    <ng-template #defaultTemplate>
      <span>
        {{ item.label }}<ng-container *ngIf="item.parent">s</ng-container>&nbsp;<span
          *ngIf="item.length"
          class="counter"
          >{{ item.length }}</span
        >
      </span>
    </ng-template>
  </a>
</nav>
