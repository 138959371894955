<button mat-button [matMenuTriggerFor]="menu" *ngIf="menuStyle === 'menu'" [attr.aria-label]="menuA11yLabel">
    <ng-container *ngTemplateOutlet="btnContent"></ng-container>
</button>
<button
    mat-button
    (click)="showBottomSheet()"
    *ngIf="menuStyle !== 'menu'"
    [attr.aria-expanded]="menuOpen"
    [attr.aria-label]="menuA11yLabel"
>
    <ng-container *ngTemplateOutlet="btnContent"></ng-container>
</button>

<ng-template #btnContent>
    <mulo-avatar
        *ngIf="hasProxy"
        class="proxy-avatar"
        [image]="proxyImage"
        [initials]="proxyInitials"
        [description]="'research.alt.profile.img.with.name' | translate : { value: proxyName }"
        size="30"
        [radius]="4"
    ></mulo-avatar>

    <span [class.bracketed-avatar]="hasProxy">
        <span class="avatar-bracket" *ngIf="hasProxy">&#123;</span>
        <mulo-avatar
            [image]="userImage"
            [initials]="userInitials"
            [description]="'research.alt.profile.img.with.name' | translate : { value: userName }"
            [size]="hasProxy ? '24' : '30'"
            [radius]="4"
            [shape]="'square'"
        ></mulo-avatar>
    </span>
    <span class="avatar-bracket" *ngIf="hasProxy">&#125;</span>
    <mat-icon svgIcon="menu-down" class="menu-icon" *ngIf="showArrow"></mat-icon>
</ng-template>

<mat-menu #menu="matMenu" class="user-menu" yPosition="below">
    <exl-user-menu-panel
        #userMenuPanel
        [userName]="userName"
        [proxyName]="proxyName"
        [menuItems]="menuItems"
        (signOut)="doSignOut()"
        (action)="onMenuAction($event)"
    >
    </exl-user-menu-panel>
</mat-menu>
