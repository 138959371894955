import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { UrlUtils } from '../shared/utils/url.utils';
import { Observable } from 'rxjs';
import { CodeLabelEntry } from '../shared/interfaces/code-label-entry.interface';


@Injectable({
    providedIn: 'root'
})
export class CodeTableService {

    constructor(private http: HttpClient){}

    getCodeTable(
        tablename: string,
        language?: string
    ): Observable<CodeLabelEntry[]> {
        const institutionCode = UrlUtils.getParam('institution');
        let params = new HttpParams()
            .set('institution', institutionCode)
            .set('tablename', tablename);

        if (language) {
            params = params.set('lang', language);
        }

        return this.http.get<CodeLabelEntry[]>(RestApiBase.CODE_TABLE_BASE, {
            params: params,
        });
    }
}
