import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  FormGroupDirective,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormGroup,
} from '@angular/forms';
import { DisplayMode, MediaService } from '@exl-ng/mulo-core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { DropListModule } from '../drop-list';

export interface MultiLanguageObj<T> {
  [langKey: string]: T;
}

@Component({
  selector: 'mulo-editable-item-section',
  template: `<ng-content></ng-content>`,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    DropListModule,
  ],
})
export abstract class EditableItemSectionComponent<T = any, M = any>
  implements OnDestroy
{
  @ViewChildren(FormGroupDirective)
  formGroupDirectives: QueryList<FormGroupDirective>;
  @Input() displayMode: DisplayMode;
  @Input() items: T[] = [];
  @Input() itemLimit: number;
  @Input() omitFields = [];
  @Input() ariaLabel: string;
  @Input() ariaLabelledBy: string;

  @Output() itemsChanged: EventEmitter<T[]> = new EventEmitter();

  formMode: DisplayMode;
  form: UntypedFormGroup;
  formInitConfig: any;
  itemInEdit = -1;
  closeForm: boolean;
  submitInProgress = false;

  selectedLanguage: string;
  languagesMap: MultiLanguageObj<M>;
  languagesKeys: string[];
  readonly media = inject(MediaService);

  protected constructor() {}

  ngOnDestroy(): void {}

  setForm(index) {
    if (index === undefined || index === null) {
      this.itemInEdit = -1;
      this.form.reset(this.formInitConfig);
      if (this.closeForm === false) {
        this.formMode = 'add';
      } else {
        this.formMode = null;
        this.closeForm = true;
      }
      this.formGroupDirectives?.forEach((directive) => {
        if (directive.form === this.form) {
          directive.resetForm(this.formInitConfig);
        }
      });
    } else {
      this.formMode = 'edit';
      this.itemInEdit = index;
      this.form.reset(
        { ...this.formInitConfig, ...this.items[index] },
        { emitEvent: false },
      );
    }
    this.submitInProgress = false;
  }

  handleSubmit(andClose = true) {
    this.closeForm = andClose;
    this.handleFormClose();
  }

  handleAddAnother = (event: MouseEvent) => {
    event.preventDefault();
    this.handleSubmit(false);
  };

  handleFormClose() {
    this.setForm(null);
    this.formMode = null;
    this.submitInProgress = false;
  }

  addItem(item) {
    this.itemLimit = undefined;
    this.items.push(item);
    this.itemsChanged.emit(this.items);
    this.setForm(null);
  }

  editItem(item, index) {
    this.items[index] = item;
    this.itemsChanged.emit(this.items);
    this.setForm(null);
  }

  removeItem(index) {
    this.items.splice(index, 1);
    this.itemsChanged.emit(this.items);
    this.setForm(null);
  }
}
