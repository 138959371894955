import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { Subject, takeUntil, takeWhile } from 'rxjs';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import moment from 'moment';
import { SystemDateFormatService } from '@exl-ng/mulo-core';
import { DatepickerChangeEvent } from '@exl-ng/mulo-common/lib/components/datepicker/datepicker.component';
import { CodeLabelEntry } from '../../../shared/interfaces/code-label-entry.interface';
import { CodeTableService } from '../../../core/code-table.service';

@Component({
    selector: 'esp-deposit-published-date',
    templateUrl: './deposit-published-date.component.html',
    styleUrls: ['./deposit-published-date.component.scss'],
})
export class DepositPublishedDateComponent implements OnInit, OnDestroy {
    @Input() autoPopulatedKey = 'DATE';
    @Input() dateKey = 'date';

    currentDate: string;
    dateInput: string;
    displayDateFormat: string;

    seasons: CodeLabelEntry[];

    private componentDestroy = new Subject<void>();

    constructor(
        public depositFormDataService: DepositFormDataService,
        private systemDateFormatService: SystemDateFormatService,
        private codetableService: CodeTableService
    ) {
        this.displayDateFormat = systemDateFormatService.getSystemDateFormat();
    }

    ngOnInit() {
        this.currentDate = moment().format(this.displayDateFormat);
        this.dateInput =
            this.depositFormDataService[this.dateKey]?.value ?? null;
        this.depositFormDataService.mainForm
            .get(this.dateKey)
            .valueChanges.pipe(takeUntil(this.componentDestroy))
            .subscribe((date) => {
                if (
                    date &&
                    date !== this.dateInput &&
                    this.depositFormDataService.startPopulating
                ) {
                    this.dateInput =
                        this.depositFormDataService[this.dateKey]?.value ??
                        null;
                }
            });
        this.setSeasons();
    }

    dateChange(change: DatepickerChangeEvent) {
        if (
            !this.depositFormDataService.startPopulating &&
            !(
                moment(this.dateInput).format(
                    this.systemDateFormatService.getSystemDateFormat()
                ) == change.stringValue || this.dateInput === change.stringValue
            )
        ) {
            this.depositFormDataService.removeKeyFromAutoPopulatedArray(
                AutoPopulateKeys[this.autoPopulatedKey]
            );
        }
        //set date as YYYYMMDD
        let datePartial = '';
        datePartial += change.y ?? '';
        datePartial +=
            change.m !== null ? String(change.m + 1).padStart(2, '0') : '';
        datePartial += change.d ? String(change.d).padStart(2, '0') : '';
        this.depositFormDataService.updateYear(change.y || '');
        this.depositFormDataService.updateMonth(
            change.m !== null ? change.m + 1 : ''
        );
        this.depositFormDataService.updateDay(change.d || '');
        this.depositFormDataService.updateDate(datePartial);
        this.depositFormDataService.updateDatePublished(datePartial);
    }

    setSeasons() {
        this.codetableService
            .getCodeTable('EsploroSeasons')
            .subscribe((data) => {
                this.seasons = data as CodeLabelEntry[];
            });
    }

    get isDateAutoPopulated() {
        return this.depositFormDataService.isKeyAutoPopulated(
            AutoPopulateKeys[this.autoPopulatedKey]
        );
    }

    ngOnDestroy() {
        if (!this.componentDestroy.isStopped) {
            this.componentDestroy.next();
            //unsubscribe from the subject itself
            this.componentDestroy.unsubscribe();
        }
    }
}
