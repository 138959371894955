import { Breakpoints } from '@angular/cdk/layout';

export type MediaQueryAlias =
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'gt-xs'
  | 'gt-sm'
  | 'gt-md'
  | 'gt-lg'
  | 'lt-xl'
  | 'lt-lg'
  | 'lt-md'
  | 'lt-sm';

/* eslint-disable @typescript-eslint/naming-convention */
export const aliasMap = {
  xs: Breakpoints.XSmall,
  sm: Breakpoints.Small,
  md: Breakpoints.Medium,
  lg: Breakpoints.Large,
  xl: Breakpoints.XLarge,
  'gt-xs': '(min-width: 600px)',
  'gt-sm': '(min-width: 960px)',
  'gt-md': '(min-width: 1280px)',
  'gt-lg': Breakpoints.XLarge,
  'lt-xl': '(max-width: 1919.98px)',
  'lt-lg': '(max-width: 1279.98px)',
  'lt-md': '(max-width: 959.98px)',
  'lt-sm': Breakpoints.XSmall,
};
