import { Component, Input, OnInit } from '@angular/core';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
    selector: 'mulo-featured-tile',
    templateUrl: './featured-tile.component.html',
    styleUrls: ['./featured-tile.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatIcon,
        SvgViewboxDirective,
    ],
})
export class FeaturedTileComponent implements OnInit {
  @Input() image;
  @Input() icon;
  @Input() rtlMirrorIcon: boolean;
  @Input() title;

  constructor() {}

  ngOnInit(): void {}
}
