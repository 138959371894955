import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
export type PortionMethod = 'descending' | 'ascending' | 'even';

@Injectable()
export class PortionListService {
  slider: number;
  value: number;

  private _itemChange = new Subject<any>();
  itemChange$ = this._itemChange.asObservable();

  constructor() {}

  sliderChanges(index, value) {
    this.slider = index;
    this.value = value;
    this._itemChange.next({ index, value });
  }
}
