import { NgModule } from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {SuperBriefComponent} from './super-brief/super-brief.component';
import {BriefBaseComponent} from './brief-base/brief-base.component';



@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        SuperBriefComponent,
        BriefBaseComponent
    ],
    exports: [
        SuperBriefComponent,
        BriefBaseComponent
    ]
})
export class BriefsModule { }
