import { Component } from '@angular/core';
import { DepositFormService } from '../deposit/deposit-form.service';
import { AuthService } from '../core/auth.service';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { StorageUtilService } from '../core/storage-util.service';
import { UrlUtils } from '../shared/utils/url.utils';
import { ConfigurationHandlerService } from '../core/configuration-handler.service';
import { JwtUtilService } from '../core/jwt-util.service';
import { RouterService } from '../core/router.service';

@Component({
    selector: 'esp-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    institutionCode: string;
    isInternalLogin = false;
    loginSystem = '';
    idpCode = '';

    constructor(
        private depositFormService: DepositFormService,
        private jwtUtilService: JwtUtilService,
        private routerService: RouterService,
        private authService: AuthService,
        private storageUtilService: StorageUtilService,
        private configurationHandlerService: ConfigurationHandlerService
    ) {
        this.institutionCode = UrlUtils.getParam('institution');
        const auth =
            this.configurationHandlerService.getAuthenticationProfile();
        const authSystem = auth?.system;

        if (this.jwtUtilService.isResearcherTypeLoggedIn()) {
            this.routerService.goToProfile();
        } else if (this.jwtUtilService.isUserTypeLoggedIn()) {
            this.routerService.goToHomepage();
        } else {
            this.idpCode = auth?.code;
            if (
                authSystem === 'SAML' ||
                authSystem === 'CAS' ||
                authSystem === 'SOCIAL_VIA_ALMA'
            ) {
                this.loginSystem = authSystem;
            } else {
                this.loginSystem = 'local';
            }

            this.handleLogin();
        }
    }

    handleLogin() {
        if (this.isExternal(this.loginSystem)) {
            this.isInternalLogin = false;
            window.location.href = this.handleExternalLogin();
        } else {
            this.isInternalLogin = true;
        }
    }

    isExternal(authenticationSystem) {
        if (
            authenticationSystem === 'SAML' ||
            authenticationSystem === 'CAS' ||
            authenticationSystem === 'SOCIAL_VIA_ALMA'
        ) {
            return true;
        }
        return false;
    }

    handleExternalLogin(isSilent = false) {
        const prevUrlPath = this.routerService.navHistory[this.routerService.navHistory.length - 1];
        const fullPrevUrl = this.authService.getUrlByRoute(prevUrlPath);
        const targetURL = decodeURIComponent(fullPrevUrl); //TODO need to change encoding/decoding
        let externalLoginURL =
            RestApiBase.EXTERNAL_LOGIN +
            '?institution=' +
            encodeURIComponent(this.institutionCode) +
            '&lang=' +
            encodeURIComponent('en_US') + //TODO chnage
            '&target-url=' +
            encodeURIComponent(targetURL) +
            '&auth=' +
            encodeURIComponent(this.loginSystem) +
            '&isSilent=' +
            isSilent;
        if (this.idpCode) {
            externalLoginURL +=
                '&authenticationProfile=' +
                encodeURIComponent(this.idpCode) +
                '&idpCode=' +
                encodeURIComponent(this.idpCode);
        }
        return externalLoginURL;
    }
}
