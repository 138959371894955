<span class="smaller-text semibold margin-right-sm">{{ labelDistribute }} %: </span>
<button
  mat-icon-button
  (click)="onClick('descending')"
  class="descending"
  [matTooltip]="labelDistDesc"
  [attr.aria-label]="labelDistDesc"
>
  <mat-icon class="mat-icon-rtl-mirror" svgIcon="sort-variant"></mat-icon>
</button>
<button
  mat-icon-button
  (click)="onClick('even')"
  class="even"
  [matTooltip]="labelDistEq"
  [attr.aria-label]="labelDistEq"
>
  <mat-icon svgIcon="menu"></mat-icon>
</button>
<button
  mat-icon-button
  (click)="onClick('ascending')"
  class="ascending"
  [matTooltip]="labelDistAsc"
  [attr.aria-label]="labelDistAsc"
>
  <mat-icon class="mat-icon-rtl-mirror" svgIcon="sort-variant"></mat-icon>
</button>
