import {Component, OnInit, Input, ViewChild, AfterContentInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import {GrowInAnimation, GrowOutAnimation, SlideInAnimation} from '@exl-ng/mulo-common';
import {DepositFormDataService} from '../../../deposit-form-data.service';
import {ConfigurationHandlerService} from '../../../../core/configuration-handler.service';
import {MappingTable} from '../../../../shared/interfaces/mapping-table.interface';
import {LicenseSelectDialogComponent} from '../../../../parts/dialogs/license-select-dialog/license-select-dialog.component';

@Component({
    selector: 'esp-deposit-link-metadata',
    templateUrl: './deposit-link-metadata.component.html',
    styleUrls: ['./deposit-link-metadata.component.scss'],
    host: { 'class': 'esp-deposit-link-metadata'},
    animations: [GrowInAnimation, GrowOutAnimation, SlideInAnimation]
})
export class DepositLinkMetadataComponent implements OnInit, AfterContentInit {

    @ViewChild(MatInput, {static: false}) textarea: MatInput;
    @ViewChild(MatSelect, {static: false}) typeInput: MatSelect;

    @Input() index;

    private _displayDescription: boolean;
    @Input()
    get displayDescription() { return this._displayDescription }
    set displayDescription(display: boolean) { this._displayDescription = display ?? true; }

    private _displayLicense: boolean;
    @Input()
    get displayLicense() { return this._displayLicense }
    set displayLicense(display: boolean) { this._displayLicense = display ?? true; }

    private _displayContentType: boolean;
    @Input()
    get displayContentType() { return this._displayContentType }
    set displayContentType(display: boolean) { this._displayContentType = display ?? true; }


    showDescriptionButton = true;
    showTypeButton = true;
    showLicenseButton = true;
    linkTypes = [];
    licenseCodes = [];

    description;
    type;
    license;
    showFooter = false;

    constructor(public depositFormDataService: DepositFormDataService,
                public configurationHandlerService: ConfigurationHandlerService,
                public matDialog: MatDialog) {
    }

    ngOnInit() {
        const assetFileAndLinkTypesMT = this.configurationHandlerService.getMappingTable('AssetFileAndLinkTypes') as MappingTable[];
        this.linkTypes = assetFileAndLinkTypesMT.filter((row) => row.enabled === 'true' && this.relevantForLink(row.source1) &&
            this.relevantForResourceType(row.source2)).map((row) => { return row.target;});

        this.description = this.link['description'];
        this.type = this.link['type'];
        if (this.type && this.linkTypes && this.linkTypes.indexOf(this.type) === -1){
            this.type = '';
            this.depositFormDataService.updateLinkType(this.index,this.type);
        }
        const licenseTypesMT = this.configurationHandlerService.getMappingTable('EsploroLicensesData') as MappingTable[];
        this.licenseCodes = licenseTypesMT.filter((row) => row.enabled === 'true' && row.source5 === 'true'
            && this.relevantForResourceType(row.source2));
        this.resetLicenseIfNotRelevant();
    }

    ngAfterContentInit() {
        setTimeout(() => {
            this.showFooter = true;
        }, 1000);
    }


    resetLicenseIfNotRelevant(){
        const codes = this.licenseCodes.map((row) => {
            return row.source1;
        });
        if (this.link.license && codes.indexOf(this.link.license) === -1){
            this.license = '';
            this.depositFormDataService.updateLinkLicense(this.index,this.type);
        }
    }

    relevantForLink(source1){
        if (source1 === 'link' || source1 === 'both'){
            return true;
        }
        return false;
    }

    relevantForResourceType(source2){
        if (source2.indexOf('all') !== -1 || source2.indexOf(this.depositFormDataService.category.value) !== -1){
            return true;
        }
        return false;
    }

    onAddDescription(){
        this.showDescriptionButton = false;
        setTimeout(() => {
            this.textarea.focus();
        }, 0);
    }

    onRemoveDescription(){
        this.description = '';
        this.onChangeDescription();
        this.showDescriptionButton = true;
    }

    onRemoveLicense() {
        this.link.license = '';
        this.showLicenseButton = true;
        this.depositFormDataService.updateLinkLicense(this.index,'');
    }

    onSetDocumentType(){
        this.showTypeButton = false;
        setTimeout(() => {
            this.typeInput.open();
        }, 700);
    }

    onRemoveDocumentType(){
        this.type = '';
        this.onChangeType();
        this.showTypeButton = true;
    }

    onChangeDescription(){
        this.depositFormDataService.updateLinkDescription(this.index,this.description);
    }

    onChangeType(){
        this.depositFormDataService.updateLinkType(this.index,this.type);
    }

    setLicense(license) {
        this.link.license = license.title;
        this.depositFormDataService.updateLinkLicense(this.index, license.title);
    }

    setLicenseToAll(license) {
        this.depositFormDataService.links.value.forEach((link, index) => {
            link.license = license.title;
            this.depositFormDataService.updateLinkLicense(index, license.title);
        });
    }

    get link(){
        return this.depositFormDataService.links.at(this.index).value;
    }

    displaySetTypeButton(){
        if ((!this.type) || (this.type && this.linkTypes.indexOf(this.type) === -1)){
            return true;
        }
        return false;
    }

    displayType(){
        if (this.type && this.linkTypes.indexOf(this.type) !== -1){
            return true;
        }
        return false;
    }

    onAddLicense(){
        this.showLicenseButton = false;
        this.showLicenseDialog(this.link.license ? this.link.license : null);
    }

    showLicenseDialog(license?) {
        const dialogRef = this.matDialog
            .open(LicenseSelectDialogComponent, {
                maxWidth: '800px',
                data: {
                    selected: license,
                    licenseCodes: this.licenseCodes
                }
            });
        dialogRef.afterClosed().subscribe(response => {
            if (response && response.applyToAll) {
                this.setLicenseToAll(response.license);
            } else if (!response || response === 'cancel') {
                this.showLicenseButton = true;
            } else {
                this.setLicense(response.license);
            }
        });
    }



}

