export interface ResearchTopic{
    code: string;
    description: string;
    actualCode: string;
    actualDescription: string;
    hierarchy: string[];
}

export class ANZTopic {
    code: string;
    label: string;
    portion: number;

    constructor(code, label, portion){
      this.code = code;
      this.label = label;
      this.portion = portion;
    }
  }
