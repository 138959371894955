export class Date {
    date: string;
    year: string;
    month: string;
    day: string;

    constructor(){}

    getDate(){
        return this.date;
    }

    setDate(date){
        this.date = date;
    }

    getYear(){
        return this.year;
    }

    setYear(year){
        this.year = year;
    }

    getMonth(){
        return this.month;
    }

    setMonth(month){
        this.month = month;
    }

    getDay(){
        return this.day;
    }

    setDay(day){
        this.day = day;
    }
}
