<ng-container *ngFor="let component of tab3">
    <esp-deposit-form-section
        sectionTitle="{{ 'research.peereview.title' | customTranslate }}"
        *ngIf="displayPeerReviewed(component)"
        [required]="isRequired(component)"
    >
        <esp-deposit-peer-reviewed></esp-deposit-peer-reviewed>
    </esp-deposit-form-section>

    <esp-deposit-form-section
        sectionTitle="{{ 'research.conference.info' | customTranslate }}"
        *ngIf="displayConferenceInformation(component)"
        [required]="isRequired(component)"
    >
        <esp-deposit-conference-information></esp-deposit-conference-information>
    </esp-deposit-form-section>

    <esp-deposit-form-section
        sectionTitle="{{ 'research.event.info' | customTranslate }}"
        *ngIf="displayEventInformation(component)"
        [required]="isRequired(component)"
    >
        <esp-deposit-event-information></esp-deposit-event-information>
    </esp-deposit-form-section>

    <esp-deposit-form-section
        sectionTitle="{{ 'research.presentation.info' | customTranslate }}"
        *ngIf="displayPresentationInformation(component)"
        [required]="isRequired(component)"
    >
        <esp-deposit-presentation-information></esp-deposit-presentation-information>
    </esp-deposit-form-section>

    <esp-deposit-status *ngIf="displayStatus(component)" [required]="isRequired(component)"></esp-deposit-status>

    <esp-deposit-form-section
        sectionTitle="{{ 'research.published.where' | customTranslate }}"
        *ngIf="displayPublisher(component) && depositFormDataService.type.value"
    >
        <esp-deposit-publisher
            placeholder="{{ 'research.published.institution' | customTranslate }}"
        ></esp-deposit-publisher>
    </esp-deposit-form-section>

    <esp-deposit-form-section
        *ngIf="displayDoi(component) && depositFormDataService.type.value"
        [required]="isRequired(component)"
        sectionTitle="{{ 'research.published.DOI' | customTranslate }}"
    >
        <esp-deposit-doi
            [assetType]="depositFormDataService.resourceType"
            [doi]="depositFormDataService.doi.value"
            [isAutoPopulated]="depositFormDataService.isKeyAutoPopulated('doi')"
            [isRegistered]="depositFormDataService.isRegisteredDOI.value"
            [isReserved]="depositFormDataService.isReservedDOI.value"
            (doiReserved)="depositFormDataService.handleDoiReserved($event)"
            (doiDismissed)="depositFormDataService.handleDoiDismissed()"
            (doiChanged)="depositFormDataService.updateDoi($event)"
            placeholder="research.published.enter"
        ></esp-deposit-doi>
    </esp-deposit-form-section>

    <esp-deposit-form-section
        sectionTitle="{{ 'research.status.info' | customTranslate }}"
        *ngIf="dispalyPublicationInformation(component) && isPublicationMoreInformation()"
    >
        <esp-deposit-publication-details-journal-article
            [status]="status.PUBLISHED"
        ></esp-deposit-publication-details-journal-article>
    </esp-deposit-form-section>

    <esp-deposit-form-section
        *ngIf="displayDatePublished(component) && depositFormDataService.type.value"
        [required]="isRequired(component)"
        sectionTitle="{{ 'research.status.publish' | customTranslate }}"
    >
        <esp-deposit-published-date
            [autoPopulatedKey]="'DATE_PUBLISHED'"
            [dateKey]="'datePublished'"
        ></esp-deposit-published-date>
    </esp-deposit-form-section>

    <esp-deposit-form-section
        *ngIf="displayDateCreated(component)"
        [required]="isRequired(component)"
        sectionTitle="{{ 'research.date.date.created' | customTranslate }}"
        description="{{ 'research.collected.subtitle' | customTranslate }}"
    >
        <esp-deposit-dates
            [range]="true"
            [dateHint]="false"
            [fromDate]="depositFormDataService.dateCreatedStart"
            [toDate]="depositFormDataService.dateCreatedEnd"
        >
        </esp-deposit-dates>
    </esp-deposit-form-section>

    <esp-deposit-form-section
        *ngIf="displayDateCollected(component)"
        [required]="isRequired(component)"
        sectionTitle="{{ 'research.collected.title' | customTranslate }}"
        description="{{ 'research.collected.subtitle' | customTranslate }}"
    >
        <esp-deposit-dates
            [range]="true"
            [dateHint]="false"
            [fromDate]="depositFormDataService.dateCollectedStart"
            [toDate]="depositFormDataService.dateCollectedEnd"
        >
        </esp-deposit-dates>
    </esp-deposit-form-section>

    <esp-deposit-form-section
        *ngIf="displayDatePresented(component)"
        [required]="isRequired(component)"
        sectionTitle="{{ 'research.conference.date.presented' | customTranslate }}"
    >
        <esp-deposit-dates [range]="false" [dateHint]="true" [fromDate]="depositFormDataService.datePresented">
        </esp-deposit-dates>
    </esp-deposit-form-section>

    <esp-deposit-form-section
        sectionTitle="{{ 'research.language.title' | customTranslate }}"
        *ngIf="displayLanguages(component)"
        [required]="isRequired(component)"
    >
        <esp-deposit-language></esp-deposit-language>
    </esp-deposit-form-section>

    <esp-deposit-form-section
        *ngIf="
            isDataset() && matchFirstCompUnderAdditionalData(component) && !depositStepThreeService.showAdditionalInfo
        "
        class="is-unindented"
    >
        <div>
            <button
                mat-button
                color="primary"
                (click)="depositStepThreeService.showAdditionalInfo = !depositStepThreeService.showAdditionalInfo"
            >
                {{ 'research.additional.add' | customTranslate }}
            </button>
        </div>
    </esp-deposit-form-section>

    <ng-container *ngIf="depositStepThreeService.showAdditionalInfo">
        <esp-deposit-form-section
            sectionTitle="{{ 'research.geospatial.geospatial' | customTranslate }}"
            *ngIf="displaySpatial(component)"
            [required]="isRequired(component)"
        >
            <esp-deposit-spatial cdkTrapFocus="false" cdkTrapFocusAutoCapture></esp-deposit-spatial>
        </esp-deposit-form-section>

        <esp-deposit-form-section
            sectionTitle="{{ 'research.methods.methods' | customTranslate }}"
            *ngIf="displayMethods(component)"
            [required]="isRequired(component)"
        >
            <esp-deposit-methods></esp-deposit-methods>
        </esp-deposit-form-section>

        <esp-deposit-form-section
            sectionTitle="{{ 'research.coverage.coverage' | customTranslate }}"
            *ngIf="displayCoverage(component)"
            [required]="isRequired(component)"
        >
            <esp-deposit-coverage></esp-deposit-coverage>
        </esp-deposit-form-section>

        <esp-deposit-form-section
            sectionTitle="{{ 'research.technical.technical' | customTranslate }}"
            *ngIf="displayTechnicalInfo(component)"
            [required]="isRequired(component)"
        >
            <esp-deposit-technical-information></esp-deposit-technical-information>
        </esp-deposit-form-section>
    </ng-container>
</ng-container>
