<esp-deposit-stepper-back-button
    *ngIf="!backButtonDisabled"
    [disabled]="backButtonDisabled"
    (back)="onBackButtonClick()"
    [class.is-detached]="headerIsHidden"
>
</esp-deposit-stepper-back-button>

<mat-horizontal-stepper [linear]="false" class="has-hidden-last-step" (selectionChange)="stepChanged($event)" #stepper>
    <mat-step>
        <ng-template matStepLabel>
            <h2>{{ steps[0].title | translate }}</h2>
        </ng-template>
        <esp-deposit-step-one
            #step
            (closeDeposit)="closeDeposit.emit($event)"
            [tab1]="firstTabComponents"
            [jumpToFiles]="jumpToFiles"
            (assetTypeSelectedEmitter)="assetTypeSelected()"
            (assetTypeClearedEmitter)="assetTypeCleared()"
            style="min-height: calc(100vh - 65px); display: block"
        >
            <!-- REMOVE -->
        </esp-deposit-step-one>
    </mat-step>

    <mat-step *ngIf="displayTab(secondTabComponents)">
        <ng-template matStepLabel>
            <h2>{{ steps[1].title | translate }}</h2>
        </ng-template>
        <esp-deposit-step-two
            #step
            [tab2]="secondTabComponents"
            [jumpToTitle]="jumpToTitle"
            [jumpToTopics]="jumpToTopics"
        >
        </esp-deposit-step-two>
    </mat-step>

    <mat-step *ngIf="displayTab(thirdTabComponents)">
        <ng-template matStepLabel>
            <h2>{{ steps[2].title | translate }}</h2>
        </ng-template>
        <esp-deposit-step-three #step [tab3]="thirdTabComponents"></esp-deposit-step-three>
    </mat-step>

    <mat-step *ngIf="displayTab(fourthTabComponents)">
        <ng-template matStepLabel>
            <h2>{{ steps[3].title | translate }}</h2>
        </ng-template>
        <esp-deposit-step-four #step [tab4]="fourthTabComponents"></esp-deposit-step-four>
    </mat-step>

    <mat-step class="is-hidden" label="hidden">
        <esp-deposit-last-step
            *ngIf="stepper.selectedIndex === 4"
            (jumpToSection)="jumpToSection($event)"
        ></esp-deposit-last-step>
    </mat-step>
</mat-horizontal-stepper>

<div class="form-buttons" [class.is-detached]="headerIsHidden">
    <div class="form-buttons-inner">
        <esp-deposit-stepper-skip-to-end-button
            *ngIf="showSkipToEnd && !isLastStep"
            (skip)="onSkipToEndButtonClick()"
            [@opacityInAnimation]="{ value: 'enter', params: { time: '.4s .4s' } }"
            [@opacityOutAnimation]="{ value: 'leave', params: { time: '.2s' } }"
        >
        </esp-deposit-stepper-skip-to-end-button>

        <esp-deposit-stepper-next-button
            (advance)="onNextButtonClick()"
            [steps]="steps"
            [selectedStep]="stepper.selectedIndex"
            [disabled]="!nextStepEnabled"
            [rounded]="headerIsHidden"
            [loading]="processingNextStep"
        >
        </esp-deposit-stepper-next-button>
    </div>
</div>
