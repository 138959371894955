import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent, BrandComponent } from '@exl-ng/mulo-common';

import { SharedModule } from '../shared/shared.module';
import { PersonBriefModule } from '../parts/person-brief/person-brief.module';
import { LoginGenericModule } from '../parts/login/login.module';
import { LayoutsModule } from '../parts/layouts/layouts.module';
import { LogoutModule } from '../logout/logout.module';

import { LoginComponent } from './login.component';
import { LoginAsComponent } from './login-as/login-as.component';
import { LoginErrorNoResearcherRoleComponent } from './login-error-no-researcher-role/login-error-no-researcher-role.component';

@NgModule({
    imports: [
        CommonModule,
        LoginGenericModule,
        BrandComponent,
        LogoutModule,
        SharedModule,
        PersonBriefModule,
        AvatarComponent,
        LayoutsModule,
    ],
    declarations: [
        LoginComponent,
        LoginAsComponent,
        LoginErrorNoResearcherRoleComponent,
    ],
    exports: [LoginComponent],
    providers: [],
})
export class LoginModule {}
