<div mat-dialog-title>
    <div class="title-wrap">
        <h1 class="beta semibold spacer">
            {{ 'research.faccess.title' | customTranslate }}
        </h1>
    </div>
    <div class="eta regular">
        <mat-checkbox (change)="checkboxChanged($event)" [(ngModel)]="applyToAll">{{
            'research.faccess.apply' | customTranslate
        }}</mat-checkbox>
    </div>
</div>

<div mat-dialog-content>
    <mat-radio-group (ngModelChange)="onSelection()" [(ngModel)]="data.selected">
        <mat-radio-button value="public">
            {{ labelsPrefix + 'public' | translate }}
        </mat-radio-button>
        <mat-radio-button value="embargoed">
            <div>{{ labelsPrefix + 'embargoed' | translate }}</div>
            <mat-form-field *ngIf="data.selected === 'embargoed'">
                <input #input [(ngModel)]="date" [matDatepicker]="picker" matInput />
                <mat-label>{{ labelsPrefix + 'available_from_date' | translate }}</mat-label>
                <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </mat-radio-button>
        <mat-radio-button value="request">
            <div>{{ labelsPrefix + 'request' | translate }}</div>
            <mat-form-field *ngIf="data.selected === 'request'">
                <mat-label>{{ labelsPrefix + 'reason' | translate }}</mat-label>
                <input #input [(ngModel)]="data.reason" autocomplete="off" matInput />
            </mat-form-field>
        </mat-radio-button>
        <mat-radio-button value="private">
            <div>{{ labelsPrefix + 'private' | translate }}</div>
            <mat-form-field *ngIf="data.selected === 'private'">
                <mat-label>{{ labelsPrefix + 'reason' | translate }}</mat-label>
                <input #input [(ngModel)]="data.reason" autocomplete="off" matInput />
            </mat-form-field>
        </mat-radio-button>
    </mat-radio-group>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>
    <button (click)="onSubmit()" [disabled]="!data.selected" class="is-large" color="accent" mat-button>
        {{ labelsPrefix + 'set_access' | translate }}
    </button>
    <button mat-dialog-close="cancel" mat-button>
        {{ labelsPrefix + 'cancel' | translate }}
    </button>
</div>
