import { Component, OnInit, Inject, ViewChild, ElementRef, NgModule, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'exl-long-text-dialog',
    templateUrl: './long-text-dialog.component.html',
    styleUrls: ['./long-text-dialog.component.scss']
})


export class LongTextDialogComponent implements OnInit, AfterViewInit {

    @ViewChild('confirm', {static: false}) confirm: ElementRef;
    @ViewChild('cancel', {static: false}) cancel: ElementRef;
    actionFocus = 'confirm';
    accentColor = 'accent';
    showDrawerButton = false;
    drawerOpened = false;
    viewInit = false;
    drawerDelay = 300;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        if (this.data.actionFocus) {
            this.actionFocus = this.data.actionFocus;
        }
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.openDrawer();
            this.viewInit = true;
        }, this.drawerDelay);
    }

    openDrawer() {
        this.drawerOpened = true;
    }

    onDrawerClosed() {
        this.drawerOpened = false;
    }

}
