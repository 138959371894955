<ng-container *ngIf="thumbs?.length > 0">
	<ng-container *ngFor="let thumb of thumbs">
	  <img
	    src="{{thumb.url}}"
	    alt="{{thumb.alt}}"
	    [style.width]="thumb.disabled ? null : width"
	    (load)="onLoad($event, thumb)"
	    [class.is-disabled]="thumb.disabled"
	    [style.opacity]="opacity"
	  />
	</ng-container>
</ng-container>
