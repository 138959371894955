import { Component, OnDestroy, OnInit } from '@angular/core';
import { DepositFormDataService } from '../../../deposit-form-data.service';
import { StatusUtils } from '../../../../shared/utils/status.utils';
import { DepositForm } from '../../../../shared/configurations/deposit-form.constant';
import { DepositPublicationDetailsService } from '../deposit-publication-details.service';
import { EsploroRecord } from '../../../../shared/interfaces/esploro-record.model';
import { AutoPopulateKeys } from '../../../../shared/configurations/auto-populate-keys.config';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
  selector: 'esp-deposit-publication-details-conference-proceeding',
  templateUrl:
    './deposit-publication-details-conference-proceeding.component.html',
  styleUrls: [
    './deposit-publication-details-conference-proceeding.component.scss',
  ],
})
export class DepositPublicationDetailsConferenceProceedingComponent
  implements OnInit, OnDestroy {
  searchIssnInProgress = false;

  private issnDestroy = new Subject<void>();
  private doiDestroy = new Subject<void>();
  private publisherDestroy = new Subject<void>();
  private volumeDestroy = new Subject<void>();
  private issueDestroy = new Subject<void>();
  private pagesDestroy = new Subject<void>();
  private isbnDestroy = new Subject<void>();

  constructor(
    public depositFormDataService: DepositFormDataService,
    private depositPublicationDetailsService: DepositPublicationDetailsService
  ) { }

  ngOnInit() {
    this.listenToIssnInput();
    this.onChangesPublisher();
    this.onChangesVolume();
    this.onChangesIssue();
    this.onChangesPages();
    this.onChangesIsbnForDisplay();
  }

  listenToIssnInput() {
    this.depositFormDataService.isPartOfIssn.valueChanges
      .pipe(
        debounceTime(DepositForm.SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.issnDestroy)
      )
      .subscribe((value) => {
        if (
          this.depositPublicationDetailsService.lastPartOfIssn !==
          value &&
          !this.depositFormDataService.startPopulating
        ) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.IS_PART_OF_ISSN
          );
        }
        if (
          value &&
          this.depositPublicationDetailsService.lastPartOfIssn !==
          value &&
          this.depositPublicationDetailsService.issnRegex.test(
            value.trim()
          )
        ) {
          this.searchIssnInProgress = true;
          this.issnEnrichment(value.trim());
        }
      });
  }

  onChangesIsbnForDisplay(): void {
    this.depositFormDataService.isPartOfIsbnForDisplay.valueChanges
      .pipe(takeUntil(this.isbnDestroy))
      .subscribe((value) => {
        const isbnForDisplay = value as string;
        let isPartOfIsbn = [];
        if (isbnForDisplay) {
          isPartOfIsbn = isbnForDisplay.split(
            DepositForm.VALUES_SEPARATOR
          );
        }
        this.depositFormDataService.updateIsPartOfIsbn(isPartOfIsbn);
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.IS_PART_OF_ISBN_FOR_DISPLAY
          );
        }
      });
  }

  onChangesPublisher(): void {
    this.depositFormDataService.publisher.valueChanges
      .pipe(takeUntil(this.publisherDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.PUBLISHER
          );
        }
      });
  }

  onChangesVolume(): void {
    this.depositFormDataService.isPartOfVolume.valueChanges
      .pipe(takeUntil(this.volumeDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.IS_PART_OF_VOLUME
          );
        }
      });
  }

  onChangesIssue(): void {
    this.depositFormDataService.isPartOfIssue.valueChanges
      .pipe(takeUntil(this.issueDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.IS_PART_OF_ISSUE
          );
        }
      });
  }

  onChangesPages(): void {
    this.depositFormDataService.isPartOfPages.valueChanges
      .pipe(takeUntil(this.pagesDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.IS_PART_OF_PAGES
          );
        }
      });
  }

  issnEnrichment(value) {
    this.depositPublicationDetailsService.enrichFromIssn(value).subscribe(
      (data) => {
        this.successAfterIssnSearch(data as EsploroRecord);
      },
      (error) => {
        this.searchIssnInProgress = false;
      }
    );
  }

  successAfterIssnSearch(data: EsploroRecord) {
    this.searchIssnInProgress = false;
    this.depositFormDataService.populatePartOfFormFromIssn(data, true);
  }

  displayISBN() {
    return !StatusUtils.isPosted(this.status);
  }

  displayISSN() {
    return !StatusUtils.isPosted(this.status);
  }

  displayPages() {
    return StatusUtils.displayPages(this.status);
  }

  displayPublisherName() {
    return StatusUtils.displayPublisherName(this.status);
  }

  displayVolume() {
    return StatusUtils.displayVolume(this.status);
  }

  displayIssue() {
    return StatusUtils.displayIssue(this.status);
  }

  get status() {
    return this.depositFormDataService.status.value;
  }

  isAutoPopulated(key) {
    return this.depositFormDataService.isKeyAutoPopulated(key);
  }

  ngOnDestroy() {
    if (!this.issnDestroy.isStopped) {
      this.issnDestroy.next();
      //unsubscribe from the subject itself
      this.issnDestroy.unsubscribe();
    }
    if (!this.doiDestroy.isStopped) {
      this.doiDestroy.next();
      this.doiDestroy.unsubscribe();
    }
    if (!this.publisherDestroy.isStopped) {
      this.publisherDestroy.next();
      this.publisherDestroy.unsubscribe();
    }
    if (!this.volumeDestroy.isStopped) {
      this.volumeDestroy.next();
      this.volumeDestroy.unsubscribe();
    }
    if (!this.issueDestroy.isStopped) {
      this.issueDestroy.next();
      this.issueDestroy.unsubscribe();
    }
    if (!this.pagesDestroy.isStopped) {
      this.pagesDestroy.next();
      this.pagesDestroy.unsubscribe();
    }
    if (!this.isbnDestroy.isStopped) {
      this.isbnDestroy.next();
      this.isbnDestroy.unsubscribe();
    }
  }
}
