import { OverlayRef } from '@angular/cdk/overlay';
import { Subject, Observable, filter, take } from 'rxjs';
import { DepositFormOverlayComponent } from './deposit-form-overlay.component';

export class DepositFormOverlayRef {

  private _beforeClose = new Subject<void>();
  private _afterClosed = new Subject<void>();
  componentInstance: DepositFormOverlayComponent;
  private ariaHiddenElements = new Map<Element, string | null>();

  constructor(private overlayRef: OverlayRef) { }

  close(): void {
    // Listen for animation 'start' events
    this.componentInstance.animationStateChanged.pipe(
      filter(event => event.phaseName === 'start'),
      take(1)
    ).subscribe(() => {
      this._beforeClose.next();
      this._beforeClose.complete();
      this.overlayRef.detachBackdrop();
    });

    // Listen for animation 'done' events
    this.componentInstance.animationStateChanged.pipe(
      filter(event => event.phaseName === 'done' && event.toState === 'leave'),
      take(1)
    ).subscribe(() => {
      this.overlayRef.dispose();
      this._afterClosed.next();
      this._afterClosed.complete();

      // Clear aria-hidden attribute if needed.
      this.restoreHidden();

      // clear the reference to the component instance to avoid memory leaks
      this.componentInstance = null;
    });

    this.componentInstance.startExitAnimation();
  }

  afterClosed(): Observable<void> {
    return this._afterClosed.asObservable();
  }

  beforeClose(): Observable<void> {
    return this._beforeClose.asObservable();
  }

  /**
   * Added because of JAWS Screen reader.
   * Hides all of the content that isn't an overlay from assistive technologies.
   */
  hideNonDialogContentFromAssistiveTechnology(overlayContainerElement: HTMLElement) {
    const overlayContainer = overlayContainerElement;

    // Ensure that the overlay container is attached to the DOM.
    if (overlayContainer.parentElement) {
      const siblings = overlayContainer.parentElement.children;

      for (let i = siblings.length - 1; i > -1; i--) {
        const sibling = siblings[i];

        if (sibling !== overlayContainer &&
          sibling.nodeName !== 'SCRIPT' &&
          sibling.nodeName !== 'STYLE' &&
          !sibling.hasAttribute('aria-live')) {

          this.ariaHiddenElements.set(sibling, sibling.getAttribute('aria-hidden'));
          sibling.setAttribute('aria-hidden', 'true');
        }
      }
    }
  }

  /**
  * Restore element that hidden from  assistive technologies.
  */
  private restoreHidden() {
    /** If all the deposit were closed, remove/restore the `aria-hidden` to a the siblings. */
    this.ariaHiddenElements.forEach((previousValue, element) => {
      if (previousValue) {
        element.setAttribute('aria-hidden', previousValue);
      } else {
        element.removeAttribute('aria-hidden');
      }
    });

    this.ariaHiddenElements.clear();
  }

}
