export class ResearcherPhone {
    phone: string;
    isPreferred: boolean;
    type: string[];
    esploroDisplayPhone: number;
    isExternal: boolean;

    constructor(phone, type, isPreferred, esploroDisplayPhone){
        this.phone = phone;
        this.type = type;
        this.isPreferred = isPreferred;
        this.esploroDisplayPhone = esploroDisplayPhone;
        this.isExternal = false;
    }
}
