<ng-content select="[slot='horizontaller-header']"></ng-content>
<div *ngIf="headerContent" class="horizontaller-header margin-bottom-md"></div>
<div #scrollerParent class="anchor">
  <button
    (click)="scroll('left')"
    *ngIf="!scrollLeftDisabled"
    @opacityOutAnimation
    [@slideInAnimation]="{ value: 'enter', params: { position: 'absolute', dX: '-100%', dY: '0' } }"
    [attr.aria-label]="scrollLeftBtnAriaLabel"
    [disabled]="scrollStarted || scrollLeftDisabled"
    class="scroll-button is-large"
    mat-icon-button
  >
    <mat-icon class="mat-icon-rtl-mirror" svgIcon="chevron-left"></mat-icon>
  </button>
  <div class="outer-wrapper">
    <div #scrollerWrapper [tabIndex]="0" class="content">
      <div #scroller class="content-inner-wrapper">
        <div class="lead-spacer"></div>
        <div class="items-wrapper">
          <div *ngIf="loading" @opacityOutAnimation class="placeholders">
            <div *ngFor="let placeholder of _placeholders" class="margin-right-sm">
              <!-- animation-out-child -->
              <ng-container
                *ngIf="placeholderTemplate; else defaultPlacholder"
                [ngTemplateOutlet]="placeholderTemplate"
              >
              </ng-container>

              <ng-template #defaultPlacholder>
                <content-loader
                  [rtl]="rtl"
                  [style]="{ width: placeholderWidth, height: height }"
                  backgroundColor="#f1f1f1"
                  class="placeholder-item"
                  foregroundColor="#dddddd"
                  viewBox
                >
                  <svg:rect [attr.height]="height" [attr.width]="placeholderWidth" x="0" y="0" />
                </content-loader>
              </ng-template>
            </div>
          </div>
          <div *ngIf="!loading" @opacityInAnimation class="items">
            <ng-content select="mulo-horizontaller-item"></ng-content>
          </div>
        </div>
        <div class="lead-spacer"></div>
      </div>
    </div>
  </div>
  <div *ngIf="showPageBullets" class="page-bullets">
    <button
      (click)="scrollToItem(i)"
      *ngFor="let item of items; index as i"
      [attr.aria-label]="
        pageBulletBtnLabel ? (pageBulletBtnLabel | translate : { num: i + 1 }) : 'Jump to item ' + (i + 1)
      "
      [class.active-bullet]="activeBullet === i"
      [disabled]="activeBullet === i"
      class="bullet"
    ></button>
  </div>
  <button
    (click)="scroll('right')"
    *ngIf="!scrollRightDisabled"
    @opacityOutAnimation
    [@slideInAnimation]="{ value: 'enter', params: { position: 'absolute', dX: '100%', dY: '0' } }"
    [attr.aria-label]="scrollRightBtnAriaLabel"
    [disabled]="scrollStarted || scrollRightDisabled"
    class="is-large scroll-button"
    mat-icon-button
  >
    <mat-icon class="mat-icon-rtl-mirror" muloSvgViewbox svgIcon="chevron-right"></mat-icon>
  </button>
</div>
