import {
  Component,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  Directive,
} from '@angular/core';
import {
  WidthInAnimation,
  WidthOutAnimation,
  shortenLargeNumber,
} from '@exl-ng/mulo-common';
import { FacetNumericalComponent } from '../facet-numerical/facet-numerical.component';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';

@Directive({
    selector: '[slot="facet-content"]',
    standalone: true
})
export class FacetItemContentDirective {}

@Component({
    selector: 'mulo-facet-item',
    templateUrl: './facet-item.component.html',
    styleUrls: ['./facet-item.component.scss'],
    host: {
        class: 'mulo-facet-item',
        '[class.is-checked]': 'hasCheckbox ? checked : false',
        '[class.is-excluded]': 'excluded',
        '[class.is-hovered]': 'mouseOver',
    },
    animations: [WidthInAnimation, WidthOutAnimation],
    standalone: true,
    imports: [
        NgIf,
        MatCheckbox,
        MatButton,
        MatIconButton,
        MatIcon,
    ],
})
export class FacetItemComponent {
  @Input() key: string;
  @Input() label: string;
  @Input() id: string;

  @Input() abbreviateCount = true;
  @Input() countDecimalPlaces = 1;
  private _count: string | number = '';

  @Input() excludeAriaLabel = 'Exclude';
  @Input() selectArialLabel = 'Select';

  @Output() clicked = new EventEmitter<null>();

  @Input() hasCheckbox = true;
  private _checked = false;
  @Output() checkedChange = new EventEmitter<boolean>();
  checkboxDisabled = false;
  private _excluded = false;
  @Output() excludedChange = new EventEmitter<boolean>();

  @ContentChild(FacetItemContentDirective) content;
  @ContentChild(FacetNumericalComponent)
  numericalFacet: FacetNumericalComponent;

  mouseOver = false;

  @Input() public get count(): string | number {
    return this._count;
  }
  public set count(count: string | number) {
    if (typeof count === 'string') {
      parseInt(count);
    }
    this._count = this.abbreviateCount
      ? shortenLargeNumber(count, this.countDecimalPlaces)
      : count;
  }

  @Input() public get checked(): boolean {
    return this._checked;
  }
  public set checked(value: boolean) {
    this._checked = value;
    if (!value && this.excluded) {
      this.excluded = false;
    }
    // this.checkedChange.emit(value)
  }

  @Input() public get excluded(): boolean {
    return this._excluded;
  }
  public set excluded(value: boolean) {
    this._excluded = value;
  }

  constructor() {}

  ngAfterContentInit() {
    if (this.numericalFacet) {
      this.content = true;
    }
  }

  handleButtonClick() {
    this.clicked.emit(null);
  }

  handleCheckedChange(ev) {
    this.checked = ev.checked;
    this.checkedChange.emit(ev.checked);
    // this.change.emit(null)
  }

  handleExcludeClick() {
    this.excluded = this.checked ? !this.excluded : true;
    this.excludedChange.emit(this.excluded);
  }

  onCheckboxClick(ev) {
    this.checked = !this.checked;
    this.handleCheckedChange({ checked: this.checked });
  }

  onCheckboxPadClick(ev) {
    ev.stopPropagation;
    if (!this.checkboxDisabled) {
      this.onCheckboxClick(ev);
    }
  }

  disableCheckbox(value: boolean) {
    this.checkboxDisabled = value;
  }
}
