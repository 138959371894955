// This module consoidates all the elements comprising thebasis for Exlibris apps UI
import { NgModule } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
// import { DomSanitizer } from '@angular/platform-browser';

// CDK overlay container - gives us acces to elements outside the app root
//  Flex layout engine (by Angular Material)
// The official way of adding Angular Material icon support
// import { MatIconRegistry } from '@angular/material';

@NgModule({
  imports: [
    // BrowserAnimationsModule,
    MatIconModule,
  ],
  exports: [
    // BrowserAnimationsModule,
    MatIconModule,
  ],
})
export class MaterialBaseModule {
  constructor(overlayContainer: OverlayContainer) {
    // const svgIconsFile = '../assets/icons/mulo-app-icons.svg';
    // const sanitizedUrl = sanitizer.bypassSecurityTrustResourceUrl(svgIconsFile)

    // // define main app SVG icon reference
    // iconRegistry.addSvgIconSet(sanitizedUrl)

    // Adds the neccessary Material extension class to the overlay container
    const materialExtensionClassName = 'mulo-extend-material';
    overlayContainer
      .getContainerElement()
      .classList.add(materialExtensionClassName);
  }
}
