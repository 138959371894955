<mat-sidenav-container [hasBackdrop]="hasBackdrop">
  <mat-sidenav #sidenav [mode]="mode" [fixedInViewport]="fixedInViewport" [position]="position" [autoFocus]="true">
    <div
      class="sidenav-header"
      [class.button-on-left]="closeButtonSide === 'left'"
      [class.button-on-right]="closeButtonSide === 'right'"
    >
      <mulo-menu-toggle-button
        *ngIf="closeButtonMode != 'hidden'"
        (clicked)="close()"
        [state]="'open'"
        [style.position]="closeButtonMode"
        [closeIcon]="closeButtonIcon"
      >
      </mulo-menu-toggle-button>
      <ng-content select="[slot='mulo-brand'], mulo-brand"></ng-content>
    </div>

    <ng-container *ngIf="observer$ | async as template">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </ng-container>
  </mat-sidenav>
</mat-sidenav-container>
