import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';

import {
    BrandComponent,
    FunkyButtonComponent,
    HeaderComponent,
    HeaderFixedDirective,
    HeaderLinksComponent,
    HeaderNavComponent,
    HeaderNavItemComponent,
    LanguageSelectButtonComponent,
    LanguageSelectComponent,
    LanguageSelectDialogComponent,
    MenuToggleButtonComponent,
    NotifyBellComponent,
    OnboardingComponent,
    OnboardingDirective,
    SearchbarAdvRootDirective,
    SearchbarAutocompleteTemplateDirective,
    SearchbarComponent,
    SearchbarOptionsComponent,
    SidenavComponent,
} from '@exl-ng/mulo-common';
import { BriefComponent } from '@exl-ng/mulo-views';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { OverlayModule } from '@angular/cdk/overlay';

import { SharedModule } from '../shared/shared.module';
import { ImageDialogModule } from '../parts/dialogs/image-dialog/image-dialog.module';
import { LogoutModule } from '../logout/logout.module';
import { UserMenuModule } from '../parts/user-menu/user-menu.module';
import { UserNavModule } from '../parts/user-nav/user-nav.module';
import { AddProjectModule } from '../project/add-project/add-project.module';
import { AdvancedSearchModule } from '../parts/advanced-search/advanced-search.module';

import { EsploroHeaderComponent } from './esploro-header.component';
import { AddActivityModule } from '../activities/add-activities/add-activity.module';

@NgModule({
    imports: [
        BrandComponent,
        HeaderComponent,
        HeaderFixedDirective,
        HeaderNavComponent,
        HeaderNavItemComponent,
        HeaderLinksComponent,
        FunkyButtonComponent,
        SharedModule,
        ImageDialogModule,
        LogoutModule,
        RouterModule,
        OnboardingComponent,
        OnboardingDirective,
        LanguageSelectButtonComponent,
        LanguageSelectDialogComponent,
        LanguageSelectComponent,
        SidenavComponent,
        MenuToggleButtonComponent,
        UserMenuModule,
        UserNavModule,
        SearchbarComponent,
        SearchbarOptionsComponent,
        SearchbarAutocompleteTemplateDirective,
        SearchbarAdvRootDirective,
        AddProjectModule,
        AdvancedSearchModule,
        AddActivityModule,
        NotifyBellComponent,
        MatMenuModule,
        BriefComponent,
        MomentModule,
        MatIconModule,
        OverlayModule,
    ],
    exports: [EsploroHeaderComponent],
    declarations: [EsploroHeaderComponent],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EsploroHeaderModule {}
