import { trigger, animate, transition, style } from '@angular/animations';
// import * as easings from './easings'

export const OpacityInAnimation = trigger('opacityInAnimation', [
  transition(
    ':enter',
    [
      style({
        opacity: 0,
      }),
      animate(
        '{{ time }} ease',
        style({
          opacity: 1,
        })
      ),
    ],
    {
      params: {
        time: '.8s',
      },
    }
  ),
]);

export const OpacityOutAnimation = trigger('opacityOutAnimation', [
  transition(
    ':leave',
    [
      style({
        opacity: 1,
      }),
      animate(
        '{{ time }} ease',
        style({
          opacity: 0,
        })
      ),
    ],
    {
      params: {
        time: '.8s',
      },
    }
  ),
]);
