export class AutoPopulateKeys {
    public static TITLE = 'title';
    public static SUBTITLE = 'subtitle';
    public static DOI = 'doi';
    public static RELATION_TITLE = 'relationTitle';
    public static IS_PART_OF_ISSN = 'isPartOfIssn';
    public static IS_PART_OF_ISSUE = 'isPartOfIssue';
    public static IS_PART_OF_VOLUME = 'isPartOfVolume';
    public static STATUS = 'status';
    public static PMID = 'pmid';
    public static ISSN = 'issn';
    public static EISSN = 'eissn';
    public static PUBLISHER = 'publisher';
    public static PEER_REVIEWED = 'peerReviewed';
    public static OPEN_ACCESS = 'openAccess';
    public static OPEN_ACCESS_STATUS = 'openAccessStatus';
    public static DESCRIPTION = 'description';
    public static METHODS = 'methods';
    public static COVERAGE = 'coverage';
    public static TECHNICAL_INFORMATION = 'technicalInformation';
    public static TITLE_ALTERNATIVE = 'titleAlternative';
    public static DATE = 'date';
    public static DATE_PUBLISHED = 'datePublished';
    public static DATE_PRESENTED = 'datePresented';
    public static DATE_COPYRIGHTED = 'dateCopyrighted';
    public static IS_PART_OF_EISSN = 'isPartOfEissn';
    public static IS_PART_OF_EISBN = 'isPartOfEisbn';
    public static ADDRESS = 'address';
    public static IS_PART_OF_ISBN_FOR_DISPLAY = 'isPartOfIsbnForDisplay';
    public static IS_PART_OF_DOI = 'isPartOfDoi';
    public static IS_PART_OF_PAGES = 'isPartOfPages';
    public static ISBN_FOR_DISPLAY = 'isbnForDisplay';
    public static PAGES = 'pages';
    public static EDITION = 'edition';
    public static SERIES = 'series';
    public static PUBLICATION_PLACE = 'publicationPlace';
    public static FORMAT = 'format';
    public static AUDIENCE = 'audience';
    public static CONFERENCE_NAME = 'conferenceName';
}
