import {CalendarDate} from '../interfaces/calendar-date.model';
import moment, {Moment} from 'moment';

export class DateUtils {

    public static getYear(date: Date) {
        return date.getFullYear();
    }

    public static getMonth(date: Date) {
        return date.getMonth() + 1;
    }

    public static getDay(date: Date) {
        return date.getDate();
    }

    public static getFullMomentDateAsString(date, format): string {
        return moment(date).format(format);
    }

    public static isEmptyDate(date: CalendarDate){
        if (date.dayOfMonth === 1 && date.hourOfDay === 0 && date.minute === 0 && date.month === 0 &&
            date.second === 0 && date.year === 1970){
            return true;
        }
        return false;
    }


}
