import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ScaleInOutAnimation } from '@exl-ng/mulo-common';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { Status } from '../../../shared/configurations/status.constant';
import { DateUtils } from '../../../shared/utils/date.utils';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { StatusUtils } from '../../../shared/utils/status.utils';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'esp-deposit-status',
  templateUrl: './deposit-status.component.html',
  styleUrls: ['./deposit-status.component.scss'],
  animations: [ScaleInOutAnimation],
})
export class DepositStatusComponent implements OnInit, OnDestroy {
  private dateDestroy = new Subject<void>();
  readonly statusConst = Status;

  @Input() required;

  dateTitleOptions = {
    unpublished: '',
    posted: 'research.status.post',
    submitted: 'research.status.submit',
    accepted: 'research.status.accept',
    epub: 'research.status.online',
    published: 'research.status.publish',
  };

  constructor(public depositFormDataService: DepositFormDataService) { }

  ngOnInit() {
    this.onChanges();
  }

  onChanges(): void {
    this.depositFormDataService.date.valueChanges
      .pipe(takeUntil(this.dateDestroy))
      .subscribe((value) => {
        if (this.status === this.statusConst.PUBLISHED) {
          return;
        }
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.DATE
          );
        }
        if (!this.depositFormDataService.date.value) {
          this.depositFormDataService.updateYear('');
          this.depositFormDataService.updateMonth('');
          this.depositFormDataService.updateDay('');
        } else {
          const date = new Date(
            this.depositFormDataService.date.value
          );
          this.depositFormDataService.updateYear(
            DateUtils.getYear(date)
          );
          this.depositFormDataService.updateMonth(
            DateUtils.getMonth(date)
          );
          this.depositFormDataService.updateDay(
            DateUtils.getDay(date)
          );
        }
      });
  }

  displayMoreInformation() {
    return (
      this.status &&
      this.status !== Status.UNPUBLISHED &&
      this.status !== Status.POSTED
    );
  }

  displayDate() {
    return this.status && this.status !== Status.UNPUBLISHED;
  }

  get isDateAutoPopulated() {
    return this.depositFormDataService.isKeyAutoPopulated(
      AutoPopulateKeys.DATE
    );
  }

  dateTitle() {
    return this.dateTitleOptions[this.status];
  }

  get status() {
    return this.depositFormDataService.status.value;
  }

  isJournalArticle() {
    return this.depositFormDataService.isPublicationJournalArticle();
  }

  isBookReview() {
    return this.depositFormDataService.isPublicationBookReview();
  }

  isBookChapter() {
    return this.depositFormDataService.isPublicationBookChapter();
  }

  isConferenceProceedings() {
    return this.depositFormDataService.isPublicationConferenceProceedings();
  }

  isBook() {
    return this.depositFormDataService.isPublicationBook();
  }

  ngOnDestroy() {
    if (!this.dateDestroy.isStopped) {
      this.dateDestroy.next();
      //unsubscribe from the subject itself
      this.dateDestroy.unsubscribe();
    }
  }
}
