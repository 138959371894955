import { NgModule } from '@angular/core';
import {PersonBriefComponent, PersonBriefTitleDirective} from './person-brief.component';




@NgModule({
    imports: [

    ],
    declarations: [
        PersonBriefComponent,
        PersonBriefTitleDirective
    ],
    exports: [
        PersonBriefComponent,
        PersonBriefTitleDirective
    ]
})
export class PersonBriefModule { }
