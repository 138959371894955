import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
    selector: 'esp-content-dialog',
    templateUrl: './content-dialog.component.html',
    styleUrls: ['./content-dialog.component.scss'],
})
export class ContentDialogComponent implements OnInit {
    private contentDialogDestroy = new Subject<void>();

    constructor(
        public dialogRef: MatDialogRef<ContentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.contentDialogDestroy.next();
        this.contentDialogDestroy.unsubscribe();
    }

    onDialogClose() {
        this.dialogRef.close();
    }

    onSubmit() {
        this.data.submit.next();
    }
}
