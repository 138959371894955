import { Component, Input, OnInit } from '@angular/core';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'mulo-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    host: {
        '[class]': "'mulo-badge ' + 'color-' + color",
        '[class.is-small]': 'size === "small"',
        '[class.is-medium]': 'size === "medium"',
        '[class.is-large]': 'size === "large"',
    },
    standalone: true,
    imports: [
        NgIf,
        MatIcon,
        SvgViewboxDirective,
        NgClass,
    ],
})
export class BadgeComponent implements OnInit {
  @Input() icon: string;
  @Input() color: 'primary' | 'accent' | 'warn' | string = 'warn';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() tooltip: string;
  @Input() type: 'dot' | 'icon' = 'icon';
  @Input() count: string;
  @Input() rtlMirrorIcon: boolean;

  hexColor: string; // HEX color
  hexColorRegex = '^#(?:[0-9a-fA-F]{3}){1,2}$';

  constructor() {}

  ngOnInit() {
    this.hexColor = this.color.match(this.hexColorRegex) ? this.color : null;
  }
}
