import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PickerItemComponent} from './picker/picker-item/picker-item.component';
import {PickerComponent} from './picker/picker.component';
import {SharedModule} from '../../shared/shared.module';

@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        PickerComponent,
        PickerItemComponent
    ],
    exports: [
        PickerComponent,
        PickerItemComponent
    ]
})
export class MenusModule { }
