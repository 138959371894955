import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { SeparatorComponent } from './../../separator/separator.component';

@Component({
    selector: 'mulo-floating-toolbar-separator',
    templateUrl: './floating-toolbar-separator.component.html',
    styleUrls: ['./floating-toolbar-separator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'mulo-separator mulo-floating-toolbar-separator' },
    standalone: true
})
export class FloatingToolbarSeparatorComponent extends SeparatorComponent implements OnInit {

  constructor() {
    super()
  }

  ngOnInit(): void {
  }

}
