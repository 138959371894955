import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'mulo-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  host: {
    class: 'mulo-spinner',
    '[style.width.px]': 'diameter',
    '[style.height.px]': 'diameter',
  },
})
export class SpinnerComponent {
  @Input() diameter: number = 80;
  @Input() colors: string | string[] = ['pink', 'cornsilk'];

  ngOnInit() {
    if (typeof this.colors === 'string') {
      this.colors = [this.colors];
    }
  }
}
