<div #content class="content">
  <ng-content></ng-content>
</div>
<ng-container *ngIf="hideButtonOnExpand ? expandable && !(expanded || expanding) : expandable">
  <button
    mat-button
    class="expand-button"
    (click)="toggle()"
    [class.at-right]="buttonPosition === 'right'"
    [class.at-left]="buttonPosition === 'left'"
    [class.is-overlapping]="buttonOverlap && !(expanded || expanding)"
    [class.has-icon]="buttonStyle === 'full'"
    [class.mat-icon-button]="buttonStyle === 'icon'"
    [color]="buttonColor"
    [mulo-aria-describe]="descriptionLabel"
    [disabled]="buttonDisabled"
  >
    <mat-icon
      *ngIf="buttonStyle === 'full' || buttonStyle === 'icon'"
      [svgIcon]="expanded || expanding ? buttonIcon.up : buttonIcon.down"
    ></mat-icon>
    <ng-container *ngIf="buttonStyle === 'full' || buttonStyle === 'text'">
      {{ expanded || expanding ? collapseLabel : expandLabel }}
    </ng-container>
  </button>
</ng-container>
