import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Directive,
    Input,
    HostBinding,
} from '@angular/core';
import { SectionClass } from '../../../shared/classes/sections.class';

@Component({
    selector: 'exl-footer-links-section',
    templateUrl: './footer-links-section.component.html',
    styleUrls: ['./footer-links-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'exl-footer-links-section' },
})
export class FooterLinksSectionComponent
    extends SectionClass
    implements OnInit
{
    @Input() public minWidth = '170px';

    @HostBinding('style.minWidth') get calcMinWidth() {
        return `calc(${this.minWidth} - ${this.columnGap})`;
    }

    @HostBinding('style.maxWidth')
    @Input()
    public maxWidth = '230px';

    @Input() public flexBasis: number | null = 4;

    @HostBinding('style.flexBasis') get flexBasisStyle() {
        return `calc(100% / ${this.flexBasis})`;
    }

    @HostBinding('class.horizontal-list')
    @Input()
    public isHorizontalList = false;

    @Input() public columnGap = '2rem';

    constructor() {
        super('footer-links');
    }

    ngOnInit(): void {}

    public updateMinWidth(value: string) {
        setTimeout(() => {
            this.minWidth = value;
        });
    }

    public updateMaxWidth(value: string) {
        setTimeout(() => {
            this.minWidth = value;
        });
    }

    public updateFlexBasis(value: number) {
        setTimeout(() => {
            this.flexBasis = value;
        });
    }
}
