import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    BehaviorSubject,
    map,
    mergeMap,
    Observable,
    shareReplay,
    take,
} from 'rxjs';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@exl-ng/mulo-common';

import { CodeLabelEntry } from '../shared/interfaces/code-label-entry.interface';
import { UrlUtils } from '../shared/utils/url.utils';
import { DateUtils } from '../shared/utils/date.utils';
import { CodeTableService } from './code-table.service';
import { SystemDateFormatService } from '@exl-ng/mulo-core';
import { AccessRight } from '../shared/interfaces/file.model';
import { EntityApi } from '../shared/configurations/rest-api-base.config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { JwtUtilService } from './jwt-util.service';
import { LicenseEsploroInterface } from '../shared/interfaces/license-esploro.interface';
import { Router } from '@angular/router';
import { I18nService } from './i18n.service';
import { EntityAutoComplete } from '../shared/interfaces/entities/entity-auto-complete';

@Injectable({ providedIn: 'root' })
export class EntityService {
    entityId: string;
    institutionCode: string;
    loading: boolean;
    api: EntityApi;
    entityCode = 'portal';
    protected entityIdSubject = new BehaviorSubject<string>(null);
    entityId$ = this.entityIdSubject.asObservable();

    protected i18nSvc = inject(I18nService);
    protected translate = inject(TranslateService);
    protected ctSvc = inject(CodeTableService);
    protected systemDateFormatService = inject(SystemDateFormatService);
    protected matDialog = inject(MatDialog);
    protected http = inject(HttpClient);
    protected jwtSvc = inject(JwtUtilService);
    protected router = inject(Router);

    constructor() {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    changeEntityId(entityId: string) {
        this.entityIdSubject.next(entityId);
    }

    getEntityById(
        entityId = this.entityId,
        queryParam = null,
    ): Observable<any> {
        let params = new HttpParams().set('institution', this.institutionCode);
        if (queryParam) {
            for (const queryParamKey in queryParam) {
                params = params.set(queryParamKey, queryParam[queryParamKey]);
            }
        }
        return this.http
            .get(`${this.api.base}/${entityId}`, { params })
            .pipe(shareReplay());
    }

    addNew(body: any): Observable<any> {
        return null;
    }

    save(entityDetails: any): Observable<any> {
        return null;
    }

    getApi<T>(api: string, queryParam = null) {
        let params = new HttpParams().set('institution', this.institutionCode);
        if (queryParam) {
            for (const queryParamKey in queryParam) {
                params = params.set(queryParamKey, queryParam[queryParamKey]);
            }
        }
        return this.http
            .get<T>(`${this.api.base}/${api}`, { params })
            .pipe(shareReplay());
    }

    getSectionApi<T>(section: string, queryParam = null) {
        let params = new HttpParams().set('institution', this.institutionCode);
        if (queryParam) {
            for (const queryParamKey in queryParam) {
                params = params.set(queryParamKey, queryParam[queryParamKey]);
            }
        }
        return this.http.get<T>(
            `${this.api.base}/${this.entityId}/${section}`,
            { params },
        );
    }
    getEntitySectionAutoComplete(section: string, searchText: string) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('q', searchText);

        return this.http
            .get<
                EntityAutoComplete[]
            >(`${this.api.base}/${this.entityId}/${section}/search`, { params })
            .pipe(shareReplay());
    }

    updateSectionItem(sectionKey: any, item: any): Observable<any> {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http
            .post(`${this.api.base}/${this.entityId}/${sectionKey}`, item, {
                params,
            })
            .pipe(shareReplay());
    }

    reorderSectionItems(
        sectionKey: string,
        newIndex: number,
        oldIndex: number,
    ): Observable<any> {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http
            .put(
                `${this.api.base}/${this.entityId}/${sectionKey}/reorder`,
                { newIndex, oldIndex },
                { params },
            )
            .pipe(shareReplay());
    }

    deleteSectionItem(
        sectionKey: any,
        itemId: any,
        precallCb?: () => void,
        sectionLabel?: string,
        itemLabel?: string,
        list?: any[],
    ): Observable<any> {
        const dialogMethod = sectionLabel
            ? this.showRemoveConfirmationDialog(sectionLabel, itemLabel)
            : this.showDeleteConfirmationDialog();

        return dialogMethod.pipe(
            mergeMap((response) => {
                if (response === 'ok') {
                    precallCb?.();
                    return !list
                        ? this.deleteItemCall(sectionKey, itemId)
                        : this.deleteItemsListCall(sectionKey, list);
                }
                return null;
            }),
        );
    }

    deleteItemsListCall(sectionKey: any, list: any[]) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http
            .post(
                `${this.api.base}/${this.entityId}/${sectionKey}/deleteItems`,
                list,
                { params },
            )
            .pipe(shareReplay());
    }

    deleteItemCall(sectionKey: any, itemId: any): Observable<any> {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http
            .delete(
                `${this.api.base}/${this.entityId}/${sectionKey}/${itemId}`,
                { params },
            )
            .pipe(shareReplay());
    }

    updateSectionListItems(sectionKey: any, items: any[]): Observable<any> {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http
            .post(`${this.api.base}/${this.entityId}/${sectionKey}`, items, {
                params,
            })
            .pipe(shareReplay());
    }

    isIdentifierUnique(identifier: string): Observable<any> {
        return null;
    }

    getPossibleLinkLicenses(): Observable<string[]> {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );

        const convertToCode$ = map((table: LicenseEsploroInterface[]) =>
            table
                .filter((row) => row.active && row.displayInResearcherView)
                .sort((a, b) => {
                    return a.code > b.code ? 1 : -1;
                })
                .map((tableRow) => tableRow.code),
        );
        return this.http
            .get(`${this.api.base}/possibleLinkLicenses`, { params })
            .pipe(convertToCode$, shareReplay());
    }

    getPossibleFilesAccessRights(): Observable<AccessRight[]> {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('language', this.i18nSvc.getLanguage());

        const convertToCode$ = map((list: AccessRight[]) =>
            list.sort((a, b) => {
                return a.name > b.name ? 1 : -1;
            }),
        );
        return this.http
            .get(`${this.api.base}/possibleAccessRights`, { params })
            .pipe(convertToCode$, shareReplay());
    }

    getAmazonInfo(): Observable<any> {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http
            .get(`${this.api.base}/${this.entityId}/files/amazonInfo`, {
                params,
            })
            .pipe(shareReplay());
    }

    getDownloadUrl(itemId: any) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http
            .get(
                `${this.api.base}/${this.entityId}/files/${itemId}/downloadUrl`,
                { params, responseType: 'text' },
            )
            .pipe(shareReplay());
    }

    getCodeTableList(
        tableName,
        sort: (a: CodeLabelEntry, b: CodeLabelEntry) => number = this
            .sortByAlphabeticalOrder,
    ) {
        const convertToCode$ = map((table: CodeLabelEntry[]) =>
            table
                .filter((tableRow) => tableRow.enabled)
                .sort(sort)
                .map((tableRow) => tableRow.code),
        );
        return this.ctSvc
            .getCodeTable(tableName, this.translate.currentLang)
            .pipe(convertToCode$, shareReplay());
    }

    getCodeTable(tableName: string): Observable<CodeLabelEntry[]> {
        const convertToCode$ = map((table: CodeLabelEntry[]) =>
            table
                .filter((tableRow) => tableRow.enabled)
                .sort((a, b) => a.description.localeCompare(b.description)),
        );
        return this.ctSvc
            .getCodeTable(tableName, this.translate.currentLang)
            .pipe(convertToCode$, shareReplay());
    }

    getTranslation(code, params = {}) {
        return this.translate.instant(code, params);
    }

    sortDatesList(dates: any[]) {
        return dates.sort(
            (x, y) => +moment(x.date, 'YYYYMMDD') - +moment(y.date, 'YYYYMMDD'),
        );
    }

    getDateLabel(date: string) {
        return !!date
            ? DateUtils.getFullMomentDateAsString(
                  moment(date, 'YYYYMMDD'),
                  this.systemDateFormatService.getSystemDateFormat(),
              )
            : '';
    }

    showDeleteConfirmationDialog() {
        // using t for translate function to increase readability
        const t = (code, params?) => this.translate.instant(code, params);

        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                message: t('research.delete.confirmation.message'),
                actions: {
                    cancel: t('research.delete.confirmation.cancel'),
                    confirm: t('research.delete.confirmation.confirm'),
                },
            },
        });
        return dialogRef.afterClosed();
    }

    showRemoveConfirmationDialog(
        sectionName: string,
        itemName = sectionName.toLocaleLowerCase(),
    ) {
        // using t for translate function to increase readability
        const t = (code, params?) => this.translate.instant(code, params);

        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: t(
                    'research.portal.entity.removeItem.confirmDialog.title',
                    { sectionName },
                ),
                message: t(
                    'research.portal.entity.removeItem.confirmDialog.message',
                    { itemName },
                ),
                actions: {
                    cancel: t('research.aria.dialog.cancel'),
                    confirm: t('research.aria.icon.button.remove'),
                },
            },
        });
        return dialogRef.afterClosed();
    }

    getOrganizationsAutoComplete(searchText: string, isInternal: boolean) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('searchText', searchText)
            .set('isInternal', isInternal);

        return this.http
            .get(`${this.api.base}/organizationsAutoComplete`, { params })
            .pipe();
    }

    updatePublicProfileVisibility(visibility: boolean) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http.put(
            `${this.api.base}/${this.entityId}/updatePublicProfileVisibility`,
            { userId: this.jwtSvc.getUserID(), visibility },
            { params },
        );
    }

    getAssociatedResearcherRoleValues(categoryCode) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('categoryCode', categoryCode)
            .set('lang', this.translate.currentLang);

        return this.http.get(
            `${this.api.base}/getAssociatedResearcherRoleValues`,
            {
                params,
            },
        );
    }

    reorderFiles(newIdx: number, oldIdx: number) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('newIndex', newIdx)
            .set('oldIndex', oldIdx);

        return this.http
            .get(`${this.api.base}/${this.entityId}/files/reorderFiles`, {
                params,
            })
            .pipe(take(1));
    }

    reorderLinks(newIdx: number, oldIdx: number) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('newIndex', newIdx)
            .set('oldIndex', oldIdx);

        return this.http
            .get(`${this.api.base}/${this.entityId}/links/reorderLinks`, {
                params,
            })
            .pipe(take(1));
    }

    toggleNavigation(isEditMode: boolean) {
        const url = this.router.url.substring(0, this.router.url.indexOf('?'));
        const queryParams = { institution: this.institutionCode };
        if (!isEditMode) {
            queryParams['mode'] = 'edit';
        }
        this.router
            .navigateByUrl('/', {
                skipLocationChange: true,
                state: { skipRoot: true },
            })
            .then(() => {
                this.router.navigate([url], {
                    queryParams,
                    replaceUrl: true,
                });
            });
    }

    forceView() {
        const url = this.router.url.substring(0, this.router.url.indexOf('?'));
        const param = { institution: this.institutionCode };
        param['mode'] = 'view';
        this.router
            .navigateByUrl('/', {
                skipLocationChange: true,
                state: { skipRoot: true },
            })
            .then(() => {
                this.router.navigate([url], { queryParams: param });
            });
    }

    private sortByAlphabeticalOrder = (a, b) =>
        a.description.localeCompare(b.description);
}
