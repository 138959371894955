import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { RouterService } from './core/router.service';
import { ActivatedRoute } from '@angular/router';
import { transition, trigger, useAnimation } from '@angular/animations';
import {
    BrandService as MuloBrandService,
    MuloMatCssVarsService,
    RouteSlideCoreAnimation,
    RouteSlideParams,
} from '@exl-ng/mulo-common';
import { ConfigurationHandlerService } from './core/configuration-handler.service';
import { HttpClient } from '@angular/common/http';
import { I18nService } from './core/i18n.service';
import { SessionStorageUtilService } from './core/session-storage-util.service';
import { JwtUtilService } from './core/jwt-util.service';
import { DepositForm } from './shared/configurations/deposit-form.constant';
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
} from '@angular/material/core';
import { DateTimeService } from './core/date-time.service';
import { componentDestroyed, MediaService } from '@exl-ng/mulo-core';
import { State } from './shared/configurations/state.constant';
import { UrlUtils } from './shared/utils/url.utils';
import { CustomizationService } from './core/customization.service';
import { BrandService } from './core/brand.service';
import { LinkResolverService } from './core/link-resolver.service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FaviconService } from './core/favicon.service';
import { GoogleAnalyticsService } from './core/google-analytics.service';
import { ExtendedConfigurationService } from './core/extended-configuration.service';
import { Configuration } from './shared/interfaces/configuration.interface';
import { map, takeUntil } from 'rxjs';
import { OnetrustPendoService } from './core/onetrust-pendo.service';

const routesAnimationConfig = trigger('routeAnimations', [
    transition(
        ':increment',
        useAnimation(RouteSlideCoreAnimation, RouteSlideParams.right),
    ),
    transition(
        ':decrement',
        useAnimation(RouteSlideCoreAnimation, RouteSlideParams.left),
    ),

    transition(
        '* <=> search',
        useAnimation(RouteSlideCoreAnimation, RouteSlideParams.up),
    ),
]);

@Component({
    selector: 'esp-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [routesAnimationConfig],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            deps: [DateTimeService],
            useFactory: (dateTimeService: DateTimeService) =>
                dateTimeService.getDateFormat(),
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, DateTimeService],
        },
    ],
})
export class AppComponent implements OnInit, OnDestroy {
    animationState: number;
    breakpoint: string;
    institutionCode: string;
    institutionName: string;
    logoUrl: string;

    // check environment settings if extending Material is enabled, and apply class to app-root
    @HostBinding('class.exl-extend-material') extendMaterial: boolean =
        this.materialExtend();

    @HostBinding('dir') direction = 'ltr';

    constructor(
        private routerService: RouterService,
        private activatedRoute: ActivatedRoute,
        private materialCssVarsService: MuloMatCssVarsService,
        private configurationHandlerService: ConfigurationHandlerService,
        private http: HttpClient,
        private sessionStorageUtilService: SessionStorageUtilService,
        private i18nService: I18nService,
        private jwtUtilService: JwtUtilService,
        private customizationService: CustomizationService,
        private linkResolverService: LinkResolverService,
        private media: MediaService,
        private faviconService: FaviconService,
        private brandService: BrandService,
        private muloBrandService: MuloBrandService,
        private _analytics: GoogleAnalyticsService,
        private extendedConfigurationService: ExtendedConfigurationService,
        private otpSvc: OnetrustPendoService,
    ) {
        this.institutionCode = UrlUtils.getParam('institution');
        this.institutionName =
            configurationHandlerService.getMainInstitutionName();
        i18nService.isLangRtl$.subscribe((isRtl) => {
            this.direction = isRtl ? 'rtl' : 'ltr';
        });
    }

    get isLoginRoute() {
        return (
            this.routerService.isLoginRoute() ||
            this.routerService.isLoginAsRoute() ||
            this.routerService.isTombstoneRoute()
        );
    }

    ngOnInit() {
        this._analytics.init();
        this._analytics.trackPageViews().subscribe();
        const theme =
            this.configurationHandlerService.getCustomizationThemeing();
        if (theme && theme.colors && Object.entries(theme.colors).length > 0) {
            this.setInstitutionTheme();
        }
        if (theme?.['fontFamily']) {
            this.materialCssVarsService.addStyleToHead(
                `:root {--custom-font-family: ${theme['fontFamily']};}`,
                'font',
            );
        }

        this.handleAppLanguages();
        this.loadDepositConfiguration();
        this.initMediaObserver();
        this.setLogoTheme();
        this.setLogoUrl();
        this.setFaviconUrl();
        this.enrichConfiguration();
        // If accessing from Rendertron, skip OneTrust
        if (window?.location.hostname !== '127.0.0.1') {
            this.otpSvc.initPendo();
            this.otpSvc.initOnetrust();
        }
    }

    handleAppLanguages() {
        const language = this.getInterfaceLanguage();
        this.i18nService.setDefaultLanguage(this.getDefaultDisplayLanguage());
        this.i18nService.setLanguage(language);
    }

    enrichConfiguration() {
        this.extendedConfigurationService
            .getExtendedConfiguration()
            .subscribe((data) => {
                const config = data as Configuration;
                this.configurationHandlerService.enrichConfiguration(config);
            });
    }

    initMediaObserver() {
        // initialize media service that registers breakpoint media classes in the HTML tag
        this.media
            .observe()
            .pipe(
                takeUntil(componentDestroyed(this)),
                map((res) => res[0]),
            )
            .subscribe((res) => {
                this.breakpoint = res;
            });
    }

    loadDepositConfiguration() {
        this.getDepositConfiguration().subscribe((data) => {
            this.configurationHandlerService.depositConfig = data;
        });
    }

    getInterfaceLanguage() {
        let language = this.getDefaultDisplayLanguage();
        if (
            this.jwtUtilService.isLoggedIn() &&
            this.jwtUtilService.getLanguage()
        ) {
            language = this.jwtUtilService.getLanguage();
        } else if (
            !this.jwtUtilService.isLoggedIn() &&
            this.sessionStorageUtilService.getSessionLanguage()
        ) {
            language = this.sessionStorageUtilService.getSessionLanguage();
        }
        return language;
    }

    setInstitutionTheme() {
        const colors =
            this.configurationHandlerService.getCustomizationThemeing().colors;
        const elements =
            this.configurationHandlerService.getCustomizationThemeing()
                .elements;
        if (colors.primary) {
            this.sessionStorageUtilService.setItem('primary', colors.primary);
            this.materialCssVarsService.setPrimaryColor(colors.primary);
        }
        if (colors.accent) {
            this.sessionStorageUtilService.setItem('accent', colors.accent);
            this.materialCssVarsService.setAccentColor(colors.accent);
        }
        if (colors.secondary) {
            this.materialCssVarsService.setSecondaryColor(colors.secondary);
        }
        if (colors.tertiary) {
            this.materialCssVarsService.setTertiaryColor(colors.tertiary);
        }
        if (elements) {
            setTimeout(() => {
                this.materialCssVarsService.setElementColors(elements);
                this.setLogoTheme();
            }, 0);
        }
    }

    setLogoTheme() {
        const lightBgLogo = this.configurationHandlerService.getLightLogoImg();
        const darkBgLogo = this.configurationHandlerService.getDarkLogoImg();

        if (lightBgLogo) {
            // light background, dark contrast
            this.muloBrandService.setLogoDark(lightBgLogo);
        }
        if (darkBgLogo) {
            // dark background, light contrast
            this.muloBrandService.setLogoLight(darkBgLogo);
        }
    }

    onActivate(event) {
        this.animationState =
            this.activatedRoute.firstChild.snapshot.data['routeIdx'];
    }

    getDepositConfiguration() {
        return this.http.get(DepositForm.DEPOSIT_CONFIGURATION_URL);
    }

    setLogoUrl() {
        const logo = this.configurationHandlerService.getDefaultLogoUrl() || '';
        this.logoUrl = logo
            ? this.linkResolverService.getLinkUrl(logo)
            : this.getDefaultLogoUrl();
        this.brandService.setLogoPathUrl(this.logoUrl);
    }

    getDefaultLogoUrl() {
        let base = window.location.origin + '/esploro';
        const instQueryParam =
            '?institution=' + UrlUtils.getParam('institution');
        if (
            this.configurationHandlerService.isCustomerParameterEnabled(
                'esploro_new_homepage_and_header',
            )
        ) {
            base += instQueryParam;
        } else {
            base += State.OUTPUTS_SEARCH + instQueryParam;
        }
        return base;
    }

    setFaviconUrl() {
        const faviconUrl = this.configurationHandlerService.getFaviconImg();
        if (faviconUrl) {
            this.faviconService.setFaviconImage(faviconUrl);
        }
    }

    private getDefaultDisplayLanguage(): string {
        return this.configurationHandlerService.getDefaultDisplayLanguage();
    }

    // get environment setting if Angular Material should be extended to allow modified styles of its components
    private materialExtend() {
        return environment.extendMaterial;
    }

    ngOnDestroy() {}
}
