<button mat-icon-button class="close-button" (click)="close()" [attr.aria-label]="data.dialogCancel">
  <mat-icon svgIcon="close" muloSvgViewbox></mat-icon>
</button>

<h1 mat-dialog-title #title>{{ dialogTitle }}</h1>
<div mat-dialog-content>
  <mulo-asset-exporter
    *ngIf="data.exportType === 'asset'"
    [labels]="data.labels"
    [inDialog]="true"
    [selectedAssets]="data.selectedAssets"
    [allAssets]="data.allAssets"
    [exportTargets]="data.exportTargets"
    [selectedTarget]="data.selectedTarget"
    [actionClick]="actionClicked"
    (loadingPreviewChange)="loadingPreview = $event"
    (actionsChange)="setActions($event)"
    [titleId]="title?.id"
    #exporter
  >
  </mulo-asset-exporter>
</div>
<div mat-dialog-actions>
  <ng-container *ngIf="targetActions$ | async as actions">
    <div class="actions-wrap" @heightInAnimation @heightOutAnimation *ngIf="actions.length">
      <ng-container *ngFor="let action of actions; index as i">
        <ng-container *ngIf="{ ad: action.disabled$ | async } as disabledObj">
          <button
            *ngIf="!action.url"
            mat-button
            color="primary"
            [disabled]="disabledObj.ad || loadingPreview"
            [class.is-large]="i === 0"
            (click)="onActionClick(action)"
          >
            {{ action.label }}
          </button>
          <a
            *ngIf="action.url"
            mat-button
            color="primary"
            class="has-icon has-icon-after"
            [disabled]="disabledObj.ad || loadingPreview"
            [href]="action.url"
            [class.is-large]="i === 0"
            target="_blank"
          >
            {{ action.label }}
            <mat-icon class="mat-icon-rtl-mirror" svgIcon="open-in-new" muloSvgViewbox></mat-icon>
          </a>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
