import {
  trigger,
  style,
  transition,
  animate,
  query,
  animateChild,
  group,
} from '@angular/animations';
import * as easings from './easings';

export const TabsHorizontalAnimation = trigger('tabsHorizontalAnimation', [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          insetBlockStart: 0,
          insetInlineStart: 0,
          width: '100%',
        }),
      ],
      { optional: true }
    ),
    query(
      ':enter',
      [
        style({
          opacity: 0,
          transform: 'translateX({{pixels}})',
        }),
      ],
      {
        optional: true,
        params: { pixels: '30px' },
      }
    ),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(
        ':leave',
        [
          animate(
            '0s ' + easings.OutExpo,
            style({
              opacity: 0,
              transform: 'translateX({{pixels}})',
            })
          ),
        ],
        {
          optional: true,
          params: { pixels: '30px' },
        }
      ),
      query(
        ':enter',
        [
          animate(
            '.5s ' + easings.OutExpo,
            style({
              opacity: 1,
              transform: 'translateX(0)',
            })
          ),
        ],
        { optional: true }
      ),
    ]),
  ]),
]);

export const TabsChildrenHorizontalAnimation = trigger(
  'tabsChildrenHorizontalAnimation',
  [
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            insetBlockStart: 0,
            insetInlineStart: 0,
            width: '100%',
          }),
        ],
        { optional: true }
      ),
      query(
        ':enter',
        [
          style({
            opacity: 0,
            position: 'absolute',
            insetBlockStart: 0,
            insetInlineStart: 0,
            transform: 'translate3d({{pixels}}, 0, 0)',
          }),
        ],
        {
          optional: true,
          // params: { pixels: '50px' }
        }
      ),
      query(':leave', animateChild(), { optional: true }),
      group([
        query(
          ':leave',
          [
            animate(
              '.2s ease',
              style({
                opacity: 0,
                // Use the distance input and reverse it to get the opposite flow on leave
                transform: 'translate3d(calc(-1 * ({{pixels}} /3)), 0, 0)',
              })
            ),
          ],
          {
            optional: true,
          }
        ),
        query(
          ':enter',
          [
            animate(
              '.3s ' + easings.OutQuint,
              style({
                opacity: 1,
                transform: 'translate3d(0, 0, 0)',
              })
            ),
            style({ position: 'relative' }),
          ],
          {
            optional: true,
          }
        ),
      ]),
      query(':enter', animateChild(), { optional: true }),
    ]),
  ]
);

export const TabsChildrenVerticalAnimation = trigger(
  'tabsChildrenVerticalAnimation',
  [
    transition('* <=> *', [
      style({ position: 'relative' }),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            insetBlockStart: 0,
            insetInlineStart: 0,
            width: '100%',
          }),
        ],
        { optional: true }
      ),
      query(
        ':enter',
        [
          style({
            opacity: 0,
            position: 'absolute',
            insetBlockStart: 0,
            insetInlineStart: 0,
            transform: 'translate3d(0, {{pixels}}, 0)',
          }),
        ],
        {
          optional: true,
          // params: { pixels: '50px' }
        }
      ),
      query(':leave', animateChild(), { optional: true }),
      group([
        query(
          ':leave',
          [
            animate(
              '.2s ease',
              style({
                opacity: 0,
                // Use the distance input and reverse it to get the opposite flow on leave
                transform: 'translate3d(0, calc(-1 * ({{pixels}} /3)), 0)',
              })
            ),
          ],
          {
            optional: true,
          }
        ),
        query(
          ':enter',
          [
            animate(
              '.3s ' + easings.OutQuint,
              style({
                opacity: 1,
                transform: 'translate3d(0, 0, 0)',
              })
            ),
            style({ position: 'relative' }),
          ],
          { optional: true }
        ),
      ]),
      query(':enter', animateChild(), { optional: true }),
    ]),
  ]
);
