<div
  class="section-anchor"
  [attr.id]="underscoreStr(title)"
  [attr.aria-describedby]="title ? 'section-title-' + underscoreStr(title) : null"
></div>

<h2 *ngIf="imageFullWidth && title" id="{{ 'section-title-' + underscoreStr(title) }}" class="section-title">
  {{ title }}
</h2>
<img
  *ngIf="imageSrc"
  class="section-img"
  [class.img-on-right]="imageOnRight"
  [class.full-width]="imageFullWidth"
  [src]="imageSrc"
  [alt]="imageAlt"
/>
<div class="section-content" *ngIf="!imageFullWidth">
  <h2 *ngIf="title" id="{{ 'section-title-' + underscoreStr(title) }}" class="section-title">
    {{ title }}
  </h2>
  <ng-content></ng-content>
</div>
<div class="page-highlight-overlay" [class.is-active]="highlightActive$ | async">
  <div matRipple #ripple></div>
</div>
