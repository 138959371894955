<button
    *ngIf="data.actions.close"
    [attr.aria-label]="'research.aria.dialog.close.dialog' | translate"
    class="dialog-close-button"
    mat-dialog-close="cancel"
    mat-icon-button
>
    <mat-icon svgIcon="close"></mat-icon>
</button>
<span class="title-wrap">
    <button
        (click)="openDrawer()"
        *ngIf="viewInit && !drawerOpened"
        [attr.aria-label]="'research.aria.dialog.show.navigation.links' | translate"
        [exlFocus]="!drawerOpened"
        class="margin-right-xs heading-button"
        mat-icon-button
    >
        <mat-icon svgIcon="menu"></mat-icon>
    </button>
    <h1 mat-dialog-title>
        <span>{{ data.title }}</span>
    </h1>
</span>
<div *ngIf="data.body" mat-dialog-content>
    <exl-navigable-text
        (drawerClosed)="onDrawerClosed()"
        [drawerOpened]="drawerOpened"
        [html]="data.body"
    ></exl-navigable-text>
</div>
<div mat-dialog-actions>
    <button
        #discard
        *ngIf="data.actions.discard"
        [attr.cdkFocusInitial]="actionFocus === 'discard' || null"
        [class.is-large]="actionFocus === 'discard'"
        [color]="actionFocus === 'discard' ? accentColor : null"
        mat-button
        mat-dialog-close="discard"
    >
        {{ data.actions.discard }}
    </button>
    <span class="spacer"></span>
    <button
        #confirm
        *ngIf="data.actions.confirm"
        [attr.cdkFocusInitial]="actionFocus === 'confirm' || null"
        [class.is-large]="actionFocus === 'confirm'"
        [color]="actionFocus === 'confirm' ? accentColor : null"
        mat-button
        mat-dialog-close="ok"
    >
        {{ data.actions.confirm | customTranslate }}
    </button>
    <button
        #cancel
        *ngIf="data.actions.cancel"
        [attr.cdkFocusInitial]="actionFocus === 'cancel' || null"
        [class.is-large]="actionFocus === 'cancel'"
        [color]="actionFocus === 'cancel' ? accentColor : null"
        mat-button
        mat-dialog-close="cancel"
    >
        {{ data.actions.cancel | customTranslate }}
    </button>
</div>
