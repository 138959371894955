<div class="metadata-container">
    <div *ngIf="!showDescriptionButton || file.description" @growOutAnimation @slideInAnimation class="metadata-block">
        <mat-form-field [muloInputExpander]="file.description" class="hasnt-underline">
            <mat-label>{{ 'research.fdescription.title' | customTranslate }}</mat-label>
            <textarea
                #textarea
                (ngModelChange)="file.description = $event; onChangeDescription($event)"
                [ngModel]="file.description"
                [placeholder]="'research.fdescription.subtitle' | customTranslate"
                [rows]="1"
                matInput
                matTextareaAutosize
            >
            </textarea>
        </mat-form-field>
        <span class="actions">
            <button
                (click)="onRemoveDescription()"
                *ngIf="!showDescriptionButton || file.description"
                [attr.aria-label]="'research.aria.deposit.files.remove.file.description' | translate"
                class="is-small"
                mat-icon-button
                type="button"
            >
                <mat-icon svgIcon="close"></mat-icon>
            </button>
        </span>
    </div>
    <div *ngIf="file.license" @growOutAnimation @slideInAnimation class="metadata-block">
        <div class="inner">
            <mat-form-field class="hasnt-underline is-dummy-disabled has-dynamic-width">
                <mat-label>{{ 'research.files.file.license.title' | customTranslate }}</mat-label>
                <mat-select disabled value="0">
                    <mat-option value="0">{{ file.license + '.name' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <span class="actions">
                <button
                    (click)="onAddLicense()"
                    [attr.aria-label]="'research.aria.deposit.files.edit.license' | translate"
                    class="is-small"
                    mat-icon-button
                    type="button"
                >
                    <mat-icon svgIcon="pencil"></mat-icon>
                </button>
                <button
                    (click)="onRemoveLicense()"
                    [attr.aria-label]="'research.aria.deposit.files.remove.license' | translate"
                    class="is-small"
                    mat-icon-button
                    type="button"
                >
                    <mat-icon svgIcon="close"></mat-icon>
                </button>
            </span>
        </div>
    </div>
</div>
<div *ngIf="file.access && displayAccessRights" @growOutAnimation @slideInAnimation class="metadata-block">
    <div class="inner">
        <mat-form-field class="hasnt-underline is-dummy-disabled has-dynamic-width">
            <mat-label>{{ 'research.files.file.access.title' | customTranslate }}</mat-label>
            <mat-select disabled value="0">
                <mat-option value="0"
                    >{{ labelsPrefix + file.access + labelsPostfix | translate }}{{ getFixedDateString() }}</mat-option
                >
            </mat-select>
        </mat-form-field>
        <span class="actions">
            <button
                (click)="onEditFileAccess()"
                [attr.aria-label]="'research.aria.deposit.files.edit.access' | translate"
                class="is-small"
                mat-icon-button
                type="button"
            >
                <mat-icon svgIcon="pencil"></mat-icon>
            </button>
            <button
                (click)="onRemoveFileAccess()"
                [attr.aria-label]="'research.aria.deposit.files.remove.access' | translate"
                class="is-small"
                mat-icon-button
                type="button"
            >
                <mat-icon svgIcon="close"></mat-icon>
            </button>
        </span>
    </div>
</div>
<div *ngIf="!showTypeButton || displayType()" @growOutAnimation @slideInAnimation class="metadata-block">
    <div class="inner">
        <mat-form-field class="hasnt-underline is-dummy-disabled has-dynamic-width">
            <mat-label>{{ 'research.ftype.title' | customTranslate }}</mat-label>
            <mat-select #typeInput (ngModelChange)="file.type = $event; onChangeType($event)" [ngModel]="file.type">
                <mat-option *ngFor="let type of fileTypes" [value]="type">
                    {{ 'AssetFileAndLinkTypesLabels.' + type | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <span class="actions">
            <button
                (click)="onRemoveDocumentType()"
                [attr.aria-label]="'research.aria.deposit.files.remove.file.type' | translate"
                class="is-small"
                mat-icon-button
                type="button"
            >
                <mat-icon svgIcon="close"></mat-icon>
            </button>
        </span>
    </div>
</div>

<div *ngIf="showFooter" class="metadata-footer">
    <button
        (click)="onAddLicense()"
        *ngIf="displayLicense && showLicenseButton && !file.license"
        @growInAnimation
        class="has-icon"
        color="primary"
        mat-button
        type="button"
    >
        <mat-icon svgIcon="plus"></mat-icon>
        {{ 'research.filemd.license' | customTranslate }}
    </button>
    <button
        (click)="onAddDescription()"
        *ngIf="displayDescription && showDescriptionButton && !file.description"
        @growInAnimation
        class="has-icon"
        color="primary"
        mat-button
        type="button"
    >
        <mat-icon svgIcon="plus"></mat-icon>
        {{ 'research.filemd.description' | customTranslate }}
    </button>

    <button
        (click)="onSetFileAccess()"
        *ngIf="!file.access && displayAccessRights"
        @growInAnimation
        class="has-icon"
        color="primary"
        mat-button
        type="button"
    >
        <mat-icon svgIcon="plus"></mat-icon>
        {{ 'research.filemd.access' | customTranslate }}
    </button>
    <button
        (click)="onSetDocumentType()"
        *ngIf="displayContentType && showTypeButton && fileTypes.length > 0 && displaySetTypeButton()"
        @growInAnimation
        class="has-icon"
        color="primary"
        mat-button
        type="button"
    >
        <mat-icon svgIcon="plus"></mat-icon>
        {{ 'research.filemd.type' | customTranslate }}
    </button>
</div>
