import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
    name: 'errorTranslate',
})
export class ErrorsTranslatePipe
    extends TranslatePipe
    implements PipeTransform
{
    transform(errors: {}, ...args: any[]): string {
        if (!errors) return '';

        const keys = Object.keys(errors);
        return keys?.length ? this.getErrorLabel(keys[0], errors[keys[0]]) : '';
    }

    getErrorLabel(key: string, errorCode: any, ...args: any[]): string {
        const label = this.getStaticErrorLabel(key);
        return label
            ? super.transform(label, args)
            : super.transform(errorCode, args);
    }

    getStaticErrorLabel(key: string): string {
        switch (key) {
            case 'required':
                return 'research.output.list.error.message';
            case 'email':
                return 'research.settings.email.add.email.invalid';
            case 'maxlength':
                return 'research.portal.entity.field.error.limit.max';
            default:
                return '';
        }
    }
}
