import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
/*
The sessionStorage property accesses a session Storage object for the current origin. sessionStorage is similar to localStorage;
while data in localStorage doesn't expire, data in sessionStorage is cleared when the page session ends.
*/
export class SessionStorageUtilService {

    languageStorageKey = 'session_language';

    constructor(){
    }

    setObject(key: string, value: any){
        sessionStorage.setItem(key,JSON.stringify(value));
    }

    getObject(key: string){
        return JSON.parse(sessionStorage.getItem(key));
    }

    setItem(key: string, value: string){
        sessionStorage.setItem(key,value);
    }

    removeItem(key: string){
        sessionStorage.removeItem(key);
    }

    getItem(key: string){
        return sessionStorage.getItem(key);
    }

    clear(){
        sessionStorage.clear();
    }

    setSessionLanguage(language){
        this.setItem(this.languageStorageKey, language);
    }

    getSessionLanguage(){
        return this.getItem(this.languageStorageKey);
    }




}
