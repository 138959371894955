/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SystemDateFormatService {
  javaDateFormat: string;
  momentDateFormat: string;
  ngDateFormat: string;

  javaMomentFormatMapping = {
    d: 'D',
    dd: 'DD',
    y: 'YYYY',
    yy: 'YY',
    yyy: 'YYYY',
    yyyy: 'YYYY',
    M: 'M',
    MM: 'MM',
    MMM: 'MMM',
    MMMM: 'MMMM',
    D: 'DDD',
  };
  javaNgFormatMapping = {
    u: 'c',
    uu: 'cc',
    X: 'Z',
    XX: 'ZZ',
    XXX: 'ZZZ',
  };

  constructor() {}

  getSystemDateFormat() {
    return this.momentDateFormat;
  }

  //translates the java.text.SimpleDateFormat date format to the moment.js date format
  setSystemDateFormat(format) {
    this.javaDateFormat = format;
    this.momentDateFormat = this.toMomentFormatString(format);
    this.ngDateFormat = this.toNgFormatString(format);
  }

  //set moment local setting in order to overide mulo datepicker format
  setMomentLocale(lang: string = moment.locale()) {
    const ldf = (s) => moment.localeData().longDateFormat(s);

    // first, set the new language
    moment.locale(lang);

    // then update the language with LL set to system setting
    moment.updateLocale(lang, {
      postformat: (_) => _,
      longDateFormat: {
        LT: ldf('LT'),
        LTS: ldf('LTS'),
        L: ldf('L'),
        LL: this.getSystemDateFormat(),
        LLL: ldf('LLL'),
        LLLL: ldf('LLLL'),
      },
    });
  }

  toMomentFormatString(formatString) {
    // replacer function
    const f = (s) => formatString.replace(s, this.javaMomentFormatMapping[s]);

    if (formatString.includes('D')) {
      formatString = f('D');
    } else if (formatString.includes('dd')) {
      formatString = f('dd');
    } else if (formatString.includes('d')) {
      formatString = f('d');
    }
    if (formatString.includes('yyyy')) {
      formatString = f('yyyy');
    } else if (formatString.includes('yyy')) {
      formatString = f('yyy');
    } else if (formatString.includes('yyy')) {
      formatString = f('yyy');
    } else if (formatString.includes('yy')) {
      formatString = f('yy');
    } else if (formatString.includes('y')) {
      formatString = f('y');
    }
    return formatString;
  }

  toNgFormatString(formatString) {
    // replacer function
    const f = (s) => formatString.replace(s, this.javaNgFormatMapping[s]);

    if (formatString.includes('uu')) {
      formatString = f('uu');
    } else if (formatString.includes('u')) {
      formatString = f('u');
    }

    if (formatString.includes('XXX')) {
      formatString = f('XXX');
    } else if (formatString.includes('XX')) {
      formatString = f('XX');
    } else if (formatString.includes('X')) {
      formatString = f('X');
    }
    return formatString;
  }

  getDateLabelFromPartial(datePartial) {
    return !!datePartial
      ? moment(datePartial, 'YYYYMMDD').format(this.getSystemDateFormat())
      : '';
  }
}
