<mat-form-field
  [ngClass]="expanded ? 'is-expanded' : 'is-auto-expanding'"
  [class.is-autopopulated]="!autopopulateDisabled && autoPopulated"
  [muloInputExpander]="model"
  class="is-comfortably-readable"
>
  <mat-label *ngIf="inputLabel">{{ inputLabel }}</mat-label>
  <input
    matInput
    *ngIf="!multipleLines"
    [(ngModel)]="model"
    (ngModelChange)="onInputChange($event)"
    [placeholder]="placeholder"
    #inputElement
    [required]="required"
  />
  <textarea
    matInput
    *ngIf="multipleLines"
    class="hasnt-scrollbar"
    [(ngModel)]="model"
    (ngModelChange)="onInputChange($event)"
    [placeholder]="placeholder"
    cdkTextareaAutosize
    matAutosizeMinRows="1"
    (cdkAutofill)="onAutoFill($event)"
    #autosize="cdkTextareaAutosize"
    #inputElement
    [maxlength]="lengthLimit"
    [required]="required"
    [rows]="1"
  ></textarea>

  <mat-icon
    matPrefix
    *ngIf="!autopopulateDisabled && autoPopulated"
    svgIcon="auto-populate"
    [matTooltip]="autoPopulatedInfo"
  ></mat-icon>
  <button
    *ngIf="showClearButton && model"
    mat-icon-button
    matSuffix
    (click)="clearInput()"
    class="clear-button"
    [attr.aria-label]="clearButtonLabel"
  >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
  <mat-hint align="end" *ngIf="lengthLimit && model">{{ model.length }} / {{ lengthLimit }}</mat-hint>
  <mat-error *ngIf="!valid" [innerHTML]="errorMsgHtml"></mat-error>
</mat-form-field>
