// import { AnimationEntryMetadata} from '@angular/core'
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export const ScaleInOutAnimation = trigger('scaleInOut', [
  transition(':enter', [
    style({
      transform: 'scale(.8)',
      opacity: 0,
      height: 0,
    }),
    animate(
      '600ms cubic-bezier(0.23, 1, 0.32, 1)',
      style({
        transform: 'scale(1)',
        opacity: 1,
        height: '*',
      })
    ),
  ]),
  transition(':leave', [
    style({
      transform: 'scale(1)',
      height: '*',
      opacity: 1,
    }),
    animate(
      '450ms cubic-bezier(0.86, 0, 0.07, 1)',
      style({
        transform: 'scale(.7)',
        height: 0,
        opacity: 0,
      })
    ),
  ]),
]);
