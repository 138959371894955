<esp-deposit-form
    [@slideContent]="animationState"
    (@slideContent.start)="onAnimationStart($event)"
    (@slideContent.done)="onAnimationDone($event)"
    (closeEmitter)="onOverlayCloseAttempt($event)">
    <ng-container slot-deposit-form-overlay-close-button>
        <button mat-button class="close-button" (click)="onDialogCloseAttempt()" type="button"
                [disabled]="depositFormService.disableCloseDialogDuringFirstSave"
                attr.aria-label="{{'research.aria.deposit.deposit.form.close' | translate}}">
            <mat-icon svgIcon="close"></mat-icon>
        </button>
    </ng-container>

</esp-deposit-form>







