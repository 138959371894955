import {Component, Input, OnInit} from '@angular/core';
import {DepositFormDataService} from '../../deposit-form-data.service';
import {UntypedFormControl} from '@angular/forms';

@Component({
    selector: 'esp-deposit-dates',
    templateUrl: './deposit-dates.component.html',
    styleUrls: ['./deposit-dates.component.scss'],
})
export class DepositDatesComponent implements OnInit {

    @Input() textPlaceholder: string;
    @Input() range = false;
    @Input() error = false;
    @Input() dateHint = false;
    @Input() autoPopulated: boolean;
    @Input() hasTextInput = false;
    @Input() fromDate: UntypedFormControl;
    @Input() toDate: UntypedFormControl;

    constructor(public depositFormDataService: DepositFormDataService) { }

    ngOnInit() {
    }

}
