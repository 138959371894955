<mulo-context-frame
    (close)="handleFormClose()"
    [isOpen]="enterNewGrant"
    class="margin-top-sm"
    [closeBtnAriaLabel]="'research.aria.deposit.grants.add.close' | translate"
>
    <esp-new-grant *ngIf="enterNewGrant" [(allGrants)]="allGrants" [(enterNewGrant)]="enterNewGrant"> </esp-new-grant>

    <ng-container slot="closed-view">
        <esp-deposit-grants-search
            *ngIf="!enterNewGrant"
            #grantsSearch
            [showAddBtn]="true"
            (addBtnClick)="enterNewGrant = true"
            (grantSelect)="addGrant($event)"
        ></esp-deposit-grants-search>
    </ng-container>
</mulo-context-frame>
<mat-list *ngIf="depositFormDataService.grants.value.length" class="grants-list mulo-elevation-list" role="list">
    <ng-container
        *ngFor="let grant of depositFormDataService.grants.value; let i = index; first as isFirst; last as isLast"
    >
        <mat-list-item
            *ngIf="grant"
            @growInAnimation
            @growOutAnimation
            exlDescriptionA11y="grant-{{ i }}"
            role="listitem"
        >
            <mat-icon matListItemIcon svgIcon="trophy"></mat-icon>
            <span exlDescription matListItemTitle>
                <span *ngIf="grant.grantId" class="grant-name semibold"
                    >{{ grant.grantName }} ({{ grant.grantId }})</span
                >
                <span *ngIf="!grant.grantId && !grant.funderAgency" class="grant-name semibold">{{
                    grant.funderCode | defaultTranslate : { default: grant.funderName }
                }}</span>
                <span *ngIf="!grant.grantId && grant.funderAgency" class="grant-name semibold"
                    >{{ grant.funderCode | defaultTranslate : { default: grant.funderName } }}
                    ,
                    {{ grant.funderAgencyCode | defaultTranslate : { default: grant.funderAgency } }}</span
                >
            </span>
            <span *ngIf="grant.grantId && !grant.funderAgency" matListItemLine>
                <span>{{ grant.funderCode | defaultTranslate : { default: grant.funderName } }}</span>
            </span>
            <span *ngIf="grant.grantId && grant.funderAgency" matListItemLine>
                <span
                    >{{ grant.funderCode | defaultTranslate : { default: grant.funderName } }}
                    ,
                    {{ grant.funderAgencyCode | defaultTranslate : { default: grant.funderAgency } }}</span
                >
            </span>

            <div class="btn-wrap" matListItemMeta>
                <button
                    (click)="onGrantRemove(i)"
                    [attr.aria-label]="'research.aria.icon.button.remove' | translate"
                    mat-icon-button
                    type="button"
                >
                    <mat-icon svgIcon="close"></mat-icon>
                </button>
            </div>
        </mat-list-item>
    </ng-container>
</mat-list>
