import { Directive, NgModule } from '@angular/core';

@Directive({
    selector: '[muloPageLine], [mulo-page-line]',
    host: { class: 'mulo-page-line' },
    standalone: true,
})
export class PageLineDirective {}


