export default {
  dropzone: {
    multiple: 'Drop your files here or',
    single: 'Drop your file here or',
  },
  select: {
    multiple: 'Select files',
    single: 'Select file',
  },
  action: {
    multiple: "Drop 'em now!",
    single: 'Drop it now!',
  },
  limit: {
    fileDrop:
      'You can upload only one file for this type of asset. Please remove the existing file first.',
    typeChanged:
      'You can upload only one file for this type of asset. Please remove irrelevant files.',
    warningTitle: 'One file only please!',
    warningMsg: 'Multiple files are not allowed for this asset type.',
    warningConfirm: 'Got it',
  },
  confirmReplace:
    'This asset type allows only one file to be uploaded. If you confirm, the previous file will be replaced by this new file.',
  invalidFilesHeader: 'The following files could not be added',
};
