import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostBinding,
} from '@angular/core';

@Component({
    selector: 'mulo-header-nav',
    templateUrl: './header-nav.component.html',
    styleUrls: ['./header-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class HeaderNavComponent implements OnInit {
  @HostBinding('class') class = 'mulo-header-nav';

  constructor() {}

  ngOnInit(): void {}
}
