<button
    mat-raised-button
    (click)="onButtonClick()"
    class="has-icon"
    [ngClass]="{ 'is-rounded': rounded }"
    [disabled]="disabled"
>
    <mat-icon class="mat-icon-rtl-mirror" svgIcon="chevron-left"></mat-icon>
    <span>{{ 'research.top.back' | translate }}</span>
</button>
