import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'mulo-status-tag',
    templateUrl: './status-tag.component.html',
    styleUrls: ['./status-tag.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'mulo-status-tag',
        '[class.has-border]': 'flavor === "bordered"',
        '[class.is-plain]': 'flavor === "plain"',
    },
    standalone: true,
    imports: [
        MatTooltip,
        NgIf,
        MatIcon,
        SvgViewboxDirective,
    ],
})
export class StatusTagComponent {
  /**
   * Optional text to appear in a tooltip when hovering over the tag
   */
  @Input() tooltip?: string;
  /**
   * An optional icon to be used for the status tag
   */
  @Input() icon?: string;
  @Input() rtlMirrorIcon: boolean;
  /**
   * An optional description text to appear next to the tag
   */
  @Input() description?: string;
  /**
   * Style (flavor) of the rendered tag
   */
  @Input() flavor?: 'bordered' | 'plain' = 'bordered';

  constructor() {}
}
