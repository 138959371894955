import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { QuillModule } from 'ngx-quill';

import { RichTextEditorComponent } from './rich-text-editor.component';
import { RichTextA11yDirective } from './rich-text-a11y.directive';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        QuillModule.forRoot(),
        TranslateModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        RichTextEditorComponent, RichTextA11yDirective,
    ],
    exports: [RichTextEditorComponent, RichTextA11yDirective],
})
export class RichTextEditorModule {}
