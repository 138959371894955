<div class="item-wrap" role="group" [attr.aria-labelledby]="labelledBy">
  <span class="label-spacer margin-left-xs"></span>
  <mat-form-field>
    <mat-label>{{ fromLabel }}</mat-label>
    <input
      matInput
      type="number"
      [attr.aria-label]="fromAriaLabel"
      [formControl]="fromControl"
      (keyup.enter)="doSubmit()"
      [min]="initialValues.min"
      [max]="initialValues.to"
    />
  </mat-form-field>
  <mat-icon class="mat-icon-rtl-mirror" svgIcon="arrow-right" muloSvgViewbox></mat-icon>
  <mat-form-field *ngIf="to !== undefined">
    <mat-label>{{ toLabel }}</mat-label>
    <input
      matInput
      type="number"
      [attr.aria-label]="toAriaLabel"
      [formControl]="toControl"
      (keyup.enter)="doSubmit()"
      [min]="initialValues.from"
      [max]="initialValues.max"
    />
  </mat-form-field>
</div>
