import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';

@Injectable()
export class SnackbarService {

    snackbarStateChange: Subject<string> = new Subject<string>();
    snackbarState: string;
    _defaultDuration = 5000;

  constructor(private snackbar: MatSnackBar) { }

  show(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 7000,
      verticalPosition: 'top',
    });
  }

    showComponent(component, message?: string,
            duration?: number,
            verticalPosition?: 'top' | 'bottom',
            panelClass?: string): MatSnackBarRef<unknown> {
        const snackBarRef = this.snackbar
            .openFromComponent(component, {
                verticalPosition: verticalPosition ?? 'top',
                panelClass: panelClass ?? 'exl-snackbar-container',
                duration: duration ?? this._defaultDuration,
                data: {
                    message: message ? message : 'Success!'
                }
            });
            snackBarRef.afterOpened().subscribe(() => {
                this.snackbarState = 'opened';
                this.onStateChange();
            });
            snackBarRef.onAction().subscribe(() => {
                this.snackbarState = 'action';
                this.onStateChange();
            });
            snackBarRef.afterDismissed().subscribe(() => {
                this.snackbarState = 'closed';
                this.onStateChange();
            });
            return snackBarRef;
    }

    onStateChange() {
        this.snackbarStateChange.next(this.snackbarState);
    }

}
