<mat-form-field
    floatLabel="auto"
    class="is-comfortably-readable"
    [muloInputExpander]="depositFormDataService.topics.value | collectByField : 'description'"
>
    <mat-label>{{ 'research.topics.overline' | customTranslate }}</mat-label>
    <mat-chip-grid #chipGrid>
        <mat-chip-row
            cdkMonitorSubtreeFocus
            [attr.aria-label]="chip.description"
            *ngFor="
                let chip of depositFormDataService.topics.value;
                first as isFirst;
                trackBy: trackByTopic;
                let i = index
            "
            [removable]="removable"
            (removed)="removeChip(chip, i)"
            matTooltipPosition="above"
            disableRipple="true"
            @widthInAnimation
            @widthOutAnimation
        >
            <exl-badge-auto-populated
                *ngIf="isAutoPopulated(chip)"
                tooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
                color="primary"
            >
            </exl-badge-auto-populated>
            <span class="semibold chip-content">
                {{ chip.description }}
            </span>
            <button
                mat-icon-button
                matChipRemove
                *ngIf="removable"
                [attr.aria-label]="('research.aria.deposit.keywords.remove' | translate) + ' ' + chip.description"
            >
                <mat-icon svgIcon="close"></mat-icon>
            </button>
        </mat-chip-row>
    </mat-chip-grid>

    <input
        matInput
        [formControl]="autoCompleteChipList"
        #chipInput
        [matAutocomplete]="auto"
        (keyup.enter)="onInputEnterKey(chipInput)"
        [matChipInputFor]="chipGrid"
        [exlFocus]="jump"
    />
    <mat-autocomplete #auto="matAutocomplete">
        <mat-optgroup
            label="{{ 'research.topics.suggestions' | customTranslate }}"
            *ngIf="filteredSuggestions.length > 0"
        >
            <mat-option
                *ngFor="let suggestion of filteredSuggestions"
                [value]="suggestion.description"
                (onSelectionChange)="addSuggestionChip(suggestion, chipInput)"
            >
                <div class="autocomplete-option-multiline">
                    <span
                        class="semibold"
                        [innerHTML]="suggestion.description | highlight : autoCompleteChipList.value"
                    ></span>
                </div>
            </mat-option>
        </mat-optgroup>
        <mat-option
            value="autoCompleteChipList.value"
            *ngIf="filteredSuggestions.length == 0 && chipInput.value.length > 0"
            (onSelectionChange)="onInputEnterKey(chipInput)"
        >
            {{ 'research.topics.add.new.topic' | customTranslate }} "<span class="bold">{{
                autoCompleteChipList.value
            }}</span
            >"
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
