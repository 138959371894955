<nav mat-tab-nav-bar [mat-stretch-tabs]="false">
  <ng-container *ngFor="let crumb of breadcrumbs; index as i; trackBy: trackByFn">
    <div *ngIf="crumb?.children?.length > 1" mat-tab-link [active]="getCrumbActive(i)">
      <button mat-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{ crumb: crumb, i: i }">
        <span class="crumb-btn-content">
          {{ breadcrumbs[i + 1]?.name === 'all' ? crumb.allLabel || allLabel : breadcrumbs[i + 1]?.label }}
          <mat-icon svgIcon="menu-down"></mat-icon>
        </span>
      </button>
    </div>
  </ng-container>
</nav>
<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-crumb="crumb" let-i="i">
    <button mat-menu-item *ngIf="crumb?.name !== 'root'" (click)="onChange(allLink, i)">
      {{ crumb.allLabel || allLabel }}
    </button>
    <button mat-menu-item *ngFor="let option of crumb.children" (click)="onChange(option, i)">
      {{ option.label }}
    </button>
  </ng-template>
</mat-menu>
