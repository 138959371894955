<mulo-prologue>
    <mulo-centered-block-layout>
        <h1 [ngSwitch]="indexKey">
            <ng-container *ngSwitchCase="'researchersIndex'">
                <mat-icon svgIcon="account" muloSvgViewbox></mat-icon>
                <span>{{ 'esploro.research.conf.research.portal.label.prefix.profileLink' | translate }}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'assetsIndex'">
                <mat-icon class="mat-icon-rtl-mirror" svgIcon="asset-thumbnail-sm" muloSvgViewbox></mat-icon>
                <span>{{ 'esploro.research.conf.research.portal.label.prefix.portalLink' | translate }}</span>
            </ng-container>
        </h1>
    </mulo-centered-block-layout>
</mulo-prologue>
<mulo-loading-slate *ngIf="loading"></mulo-loading-slate>
<mulo-centered-block-layout>
    <nav>
        <ol>
            <ng-container *ngFor="let section of indexData | keyvalue">
                <li *ngIf="section.key !== 'OTHER'">
                    <a
                        *ngIf="section.value.length > 0; else textOnly"
                        [routerLink]="['../', section.key]"
                        queryParamsHandling="preserve"
                        >{{ section.key }}</a
                    >
                    <ng-template #textOnly>
                        <span>{{ section.key }}</span>
                    </ng-template>
                </li>
            </ng-container>
            <li *ngIf="indexData?.['OTHER'] && indexData?.['OTHER'].length > 0">
                <a routerLink="../OTHER" queryParamsHandling="preserve">?</a>
            </li>
        </ol>
    </nav>
    <div class="back-to-top" muloSticky (muloStickyStuck)="bttStuck = $event" [class.is-stuck]="bttStuck">
        <a
            *ngIf="bttStuck"
            @opacityInAnimation
            @opacityOutAnimation
            routerLink="."
            queryParamsHandling="preserve"
            mat-fab
            extended
            class="has-shadow"
        >
            <mat-icon svgIcon="arrow-up"></mat-icon>
            {{ 'research.portal.fulldisplay.backToTopButton' | translate }}
        </a>
    </div>
    <main>
        <ng-container *ngIf="indexData?.[indexIdx] as section">
            <h2 [id]="indexIdx">{{ indexIdx }}</h2>
            <ol class="index-list">
                <li *ngFor="let item of section">
                    <ng-container *ngIf="item.split(delimiter) as nameUrlPair">
                        <a class="hasnt-color" [href]="nameUrlPair[1]">{{ nameUrlPair[0] }}</a>
                    </ng-container>
                </li>
            </ol>
        </ng-container>
    </main>
</mulo-centered-block-layout>
