<mulo-horizontaller
  [buttonWidth]="16"
  [minHeight]="80"
  [placeholderWidth]="160"
  [placeholders]="images?.length"
  [rtl]="rtl"
  verticalAlign="center"
>
  <mulo-horizontaller-item *ngFor="let img of images; let i = index" [minWidth]="0" class="margin-right-sm">
    <figure>
      <button (click)="openDialog(i)" [attr.aria-expanded]="currentFullImgIdx === i" class="image-btn">
        <img (load)="imgLoaded(img.src)" [alt]="img.alt || img.caption" [src]="img.src" />
      </button>
      <figcaption *ngIf="img.caption">{{ img.caption }}</figcaption>
    </figure>
  </mulo-horizontaller-item>
</mulo-horizontaller>
<ng-template #dialogTmpl>
  <button [aria-label]="closeBtnAriaLabel" class="is-large close-button" mat-dialog-close mat-icon-button>
    <mat-icon muloSvgViewbox svgIcon="close"></mat-icon>
  </button>

  <button
    (click)="currentFullImgIdx = currentFullImgIdx - 1"
    [attr.aria-label]="scrollLeftBtnAriaLabel"
    [disabled]="currentFullImgIdx === 0"
    class="is-large scroll-button scroll-button-left"
    mat-icon-button
  >
    <mat-icon class="mat-icon-rtl-mirror" muloSvgViewbox svgIcon="chevron-left"></mat-icon>
  </button>
  <figure *ngIf="images[currentFullImgIdx] as img">
    <img [alt]="img.alt || img.caption" [src]="img.src" />
    <figcaption *ngIf="img.caption" @heightInAnimation @heightOutAnimation>
      <span>{{ img.caption }}</span>
    </figcaption>
  </figure>
  <button
    (click)="currentFullImgIdx = currentFullImgIdx + 1"
    [attr.aria-label]="scrollRightBtnAriaLabel"
    [disabled]="currentFullImgIdx === images.length - 1"
    class="is-large scroll-button scroll-button-right"
    mat-icon-button
  >
    <mat-icon class="mat-icon-rtl-mirror" muloSvgViewbox svgIcon="chevron-right"></mat-icon>
  </button>
</ng-template>
