import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { GrowInAnimation } from '@exl-ng/mulo-common';
import { JwtUtilService } from '../../core/jwt-util.service';
import { LogoutService } from '../../logout/logout.service';
import { LoginAsService } from './login-as.service';
import { ResearcherProxyFor } from '../../shared/interfaces/researcher-proxy-for.interface';
import { ResearcherDataService } from '../../core/researcher-data.service';
import { ConfigurationHandlerService } from '../../core/configuration-handler.service';
import { UrlUtils } from '../../shared/utils/url.utils';
import { StorageUtilService } from '../../core/storage-util.service';
import { SEOService } from '../../core/seo.service';
import { AffiliationService } from '../../settings/sections/settings-affiliations/affiliation.service';
import { ProfileService } from '../../profile/profile.service';
import { OnetrustPendoService } from '../../core/onetrust-pendo.service';

@Component({
    selector: 'esp-login-as',
    templateUrl: './login-as.component.html',
    styleUrls: ['./login-as.component.scss'],
    animations: [GrowInAnimation],
})
export class LoginAsComponent implements OnInit {
    accessToResearcherCenter: boolean;
    proxyFor: string;
    signInName: string;
    researchersPhotos = {};
    researchersProxyFor: ResearcherProxyFor[];

    mainInstCode: string;
    institutionCode: string;

    constructor(
        private jwtUtilService: JwtUtilService,
        private logoutService: LogoutService,
        private loginAsService: LoginAsService,
        private researcherDataService: ResearcherDataService,
        private profileSvc: ProfileService,
        private configurationHandlerService: ConfigurationHandlerService,
        private router: Router,
        private storageUtilService: StorageUtilService,
        private SEOService: SEOService,
        private translate: TranslateService,
        public affiliationService: AffiliationService,
        private otpSvc: OnetrustPendoService,
    ) {}

    ngOnInit() {
        this.changeDocumentTitle();
        this.institutionCode = UrlUtils.getParam('institution');
        this.mainInstCode =
            this.configurationHandlerService.getMainInstitutionCode();
        this.accessToResearcherCenter =
            this.jwtUtilService.getAccessToResearcherCenter();
        this.proxyFor = this.jwtUtilService.getProxyFor();
        this.signInName = this.jwtUtilService.getUserDisplayName(true);
        this.loginAsService.getProxiesFor(this.proxyFor).subscribe((data) => {
            this.researchersProxyFor = data as ResearcherProxyFor[];
            this.getCorrespondingProfilesImages();
        });
    }

    changeDocumentTitle() {
        this.translate
            .get('research.login.as.document.title', {
                value0: this.jwtUtilService.getUserDisplayName(true),
                value1: this.configurationHandlerService.getMainInstitutionName(),
            })
            .subscribe((res) => {
                this.SEOService.updateTitle(res);
            });
    }

    getCorrespondingProfilesImages() {
        this.researchersProxyFor.forEach((user) => {
            const userId = user.userID;
            this.researcherDataService
                .getResearcherProfileImage(userId)
                .subscribe((imgBlob) => {
                    if (imgBlob) {
                        this.researchersPhotos[userId] =
                            window.URL.createObjectURL(imgBlob);
                    } else {
                        this.profileSvc
                            .getResearcherDataForSEO(userId)
                            .subscribe((data) => {
                                this.researchersPhotos[userId] =
                                    data.researcherImageUrl ?? '';
                            });
                    }
                });
        });
    }

    signOut() {
        this.logoutService.logout();
    }

    loginToOwnDahboard() {
        this.storageUtilService.removeItem('current_user');
        this.otpSvc.updateLoggedIn();
        this.goToProfile();
    }

    onPersonClick(researcher: ResearcherProxyFor) {
        this.storageUtilService.setObject('current_user', researcher);
        this.otpSvc.updateLoggedIn();
        this.goToProfile();
    }

    goToProfile() {
        this.router.navigate(['/profile'], {
            queryParams: { institution: this.institutionCode },
        });
    }

    getInitial(researcher: ResearcherProxyFor) {
        let initials = researcher.lastName.charAt(0).toUpperCase();
        if (researcher.firstName) {
            initials = researcher.firstName.charAt(0).toUpperCase() + initials;
        }
        return initials;
    }

    showTitleOrPosition(researcher: any) {
        return (
            (researcher.currentAffiliation &&
                (researcher.currentAffiliation.title ||
                    researcher.currentAffiliation.position)) ||
            researcher.position
        );
    }
}
