import {Injectable} from '@angular/core';

function _window(): any {
    return window;
}

@Injectable({
    providedIn: 'root'
})
export class WindowRefService {

    constructor(){}

    get nativeWindow(): any {
        return _window();
    }

    getOrigin(): string {
        return this.nativeWindow.location.origin;
    }

}
