import { Injectable } from '@angular/core';
import { BehaviorSubject, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  private _logoUrls = new BehaviorSubject<{ light: string; dark: string }>({
    light: null,
    dark: null,
  });

  public logoUrls$ = this._logoUrls.asObservable().pipe(shareReplay());

  constructor() {}

  setLogo(input: string | { light?: string; dark?: string }) {
    if (typeof input === 'string') {
      this._logoUrls.next({ light: input, dark: input });
    } else {
      this._logoUrls.next({ ...this._logoUrls.getValue(), ...input });
    }
  }

  setLogoLight(url: string) {
    this._logoUrls.next({ ...this._logoUrls.getValue(), light: url });
  }
  setLogoDark(url: string) {
    this._logoUrls.next({ ...this._logoUrls.getValue(), dark: url });
  }
}
