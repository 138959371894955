import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'mulo-floating-toolbar-buttons',
    templateUrl: './floating-toolbar-buttons.component.html',
    styleUrls: ['./floating-toolbar-buttons.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'mulo-floating-toolbar-buttons' },
    standalone: true,
})
export class FloatingToolbarButtonsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
