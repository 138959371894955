import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {LogoutDirective} from './logout.directive';

@NgModule({
    imports: [
        MatButtonModule
    ],
    declarations: [
        LogoutDirective
    ],
    exports: [
        LogoutDirective
    ],
    providers: [
    ]
})
export class LogoutModule { }
