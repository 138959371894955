import { Component, OnInit } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgxPaginationModule, PaginationInstance } from 'ngx-pagination';

import { MediaService } from '@exl-ng/mulo-core';
import {
    ExpandableComponent,
    FloatingToolbarButtonsComponent,
    FloatingToolbarComponent,
    FloatingToolbarSeparatorComponent,
    HeightInAnimation,
    HeightOutAnimation,
    OpacityInAnimation,
    OpacityOutAnimation,
    PagingComponent,
    PrologueComponent,
    StickyDirective,
    ThumbnailComponent,
    WidthInAnimation,
    WidthOutAnimation,
} from '@exl-ng/mulo-common';
import {
    BriefComponent,
    CenteredBlockLayoutComponent,
} from '@exl-ng/mulo-views';

import { SharedModule } from '../../shared/shared.module';
import { Discovery } from '../../shared/configurations/discovery.constant';
import { UrlUtils } from '../../shared/utils/url.utils';
import { Collection } from '../../shared/interfaces/collection.interface';
import { SEOService } from '../../core/seo.service';

import { CollectionsService } from '../collections.service';

@Component({
    selector: 'esp-collections-browse-page',
    templateUrl: './collections-browse-page.component.html',
    styleUrls: ['./collections-browse-page.component.scss'],
    animations: [
        HeightInAnimation,
        HeightOutAnimation,
        WidthInAnimation,
        WidthOutAnimation,
        OpacityInAnimation,
        OpacityOutAnimation,
    ],
    standalone: true,
    imports: [
        CommonModule,
        PrologueComponent,
        StickyDirective,
        FloatingToolbarComponent,
        FloatingToolbarButtonsComponent,
        FloatingToolbarSeparatorComponent,
        PagingComponent,
        ExpandableComponent,
        CenteredBlockLayoutComponent,
        BriefComponent,
        NgxPaginationModule,
        SharedModule,
        NgIf,
        ThumbnailComponent,
    ],
})
export class CollectionsBrowsePageComponent implements OnInit {
    institution = UrlUtils.getParam('institution');

    paginationConfig: PaginationInstance = {
        id: 'clientPaging',
        itemsPerPage: Discovery.ASSETS_BULK_SIZE,
        currentPage: 1,
    };

    featuredPaginationConfig: PaginationInstance = {
        id: 'featuredPaging',
        itemsPerPage: Discovery.ASSETS_BULK_SIZE,
        currentPage: 1,
    };

    searchInProgress = false;
    toolbarScrolled;
    toolbarStuck = false;
    toolbarSticky = false;

    collections: Collection[] = [];
    featuredCollections: Collection[] = [];
    pageCount: number;

    constructor(
        public media: MediaService,
        private collectionsService: CollectionsService,
        private seoService: SEOService,
        private translate: TranslateService,
    ) {}

    ngOnInit() {
        this.collectionsService.getActiveCollections().subscribe((data) => {
            this.collections = data;
            this.pageCount = Math.ceil(
                this.collections.length / this.paginationConfig.itemsPerPage,
            );
        });
        this.collectionsService.getFeaturedCollections().subscribe((data) => {
            this.featuredCollections = data;
        });
        this.updateTitle();
    }

    updateTitle() {
        this.translate.get('research.search.collections').subscribe((title) => {
            this.seoService.updatePageTitle(title);
        });
    }

    pageChange(event) {
        this.paginationConfig.currentPage = event;
        this.scrollToTop();
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    get currentPageStart() {
        const lastPageEnd =
            (this.paginationConfig.currentPage - 1) *
            this.paginationConfig.itemsPerPage;
        return lastPageEnd + 1;
    }
    get currentPageEnd() {
        return Math.min(
            this.collections.length,
            this.paginationConfig.currentPage *
                this.paginationConfig.itemsPerPage,
        );
    }
}
