<div
    class="guide-text-anchor"
    [matMenuTriggerFor]="guideText"
    #guideTextTrigger="matMenuTrigger"
    [style.margin-left]="offsetTextPosition"
></div>
<mat-menu
    #guideText="matMenu"
    class="exl-common-onboarding-text-panel app-theme-{{ color }}"
    (closed)="onGuideTextClosed()"
    backdropClass="app-theme-{{ color }}"
>
    <div class="onboarding-guide-content" [style.text-align]="align">
        <div [innerHTML]="text | translate"></div>
        <button mat-stroked-button class="is-large onboarding-guide-button">
            {{ 'research.header.proxy.menu.message.go.it' | translate }}
        </button>
    </div>
</mat-menu>
