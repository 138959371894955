import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ElementRef,
  NgZone,
  HostBinding,
} from '@angular/core';
import { PortionListService } from '../portion-list.service';
import { WidthOutAnimation } from '../../../animations/width.animation';
import { MatListItem } from '@angular/material/list';
import { Platform } from '@angular/cdk/platform';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatSuffix } from '@angular/material/form-field';

@Component({
    selector: 'mulo-portion-list-item',
    templateUrl: './portion-list-item.component.html',
    styleUrls: ['./portion-list-item.component.scss'],
    animations: [WidthOutAnimation],
    standalone: true,
    imports: [
        MatFormField,
        MatInput,
        FormsModule,
        MatSuffix,
        NgIf,
        MatSlider,
        MatSliderThumb,
        MatIconButton,
        MatIcon,
    ],
})
export class PortionListItemComponent extends MatListItem implements OnChanges {
  @Input() hasSlider = true;
  @Input() unit = '%';
  @Input() max = 100;
  @Input() min = 0;
  @Input() item;
  @Input() pliDisabled = true;
  @Input() thumbLabel = true;
  @Input() id: number;
  @Input() removeBtnAriaLabel = 'Remove';
  @Input() portionInputAriaLabel = 'Portion percentage';
  @Input() portionSliderAriaLabel = 'Portion percentage slider';
  @HostBinding('role') @Input() ariaRole = 'listitem';

  @Output() itemChange: EventEmitter<number> = new EventEmitter();
  @Output() changeEnd: EventEmitter<number> = new EventEmitter();
  @Output() remove: EventEmitter<null> = new EventEmitter();
  roundedPortion = 0;

  constructor(
    public service: PortionListService,
    _element: ElementRef<HTMLElement>,
    _ngZone: NgZone,
    _platform: Platform
  ) {
    super(_element, _ngZone, null, _platform);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item) {
      this.roundedPortion = Math.floor(this.item.portion);
    }
  }

  sliderChange(event) {
    this.service.sliderChanges(this.id, event.value);
    this.changeEnd.emit(event.value);
  }

  onInputChange(event) {
    if (event > 100) {
      event = 100;
    }
    this.changeEnd.emit(event);
  }

  removeField() {
    this.remove.emit(null);
  }
}
