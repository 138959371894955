<button
    mat-icon-button
    *ngIf="data?.actions?.close"
    class="dialog-close-button"
    [mat-dialog-close]="'cancel'"
    aria-label="{{ 'research.aria.dialog.close.dialog' | translate }}"
>
    <mat-icon svgIcon="close"></mat-icon>
</button>
<h2 mat-dialog-title cdkFocusInitial>
    <ng-container *ngIf="!useCustomPipe">{{ data?.title | translate : titleParam }}</ng-container>
    <ng-container *ngIf="useCustomPipe">{{ data?.title | customTranslate }}</ng-container>
</h2>

<div mat-dialog-content *ngIf="data?.message">
    <ng-container *ngIf="!useCustomPipe">{{ data?.message | translate }}</ng-container>
    <ng-container *ngIf="useCustomPipe">{{ data?.message | customTranslate : messageParam }}</ng-container>
</div>

<div mat-dialog-actions>
    <button
        mat-button
        #discard
        *ngIf="data?.actions?.discard"
        [mat-dialog-close]="'discard'"
        [color]="actionFocus === 'discard' ? accentColor : null"
        [class.is-large]="actionFocus === 'discard'"
    >
        <ng-container *ngIf="!useCustomPipe">{{ data?.actions?.discard | translate }}</ng-container>
        <ng-container *ngIf="useCustomPipe">{{ data?.actions?.discard | customTranslate }}</ng-container>
    </button>
    <span class="spacer"></span>
    <button
        mat-button
        #cancel
        *ngIf="data?.actions?.cancel"
        [mat-dialog-close]="'cancel'"
        [color]="actionFocus === 'cancel' ? accentColor : null"
        [class.is-large]="actionFocus === 'cancel'"
    >
        <ng-container *ngIf="!useCustomPipe">{{ data?.actions?.cancel | translate }}</ng-container>
        <ng-container *ngIf="useCustomPipe">{{ data?.actions?.cancel | customTranslate }}</ng-container>
    </button>
    <button
        mat-button
        #confirm
        *ngIf="data?.actions?.confirm"
        [mat-dialog-close]="'ok'"
        [color]="actionFocus === 'confirm' ? accentColor : null"
        [class.is-large]="actionFocus === 'confirm'"
    >
        <ng-container *ngIf="!useCustomPipe">{{ data?.actions?.confirm | translate }}</ng-container>
        <ng-container *ngIf="useCustomPipe">{{ data?.actions?.confirm | customTranslate }}</ng-container>
    </button>
</div>
