<button
  *ngIf="!_disableScrollAfter || !_disableScrollBefore"
  type="button"
  mat-icon-button
  class="scroll-button"
  (click)="scroll($event, 'left')"
  [attr.aria-label]="scrollLeftBtnAriaLabel"
  [disabled]="!canAdd || _disableScrollBefore"
>
  <mat-icon class="mat-icon-rtl-mirror" svgIcon="chevron-left"></mat-icon>
</button>
<mat-button-toggle-group
  #scrollerWrapper
  [value]="selectedButton"
  name="languages"
  [attr.aria-label]="buttonGroupLabel"
  [hideSingleSelectionIndicator]="true"
>
  <mat-button-toggle
    *ngFor="let item of buttonsKeys; let i = index"
    (change)="selectedButtonChanged.emit(item)"
    [value]="item"
    [aria-label]="buttonsKeysAriaLabels?.[i]"
    [disabled]="!canAdd"
    >{{ item }}</mat-button-toggle
  >
</mat-button-toggle-group>
<button
  *ngIf="!_disableScrollAfter || !_disableScrollBefore"
  type="button"
  mat-icon-button
  class="scroll-button"
  (click)="scroll($event, 'right')"
  [attr.aria-label]="scrollRightBtnAriaLabel"
  [disabled]="!canAdd || _disableScrollAfter"
>
  <mat-icon class="mat-icon-rtl-mirror" svgIcon="chevron-right"></mat-icon>
</button>
