<form #formDirective="ngForm" (ngSubmit)="handleSubmit()" [formGroup]="person">
    <div class="fields-wrap">
        <mat-form-field>
            <input formControlName="name" matInput />
            <mat-label>{{ 'esploro.research.conf.research.portal.label.prefix.author' | translate }}</mat-label>
        </mat-form-field>
        <esp-multiple-affiliations
            [label]="'research.settings.section.affiliations' | translate"
            [orgCodeListCtrl]="person?.get('affiliationCodeList')"
            [orgNameListCtrl]="person?.get('affiliationNameList')"
        ></esp-multiple-affiliations>
    </div>
    <div class="actions">
        <button [disabled]="!person.valid" mat-raised-button type="submit">
            {{ 'research.settings.affiliation.save' | translate }}
        </button>
    </div>
</form>
