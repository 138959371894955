<button
  mat-icon-button
  class="close-button"
  (click)="close()"
  [attr.aria-label]="data.dialogCancel"
>
  <mat-icon svgIcon="close" muloSvgViewbox></mat-icon>
</button>

<h1 mat-dialog-title>{{data.dialogTitle || dialogTitle}}</h1>
<div mat-dialog-content>
  <mulo-sharer
    [inDialog]="true"
    [labels]="data.labels"
    [shareTargets]="data.shareTargets"
    [shareData]="data.shareData"
  >
  </mulo-sharer>
</div>
