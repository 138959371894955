import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  InjectionToken,
  Input,
  NgZone,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatListItem } from '@angular/material/list';
import { ActionListItemActionComponent } from './action-list-item-action/action-list-item-action.component';
import { Platform } from '@angular/cdk/platform';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MediaIfDirective } from '@exl-ng/mulo-core';
import {
  MatMenu,
  MatMenuContent,
  MatMenuTrigger,
} from '@angular/material/menu';
import { CdkObserveContent } from '@angular/cdk/observers';
import { StatusTagComponent } from '../status-tag/status-tag.component';
import { NgIf, NgTemplateOutlet } from '@angular/common';

/**
 * An interface and InjectionToken to prevent Circular dependency of ActionListItemComponent and ActionListItemActionComponent
 * Becuase parent component ActionListItemComponent hold contentChildren of child ActionListItemActionComponent
 * and ActionListItemActionComponent inject ActionListItemComponent
 */
export interface MuloActionListItem {}

export const MULO_ACTION_LIST_ITEM = new InjectionToken<MuloActionListItem>(
  'MULO_ACTION_LIST_ITEM',
);

@Component({
  selector: 'mulo-action-list-item',
  templateUrl: './action-list-item.component.html',
  styleUrls: ['./action-list-item.component.scss'],
  host: {
    '[class.is-hidden]': 'hidden',
    '[class.is-hover]': 'showOnHover',
  },
  providers: [
    {
      provide: MULO_ACTION_LIST_ITEM,
      useExisting: ActionListItemComponent,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    StatusTagComponent,
    CdkObserveContent,
    MatMenuContent,
    MediaIfDirective,
    NgTemplateOutlet,
    MatIconButton,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
  ],
})
export class ActionListItemComponent
  extends MatListItem
  implements MuloActionListItem, AfterContentInit, OnChanges
{
  @Input() hidden = false;
  @Input() hiddenLabel = 'Hidden';
  @Input() hiddenDescription = '';
  @Input() showOnHover = false;
  @Input() moreBtnLabel = 'More Options';
  @ContentChildren(ActionListItemActionComponent)
  actions: QueryList<ActionListItemActionComponent>;

  @ViewChild('unscopedContent') _unscopedContent: ElementRef<HTMLSpanElement>;
  updateItemLines = super._updateItemLines;

  constructor(
    _element: ElementRef<HTMLElement>,
    _ngZone: NgZone,
    _platform: Platform,
  ) {
    super(_element, _ngZone, null, _platform);
  }

  ngAfterContentInit() {
    // Disable the item if all action buttons disabled
    this.disabled =
      this.disabled ||
      (this.actions?.length > 0 &&
        !this.actions.some((action) => !action.disabled));
    this.recalcLinesForHidden();
    this._lines.changes.subscribe(() => this.recalcLinesForHidden());
    this._titles.changes.subscribe(() => this.recalcLinesForHidden());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['hidden'] && !changes['hidden'].firstChange) {
      this.recalcLinesForHidden();
    }
  }

  recalcLinesForHidden() {
    if (this.hidden === true) {
      this.lines = this.inferLinesFromContent() + 1;
    } else {
      this.lines = this.inferLinesFromContent();
    }
  }

  private inferLinesFromContent() {
    let numOfLines = this._titles.length + this._lines.length;
    if (this._hasUnscopedTextContent) {
      numOfLines += 1;
    }
    return numOfLines;
  }
}
