<div
    exlFileUpload
    (dragOverEmitter)="onDragOver($event)"
    (filesDroppedEmitter)="onFilesDropped($event)"
    [ngClass]="{ 'is-minimal': minimal, 'is-hidden': hidden }"
    [multiple]="multiple"
>
    <div class="inner">
        <ng-container *ngIf="!dragOverActive">
            <div class="label">
                <ng-container *ngIf="multiple">{{ 'research.files.loader' | customTranslate }}</ng-container>
            </div>
            <button
                mat-raised-button
                color="primary"
                class="has-icon has-file-input is-large"
                (click)="fileRequester.click()"
                type="button"
                role="button"
            >
                <span class="button-content-wrapper">
                    <span>{{ 'research.files.button' | customTranslate }}</span>
                </span>
            </button>
            <input
                type="file"
                (change)="onFilesSelected($event)"
                style="display: none"
                attr.aria-label="{{ 'research.aria.deposit.select.files' | translate }}"
                [multiple]="multiple"
                tabindex="-1"
                id="selected_file"
                #fileRequester
            />
            <!--</span>-->
        </ng-container>
        <ng-container *ngIf="dragOverActive">
            <div class="label">
                <span *ngIf="multiple">{{ multiDropActionLabel }}</span>
                <span *ngIf="!multiple">{{ singleDropActionLabel }}</span>
            </div>
        </ng-container>
    </div>
</div>

<div *ngIf="depositFormDataService.invalidFiles.length" class="invalid-files">
    <mat-list>
        <h3 mat-subheader style="color: red">{{ 'research.files.max.file.size.error' | translate }}</h3>
        <mat-list-item *ngFor="let file of depositFormDataService.invalidFiles">
            <h4 mat-line>{{ file.name }}</h4>
        </mat-list-item>
    </mat-list>
</div>
