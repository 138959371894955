export class SEO {
    public static DESCRIPTIION_META = 'description';
    public static AUTHOR_META = 'author';
    public static TITLE_PROP = 'og:title';
    public static DESCRIPTION_PROP = 'og:description';
    public static IMAGE_PROP = 'og:image';
    public static IMAGE_WIDTH_PROP = 'og:image:width';
    public static IMAGE_HEIGHT_PROP = 'og:image:height';
    public static IMAGE_ALT_PROP = 'og:image:alt';
    public static URL_PROP = 'og:url';
    public static LOCALE_PROP = 'og:locale';
    public static TYPE_PROP = 'og:type';
    public static PROFILE_FIRST_NAME_PROP = 'profile:first_name';
    public static PROFILE_LAST_NAME_PROP = 'profile:last_name';
    public static PROFILE_USERNAME_PROP = 'profile:username';
}
