<h1 id="dialog-title" mat-dialog-title>
    {{ data.title | customTranslate }}
</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>{{ 'research.links.url' | customTranslate }}</mat-label>
        <input [(ngModel)]="data.url" autocomplete="url" matInput required />
        <mat-icon matPrefix svgIcon="link-variant"></mat-icon>
        <mat-error [innerHtml]="'research.settings.field.required.error.message' | translate"></mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ 'research.links.description' | customTranslate }}</mat-label>
        <input [(ngModel)]="data.description" autocomplete="off" matInput />
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <span class="spacer"></span>

    <button
        *ngIf="data.actions.confirm"
        [disabled]="!data.url"
        [mat-dialog-close]="data"
        aria-describedby="dialog-title"
        class="is-large"
        color="accent"
        mat-button
    >
        {{ data.actions.confirm | customTranslate }}
    </button>

    <button *ngIf="data.actions.cancel" mat-button mat-dialog-close="cancel">
        {{ data.actions.cancel | customTranslate }}
    </button>
</div>
