export class PortalSearch {
    public static OUTPUTS_DEFAULT_SCOPE = 'Research';
}

export class SearchEntities {
    public static RESEARCHERS_ENTITY = 'researchers';
    public static OUTPUTS_ENTITY = 'outputs';
    public static PROJECTS_ENTITY = 'projects';
}

export class PortalSearchScope{

    public value : string;
    public viewValue : string;

    constructor(code : string, prefix: string){
        this.viewValue = code;
        this.value = code.replace(prefix,'');
    }
}

export class SearchScopesByEntity {
    public static OUTPUTS = [
        {
            value: 'Research',
            viewValue: 'research.portal.scopes.option.Research',
            ctrlCode: 'research.portal.homepage.all',
        },
        {
            value: 'ResearchNonETD',
            viewValue: 'research.portal.scopes.option.ResearchNonETD',
            ctrlCode: 'research.portal.homepage.nonETD',
        },
        {
            value: 'ResearchETD',
            viewValue: 'research.portal.scopes.option.ResearchETD',
            ctrlCode: 'research.portal.homepage.etd',
        },
        {
            value: 'ResearchOA',
            viewValue: 'research.portal.scopes.option.ResearchOA',
            ctrlCode: 'research.portal.homepage.openAccess',
        }
    ];
}
