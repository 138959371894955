import {
  Component,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';

import { MuloNavLink } from '../../../models/nav-link.model';
import { NavComponent } from '../nav.component';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuContent, MatMenuItem } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';
import { MatTabNav, MatTabLink } from '@angular/material/tabs';

@Component({
    selector: 'mulo-breadcrumbs-nav',
    templateUrl: './breadcrumbs-nav.component.html',
    styleUrls: ['./breadcrumbs-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatTabNav,
        NgFor,
        NgIf,
        MatTabLink,
        MatButton,
        MatMenuTrigger,
        MatIcon,
        MatMenu,
        MatMenuContent,
        MatMenuItem,
    ],
})
export class BreadcrumbsNavComponent extends NavComponent
  implements OnChanges, OnDestroy {
  @Input() allLabel: string = 'All';

  breadcrumbs: MuloNavLink[] = [];
  breadcrumbChanges = new Subject<MuloNavLink[]>();
  allLink: MuloNavLink = {
    name: 'all',
    label: this.allLabel,
  };

  constructor() {
    super();
    this.breadcrumbChanges.subscribe((res) => (this.breadcrumbs = res));
    this.itemSelected.subscribe((item) => (this.selectedIndex = item.id));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) return null;
    // console.group('BreadcrumbsNavComponent.ngOnChanges', changes);
    if (
      changes.selectedIndex?.currentValue !== null &&
      changes.selectedIndex?.currentValue !== undefined
    ) {
      const idx = changes.selectedIndex.currentValue;
      const selectedItem = this.findNavLink(idx);

      let currentNode = selectedItem;
      let path = [currentNode.name];
      while (currentNode && currentNode.parent) {
        path.unshift(currentNode.parent);
        currentNode = this.findNavLink(currentNode.parent, 'name');
      }
      this.selectedPath = path;

      this.breadcrumbChanges.next(this.findPathItems());
      this.itemSelected.next(selectedItem);
    }
    if (changes.selectedPath?.currentValue) {
      this.breadcrumbChanges.next(this.findPathItems());
      const idx = this.breadcrumbs.length - 1;
      this.itemSelected.emit(
        this.breadcrumbs[this.breadcrumbs[idx].name !== 'all' ? idx : idx - 1]
      );
    }
    // console.groupEnd();
  }

  ngOnDestroy() {
    this.breadcrumbChanges.unsubscribe();
    this.itemSelected.unsubscribe();
  }

  trackByFn(index: number, item: MuloNavLink) {
    return item.name;
  }

  findPathItems() {
    // console.group('BreadcrumbsNavComponent.findPathItems');
    const findRecursively = (arr, level = 0) => {
      // console.group('findRecursively', arr, level);
      const depth = this.selectedPath.length;
      let returnValue: MuloNavLink[] = [];
      arr.forEach((obj) => {
        if (obj.name === this.selectedPath[level]) {
          console.log(obj);
          returnValue.push(obj);
        }
        if (Array.isArray(obj.children)) {
          findRecursively(obj.children, level + 1);
        }
        if (level === depth - 1) {
          const id = obj.id;
          this.emitNavDirection(this.selectedIndex, id);
          this.selectedIndex = id;
        }
        // if (obj.name != this.selectedPath[level] && !obj.expanded) {
        //   obj.toggled = false
        // }
        // if (obj.name === this.selectedPath[level]) {
        //   if (level < depth) {
        //     obj.toggled = true
        //   }
        //   if (Array.isArray(obj.children)) findRecursively(obj.children, level + 1)
        //   if (level === depth - 1) {
        //     setTimeout(() => {
        //       const id = obj.id
        //       this.emitNavDirection(this.selectedIndex, id)
        //       // this.updateActiveItem(id)
        //       this.selectedIndex = id
        //     }, this.expandTime);
        //   }
        // }
      });
      // console.groupEnd();
      return returnValue;
    };

    const findIteratively = (pathArr: string[]) => {
      // console.group('findIteratively', pathArr);
      let currentNode: MuloNavLink = {
        name: 'root',
        label: '',
        children: this.items,
      };
      let returnValue: MuloNavLink[] = [currentNode];

      for (const pathKey of pathArr) {
        currentNode = currentNode?.children?.find(
          (childNavLink) => childNavLink.name === pathKey
        );
        if (currentNode !== undefined) {
          returnValue.push(currentNode);
        }
      }
      if (currentNode?.children?.length > 1) {
        returnValue.push(this.allLink);
      }
      // console.groupEnd();
      return returnValue;
    };

    // const find1 = findRecursively(this.items);
    const find2 = findIteratively(this.selectedPath);

    // console.log('find2 :>> ', find2);
    // console.groupEnd();
    return find2;
  }

  onChange(item: MuloNavLink, index: number) {
    // console.group('BreadcrumbsNavComponent.onChange');

    // divide the work so that we don't end up with an "undefined" element
    let slicedPath = [...this.selectedPath.slice(0, index)];
    if (item.name !== 'all') {
      slicedPath.push(item.name);
    }
    // set the path, then do find again
    this.selectedPath = slicedPath;
    this.breadcrumbChanges.next(this.findPathItems());

    // old code
    const nextIdx = index + 1;
    this.emitNavDirection(this.selectedIndex, item.children ? nextIdx : index);
    this.itemSelected.emit(
      item.name !== 'all' ? item : this.breadcrumbs[index]
    );
    // console.groupEnd();
  }

  getCrumbActive(idx: number) {
    let idToCheck: number;

    if (this.breadcrumbs[idx + 1]?.name === 'all') {
      idToCheck = this.breadcrumbs[idx].id;
    } else if (this.breadcrumbs[idx + 2]?.name !== 'all') {
      idToCheck = this.breadcrumbs[idx + 1].id;
    }

    return idToCheck === this.selectedIndex;
  }
}
