<mat-form-field
    class="is-comfortably-readable"
    [muloInputExpander]="searchTerm?.value || (placeholder | customTranslate)"
>
    <mat-label>{{ placeholder | customTranslate }}</mat-label>
    <input matInput [formControl]="searchTerm" [matAutocomplete]="auto" />
    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="searchInProgress"></mat-progress-bar>
    <mat-autocomplete #auto="matAutocomplete" class="is-wide">
        <!-- no results -->
        <mat-option
            class="no-results-option"
            disabled
            *ngIf="searchTerm.value && !searchInProgress && noResultsMessage && searchResult.length == 0"
        >
            <span role="alert" [innerHTML]="noResultsMessage | customTranslate : { value: searchTerm.value }"></span>
        </mat-option>

        <!-- results by search -->
        <ng-container *ngIf="searchResult">
            <mat-option
                *ngFor="let result of searchResult; let i = index"
                [value]=""
                (onSelectionChange)="onResultSelection(result, i)"
                [disabled]="searchInProgress"
            >
                <div class="autocomplete-option-multiline">
                    <small class="result-type small-caps semibold" *ngIf="result.resourcetypeEsploro">{{
                        getResourceType(result) | translate
                    }}</small>
                    <span
                        class="line-1 result-title semibold"
                        [innerHTML]="getTitle(result) | highlight : searchTerm.value"
                    >
                    </span>

                    <small class="line-2 result-authors" *ngIf="getCreatorOrContributor(result)">
                        <span
                            [innerHTML]="getCreatorOrContributor(result) | lowercase | highlight : searchTerm.value"
                        ></span>
                    </small>

                    <small class="line-3 result-publication italics">
                        <ng-container *ngIf="getPublicationTitle(result)">
                            <span [innerHTML]="getPublicationTitle(result) | highlight : searchTerm.value"></span>
                            <span>&nbsp;</span>
                        </ng-container>
                        <ng-container *ngIf="getPublicationDate(result)">
                            <span [innerHTML]="getPublicationDate(result) | highlight : searchTerm.value"></span>
                        </ng-container>
                    </small>
                </div>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
</mat-form-field>
