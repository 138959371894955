<button
  mat-icon-button
  *ngIf="data.actions && data.actions.close"
  class="dialog-close-button"
  [mat-dialog-close]="'cancel'"
>
  <mat-icon svgIcon="close"></mat-icon>
</button>
<h2 mat-dialog-title cdkFocusInitial>
  {{ data.title }}
</h2>
<div mat-dialog-content *ngIf="data.message" [innerHTML]="data.message"></div>
<div mat-dialog-actions>
  <button
    mat-button
    #discard
    *ngIf="data.actions && data.actions.discard"
    [mat-dialog-close]="'discard'"
    [color]="actionFocus === 'discard' ? accentColor : null"
    [class.is-large]="actionFocus == 'discard'"
  >
    {{ data.actions.discard }}
  </button>
  <span class="action-spacer"></span>
  <button
    mat-button
    #confirm
    [mat-dialog-close]="'ok'"
    [color]="actionFocus === 'confirm' ? accentColor : null"
    [class.is-large]="actionFocus == 'confirm'"
  >
    {{ confirmText }}
  </button>
  <button
    mat-button
    #cancel
    [mat-dialog-close]="'cancel'"
    [color]="actionFocus === 'cancel' ? accentColor : null"
    [class.is-large]="actionFocus == 'cancel'"
  >
    {{ cancelText }}
  </button>
</div>
