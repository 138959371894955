import {
  AfterViewInit,
  Directive,
  Host,
  NgModule,
  OnInit,
} from '@angular/core';
import { MatChipRemove } from '@angular/material/chips';

@Directive({
    selector: '[muloFacetChip]',
    host: {},
    standalone: true,
})
export class FacetChipDirective implements OnInit, AfterViewInit {
  constructor(@Host() private removeButton: MatChipRemove) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.removeButton.tabIndex = 0;
  }
}


