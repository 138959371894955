import { NgModule } from '@angular/core';
import {LoginBoxComponent} from './login-box/login-box.component';
import {SharedModule} from '../../shared/shared.module';


@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        LoginBoxComponent
    ],
    exports: [
        LoginBoxComponent
    ]
})
export class LoginGenericModule { }
