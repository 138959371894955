<button mat-icon-button *ngIf="data.actions.close" class="dialog-close-button" [mat-dialog-close]="'cancel'">
  <mat-icon svgIcon="close"></mat-icon>
</button>
<h2 class="padding-zero" mat-dialog-title>
  {{ data.title }}
</h2>
<div mat-dialog-content>
  {{ data.message }}
</div>
<div mat-dialog-actions>
  <button mat-raised-button *ngIf="data.actions.confirm" color="primary" [mat-dialog-close]="'ok'">
    {{ data.actions.confirm }}
  </button>
  <button mat-button *ngIf="data.actions.discard" color="warn" [mat-dialog-close]="'discard'">
    {{ data.actions.discard }}
  </button>
  <button mat-button *ngIf="data.actions.cancel" [mat-dialog-close]="'cancel'">
    {{ data.actions.cancel }}
  </button>
</div>
