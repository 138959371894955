import {
    AfterContentInit,
    Component,
    ElementRef,
    HostBinding,
    Input,
    ViewChild,
} from '@angular/core';
import { BriefBaseComponent } from '../../briefs/brief-base/brief-base.component';
import { AltmetricService } from '../../altmetric/altmetric.service';
import { MediaService } from '@exl-ng/mulo-core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'exl-citation-brief',
    templateUrl: './citation-brief.component.html',
    styleUrls: ['./citation-brief.component.scss'],
})
export class CitationBriefComponent
    extends BriefBaseComponent
    implements AfterContentInit
{
    @HostBinding('class.exl-citation-brief') hostClass = true;
    @ViewChild('exlSlotPreBrief', { static: false }) slotPreBrief: ElementRef;
    @ViewChild('menuBtn') menuBtn: MatButton;
    @Input() linkTarget = '_blank';
    @Input() titleHeaderLevel: number;
    @Input() useByForAuthors: boolean | string = true;

    maxVisibleAuthors = 10;
    @HostBinding('style.minHeight') hostMinHeight;

    @Input() id = Math.random().toString(36).substring(2);

    constructor(
        private altmetricService: AltmetricService,
        public media: MediaService,
        private sanitizer: DomSanitizer,
    ) {
        super();
    }

    ngAfterContentInit() {
        setTimeout(() => {
            this.altmetricService.loadScript();
        }, 800);
    }

    onThumbLoad(height) {
        this.hostMinHeight = this.sanitizer.bypassSecurityTrustStyle(
            `calc(${height}px + 4rem)`,
        );
    }
}
