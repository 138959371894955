import {Injectable, OnInit} from '@angular/core';


@Injectable()
export class DepositStepThreeService implements OnInit{

    private _showAdditionalInfo = false;

    constructor() {
    }

    ngOnInit() {
    }

    get showAdditionalInfo(): boolean {
        return this._showAdditionalInfo;
    }

    set showAdditionalInfo(value: boolean) {
        this._showAdditionalInfo = value;
    }




}
