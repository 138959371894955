import { Directive, HostListener } from '@angular/core';
import {LogoutService} from './logout.service';

@Directive({
    selector: '[espLogout]'
})

export class LogoutDirective {


    constructor(private logoutService: LogoutService) { }

    @HostListener('click', ['$event']) clickEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        this.logoutService.logout();
    }

}

