export class Identifier {
    type: string;
    value: string;
    isExternal: boolean;

    constructor(type, value) {
        this.type = type;
        this.value = value;
        this.isExternal = false;
    }

}
