import { ResearcherProfileFieldsWrapper } from "./researcher-profile-fields-wrapper";

export interface ResearcherProfileLockFieldsConfiguration {
    lockConfigurationMode: LockConfigurationMode;
    profileFieldsWrapper: ResearcherProfileFieldsWrapper;
}

export enum LockConfigurationMode {
    ALL_OPEN = 'ALL_OPEN',
    ALL_LOCKED = 'ALL_LOCKED',
    SELECTED_LOCKED = 'SELECTED_LOCKED',
}