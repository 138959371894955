<div>
    <div>
        <mat-form-field
            *ngIf="displayBookTitle()"
            [class.is-autopopulated]="isAutoPopulated('relationTitle')"
            [muloInputExpander]="depositFormDataService.relationTitle.value"
        >
            <input matInput [formControl]="depositFormDataService.relationTitle" />
            <mat-label>{{ 'research.status.title' | customTranslate }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('relationTitle')"
                svgIcon="auto-populate"
                matTooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
            ></mat-icon>
        </mat-form-field>

        <mat-form-field
            *ngIf="displayISBN()"
            [class.is-autopopulated]="isAutoPopulated('isPartOfIsbnForDisplay')"
            [muloInputExpander]="depositFormDataService.isPartOfIsbnForDisplay.value"
        >
            <input matInput [formControl]="depositFormDataService.isPartOfIsbnForDisplay" />
            <mat-label>{{ 'research.status.isbn' | customTranslate }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('isPartOfIsbnForDisplay')"
                svgIcon="auto-populate"
                matTooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
            ></mat-icon>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field
            *ngIf="displayPages()"
            [class.is-autopopulated]="isAutoPopulated('isPartOfPages')"
            class="is-short-input"
        >
            <input matInput [formControl]="depositFormDataService.isPartOfPages" />
            <mat-hint align="start">{{ 'research.status.page.hint' | customTranslate }}</mat-hint>
            <mat-label>{{ 'research.status.pages' | customTranslate }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('isPartOfPages')"
                svgIcon="auto-populate"
                matTooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
            ></mat-icon>
        </mat-form-field>

        <mat-form-field
            *ngIf="displayBookDOI()"
            [class.is-autopopulated]="isAutoPopulated('isPartOfDoi')"
            [muloInputExpander]="depositFormDataService.isPartOfDoi.value"
        >
            <input matInput [formControl]="depositFormDataService.isPartOfDoi" />
            <mat-label>{{ 'research.status.bookdoi' | customTranslate }}</mat-label>
            <mat-hint>{{ 'research.status.doi.hint' | translate }}</mat-hint>
            <mat-error>
                {{ 'research.status.doi.error' | customTranslate }}
            </mat-error>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('isPartOfDoi')"
                svgIcon="auto-populate"
                matTooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
            ></mat-icon>
        </mat-form-field>

        <mat-form-field
            *ngIf="displayPublisherName()"
            [class.is-autopopulated]="isAutoPopulated('publisher')"
            [muloInputExpander]="depositFormDataService.publisher.value"
            class="is-short-input"
        >
            <input matInput [formControl]="depositFormDataService.publisher" />
            <mat-label>{{ 'research.status.publisher' | customTranslate }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('publisher')"
                svgIcon="auto-populate"
                matTooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
            ></mat-icon>
        </mat-form-field>
    </div>
</div>
