<mat-card appearance="outlined">
  <div class="error-message" *ngIf="error && error.length">
    {{error}}
  </div>
  <form [formGroup]="loginForm" (ngSubmit)="doSubmit()">
    <div class="login-form">
      <mat-form-field class="is-auto-filling">
        <mat-label>{{loginLabel}}</mat-label>
        <input matInput type="text" formControlName="username" required />
        <mat-error
          *ngIf="submitted && f.username.errors && f.username.errors.required"
        >
          {{usernameRequiredError}}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="is-auto-filling">
        <mat-label>{{passwordLabel}}</mat-label>
        <input matInput type="password" formControlName="password" required />
        <mat-error
          *ngIf="submitted && f.password.errors && f.password.errors.required"
        >
          {{passwordRequiredError}}
        </mat-error>
      </mat-form-field>
      <button
        mat-raised-button
        type="submit"
        color="primary"
        class="submit-button is-large"
        [class.is-processing]="loading"
      >
        <ng-container *ngIf="!loading">{{signInCta}}</ng-container>
        <mat-spinner *ngIf="loading" diameter="30"></mat-spinner>
      </button>
      <ng-container *ngIf="helpText && helpText.length">
        <a mat-button class="is-tiny" href="" color="accent">{{helpText}}</a>
      </ng-container>
    </div>
  </form>
</mat-card>
