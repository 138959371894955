<form #formDirective="ngForm" (ngSubmit)="onSubmitNewPerson()" [formGroup]="person">
    <div class="fields-wrap">
        <mat-form-field [muloInputExpander]="firstName">
            <input
                #first
                [attr.autocomplete]="newAuthorType === 'person' ? 'given-name' : 'organization'"
                formControlName="firstName"
                matInput
                required
            />
            @if (newAuthorType === 'person') {
                <mat-label>{{ 'research.peoplenew.first' | customTranslate }}</mat-label>
            } @else {
                <mat-label>{{ 'research.organizationnew.first' | customTranslate }}</mat-label>
            }
            <mat-error [innerHtml]="'research.settings.field.required.error.message' | translate"></mat-error>
        </mat-form-field>
        @if (newAuthorType === 'person') {
            <mat-form-field>
                <input autocomplete="additional-name" formControlName="middleName" matInput />
                <mat-label>{{ 'research.peoplenew.middle' | customTranslate }}</mat-label>
            </mat-form-field>

            <mat-form-field>
                <input #last autocomplete="family-name" formControlName="lastName" matInput required />
                <mat-label>{{ 'research.peoplenew.last' | customTranslate }}</mat-label>
                <mat-error [innerHtml]="'research.settings.field.required.error.message' | translate"></mat-error>
            </mat-form-field>

            <mat-form-field>
                <input autocomplete="honorific-suffix" formControlName="nameSuffix" matInput />
                <mat-label>{{ 'research.peoplenew.suffix' | customTranslate }}</mat-label>
            </mat-form-field>

            <esp-multiple-affiliations
                [label]="'research.settings.section.affiliations' | translate"
                [orgCodeListCtrl]="person?.get('affiliationCodeList')"
                [orgNameListCtrl]="person?.get('affiliationNameList')"
            ></esp-multiple-affiliations>
        }
    </div>
    <div class="actions">
        <button [disabled]="!isValid()" mat-raised-button type="submit">
            {{ 'research.peoplenew.add' | customTranslate }}
        </button>
    </div>
</form>
