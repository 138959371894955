import { NgModule } from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {OnboardingComponent} from './onboarding.component';
import {OnboardingDirective} from './onboarding.directive';


@NgModule({
    imports: [
        SharedModule
    ],
    declarations: [
        OnboardingComponent,
        OnboardingDirective
    ],
    exports: [
        OnboardingComponent,
        OnboardingDirective
    ]
})
export class OnboardingModule { }
