import { Component, OnDestroy, OnInit } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { DateUtils } from '../../../shared/utils/date.utils';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'esp-deposit-event-information',
  templateUrl: './deposit-event-information.component.html',
  styleUrls: ['./deposit-event-information.component.scss'],
})
export class DepositEventInformationComponent implements OnInit, OnDestroy {
  private startDateDestroy = new Subject<void>();
  private endDateDestroy = new Subject<void>();

  constructor(public depositFormDataService: DepositFormDataService) { }

  ngOnInit() {
    this.onChangesEventStartDate();
    this.onChangesEventEndDate();
  }

  onChangesEventStartDate(): void {
    this.depositFormDataService.eventStartDate.valueChanges
      .pipe(takeUntil(this.startDateDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.eventStartDate.value) {
          this.depositFormDataService.updateEventStartYear('');
          this.depositFormDataService.updateEventStartMonth('');
          this.depositFormDataService.updateEventStartDay('');
        } else {
          const date = new Date(
            this.depositFormDataService.eventStartDate.value
          );
          this.depositFormDataService.updateEventStartYear(
            DateUtils.getYear(date)
          );
          this.depositFormDataService.updateEventStartMonth(
            DateUtils.getMonth(date)
          );
          this.depositFormDataService.updateEventStartDay(
            DateUtils.getDay(date)
          );
        }
      });
  }

  onChangesEventEndDate(): void {
    this.depositFormDataService.eventEndDate.valueChanges
      .pipe(takeUntil(this.endDateDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.eventEndDate.value) {
          this.depositFormDataService.updateEventEndYear('');
          this.depositFormDataService.updateEventEndMonth('');
          this.depositFormDataService.updateEventEndDay('');
        } else {
          const date = new Date(
            this.depositFormDataService.eventEndDate.value
          );
          this.depositFormDataService.updateEventEndYear(
            DateUtils.getYear(date)
          );
          this.depositFormDataService.updateEventEndMonth(
            DateUtils.getMonth(date)
          );
          this.depositFormDataService.updateEventEndDay(
            DateUtils.getDay(date)
          );
        }
      });
  }

  ngOnDestroy() {
    if (!this.startDateDestroy.isStopped) {
      this.startDateDestroy.next();
      this.startDateDestroy.unsubscribe();
    }
    if (!this.endDateDestroy.isStopped) {
      this.endDateDestroy.next();
      this.endDateDestroy.unsubscribe();
    }
  }
}
