import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
} from '@angular/core';
import { NgIf, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'mulo-side-main-layout',
    templateUrl: './side-main-layout.component.html',
    styleUrls: ['./side-main-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgTemplateOutlet],
})
export class SideMainLayoutComponent implements OnInit {
  /**
   * Position of the side column - to the left of the main colum, or to its right
   */
  @Input() sideSide: 'left' | 'right' = 'left';
  /**
   * Overrides the default sidebar width
   */
  @HostBinding('style.--side-column-size') @Input() sideColumnSize:
    | number
    | string = '15em';

  constructor() {}

  ngOnInit(): void {}
}
