import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { NavComponent } from '../nav.component';
import { MuloNavLink } from '../../../models/nav-link.model';
import { Router, ActivatedRoute, RouterLink } from '@angular/router';
import { Location, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { MatTabNav, MatTabLink } from '@angular/material/tabs';

@Component({
    selector: 'mulo-horizontal-nav',
    templateUrl: './horizontal-nav.component.html',
    styleUrls: ['./horizontal-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatTabNav,
        NgFor,
        MatTabLink,
        RouterLink,
        NgIf,
        NgTemplateOutlet,
    ],
})
export class HorizontalNavComponent extends NavComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    super();
  }

  ngOnInit(): void {}

  changeItem(selected: MuloNavLink, fragment?: boolean) {
    if (fragment) {
      const routeWithFragment = this.router
        .createUrlTree([], {
          relativeTo: this.activatedRoute,
          fragment: selected.name,
          queryParamsHandling: 'preserve',
        })
        .toString();
      this.location.replaceState(routeWithFragment);
      this.itemSelected.emit(selected);
      return;
    }

    const toggle = (array: MuloNavLink[]) => {
      array.forEach((el: MuloNavLink) => {
        if (el.level !== 0) {
          el.toggled = false;
        }
        if (!el.expanded) {
          el.toggled = false;
        }
        if (el?.name === selected?.name && el?.label === selected?.label) {
          el.toggled = true;
        }
        if (el.children) {
          toggle(el.children);
        }
      });
    };
    if (selected.level !== this.levelsDepth) {
      console.log(selected);
      toggle(this.items);
    }

    this.emitNavDirection(this.selectedIndex, this.items.indexOf(selected));
    if (selected.url) {
      this.router.navigate([selected.url], { relativeTo: this.activatedRoute });
    } else {
      this.itemSelected.emit(selected);
    }
  }
}
