import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  HeightInAnimation,
  HeightOutAnimation,
  OpacityInAnimation,
  OpacityOutAnimation,
} from '../../animations/index';
import { BehaviorSubject } from 'rxjs';
import { MatRipple } from '@angular/material/core';
import { DOCUMENT, NgClass, NgIf, AsyncPipe } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { WINDOW } from 'ngx-window-token';
import { CdkAriaLive } from '@angular/cdk/a11y';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'mulo-copier',
    templateUrl: './copier.component.html',
    styleUrls: ['./copier.component.scss'],
    animations: [
        HeightInAnimation,
        HeightOutAnimation,
        OpacityInAnimation,
        OpacityOutAnimation,
    ],
    standalone: true,
    imports: [
        NgClass,
        MatRipple,
        NgIf,
        MatButton,
        MatIcon,
        CdkAriaLive,
        AsyncPipe,
    ],
})
export class CopierComponent implements OnInit {
  @Input() copyLabel = 'Copy';
  @Input() copiedLabel = 'Copied!';
  @Input() copyIcon = 'copy';
  @Input() rtlMirrorIcon: boolean;
  @Input() processing = false;
  @Input() display: 'single' | 'wrapping' = 'single';
  @Input() copyMethod: 'rich' | 'plain' = 'plain';
  @Input() content: any = 'ksajdljasdljasldkjasd';
  @HostBinding('style.--button-indent') @Input() btnIndent: string;
  @Output() copied = new EventEmitter<null>();
  @ViewChild(MatRipple) ripple: MatRipple;

  copying$ = new BehaviorSubject<boolean>(false);
  private copyListener: () => void;

  constructor(
    @Inject(DOCUMENT) private document,
    private renderer: Renderer2,
    private cb: Clipboard,
    @Inject(WINDOW) private _window: any
  ) {}

  ngOnInit(): void {}

  copyToClipboard(ev: MouseEvent) {
    ev.stopImmediatePropagation();
    this.ripple.disabled = true;
    this.copying$.next(true);
    this.launchRipple(ev.screenX, ev.screenY);
    this.copyByMethod();
  }

  copyByMethod() {
    const htmlContent = this.content;
    const textContent = new DOMParser()
      .parseFromString(htmlContent, 'text/html')
      .body.textContent.trim();

    if (this.copyMethod === 'plain') {
      // When using preformatted (<pre>) content, use CDK clipboards
      this.cb.copy(this.content);
    } else if (this._window?.navigator?.clipboard?.write) {
      // ...otherwise try to use the async clipboard api if available...
      const data = [
        new this._window.ClipboardItem({
          'text/html': new Blob([htmlContent], { type: 'text/html' }),
          'text/plain': new Blob([textContent], { type: 'text/plain' }),
        }),
      ];
      this._window.navigator.clipboard.write(data);
    } else {
      // ...otherwise use an event listener on the document
      const listener = (ev) => {
        ev.preventDefault();
        ev.clipboardData.setData('text/html', htmlContent);
        ev.clipboardData.setData('text/plain', textContent);
      };
      this.copyListener = this.renderer.listen('document', 'copy', listener);
      this.document.execCommand('copy');
    }
    setTimeout(() => {
      this.copied.emit(null);
    });
  }

  launchRipple(x: number, y: number) {
    const rippleRef = this.ripple.launch(x, y, {
      persistent: true,
      centered: false,
      animation: { enterDuration: 300, exitDuration: 500 },
    });

    const unlisteners = [];
    for (const ev of ['click', 'keydown.enter', 'keydown.space']) {
      unlisteners.push(
        this.renderer.listen('window', ev, () => {
          // Fade out the ripple later.
          rippleRef.fadeOut();
          // remove copy event listener if used
          if (this.copyListener) this.copyListener();

          setTimeout(() => {
            // Emit end of copy process
            this.copying$.next(false);
            // discard listeners nicely
            unlisteners.forEach((ul) => ul());
          }, 500);
        })
      );
    }
  }
}
