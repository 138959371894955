<div class="metadata-container">
    <div *ngIf="!showDescriptionButton || link.description" @growOutAnimation @slideInAnimation class="metadata-block">
        <mat-form-field [muloInputExpander]="description" class="hasnt-underline">
            <mat-label>{{ 'research.linkmd.description' | customTranslate }}</mat-label>
            <textarea
                #textarea
                (ngModelChange)="description = $event; onChangeDescription()"
                [ngModel]="link.description"
                [placeholder]="'research.linkmd.describe' | customTranslate"
                [rows]="1"
                matInput
                matTextareaAutosize
            >
            </textarea>
        </mat-form-field>
        <span class="actions">
            <button
                (click)="onRemoveDescription()"
                *ngIf="!showDescriptionButton || link.description"
                [attr.aria-label]="'research.aria.deposit.links.remove.link.description' | translate"
                class="is-small"
                mat-icon-button
                type="button"
            >
                <mat-icon svgIcon="close"></mat-icon>
            </button>
        </span>
    </div>
    <div *ngIf="link.license" @growOutAnimation @slideInAnimation class="metadata-block">
        <div class="inner">
            <mat-form-field class="hasnt-underline is-dummy-disabled has-dynamic-width">
                <mat-label>{{ 'research.links.link.license.title' | customTranslate }}</mat-label>
                <mat-select disabled value="0">
                    <mat-option value="0">{{ link.license + '.name' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            <span class="actions">
                <button
                    (click)="onAddLicense()"
                    [attr.aria-label]="'research.aria.deposit.links.edit.license' | translate"
                    class="is-small"
                    mat-icon-button
                    type="button"
                >
                    <mat-icon svgIcon="pencil"></mat-icon>
                </button>
                <button
                    (click)="onRemoveLicense()"
                    [attr.aria-label]="'research.aria.deposit.links.remove.license' | translate"
                    class="is-small"
                    mat-icon-button
                    type="button"
                >
                    <mat-icon svgIcon="close"></mat-icon>
                </button>
            </span>
        </div>
    </div>
</div>
<div *ngIf="!showTypeButton || displayType()" @growOutAnimation @slideInAnimation class="metadata-block">
    <div class="inner">
        <mat-form-field class="hasnt-underline is-dummy-disabled has-dynamic-width">
            <mat-label>{{ 'research.linkmd.type' | customTranslate }}</mat-label>
            <mat-select #typeInput (selectionChange)="type = $event.value; onChangeType()" [ngModel]="type">
                <mat-option *ngFor="let type of linkTypes" [value]="type">
                    {{ 'AssetFileAndLinkTypesLabels.' + type | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <span class="actions">
            <button
                (click)="onRemoveDocumentType()"
                [attr.aria-label]="'research.aria.deposit.links.remove.link.type' | translate"
                class="is-small"
                mat-icon-button
                type="button"
            >
                <mat-icon svgIcon="close"></mat-icon>
            </button>
        </span>
    </div>
</div>

<div *ngIf="showFooter" class="metadata-footer">
    <button
        (click)="onAddLicense()"
        *ngIf="displayLicense && showLicenseButton && !link.license"
        @growInAnimation
        class="has-icon"
        color="primary"
        mat-button
        type="button"
    >
        <mat-icon svgIcon="plus"></mat-icon>
        {{ 'research.link.license.add' | customTranslate }}
    </button>
    <button
        (click)="onAddDescription()"
        *ngIf="displayDescription && showDescriptionButton && !link.description"
        @growInAnimation
        class="has-icon"
        color="primary"
        mat-button
        type="button"
    >
        <mat-icon svgIcon="plus"></mat-icon>
        {{ 'research.linktable.description' | customTranslate }}
    </button>
    <button
        (click)="onSetDocumentType()"
        *ngIf="displayContentType && showTypeButton && linkTypes.length > 0 && displaySetTypeButton()"
        @growInAnimation
        class="has-icon"
        color="primary"
        mat-button
        type="button"
    >
        <mat-icon svgIcon="plus"></mat-icon>
        {{ 'research.linktable.type' | customTranslate }}
    </button>
</div>
