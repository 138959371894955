import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { MediaService } from '@exl-ng/mulo-core';
import { ColorComponentMixinBase } from '../../shared/common-behavior/color';

@Component({
    selector: 'exl-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    host: { 'class': 'exl-footer' },
    inputs: ['color']
})
export class FooterComponent extends ColorComponentMixinBase implements OnInit {

    constructor(elementRef: ElementRef,
        public media: MediaService) {
        super(elementRef);
    }

    ngOnInit() {
    }

}
