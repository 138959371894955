import { Component, OnInit, ChangeDetectionStrategy, Input, SimpleChanges, OnChanges, ContentChildren, QueryList, ViewChild } from '@angular/core';
import { FacetSectionComponent } from './facet-section/facet-section.component';
import { FacetItemComponent } from './facet-item/facet-item.component';
import { MatAccordion } from '@angular/material/expansion';

@Component({
    selector: 'mulo-facets',
    templateUrl: './facets.component.html',
    styleUrls: ['./facets.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        'class': 'mulo-facets',
        '[class.has-checkboxes]': 'showCheckboxes',
        '[class.is-disabled]': 'disabled'
    },
    standalone: true
})
export class FacetsComponent implements OnInit, OnChanges {

  @Input() showCheckboxes: boolean = false
  @Input() disabled: boolean = false

  // @ViewChild(MatAccordion) accordion: MatAccordion

  @ContentChildren(FacetItemComponent, { descendants: true }) items: QueryList<FacetItemComponent>

  // @ContentChildren(FacetSectionComponent, { descendants: true }) sections: QueryList<FacetSectionComponent>

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) return
    if (changes.showCheckboxes) {
      if (this.items) {
        this.setCheckboxes()
      }
    }
  }

  setCheckboxes() {
    const val = this.showCheckboxes
    if (this.items) {
      this.items.toArray().forEach(item => item.hasCheckbox = val)
    }
  }
}
