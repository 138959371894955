export class State {
    public static PROFILE = '/profile';
    public static SETTINGS = '/settings';
    public static SEARCH = '/search';
    public static OUTPUTS_SEARCH = '/search/outputs/';
    public static RESEARCHERS_SEARCH = '/search/researchers/';
    public static PROJECTS_SEARCH = '/search/projects/';
    public static LOGIN = '/login';
    public static LOGIN_AS = '/login-as';
    public static HOME_PAGE = '';
    public static RESOURCES = '/resources';
    public static PROJECTS = '/profile/projects';
    public static ACTIVITIES = '/profile/activities';

}
