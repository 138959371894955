import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UrlUtils } from '../shared/utils/url.utils';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { Collection } from '../shared/interfaces/collection.interface';

@Injectable({
    providedIn: 'root',
})
export class CollectionsService {
    institutionCode = UrlUtils.getParam('institution');

    constructor(private http: HttpClient) {}

    getActiveCollections() {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        return this.http.get<Collection[]>(RestApiBase.BROWSE_COLLECTIONS, {
            params,
        });
    }

    getFeaturedCollections() {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        return this.http.get<Collection[]>(
            RestApiBase.GET_FEATURED_COLLECTIONS,
            { params }
        );
    }

    getCollectionCodesByEntityId(entityId: string) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );
        return this.http.get<Collection[]>(
            RestApiBase.GET_COLLECTION_CODES_BY_ENTITY_ID + '/' + entityId,
            { params }
        );
    }
    getOrganizationCollections(organizationCode: string) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('organizationCode', organizationCode);
        return this.http.get<Collection[]>(
            RestApiBase.GET_ORG_UNIT_COLLECTIONS,
            { params }
        );
    }
}
