<dl class="dictionary is-{{alignLabels}}-aligned as-{{display}}">
  <ng-container *ngIf="items">
    <ng-container *ngFor="let key of keys(items)">
      <div mulo-dictionary-item
        [label]="key"
        *ngIf="parseValue(key) as value"
        [labelAlign]="alignLabels"
      >
        <span slot="content" [innerHTML]="value"></span>
      </div>
    </ng-container>
  </ng-container>
  <ng-content></ng-content>
</dl>
