export class TabComponentModel {
    field:string;
    mandatory:boolean;
    visible:string;
    order:string;
    default_value:string;
    options:string[];

    constructor(field, mandatory, visible, order, default_value, options){
        this.field = field;
        this.mandatory = mandatory;
        this.visible = visible;
        this.order = order;
        this.default_value = default_value;
        this.options = options;
    }
}
