import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../../../profile/profile.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '../../../core/snackbar.service';
import { SuccessSnackbarComponent } from '../../snackbars/success-snackbar/success-snackbar.component';

@Component({
    selector: 'exl-change-password-dialog',
    templateUrl: './change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

    passwordForm: UntypedFormGroup;
    errorMessage = '';
    saveInProgress = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private formBuilder: UntypedFormBuilder,
                private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
                public profileService: ProfileService,
                private translate: TranslateService,
                private snackbarService: SnackbarService) {
        dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.passwordForm = this.formBuilder.group({
                old: [null, Validators.required],
                new: ['', Validators.required],
                repeat: ['', Validators.required]
            }
        )
    }

    getPasswordFormValue = (fieldName: string): string => {
        return this.passwordForm.get(fieldName).value;
    }

    onSubmit() {
        if (this.passwordForm.valid){
            if (this.passwordForm.value.new !== this.passwordForm.value.repeat){
                this.errorMessage = this.translate.instant('research.password.change.error.notUnique');
                return;
            }
            this.resetErrors();
            this.saveInProgress = true;
            this.profileService.changePassword(this.getPasswordFormValue('old'), this.getPasswordFormValue('new'),
                this.getPasswordFormValue('repeat')).subscribe(data =>{
                    this.saveInProgress = false;
                if (data.status === 'ok'){
                    this.dialogRef.close();
                    this.showSuccessfullMessage();
                } else {
                    this.errorMessage = data['reply-text'];
                }
            }, () => this.saveInProgress = false);
        }
    }

    resetErrors(){
        this.errorMessage = '';
    }

    showSuccessfullMessage() {
        this.snackbarService.showComponent(
            SuccessSnackbarComponent,
            'research.password.change.success',
            6000
        );
    }

}
