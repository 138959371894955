<div class="info">
    <span *ngIf="!proxyName">
        {{ 'research.header.signin.as' | translate }} <b class="semibold">{{ userName }}</b>
    </span>
    <span *ngIf="proxyName">
        {{ 'research.header.signin.as' | translate }} <b class="semibold">{{ proxyName }}</b
        >, {{ 'research.header.signin.manage' | translate }}
        <b class="semibold">{{ userName }}</b>
    </span>
</div>
<ng-container *ngFor="let section of menuItems">
    <!-- <div> -->
    <ng-container *ngFor="let item of section.items">
        <button mat-menu-item [disabled]="item.disabled" (click)="onItemClick(item)" id="{{ item.label }}">
            <mat-icon *ngIf="item.icon" [svgIcon]="item.icon"></mat-icon>
            <span *ngIf="item.label">
                {{ item.label | translate }}
            </span>
            <span *ngIf="item.labelMethod">{{ item.labelMethod() }}</span>
            <span *ngIf="section.section === 'language'">
                {{ getCurrentLanguage() | translate }} {{ getCurrentLanguage().toUpperCase() }}
                <span class="cdk-visually-hidden">{{ ' ' }}{{ getLabel(item) | translate }}{{ ' ' }}</span>
            </span>
        </button>
    </ng-container>
    <!-- </div> -->
    <mat-divider *ngIf="section.divider"></mat-divider>
</ng-container>

<button mat-menu-item (click)="doSignOut()" id="signOut">
    {{ 'research.header.sign.out' | translate }}
</button>
