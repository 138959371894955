import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigurationHandlerService } from './configuration-handler.service';
import { filter, tap } from 'rxjs';

// google-analytics.service.ts
declare let gtag: Function;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    private googleAnalyticsId: string;
    private googleVerification: string;
    private scriptsLoaded: boolean = false;

    constructor(
        private _router: Router,
        private configurationHandlerService: ConfigurationHandlerService
    ) {
        this.googleAnalyticsId =
            this.configurationHandlerService.getCustomerParameter(
                'esploro_ga_tracking_id'
            );
        this.googleVerification =
            this.configurationHandlerService.getCustomerParameter(
                'esploro_google_verification'
            );
    }

    init() {
        if (!this.scriptsLoaded) {
            this.insertMainScript();
        }
        this.addGoogleVerificationMeta();
    }

    private insertMainScript() {
        if (this.googleAnalyticsId) {
            const script = document.createElement('script');
            script.src = `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalyticsId}`;
            script.async = true;
            document.getElementsByTagName('head')[0].appendChild(script);
            const gtagEl = document.createElement('script');
            const gtagBody = document.createTextNode(`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
        `);
            gtagEl.appendChild(gtagBody);
            document.body.appendChild(gtagEl);
            script.onload = () => {
                this.scriptsLoaded = true;
            };
        }
    }

    trackSinglePageView(event: NavigationEnd) {
        if (this.googleAnalyticsId && this.scriptsLoaded) {
            gtag('js', new Date());
            gtag('config', this.googleAnalyticsId, {
                page_path: event.urlAfterRedirects,
            });
        }
    }

    trackPageViews() {
        return this._router.events.pipe(
            filter(() => this.scriptsLoaded === true),
            filter((evt) => evt instanceof NavigationEnd),
            tap((event: NavigationEnd) => {
                this.trackSinglePageView(event);
            })
        );
    }

    trackEvent(
        { eventName, eventCategory, eventAction, eventLabel, eventValue } = {
            eventName: null,
            eventCategory: null,
            eventAction: null,
            eventLabel: null,
            eventValue: null,
        }
    ) {
        gtag('event', eventName, {
            eventCategory,
            eventLabel,
            eventAction,
            eventValue,
        });
    }

    addGoogleVerificationMeta() {
        if (this.googleVerification) {
            var metatag = document.createElement('meta');
            metatag.setAttribute('name', 'google-site-verification');
            metatag.setAttribute('content', this.googleVerification);
            document.head.appendChild(metatag);
        }
    }
}
