<exl-labeled-row label="{{ 'research.asset.type.title.summary' | customTranslate }}">
    {{ resourceType | translate }}
    <span *ngIf="displayPeerReviewed()"> | {{ 'research.summary.peer.reviewed' | customTranslate }}</span>
</exl-labeled-row>

<exl-labeled-row label="{{ 'research.title.title.summary' | customTranslate }}">
    {{ asset.title }}
    <span class="warning" *ngIf="depositFormDataService.title.status === 'INVALID'">
        {{ 'research.title.warning.summary' | customTranslate }}
        <button mat-button class="is-inline" color="primary" (click)="goToTitle()">
            {{ 'research.title.summary.add.title' | customTranslate }}
        </button>
    </span>
</exl-labeled-row>

<exl-labeled-row label="{{ 'research.subtitle.title.summary' | customTranslate }}" *ngIf="displaySubtitle()">
    {{ asset.subtitle }}
</exl-labeled-row>

<exl-labeled-row label="{{ 'research.authors.title.summary' | customTranslate }}">
    <span *ngFor="let author of asset.authors; let last = last"
        >{{ author.name }}
        <span *ngIf="!last">, </span>
    </span>
</exl-labeled-row>

<exl-labeled-row label="{{ 'research.files.title.summary' | customTranslate }}">
    <div *ngFor="let file of files; let i = index" class="file">
        <ng-container>{{ i + 1 }}. {{ file.fullName }}</ng-container>
    </div>

    <span class="suggestion" *ngIf="!files || files.length === 0">
        {{ 'research.file.warning.summary' | customTranslate }}
        <button mat-button class="is-inline" color="primary" (click)="goToFiles()">
            {{ 'research.file.summary.add.files' | customTranslate }}
        </button>
    </span>
</exl-labeled-row>

<exl-labeled-row label="{{ 'research.links.title.summary' | customTranslate }}" *ngIf="asset.links.length > 0">
    <div *ngFor="let link of asset.links; let i = index" class="file">
        <ng-container>{{ i + 1 }}. {{ link.url }}</ng-container>
    </div>
</exl-labeled-row>

<exl-labeled-row label="{{ 'research.topics.title.summary' | customTranslate }}">
    <span *ngFor="let topic of assetTopics; let last = last"
        >{{ topic }}
        <span *ngIf="!last">, </span>
    </span>
    <span class="suggestion" *ngIf="assetTopics.length === 0">
        {{ 'research.topics.warning.summary' | customTranslate }}
        <button mat-button class="is-inline" color="primary" (click)="goToTopics()">
            {{ 'research.file.summary.add.topics' | customTranslate }}
        </button>
    </span>
</exl-labeled-row>

<exl-labeled-row label="{{ 'research.title.summary.description.title' | customTranslate }}" *ngIf="asset.description">
    <span [innerHTML]="asset.description"></span>
</exl-labeled-row>

<exl-labeled-row
    *ngIf="asset.doi || asset.pmid || asset.isbnForDisplay"
    label="{{ 'research.summary.asset.identifiers.title' | customTranslate }}"
>
    <ng-container *ngIf="asset.doi">{{ 'research.summary.asset.doi' | customTranslate }} {{ asset.doi }}</ng-container>
    <ng-container *ngIf="(asset.doi && asset.pmid) || (asset.doi && asset.isbnForDisplay)">,</ng-container>
    <ng-container *ngIf="asset.pmid" class="line"
        >{{ 'research.summary.asset.pmid' | customTranslate }} {{ asset.pmid }}</ng-container
    >
    <ng-container *ngIf="asset.isbnForDisplay" class="line"
        >{{ 'research.summary.asset.isbn' | customTranslate }} {{ asset.isbnForDisplay }}</ng-container
    >
</exl-labeled-row>

<!-- geolocation -->
<exl-labeled-row
    *ngIf="displayGeoLocationInfo()"
    label="{{ 'research.summary.asset.geolocation.title' | customTranslate }}"
>
    <p *ngIf="asset.address">
        {{ 'research.summary.asset.geolocation.address' | customTranslate }}: {{ asset.address }}
    </p>
    <p *ngIf="asset.pointLatitude && asset.pointLongitude">
        {{ 'research.summary.asset.geolocation.latitude' | customTranslate }}: {{ asset.pointLatitude }},
        {{ 'research.summary.asset.geolocation.longitude' | customTranslate }}: {{ asset.pointLongitude }}
    </p>
    <p *ngIf="asset.boundingBoxSouth && asset.boundingBoxWest && asset.boundingBoxNorth && asset.boundingBoxEast">
        {{ 'research.summary.asset.geolocation.south' | customTranslate }}: {{ asset.boundingBoxSouth }},
        {{ 'research.summary.asset.geolocation.west' | customTranslate }}: {{ asset.boundingBoxWest }},
        {{ 'research.summary.asset.geolocation.north' | customTranslate }}: {{ asset.boundingBoxNorth }},
        {{ 'research.summary.asset.geolocation.east' | customTranslate }}: {{ asset.boundingBoxEast }}
    </p>
</exl-labeled-row>

<exl-labeled-row
    label="{{ 'research.summary.asset.geolocation.title' | customTranslate }}"
    *ngIf="
        depositFormDataService.mainForm?.errors?.validateSpatialMandatoryFields ||
        !depositFormDataService.pointLongitude.valid ||
        !depositFormDataService.pointLatitude.valid ||
        !depositFormDataService.boundingBoxEast.valid ||
        !depositFormDataService.boundingBoxNorth.valid ||
        !depositFormDataService.boundingBoxWest.valid ||
        !depositFormDataService.boundingBoxSouth.valid
    "
>
    <span class="warning" *ngIf="depositFormDataService.mainForm?.errors?.validateSpatialMandatoryFields">
        {{ 'research.geospatial.error' | customTranslate }}
    </span>
    <span class="warning" *ngIf="!depositFormDataService.pointLongitude.valid">
        {{ 'research.geospatial.longitude.error' | customTranslate }}
    </span>
    <span class="warning" *ngIf="!depositFormDataService.pointLatitude.valid">
        {{ 'research.geospatial.latitude.error' | customTranslate }}
    </span>
    <span class="warning" *ngIf="!depositFormDataService.boundingBoxEast.valid">
        {{ 'research.geospatial.east.error' | customTranslate }}
    </span>
    <span class="warning" *ngIf="!depositFormDataService.boundingBoxNorth.valid">
        {{ 'research.geospatial.north.error' | customTranslate }}
    </span>
    <span class="warning" *ngIf="!depositFormDataService.boundingBoxWest.valid">
        {{ 'research.geospatial.west.error' | customTranslate }}
    </span>
    <span class="warning" *ngIf="!depositFormDataService.boundingBoxSouth.valid">
        {{ 'research.geospatial.south.error' | customTranslate }}
    </span>
</exl-labeled-row>

<exl-labeled-row
    *ngIf="displayPublicationDetails()"
    label="{{ 'research.summary.asset.publication.title' | customTranslate }}"
>
    <p *ngIf="displayPublicationDate()">
        {{ getDateTitleBasedOnStatus() | customTranslate }} : {{ asset.seasonPublished | translate }}
        {{ formattedDate(asset.date) }}
    </p>
    <p class="comma-separated-values">
        <span *ngIf="displayRelationTitle()">{{ asset.relationTitle }}</span>
        <span *ngIf="displayIsPartOfIssn()"
            >{{ 'research.summary.asset.publication.part.issn' | customTranslate }} {{ asset.isPartOfIssn }}</span
        >
        <span *ngIf="displayIsPartOfIsbnForDisplay()"
            >{{ 'research.summary.asset.publication.part.isbn' | customTranslate }}
            {{ asset.isPartOfIsbnForDisplay }}</span
        >
        <span *ngIf="displayIsPartOfVolume()"
            >{{ 'research.summary.asset.publication.volume' | customTranslate }} {{ asset.isPartOfVolume }}</span
        >
        <span *ngIf="displayIsPartOfIssue()"
            >{{ 'research.summary.asset.publication.issue' | customTranslate }} {{ asset.isPartOfIssue }}</span
        >
        <span *ngIf="displayIsPartOfPages()"
            >{{ 'research.summary.asset.publication.pages' | customTranslate }} {{ asset.isPartOfPages }}</span
        >
        <span *ngIf="asset.publisher"
            >{{ 'research.summary.asset.publication.publisher' | customTranslate }} {{ asset.publisher }}</span
        >
        <span *ngIf="displayIsPartOfDoi()"
            >{{ 'research.summary.asset.publication.part.doi' | customTranslate }} {{ asset.isPartOfDoi }}</span
        >
        <span *ngIf="isBook() && asset.edition"
            >{{ 'research.summary.asset.publication.edition' | customTranslate }} {{ asset.edition }}</span
        >
        <span *ngIf="isBook() && asset.pages"
            >{{ 'research.summary.asset.publication.pages' | customTranslate }} {{ asset.pages }}</span
        >
        <span *ngIf="isBook() && asset.series"
            >{{ 'research.summary.asset.publication.series' | customTranslate }} {{ asset.series }}</span
        >
        <span *ngIf="isBook() && asset.publicationPlaceForDisplay"
            >{{ 'research.summary.asset.publication.publication.place' | customTranslate }}
            {{ asset.publicationPlaceForDisplay }}</span
        >
    </p>
</exl-labeled-row>

<exl-labeled-row
    *ngIf="depositFormDataService.mainForm?.errors?.validDate"
    label="{{ 'research.summary.asset.publication.title' | customTranslate }}"
>
    <span class="warning">
        {{ 'research.status.error' | customTranslate }}
    </span>
</exl-labeled-row>

<!--Conference-->
<exl-labeled-row
    *ngIf="displayConferenceDetails()"
    label="{{ 'research.summary.asset.conference.title' | customTranslate }}"
>
    <p class="comma-separated-values">
        <span *ngIf="asset.conferenceName">
            {{ asset.conferenceName }}
        </span>

        <span *ngIf="asset.conferenceNumber">
            {{ asset.conferenceNumber }}
        </span>

        <span *ngIf="asset.conferenceLocation">
            {{ asset.conferenceLocation }}
        </span>

        <span *ngIf="asset.conferenceStartDate" class="line">
            {{ formattedDate(asset.conferenceStartDate) }}
            <ng-container *ngIf="asset.conferenceEndDate"
                >&ndash;{{ formattedDate(asset.conferenceEndDate) }}</ng-container
            >
        </span>
    </p>
</exl-labeled-row>

<!--Event-->
<exl-labeled-row *ngIf="displayEventDetails()" label="{{ 'research.summary.asset.event.title' | customTranslate }}">
    <p class="comma-separated-values">
        <span *ngIf="asset.eventName">
            {{ asset.eventName }}
        </span>

        <span *ngIf="asset.eventType">
            {{ asset.eventType }}
        </span>

        <span *ngIf="asset.eventLocation">
            {{ asset.eventLocation }}
        </span>

        <span *ngIf="asset.eventStartDate" class="line">
            {{ formattedDate(asset.eventStartDate) }}
            <ng-container *ngIf="asset.eventEndDate">&ndash;{{ formattedDate(asset.eventEndDate) }}</ng-container>
        </span>
    </p>
</exl-labeled-row>

<!--Presentation-->
<exl-labeled-row
    *ngIf="displayPresentationDetails()"
    label="{{ 'research.summary.asset.presentation.title' | customTranslate }}"
>
    <p class="comma-separated-values">
        <span *ngIf="asset.pages">
            {{ 'research.summary.asset.publication.pages' | customTranslate }}: {{ asset.pages }}
        </span>

        <span *ngIf="asset.audience">
            {{ 'research.summary.asset.conference.audience' | customTranslate }}: {{ asset.audience }}
        </span>
    </p>
</exl-labeled-row>

<!-- dates (other than publication) -->
<exl-labeled-row *ngIf="displayDatesSection()" label="{{ 'research.summary.asset.dates.title' | customTranslate }}">
    <p class="comma-separated-values">
        <span *ngIf="asset.datePublished"
            >{{ 'research.summary.asset.date.published' | customTranslate }} :
            {{ formattedDate(asset.datePublished) }}</span
        >

        <span *ngIf="asset.datePresented"
            >{{ 'research.summary.asset.date.presented' | customTranslate }} :
            {{ formattedDate(asset.datePresented) }}</span
        >

        <span *ngIf="asset.dateCollectedStart"
            >{{ 'research.summary.asset.date.collected' | customTranslate }} :
            {{ formattedDate(asset.dateCollectedStart) }}
            <ng-container *ngIf="asset.dateCollectedEnd"
                >&ndash;{{ formattedDate(asset.dateCollectedEnd) }}</ng-container
            >
        </span>

        <span *ngIf="asset.dateCreatedStart" class="line"
            >{{ 'research.summary.asset.date.created' | customTranslate }} : {{ formattedDate(asset.dateCreatedStart) }}
            <ng-container *ngIf="asset.dateCreatedEnd">&ndash;{{ formattedDate(asset.dateCreatedEnd) }}</ng-container>
        </span>
    </p>
</exl-labeled-row>

<exl-labeled-row label="{{ 'research.title.summary.methods.title' | customTranslate }}" *ngIf="displayMethods()">
    <span [innerHTML]="asset.methods"></span>
</exl-labeled-row>

<exl-labeled-row label="{{ 'research.title.summary.coverage.title' | customTranslate }}" *ngIf="displayCoverage()">
    <span [innerHTML]="asset.coverage"></span>
</exl-labeled-row>

<exl-labeled-row
    label="{{ 'research.title.summary.technical.title' | customTranslate }}"
    *ngIf="displayTechInformation()"
>
    <span [innerHTML]="asset.technicalInformation"></span>
</exl-labeled-row>

<exl-labeled-row label="{{ 'research.languages.title.summary' | customTranslate }}" *ngIf="asset.languages.length > 0">
    <span *ngFor="let lang of asset.languages; let last = last">
        {{ lang | translate }}
        <span *ngIf="!last">, </span>
    </span>
</exl-labeled-row>

<exl-labeled-row *ngIf="asset.grants.length > 0" label="{{ 'research.grants.title.summary' | customTranslate }}">
    <p *ngFor="let grant of asset.grants; let i = index">
        {{ i + 1 }}. {{ grant.grantId }}, {{ grant.grantName }} ,
        {{ grant.funderCode | defaultTranslate : { default: grant.funderName } }}
    </p>
</exl-labeled-row>
