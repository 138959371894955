import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {RestApiBase} from '../../../shared/configurations/rest-api-base.config';

@Injectable()
export class DepositLanguageService {

    constructor(private http: HttpClient) {
    }

    getLanguages(){
        return this.http.get(RestApiBase.LANGUAGES_LOOKUP);
    }

    getThreeCharactersLang(language){
        const params = new HttpParams().set('language', language);
        return this.http.get(RestApiBase.LANGUAGES_THREE_CHARACTERS_CONVERSION, { params: params });
    }








}
