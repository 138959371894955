<ng-container [ngSwitch]="cmpType">
  <mat-chip-listbox
    *ngSwitchCase="'mat-chip-list'"
    cdkDropListGroup
    cdkDropListOrientation="horizontal"
    muloDropListA11y
    (muloDropListA11yMove)="onA11yMove($event)"
    (muloDropListA11yEditing)="onA11yEditing($event)"
  >
    <div
      *ngFor="let itemTemplate of muloDragTemplates; index as i; trackBy: trackItem;"
      class="item-list"
      cdkDropList
      cdkDropListOrientation="horizontal"
      [cdkDropListData]="muloDragTemplates"
      (cdkDropListDropped)="drop($event, i)"
      (cdkDropListEntered)="entered(i)"
      (cdkDropListSorted)="sorted($event, i)"
      class="drop-list"
    >
      <mat-chip-option
        cdkDrag
        (cdkDragStarted)="picked(i)"
        (cdkDragReleased)="released(i)"
        draggable="true"
        class="item-handle"
        [class.cdk-drag-preview]="dragging && i === pickedItem"
      >
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
      </mat-chip-option>
    </div>
  </mat-chip-listbox>
  <div
    *ngSwitchDefault
    cdkDropListGroup
    muloDropListA11y
    (muloDropListA11yMove)="onA11yMove($event)"
    (muloDropListA11yEditing)="onA11yEditing($event)"
    class="drop-list-wrapper"
  >
    <div
      *ngFor="let itemTemplate of muloDragTemplates; index as i; trackBy: trackItem;"
      class="item-list"
      cdkDropList
      cdkDropListOrientation="horizontal"
      [cdkDropListData]="muloDragTemplates"
      (cdkDropListDropped)="drop($event, i)"
      (cdkDropListEntered)="entered(i)"
      (cdkDropListSorted)="sorted($event, i)"
      class="drop-list"
    >
      <span
        cdkDrag
        (cdkDragStarted)="picked(i)"
        (cdkDragReleased)="released(i)"
        draggable="true"
        class="item-handle"
        [class.cdk-drag-preview]="dragging && i === pickedItem"
      >
        <ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
      </span>
    </div>
  </div>
</ng-container>
