import { Injectable } from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { State } from '../shared/configurations/state.constant';
import { UrlUtils } from '../shared/utils/url.utils';
import { filter } from 'rxjs';
import { Location } from '@angular/common';
import { SEOService } from './seo.service';

const NAV_HISTORY_LIMIT = 50;

@Injectable({ providedIn: 'root' })
export class RouterService {
    institutionCode = UrlUtils.getParam('institution');
    navHistory: string[] = [];

    constructor(
        private router: Router,
        private location: Location,
        private seoSvc: SEOService
    ) {
        router.events
            .pipe(
                filter(
                    (e: Event): e is NavigationStart =>
                        e instanceof NavigationStart
                )
            )
            .subscribe((e: NavigationStart) => {
                const nav = router.getCurrentNavigation();
                const state = this.location.getState() as any;
                const prevUrl =
                    !state?.skipRoot && !this.sameUrl(e.url)
                        ? router.url
                        : state?.prevUrl;
                if (prevUrl !== state?.prevUrl) {
                    if (this.navHistory.length >= NAV_HISTORY_LIMIT) {
                        // with no limit, we run the risk of memory bloat
                        this.navHistory.shift();
                    }
                    this.navHistory.push(prevUrl);
                }
                nav.extras.state = Object.assign({}, nav.extras.state, {
                    prevUrl,
                });
                if (!this.sameUrl(e.url) || !this.sameUrl(router.url)) {
                    seoSvc.removeSchemaDotOrgScript();
                }
            });
    }

    sameUrl(_navigateUrl: string) {
        const currUrl = this.location
            .path(true)
            .substring(0, this.location.path(true).indexOf('?'));
        const navigateUrl = _navigateUrl.substring(
            0,
            _navigateUrl.indexOf('?')
        );
        return currUrl === navigateUrl;
    }

    getCurrentRoute() {
        return this.router.url;
    }

    isProfileRoute() {
        return this.getCurrentRoute().indexOf(State.PROFILE) === 0;
    }

    isSearchRoute() {
        return this.getCurrentRoute().indexOf(State.SEARCH) === 0;
    }

    isLoginRoute() {
        return this.getCurrentRoute().indexOf(State.LOGIN) === 0;
    }

    isLoginAsRoute() {
        return this.getCurrentRoute().indexOf(State.LOGIN_AS) === 0;
    }
    isTombstoneRoute() {
        return this.getCurrentRoute().indexOf('/unavailable') === 0;
    }

    goToHomepage() {
        this.router.navigate([State.HOME_PAGE], {
            queryParams: { institution: this.institutionCode },
        });
    }

    goToResearchersSearch() {
        this.router.navigate([State.RESEARCHERS_SEARCH], {
            queryParams: { institution: this.institutionCode },
        });
    }

    goToProfile(path?: string) {
        this.router.navigate([State.PROFILE, path].filter(Boolean), {
            queryParams: { institution: this.institutionCode },
        });
    }

    goToEntity(key: string) {
        this.router.navigate([key], {
            queryParams: { institution: this.institutionCode },
        });
    }

    getPrevUrl() {
        const prevUrl = this.navHistory[this.navHistory.length - 1];
        return prevUrl === '/' ? null : this.router.parseUrl(prevUrl);
    }

    goBack() {
        this.navHistory.pop();
        this.location.back();
    }
}
