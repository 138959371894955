import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ExportersDialogComponent } from './asset-exporter/asset-exporter.component';
import {
  ExportTarget,
  AssetExportConfig,
  ExportAction,
  ExportChangeEvent,
  TargetOptionSelect,
} from './exporter.model';
import { Subject, Observable, merge, take } from 'rxjs';
const exportDialogDefaults = {
  width: '820px',
};

@Injectable({
  providedIn: 'root',
})
export class ExportersService {
  private _currentTarget: ExportTarget;

  private _outputPreview = new Subject<string>();
  public outputPreview$ = this._outputPreview.asObservable();

  private _targetActions = new Subject<ExportAction[]>();
  public targetActions$ = this._targetActions.asObservable();

  private _targetChanged = new Subject<{
    event: ExportChangeEvent;
    target: ExportTarget;
  }>();
  public targetChanged$ = this._targetChanged.asObservable();

  private _actionClicked = new Subject<{
    event: ExportChangeEvent;
    target: ExportTarget;
    action: ExportAction;
  }>();
  public actionClicked$ = this._actionClicked.asObservable();

  private _actionSuccess = new Subject<{
    event: ExportChangeEvent;
    target: ExportTarget;
    action: ExportAction;
  }>();
  public actionSuccess$ = this._actionSuccess.asObservable();

  private _dialogClosed = new Subject<{ event: ExportChangeEvent }>()
  public dialogClosed$ = this._dialogClosed.asObservable();

  private _outputCopied = new Subject<{ event: ExportChangeEvent }>()
  public outputCopied$ = this._outputCopied.asObservable();

  constructor(private dialog: MatDialog) {}

  /** Cal the ASSET export dialog */
  public showAssetExportDialog(config: AssetExportConfig): Observable<any> {
    this.openExportDialog({ exportType: 'asset', ...config });
    return merge(
      this.targetChanged$,
      this.actionClicked$,
      this.actionSuccess$,
      this.dialogClosed$,
      this.outputCopied$);
  }

  /** Call the export dialog */
  public openExportDialog(
    config: AssetExportConfig,
    width: string = exportDialogDefaults.width
  ) {
    this.dialog.open(ExportersDialogComponent, {
      panelClass: 'mulo-exporters-dialog-container',
      width: width,
      data: {
        // onActionClick: this.onActionClick,
        // onTargetChanged: this.onTargetChanged,
        ...config,
      },
    })
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => this.onDialogClosed());
  }

  /** Call to update the output preview */
  public updatePreview(content: string) {
    this._outputPreview.next(content);
  }

  /** Call to update the exporter main actions */
  public updateActions(actions: ExportAction[]) {
    this._targetActions.next(actions);
  }

  /** When an export target changes */
  public onTargetChanged(target: ExportTarget) {
    this._currentTarget = target;
    this._targetChanged.next({
      event: 'targetChange',
      target: target,
    });
  }

  /** When a main action was clicked */
  public onActionClick(action: ExportAction) {
    this._actionClicked.next({
      event: 'actionClick',
      action: action,
      target: this._currentTarget,
    });
  }
  /** When a main action returned successfully */
  public onActionSuccess(action: ExportAction) {
    this._actionSuccess.next({
      event: 'actionSuccess',
      action: action,
      target: this._currentTarget,
    });
  }

  /** Export Dialog closed */
  public onDialogClosed() {
    this._dialogClosed.next({
      event: 'dialogClosed'
    });
  }

  /** Output copied */
  public onOutputCopied() {
    this._outputCopied.next({
      event: 'outputCopied',
    });
  }

  // DEMO STUFF ONLY FROM HERE
  demoConvert(change: ExportTarget, content: any[]) {
    let output = ``;
    for (let i = 0; i < content.length; i++) {
      let src = content[i];
      if (change.value == 'bibtex')
        output = this.trimCommasOrSpaces(
          output,
          i,
          this.demoCitationToBibtex(src)
        );

      if (change.value == 'ris')
        output = this.trimCommasOrSpaces(
          output,
          i,
          this.demoCitationToRis(src)
        );

      if (change.value == 'citation') {
        const style = change.targetOptions.find((o) => o.value === 'style');
        if (style.type === 'select') {
          output = this.trimCommasOrSpaces(
            output,
            i,
            this.demoCitationFormatting(src, (style as TargetOptionSelect).selected)
          ) + '<br><br>';
        }
      }

      if (change.value == 'email')
        output = this.trimCommasOrSpaces(
          output,
          i,
          this.demoCitationToEmail(src)
        );
    }
    return output;
  }

  demoCitationFormatting(asset, style) {
    const a = asset;

    const formatted = {
      apa6: () =>
        `${a.authors[0].name}. "${a.title}." ${a.authors[0].afffiliation}, ${a.publication}`,
      mla7: () =>
        `${a.authors[0].name}. (2010) <i>${a.title}.</i> (p.${a.pages}) [${a.authors[0].afffiliation}], https://doi.org/${a.doi}`,
      default: () =>
        `${a.authors[0].name}. <i>${a.title}.</i> (p.${a.pages}) [${a.authors[0].afffiliation}]`,
    };

    return formatted[formatted[style] ? style : 'default']();
  }

  demoCitationToBibtex(citation) {
    const c = citation;

    const attributes = ['title', 'publisher', 'year', 'volume', 'pages'];

    let bib = `@${this.getType(c.type)}{somekey,${this.authorsCommaSep(
      c.authors
    )}`;

    for (let i = 0; i < attributes.length; i++) {
      const attr = attributes[i];
      bib = `${bib}${this.getAttribute(attr, c[attr])}`;
    }

    return `${bib}
}`;
  }

  demoCitationToRis(citation) {
    const c = citation;
    let ris = `TY - ${this.getType(c.type).slice(0, 4).toUpperCase()}
TI - ${c.title}${this.authorsLineSep(c.authors)}
ER -`;

    return `${ris}`;
  }

  demoCitationToEmail(c) {
    const subject = 'Some example subject - can be anything really';
    const footer = 'Powered by Esploro!';
    const lines = [
      c.authors?.map((x) => x.name?.trim()).join(', '),
      `${c.publication}, ${c.volume}(${c.issue}):${c.pages}`,
    ];
    const str =
      c.title.toUpperCase() + '\n' + lines.join('\n') + '\n\n' + footer;

    const url = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(str)}`;

    return str;
  }

  trimCommasOrSpaces(content, index, fn) {
    return index == 0
      ? `${fn}
`
      : `${content}
${fn}
`;
  }

  getType(type) {
    let t = type.toLowerCase();
    if (t.includes('article')) return 'article';
    if (t.includes('book')) return 'book';
    if (t.includes('conference')) return 'conference';
    return type;
  }

  authorsLineSep(authors) {
    if (authors.length == 1) {
      return `AU - ${authors[0].name ? authors[0].name.trim() : ''}<br>`;
    } else {
      let a = ``;
      for (let i = 0; i < authors.length; i++) {
        const auth = authors[i].name ? authors[i].name.trim() : '';
        a = `${a}
A${i + 1} - ${auth}`;
      }
      return `${a}`;
    }
  }

  authorsCommaSep(authors) {
    let a = ``;
    for (let i = 0; i < authors.length; i++) {
      const auth = authors[i].name ? authors[i].name.trim() : '';
      a = i == 0 ? `${auth}` : `${a}, ${auth}`;
    }
    return `
    authors = "${a}",`;
  }

  getAttribute(attr, value) {
    return value && value.length
      ? `
    ${attr} = "${value}",`
      : ``;
  }
}
