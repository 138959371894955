import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { MediaIfDirective } from '@exl-ng/mulo-core';

import { AdvancedSearchComponent } from './advanced-search.component';
import { SharedModule } from '../../shared/shared.module';
import { DateInputModule } from '../date-input/date-input.module';

@NgModule({
    declarations: [AdvancedSearchComponent],
    imports: [
        CommonModule,
        SharedModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MediaIfDirective,
        DateInputModule,
    ],
    exports: [AdvancedSearchComponent],
})
export class AdvancedSearchModule {}
