import {
  Component,
  Inject,
  HostBinding,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DepositFormDataService } from '../../../deposit-form-data.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'exl-file-progress-dialog',
  templateUrl: './file-progress-dialog.component.html',
  styleUrls: ['./file-progress-dialog.component.scss'],
  host: { class: 'exl-file-progress-dialog' },
})
export class FileProgressDialogComponent implements OnInit, OnDestroy {
  @HostBinding('class.is-collapsed') panelCollapsed = false;

  uploadQueue = [];
  uploaderTitle = 'research.files.popup1';
  uploadQueueCount = 0;
  uploadDone = false;

  private filesDestroy = new Subject<void>();

  constructor(
    public depositFormDataService: DepositFormDataService,
    public dialogRef: MatDialogRef<FileProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.uploadQueue = this.data.files.filter(
      (obj) => obj.uploadProgress === 0
    );
  }

  ngOnInit() {
    this.subscribeToFileActivity();
  }

  setUploaderTitle() {
    if (this.uploadQueue.length === 0) {
      this.uploaderTitle = 'research.files.popup2';
      this.uploadDone = true;
      this.panelCollapsed = false;
    } else {
      this.uploaderTitle = 'research.files.popup1';
    }
  }

  subscribeToFileActivity() {
    this.depositFormDataService.files.valueChanges
      .pipe(takeUntil(this.filesDestroy))
      .subscribe((data) => {
        this.uploadQueue = data.filter(
          (obj) => obj.uploadProgress < 100
        );
        this.uploadQueueCount = this.uploadQueue.length;
        if (this.uploadQueueCount === 0) {
          setTimeout(() => {
            this.close();
          }, 2000);
        }
        this.setUploaderTitle();
      });
  }

  togglePanelSize() {
    this.panelCollapsed = !this.panelCollapsed;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (!this.filesDestroy.isStopped) {
      this.filesDestroy.next();
      //unsubscribe from the subject itself
      this.filesDestroy.unsubscribe();
    }
  }

  getAriaLabelForPanelButton() {
    if (this.panelCollapsed) {
      return 'research.aria.deposit.upload.files.progress.panel.open';
    }
    return 'research.aria.deposit.upload.files.progress.panel.close';
  }
}
