export class ResearcherName {
    firstName: string;
    lastName: string;
    middleName: string;
    nameSuffix: string;
    isDefaultName?: boolean;

    constructor(first, middle, last, suffix, isDefaultName = false){
        this.firstName = first;
        this.middleName = middle;
        this.lastName = last;
        this.nameSuffix = suffix;
        this.isDefaultName = isDefaultName;
    }
}
