import { NgModule } from '@angular/core';
import { FunkyButtonComponent } from '@exl-ng/mulo-common';
import { SharedModule } from '../../shared/shared.module';
import { AddActivitiesComponent } from './add-activities.component';

@NgModule({
    imports: [SharedModule, FunkyButtonComponent],
    declarations: [AddActivitiesComponent],
    exports: [AddActivitiesComponent],
})
export class AddActivityModule {}
