import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AdvancedSearchService {

    constructor() {}

    getSearchTerm(elements: string[]){
        let searchTerm = elements[2];
        if (elements.length > 4) {
            let newArr = elements.slice(2,elements.length-1);
            searchTerm = newArr.join(',');
        }
        return searchTerm;
    }


}
