<mat-chip-set [attr.aria-label]="activeItemsAriaLabel" role="group">
  <div *ngFor="let item of activeItems; index as i; trackBy: trackByFn" @widthOutAnimation @widthInAnimation>
    <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item, index: i }"> </ng-container>
  </div>
  <ng-template #itemTemplate let-item="item" let-index="index">
    <mat-chip
      #chip
      [class.is-hovered]="hoveredItem === index"
      [class.is-focused]="focusedItem === index"
      [class.is-excluded]="item.excluded"
      [class.is-locked]="item.locked"
      [attr.aria-label]="item.ariaLabel || item.label"
      [disabled]="disabled"
      [disableRipple]="true"
      (removed)="handleRemove(item)"
      (mouseenter)="hoveredItem = index"
      (mouseleave)="hoveredItem = null"
    >
      <span class="chip-label" *ngIf="!item.excluded">{{ item.label }}</span>
      <del *ngIf="item.excluded" #del cdkAriaLive="assertive">
        <span class="cdk-visually-hidden">{{ excludedA11yLabel }}</span>
        <span class="chip-label">{{ item.label }}</span>
      </del>
      <span class="cdk-visually-hidden">{{ removeBtnAriaLabel }}</span>
      <div class="lock-button-wrapper">
        <button
          mat-icon-button
          class="lock-button"
          *ngIf="lockable"
          [attr.aria-label]="item.label + ' ' + lockBtnAriaLabel"
          [class.is-visible]="hoveredItem === index"
          (click)="handleLock(item)"
          (focus)="focusedItem = index"
          (blur)="focusedItem = null"
          (mouseenter)="item.lockHover = item.locked"
          (mouseleave)="item.lockHover = !item.locked"
        >
          <mat-icon muloSvgViewbox [svgIcon]="item.lockHover ? 'lock-open-outline' : 'lock-outline'"></mat-icon>
        </button>
      </div>
      <button
        matChipRemove
        muloFacetChip
        [attr.aria-labelledby]="chip.id"
        (focus)="focusedItem = index"
        (blur)="focusedItem = null"
      >
        <mat-icon muloSvgViewbox svgIcon="close"></mat-icon>
      </button>
    </mat-chip>
  </ng-template>
  <div *ngIf="showActions" class="actions">
    <button mat-button class="has-icon" (click)="handleRememberAllClick()" *ngIf="lockable" [disabled]="disabled">
      <mat-icon svgIcon="lock-outline"></mat-icon>
      <span>{{ rememberAllLabel }}</span>
    </button>
    <button mat-button class="has-icon" (click)="handleResetClick()" [disabled]="disabled">
      <mat-icon class="mat-icon-rtl-mirror" svgIcon="refresh"></mat-icon>
      <span>{{ resetLabel }}</span>
    </button>
  </div>
</mat-chip-set>
