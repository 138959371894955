import {CodeLabelEntry} from '../interfaces/code-label-entry.interface';
import { Grant } from '../interfaces/grant.model';
import {CreatorContributor} from '../interfaces/creator-contributor.interface';
import {PersonOrganizationAffiliationDataWrapper} from '../interfaces/person-organization-affiliation-data-wrapper.model';

export class SortUtils {

    public static alphabeticallySort(a:string, b:string): number {
        if (a.toLowerCase() < b.toLowerCase()) {
            return -1;
        } else if (a.toLowerCase() > b.toLowerCase()) {
            return 1;
        }
        return 0;
    }

    public static sortByDescription(a:CodeLabelEntry, b:CodeLabelEntry): number {
        if (a.description < b.description) {
            return -1;
        } else if (a.description > b.description) {
            return 1;
        }
        return 0;
    }

    public static grantSortByName(a: Grant, b:Grant): number {
        if (a.grantName < b.grantName) {
            return -1;
        } else if (a.grantName > b.grantName) {
            return 1;
        }
        return 0;
    }


    public static authorSortByOrder(a: CreatorContributor, b:CreatorContributor): number {
        if (a.order < b.order) {
            return -1;
        } else if (a.order > b.order) {
            return 1;
        }
        return 0;
    }

    public static labelSort(a:string[], b:string[]): number {
        if (a[1] < b[1]) {
            return -1;
        } else if (a[1] > b[1]) {
            return 1;
        }
        return 0;
    }

    public static affiliationSortByOrder(a: PersonOrganizationAffiliationDataWrapper, b: PersonOrganizationAffiliationDataWrapper){
        if (a.data.positionOrder < b.data.positionOrder) {
            return -1;
        } else if (a.data.positionOrder > b.data.positionOrder) {
            return 1;
        }
        return 0;
    }

}
