<div
  mulo-file-drop
  (filesDroppedEmitter)="onFilesDropped($event)"
  (filesInvalidEmitter)="onFileInvalids($event)"
  (dragOverEmitter)="onDragOver($event)"
  [ngClass]="{'is-minimal': minimal, 'is-hidden': hidden }"
  [multiple]="multiple"
>
  <div class="inner">
    <ng-container *ngIf="!dragOverActive">
      <div class="label">
        {{multiple ? multiFilesDropLabel : singleFileDropLabel}}
      </div>
      <button
        mat-stroked-button
        mat-raised-button
        class="has-icon has-file-input is-large"
        (click)="fileRequester.click()"
        #fileUploadButton
      >
        <span>{{multiple ? multiSelectBtnLabel : singleSelectBtnLabel}}</span>
      </button>
      <input
        type="file"
        tabindex="-1"
        style="display: none"
        [accept]="allowedExtensions"
        [multiple]="multiple"
        (change)="onFilesSelected($event)"
        #fileRequester
        (click)="$event.stopPropagation()"
        attr.aria-label="{{multiple ? multiSelectBtnLabel : singleSelectBtnLabel}}"
      />
    </ng-container>
    <div *ngIf="dragOverActive" class="label">
      {{multiple ? multiDropActionLabel : singleDropActionLabel}}
    </div>
  </div>
</div>

<div *ngIf="fileList.length" @scaleInOut>
  <div class="files-list-message" *ngIf="showMultiFileWarning">
    <span class="message-content mulo-color-warn">
      <mat-icon svgIcon="alert-outline"></mat-icon>
      <span>{{typeChangedLimitMsg}}</span>
    </span>
  </div>
</div>

<div *ngIf="invalidFiles.length" class="invalid-files">
  <mat-list>
    <h3 mat-subheader>{{invalidFilesHeaderLabel}}</h3>
    <mat-list-item *ngFor="let file of invalidFiles">
      <h4 mat-line>{{ file.name }}</h4>
    </mat-list-item>
  </mat-list>
</div>
