<mat-form-field floatLabel="auto" class="is-comfortably-readable">
    <mat-label>
        <ng-container *ngIf="label; else fixedLabel">{{ label }}</ng-container>
        <ng-template #fixedLabel>{{ 'research.language.overline' | customTranslate }}</ng-template>
    </mat-label>

    <mat-chip-grid #chipGrid>
        <mat-chip-row
            *ngFor="let chip of displayLanguages; first as isFirst; let i = index"
            cdkMonitorSubtreeFocus
            [removable]="removable"
            (removed)="removeChip(i, chip)"
            [class.is-autopopulated]="isAutoPopulated(chip)"
            @widthInAnimation
            @widthOutAnimation
        >
            <exl-badge-auto-populated
                *ngIf="isAutoPopulated(chip)"
                [tooltip]="'research.auto.populate.tooltip' | customTranslate"
                color="primary"
            >
            </exl-badge-auto-populated>
            <span class="chip-content semibold">{{ chip | translate }}</span>
            <button
                mat-icon-button
                matChipRemove
                *ngIf="removable"
                [attr.aria-label]="('research.aria.deposit.languages.remove' | translate) + ' ' + (chip | translate)"
            >
                <mat-icon svgIcon="close"></mat-icon>
            </button>
        </mat-chip-row>
    </mat-chip-grid>
    <input
        matInput
        (keydown.enter)="$event.preventDefault()"
        [formControl]="autoCompleteChipList"
        [required]="this.depositFormDataService.languages.invalid"
        #autoCompleteTrigger
        [matAutocomplete]="auto"
        (focus)="openAutoComplete()"
        [matChipInputFor]="chipGrid"
    />
    <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="optionSelected($event, autoCompleteTrigger)"
        [hideSingleSelectionIndicator]="false"
    >
        <mat-option *ngFor="let option of filteredOptions" [value]="option[0]">
            <span [innerHTML]="option[1] | highlight : autoCompleteChipList.value"></span>
        </mat-option>
    </mat-autocomplete>
    <mat-error
        *ngIf="this.depositFormDataService.languages?.invalid"
        [innerHTML]="this.depositFormDataService.languages?.errors | errorTranslate"
    >
    </mat-error>
</mat-form-field>
