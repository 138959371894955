import { Component } from '@angular/core';
import { ConfigurationHandlerService } from '../core/configuration-handler.service';
import { SEOService } from '../core/seo.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'esp-tombstone-page',
    templateUrl: './tombstone-page.component.html',
    styleUrls: ['./tombstone-page.component.scss'],
})
export class TombstonePageComponent {
    constructor(
        configSvc: ConfigurationHandlerService,
        seoSvc: SEOService,
        xl8: TranslateService
    ) {
        xl8.get('research.search.document.title', {
            value: configSvc.getMainInstitutionName(),
        }).subscribe((res) => seoSvc.updateTitle(res));
    }
}
