import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import { KeywordItemComponent } from './keyword-item/keyword-item.component';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
    selector: 'mulo-keywords',
    templateUrl: './keywords.component.html',
    styleUrls: ['./keywords.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'mulo-keywords',
        '[class.as-separators]': 'this.flavor === "separators"',
        '[class.as-blocks]': 'this.flavor === "blocks"',
        '[class.as-tags]': 'this.flavor === "tags"',
    },
    standalone: true,
    imports: [
        NgIf,
        MatIcon,
        SvgViewboxDirective,
    ],
})
export class KeywordsComponent implements AfterContentInit {
  @Input() icon: string | 'none' = 'tag-multiple';
  @Input() rtlMirrorIcon: boolean;
  @Input() flavor: 'blocks' | 'separators' | 'tags' = 'separators';

  @ContentChildren(KeywordItemComponent) items: QueryList<KeywordItemComponent>;

  constructor() {
    if (this.flavor === 'blocks' || this.flavor === 'tags') {
      this.icon = null;
    }
  }

  ngAfterContentInit() {
    this.items.forEach((keyword) => {
      if (this.flavor === 'tags') {
        keyword.showIcon = true;
      }

      if (this.flavor === 'blocks') {
        keyword.setFlavor('block');
      }
    });
  }

  showIcon() {
    return this.flavor !== 'tags';
  }
}
