import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DepositFormService } from '../../../deposit-form.service';
import { DepositFormDataService } from '../../../deposit-form-data.service';
import { SortUtils } from '../../../../shared/utils/sort.utils';
import { ConfigurationHandlerService } from '../../../../core/configuration-handler.service';
import {
    GrowInAnimation,
    GrowOutAnimation,
    ScaleInOutAnimation,
} from '@exl-ng/mulo-common';
import { MappingTable } from '../../../../shared/interfaces/mapping-table.interface';
import { DepositForm } from '../../../../shared/configurations/deposit-form.constant';
import { TranslateService } from '@ngx-translate/core';
import { AnnouncerService } from '../../../../core/announcer.service';
import { UntypedFormArray } from '@angular/forms';
import { DepositAuthorsService } from '../deposit-authors.service';
import { User } from '../../../../shared/interfaces/user.model';

@Component({
    selector: 'esp-authors-list',
    templateUrl: './authors-list.component.html',
    styleUrls: ['./authors-list.component.scss'],
    animations: [ScaleInOutAnimation, GrowInAnimation, GrowOutAnimation],
    host: { class: 'esp-authors-list' },
})
export class AuthorsListComponent implements OnInit {
    @Input() authors: UntypedFormArray;
    @Output() enterNewPersonChange = new EventEmitter();
    @Output() editAuthor = new EventEmitter<User>();
    roles: string[] = [];
    rolesTranslations = [];

    trackByUserID = (index: number, author: any) => author.id;

    constructor(
        private depositFormService: DepositFormService,
        public depositFormDataService: DepositFormDataService,
        private depositAuthorsService: DepositAuthorsService,
        private configSvc: ConfigurationHandlerService,
        private announcerService: AnnouncerService,
        private translate: TranslateService,
    ) {}

    ngOnInit() {
        const resourceTypeTemplate =
            this.depositFormDataService.resourceTypeTemplate;
        const isDefaultForm = resourceTypeTemplate === DepositForm.DEFAULT_TYPE;

        const esploroContributorTypes = this.configSvc.getMappingTable(
            'EsploroContributorTypes',
        );
        const roleFilter = (row: MappingTable) =>
            row.enabled === 'true' &&
            this.relevantForResourceType(row.source1, isDefaultForm);
        this.roles =
            esploroContributorTypes
                ?.filter(roleFilter)
                .map((row) => row.target) || [];
        this.translateRolesAndSort();
    }

    relevantForResourceType(source1: string, isDefaultForm: boolean) {
        const category = this.depositFormDataService.category?.value;
        const isEtdType = this.depositFormDataService.type?.value?.startsWith(
            DepositForm.ETD_TYPE,
        );
        return (
            (isDefaultForm && !isEtdType && !this.isForEDTOnly(source1)) ||
            (isEtdType && this.isIncludeETD(source1)) ||
            source1.includes('ALL') ||
            (category && source1.includes(category))
        );
    }

    translateRolesAndSort() {
        if (this.roles?.length > 0) {
            this.translate.get(this.roles).subscribe((res) => {
                this.rolesTranslations = Object.entries(res);
                this.rolesTranslations.sort(SortUtils.labelSort);
            });
        }
    }

    isForEDTOnly(resourceTypes: string) {
        const resourceTypesArray = resourceTypes
            .split(',')
            .map((rt) => rt.trim());
        const isETDOnly = resourceTypesArray.every((s1) =>
            s1.startsWith(DepositForm.ETD_PREFIX),
        );
        return isETDOnly;
    }

    isIncludeETD(resourceTypes: string) {
        const resourceTypesArray = resourceTypes
            .split(',')
            .map((rt) => rt.trim());
        const isIncludeETD = resourceTypesArray.some((s1) =>
            s1.startsWith(DepositForm.ETD_PREFIX),
        );
        return isIncludeETD;
    }

    onAuthorRemove(index) {
        const { value } = this.authors?.at(index);
        this.authors?.removeAt(index);
        this.announcerService.annonuceRemove(value?.name);
        this.depositAuthorsService.removeUserId(value.id);
    }

    onAuthorShift(index, direction) {
        const removedItem = this.authors?.at(index);
        const newPos = direction === 'up' ? index - 1 : index + 1;
        this.authors?.removeAt(index);
        this.authors?.insert(newPos, removedItem);
    }

    isAutoPopulated(id) {
        return (
            this.depositFormDataService.authorsAutoPopulated.indexOf(id) !== -1
        );
    }

    changeRole(index, role) {
        // this.depositFormDataService.changeRole(index, role);
        const author = this.authors.at(index);
        author.patchValue({ role });
    }
}
