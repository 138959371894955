import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
    selector: 'mulo-floating-toolbar',
    templateUrl: './floating-toolbar.component.html',
    styleUrls: ['./floating-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'mulo-floating-toolbar',
        '[class.has-background]': 'background === "opaque"',
        '[class.has-shadow]': 'shadow',
        '[class.is-disabled]': 'disabled',
    },
    standalone: true,
})
export class FloatingToolbarComponent implements OnInit {

  @Input() shadow: boolean = false;
  @Input() background: 'transparent' | 'opaque' = 'opaque';
  @Input() disabled: boolean = false

  constructor() {}

  ngOnInit() {}
}
