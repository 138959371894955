import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import {mixinColor} from '../../shared/common-behavior/color';
import {MatMenuTrigger} from '@angular/material/menu';

class OnboardingComponentBase {
    constructor (public _elementRef: ElementRef) { }
}

const _OnboardingComponentMixinBase = mixinColor(OnboardingComponentBase);

@Component({
    selector: 'exl-common-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent extends _OnboardingComponentMixinBase implements OnInit {

    guideShowDelay = 600;
    @Input() text: string;
    @Input() align: 'left' | 'right' | 'center' = 'left';
    @Input() textOffset: number;
    @Input() buttonText = 'research.header.proxy.menu.message.go.it';
    @Output() guideClosed: EventEmitter<null> = new EventEmitter();

    @ViewChild('guideTextTrigger', {static: true}) guideTextTrigger: MatMenuTrigger;

    constructor (elementRef: ElementRef) {
        super(elementRef);
    }

    ngOnInit(){
        setTimeout(() => {
            this.guideTextTrigger.openMenu();
        }, this.guideShowDelay);
    }

    onGuideTextClosed() {
        this.guideClosed.emit(null);
    }

    get offsetTextPosition(){
        const off = this.textOffset;
        return this.align === 'left' ? `-${off}rem` : `${off}rem`;
    }

}
