<div>
    <div>
        <mat-form-field
            [muloInputExpander]="depositFormDataService.conferenceName.value"
            class="is-comfortably-readable"
            [class.is-autopopulated]="isAutoPopulated('conferenceName')"
        >
            <input
                matInput
                exlAutomationClass="conference-name"
                [formControl]="depositFormDataService.conferenceName"
            />
            <mat-label>{{
                'research.conference.name' | customTranslate
            }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('conferenceName')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
        </mat-form-field>
        <mat-form-field class="is-comfortably-readable">
            <input
                matInput
                exlAutomationClass="conference-number"
                [formControl]="depositFormDataService.conferenceNumber"
            />
            <mat-label>{{
                'research.conference.number' | customTranslate
            }}</mat-label>
        </mat-form-field>
        <mat-form-field
            [muloInputExpander]="
                depositFormDataService.conferenceLocation.value
            "
            class="is-comfortably-readable"
        >
            <input
                matInput
                exlAutomationClass="conference-location"
                [formControl]="depositFormDataService.conferenceLocation"
            />
            <mat-label>{{
                'research.conference.location' | customTranslate
            }}</mat-label>
        </mat-form-field>
    </div>

    <div>
        <mat-form-field
            [class.is-autopopulated]="isAutoPopulated('relationTitle')"
            class="is-comfortably-readable"
            *ngIf="!isConference() && !isPublicationAbstract()"
            [muloInputExpander]="depositFormDataService.relationTitle.value"
        >
            <input
                matInput
                exlAutomationClass="conference-relation-title"
                [formControl]="depositFormDataService.relationTitle"
            />
            <mat-label>{{
                'research.conference.title' | customTranslate
            }}</mat-label>
            <mat-icon
                matPrefix
                *ngIf="isAutoPopulated('relationTitle')"
                svgIcon="auto-populate"
                matTooltip="{{
                    'research.auto.populate.tooltip' | customTranslate
                }}"
            ></mat-icon>
        </mat-form-field>
        <div>
            <esp-deposit-dates
                [range]="true"
                [dateHint]="true"
                [fromDate]="depositFormDataService.conferenceStartDate"
                [toDate]="depositFormDataService.conferenceEndDate"
            >
            </esp-deposit-dates>
        </div>
    </div>
</div>
