import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

type ButtonState = 'open' | 'closed';

@Component({
    selector: 'mulo-menu-toggle-button',
    templateUrl: './menu-toggle-button.component.html',
    styleUrls: ['./menu-toggle-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'mulo-menu-toggle-button' },
    standalone: true,
    imports: [
        MatIconButton,
        MatIcon,
        SvgViewboxDirective,
    ],
})
export class MenuToggleButtonComponent implements OnInit {
  @Input() state: ButtonState = 'closed';
  @Input() openIcon = 'menu';
  @Input() closeIcon = 'menu-open';
  @Input() openText: string | null = 'Open Menu';
  @Input() closeText: string | null = 'Close Menu';

  get isClosed() {
    return this.state === 'closed';
  }
  @Output() clicked: EventEmitter<ButtonState> = new EventEmitter();

  ngOnInit(): void {}

  onClick() {
    this.clicked.emit(this.state);
    // this.toggleState()
  }

  public toggleState() {
    this.state === 'open' ? (this.state = 'closed') : (this.state = 'open');
  }
}
