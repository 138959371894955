import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {TranslatePipe, TranslateService} from '@ngx-translate/core';
import {DepositFormDataService} from '../../deposit/deposit-form-data.service';
import {StorageUtilService} from '../../core/storage-util.service';

@Pipe({
    name: 'customTranslate',
    pure: false
})

export class CustomTranslatePipe extends TranslatePipe implements PipeTransform{

    readonly DEFAULT_AFFILIATION = 'default';
    readonly DEFAULT_RESOURCE_TYPE = 'default';
    readonly CODE_DELIMITER = '.';
    readonly RESOURCE_TYPE_DELIMITER = '.';

    constructor(private translateService: TranslateService, _ref: ChangeDetectorRef,
                private depositFormDataService: DepositFormDataService,
                private storageUtilService: StorageUtilService) {
        super(translateService, _ref);
    }

    //@measureTime('custom translate pipe')
    transform(query: string, ...args: any[]): any {
        try {
            const mainAffiliation = this.storageUtilService.getItem('user_main_affiliation');
            const resourceType = this.depositFormDataService.resourceType as string;
            const category = resourceType.split(this.RESOURCE_TYPE_DELIMITER)[0];
            const type = resourceType.split(this.RESOURCE_TYPE_DELIMITER)[1];
            let result = this.getResourceTypeAffiliationTranslation(query, type, mainAffiliation, args);
            if (!result) {
                result = this.getGeneralTypeAffiliationTranslation(query, category, mainAffiliation, args);
                if (!result) {
                    result = this.getResourceTypeDefaultAffiliationTranslation(query, type, args);
                    if (!result) {
                        result = this.getGeneralTypeDefaultAffiliationTranslation(query, category, args);
                        if (!result) {
                            result = this.getDefaultResourceTypeAffiliationTranslation(query, mainAffiliation, args);
                            if (!result) {
                                result = this.getDefaultTranslation(query, args);
                            }
                        }
                    }
                }
            }
            return result;
        } catch (e) {
            return super.transform(query, ...args);
        }

    }

    getResourceTypeAffiliationTranslation(query, type, affiliation, args){
        if (type && affiliation){
            const modifiedQuery = query + this.CODE_DELIMITER + type + this.CODE_DELIMITER + affiliation;
            return super.transform(modifiedQuery, ...args);
        }
        return '';
    }

    getGeneralTypeAffiliationTranslation(query, generalType, affiliation, args){
        if (generalType && affiliation){
            const modifiedQuery = query + this.CODE_DELIMITER + generalType + this.CODE_DELIMITER + affiliation;
            return super.transform(modifiedQuery, ...args);
        }
        return '';
    }

    getResourceTypeDefaultAffiliationTranslation(query, type, args){
        if (type){
            const modifiedQuery = query + this.CODE_DELIMITER + type + this.CODE_DELIMITER + this.DEFAULT_AFFILIATION;
            return super.transform(modifiedQuery, ...args);
        }
        return '';
    }

    getGeneralTypeDefaultAffiliationTranslation(query, generalType, args){
        if (generalType){
            const modifiedQuery = query + this.CODE_DELIMITER + generalType + this.CODE_DELIMITER + this.DEFAULT_AFFILIATION;
            return super.transform(modifiedQuery, ...args);
        }
        return '';

    }

    getDefaultResourceTypeAffiliationTranslation(query, affiliation, args){
        if (affiliation){
            const modifiedQuery = query + this.CODE_DELIMITER + this.DEFAULT_RESOURCE_TYPE + this.CODE_DELIMITER + affiliation;
            return super.transform(modifiedQuery, ...args);
        }
        return '';
    }

    getDefaultTranslation(query, args){
        const modifiedQuery = query + this.CODE_DELIMITER + this.DEFAULT_RESOURCE_TYPE + this.CODE_DELIMITER + this.DEFAULT_AFFILIATION;
        return super.transform(modifiedQuery, ...args);
    }


}
