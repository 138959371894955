import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';

@Component({
    selector: 'mulo-orcid-logo',
    templateUrl: './orcid-logo.component.html',
    styleUrls: ['./orcid-logo.component.scss'],
    host: { class: 'mulo-orcid-logo' },
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class OrcidLogoComponent implements OnInit {
  @Input() bgColor: string | null = null;
  @Input() size: number = 24;

  constructor() {}

  ngOnInit(): void {}
}
