import {
  Component,
  OnInit,
  HostBinding,
  Input,
  ElementRef,
  ContentChild,
  Renderer2,
  AfterContentInit,
} from '@angular/core';
import { ColorComponentMixinBase } from '../../common-behavior/color';
import { BrandComponent } from '../brand/brand.component';

@Component({
    selector: 'mulo-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
})
export class HeaderComponent extends ColorComponentMixinBase
  implements OnInit, AfterContentInit {
  //extends _HeaderComponentMixinBase
  @Input() themeColor: 'primary' | 'accent' | 'secondary' | 'tertiary' | 'none';
  @Input() backgroundColor: string;
  @Input() textColor: string;
  @Input() height = 64;
  @HostBinding('class') hostThemeClass = 'mulo-header';
  @HostBinding('style.backgroundColor') customBackgroundColor;
  @HostBinding('style.color') customTextColor;

  @ContentChild(BrandComponent, { static: false, read: ElementRef })
  logo: ElementRef;

  constructor(elementRef: ElementRef, private renderer: Renderer2) {
    super(elementRef);
  }

  ngOnInit() {
    if (this.themeColor) {
      this.setHostThemeClass();
    } else if (this.backgroundColor || this.textColor) {
      this.setCustomColors();
    }
  }

  ngAfterContentInit() {
    if (this.logo?.nativeElement?.parentNode?.localName === 'a') {
      this.renderer.addClass(
        this.logo.nativeElement.parentNode.parentNode,
        'brand-wrapper'
      );
    }
  }

  setCustomColors() {
    this.customBackgroundColor = this.backgroundColor;
    this.customTextColor = this.textColor;
  }

  setHostThemeClass() {
    if (this.themeColor === 'none') {
      this.hostThemeClass = 'hasnt-background';
    } else {
      this.hostThemeClass = `palette-${this.themeColor}`;
    }
  }
}
