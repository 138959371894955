import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgTemplateOutlet } from '@angular/common';

let uniqueId = 0;

@Component({
    selector: 'mulo-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss'],
    host: {
        '[class.is-expanded]': 'expanded',
        role: 'row',
    },
    standalone: true,
    imports: [
        NgIf,
        MatIcon,
        SvgViewboxDirective,
        MatButton,
        NgTemplateOutlet,
    ],
})
export class ExpansionPanelComponent extends CdkAccordionItem {
  @Input() headerLabel;
  @ViewChild('body') bodyContent;

  readonly _id: string;

  constructor(
    @Optional() @SkipSelf() accordion: CdkAccordion,
    _changeDetectorRef: ChangeDetectorRef,
    _uniqueSelectionDispatcher: UniqueSelectionDispatcher
  ) {
    super(accordion, _changeDetectorRef, _uniqueSelectionDispatcher);
    this._id = `${uniqueId++}`;
  }

  get hasBodyContent() {
    return this.bodyContent?.nativeElement?.children?.length > 0;
  }

  preventDrag(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
    ev.target.dispatchEvent(new MouseEvent('click', { ...ev }));
  }
}
