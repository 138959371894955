import {
  Pipe,
  PipeTransform,
  NgModule,
  LOCALE_ID,
  Inject,
} from '@angular/core';
import { formatNumber } from '@angular/common';
import { isEmpty, strToNumber, digitsInfoParse } from '../utils';

@Pipe({
    name: 'fileSize',
    standalone: true
})
export class FileSizePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private _locale: string) {}

  transform(
    value: number,
    digitsInfo: string = '1.2-2',
    locale: string = this._locale
  ): string {
    const k = 1024,
      sizes = ['byte', 'kilobyte', 'megabyte', 'gigabyte'],
      i = Math.floor(Math.log(value) / Math.log(k));

    if (isEmpty(value)) return null;
    const scaledValue = value / Math.pow(k, i);

    try {
      let options: Intl.NumberFormatOptions = {
        style: 'unit',
        unit: sizes[i] || 'byte',
        unitDisplay: 'short',
        minimumIntegerDigits: digitsInfoParse(digitsInfo)[0],
        minimumFractionDigits: digitsInfoParse(digitsInfo)[1],
        maximumFractionDigits: digitsInfoParse(digitsInfo)[2],
      };
      return new Intl.NumberFormat(locale, options).format(scaledValue);
    } catch (error) {
      console.error(`Intl.NumberFormat Error: '${error.message}'`);
      try {
        const sizeUnitString = {
          byte: ' bytes',
          kilobyte: ' kB',
          megabyte: ' MB',
          gigabyte: ' GB',
        }[sizes[i] || 'byte'];

        const num = strToNumber(scaledValue);
        return formatNumber(num, locale, digitsInfo) + sizeUnitString;
      } catch (error) {
        throw Error(`FileSizePipe Error: '${error.message}'`);
      }
    }
  }
}

