import {
  Attribute,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgModule,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
    selector: '[muloMultipleEvents]',
    standalone: true,
})
export class MultipleEventsDirective implements OnInit, OnDestroy {
  @Output() muloMultipleEvents: EventEmitter<any> = new EventEmitter();
  @Input() eventOrigins = 'keydown.enter,keydown.space';
  @HostBinding() tabIndex: number;
  private unlisteners = [];

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    @Attribute('tabindex') tabIndex: string
  ) {
    this.tabIndex = parseInt(tabIndex, 10) || 0;
  }

  ngOnInit() {
    this.eventListInit();
  }

  eventListInit() {
    if (!this.muloMultipleEvents) {
      return;
    }
    const eventList = this.eventOrigins?.split(/[ ,|]/);
    eventList?.forEach((origin) => {
      this.unlisteners.push(
        // the listen function returns An "unlisten" function for disposing of this handler.
        this.renderer.listen(
          this.elementRef.nativeElement,
          origin,
          this.keyFunc
        )
      );
    });
  }

  keyFunc = (event) => {
    this.muloMultipleEvents.emit(event);
  };

  ngOnDestroy(): void {
    this.unlisteners?.forEach((unlisten) => unlisten());
  }
}


