import { Injectable } from '@angular/core';
import { LinksConstant } from '../shared/configurations/links.constant';
import { UrlUtils } from '../shared/utils/url.utils';
import { ConfigurationHandlerService } from './configuration-handler.service';
import { I18nService } from './i18n.service';

@Injectable({ providedIn: 'root' })
export class LinkResolverService {
    institutionCode = UrlUtils.getParam('institution');

    constructor(
        private i18nService: I18nService,
        private configSvc: ConfigurationHandlerService
    ) {}

    getLinkUrl(url: string) {
        if (UrlUtils.isURL(url)) {
            return url;
        }
        const baseUrl = this.configSvc.getExternalFacingBaseUrl();
        let newUrl = baseUrl + (url.startsWith('/') ? '' : '/') + url;
        newUrl = newUrl.replace('{{inst}}', this.institutionCode);
        newUrl = newUrl.replace('{{lang}}', this.i18nService.getLanguage());
        return newUrl;
    }

    updateSocialMediaLinkUrl(url: string, code: string) {
        if (UrlUtils.isURL(url)) {
            return url;
        }
        url = url.startsWith('/') ? url.substring(1) : url;
        return LinksConstant.LINK_SOCIALS_MEDIA_URL[code.toLowerCase()] + url;
    }
}
