<mulo-thumbnail *ngIf="thumbnailUrl" [thumbs]="[{ alt: title, url: thumbnailUrl }]" [width]="130"></mulo-thumbnail>
<ng-content select="mulo-infobar"></ng-content>
<ng-content select="[slot='pre-title']"></ng-content>
<h1 *ngIf="!useHtmlAsTitle" dir="auto">{{ title }}</h1>
<h1 *ngIf="useHtmlAsTitle" [innerHtml]="title | htmlSanitize" dir="auto"></h1>
<div class="page-mast-lines">
  <ng-content select="[mulo-page-line], [muloPageLine]"></ng-content>
</div>
<ng-content></ng-content>
<div class="page-highlight-overlay" [class.is-active]="highlightActive$ | async">
  <div matRipple #ripple></div>
</div>
