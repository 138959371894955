import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

@Directive({
    selector: '[muloSearchbarAdvRoot]',
    exportAs: 'muloSearchbarAdvRoot',
    standalone: true,
})
export class SearchbarAdvRootDirective implements OnInit, AfterViewInit {
  parentHeight = 0;
  advSearch: EmbeddedViewRef<any>;
  isOpen = false;

  constructor(
    private _vcr: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private changeRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this._vcr.createEmbeddedView(this.templateRef);
  }
  ngAfterViewInit() {
    this.parentHeight =
      this._vcr.element?.nativeElement?.parentElement?.scrollHeight;
  }

  openAdvSearch(content: TemplateRef<any>) {
    this.advSearch = this._vcr.createEmbeddedView(content);
    this.isOpen = true;
    this.changeRef.markForCheck();
  }

  closeAdvSearch() {
    this.advSearch?.destroy();
    this.isOpen = false;
    this.changeRef.markForCheck();
  }
}
