import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnInit, SimpleChanges, OnChanges
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';

@Component({
    selector: 'exl-plain-text-input',
    templateUrl: './plain-text-input.component.html',
    styleUrls: ['./plain-text-input.component.scss'],
    host: {'class': 'exl-plain-text-input'}
})
export class PlainTextInputComponent implements OnInit, OnChanges{

    @Input() input: UntypedFormControl;
    @Input() jump;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() autoPopulated = false;
    @Input() multipleLines = false;
    @Input() lengthLimit: number;
    @Input() focus = false;
    @Input() expanded = false;
    @Input('autocomplete') autocompleteLabel: string;
    @Output() output: EventEmitter<string> = new EventEmitter();


    @ViewChild('autosize', {static: false}) autosize: CdkTextareaAutosize;

    @ViewChild('inputElement', {static: false}) inputElement;

    constructor(){}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.focus && changes.focus.currentValue) {
            setTimeout(() => {
                this.focusOnInput();
            }, 0);
        }
    }

    focusOnInput(){
        this.inputElement.nativeElement.focus();
    }

    clearInput() {
        this.input.reset();
    }


}
