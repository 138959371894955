import { ResearchTopic } from './research-topic.intreface';

export enum TopicType{
    SUBJECT,
    KEYWORD
}

export class SelectedResearchTopic{
    code: string;
    description: string;
    hierarchy: string;
    type: TopicType;

    constructor(code, description, hierarchy, type){
        this.code = code;
        this.description = description;
        this.hierarchy = hierarchy;
        this.type = type;
    }

    public static buildFromResearchTopic(data: ResearchTopic){
        let code: string = data.code;
        if(data.actualCode !== null && data.actualCode !== ''){
            code = data.actualCode;
        }
        let desc: string = data.description;
        if(data.actualDescription != null && data.actualDescription !== ''){
            desc = data.actualDescription;
        }
        let hierarchString = '';
        if(data.hierarchy != null && data.hierarchy.length > 0){
            hierarchString = data.hierarchy.join('/');
        }
        return new SelectedResearchTopic(code, desc,hierarchString, TopicType.SUBJECT);
    }
}

