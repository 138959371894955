<a
  *ngIf="show == 'logo' || show == 'both'"
  href="https://orcid.org"
  mat-icon-button
  class="orcid-link"
  target="_blank"
  [matTooltip]="logoTooltip"
  [attr.aria-label]="logoTooltip"
>
  <mulo-orcid-logo></mulo-orcid-logo>
</a>
<a
  *ngIf="show == 'id' || show == 'both'"
  href="https://orcid.org/{{orcidId}}"
  class="orcidId-link"
  [class.hasnt-color]="!showAsLink"
  target="_blank"
  [title]="idDescription"
>
  <span class="overflow-elipsis">{{orcidId}}</span>
</a>
