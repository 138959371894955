<form (ngSubmit)="onSubmit()" [formGroup]="passwordForm" style="width: 20rem">
    <h1 id="dialog-title" mat-dialog-title>
        {{ 'research.password.change.title' | translate }}
    </h1>
    <div *ngIf="errorMessage" class="semibold palette-warn-color margin-bottom-sm">{{ errorMessage }}</div>
    <div mat-dialog-content>
        <mat-form-field>
            <mat-label>{{ 'research.password.change.current' | translate }}</mat-label>
            <input autocomplete="new-password" formControlName="old" matInput required type="password" />
            <mat-error [innerHtml]="'research.output.list.error.message' | translate"></mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'research.password.change.new' | translate }}</mat-label>
            <input autocomplete="new-password" formControlName="new" matInput required type="password" />
            <mat-error [innerHtml]="'research.output.list.error.message' | translate"></mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'research.password.change.repeat' | translate }}</mat-label>
            <input autocomplete="new-password" formControlName="repeat" matInput required type="password" />
            <mat-error [innerHtml]="'research.output.list.error.message' | translate"></mat-error>
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <span class="spacer"></span>
        <button mat-dialog-close="cancel" mat-button>
            {{ 'research.delete.confirmation.cancel' | translate }}
        </button>

        <button
            [disabled]="passwordForm.invalid || saveInProgress"
            aria-describedby="dialog-title"
            class="is-large"
            color="accent"
            mat-button
            type="submit"
        >
            {{ 'research.portal.entity.item.save' | translate }}
        </button>
    </div>
</form>
