export class DepositFileMetadata {
    filename: string;
    description: string;
    esploroLicenseCode: string;
    esploroFileType: string;
    accessRightsType: string;
    accessRightsReason: string;
    accessRightsFixedDate: string;
    accessRightsFixedYear: string;
    accessRightsFixedMonth: string;
    accessRightsFixedDay: string;

    constructor(){}
}
