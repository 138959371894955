import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { DragDropModule, DragDrop } from '@angular/cdk/drag-drop';

import { DropListComponent } from './drop-list.component';

import {
  DropListDirective,
  DragDirective,
  DragWrapperComponent,
} from './drop-list.directive';

@NgModule({
    imports: [CommonModule, MatChipsModule, DragDropModule, DropListComponent,
    DragDirective,
    DropListDirective,
    DragWrapperComponent],
    exports: [
    DropListComponent,
    DragDirective,
    DropListDirective,
    DragWrapperComponent,
],
    providers: [DragDrop]
})
export class DropListModule {}
