<div class="wrapper" [ngClass]="'is-' + display">
  <div matRipple #ripple matRippleColor="primary" [class.is-active]="copying$ | async"></div>
  <div class="toolbar">
    <button
      *ngIf="!(copying$ | async); else copying"
      mat-raised-button
      color="primary"
      class="copy-button has-icon"
      [disabled]="processing"
      @heightOutAnimation
      @opacityInAnimation
      (click)="copyToClipboard($event)"
    >
      <!--
    (click)="copyToClipboard($event, output.innerHTML)"

   -->
      <mat-icon [svgIcon]="copyIcon" [class.mat-icon-rtl-mirror]="rtlMirrorIcon"></mat-icon>
      <span>{{ copyLabel }}</span>
    </button>
    <ng-template #copying>
      <button
        cdkAriaLive="polite"
        mat-raised-button
        color="accent"
        class="copy-button is-active has-icon"
        [disabled]="true"
        @heightInAnimation
        @opacityOutAnimation
      >
        <mat-icon svgIcon="check"></mat-icon>
        {{ copiedLabel }}
      </button>
    </ng-template>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
