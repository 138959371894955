import { ChatEntry } from '@exl-ng/mulo-common';

export class ResearcherNotification extends ChatEntry {
    uuid: string;
    contentTitle: string;
    reason: string;
    createdBy: string;
    subject: string;
    showNotification: boolean;
}

export class NotificationData {
    currNote: ResearcherNotification;
    notifications: ResearcherNotification[];
    index: number;
}
