<mat-form-field
    floatLabel="auto"
    class="is-comfortably-readable"
    [muloInputExpander]="label || ('research.topics.overline' | customTranslate)"
>
    <mat-label>
        <ng-container *ngIf="label; else fixedLabel">{{ label }}</ng-container>
        <ng-template #fixedLabel>{{ 'research.topics.overline' | customTranslate }}</ng-template>
    </mat-label>
    <mat-chip-grid #chipGrid>
        <mat-chip-row
            *ngFor="
                let chip of depositFormDataService.topics.value;
                first as isFirst;
                trackBy: trackByTopic;
                let i = index
            "
            [removable]="removable"
            [aria-label]="chip.description"
            (removed)="removeChip(chip, i)"
            [matTooltip]="chip.hierarchy"
            matTooltipPosition="above"
            [class.is-autopopulated]="isAutoPopulated(chip)"
            [disableRipple]="true"
            @widthInAnimation
            @widthOutAnimation
        >
            <exl-badge-auto-populated
                *ngIf="isAutoPopulated(chip)"
                tooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
                color="primary"
            >
            </exl-badge-auto-populated>
            <span class="semibold chip-content">
                {{ chip.description }}
            </span>
            <button
                mat-icon-button
                matChipRemove
                *ngIf="removable"
                attr.aria-label="{{ 'research.aria.deposit.keywords.remove' | translate }}{{ ' ' }}{{
                    chip.description
                }}"
            >
                <mat-icon svgIcon="close"></mat-icon>
            </button>
        </mat-chip-row>
    </mat-chip-grid>

    <input
        matInput
        (keydown.enter)="$event.preventDefault()"
        [required]="this.depositFormDataService.topics.invalid"
        [formControl]="autoCompleteChipList"
        #chipInput
        [matAutocomplete]="auto"
        (keyup.enter)="onInputEnterKey(chipInput)"
        [exlFocus]="jump"
        [matChipInputFor]="chipGrid"
    />
    <mat-error
        *ngIf="this.depositFormDataService.topics?.invalid"
        [innerHTML]="this.depositFormDataService.topics?.errors | errorTranslate"
    >
    </mat-error>

    <mat-progress-bar mode="indeterminate" color="primary" *ngIf="searchInProgress"></mat-progress-bar>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-optgroup *ngIf="filteredSuggestions.length > 0">
            <label>
                <span [muloElementAsHeading]="4">
                    {{ 'research.topics.suggestions' | customTranslate }}
                </span>
            </label>
            <mat-option
                *ngFor="let suggestion of filteredSuggestions"
                [value]="suggestion.description"
                (onSelectionChange)="addSuggestionChip(suggestion, chipInput)"
            >
                <div class="autocomplete-option-multiline">
                    <span
                        class="semibold"
                        [innerHTML]="suggestion.description | highlight : autoCompleteChipList.value"
                    ></span>
                    <span
                        class="italics"
                        [innerHTML]="suggestion.hierarchy | highlight : autoCompleteChipList.value"
                    ></span>
                </div>
            </mat-option>
        </mat-optgroup>

        <mat-option
            *ngFor="let topic of topics"
            [value]="topic.description"
            (onSelectionChange)="addChip(topic, chipInput)"
        >
            <div class="autocomplete-option-multiline">
                <span class="semibold" [innerHTML]="topic.description | highlight : autoCompleteChipList.value"></span>
                <span
                    class="italics"
                    [innerHTML]="getHierarchyAsString(topic.hierarchy) | highlight : autoCompleteChipList.value"
                ></span>
            </div>
        </mat-option>
        <mat-option
            [value]="autoCompleteChipList.value"
            *ngIf="autoCompleteChipList.value && noValuesFound && !searchInProgress"
            (onSelectionChange)="onInputEnterKey(chipInput)"
        >
            {{ 'research.topics.add.new.topic' | customTranslate }} "<span class="bold">{{
                autoCompleteChipList.value
            }}</span
            >"
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
