<div class="checkboxes-wrapper margin-neg-bottom-xs">
    <mat-checkbox [formControl]="depositFormDataService.enableAddress" id="address">{{
        'research.geospatial.address' | customTranslate
    }}</mat-checkbox>
    <mat-checkbox [formControl]="depositFormDataService.enablePoint" id="point">{{
        'research.geospatial.point' | customTranslate
    }}</mat-checkbox>
    <mat-checkbox [formControl]="depositFormDataService.enableBoundingBox" id="bounding-box">{{
        'research.geospatial.bounding' | customTranslate
    }}</mat-checkbox>
</div>

<div class="content-wrapper">
    <div *ngIf="depositFormDataService.enableAddress.value" @growInAnimation @growOutAnimation>
        <div aria-describedby="address" class="input-row" role="group">
            <exl-plain-text-input
                [autoPopulated]="isAddressAutoPopulated"
                [exlRequiredInput]="depositFormDataService.address"
                [input]="depositFormDataService.address"
                [multipleLines]="true"
                [placeholder]="'research.geospatial.addresstext' | customTranslate"
                autocomplete="street-address"
            >
            </exl-plain-text-input>
        </div>
    </div>

    <div *ngIf="depositFormDataService.enablePoint.value" @growInAnimation @growOutAnimation>
        <div aria-describedby="point" class="input-row" role="group">
            <mat-form-field class="is-short-input">
                <input
                    [exlRequiredInput]="depositFormDataService.pointLatitude"
                    [formControl]="depositFormDataService.pointLatitude"
                    matInput
                />
                <mat-label>{{ 'research.geospatial.latitude' | customTranslate }}</mat-label>
                <mat-error>
                    <span
                        [innerHTML]="
                            getErrorMessage(depositFormDataService.pointLatitude, 'research.geospatial.latitude.error')
                                | customTranslate
                        "
                    >
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="is-short-input">
                <input
                    [exlRequiredInput]="depositFormDataService.pointLongitude"
                    [formControl]="depositFormDataService.pointLongitude"
                    matInput
                />
                <mat-label>{{ 'research.geospatial.longitude' | customTranslate }}</mat-label>
                <mat-error>
                    <span
                        [innerHTML]="
                            getErrorMessage(
                                depositFormDataService.pointLongitude,
                                'research.geospatial.longitude.error'
                            ) | customTranslate
                        "
                    >
                    </span>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <div
        *ngIf="depositFormDataService.enableBoundingBox.value"
        @growInAnimation
        @growOutAnimation
        aria-describedby="bounding-box"
        role="group"
    >
        <div class="input-row">
            <span class="input-row-label">{{ 'research.geospatial.sw' | customTranslate }}</span>
            <mat-form-field class="is-short-input">
                <input
                    [exlRequiredInput]="depositFormDataService.boundingBoxSouth"
                    [formControl]="depositFormDataService.boundingBoxSouth"
                    matInput
                />
                <mat-label>{{ 'research.geospatial.south' | customTranslate }}</mat-label>
                <mat-error>
                    <span
                        [innerHTML]="
                            getErrorMessage(depositFormDataService.boundingBoxSouth, 'research.geospatial.south.error')
                                | customTranslate
                        "
                    >
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="is-short-input">
                <input
                    [exlRequiredInput]="depositFormDataService.boundingBoxWest"
                    [formControl]="depositFormDataService.boundingBoxWest"
                    matInput
                />
                <mat-label>{{ 'research.geospatial.west' | customTranslate }}</mat-label>
                <mat-error>
                    <span
                        [innerHTML]="
                            getErrorMessage(depositFormDataService.boundingBoxWest, 'research.geospatial.west.error')
                                | customTranslate
                        "
                    >
                    </span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="input-row">
            <span class="input-row-label">{{ 'research.geospatial.ne' | customTranslate }}</span>
            <mat-form-field class="is-short-input">
                <input
                    [exlRequiredInput]="depositFormDataService.boundingBoxNorth"
                    [formControl]="depositFormDataService.boundingBoxNorth"
                    matInput
                />
                <mat-label>{{ 'research.geospatial.north' | customTranslate }}</mat-label>
                <mat-error>
                    <span
                        [innerHTML]="
                            getErrorMessage(depositFormDataService.boundingBoxNorth, 'research.geospatial.north.error')
                                | customTranslate
                        "
                    >
                    </span>
                </mat-error>
            </mat-form-field>
            <mat-form-field class="is-short-input">
                <input
                    [exlRequiredInput]="depositFormDataService.boundingBoxEast"
                    [formControl]="depositFormDataService.boundingBoxEast"
                    matInput
                />
                <mat-label>{{ 'research.geospatial.east' | customTranslate }}</mat-label>
                <mat-error>
                    <span
                        [innerHTML]="
                            getErrorMessage(depositFormDataService.boundingBoxEast, 'research.geospatial.east.error')
                                | customTranslate
                        "
                    >
                    </span>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
