import { Component, inject, OnInit } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { merge, tap } from 'rxjs';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from '../shared/shared.module';
import { ConfigurationHandlerService } from '../core/configuration-handler.service';
import { CenteredBlockLayoutComponent } from '@exl-ng/mulo-views';

@Component({
    selector: 'esp-design-system-demo',
    standalone: true,
    imports: [
        MatCardModule,
        MatIconModule,
        SharedModule,
        CenteredBlockLayoutComponent,
    ],
    templateUrl: './design-system-demo.component.html',
    styleUrl: './design-system-demo.component.scss',
})
export class DesignSystemDemoComponent implements OnInit {
    iconRegistry = inject(MatIconRegistry);
    sanitizer = inject(DomSanitizer);
    cfgSvc = inject(ConfigurationHandlerService);

    iconNames = [];
    entityIcons = [
        { label: 'Output', icon: 'asset-thumbnail-sm' },
        { label: 'Activities', icon: 'calendar-check' },
        { label: 'Resources', icon: 'resources' },
        { label: 'Organizational Units', icon: 'graph-outline' },
        { label: 'Grants', icon: 'trophy' },
        { label: 'Collections', icon: 'bookmark-star' },
        { label: 'Media Mentions', icon: 'newspaper' },
    ];
    colors = [
        { key: 'primary', label: 'Primary', value: '#397593' },
        { key: 'accent', label: 'Accent', value: '#d23b21' },
        { key: 'warn', label: 'Warning', value: '#b80900' },
        { key: 'secondary', label: 'Secondary' },
        { key: 'tertiary', label: 'Tertiary' },
    ];

    ngOnInit() {
        const safeCoreIconUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            'assets/icons/mulo-core-icons.svg',
        );
        const safeEspIconUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            'assets/icons/exl-app-icons.svg',
        );
        merge(
            this.iconRegistry.getSvgIconFromUrl(safeCoreIconUrl),
            this.iconRegistry.getSvgIconFromUrl(safeEspIconUrl),
        )
            .pipe(
                tap({
                    complete: () =>
                        this.iconNames.sort((a, b) => a.localeCompare(b)),
                }),
            )
            .subscribe((_) => this.parseIconNames(_));

        const cfgColors = this.cfgSvc.getCustomizationThemeing()?.colors;
        for (const color in cfgColors) {
            const index = this.colors.findIndex((c) => c.key === color);
            if (index !== -1) {
                this.colors[index].value = cfgColors[color];
            }
        }
    }

    parseIconNames(svg: SVGElement) {
        const collection = svg.getElementsByTagName('symbol');
        for (const symbol of Array.from(collection)) {
            this.iconNames.push(symbol.id);
        }
    }
}
