import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'mulo-visibility-switch',
    templateUrl: './visibility-switch.component.html',
    styleUrls: ['./visibility-switch.component.scss'],
    standalone: true,
    imports: [
        MatIconButton,
        MatTooltip,
        MatIcon,
    ],
})
export class VisibilitySwitchComponent implements OnInit {
  @Input() active: boolean = true;
  @Input() disabled = false;
  @Input() toolTipText = 'Show/hide this';
  @Input() visibleLabel = 'Visible';
  @Input() hiddenLabel = 'Hidden';
  @Output() changed: EventEmitter<null> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  toggle() {
    this.changed.emit(null);
  }
}
