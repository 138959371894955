import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  ContentChildren,
  AfterViewInit,
} from '@angular/core';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { BehaviorSubject, Subject } from 'rxjs';
import { AltmetricService } from './altmetric.service';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { DomChangeDirective } from '../../directives/dom-changes.directive';

@Component({
    selector: 'mulo-altmetric',
    templateUrl: './altmetric.component.html',
    styleUrls: ['./altmetric.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[attr.id]': 'doi'
    },
    standalone: true,
    imports: [
        DomChangeDirective,
        MatMenuTrigger,
        NgIf,
        MatMenu,
        NgFor,
        MatMenuItem,
        AsyncPipe,
    ],
})
export class AltmetricComponent implements AfterViewInit {
  @ViewChild('altmetricEmbed', { static: true }) element: ElementRef;
  @ViewChild('menuTrigger', { static: true }) trigger: MatMenuTrigger;

  @Input() popover: 'left' | 'right' | 'top' | 'bottom' | 'none' = 'none';
  @Input() details: 'none' | 'right' = 'right';
  @Input() flavor: 'donut' | 'medium-donut' | 'large-donut' = 'donut';
  @Input() menuHideDelay: number = 500;
  @Input() triggerBy: 'click' | 'hover' = 'hover';

  @Input() set doi(val) {
    this._doi = val && val.replace('https://doi.org/', '');
  }
  get doi(): string {
    return this._doi;
  }
  private _doi: string;

  menuContent = new Array();
  mouseEntered: boolean = false;
  mouseLeft: boolean = false;
  menuCloseTimer;
  score: number | null = null;
  imageLoaded$ = new Subject<boolean>();

  constructor(private service: AltmetricService) {}

  ngAfterViewInit() {
    this.service.loadScript(this.doi);
  }

  onTriggerClick(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    if (this.triggerBy === 'click') {
      this.trigger.openMenu();
    }
  }

  onMouseEnterBadge() {
    clearTimeout(this.menuCloseTimer);
    if (!this.trigger.menuOpen) {
      if (!this.menuContent.length) {
        this.createMenu();
      }
      if (this.triggerBy === 'hover') {
        this.trigger.openMenu();
      }
    }
  }

  onMouseEnterMenu() {
    clearTimeout(this.menuCloseTimer);
  }

  onMouseLeaveMenu() {
    clearTimeout(this.menuCloseTimer);
    this.menuCloseTimer = setTimeout(() => {
      this.trigger.closeMenu();
    }, this.menuHideDelay);
  }

  createMenu() {
    if (this.menuContent.length) return null;

    const entries = this.element.nativeElement.children[0].children[1]
      .children[0].children[0].children[0].children;
    let array = [];
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i].children[0];
      if (entry.nodeName.toLowerCase() === 'a') {
        array.push({
          html: entry.innerHTML,
          href: entry.href,
          color: entry.parentNode.style['border-left-color'],
        });
      }
    }
    this.menuContent = array;
  }

  getImgData(ev) {
    const img = ev.target.children[0].children[0].children[0].children[0]
    const alt = img.alt.split(' ');
    img.onload = () => {
      this.showImage()
    }
    this.score = parseInt(alt[alt.length - 1]);
  }

  showImage(){
    this.imageLoaded$.next(true)
  }

  handleDomChange(ev) {

    if (!this.score && ev.target.children.length) {
      this.getImgData(ev);
    }

  }
}
