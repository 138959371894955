/* eslint-disable no-console */
import { isDevMode } from '@angular/core';

/**
 * Will create a console group opening, using `console.group`,
 * but only in Dev mode (uses `isDevMode`). Use with `cge()`.
 *
 * @param data Data to be passed to `console.group`.
 */
export const cgr = (...data: any[]) => {
  if (isDevMode()) {
    console.group(...data);
  }
}

/**
 * Will create a console group closing, using `console.groupEnd`,
 * but only in Dev mode (uses `isDevMode`). Use with `cgr()`.
 */
export const cge = () => {
  if (isDevMode()) {
    console.groupEnd();
  }
}

/**
 * Will create a console log output, using `console.log`,
 * but only in Dev mode (uses `isDevMode`).
 *
 * @param data Data to be passed to `console.log`.
 */
export const clg = (...data: any[]) => {
  if (isDevMode()) {
    console.log(...data);
  }
}

/**
 * Will create a console log output of a label and an object,
 * using `console.log`, but only in Dev mode (uses `isDevMode`).
 *
 * @param label Label to identify the object.
 * @param object Object to be output.
 */
export const clo = (label: string, object: any) => {
  if (isDevMode()) {
    console.log(label + ' :>> ', object);
  }
}

/**
 * Will create a console table output, using `console.table`,
 * but only in Dev mode (uses `isDevMode`).
 *
 * @param tabularData The data to display. This must be either an array or an object.
 * @param properties An array containing the names of columns to include in the output.
 */
export const clt = (tabularData: any, properties?: string[]) => {
  if (isDevMode()) {
    console.table(tabularData, properties);
  }
}

/**
 * Will create a console debug output, using `console.debug`,
 * but only in Dev mode (uses `isDevMode`).
 *
 * @param data Data to be passed to `console.debug`.
 */
export const cdb = (...data: any[]) => {
  if (isDevMode()) {
    console.debug(...data);
  }
}

/**
 * Will create a console info output, using `console.info`,
 * but only in Dev mode (uses `isDevMode`).
 *
 * @param data Data to be passed to `console.info`.
 */
export const cin = (...data: any[]) => {
  if (isDevMode()) {
    console.info(...data);
  }
}

/**
 * Will create a console timer start, using `console.time`,
 * but only in Dev mode (uses `isDevMode`). Use with `ctl()` & `cte()`.
 *
 * @param label Label to be passed to `console.time`.
 */
export const cti = (label?: string) => {
  if (isDevMode()) {
    console.time(label);
  }
}

/**
 * Will create a console timer log, using `console.timeLog`,
 * but only in Dev mode (uses `isDevMode`). Use with `cti()` & `cte()`.
 *
 * @param label Label to be passed to `console.timeLog`.
 * @param data Data to be printed
 */
export const ctl = (label?: string, ...data: any[]) => {
  if (isDevMode()) {
    console.timeLog(label, ...data);
  }
}

/**
 * Will create a console timer end, using `console.timeEnd`,
 * but only in Dev mode (uses `isDevMode`). Use with `cti()` & `ctl()`.
 *
 * @param label Label to be passed to `console.timeEnd`.
 */
export const cte = (label?: string) => {
  if (isDevMode()) {
    console.timeEnd(label);
  }
}
