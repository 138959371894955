export enum EsploroCreatorStatus {
    MANUAL = 'MANUAL',
    AFF_STRONG = 'AFF_STRONG',
    AFF_UNCERTAIN = 'AFF_UNCERTAIN',
    NEW_NONAFF = 'NEW_NONAFF',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    NON_AFF_STRONG = 'NON_AFF_STRONG',
    NON_AFF_UNCERTAIN = 'NON_AFF_UNCERTAIN',
    MATCHED_WITH_ID = 'MATCHED_WITH_ID',
}

export interface EsploroAuthor {
    almaId: number;
    familyName: string;
    givenName: string;
    fullName: string;
    identifierOrcid: string;
    identifierIsni: string;
    identifierResearcherid: string;
    identifierScopus: string;
    identifierArxiv: string;
    identifierEra: string;
    identifierOrcidUri: string;
    identifierIsniUri: string;
    identifierResearcheridUri: string;
    identifierScopusUri: string;
    identifierArxivUri: string;
    identifierEraUri: string;
    affiliation: string[];
    status: EsploroCreatorStatus;
    mappedAffiliationErodCode?: string;
}
