export class StringUtils {
    public static regexDoi = /10\.[^\/]+\/.+/;
    public static regexDoiPartial = /[^\/]+\/.+/;
    public static regexIsbn = /^(\d{9}|\d{12})(x|X|\d)$/;

    public static convertStringToHtml(string) {
        return `${string}`;
    }

    public static capitalizeName(string) {
        return string.trim().replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
        });
    }

    public static isDoiUrlFormat = (doiUrl: string): boolean =>
        doiUrl?.startsWith('http') &&
        doiUrl?.includes('doi.org') &&
        StringUtils.regexDoi.test(doiUrl);

    public static doiFromUrl = (doiUrl: string) =>
        doiUrl?.match(StringUtils.regexDoi)?.[0];
    public static isDoiFormat = (doi: string): boolean =>
        new RegExp(StringUtils.regexDoi, 'y').test(doi);

    public static isNil = (s: any): boolean => s === undefined || s === null;
    public static isNilOrEmpty = (s: string): boolean =>
        StringUtils.isNil(s) || s.trim() === '';
}
