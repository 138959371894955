import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FaviconService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    setFaviconImage(faviconImageUrl: string) {
        const link: HTMLLinkElement = this.document.createElement('link');
        link.setAttribute('rel', 'icon');
        link.setAttribute('type', 'image/x-icon');
        link.setAttribute('href', faviconImageUrl);
        this.document.head.appendChild(link);
    }
}
