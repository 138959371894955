<span class="badge-text" *ngIf="type !== 'dot'">
    <ng-content *ngIf="!count"></ng-content>
    <span *ngIf="count">{{ count }}</span>
</span>
<mat-icon
    class="badge-background"
    [ngClass]="color ? 'exl-' + color : undefined"
    muloSvgViewbox
    [style.color]="hexColor ? color : undefined"
    [svgIcon]="type === 'icon' ? 'decagram' : 'circle-dot'"
>
</mat-icon>
<mat-icon *ngIf="icon" [class.mat-icon-rtl-mirror]="rtlMirrorIcon" [svgIcon]="icon" class="exl-{{ color }}"></mat-icon>
