import {Component, Input, OnInit} from '@angular/core';
import {TabComponentModel} from '../../../shared/interfaces/tab-component.model';
import {ConfigurationHandlerService} from '../../../core/configuration-handler.service';
import {DepositFormDataService} from '../../deposit-form-data.service';


@Component({
    selector: 'esp-deposit-step-two',
    templateUrl: './deposit-step-two.component.html',
    styleUrls: ['./deposit-step-two.component.scss']
})
export class DepositStepTwoComponent implements OnInit {

    @Input() tab2;
    @Input() jumpToTitle;
    @Input() jumpToTopics;
    @Input() selectedType;
    @Input() selectedSubtype;

    researchTerms;

    constructor(private configurationHandlerService: ConfigurationHandlerService,
        public depositFormDataService: DepositFormDataService) { }

    ngOnInit() {
        this.researchTerms = this.configurationHandlerService.getCustomerParameter('esploro_research_subject_terms');
    }

    displayTitle(comp: TabComponentModel){
        return comp.visible && comp.field === 'title';
    }

    displaySubtitle(comp: TabComponentModel){
        return comp.visible && comp.field === 'subtitle';
    }

    displayInvolved(comp: TabComponentModel){
        return comp.visible && comp.field ==='involved';
    }

    displayKeywords(comp: TabComponentModel){
        return comp.visible && comp.field === 'keywords';
    }

    displayDescription(comp: TabComponentModel){
        return comp.visible && comp.field === 'description';
    }

    anzInstitution(){
        return this.researchTerms === 'ANZSRC';
    }

    isRequired(comp){
        return comp.mandatory;
    }

}
