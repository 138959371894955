import {
  trigger,
  style,
  transition,
  animate,
  keyframes,
} from '@angular/animations';
import * as easings from './easings';

export const SpringInAnimation = trigger('springInAnimation', [
  // transition(':enter', [
  //     style({
  //         opacity: 0,
  //         height: 0,
  //         transform: 'scale({{ startScale }})',
  //         transformOrigin: '{{ origin }}'
  //     }),
  //     animate('{{ time }}', keyframes([
  //         style({ transform: 'scale(0)', offset: 0 }),
  //         style({ transform: 'scale(2)', offset: 0.33 }),
  //         style({ transform: 'scale(1)', offset: 1 }),
  //     ]))
  // ], {
  //     params: {
  //         endHeight: '*',
  //         startScale: '0',
  //         time: '1s 1s',
  //         origin: 'center center'
  //     }
  //     })
  transition(
    ':enter',
    animate(
      '2000ms',
      keyframes([
        style({ transform: 'translateX(0)    rotateY(0)', offset: 0 }),
        style({ transform: 'translateX(50%)  rotateY(90deg)', offset: 0.33 }),
        style({ transform: 'translateY(-75%) rotateY(180deg)', offset: 0.66 }),
        style({ transform: 'translateX(-100%)', offset: 1.0 }),
      ])
    )
  ),
]);

export const SpringOutAnimation = trigger('springOutAnimation', [
  transition(
    ':leave',
    [
      style({
        height: '{{ startHeight }}',
        opacity: 1,
        transform: 'scale(1)',
        transformOrigin: '{{ origin }}',
        overflow: '{{ overflow }}',
      }),
      animate(
        '{{ time }} ' + easings.OutQuint,
        style({
          height: 0,
          opacity: 0,
          transform: 'scale({{ endScale}})',
        })
      ),
    ],
    {
      params: {
        startHeight: '*',
        endScale: '.9',
        time: '.4s',
        origin: 'left top',
        overflow: 'hidden',
      },
    }
  ),
]);
