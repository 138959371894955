<form
    #formDirective="ngForm"
    (ngSubmit)="onSubmitNewGrant()"
    @growInAnimation
    @growOutAnimation
    [formGroup]="newGrant"
    autocomplete="off"
    class="grant-add-form"
>
    <div class="fields-wrap">
        <mat-form-field class="is-comfortably-readable">
            <input formControlName="grantName" matInput maxlength="254" />
            <mat-label>{{ 'funding.name' | customTranslate }}</mat-label>
        </mat-form-field>
        <mat-form-field class="is-comfortably-readable">
            <input [matAutocomplete]="auto2" formControlName="grantId" matInput maxlength="254" required />
            <mat-label>{{ 'funding.id' | customTranslate }}</mat-label>
            <mat-autocomplete #auto2="matAutocomplete">
                <ng-container *ngIf="filteredGrantIdsGrants">
                    <mat-option
                        (onSelectionChange)="onGrantSelect(result)"
                        *ngFor="let result of filteredGrantIdsGrants"
                    >
                        <div class="autocomplete-option-multiline">
                            <span
                                [innerHTML]="
                                    result.grantName + ' (' + result.grantId + ')'
                                        | highlight : newGrant.get('grantId').value
                                "
                                class="semibold"
                            ></span>
                            <small *ngIf="!result.funderAgency" class="italics">{{
                                result.funderCode | defaultTranslate : { default: result.funderName }
                            }}</small>
                            <small *ngIf="result.funderAgency" class="italics"
                                >{{ result.funderCode | defaultTranslate : { default: result.funderName } }}
                                ,
                                {{
                                    result.funderAgencyCode | defaultTranslate : { default: result.funderAgency }
                                }}</small
                            >
                        </div>
                    </mat-option>
                </ng-container>
            </mat-autocomplete>
            <mat-error [innerHtml]="'research.settings.field.required.error.message' | translate"></mat-error>
        </mat-form-field>

        <esp-external-organization-field
            [isComfortablyReadable]="true"
            [label]="'funding.funder' | customTranslate"
            [organizationCode]="this.newGrant?.get('funderCode')"
            [organizationName]="this.newGrant?.get('funderName')"
            newOrganizationType="esploro.organization.types.other"
        >
        </esp-external-organization-field>
    </div>
    <div class="actions">
        <button [disabled]="!isValid() || saveInProcess" mat-raised-button type="submit">
            {{ 'funding.button' | customTranslate }}
        </button>
    </div>
</form>
