<p class="title">{{ 'research.add.project.title.description' | translate }}</p>

<form #formContent (ngSubmit)="formSubmitSubject$.next($event)" [formGroup]="projectForm">
    <h3>{{ 'research.add.project.name' | translate }}</h3>
    <p>{{ 'research.add.project.name.description' | translate }}</p>
    <mat-form-field class="is-comfortably-readable">
        <mat-label>{{ 'research.add.project.name.placeholder' | translate }}</mat-label>
        <input formControlName="name" matInput type="text" />
    </mat-form-field>
    <h3>{{ 'research.add.project.identifier' | translate }}</h3>
    <p>{{ 'research.add.project.identifier.description' | translate }}</p>
    <mat-form-field class="is-comfortably-readable">
        <mat-label>{{ 'research.add.project.identifier.placeholder' | translate }}</mat-label>
        <input [maxlength]="255" formControlName="projectIdentifier" matInput type="text" />
        <mat-error>{{ 'research.add.project.identifier.error.message' | translate }}</mat-error>
    </mat-form-field>
    <h3>{{ 'research.add.project.type' | translate }}</h3>
    <p>{{ 'research.add.project.type.description' | translate }}</p>
    <mat-form-field class="is-comfortably-readable">
        <mat-label>{{ 'research.add.project.type.placeholder' | translate }}</mat-label>
        <mat-select formControlName="type">
            <mat-option *ngFor="let type of projectTypes$ | async" [value]="type">{{ type | translate }}</mat-option>
        </mat-select>
    </mat-form-field>
    <h3>{{ 'research.add.project.academic.department' | translate }}</h3>
    <p>
        {{ 'research.add.project.academic.department.description' | translate }}
    </p>
    <esp-internal-organization-autocomplete-input
        (departmentChange)="setInternalOrganization($event)"
        [classField]="'is-comfortably-readable'"
        [internalOrgControl]="internalDepartment"
        [label]="'research.add.project.academic.department'"
        [placeholder]="'research.add.project.academic.department.placeholder'"
        [required]="false"
    ></esp-internal-organization-autocomplete-input>
    <button
        [ariaLabel]="'research.aria.deposit.submit' | translate"
        [disabled]="!isValid()"
        [gleam]="false"
        [jellyOnHover]="false"
        mulo-funky-button
    >
        <span *ngIf="!loading" class="margin-right-xs">
            {{ 'research.add.project.dialog.button.submit' | translate }}</span
        >
        <mat-icon *ngIf="!loading" class="mat-icon-rtl-mirror" svgIcon="send"></mat-icon>
        <mat-spinner *ngIf="loading" [@SpinnerIn] diameter="30"></mat-spinner>
    </button>
</form>
