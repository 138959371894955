import { NgModule } from '@angular/core';
import {UserNavComponent} from './user-nav.component';

@NgModule({
    imports: [
    ],
    declarations: [
        UserNavComponent
    ],
    exports: [
        UserNavComponent
    ]
})
export class UserNavModule { }
