import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { ColorComponentMixinBase } from '../../common-behavior/color';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'mulo-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
    inputs: ['color'],
    standalone: true,
    imports: [
        MatMenuTrigger,
        MatMenu,
        MatButton,
    ],
})
export class OnboardingComponent extends ColorComponentMixinBase
  implements OnInit {
  guideShowDelay: number = 600;
  @Input() text: string;
  @Input() align: 'left' | 'right' | 'center' = 'left';
  @Input() textOffset: number;
  @Input() buttonText: string = 'Got it';
  @Output() guideClosed: EventEmitter<null> = new EventEmitter();

  @ViewChild('guideTextTrigger', { static: true })
  guideTextTrigger: MatMenuTrigger;

  constructor(elementRef: ElementRef) {
    super(elementRef);
  }

  ngOnInit() {
    setTimeout(() => {
      this.guideTextTrigger.openMenu();
    }, this.guideShowDelay);
  }

  onGuideTextClosed() {
    this.guideClosed.emit(null);
  }

  get offsetTextPosition() {
    let off = this.textOffset;
    return this.align === 'left' ? `-${off}rem` : `${off}rem`;
  }
}
