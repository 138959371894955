export enum ResearcherAssetStatus {
    DECLINED = 'DECLINED',
    RETURNED = 'RETURNED',
    SUBMITTED = 'SUBMITTED',
    APPROVED = 'APPROVED',
    DRAFT = 'RESEARCHER_DRAFT',
    LIBRARIAN_DRAFT = 'DRAFT',
    UNDER_REVIEW = 'UNDER_REVIEW',
    IMPORTED = 'IMPORTED',
    RESUBMITTED = 'RESUBMITTED',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
}
