import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { UrlUtils } from '../../shared/utils/url.utils';
import { RestApiBase } from '../../shared/configurations/rest-api-base.config';

@Injectable({
    providedIn: 'root',
})
export class LoginAsService {
    institutionCode = UrlUtils.getParam('institution');

    constructor(private http: HttpClient) {}

    getProxiesFor(userIDs) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('userIDs', userIDs);
        return this.http.get(RestApiBase.GET_PROXIES_FOR, { params: params });
    }
}
