import { Component, OnInit } from '@angular/core';
import {DepositFormDataService} from '../../deposit-form-data.service';

@Component({
    selector: 'esp-deposit-presentation-information',
    templateUrl: './deposit-presentation-information.component.html',
    styleUrls: ['./deposit-presentation-information.component.scss']
})
export class DepositPresentationInformationComponent implements OnInit {

    constructor(public depositFormDataService: DepositFormDataService) { }

    ngOnInit() {
    }

}
